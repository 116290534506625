import { Header, UniversalButton } from "../../../../components";
import styles from "./Recovery.module.scss";

function Recovery() {

  return (
    <div className="backImg">
      <Header />
      <div className="container-center">
        <div className={styles.block}>
          <p>Для восстановления обратитесь к администратору</p>
          <UniversalButton label={"Позвонить администратору"} color={"primary"} size={"large"} typeButton={"button"} type={"with-border"} />
        </div>
      </div>
    </div>
  );
}

export default Recovery;
