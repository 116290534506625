import React, { useEffect, useState } from 'react';
import styles from '../Modals.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import MainInput from '../../../../../../components/Inputs/MainInput/MainInput';
import { useForm } from 'react-hook-form';
import {
	confirmClient,
	confirmOrg,
	deleteClient,
	getContractsList,
	postCreateContract,
	putClient,
	setClientListAfterConfirm,
	setClientListAfterDelete,
	setClientListAfterPut,
	setCurrentClientInitial,
	setInputChange,
	setNewClientCorporateAddress,
	setNewClientCorporateName,
	setNewClientEmail,
	setNewClientInn,
} from '../../../../../../store/slices/clientSlice';
import { setSideBarActive } from '../../../../../../store/slices/sideBarSlice';
import { setTerminalClientsAfterPut } from '../../../../../../store/slices/terminalPageSlice';
import StatusBtn from '../components/StatusBtn';
import { IconCloseLock, IconCopy2, IconOpenLock, IconTrash } from '../../../../../../components/Icons';
import ChangeBtn from '../components/ChangeBtn';
import { UniversalButton } from '../../../../../../components';
import copy from 'copy-to-clipboard';
import { useMediaQuery } from 'react-responsive';
import { Alert, Snackbar } from '@mui/material';

function ModalClientInputs({ type }) {
	// type: clients/terminalclients

	//! Нужно для правильной работы input в useForm при анмаунте компонента
	const [useFormPlug, setUseFormPlug] = useState('');

	const dispatch = useDispatch();
	const {
		newClientCorporateName,
		newClientCorporateAddress,
		newClientEmail,
		newClientInn,
		currentClient,
		inputChange,
		newClientPassword,
		modalType,
		getClient_pending,
		contractsList,
		isCurrentClientConfirmed,
		confirmOrg_success,
	} = useSelector((state) => state.client);

	const { currentTerminalId } = useSelector((state) => state.terminalPage);

	const [isActive, setIsActive] = useState(false);
	const [disabledBtn, setDisabledBtn] = useState(true);

	const {
		register,
		setFocus,
		setValue,
		formState: { errors },
		clearErrors,
		handleSubmit,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			//   email: localStorage.getItem("email"),
			//   FLName: localStorage.getItem("FLName"),
			//   login: localStorage.getItem("login"),
			//   numberContract: localStorage.getItem("numberContract"),
			//   urAdress: localStorage.getItem("urAdress"),
			//   urName: localStorage.getItem("urName"),
			//   autoNum: '',
		},
	});

	// Логика тоста
	const isDesktop = useMediaQuery({ minWidth: 1200 });
	const [openToast, setOpenToast] = useState(false);
	const handleClose = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenToast(false);
	};

	// удаление клиента
	const handleDeleteUser = () => {
		dispatch(deleteClient({ user_id: currentClient.id }));
		dispatch(setClientListAfterDelete({ user_id: currentClient.id }));
		dispatch(setSideBarActive(false));
	};

	// запрос на смену верификации клиента
	const handleConfirmClient = async ({ status }) => {
		let requestData = {
			is_manager_confirmed: status === 'одобрить' ? 1 : status === 'отклонить' ? 3 : undefined,
		};
		dispatch(confirmOrg({ org_id: currentClient.id, requestData }));
		setOpenToast(true);
	};

	// ! Изменение клиента
	const handlePutClient = async (e) => {
		let requestData = {};

		if (currentClient.organization_name !== newClientCorporateName) {
			requestData['organization_name'] = newClientCorporateName;
		}
		if (currentClient?.contact_email !== newClientEmail) {
			requestData['contact_email'] = newClientEmail;
		}
		if (currentClient?.inn !== newClientInn) {
			requestData['inn'] = newClientInn;
		}
		if (currentClient?.organization_address !== newClientCorporateAddress) {
			requestData['organization_address'] = newClientCorporateAddress;
		}

		if (Object.keys(requestData).length > 0) {
			dispatch(putClient({ user_id: currentClient?.id, requestData }));
			dispatch(setInputChange(false));
		}

		if (type === 'terminal') {
			dispatch(
				setTerminalClientsAfterPut({
					updatedUser: {
						organization_name: newClientCorporateName,
						organization_address: newClientCorporateAddress,
						contact_email: newClientEmail,
						password: newClientPassword,
					},
					user_id: currentClient.id,
				}),
			);
		} else {
			dispatch(
				setClientListAfterPut({
					updatedUser: {
						organization_name: newClientCorporateName,
						organization_address: newClientCorporateAddress,
						contact_email: newClientEmail,
						password: newClientPassword,
					},
					user_id: currentClient.id,
				}),
			);
		}
	};

	//! Сброс изменений
	const handlePutBaseClient = () => {
		clearErrors();
		dispatch(setCurrentClientInitial());
	};

	// ! Если нет номера контракта кнопки принять и отклонить недоступны
	useEffect(() => {
		if (contractsList?.some((obj) => obj.contract_type === 'Договор')) {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
	}, [contractsList]);

	// ! Отслеживание изменений в инпутах
	useEffect(() => {
		dispatch(setNewClientCorporateAddress(currentClient?.organization_address));
		dispatch(setNewClientCorporateName(currentClient?.organization_name));
		dispatch(setNewClientEmail(currentClient?.contact_email));
		dispatch(setNewClientInn(currentClient?.inn));
		if (currentClient) {
			dispatch(getContractsList({ org_id: currentClient.id }));
		}
	}, [currentClient, dispatch]);

	//! Чтобы в инпутах отображались данные при переключении между вкладками, нужно сделать это
	useEffect(() => {
		setUseFormPlug(newClientCorporateName);
	}, [newClientCorporateName, newClientEmail, newClientCorporateAddress, dispatch]);

	// Функции для копирования информации из инпутов

	const handlerCopyUrName = () => {
		copy(newClientCorporateName);
	};

	const handlerCopyEmail = () => {
		copy(newClientEmail);
	};

	const handlerCopyAddress = () => {
		copy(newClientCorporateAddress);
	};

	const handlerCopyInn = () => {
		copy(newClientInn);
	};

	return (
		<>
			{confirmOrg_success && (
				<Snackbar
					open={openToast}
					onClose={handleClose}
					autoHideDuration={3000}
					anchorOrigin={!isDesktop ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'left' }}
				>
					<Alert severity="success" sx={{ width: '100%' }}>
						Данные клиента успешно обновлены
					</Alert>
				</Snackbar>
			)}
			<div className={styles.card__userInfo}>
				<form onSubmit={handleSubmit(handlePutClient)}>
					<div className={styles.card__blockInput}>
						<MainInput
							title={'Наименование юр. лица'}
							errorTitle={'Некорректный формат юр.лица'}
							name="urName"
							setValue={setValue}
							register={register}
							setFocus={setFocus}
							handleFieldChange={(e) => dispatch(setNewClientCorporateName(e.target.value))}
							value={newClientCorporateName ? newClientCorporateName : ''}
							errors={errors}
							setInputChange={(bool) => dispatch(setInputChange(bool))}
							rules={{
								required: 'Обязательное поле',
								minLength: {
									value: 5,
									message: 'Минимум 5 символов',
								},
							}}
							placeholder={'ПАО "Аэрофлот'}
							// disabled={!requireUIFunc({ name: 'ChangeOrgs', terminal_id: currentTerminalId })}
						/>
						<UniversalButton
							icon={<IconCopy2 color={'white'} size={'medium'} />}
							typeButton={'button'}
							size={'small'}
							color={'primary'}
							onClick={handlerCopyUrName}
						/>
					</div>
					<div className={styles.card__blockInput}>
						<MainInput
							title={'ИНН'}
							errorTitle={'Некорректный формат ИНН'}
							name="inn"
							setValue={setValue}
							handleFieldChange={(e) => dispatch(setNewClientInn(e.target.value))}
							value={newClientInn ? newClientInn : ''}
							register={register}
							setFocus={setFocus}
							errors={errors}
							setInputChange={(bool) => dispatch(setInputChange(bool))}
							rules={{
								required: 'Обязательное поле',
								minLength: {
									value: 10,
									message: 'Минимум 10 символов',
								},
							}}
							placeholder={'1234567890'}
						/>
						<UniversalButton
							icon={<IconCopy2 color={'white'} size={'medium'} />}
							typeButton={'button'}
							size={'small'}
							color={'primary'}
							onClick={handlerCopyInn}
						/>
					</div>
					<div className={styles.card__blockInput}>
						<MainInput
							title={'Электронная почта'}
							errorTitle={'Некорректный формат почты'}
							name="email"
							setValue={setValue}
							handleFieldChange={(e) => dispatch(setNewClientEmail(e.target.value))}
							value={newClientEmail ? newClientEmail : ''}
							register={register}
							setFocus={setFocus}
							errors={errors}
							setInputChange={(bool) => dispatch(setInputChange(bool))}
							rules={{
								required: 'Обязательное поле',
								pattern: {
									value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Пример: ivan.petrov@mail.ru',
								},
							}}
							placeholder={'ivan.petrov@mail.ru'}
							// disabled={!requireUIFunc({ name: 'ChangeOrgs', terminal_id: currentTerminalId })}
						/>
						<UniversalButton
							icon={<IconCopy2 color={'white'} size={'medium'} />}
							typeButton={'button'}
							size={'small'}
							color={'primary'}
							onClick={handlerCopyEmail}
						/>
					</div>
					<div className={styles.card__blockInput}>
						<MainInput
							title={'Юридический адрес'}
							errorTitle={'Некорректный формат юр. адреса'}
							name="urAdress"
							setValue={setValue}
							handleFieldChange={(e) => dispatch(setNewClientCorporateAddress(e.target.value))}
							value={newClientCorporateAddress ? newClientCorporateAddress : ''}
							errors={errors}
							setFocus={setFocus}
							register={register}
							setInputChange={(bool) => dispatch(setInputChange(bool))}
							rules={{
								required: 'Обязательное поле',
								minLength: {
									value: 5,
									message: 'Минимум 5 символов',
								},
							}}
							placeholder={'ул. Пушкина, д.31'}
							// disabled={!requireUIFunc({ name: 'ChangeOrgs', terminal_id: currentTerminalId })}
						/>
						<UniversalButton
							icon={<IconCopy2 color={'white'} size={'medium'} />}
							typeButton={'button'}
							size={'small'}
							color={'primary'}
							onClick={handlerCopyAddress}
						/>
					</div>
				</form>
			</div>

			<div className={styles.card__btns_stack}>
				<div>
					<div className={styles.card__btns}>
						{/* пермишен на удаление клиента/организации */}
						{/* <RequireUI type={{ name: 'DelOrgs', terminal_id: currentTerminalId }}>
							<UniversalButton
								icon={<IconTrash color={'white'} />}
								color={'error'}
								size={'small'}
								onClick={handleDeleteUser}
							/>
						</RequireUI> */}
						<UniversalButton icon={<IconTrash color={'white'} />} color={'error'} size={'small'} onClick={handleDeleteUser} />
					</div>
					<ChangeBtn
						inputChange={inputChange}
						handlePutBaseUser={handlePutBaseClient}
						setInputChange={setInputChange}
						handlePutUser={handlePutClient}
						errors={errors}
					/>
				</div>

				{type !== 'terminal' && (
					<div>
						{isCurrentClientConfirmed === 'Одобрен' ? (
							<StatusBtn
								icon={<IconCloseLock size={'largeS'} color={'primary_white'} />}
								status={'отклонить'}
								buttonText={'Отклонить'}
								bgColor={'error'}
								handlePostClient={handleConfirmClient}
							/>
						) : isCurrentClientConfirmed === 'Отклонен' ? (
							<StatusBtn
								icon={<IconOpenLock size={'largeS'} color={'primary_white'} />}
								status={'одобрить'}
								buttonText={'Одобрить'}
								bgColor={'succesful'}
								handlePostClient={handleConfirmClient}
							/>
						) : (
							<>
								<StatusBtn
									icon={<IconOpenLock size={'largeS'} color={'primary_white'} />}
									disabled={disabledBtn ? true : false}
									status={'одобрить'}
									buttonText={'Одобрить'}
									bgColor={'succesful'}
									handlePostClient={handleConfirmClient}
								/>
								<StatusBtn
									icon={<IconCloseLock size={'largeS'} color={'primary_white'} />}
									disabled={disabledBtn ? true : false}
									status={'отклонить'}
									buttonText={'Отклонить'}
									bgColor={'error'}
									handlePostClient={handleConfirmClient}
								/>
							</>
						)}
					</div>
				)}
			</div>
		</>
	);
}

export default ModalClientInputs;
