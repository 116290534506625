import styles from "./Step.module.scss";
import { Form, UniversalButton } from "../../../../../components";
import { useState } from "react";
import MainInput from "../../../../../components/Inputs/MainInput/MainInput";

function Step1({ setPage }) {

  const onSubmit = (data) => {
    localStorage.setItem("numberContract", data.numberContract)
    setPage((currentPage) => currentPage + 1);
  };

  const [numberContract, setNumberContract] = useState(localStorage.getItem("numberContract") || "")

  return (
    <div className={styles.block}>
      <h5>Новый клиент</h5>
      <Form onSubmit={onSubmit}>
        <MainInput
          title={'Номер договора'}
          errorTitle={'Некорректный номера договора'}
          name="numberContract"
          value={numberContract}
          setValue={setNumberContract}
          rules={{
            required: "Обязательное поле",
            minLength: {
              value: 5,
              message: "Минимум 5 символов",
            },
          }}
          placeholder={'12345'}
        />
        <div className={styles.block__btns}>
          <UniversalButton label={"Продолжить"} color={"primary"} size={"large"} typeButton={"submit"} />
        </div>
      </Form>
    </div>
  );
}

export default Step1;
