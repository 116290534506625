import React, { useEffect, useRef, useState } from 'react';
import styles from './ChangeContainerForm.module.scss';
import UniversalButton from '../../Buttons/UniversalButton/UniversalButton';
import UniversalSelect from '../AddContainers/UniversalSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
	getLines,
	putContainer,
	setChoosenContainerForChangeSaveVariant,
	setContainersToAddInOrderAfterPut,
	setCurrentOrderContainerList,
	setOrganizationContainerListAfterPut,
} from '../../../store/slices/orderSlice';
import { useForm } from 'react-hook-form';
import MainInput from '../../Inputs/MainInput/MainInput';
import Switcher from '../../Switcher/Switcher';
import { useParams } from 'react-router-dom';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../store/slices/sideBarSlice';
export default function ChangeContainerForm({ type }) {
	//* type:
	// - export_before_organization_containers (Список контейнеров организации при создании заявки на вывоз)
	// - export_before_order_containers (Список контейнеров добавленных к заявку на вывоз в процессе создания заявки)
	// - import_before_order_containers (Список созданных контейнеров в заявке на ввоз в процессе создания)
	// - import_after_order_containers (Список контейнеров в заявке на ввоз (уже созданной))
	// - export_after_organization_containers (Список контейнеров организации при создании заявки на вывоз)
	// - export_after_order_containers (Список контейнеров добавленных к заявку на вывоз в процессе создания заявки)

	const [resetForm, setResetForm] = useState();

	const {
		lineList,
		allSizes,
		allTypes,
		currentOrderData,
		newOrder_terminal_id,
		choosenContainerForChangeCancelVariant,
		choosenContainerForChangeSaveVariant,
		putContainer_success,
	} = useSelector((state) => state.order);

	const { register, handleSubmit, unregister, setFocus, formState, setValue, clearErrors, reset } = useForm({});
	const { errors } = formState;

	const { terminalId, orderId } = useParams();
	const dispatch = useDispatch();

	//* Кастомный хук
	const useUpdateEffect = (effect, deps) => {
		const isInitialMount = useRef(true);
		useEffect(() => {
			if (isInitialMount.current) {
				isInitialMount.current = false;
			} else {
				return effect();
			}
		}, deps);
	};

	const onCheckedDamage = (e) => {
		dispatch(setChoosenContainerForChangeSaveVariant({ type: 'is_damaged', data: e }));
	};
	const onCheckedDanger = (e) => {
		dispatch(setChoosenContainerForChangeSaveVariant({ type: 'is_dangerous', data: e }));
	};

	const handleSaveChanges = () => {
		let variableTerminalId;
		if (
			type === 'import_before_order_containers' ||
			type === 'export_before_order_containers' ||
			type === 'export_before_organization_containers'
		) {
			variableTerminalId = newOrder_terminal_id;
		}
		if (
			type === 'import_after_order_containers' ||
			type === 'export_after_order_containers' ||
			type === 'export_after_organization_containers'
		) {
			variableTerminalId = terminalId;
		}
		dispatch(
			putContainer({
				terminal_id: variableTerminalId,
				container_id: choosenContainerForChangeSaveVariant.id,
				requestData: {
					container_number: choosenContainerForChangeSaveVariant.container_number,
					booking: choosenContainerForChangeSaveVariant.booking,
					size: choosenContainerForChangeSaveVariant.size.id,
					line: choosenContainerForChangeSaveVariant.line.id,
					type: choosenContainerForChangeSaveVariant.type.id,
					is_dangerous: choosenContainerForChangeSaveVariant.is_dangerous,
					is_damaged: choosenContainerForChangeSaveVariant.is_damaged,
				},
			}),
		);
	};
	const handleCancelChanges = () => {
		dispatch(setChoosenContainerForChangeSaveVariant({ type: 'line', data: choosenContainerForChangeCancelVariant.line_display }));
		dispatch(setChoosenContainerForChangeSaveVariant({ type: 'type', data: choosenContainerForChangeCancelVariant.type_display }));
		dispatch(setChoosenContainerForChangeSaveVariant({ type: 'size', data: choosenContainerForChangeCancelVariant.size_display }));
		dispatch(
			setChoosenContainerForChangeSaveVariant({ type: 'is_dangerous', data: choosenContainerForChangeCancelVariant.is_dangerous }),
		);
		dispatch(setChoosenContainerForChangeSaveVariant({ type: 'is_damaged', data: choosenContainerForChangeCancelVariant.is_damaged }));
		dispatch(
			setChoosenContainerForChangeSaveVariant({
				type: 'container_number',
				data: choosenContainerForChangeCancelVariant.container_number,
			}),
		);
		dispatch(
			setChoosenContainerForChangeSaveVariant({
				type: 'booking',
				data: choosenContainerForChangeCancelVariant.booking,
			}),
		);
	};

	const handleSelectedOptionChange = (key, selectedOption) => {
		dispatch(setChoosenContainerForChangeSaveVariant({ type: [key], data: selectedOption }));
	};
	useUpdateEffect(() => {
		if (type === 'export_before_order_containers' || type === 'import_before_order_containers') {
			dispatch(setContainersToAddInOrderAfterPut(choosenContainerForChangeSaveVariant));
		}
		if (type === 'export_after_order_containers' || type === 'import_after_order_containers') {
			dispatch(setCurrentOrderContainerList(choosenContainerForChangeSaveVariant));
		}
		if (type === 'export_before_organization_containers' || type === 'export_after_organization_containers') {
			dispatch(setOrganizationContainerListAfterPut(choosenContainerForChangeSaveVariant));
		}
		dispatch(setSideBarActive(false));
		dispatch(setCurrentElementForSideBar(null));
	}, [putContainer_success]);

	useEffect(() => {
		dispatch(getLines());
	}, []);

	return (
		<>
			<div className={styles.addContainers__mainBlock}>
				<div>
					<div className={styles.addContainers__header}>
						<div className={styles.topTitle_btn}>
							<h4 className={styles.addContainers__title}>
								Изменить контейнер № {choosenContainerForChangeCancelVariant?.container_number}
							</h4>
						</div>
						<p className={styles.addContainers__subtitle}>Вы можете изменить данные контейнера</p>
					</div>
					<div className={styles.addContainers__body}>
						<div className={styles.addContainers__form}>
							<div className={styles.typeSizeBlock}>
								<UniversalSelect
									resetForm={resetForm}
									setResetForm={setResetForm}
									placeholder={'MSC'}
									nameCreateOrder="lineChange"
									label="Линия"
									options={lineList}
									elementName="true"
									selectedOption={choosenContainerForChangeSaveVariant.line.line_name}
									setSelectedOption={(e) => dispatch(setChoosenContainerForChangeSaveVariant({ type: 'line', data: e }))}
									onSelectedOptionChange={(value) => handleSelectedOptionChange('line', value)}
									setFocus={setFocus}
									register={register}
									errors={errors}
									setValue={setValue}
								/>
								<UniversalSelect
									resetForm={resetForm}
									placeholder={'DV'}
									setResetForm={setResetForm}
									nameCreateOrder="typeChange"
									label="Тип"
									options={allTypes}
									selectedOption={choosenContainerForChangeSaveVariant.type.name}
									setSelectedOption={(e) => dispatch(setChoosenContainerForChangeSaveVariant({ type: 'type', data: e }))}
									onSelectedOptionChange={(value) => handleSelectedOptionChange('type', value)}
									setFocus={setFocus}
									register={register}
									errors={errors}
									setValue={setValue}
									clearErrors={clearErrors}
								/>
								<UniversalSelect
									resetForm={resetForm}
									setResetForm={setResetForm}
									placeholder={'40'}
									nameCreateOrder="sizeChange"
									label="Размер"
									options={allSizes}
									selectedOption={choosenContainerForChangeSaveVariant.size.name}
									setSelectedOption={(e) => dispatch(setChoosenContainerForChangeSaveVariant({ type: 'size', data: e }))}
									onSelectedOptionChange={(value) => handleSelectedOptionChange('size', value)}
									setFocus={setFocus}
									register={register}
									errors={errors}
									setValue={setValue}
								/>
							</div>
							<div className={styles.form__switch}>
								<div>
									<p>Контейнер поврежден</p>
									<Switcher
										externalChecked={choosenContainerForChangeSaveVariant.is_damaged}
										onChecked={onCheckedDamage}
									/>
								</div>
								<div>
									<p>Опасный груз</p>
									<Switcher
										externalChecked={choosenContainerForChangeSaveVariant.is_dangerous}
										onChecked={onCheckedDanger}
									/>
								</div>
							</div>
							<div className={styles.form__input}>
								<div id={'input_container_change'}>
									<div className={styles.container_number_wrapper}>
										<div className={styles.container_number_input_wrapper}>
											<div className={styles.container_number_input_main}>
												<MainInput
													title={'Букинг'}
													errorTitle="Некорректный текст"
													name={'booking_change'}
													setFocus={setFocus}
													setResetForm={setResetForm}
													register={register}
													errors={errors}
													value={choosenContainerForChangeSaveVariant?.booking}
													setValue={(value) =>
														dispatch(
															setChoosenContainerForChangeSaveVariant({
																type: 'booking',
																data: value,
															}),
														)
													}
													rules={{}}
													placeholder={'Текст'}
												/>
											</div>
										</div>
									</div>
									<div className={styles.container_number_wrapper}>
										<div className={styles.container_number_input_wrapper}>
											<div className={styles.container_number_input_main}>
												<MainInput
													title={`Номер контейнера`}
													errorTitle="Некорректный формат номера"
													name={`container_number_change`}
													setFocus={setFocus}
													setResetForm={setResetForm}
													register={register}
													errors={errors}
													value={choosenContainerForChangeSaveVariant?.container_number}
													setValue={(value) =>
														dispatch(
															setChoosenContainerForChangeSaveVariant({
																type: 'container_number',
																data: value,
															}),
														)
													}
													rules={{
														required: 'Введите номер контейнера',
														minLength: { value: 4, message: 'Минимум 4 символа' },
													}}
													mask={'*********************'}
													placeholder="Например: FHD 4749164 6"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.addContainers__footer}>
					<UniversalButton
						label="Cбросить изменения"
						typeButton="button"
						onClick={handleCancelChanges}
						size="small"
						color="primary"
						type="with-border"
					/>

					<UniversalButton label="Сохранить" typeButton="button" onClick={handleSaveChanges} size="small" color="primary" />
				</div>
			</div>
		</>
	);
}
