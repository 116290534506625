import React from 'react';
import styles from './Step3.module.scss';
import { UniversalButton } from '../../../../../../../../components';
import { IconPlus } from '../../../../../../../../components/Icons';
import { useSelector } from 'react-redux';
import DamageCard from '../../../../ReportContainers/components/DamageList/components/DamageCard';

const Card = ({ title, type, description }) => {
	return (
		<div className={styles.card}>
			<div className={styles.cardHeader}>
				<span>{title}</span>
				<span>{type}</span>
			</div>
			<div className={styles.description}>{description}</div>
		</div>
	);
};

const Step3 = () => {
	const { damagesList } = useSelector((state) => state.containerAccept);
	// const fakeData = [
	// 	{
	// 		title: 'Левая сторона - Боковая панель - T1',
	// 		type: 'Деформация',
	// 		description: 'Комментарий приемосдатчика Комментарий приемосдатчика Комментарий приемосдатчика Комментарий приемосдатчика ',
	// 	},
	// 	{
	// 		title: 'Левая сторона - Боковая панель - T1',
	// 		type: 'Деформация',
	// 		description: 'Комментарий приемосдатчика',
	// 	},
	// 	{
	// 		title: 'Левая сторона - Боковая панель - T1',
	// 		type: 'Деформация',
	// 		description: 'Комментарий приемосдатчика',
	// 	},
	// 	{
	// 		title: 'Левая сторона - Боковая панель - T1',
	// 		type: 'Деформация',
	// 		description: 'Комментарий приемосдатчика',
	// 	},
	// 	{
	// 		title: 'Левая сторона - Боковая панель - T1',
	// 		type: 'Деформация',
	// 		description: 'Комментарий приемосдатчика',
	// 	},
	// 	{
	// 		title: 'Левая сторона - Боковая панель - T1',
	// 		type: 'Деформация',
	// 		description: 'Комментарий приемосдатчика',
	// 	},
	// 	{
	// 		title: 'Левая сторона - Боковая панель - T1',
	// 		type: 'Деформация',
	// 		description: 'Комментарий приемосдатчика',
	// 	},
	// 	{
	// 		title: 'Левая сторона - Боковая панель - T1',
	// 		type: 'Деформация',
	// 		description: 'Комментарий приемосдатчика',
	// 	},
	// 	{
	// 		title: 'Левая сторона - Боковая панель - T1',
	// 		type: 'Деформация',
	// 		description: 'Комментарий приемосдатчика',
	// 	},
	// 	{
	// 		title: 'Левая сторона - Боковая панель - T1',
	// 		type: 'Деформация',
	// 		description: 'Комментарий приемосдатчика',
	// 	},
	// ];
	return (
		<div className={styles.stepContainer}>
			<p className={styles.title}>Повреждения</p>
			<div className={styles.cardContainer}>
				{damagesList.map((el, index) => (
					<DamageCard key={index} onClick={() => console.log('')} damage={el} />
					// <Card key={index} title={el.title} type={el.type} description={el.description} />
					// onClick={() => handleOpenDamagePage(item.id)}
				))}
			</div>
			<div className={styles.actionButton}>
				<UniversalButton label={'Принять другой контейнер'} color={'accent'} size={'medium'} icon={<IconPlus color={'white'} />} />
			</div>
		</div>
	);
};

export default Step3;
