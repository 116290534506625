import React from 'react';
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconFilter({ size, color }) {
  return (
    <div className={styles.icon}>
      <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none">
        <path d="M22 3L22.5727 3.48429C22.7612 3.26134 22.8032 2.94922 22.6803 2.68434C22.5574 2.41947 22.292 2.25 22 2.25V3ZM2 3V2.25C1.70801 2.25 1.44256 2.41947 1.31966 2.68434C1.19677 2.94922 1.23877 3.26134 1.42732 3.48429L2 3ZM10 12.46H10.75C10.75 12.2827 10.6872 12.1111 10.5727 11.9757L10 12.46ZM10 19H9.25C9.25 19.2841 9.4105 19.5438 9.66459 19.6708L10 19ZM14 21L13.6646 21.6708C13.8971 21.7871 14.1732 21.7746 14.3943 21.638C14.6154 21.5013 14.75 21.2599 14.75 21H14ZM14 12.46L13.4273 11.9757C13.3128 12.1111 13.25 12.2827 13.25 12.46H14ZM22 2.25H2V3.75H22V2.25ZM1.42732 3.48429L9.42732 12.9443L10.5727 11.9757L2.57268 2.51571L1.42732 3.48429ZM9.25 12.46V19H10.75V12.46H9.25ZM9.66459 19.6708L13.6646 21.6708L14.3354 20.3292L10.3354 18.3292L9.66459 19.6708ZM14.75 21V12.46H13.25V21H14.75ZM14.5727 12.9443L22.5727 3.48429L21.4273 2.51571L13.4273 11.9757L14.5727 12.9443Z" fill={IconColor(color)} />
      </svg>
    </div>

  );
}

export default IconFilter;




