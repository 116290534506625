import { useEffect, useRef, useState } from 'react';
import styles from './AddContainers.module.scss';
import SelectLaptop from '../../Select/SelectLaptop';
import MainInput from '../../Inputs/MainInput/MainInput';

function UniversalSelect({
	label,
	nameCreateOrder,
	options,
	elementName,
	onSelectedOptionChange,
	resetForm,
	setResetForm,
	placeholder,
	register,
	setFocus,
	errors,
	setValue,
	noRequired,
	selectedOption,
	setSelectedOption,
	multiSelect,
}) {
	// открытие модального окна списка
	const [showModal, setShowModal] = useState(false);
	// const [selectedOption, setSelectedOption] = useState('');
	const [selectedId, setSelectedId] = useState('');

	const handleOpenModal = () => {
		setShowModal(!showModal);
	};

	// закрытие списка при открытии другого
	const inputSelectRef = useRef(null);

	const closeDropdown = (event) => {
		if (inputSelectRef.current && !inputSelectRef.current.contains(event.target)) {
			setShowModal(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', closeDropdown);

		return () => {
			document.removeEventListener('mousedown', closeDropdown);
		};
	}, []);

	// функция при изменении selectedOption
	useEffect(() => {
		if (resetForm) {
			setSelectedOption('');
			setResetForm(false);
		}
		onSelectedOptionChange(selectedOption);
	}, [selectedOption]);

	return (
		<div className={styles.form__select}>
			<div ref={inputSelectRef}>
				<MainInput
					title={label}
					type={'selectInput'}
					name={nameCreateOrder}
					errorTitle="Выберите"
					register={register}
					errors={errors}
					setFocus={setFocus}
					//! Тут вопрос. Было закоменченное, поставил ниже, может нужно вернуть обранто
					// setValue={setSelectedOption}
					setValue={setValue}
					value={selectedOption ? selectedOption : ''}
					rules={{ required: noRequired ? '' : selectedOption ? false : 'Обязательное поле', minLength: { value: 1 } }}
					placeholder={placeholder}
					modalDropList={showModal}
					onClick={handleOpenModal}
				/>
				{showModal && (
					<SelectLaptop
						options={options}
						errors={errors}
						name={nameCreateOrder}
						selectedOption={selectedOption}
						setSelectedOption={setSelectedOption}
						setSelectedId={setSelectedId}
						setShowModal={setShowModal}
						elementName={elementName}
						multiSelect={multiSelect}
					/>
				)}
			</div>
		</div>
	);
}

export default UniversalSelect;
