export const cardComponent = {
	wrapper: {
		border: '2px solid',
		borderRadius: '16px',
		borderColor: (theme) => theme.palette.border.primary,
		boxShadow: 'none',
		backgroundImage: 'none',
		transition: '0.3s ease',
		width: '100%',

		'&:hover': {
			borderColor: (theme) => theme.palette.border.primaryHover,
			cursor: 'pointer',
			boxShadow: '0px 0px 6px #443a8e80',
		},

		'&:focus': {
			borderColor: (theme) => theme.palette.border.primaryFocus,
		},

		'&:disabled': {
			borderColor: (theme) => theme.palette.border.primaryDisabled,
		},
	},
	selected: {
		borderColor: (theme) => theme.palette.border.accent,

		'&:hover': {
			borderColor: (theme) => theme.palette.border.accentHover,
		},
	},
	content: { display: 'flex', flexDirection: 'row', padding: '8px !important', gap: '4px', alignItems: 'flex-start' },
	container: { display: 'flex', flexDirection: 'row', gap: '8px', width: '100%' },
	avatarInitials: {
		color: (theme) => theme.palette.text.primary,
		backgroundColor: '#7e2a2c',
		borderRadius: '12px',
		minWidth: '56px',
		height: '56px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '24px',
	},
	descr: { display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' },
	box: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: '4px',
		width: '100%',
		minWidth: '0',
		color: (theme) => theme.palette.text.secondary,
	},
	stack: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		gap: '4px',
		color: (theme) => theme.palette.text.secondary,
	},
	textOverflow: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		flexGrow: 1,
		minWidth: 0,
	},
	info: {
		display: 'flex',
		alignItems: 'center',
		gap: '4px',
	},

	infoRight: {
		display: 'flex',
		alignItems: 'center',
		gap: '4px',
		justifyContent: 'flex-end',
	},

	// Статусы карточки
	successful: {
		color: (theme) => theme.palette.text.successful,
	},
	warning: {
		color: (theme) => theme.palette.text.warning,
	},
	error: {
		color: (theme) => theme.palette.text.error,
	},
	blue: {
		color: (theme) => theme.palette.text.blue,
	},
};
