import styles from "./Header.module.scss";
import { useMediaQuery } from "react-responsive";


function Header({ text, btnDesktop, btnMobile, step }) {

    // отслеживание ширины экрана
    const isDesktop = useMediaQuery({ minWidth: 320.9 });

    return (
        <div className={styles.header}>
            <p className={styles.header__title}>{text}</p>
            <div className={styles.header__action}>
                {step &&
                    <p className={styles.header__step}>Шаг {step}</p>}
                {isDesktop ? (
                    <>
                        {btnDesktop && btnDesktop}
                    </>
                ) : (
                    <>
                        {btnMobile && btnMobile}
                    </>
                )}
            </div>
        </div>
    );
}

export default Header;
