import React, { useEffect } from 'react';
import styles from './Step1.module.scss';
import { IconDanger, IconPlus, IconWarningTool } from '../../../../../../../../components/Icons';
import { UniversalButton } from '../../../../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getOneEntryreport, setPage } from '../../../../../../../../store/slices/containerAcceptanceSlice';
import { useNavigate, useParams } from 'react-router-dom';

const Step1 = () => {
	const { entryOneReport } = useSelector((state) => state.containerAccept);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { actId } = useParams();
	// Создание объекта Date из строки
	const dateFormatter = (dateToFormate) => {
		const date = new Date(dateToFormate);

		// Определение названий месяцев
		const months = [
			'января',
			'февраля',
			'марта',
			'апреля',
			'мая',
			'июня',
			'июля',
			'августа',
			'сентября',
			'октября',
			'ноября',
			'декабря',
		];

		// Получение компонентов даты
		const day = date.getUTCDate();
		const month = months[date.getUTCMonth()];
		const year = date.getUTCFullYear();
		const hours = String(date.getUTCHours()).padStart(2, '0');
		const minutes = String(date.getUTCMinutes()).padStart(2, '0');

		// Формирование итоговой строки
		const formattedDate = `${day} ${month} ${year} ${hours}:${minutes}`;

		return formattedDate;
	};

	const handleAddAnotherContainer = () => {
		navigate('/accountEmpl/containerAcceptance');
		dispatch(setPage(2));
	};

	useEffect(() => {
		dispatch(getOneEntryreport({ actId }));
	}, []);

	return (
		<div className={styles.stepContainer}>
			<p className={styles.title}>Основная информация</p>
			<div className={styles.stepContainer_info}>
				<div className={styles.actCard}>
					<p className={styles.actCard_title}>Акт №{entryOneReport?.id}</p>
					<p className={styles.actCard_date}>Дата, время осмотра: {dateFormatter(entryOneReport?.created_at)}</p>
					<p className={styles.actCard_status}>Статус акта: {entryOneReport?.status_display}</p>
				</div>
				<div className={styles.passCard}>
					<div className={styles.passCard_top}>
						<p>Пропуск №{entryOneReport?.container?.entrypass[0].id}</p>
					</div>
					<div className={styles.passCard_bottom}>
						<p>Охранник: ??</p>
						<p>
							Дата, время въезда:{' '}
							{entryOneReport?.container?.entrypass[0].chekin_at
								? dateFormatter(entryOneReport?.container?.entrypass[0].chekin_at)
								: '-'}
						</p>
					</div>
				</div>
				<div className={styles.autoCard}>
					<div className={styles.autoCard_top}>
						<p>Автомобиль: {entryOneReport?.container?.entrypass[0].vehicle_display}</p>
					</div>
					<div className={styles.autoCard_bottom}>
						<p>
							Прицеп:{' '}
							{entryOneReport?.container?.entrypass[0].vehicle_trailer_number
								? entryOneReport?.container?.entrypass[0].vehicle_trailer_number
								: '-'}
						</p>
						<p>Водитель: {entryOneReport?.container?.entrypass[0].vehicle_driver}</p>
					</div>
				</div>
				<div className={styles.transceiverCard}>
					<div className={styles.transceiverCard_top}>
						<p>Приемосдатчик</p>
					</div>
					<div className={styles.transceiverCard_bottom}>
						<p>Водитель: {entryOneReport?.container?.entrypass[0].vehicle_driver}</p>
					</div>
				</div>
				<div className={styles.containerCard}>
					<div className={styles.containerCard_top}>
						<p>Контейнер № {entryOneReport?.container?.container_number}</p>
					</div>
					<div className={styles.containerCard_bottom}>
						<div className={styles.containerCard_bottom_info}>
							<p>{entryOneReport?.container?.type_display}</p>
							<p>{entryOneReport?.container?.size_display}</p>
							<p>{entryOneReport?.container?.line_display}</p>
						</div>
						<div className={styles.containerCard__details}>
							{entryOneReport?.container?.is_dangerous && (
								<div className={styles.details__danger}>
									<IconDanger size={'small'} color={'white'} />
								</div>
							)}
							{entryOneReport?.container?.is_damaged && (
								<div className={styles.details__damage}>
									<IconWarningTool size={'small'} color={'white'} />
								</div>
							)}
						</div>
					</div>
				</div>
				<div className={styles.clientCard}>
					<div className={styles.clientCard_top}>
						<p>Клиент {entryOneReport?.client?.organization_name}</p>
					</div>
					<div className={styles.clientCard_bottom}>
						<p>Договор ?? от ??</p>
					</div>
				</div>
			</div>
			<div className={styles.actionButton}>
				<UniversalButton
					label={'Принять другой контейнер'}
					color={'accent'}
					size={'medium'}
					onClick={handleAddAnotherContainer}
					icon={<IconPlus color={'white'} />}
				/>
			</div>
		</div>
	);
};

export default Step1;
