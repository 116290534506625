import api from '../../api.js';

export const logs = api.injectEndpoints({
	endpoints: (builder) => ({
		getLogsList: builder.query({
			query: ({ page, page_size, sorting }) => {
				const params = { page, page_size };

				const sortParam = sorting?.length ? sorting.map(({ id, desc }) => (desc ? `-${id}` : id)).join(',') : null;

				if (sortParam) {
					params.ordering = sortParam;
				}
				return {
					url: '/api/logs/list/',
					params,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetLogsListQuery, useLazyGetLogsListQuery } = logs;
