// генерация инициалов и цвета аватарок пользователей

function getInitialsCLient(name) {
    if (name) {
        const matches = name.match(/[a-zA-Zа-яА-Я]/g);
        return matches.slice(0, 2).join('').toUpperCase();
    }

}

function getInitials(name) {
    if (name) {

        let counter = 1;
        name = name.replace(/[\s]+/gim, ' ');
        name.replace(/(\s+)/g, () => {
            counter++;
        });

        if (counter === 1) {
            return `${name.split(" ")[0][0].toUpperCase()}`;
        } else {
            return `${name.split(" ")[0][0]}${name.split(" ")[1][0].toUpperCase()}`;
        }

    }

}

function generateBackground(name) {
    if (name) {
        // цвета
        const colors = ["#7B2949", "#64307B", "#50378A", "#004787", "#005230", "#7B2F0A", "#7E2A2C", "#7B2948"];

        let hash = 0;

        for (let i = 0; i < name.length; i += 1) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }

        const randomNumber = Math.abs(hash) % 100 / 100;
        const index = Math.floor(randomNumber * colors.length);

        return colors[index];
    } else {
        return "#7B2949";
    }
}

export { getInitials, generateBackground, getInitialsCLient };