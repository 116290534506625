import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconBadge({ size, onClick, color, className }) {
	return (
		<div onClick={onClick} className={`${styles.icon} ${className}`}>
			<svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 30 31" fill="none">
				<path
					d="M14.9999 27.5741H22.4999C23.163 27.5741 23.7989 27.3107 24.2677 26.8419C24.7365 26.373 24.9999 25.7371 24.9999 25.0741V8.8241L18.7499 2.5741H7.49994C6.8369 2.5741 6.20101 2.83749 5.73217 3.30633C5.26333 3.77517 4.99994 4.41106 4.99994 5.0741V8.8241"
					stroke={IconColor(color)}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M17.4999 2.5741V7.5741C17.4999 8.23714 17.7633 8.87302 18.2322 9.34186C18.701 9.8107 19.3369 10.0741 19.9999 10.0741H24.9999"
					stroke={IconColor(color)}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M6.24994 21.3241C6.7424 21.3241 7.23003 21.2271 7.685 21.0386C8.13997 20.8502 8.55337 20.574 8.90159 20.2257C9.24981 19.8775 9.52603 19.4641 9.71449 19.0092C9.90294 18.5542 9.99994 18.0666 9.99994 17.5741C9.99994 17.0816 9.90294 16.594 9.71449 16.139C9.52603 15.6841 9.24981 15.2707 8.90159 14.9224C8.55337 14.5742 8.13997 14.298 7.685 14.1095C7.23003 13.9211 6.7424 13.8241 6.24994 13.8241C5.25538 13.8241 4.30155 14.2192 3.59829 14.9224C2.89503 15.6257 2.49994 16.5795 2.49994 17.5741C2.49994 18.5687 2.89503 19.5225 3.59829 20.2257C4.30155 20.929 5.25538 21.3241 6.24994 21.3241Z"
					stroke={IconColor(color)}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8.74994 20.6991L9.99994 27.5741L6.24994 26.3241L2.49994 27.5741L3.74994 20.6991"
					stroke={IconColor(color)}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}

export default IconBadge;
