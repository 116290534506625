import React, { useState } from 'react';
import styles from './InfoBlocks.module.scss';
import { IconArrowDown } from '../../../Icons';

function SmallInfoBlock({ title, key1, value1, key2, value2, key3, value3, moreInfoContainer }) {
	// Состояние для отображения дополнительной информации
	const [moreInfo, setMoreInfo] = useState(false);

	return (
		<div className={styles.mainBlock}>
			{/* Заголовок блока */}
			<p className={styles.title}>{title}</p>
			<div className={styles.smallInfo}>
				{/* Первая строка информации */}
				<div className={styles.lineInfo}>
					{key1}:<p>{value1}</p>
				</div>
				{/* Вторая строка информации */}
				<div className={styles.lineInfo}>
					{key2}:<p>{value2}</p>
				</div>
				{/* Дополнительная информация */}
				{moreInfo && (
					<div className={styles.moreInfoContainer}>
						<div>
							<div className={styles.lineInfo}>
								20-футовые: <p>{moreInfoContainer[0].Ph20}</p>
							</div>
							<div className={styles.lineInfo}>
								40-футовые: <p>{moreInfoContainer[0].Ph40}</p>
							</div>
						</div>
						<div>
							<div className={styles.lineInfo}>
								Стандартные (DV): <p>{moreInfoContainer[1].standart}</p>
							</div>
							<div className={styles.lineInfo}>
								Высокие (HC): <p>{moreInfoContainer[1].height}</p>
							</div>
						</div>
					</div>
				)}
				{/* Если есть третья строка информации */}
				{key3 || value3 ? (
					<div className={styles.lineInfo}>
						{key3}:<p>{value3}</p>
					</div>
				) : (
					// Если нет третьей строки информации, отображается кнопка для раскрытия дополнительной информации
					<div onClick={() => setMoreInfo(!moreInfo)} className={`${styles.moreInfo} ${moreInfo ? styles.arrowMoreInfo : ''}`}>
						{moreInfo ? 'Скрыть' : 'Больше информации'}
						<IconArrowDown color={'black'} />
					</div>
				)}
			</div>
		</div>
	);
}

export default SmallInfoBlock;
