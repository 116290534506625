import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { postEntryReportsApi } from '../../api/entryreports/postEntryReportsApi';
import { getEntryReportsApi } from '../../api/entryreports/getEntryReportsApi';
import { getOneEntryReportApi } from '../../api/entryreports/getOneEntryReportApi';
import { putEntryReportsApi } from '../../api/entryreports/putEntryReportsApi';
import { getTerminalContainersApi } from '../../api/containers/getTerminalContainers';
import { getVehiclesApi } from '../../api/vehicle/getVehiclesApi';
import { getContainersOnTerminalApi } from '../../api/containers/getContainersOnTerminalApi';
import { getAllContainersList } from '../../api/containers/getAllContainersList';
import { postVehicleApi } from '../../api/vehicle/postVehicleApi';
import { putOneContainerApi } from '../../api/containers/putOneContainerApi';
import S3Manager from '../../helpers/s3storage';
import { v4 as uuidv4 } from 'uuid';
import { detailCodeToName } from '../../pages/Account/AccountEmpl/components/ReportContainers/steps/ChoiceDamageArea/components/ContainerMap/Sides/helpers/detailCodeToName';
import { putEntries } from '../../api/entries/putEntries';
const data = require('../../assets/damages1.json');

export const getOneEntryreport = createAsyncThunk('entryreports/getOneEntryreport', async ({ actId }, { rejectWithValue }) => {
	try {
		const response = await getOneEntryReportApi(actId);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const getEntryreports = createAsyncThunk('entryreports/getEntryreports', async ({ isConfirmed }, { rejectWithValue, getState }) => {
	const state = getState();
	try {
		let response;
		if (state.containerAccept.reportsNext && state.containerAccept.reportsFetching) {
			response = await getEntryReportsApi(state.containerAccept.reportsNext, isConfirmed);
		} else if (state.containerAccept.reportsUrl && state.containerAccept.reportsFetching) {
			response = await getEntryReportsApi(state.containerAccept.reportsUrl, isConfirmed);
		}
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const postEntryreports = createAsyncThunk('entryreports/postEntryreports', async ({ requestData }, { rejectWithValue }) => {
	try {
		const response = await postEntryReportsApi(requestData);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const putEntryreports = createAsyncThunk('entryreports/putEntryreports', async ({ reportId, requestData }, { rejectWithValue }) => {
	try {
		const response = await putEntryReportsApi({ reportId, requestData });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const putDamagesInEntryreport = createAsyncThunk(
	'entryreports/putDamagesInEntryreport',
	async ({ reportId }, { rejectWithValue, getState }) => {
		try {
			const state = getState();
			const damagesListFromState = state.containerAccept.damagesList.filter((el) => el !== null);
			console.log(damagesListFromState, 'TOCHTONADO');

			// Преобразуем damagesFromState в нужный формат

			const requestData = {
				damages: damagesListFromState.map((damage) => {
					const isTypeOne = !!damage.damages; // Определяем тип объекта
					const placement = isTypeOne
						? damage.damages.map((dmg) => ({
								option: dmg.divider || dmg.option,
								parts: dmg.part || dmg.parts || [],
							}))
						: damage.placement.map((place) => ({
								option: place.option,
								parts: place.parts || [],
							}));

					const dimensions = isTypeOne
						? {
								l: damage.length || '',
								w: damage.width || '',
								d: damage.depth || '',
								comment: damage.comment || '',
							}
						: damage.dimensions;

					// let firstChar = damage?.damageName.charAt(0);
					// let upperFirstChar = firstChar.toUpperCase();
					// let restOfString = damage?.damageName.slice(1);

					const component = isTypeOne
						? [damage?.damageName.charAt(0).toUpperCase() + damage?.damageName.slice(1)]
						: damage.component;
					// const dimensions = {
					// 	h: damage.height || '',
					// 	w: damage.width || '',
					// 	l: damage.length || '',
					// 	comment: damage.comment || '',
					// };
					const localDamage = damage?.damageType || damage.damage[0];

					return {
						uuid: damage.uuid ? damage.uuid : uuidv4(),
						side: damage.side ? damage.side : damage.sideIndex,
						...state.currentSideDamage,
						component: component,
						placement: placement,
						pictures: damage.pictures.map((img) => ({
							filename: img.filename || img.name,
							url: img.url,
						})),
						dimensions: dimensions,
						damage: [{ name: localDamage.name, code: localDamage.code }],
					};
				}),
			};

			const response = await putEntryReportsApi({ reportId, requestData });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

export const getVehiclesForContainerAcceptance = createAsyncThunk(
	'containerAccept/getVehiclesForContainerAcceptance',
	async ({ number }, { rejectWithValue }) => {
		try {
			const url = number ? `number=${number}&page_size=5` : 'page_size=5';
			const response = await getVehiclesApi(url);
			if (!response.statusText === 'OK') {
				throw new Error('containerAccept/getVehiclesForContainerAcceptance. Не удалось загрузить данные');
			}
			console.log(response.data.results);
			return response.data.results;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

// Запрос на получение списка всех контейнеров
export const getContainersForContainerAcceptance = createAsyncThunk(
	'containerAccept/getContainersForContainerAcceptance',
	async ({ number }, { rejectWithValue, getState }) => {
		try {
			const url = number
				? `?container_number=${number}&page_size=5&order_type=1&report_available=true`
				: '?page_size=5&order_type=1&report_available=true';

			const response = await getAllContainersList(url);
			// const response = await getTerminalContainersApi(1);
			return response.data.results;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

export const postVehicle = createAsyncThunk('vehicle/postVehicle', async (_, { rejectWithValue, getState }) => {
	try {
		const state = getState();
		const formData = { registration_number: state.containerAccept.vehicleNumberContainerAcceptance };
		const response = await postVehicleApi(formData);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса vehicle/postNewVehicleAction. Не удалось добавить данные');
		}
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

//! Переход на следующую страницу
export const handleNextAsync = createAsyncThunk('containerAccept/handleNextAsync', async (params, { dispatch, getState }) => {
	const state = getState();
	const { page, currentSideDamage } = state.containerAccept;
	if (page === 6) {
		await dispatch(setDamagesList(currentSideDamage));
		await dispatch(putDamagesInEntryreport({ reportId: state.containerAccept.entryOneReport.id }));
		await dispatch(clearCurrentSideDamage());
		await localStorage.removeItem('uploadedImages');
		await dispatch(setTemporaryActiveBlocks([]));
		await dispatch(clearDamagedDetails());
		await dispatch(setDamageNameMaterial(''));
		await dispatch(setHandleNextButtonDisabled(true));
		dispatch(setPage(2));
	} else if (page === 4) {
		await dispatch(concatDamageNameAndMaterial());
		dispatch(setPage(5));
	} else {
		dispatch(setPage(page + 1));
	}
});

//! Добавление повреждений деталей и Очистка списка повреждений в случае выбора детали
export const setDetailsInsteadDamages = createAsyncThunk(
	'containerAccept/setDetailsInsteadDamages',
	async ({ sideIndex, sideName, type, divider, part, active, damageName }, { dispatch, getState }) => {
		try {
			console.log(sideIndex, sideName, type, divider, part, active, damageName, 'setDetailsInsteadDamages');
			const state = getState();
			await dispatch(clearChoosenDamageForChoosingDetail());
			await dispatch(setCurrentSideDetails({ sideIndex, sideName, type, divider, part, active, damageName }));
		} catch (error) {
			console.log(error);
		}
	},
);
// Редактирование одного контейнера
export const putOneContainer = createAsyncThunk(
	'containerAccept/putOneContainer',
	async ({ terminal_pk, container_pk, requestData }, { rejectWithValue }) => {
		try {
			const response = await putOneContainerApi(terminal_pk, container_pk, requestData);
			return response.data;
		} catch (error) {
			return rejectWithValue({ error: error.message, containerId: container_pk });
		}
	},
);

// Редактирование пропусков
export const putEntriesList = createAsyncThunk(
	'containerAccept/putEntries',
	async ({ terminal_id, order_id, requestData }, { rejectWithValue }) => {
		try {
			const response = await putEntries(terminal_id, order_id, requestData);
			return response.data;
		} catch (error) {
			return rejectWithValue({ error: error.message, reqData: requestData });
		}
	},
);

const containerAcceptance = createSlice({
	name: 'containerAccept',
	initialState: {
		page: 2,
		handleNextButtonDisabled: true,
		activeContainer: null,
		selectedSide: null,
		selectedCar: null,
		temporaryActiveBlocks: [],
		containerSides: data.container_sides,

		damagesList: [],
		currentSideDamage: {
			side: null,
			damages: [],
			pictures: [],
		},
		damagedDetails: [],
		activeBlockType: null,
		selectedDamage: null,

		reportId: null,

		entryOneReport: [], //один репорт
		entryReports: [], //все репорты

		// Infinite Scroll Reports
		reportsFetching: true,
		reportsUrl: `?page_size=30`,
		reportsNext: null,

		// получение всех репотов
		getEntryreports_success: false,
		getEntryreports_pending: false,
		getEntryreports_error: false,

		// получение одного репотв
		getOneEntryreport_success: false,
		getOneEntryreport_pending: false,
		getOneEntryreport_error: false,

		//создание репортов
		postEntryreports_success: false,
		postEntryreports_pending: false,
		postEntryreports_error: false,

		//изменение репортов
		putEntryreports_success: false,
		putEntryreports_pending: false,
		putEntryreports_error: false,

		// Получение контейнеров для выбора
		containerListForContainerAcceptance: [],
		getContainersForContainerAcceptance_success: false,
		getContainersForContainerAcceptance_pending: false,
		getContainersForContainerAcceptance_error: false,

		// Получение автомобилей для выбора
		vehicleListForContainerAcceptance: [],
		getVehiclesForContainerAcceptance_success: false,
		getVehiclesForContainerAcceptance_pending: false,
		getVehiclesForContainerAcceptance_error: false,

		// Первый шаг
		containerNumberContainerAcceptance: null,
		vehicleNumberContainerAcceptance: null,
		driverContainerAcceptance: null,
		grossContainerAcceptance: null,
		nettoContainerAcceptance: null,
		isFilledContainerAcceptance: false,

		changeVehicleNumberContainerAcceptance: null,
		changeDriverContainerAcceptance: null,

		sizeContainerAcceptance: '',
		sizes: [
			{ id: 1, name: '20' },
			{ id: 2, name: '40' },
			{ id: 3, name: '45' },
		],

		typeContainerAcceptance: '',
		types: [
			{ id: 1, name: 'DC' },
			{ id: 2, name: 'DV' },
			{ id: 3, name: 'HC' },
			{ id: 4, name: 'PW' },
			{ id: 5, name: 'OT' },
			{ id: 6, name: 'OT HC' },
			{ id: 7, name: 'RF' },
			{ id: 8, name: 'RF HC' },
			{ id: 9, name: 'FR' },
			{ id: 10, name: 'FR HC' },
			{ id: 11, name: 'TC' },
			{ id: 12, name: 'HR' },
		],

		// Создание автомобиля в случае его отсутствия
		postVehicle_success: false,
		postVehicle_pending: false,
		postVehicle_error: false,

		// Редактирование одного контейнера
		putOneContainer_success: false,
		putOneContainer_pending: false,
		putOneContainer_rejected: false,

		putDamagesInEntryreport_success: false,
		putDamagesInEntryreport_pending: false,
		putDamagesInEntryreport_rejected: false,

		// Выбранная опция материала для крыши и пола
		choosenMaterial: '',

		// Фотофиксация контейнера
		photoFixContainer: {
			pictures: [],
		},
	},
	reducers: {
		setHandleNextButtonDisabled(state, action) {
			state.handleNextButtonDisabled = action.payload;
		},
		// Сложение названия стороны и материала для пола и крыши при переходе с 4 страницы на 5
		concatDamageNameAndMaterial(state, action) {
			state.currentSideDamage = {
				...state.currentSideDamage,
				damageName: `${state.currentSideDamage.damageName + ' ' + state.choosenMaterial.toLowerCase()}`,
			};
		},
		//Добавление кода поврежденных деталей
		setDamagedDetails(state, action) {
			const hasDetail = state.damagedDetails.some((detail) => detail === action.payload);
			if (hasDetail) {
				state.damagedDetails = [];
				state.currentSideDamage.damages = state.currentSideDamage.damages.filter((el) => el.parts !== action.payload);
			} else {
				state.damagedDetails = [action.payload];
			}
		},
		// Очистка массива с деталями
		clearDamagedDetails(state, action) {
			state.damagedDetails = [];
		},
		// Добавление материала крыши и пола из чекбокса повреждению
		setDamageNameMaterial(state, action) {
			state.choosenMaterial = action.payload;

			// if (state.currentSideDamage.damages.length === 0) {
			// 	state.currentSideDamage = {
			// 		...state.currentSideDamage,
			// 		damageName: `${state.currentSideDamage.damageName}`,
			// 	};
			// } else {
			// 	state.currentSideDamage = {
			// 		...state.currentSideDamage,
			// 		damageName: `${state.currentSideDamage.damageName + ' ' + action.payload.toLowerCase()}`,
			// 	};
			// }
		},
		// Временный выбор активных блоков
		setTemporaryActiveBlocks(state, action) {
			state.temporaryActiveBlocks = action.payload;
		},
		// Выбирает тип повреждения
		setDamageType(state, action) {
			state.currentSideDamage = { ...state.currentSideDamage, damageType: action.payload };
		},
		// Добавляет комментарий
		setDamageComment(state, action) {
			state.currentSideDamage = { ...state.currentSideDamage, comment: action.payload };
		},
		// Добавляет длину повреждения
		setDamageLength(state, action) {
			state.currentSideDamage = { ...state.currentSideDamage, length: action.payload };
		},
		// Добавляет ширину повреждения
		setDamageWidth(state, action) {
			state.currentSideDamage = { ...state.currentSideDamage, width: action.payload };
		},
		// Добавляет глубину повреждения
		setDamageDepth(state, action) {
			state.currentSideDamage = { ...state.currentSideDamage, depth: action.payload };
		},
		setDamageImages(state, action) {
			if (state.currentSideDamage?.pictures?.length === 0) {
				state.currentSideDamage.pictures = [
					{ name: action.payload.Key, url: action.payload.Location, imageSrc: action.payload.imageSrc },
				];
			} else {
				state.currentSideDamage.pictures = [
					...state.currentSideDamage?.pictures,
					{ name: action.payload.Key, url: action.payload.Location, imageSrc: action.payload.imageSrc },
				];
			}
		},

		setDamageImagesIfSelectedDamage(state, action) {
			//for client
			state.selectedDamage.pictures = [
				...state.selectedDamage?.pictures,
				{ filename: action.payload.Key, url: action.payload.Location },
			];
			//for back
			const currentUuid = state.selectedDamage.uuid;
			state.damagesList = state?.damagesList?.map((el) => {
				if (el.uuid === currentUuid) {
					return {
						...el,
						pictures: [...el.pictures, { filename: action.payload.Key, url: action.payload.Location }],
					};
				} else {
					return el;
				}
			});
		},

		// Собирает повреждение
		setCurrentSideDamage(state, action) {
			const { sideIndex, sideName, type, divider, part, partCode, active, damageName } = action.payload;
			console.log(sideIndex, sideName, type, divider, part, partCode, active, damageName, 'setCurrentSideDamage');
			if (active) {
				state.currentSideDamage = {
					// id: uuidv4(),
					id: state.damagesList?.length + 1,
					side: sideName,
					sideIndex: sideIndex,
					damageName: damageName,
					partCode: partCode,
					pictures: state.currentSideDamage.pictures,
					damages: [...(state.currentSideDamage?.damages || []), { sideIndex, type, divider, part }],
				};
				state.activeBlockType = type;
			} else {
				const filteredDamages =
					state.currentSideDamage?.damages.filter(
						(damage) => damage.sideIndex !== sideIndex || damage.divider !== divider || damage.part !== part,
					) || [];

				let temporaryPartCode;
				if (filteredDamages.length === 0) {
					temporaryPartCode = {};
				} else {
					temporaryPartCode = partCode;
				}

				state.currentSideDamage = {
					...state.currentSideDamage,
					partCode: temporaryPartCode,
					damages: filteredDamages,
				};

				if (filteredDamages.length === 0) {
					state.activeBlockType = null;
				}
			}
		},
		// Добавление деталей в список повреждений
		setCurrentSideDetails(state, action) {
			const { sideIndex, sideName, type, divider, part, partCode, active, damageName } = action.payload;
			console.log(sideIndex, sideName, type, divider, part, partCode, active, damageName, 'setCurrentSideDetails');
			if (active) {
				state.currentSideDamage = {
					// id: uuidv4(),
					id: state.damagesList?.length + 1,
					side: sideName,
					sideIndex: sideIndex,
					damageName: detailCodeToName(part),
					partCode: '-',
					pictures: state.currentSideDamage.pictures,
					damages: [{ option: divider, parts: part }],
				};
			} else {
				const filteredDamages =
					state.currentSideDamage?.damages.filter(
						(damage) => damage.sideIndex !== sideIndex || damage.divider !== divider || damage.part !== part,
					) || [];
			}
		},
		// Дополнительные повреждения по галочке
		setCurrentSideAdditionalDamage(state, action) {
			const { sideIndex, type, active, damageName } = action.payload;
		},
		// Изменяет значение active у side
		setCurrentSideActiveDamage(state, action) {
			const { name, code, active } = action.payload;

			state.currentSideDamage = {
				...state.currentSideDamage,
				damagedComponentCode: { name, code, active },
			};

			// state.containerSides.forEach((side) => {
			// 	side.checkboxData.forEach((item) => {
			// 		if (item.type === type) {
			// 			item.active = active;
			// 		}
			// 		if (item.additionals) {
			// 			item.additionals.forEach((additional) => {
			// 				if (additional.type === type) {
			// 					additional.active = active;
			// 				}
			// 			});
			// 		}
			// 	});
			// });
		},
		clearCurrentSideDamage(state, action) {
			state.currentSideDamage = { side: null, sideIndex: null, damages: [], pictures: [] };
		},
		// Добавляет повреждение в список
		setDamagesList: (state, action) => {
			if (Array.isArray(action.payload)) {
				state.damagesList = [...action.payload];
			} else {
				state.damagesList = [...state.damagesList, action.payload];
			}
		},
		// Удалить картинку по filename из массива pictures
		setDamageListDeletePhoto(state, action) {
			const { uuid, filename } = action.payload;
			const damage = state.damagesList.find((damage) => damage.uuid === uuid);
			if (damage) {
				damage.pictures = damage.pictures.filter((picture) => picture.filename !== filename);
			}
		},

		// Устанавливает номер контейнера
		setContainerNumberContainerAcceptance(state, action) {
			if (action.payload) {
				state.containerNumberContainerAcceptance = action.payload;
				state.sizeContainerAcceptance = action.payload.size;
				state.typeContainerAcceptance = action.payload.type;
			}
		},
		// Устанавливает данные водителя
		setDriverContainerAcceptance(state, action) {
			state.driverContainerAcceptance = action.payload;
		},
		// Устанавливает номер автомобиля
		setVehicleNumberContainerAcceptance(state, action) {
			if (action.payload) {
				state.vehicleNumberContainerAcceptance = action.payload;
			}
		},
		// Устанавливает массу брутто
		setBruttoContainerAcceptance(state, action) {
			state.grossContainerAcceptance = action.payload;
		},
		// Устанавливает массу нетто
		setNettoContainerAcceptance(state, action) {
			state.nettoContainerAcceptance = action.payload;
		},
		// Устанавливает груженный контейнер или нет
		setIsFilledContainerAcceptance(state, action) {
			state.isFilledContainerAcceptance = action.payload;
		},
		// Устанавливает страницу в логике приема контейнера
		setPage(state, action) {
			state.page = action.payload;
		},
		// Устанавливает индекс выбранного повреждения
		setSelectedDamage(state, action) {
			state.selectedDamage = action.payload;
		},
		// Устанавливает выбранный контейнер
		setActiveContainer(state, action) {
			state.activeContainer = action.payload;
		},
		// Устанавливает выбранную сторону контейнера
		setSelectedSide(state, action) {
			state.selectedSide = action.payload;
			// state.selectedSide = state.containerSides.find((side) => side.type === action.payload);
			// action.payload;
		},
		// Устанавливает индекс выбранной машины
		setSelectedCar(state, action) {
			state.selectedCar = action.payload;
		},
		//Инфинити скролл
		setReportsFetching(state, action) {
			state.reportsFetching = action.payload;
		},
		// Устанавливает данные размера контейнера
		setSizeContainerAcceptance(state, action) {
			state.sizeContainerAcceptance = action.payload;
		},
		// Устанавливает данные размера контейнера
		setTypeContainerAcceptance(state, action) {
			state.typeContainerAcceptance = action.payload;
		},
		setChangeVehicleNumberContainerAcceptance(state, action) {
			if (action.payload) {
				state.changeVehicleNumberContainerAcceptance = action.payload;
			}
		},
		setChangeDriverContainerAcceptance(state, action) {
			state.changeDriverContainerAcceptance = action.payload;
		},
		// Очистка повреждений на случай выбора детали
		clearChoosenDamageForChoosingDetail(state, action) {
			state.temporaryActiveBlocks = [];
			state.currentSideDamage = { ...state.currentSideDamage, damages: [] };
		},
		// Очистка деталей на случай выбора повреждения
		clearChoosenDetailsForChoosingDamage(state, action) {
			state.damagedDetails = [];
		},

		// Фотофиксация контейнера
		setPhotoFixContainer(state, action) {
			if (state.photoFixContainer?.pictures?.length === 0) {
				state.photoFixContainer.pictures = [
					{ name: action.payload.Key, url: action.payload.Location, imageSrc: action.payload.imageSrc },
				];
			} else {
				state.photoFixContainer.pictures = [
					...state.photoFixContainer?.pictures,
					{ name: action.payload.Key, url: action.payload.Location, imageSrc: action.payload.imageSrc },
				];
			}
		},
	},
	extraReducers: (builder) => {
		//создание репорта
		builder.addCase(postEntryreports.fulfilled, (state, action) => {
			console.log(action.payload.id);
			state.reportId = action.payload.id;
			state.postEntryreports_success = true;
			state.postEntryreports_pending = false;
			state.postEntryreports_error = false;
		});
		builder.addCase(postEntryreports.pending, (state, action) => {
			state.postEntryreports_success = false;
			state.postEntryreports_pending = true;
			state.postEntryreports_error = false;
		});
		builder.addCase(postEntryreports.rejected, (state, action) => {
			state.postEntryreports_success = false;
			state.postEntryreports_pending = false;
			state.postEntryreports_error = true;
		});
		//изменение репорта
		builder.addCase(putEntryreports.fulfilled, (state, action) => {
			// console.log(action.payload.id);
			// state.reportId = action.payload.id;
			state.putEntryreports_success = true;
			state.putEntryreports_pending = false;
			state.putEntryreports_error = false;
		});
		builder.addCase(putEntryreports.pending, (state, action) => {
			state.putEntryreports_success = false;
			state.putEntryreports_pending = true;
			state.putEntryreports_error = false;
		});
		builder.addCase(putEntryreports.rejected, (state, action) => {
			state.putEntryreports_success = false;
			state.putEntryreports_pending = false;
			state.putEntryreports_error = true;
		});
		//получение всех репортов
		builder.addCase(getEntryreports.fulfilled, (state, action) => {
			console.log(action.payload?.next);
			if (action.payload?.next) {
				const startIndex = action.payload?.next?.indexOf('?');
				const trimmedUrl = `${action.payload?.next?.substring(startIndex)}`;
				state.reportsNext = trimmedUrl;
				state.reportsUrl = '';
			} else {
				state.reportsNext = null;
				state.reportsUrl = '';
			}
			state.reportsFetching = false;
			state.entryReports = [...state.entryReports, ...action.payload?.results];
			state.getEntryreports_success = true;
			state.getEntryreports_pending = false;
			state.getEntryreports_error = false;
		});
		builder.addCase(getEntryreports.pending, (state, action) => {
			state.getEntryreports_success = false;
			state.getEntryreports_pending = true;
			state.getEntryreports_error = false;
		});
		builder.addCase(getEntryreports.rejected, (state, action) => {
			state.getEntryreports_success = false;
			state.getEntryreports_pending = false;
			state.getEntryreports_error = true;
		});
		//получение одного репорта
		builder.addCase(getOneEntryreport.fulfilled, (state, action) => {
			state.entryOneReport = action.payload;
			state.damagesList = action.payload.damages || [];
			state.getOneEntryreport_success = true;
			state.getOneEntryreport_pending = false;
			state.getOneEntryreport_error = false;
		});
		builder.addCase(getOneEntryreport.pending, (state, action) => {
			state.getOneEntryreport_success = false;
			state.getOneEntryreport_pending = true;
			state.getOneEntryreport_error = false;
		});
		builder.addCase(getOneEntryreport.rejected, (state, action) => {
			state.getOneEntryreport_success = false;
			state.getOneEntryreport_pending = false;
			state.getOneEntryreport_error = true;
		});

		// получение списка контейнеров на терминале
		builder.addCase(getContainersForContainerAcceptance.fulfilled, (state, action) => {
			state.containerListForContainerAcceptance = action.payload.map((container) => {
				return {
					id: container.id,
					container_number: container.container_number,
					size: container.size_display,
					type: container.type_display,
					terminal: container.entrypass[0].terminal,
				};
			});
			state.getContainersForContainerAcceptance_success = true;
			state.getContainersForContainerAcceptance_pending = false;
			state.getContainersForContainerAcceptance_error = false;
		});
		builder.addCase(getContainersForContainerAcceptance.pending, (state, action) => {
			state.getContainersForContainerAcceptance_success = false;
			state.getContainersForContainerAcceptance_pending = true;
			state.getContainersForContainerAcceptance_error = false;
		});
		builder.addCase(getContainersForContainerAcceptance.rejected, (state, action) => {
			state.getContainersForContainerAcceptance_success = false;
			state.getContainersForContainerAcceptance_pending = false;
			state.getContainersForContainerAcceptance_error = true;
		});

		// Получение списка автомобилей
		builder.addCase(getVehiclesForContainerAcceptance.fulfilled, (state, action) => {
			state.vehicleListForContainerAcceptance = action.payload;
			state.getVehiclesForContainerAcceptance_success = true;
			state.getVehiclesForContainerAcceptance_pending = false;
			state.getVehiclesForContainerAcceptance_error = false;
		});
		builder.addCase(getVehiclesForContainerAcceptance.pending, (state, action) => {
			state.getVehiclesForContainerAcceptance_success = false;
			state.getVehiclesForContainerAcceptance_pending = true;
			state.getVehiclesForContainerAcceptance_error = false;
		});
		builder.addCase(getVehiclesForContainerAcceptance.rejected, (state, action) => {
			state.getVehiclesForContainerAcceptance_success = false;
			state.getVehiclesForContainerAcceptance_pending = false;
			state.getVehiclesForContainerAcceptance_error = true;
		});

		builder
			.addCase(postVehicle.fulfilled, (state, action) => {
				console.log(action.payload, 'vehicle');
				state.vehicleListForContainerAcceptance = [...state.vehicleListForContainerAcceptance, action.payload];
				state.vehicleNumberContainerAcceptance = action.payload;
				state.postVehicle_success = true;
				state.postVehicle_pending = false;
				state.postVehicle_error = false;
			})
			.addCase(postVehicle.pending, (state, action) => {
				state.postVehicle_success = false;
				state.postVehicle_pending = true;
				state.postVehicle_error = false;
			})
			.addCase(postVehicle.rejected, (state, action) => {
				state.postVehicle_success = false;
				state.postVehicle_pending = false;
				state.postVehicle_error = action.payload;
			});
		// Редактирование одного контейнера
		builder
			.addCase(putOneContainer.fulfilled, (state, action) => {
				state.putOneContainer_success = true;
				state.putOneContainer_pending = false;
				state.putOneContainer_rejected = false;
			})
			.addCase(putOneContainer.pending, (state, action) => {
				state.putOneContainer_success = false;
				state.putOneContainer_pending = true;
				state.putOneContainer_rejected = false;
			})
			.addCase(putOneContainer.rejected, (state, action) => {
				state.putOneContainer_success = false;
				state.putOneContainer_pending = false;
				state.putOneContainer_rejected = action.payload.error;
			});
		builder
			.addCase(putDamagesInEntryreport.fulfilled, (state, action) => {
				state.entryOneReport = action.payload;
				state.damagesList = action.payload.damages;
				state.putDamagesInEntryreport_success = true;
				state.putDamagesInEntryreport_pending = false;
				state.putDamagesInEntryreport_rejected = false;
			})
			.addCase(putDamagesInEntryreport.pending, (state, action) => {
				state.putDamagesInEntryreport_success = false;
				state.putDamagesInEntryreport_pending = true;
				state.putDamagesInEntryreport_rejected = false;
			})
			.addCase(putDamagesInEntryreport.rejected, (state, action) => {
				state.putDamagesInEntryreport_success = false;
				state.putDamagesInEntryreport_pending = false;
				state.putDamagesInEntryreport_rejected = action.payload.error;
			});
		// Редактирование пропуска
		builder
			.addCase(putEntriesList.fulfilled, (state, action) => {
				state.putEntriesList_success = true;
				state.putEntriesList_pending = false;
				state.putEntriesList_error = false;

				// Обновление данных после редактирования
				state.driverContainerAcceptance = action.payload[0].vehicle_driver;
				state.vehicleNumberContainerAcceptance.id = action.payload[0].vehicle;
				state.vehicleNumberContainerAcceptance.registration_number = action.payload[0].vehicle_display;
			})
			.addCase(putEntriesList.pending, (state, action) => {
				state.putEntriesList_success = false;
				state.putEntriesList_pending = true;
				state.putEntriesList_error = false;
			})
			.addCase(putEntriesList.rejected, (state, action) => {
				state.putEntriesList_success = false;
				state.putEntriesList_pending = false;
				state.putEntriesList_error = action.payload.error;
			});
	},
});

export const {
	clearDamagedDetails,
	concatDamageNameAndMaterial,
	setDamageNameMaterial,
	setCurrentSideDetails,
	clearChoosenDetailsForChoosingDamage,
	clearChoosenDamageForChoosingDetail,
	setDamagedDetails,
	setTemporaryActiveBlocks,
	setCurrentSideActiveDamage,
	setCurrentSideAdditionalDamage,
	setDamageImages,
	setDamageComment,
	setDamageLength,
	setDamageWidth,
	setDamageDepth,
	setDamageType,
	setCurrentSideDamage,
	clearCurrentSideDamage,
	setDamagesList,
	setContainerNumberContainerAcceptance,
	setVehicleNumberContainerAcceptance,
	setDriverContainerAcceptance,
	setPage,
	setActiveContainer,
	setSelectedSide,
	setRegistrationNumber,
	setTrailerNumber,
	setDriver,
	setSelectedCar,
	setSelectedDamage,
	setReportsFetching,
	setDamageImagesIfSelectedDamage,
	setSizeContainerAcceptance,
	setTypeContainerAcceptance,
	setDamageListDeletePhoto,
	setChangeVehicleNumberContainerAcceptance,
	setChangeDriverContainerAcceptance,
	setHandleNextButtonDisabled,
	setPhotoFixContainer,
	setBruttoContainerAcceptance,
	setNettoContainerAcceptance,
	setIsFilledContainerAcceptance,
} = containerAcceptance.actions;
export default containerAcceptance.reducer;
