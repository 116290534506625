export const damageTypeToName = (type) => {
	switch (type) {
		//F - фидера
		case 'angular-HL':
			return 'Левый верхний фидер';
		case 'angular-HR':
			return 'Правый верхний фидер';
		case 'angular-BR':
			return 'Правый нижний фидер';
		case 'angular-BL':
			return 'Левый нижний фидер';

		// F - балки
		case 'beam-horizontal-B':
			return 'Нижняя балка';
		case 'beam-horizontal-H':
			return 'Верхняя балка';
		case 'beam-vertical-R':
			return 'Правая балка';
		case 'beam-vertical-L':
			return 'Левая балка';

		// F - центр
		case 'mainPanel':
			return 'Панель передняя';

		// D - Верх

		// U - Низ
		case 'mainPanel_U':
			return 'Нижняя панель';
		default:
			break;
	}
};
