export function BtnFont(font) {
  let result =
    font === 'fs-18'
      ? 'fs-18'
      : font === 'fs-20'
        ? 'fs-20'
        : font === 'fs-14'
          ? 'fs-14'
          : '';
  return result;
}

export function BtnBg(bg) {
  let result =
    bg === 'secondary'
      ? 'secondary'
      : bg === "delete"
        ? "delete"
        : bg === "blue"
          ? "blue"
          : bg === "code"
            ? "code"
            : bg === "white"
              ? "white"
              : bg === "orange"
                ? "orange"
                : bg === "save"
                  ? "save"
                  : bg === "grey"
                    ? "grey"
                    : bg === "green"
                      ? "green"
                      : '';
  return result;
}

export function BtnSize(size) {
  let result =
    size === 'p-1624'
      ? 'p-1624'
      : size === 'p-1724'
        ? 'p-1724'
        : size === 'p-1216'
          ? 'p-1216'
          : size === 'p-810'
            ? 'p-810'
            : '';
  return result;
}

export function BtnFlex(flex) {
  let result =
    flex === 'js-between'
      ? 'js-between'
      : '';
  return result;
} 