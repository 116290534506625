import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material';
import CardComponent from '../../../../newComponents/CardComponent/CardComponent';
import { account, accountGridItemHalf } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getInitials } from '../../../../helpers/formatText';
import { useLazyGetEmplsListQuery } from '../../../../store/newApi/users/users.js';
import Preloader from '../../../../components/Preloader/Preloader';
import Filter from '../../../../newComponents/Filter/Filter.jsx';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../store/slices/sideBarSlice.js';
import ModalClientEmpl from '../components/Modals/ModalsCllient/ModalClientEmpl.jsx';
import { useDispatch } from 'react-redux';

function AccountEmplEmpls() {
	const [trigger, { data, isLoading }] = useLazyGetEmplsListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);

	// Для режима выбора карточек
	const [selectionMode, setSelectionMode] = useState(false);
	const [selectedCards, setSelectedCards] = useState([]);

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ page: 1, page_size: 30 });
	}, []);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ page: nextPage, page_size: 30 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => [...prevData, ...data.results]);
			setFetching(false);
		}
	}, [data]);

	// Обработка выбора карточек
	const handleCardSelect = (id) => {
		setSelectedCards((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((cardId) => cardId !== id);
			} else {
				return [...prevSelected, id];
			}
		});
	};

	const dispatch = useDispatch();

	const handleNewEmpl = () => {
		dispatch(setCurrentElementForSideBar(<ModalClientEmpl employer={''} />));
		dispatch(setSideBarActive(true));
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Сотрудники'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium" onClick={handleNewEmpl}>
						Добавить сотрудника
					</Button>,
				]}
			/>
			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					<div className={styles.account__filter}>
						<Filter />
					</div>
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemHalf(isDesktop)} key={index}>
									<CardComponent
										avatar={card?.full_name ? getInitials(card?.full_name) : 'А'}
										selectionMode={selectionMode}
										isSelected={selectedCards.includes(card?.id)}
										onSelectCard={() => handleCardSelect(card?.id)}
										title={card?.full_name ? card?.full_name : 'Администратор'}
										rightText={`${card?.terminals ? card?.terminals[0]?.group?.name : '-'}`}
										leftAdditionalInfo={[{ name: 'Логин:', text: `${card?.username ? card?.username : '-'}` }]}
										rightAdditionalInfo={[
											{ name: 'Терминал:', text: `${card?.terminals ? card?.terminals[0]?.name : '-'}` },
										]}
									/>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplEmpls;
