import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconCalendar({ size, onClick, color, className }) {
    return (
        <div onClick={onClick} className={`${styles.icon} ${className}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 16 16" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.3333 2.99984V4.33317H11.3333V2.99984H12C12.5523 2.99984 13 3.44755 13 3.99984V5.34359L3 5.34359V3.99984C3 3.44755 3.44772 2.99984 4 2.99984H4.66667V4.33317H5.66667V2.99984L10.3333 2.99984ZM3 6.34359L3 11.9998C3 12.5521 3.44772 12.9998 4 12.9998H12C12.5523 12.9998 13 12.5521 13 11.9998V6.34359L3 6.34359ZM4 1.99984H4.66667V0.666504H5.66667V1.99984L10.3333 1.99984V0.666504H11.3333V1.99984H12C13.1046 1.99984 14 2.89527 14 3.99984V11.9998C14 13.1044 13.1046 13.9998 12 13.9998H4C2.89543 13.9998 2 13.1044 2 11.9998V3.99984C2 2.89527 2.89543 1.99984 4 1.99984Z"
                    fill={IconColor(color)}
                />
            </svg>
        </div>
    );
}

export default IconCalendar;