import React from 'react';
import UniversalBlock from '../components/UniversalBlock';
import styles from './SideStyle.module.scss';
import { useSelector } from 'react-redux';
import { isBlockChoosen } from './helpers/isBlockChoosen';
import UniversalDetailBlock from '../components/UniversalDetailBlock';
import { isDetailDamaged } from './helpers/isDetailDamaged';

function RoofSide() {
	const { damagesList, selectedSide, entryOneReport } = useSelector((state) => state.containerAccept);
	const { placement_code_description } = selectedSide;

	return (
		<div className={styles.mainBlock_U}>
			<div className={styles.mapRowNumber_new}>
				<p className={styles.topNumber_new}></p>
				{placement_code_description[0].parts[entryOneReport.container.size_display === '20' ? '20-feet' : '40-feet']
					.slice()
					.reverse()
					.map((part) => (
						<p key={part} className={styles.topNumber_new}>
							{part}
						</p>
					))}
			</div>
			{placement_code_description.map(({ option, parts }) => (
				<div key={option} className={styles.mapRow_new}>
					<p className={styles.sideLabel_new}>{option}</p>
					<UniversalDetailBlock
						type={`angular-T_LL`}
						sideIndex={'T'}
						sideName={'Крыша'}
						divider={option}
						part={'1'}
						choosen={false}
					/>
					<UniversalDetailBlock
						type={`angular-T_LR`}
						sideIndex={'T'}
						sideName={'Крыша'}
						divider={option}
						part={'1'}
						choosen={false}
					/>
					<UniversalDetailBlock
						type={`angular-T_RL`}
						sideIndex={'T'}
						sideName={'Крыша'}
						divider={option}
						part={'1'}
						choosen={false}
					/>
					<UniversalDetailBlock
						type={`angular-T_RR`}
						sideIndex={'T'}
						sideName={'Крыша'}
						divider={option}
						part={'1'}
						choosen={false}
					/>
					{parts[entryOneReport.container.size_display === '20' ? '20-feet' : '40-feet']
						.slice()
						.reverse()
						.map((part, index) => (
							<UniversalBlock
								key={index}
								setSmthSelected={() => {}}
								type={getType(option, part)}
								sideIndex={'T'}
								sideName={'Крыша'}
								divider={option}
								part={part.toString()}
								partCode={getPartCode(option, part)}
								choosen={isBlockChoosen(damagesList, 'T', option, part.toString())}
							/>
						))}
				</div>
			))}
		</div>
	);
}

function getType(option, part) {
	if (option === 'L' || option === 'R') {
		if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 0].includes(part)) {
			return 'mainPanel';
		}
	}
	// Другие условия для различных опций и частей, если требуются
	// if (option === 'H' && part === 1) {
	// 	return 'angular-HR';
	// } else if (option === 'H' && part === 4) {
	// 	return 'angular-HL';
	// } else if (option === 'G' && part === 1) {
	// 	return 'angular-BR';
	// } else if (option === 'G' && part === 4) {
	// 	return 'angular-BL';
	// } else if (option === 'H' && (part === 2 || part === 3)) {
	// 	return `beam-horizontal-H`;
	// } else if (option === 'G' && (part === 2 || part === 3)) {
	// 	return `beam-horizontal-B`;
	// } else if ((option === 'T' && part === 1) || (option === 'B' && part === 1)) {
	// 	return 'beam-vertical-R';
	// } else if ((option === 'T' && part === 4) || (option === 'B' && part === 4)) {
	// 	return 'beam-vertical-L';
	// } else {
	// 	return 'mainPanel';
	// }
}

function getPartCode(option, part) {
	// Логика для получения кода детали, если требуется
	return `${option}-${part}`;
}

export default RoofSide;
