export const filter = {
	wrapper: {
		position: 'relative',
		marginBottom: '8px',
	},
	btnClose: {
		position: 'fixed',
		top: '12px',
		right: '388px',
		zIndex: '1301',
		width: '44px',
		height: '44px',
		borderRadius: '8px',
		border: '1px solid',
		borderColor: (theme) => theme.palette.border.primaryHover,
		backgroundColor: '#ffffff',

		'&:hover': {
			backgroundColor: '#ffffff',
		},
	},

	drawer: {
		'& .MuiModal-backdrop': {
			backgroundColor: 'transparent',
		},
		'& .MuiDrawer-paper': {
			boxShadow: '0px 4px 4px 0px #00000040',
		},
	},

	content: {
		color: (theme) => theme.palette.text.secondary,
		width: '380px',
		height: 1,
		overflow: 'auto',

		'&::-webkit-scrollbar': {
			width: '3px',
		},

		'&::-webkit-scrollbar-track': {
			backgroundColor: '#ffdebc',
		},

		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#ff8e16',
		},
	},

	row: {
		display: 'flex',
		gap: '8px',
	},

	input: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: (theme) => theme.palette.border.primary,
				borderWidth: '2px',
			},
			'&:hover fieldset': {
				borderColor: (theme) => theme.palette.border.primaryHover,
			},
			'&.Mui-focused fieldset': {
				borderColor: (theme) => theme.palette.border.primaryFocus,
			},
		},
		'& input:-webkit-autofill': {
			WebkitTextFillColor: (theme) => theme.palette.text.primary,
			caretColor: (theme) => theme.palette.text.primary,
			WebkitBoxShadow: '0 0 0px 1000px white inset',
			backgroundColor: 'transparent',
		},
		input: {
			height: '36px',
			padding: '0 0 0 16px',
			fontSize: '16px',
			fontWeight: '400',
			lineHeight: '24px',
			letterSpacing: '0.08px',
			fontFamily: 'Montserrat',
		},
	},

	button: {
		padding: '8px 0 16px 16px',
		position: 'sticky',
		bottom: '0',
		right: '0',
		backgroundColor: '#ffffff',
	},
};
