// import React, { useState, useCallback, useEffect } from 'react';
// import styles from './CheckBox.module.scss';
// import { IconCheck } from '../Icons';

// function CheckBox({ type, onClick, typeCheck, disabled }) {
// 	const [check, setCheck] = useState(typeCheck !== undefined ? typeCheck : false);
// 	const onCheck = useCallback(() => {
// 		if (!disabled) {
// 			setCheck(!check);
// 			onClick && onClick();
// 		}
// 	}, [disabled, check]);

// 	useEffect(
// 		() => {
// 			console.log(typeCheck, 'Type check');
// 			if (typeCheck !== undefined) {
// 				setCheck(typeCheck);
// 			}
// 		},
// 		typeCheck !== undefined ? [typeCheck] : [],
// 	);

// 	return (
// 		<div className={`${styles.checkbox} ${type === 2 ? styles.checkboxType2 : ''}`} onClick={onCheck}>
// 			{check ? (
// 				<div className={styles.check}>
// 					<div className={`${styles.bg} ${type === 2 ? styles.bgType2 : ''}`}>
// 						<IconCheck size={'largeS'} color={'primary_white'} />
// 					</div>
// 				</div>
// 			) : (
// 				<div className={styles.noCheck} />
// 			)}
// 		</div>
// 	);
// }

// export default CheckBox;
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types'; // Добавьте PropTypes для проверки типов пропсов
import styles from './CheckBox.module.scss';
import { IconCheck } from '../Icons';

function CheckBox({ type, onClick, typeCheck, disabled }) {
	const [check, setCheck] = useState(typeCheck || false);

	const onCheck = useCallback(() => {
		if (!disabled) {
			setCheck((prevCheck) => !prevCheck);
			onClick && onClick();
		}
	}, [disabled, onClick]);

	useEffect(() => {
		if (typeCheck !== undefined) {
			setCheck(typeCheck);
		}
	}, [typeCheck]);

	return (
		<div className={`${styles.checkbox} ${type === 2 ? styles.checkboxType2 : ''} `} onClick={onCheck}>
			{check ? (
				<div className={`${styles.check} `}>
					<div className={`${styles.bg} ${type === 2 ? styles.bgType2 : ''} ${disabled ? styles.disabled : ''} `}>
						<IconCheck size={'largeS'} color={'primary_white'} />
					</div>
				</div>
			) : (
				<div className={`${styles.noCheck} ${disabled ? styles.disabled_border : ''}`} />
			)}
		</div>
	);
}

CheckBox.propTypes = {
	type: PropTypes.number,
	onClick: PropTypes.func,
	typeCheck: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default CheckBox;
