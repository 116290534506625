import { modelPathMatch } from './modelPathMatch';

export function handleSearchTypePermission(terminals, path, type) {
	// Сопоставить модель и путь
	const model = modelPathMatch.find((item) => item.path === path)?.model;

	// TODO Потом поменять когда будут известны все модели и пути
	// if (!model) return false;
	if (!model) return true;

	// Поиск типа в правах
	return terminals?.some(
		(terminal) =>
			terminal.permissions &&
			terminal.permissions.some(
				(permission) => permission.model.toLowerCase() === model.toLowerCase() && permission.permission_type.includes(type),
			),
	);
}
