import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Preloader, Search } from '../../../../../components';
import ActCard from './components/ActCard/ActCard';
import styles from './ReceptionTransmission.module.scss';
import { useEffect } from 'react';
import { getEntryreports, setReportsFetching } from '../../../../../store/slices/containerAcceptanceSlice';

const ReceptionTransmission = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { confirmed } = useParams(); //параметр, чтобы достать разный тип актов
	const { entryReports, reportsFetching, getEntryreports_pending } = useSelector((state) => state.containerAccept);

	//Переход на Акт после его полного создания
	const handleClickOnAct = (cardId) => {
		navigate(`/accountEmpl/receptionTransmission/act/${cardId}`);
	};
	//Переход на редактирование Черновика
	const handleClickGoToDraft = (cardId) => {
		navigate(`/accountEmpl/containerAcceptance/${cardId}`);
	};

	const scrollHandler = (e) => {
		if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100) {
			dispatch(setReportsFetching(true));
		}
	};

	useEffect(() => {
		const isConfirmed = confirmed === 'false' ? 1 : 2;
		dispatch(getEntryreports({ isConfirmed }));
	}, [dispatch, reportsFetching, confirmed]);

	return (
		<div className={styles.main_block}>
			{getEntryreports_pending && (
				<div className={styles.reportsList_wrapper_preloader}>
					<Preloader />
				</div>
			)}
			<Search />
			<div className={styles.acts_block} onScroll={scrollHandler}>
				{entryReports.map((item, index) => (
					<ActCard
						key={item.id}
						card={item}
						onClick={() => {
							item.status_display !== 'Черновик' ? handleClickOnAct(item.id) : handleClickGoToDraft(item.id);
						}}
					/>
				))}
			</div>
		</div>
	);
};

export default ReceptionTransmission;
