// обрезка строки с указанной длиной
export const cropString = (str, maxLength) => {
	if (str.length > maxLength) {
		return str.substring(0, maxLength) + '...';
	}
	return str;
};

export const getInitials = (name) => {
	// Проверяем наличие кавычек разных типов и извлекаем текст внутри них
	const quoteMatch = name.match(/["«](.+?)["»]/);

	let textToProcess = name;

	// Если есть кавычки, работаем с текстом внутри них
	if (quoteMatch) {
		textToProcess = quoteMatch[1];
	}

	// Удаляем все запятые и разделяем текст по пробелам
	const words = textToProcess.replace(/,/g, '').split(' ');

	// Если одно слово, возвращаем первую букву
	if (words.length === 1) {
		return words[0].slice(0, 1).toUpperCase();
	}

	// Если два или более слов, возвращаем первые буквы каждого слова
	if (words.length >= 2) {
		return (words[0][0] + words[1][0]).toUpperCase();
	}

	return '';
};

// для цены чтобы тысячи были с пробелом
export const formatPrice = (price) => {
	if (price >= 1000) {
		// Преобразование числа в строку с разделением тысяч пробелом
		return price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' '); // Заменяем запятую на пробел
	} else {
		// Отображаем число с двумя десятичными знаками без разделения тысяч
		return price.toFixed(2);
	}
};
