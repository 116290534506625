import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	filter: {
		page: 1,
		page_size: 30,
	},
};

export const rapackSlice = createSlice({
	name: 'rapack',
	initialState,
	reducers: {
		setFilter: (state, action) => {
			state.filter = action.payload;
		},
		resetFilter: (state) => {
			state.filter = initialState;
		},
	},
});

export const { setFilter, resetFilter } = rapackSlice.actions;
export default rapackSlice.reducer;
