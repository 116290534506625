import { useDispatch, useSelector } from 'react-redux';
import { UniversalButton } from '../../../../../../../components';
import { IconAlertCircle, IconEdit } from '../../../../../../../components/Icons';
import styles from './MainApplication.module.scss';
import { setCurrentElementForSideBar, setSideBarActive, setHeightModal } from '../../../../../../../store/slices/sideBarSlice';
import ContainerForm from '../ContainerForm/ContainerForm';
import {
	setVehicleNumberContainerAcceptance,
	setDriverContainerAcceptance,
	setChangeVehicleNumberContainerAcceptance,
	setChangeDriverContainerAcceptance,
} from '../../../../../../../store/slices/containerAcceptanceSlice';
import { useUpdateEffect } from '../../../../../../../hooks/useUpdateEffect';

const MainApplication = () => {
	const dispatch = useDispatch();

	const {
		entryOneReport,
		containerNumberContainerAcceptance,
		vehicleNumberContainerAcceptance,
		driverContainerAcceptance,
		sizeContainerAcceptance,
		typeContainerAcceptance,
	} = useSelector((state) => state.containerAccept);

	const { container } = entryOneReport; //инфо о контейнере
	const entrypass = container?.entrypass; //инфо энтрипасс

	useUpdateEffect(() => {
		dispatch(setVehicleNumberContainerAcceptance({ id: entrypass[0]?.vehicle, registration_number: entrypass[0]?.vehicle_display }));
		dispatch(setDriverContainerAcceptance(entrypass[0]?.vehicle_driver));
		dispatch(
			setChangeVehicleNumberContainerAcceptance({ id: entrypass[0]?.vehicle, registration_number: entrypass[0]?.vehicle_display }),
		);
		dispatch(setChangeDriverContainerAcceptance(entrypass[0]?.vehicle_driver));
	}, [entrypass]);

	const handleChangeReport = () => {
		dispatch(setHeightModal(false)); // полная высота модалки
		dispatch(setSideBarActive(true));
		dispatch(setCurrentElementForSideBar(<ContainerForm />));
	};

	const handleMoreInformation = () => {
		dispatch(setHeightModal(true)); // полная высота модалки
		dispatch(setSideBarActive(true));
		dispatch(
			setCurrentElementForSideBar(
				<>
					<strong>Примечаниe</strong>
					<br />
					По ВКЗ Альянс работаем по следующей схеме:прием и осмотр ктк осуществляется по стандартной схеме, подгружаем фото
					повреждений и акт, указываем статус Поврежден, отправляем в общий сток клиента. !!!Смету не составляем!!! При выдаче
					снимаем блок (СИНИЙ ЗАМОЧЕК) самостоятельно, без дополнительного согласования. Если водитель отказывается ставить
					поврежденный ктк, работаем также по стандартной схеме, через согласование дополнительных крановых.
				</>,
			),
		);
	};

	return (
		<div className={styles.appContainer}>
			<div className={styles.leftInfo}>
				<p className={styles.title}>{`Заявка №${entryOneReport?.id}`}</p>
				<div className={styles.bottomInfo}>
					<div className={styles.infoContainer}>
						<p className={styles.info}>Номер АМ: </p>
						<p className={styles.subInfo}>
							{vehicleNumberContainerAcceptance?.registration_number || container?.entrypass[0]?.vehicle_display}
						</p>
					</div>
					<div className={styles.infoContainer}>
						<p className={styles.info}>Контейнер:</p>
						<div>
							<p className={styles.subInfo}>
								{containerNumberContainerAcceptance?.container_number || container?.container_number}
							</p>
							<div className={`${styles.container__status} ${styles.warning}`}>
								{typeContainerAcceptance || container?.type_display}
								<span> </span>
								{sizeContainerAcceptance || container?.size_display}
							</div>
						</div>
					</div>
					<div className={styles.infoContainer}>
						<p className={styles.info}>Груз:</p>
						<p className={styles.subInfo}>Мониторы</p>
					</div>
					<div className={styles.infoContainer}>
						<p className={styles.info}>Водитель:</p>
						<p className={styles.subInfo}>{driverContainerAcceptance || container?.entrypass[0]?.vehicle_driver}</p>
					</div>
				</div>
			</div>
			<div className={styles.actionContainer}>
				<UniversalButton
					onClick={handleMoreInformation}
					size={'small'}
					typeButton={'button'}
					color={'white'}
					icon={<IconAlertCircle size={'largeS'} color={'primary-active'} />}
				/>
				<UniversalButton
					onClick={handleChangeReport}
					size={'small'}
					typeButton={'button'}
					color={'white'}
					icon={<IconEdit size={'largeS'} color={'primary-active'} />}
				/>
			</div>
		</div>
	);
};

export default MainApplication;
