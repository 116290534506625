import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconFileImg({ onClick, size, color }) {
  return (
    <div className={styles.icon} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)}
        height={IconSize(size)} viewBox="0 0 12 14" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.99996 12.6667H9.99996C10.1841 12.6667 10.3333 12.5175 10.3333 12.3334V11.4293L7.98396 9.47558L6.96663 10.2386C6.78257 10.3766 6.52807 10.3713 6.35001 10.2256L3.01489 7.49683L1.66713 8.7056L1.66663 8.70504V12.3334C1.66663 12.5175 1.81586 12.6667 1.99996 12.6667ZM1.66663 7.36278V1.66671C1.66663 1.48261 1.81586 1.33337 1.99996 1.33337H6.98245C7.06923 1.33337 7.15259 1.36722 7.21481 1.42771L10.2323 4.3614C10.2969 4.42416 10.3333 4.51036 10.3333 4.6004V10.1287L8.31966 8.45414C8.14162 8.30609 7.88521 8.29965 7.69996 8.43858L6.68004 9.20352L3.31658 6.4516C3.12577 6.29549 2.84965 6.30175 2.66612 6.46636L1.66663 7.36278ZM1.99996 0.333374C1.26358 0.333374 0.666626 0.930328 0.666626 1.66671V12.3334C0.666626 13.0698 1.26358 13.6667 1.99996 13.6667H9.99996C10.7363 13.6667 11.3333 13.0698 11.3333 12.3334V4.6004C11.3333 4.24026 11.1876 3.89544 10.9294 3.6444L7.91189 0.710714C7.66301 0.468745 7.32957 0.333374 6.98245 0.333374H1.99996ZM6.66663 6.00004C6.66663 5.44776 7.11434 5.00004 7.66663 5.00004C8.21891 5.00004 8.66663 5.44776 8.66663 6.00004C8.66663 6.55233 8.21891 7.00004 7.66663 7.00004C7.11434 7.00004 6.66663 6.55233 6.66663 6.00004Z" fill={IconColor(color)} />
      </svg>
    </div>
  );
}

export default IconFileImg;

