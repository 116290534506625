/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getClientVehicles } from '../../api/vehicle/getClientVehicles';
import { deleteVehicle } from '../../api/vehicle/deleteVehicle';
import { putVehicle } from '../../api/vehicle/putVehicle';
import { postNewVehicle } from '../../api/vehicle/postNewVehicle';
import { getVehiclesApi } from '../../api/vehicle/getVehiclesApi';
import { postVehicleApi } from '../../api/vehicle/postVehicleApi';

export const getClientVehiclesApi = createAsyncThunk('vehicle/getClientVehiclesApi', async (clientId, { rejectWithValue }) => {
	try {
		const response = await getClientVehicles(clientId);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса vehicle/getClientVehiclesApi. Не удалось загрузить данные');
		}
		return response.data.results;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const getVehicles = createAsyncThunk('vehicle/getVehicles', async ({ number }, { rejectWithValue }) => {
	try {
		const url = number ? `number=${number}&page_size=5` : 'page_size=5';
		const response = await getVehiclesApi(url);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса vehicle/getClientVehiclesApi. Не удалось загрузить данные');
		}
		return response.data.results;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});
export const postNewVehicleAction = createAsyncThunk('vehicle/postNewVehicleAction', async ({ id, formData }, { rejectWithValue }) => {
	try {
		const response = await postNewVehicle(id, formData);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса vehicle/postNewVehicleAction. Не удалось добавить данные');
		}
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const deleteVehicleAction = createAsyncThunk('vehicle/deleteVehicleAction', async ({ id }, { rejectWithValue }) => {
	console.log(`удаление ${id} машины`);
	try {
		const response = await deleteVehicle(id);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса vehicle/deleteVehicleAction. Не удалось удалить данные');
		}
		return {
			data: response.data,
			vehicleId: id,
		};
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const putVehicleAction = createAsyncThunk(
	'vehicle/putVehicleAction',
	async ({ vehicleId, clientId, formData }, { rejectWithValue }) => {
		try {
			const response = await putVehicle(vehicleId, clientId, formData);
			if (!response.statusText === 'OK') {
				throw new Error('Ошибка запроса vehicle/putVehicleAction. Не удалось изменить данные');
			}
			return { response: response.data, vehicleId, formData };
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

const vehicleSlice = createSlice({
	name: 'vehicle',
	initialState: {
		//! API
		error: null,
		getClientVehiclesSuccess: false,
		putVehicleSuccess: false,
		postNewVehicleSuccess: false,

		//! Vehicle List
		vehiclesList: [],
		vehiclesListInOrder: [],

		//! Vehicle Item
		vehicleId: null,
		registrationNumber: null,
		trailerNumber: null,
		driverFullName: null,

		//! DeleteVehicle_статусы
		deleteVehicle_success: false,
		deleteVehicle_pending: false,
		deleteVehicle_error: false,

		//! Новое
		getVehicles_success: false,
		getVehicles_pending: false,
		getVehicles_error: false,
	},

	reducers: {
		setVehicleGetSuccess(state, action) {
			state.getClientVehiclesSuccess = action.payload;
		},
		setVehiclePostSuccess(state, action) {
			state.postNewVehicleSuccess = action.payload;
		},
		setVehiclePutSuccess(state, action) {
			state.putVehicleSuccess = action.payload;
		},
		setVehicleId(state, action) {
			state.vehicleId = action.payload;
		},
		setRegistrationNumber(state, action) {
			state.registrationNumber = action.payload;
		},
		setTrailerNumber(state, action) {
			state.trailerNumber = action.payload;
		},
		setDriverFullName(state, action) {
			state.driverFullName = action.payload;
		},
		addVehicleToOrder(state, action) {
			const addedVehicle = state.vehiclesList.find((el) => el.id === action.payload);
			state.vehiclesList = state.vehiclesList.filter((el) => el.id !== action.payload);
			state.vehiclesListInOrder.push(addedVehicle);
		},
		removeVehicleFromOrder(state, action) {
			const removedVehicle = state.vehiclesListInOrder.find((el) => el.id === action.payload);
			state.vehiclesListInOrder = state.vehiclesListInOrder.filter((el) => el.id !== action.payload);
			state.vehiclesList.push(removedVehicle);
		},
	},
	selectors: {
		selectVehicleId: (state) => state.vehicleId,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getClientVehiclesApi.fulfilled, (state, action) => {
				state.getClientVehiclesSuccess = true;
				state.vehiclesList = action.payload;
			})
			.addCase(getClientVehiclesApi.pending, (state, action) => {
				state.error = null;
				state.getClientVehiclesSuccess = false;
			})
			.addCase(getClientVehiclesApi.rejected, (state, action) => {
				state.error = action.payload;
				state.getClientVehiclesSuccess = false;
			});
		//! мой билдер на удаление
		builder
			.addCase(deleteVehicleAction.fulfilled, (state, action) => {
				state.deleteVehicle_success = true;
				state.deleteVehicle_pending = false;
				state.deleteVehicle_error = false;
				//TODO проверить фильтрацию на удаление машин при появлении бэка
				state.vehiclesList = state.vehiclesList.filter((el) => el.id !== action.payload.vehicleId);
			})
			.addCase(deleteVehicleAction.pending, (state, action) => {
				state.deleteVehicle_success = false;
				state.deleteVehicle_pending = true;
				state.deleteVehicle_error = false;
			})
			.addCase(deleteVehicleAction.rejected, (state, action) => {
				state.deleteVehicle_success = false;
				state.deleteVehicle_pending = false;
				state.deleteVehicle_error = action.payload;
			});

		//! мой билдер на удаление(конец)

		builder
			.addCase(postNewVehicleAction.fulfilled, (state, action) => {
				state.postNewVehicleSuccess = true;
				state.vehiclesList = [
					...state.vehiclesList,
					{
						id: action.payload.id,
						registration_number: action.payload.registration_number,
						trailer_number: action.payload.trailer_number,
						driver: action.payload.driver,
					},
				];
			})
			.addCase(postNewVehicleAction.pending, (state, action) => {
				state.error = null;
				state.postNewVehicleSuccess = false;
			})
			.addCase(postNewVehicleAction.rejected, (state, action) => {
				state.error = action.payload;
				state.postNewVehicleSuccess = false;
			});

		builder
			.addCase(putVehicleAction.fulfilled, (state, action) => {
				state.putVehicleSuccess = true;

				const vehicleElementInArray = state.vehiclesList.find((el) => el.id === action.payload.vehicleId);
				const index = state.vehiclesList.indexOf(vehicleElementInArray);
				const newArrayElement = {
					id: action.payload.vehicleId,
					registration_number: action.payload.formData.registration_number,
					trailer_number: action.payload.formData.trailer_number,
					driver: action.payload.formData.driver,
				};
				state.vehiclesList.splice(index, 1, newArrayElement);
			})
			.addCase(putVehicleAction.pending, (state, action) => {
				state.error = null;
				state.putVehicleSuccess = false;
			})
			.addCase(putVehicleAction.rejected, (state, action) => {
				state.error = action.payload;
				state.putVehicleSuccess = false;
			});
		builder
			.addCase(getVehicles.fulfilled, (state, action) => {
				console.log(action.payload, 'vehicle');
				state.getVehicles_success = true;
				state.getVehicles_pending = false;
				state.getVehicles_error = false;
				state.vehiclesList = action.payload;
			})
			.addCase(getVehicles.pending, (state, action) => {
				state.getVehicles_success = false;
				state.getVehicles_pending = true;
				state.getVehicles_error = false;
			})
			.addCase(getVehicles.rejected, (state, action) => {
				state.getVehicles_success = false;
				state.getVehicles_pending = false;
				state.getVehicles_error = action.payload;
			});
	},
});

export const {
	setVehicleGetSuccess,
	setVehiclePostSuccess,
	setVehiclePutSuccess,
	setVehicleId,
	setRegistrationNumber,
	setTrailerNumber,
	addVehicleToOrder,
	removeVehicleFromOrder,
	setDriverFullName,
} = vehicleSlice.actions;
export const { selectVehicleId } = vehicleSlice.selectors;
export default vehicleSlice.reducer;
