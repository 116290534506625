import { useState } from "react";
import { BtnIcon } from "../Buttons";
import { IconSearch } from "../Icons";
import styles from "./Search.module.scss";
import UniversalButton from "../Buttons/UniversalButton/UniversalButton";

function Search({ searchValue, setSearchValue }) {
    const [inputValue, setInputValue] = useState('');

    const handlerSearch = (e) => {
        setInputValue(e.target.value);
        setSearchValue(e.target.value);
    }

    return (
        <div className={`${styles.search__block} ${inputValue.trim() !== '' ? styles.active : ''}`}>
            <UniversalButton icon={<IconSearch size={'medium'} color={'primary_white'} />} size={"small"} typeButton={"button"} />
            <input onChange={(e) => handlerSearch(e)} placeholder="Начните писать, например: Константин Константинович или охранник" />
        </div>
    );
}

export default Search;