import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UniversalButton } from '../../../../../../../components';
import { setSideBarActive } from '../../../../../../../store/slices/sideBarSlice';
import { putDamagesInEntryreport, setDamagesList, setPage } from '../../../../../../../store/slices/containerAcceptanceSlice';
import styles from './DeleteModal.module.scss';

const DeleteModal = () => {
	const { reportId } = useParams();
	const dispatch = useDispatch();
	const { selectedDamage, entryOneReport } = useSelector((state) => state.containerAccept);

	const handleDeleteOneDamage = async () => {
		const updatedDamagesList = entryOneReport.damages.filter((damage) => damage.uuid !== selectedDamage.uuid);
		dispatch(setDamagesList(updatedDamagesList));
		await dispatch(putDamagesInEntryreport({ reportId }));
		updatedDamagesList.length ? dispatch(setPage(9)) : dispatch(setPage(2));
	};

	const handleCancel = () => {
		dispatch(setSideBarActive(false));
	};
	return (
		<div className={styles.deleteContainer}>
			<p className={styles.deleteTitle}>Удалить повреждение?</p>
			<p className={styles.damageTitle}>
				{selectedDamage?.side} - {selectedDamage?.damage[0]?.name}
			</p>
			<div className={styles.actionContainer}>
				<UniversalButton
					onClick={handleDeleteOneDamage}
					typeButton={'button'}
					label={'Удалить'}
					size={'large'}
					color={'error'}
					type={'with-border'}
				/>
				<UniversalButton onClick={handleCancel} typeButton={'button'} label={'Отмена'} size={'large'} color={'primary'} />
			</div>
		</div>
	);
};

export default DeleteModal;
