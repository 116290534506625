import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconUsers2({ onClick, size, color }) {
  return (
    <div className={styles.icon} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)}
        height={IconSize(size)} viewBox="0 0 11.14 10.86"><path d="M10.17,2.71A1.52,1.52,0,1,1,8.65,1.19,1.52,1.52,0,0,1,10.17,2.71Zm-4.74,3A1.42,1.42,0,1,1,4,4.31,1.42,1.42,0,0,1,5.43,5.73ZM6,11.05H6.8a2.79,2.79,0,1,0-5.57,0H2a2,2,0,1,1,4.05,0ZM7.27,6.62A2,2,0,0,1,10.61,8h.76a2.68,2.68,0,0,0-.8-1.91,2.7,2.7,0,0,0-3.84,0A2.67,2.67,0,0,0,5.94,8H6.7A2,2,0,0,1,7.27,6.62Z" transform="translate(-0.73 -0.69)" fill={IconColor(color)} /></svg>
    </div>
  );
}

export default IconUsers2;

