import styles from './Advantages.module.scss';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { IconBadge, IconBlocks, IconMapPin } from '../../../../components/Icons';

function Advantages() {
	const advantages = [
		{
			icon: <IconBadge color={'white'} size={'largeM'} />,
			title: 'Экспертиза',
			text: 'ТЕТРИС более 10 лет занимается обслуживанием и терминальной обработкой контейнеров',
		},
		{
			icon: <IconBlocks color={'white'} size={'largeM'} />,
			title: 'Комплексность',
			text: 'Накопление, переброска, растарка, затарка, хранение, ремонт, мойка контейнеров - все возможно на терминалах ТЕТРИС',
		},
		{
			icon: <IconMapPin color={'white'} size={'largeM'} />,
			title: 'География',
			text: 'ТЕТРИС – это 7 современных терминалов в Москве, Московской области и Краснодарском крае, общая вместимость которых более 50 000 TEU',
		},
	];

	const placemarks = [
		{
			coords: [56.13086356859667, 37.39080149999999],
			text: 'Терминал «СЕВЕР» Адрес: Московская область, Дмитровский район, с.Белый Раст, 5А',
		},
		{ coords: [55.49018206924636, 37.55731349999996], text: 'Терминал «ТЕТРИС» Адрес: г. Москва, г. Щербинка, ул. Южная 7А' },
		{
			coords: [55.377879569343776, 36.764154999999946],
			text: 'Терминал «МИКС» Адрес: Московская область, г. Наро-Фоминск, ул. Московская, 19',
		},
		{
			coords: [56.146001, 37.369646],
			text: 'Терминал «НОРД» Адрес: Московская область, Дмитровский район, посёлок Никольское, вл. 170',
		},
		{
			coords: [56.031453, 37.452884],
			text: 'Терминал «ЛОБНЯ» Адрес: Московская область, г.о. Лобня, г. Лобня, улица Горки Киовские, вл5',
		},
		{ coords: [44.920125, 38.015586], text: 'Терминал «КРЫМСК» Адрес: г. Крымск, ул. Строительная 51Б' },
		{ coords: [55.46347, 37.70521], text: 'Терминал «ДОМОДЕДОВО» Адрес: г.о.Домодедово, с. Домодедово, ул. Зорин Лес' },
		{
			coords: [55.73629056901608, 38.226360499999984],
			text: 'Терминал «ВОСТОК» Адрес: Московская область, Богородский городской округ, Электроугли, Заводская улица, 20с1',
		},
	];

	return (
		<div className={styles.advantages} id="advantages">
			<div className={styles.advantages__list}>
				{advantages.map((item, index) => (
					<div className={styles.advantages__item} key={index}>
						<div className={styles.advantages__title}>
							{item.icon}
							<h5>{item.title}</h5>
						</div>
						<div className={styles.advantages__text}>{item.text}</div>
					</div>
				))}
				<div className={styles.advantages__map}>
					<YMaps>
						<Map
							width="100%"
							height="320px"
							defaultState={{
								center: [51.099482998930014, 38.64713850627797],
								zoom: 4,
								controls: [],
							}}
						>
							{placemarks.map((item, ind) => {
								return (
									<Placemark
										key={ind}
										defaultGeometry={item.coords}
										options={{
											iconImageSize: [5, 5],
											preset: 'islands#redDotIcon',
										}}
										modules={['geoObject.addon.balloon']}
										properties={{
											balloonContentBody: item.text,
										}}
									/>
								);
							})}
						</Map>
					</YMaps>
				</div>
			</div>
		</div>
	);
}

export default Advantages;
