import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getEntriesListTable } from '../../api/entries/getEntriesList';
import { putEntries } from '../../api/entries/putEntries';
import { format } from 'date-fns';
import { getExportExcelEntriesApi } from '../../api/entries/getExportExcelEntriesApi';

// Лист всех пропусков для таблицы
export const getAllEntries = createAsyncThunk('entries/getAllEntries', async (_, { rejectWithValue, getState }) => {
	try {
		const state = getState();
		const filter = () => {
			let filterArray = [`?page_size=30&page=${state.entries.filters.page}`];

			// По всем ключам filters и если есть фильтр добавляет в массив
			Object.keys(state.entries.filters).forEach((key) => {
				if (key !== 'page' && state.entries.filters[key] !== null) {
					filterArray.push(`&${key}=${state.entries.filters[key]}`);
				}
			});

			// Если ordering непустой то добавляем массив
			if (state.entries.ordering !== 'undefined' && state.entries.ordering !== '-undefined' && state.entries.ordering !== '') {
				filterArray.push(`&ordering=${state.entries.ordering}`);
			}

			// Если globalSearch непустой то добавляем массив
			if (state.entries.globalSearch) {
				filterArray.push(`&search=${state.entries.globalSearch}`);
			}

			return filterArray.join('');
		};

		let response;
		if (state.entries.next && state.entries.fetching) {
			response = await getEntriesListTable(state.entries.next);
		} else if (state.entries.url && state.entries.fetching) {
			response = await getEntriesListTable(filter());
		}
		return { data: response.data, urlExport: filter() };
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

// Редактирование пропусков
export const putEntriesList = createAsyncThunk(
	'entries/putEntries',
	async ({ terminal_id, order_id, requestData }, { rejectWithValue }) => {
		try {
			const response = await putEntries(terminal_id, order_id, requestData);
			return response.data;
		} catch (error) {
			return rejectWithValue({ error: error.message, reqData: requestData });
		}
	},
);

// Экспорт таблицы в excel
export const getExportExcel = createAsyncThunk('entries/getExportExcel', async (query, { rejectWithValue, getState }) => {
	try {
		const state = getState();
		let response;
		if (query === '') {
			response = await getExportExcelEntriesApi(query, state.entries.filterExport.replace(/\?page_size=\d+&page=\d+&?/, ''));
		} else {
			response = await getExportExcelEntriesApi(query, state.entries.filterExport.replace(/\?page_size=\d+&page=\d+/, ''));
		}
		return response;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

const entriesSlice = createSlice({
	name: 'entries',
	initialState: {
		entriesList: [],
		countEntriesList: 0,
		// Первая загрузка
		firstLoad: false,

		// Infinite Scroll Entries
		fetching: true,
		url: `?page_size=30`,
		next: null,

		// Сортировка, поиск, фильтрация
		ordering: '',
		globalSearch: '',
		filters: {
			page: 1,
			name: null,
			status: null,
			order_number: null,
			container_number: null,
			transporter_org: null,
			vehicle_number: null,
			vehicle_driver: null,
			vehicle_trailer_number: null,
			seal_number: null,
			contact_number: null,
			eta_checkin_from: null,
			eta_checkin_to: null,
		},

		// Url фильтрации, сортировки и поиска для экспорта в excel
		filterExport: '',

		// Статусы для отправки запросов фильтрации
		allStatuses: {
			status: [
				{ id: 0, name: 'Пропуск создан' },
				{ id: 1, name: 'Пропуск подтвержден' },
				{ id: 2, name: 'Пропуск активирован' },
				{ id: 3, name: 'Пропуск отменён' },
				{ id: 4, name: 'Контейнер и пропуск удалены' },
			],
		},

		// Получение листа
		getAllEntries_success: false,
		getAllEntries_pending: false,
		getAllEntries_error: false,

		// Редактирование
		putEntriesList_success: false,
		putEntriesList_pending: false,
		putEntriesList_error: false,

		// Экспорт в excel
		getExportExcel_success: false,
		getExportExcel_pending: false,
		getExportExcel_error: false,
	},
	reducers: {
		setEntriesFetching(state, action) {
			state.fetching = action.payload;
		},

		// Фильтрация
		setFilters(state, action) {
			state.next = null;
			state.entriesList = [];
			state.url = '?page_size=30';

			Object.keys(state.filters).forEach((key) => {
				// Если ключ равен 'page', устанавливаем значение 1, иначе null
				state.filters[key] = key === 'page' ? 1 : null;
			});

			// Функция для поиска значений в массивах
			function getValueByKey(key, value) {
				const statuses = state.allStatuses[key];
				const status = statuses.find((status) => status.name === value);
				return status ? status.id : null;
			}

			// Формирование массива параметров запроса
			function paramsArray(array) {
				array.map((item) => {
					let paramValue;
					if (item.id === 'status_display') {
						state.filters['status'] = getValueByKey('status', item.value);
					} else if (item.id === 'eta_checkin') {
						// Форматирование даты
						const formatDate = (date) => format(date.toISOString(), 'yyyy-MM-dd');
						state.filters['eta_checkin_from'] = item.value[0]?.$d ? formatDate(item.value[0].$d) : null;
						state.filters['eta_checkin_to'] = item.value[1]?.$d ? formatDate(item.value[1].$d) : null;
					} else {
						state.filters[item.id] = item.value;
					}

					return paramValue !== null ? paramValue : '';
				});
			}

			if (action.payload) {
				paramsArray(action.payload);
			}
		},

		// Сортировка
		setOrdering(state, action) {
			state.next = null;
			state.entriesList = [];
			state.url = '?page_size=30';

			function changeSortingMore(value) {
				if (state.ordering === value) {
					state.ordering = '-' + value;
				} else {
					state.ordering = value;
				}
			}

			if (action.payload[0]?.id) {
				if (action.payload[0]?.id === 'status_display') {
					changeSortingMore('status');
				} else if (action.payload[0]?.id === 'name') {
					changeSortingMore('id');
				} else {
					changeSortingMore(`${action.payload[0]?.id}`);
				}
			} else {
				changeSortingMore(`${action.payload[0]?.id}`);
			}
		},

		// Глобальный поиск
		setGlobalSearch(state, action) {
			state.next = null;
			state.entriesList = [];
			state.url = '?page_size=30';
			state.globalSearch = '';

			if (action.payload) {
				state.globalSearch = action.payload;
			} else {
				state.globalSearch = '';
			}
		},
	},

	extraReducers: (builder) => {
		// Получение листа
		builder
			.addCase(getAllEntries.fulfilled, (state, action) => {
				state.getAllEntries_success = true;
				state.getAllEntries_pending = false;
				state.getAllEntries_rejected = false;

				// Infinite scroll
				if (action.payload.data.next) {
					const startIndex = action.payload.data.next?.indexOf('?');
					const trimmedUrl = `${action.payload.data.next?.substring(startIndex)}`;
					state.next = trimmedUrl;
					state.url = '';
				} else {
					state.next = null;
					state.url = '';
				}
				state.fetching = false;
				state.entriesList = [...state.entriesList, ...action.payload.data.results];
				state.countEntriesList = action.payload.data.count;
				state.firstLoad = true;
				state.filterExport = action.payload.urlExport;
			})
			.addCase(getAllEntries.pending, (state, action) => {
				state.getAllEntries_success = false;
				state.getAllEntries_pending = true;
				state.getAllEntries_rejected = false;
			})
			.addCase(getAllEntries.rejected, (state, action) => {
				state.getAllEntries_success = false;
				state.getAllEntries_pending = false;
				state.getAllEntries_rejected = action.payload;
			});

		// Редактирование
		builder
			.addCase(putEntriesList.fulfilled, (state, action) => {
				state.putEntriesList_success = true;
				state.putEntriesList_pending = false;
				state.putEntriesList_error = false;

				// Обновление данных после редактирования
				action.payload.forEach((entry) => {
					const index = state.entriesList.findIndex((el) => el.id === entry.id);
					if (index !== -1) {
						state.entriesList[index] = { ...state.entriesList[index], ...entry };
					}
				});
			})
			.addCase(putEntriesList.pending, (state, action) => {
				state.putEntriesList_success = false;
				state.putEntriesList_pending = true;
				state.putEntriesList_error = false;
			})
			.addCase(putEntriesList.rejected, (state, action) => {
				state.putEntriesList_success = false;
				state.putEntriesList_pending = false;
				state.putEntriesList_error = action.payload.error;

				// Не менять значения после редактирования
				action.payload.reqData.forEach((entry) => {
					const index = state.entriesList.findIndex((el) => el.id === entry.pass_pk);
					if (index !== -1) {
						state.entriesList[index] = { ...state.entriesList[index] };
					}
				});
			});
		// Экспорт в excel
		builder
			.addCase(getExportExcel.fulfilled, (state, action) => {
				state.getExportExcel_success = true;
				state.getExportExcel_pending = false;
				state.getExportExcel_error = false;

				// Скачивание файла
				let blob = new Blob([action.payload.data], {
					type: 'excel/xlsx',
				});
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				// Форматирование даты для названия файла
				let formattedDate = new Date()
					.toLocaleString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
					.replace(',', '');
				link.download = `Таблица пропусков_${formattedDate}.xlsx`;
				link.click();
			})
			.addCase(getExportExcel.pending, (state, action) => {
				state.getExportExcel_success = false;
				state.getExportExcel_pending = true;
				state.getExportExcel_error = false;
			})
			.addCase(getExportExcel.rejected, (state, action) => {
				state.getExportExcel_success = false;
				state.getExportExcel_pending = false;
				state.getExportExcel_error = action.payload;
			});
	},
});

export const { setEntriesFetching, setFilters, setOrdering, setGlobalSearch } = entriesSlice.actions;
export default entriesSlice.reducer;
