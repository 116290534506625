import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useLazyGetLogsListQuery } from '../../../../store/newApi/logs/logs';
import { formatInitialDate } from '../../../../helpers/formatDate';
import Filter from '../../../../newComponents/Filter/Filter';

function AccountEmplTableLogs() {
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});

	// колонки
	const columnsList = useMemo(
		() => [
			{
				accessorFn: (row) => row.id,
				id: 'id',
				header: 'ID',
				size: 120,
				sortDescFirst: false,
			},
			{
				accessorFn: (row) => row.full_name,
				id: 'full_name',
				header: 'ФИО',
				size: 240,
			},
			{
				accessorFn: (row) => row.user_id,
				id: 'user_id',
				header: 'ID Пользователя',
				size: 230,
				sortDescFirst: false,
			},
			{
				accessorFn: (row) => row.full_url,
				id: 'full_url',
				header: 'Ссылка',
				size: 300,
			},
			{
				accessorFn: (row) => row.ip_address,
				id: 'ip_address',
				header: 'IP адрес',
				size: 200,
			},
			{
				accessorFn: (row) => row.created_at && formatInitialDate(row.created_at),
				id: 'created_at',
				header: 'Дата и время',
				size: 340,
			},
		],
		[],
	);

	// порядок колонок
	const columnOrdering = ['mrt-row-select', 'id', 'full_name', 'user_id', 'full_url', 'ip_address', 'created_at'];

	return (
		<div className={styles.account__wrap}>
			<Header text={'История действий сотрудников'} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<Filter />
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						hookName={useLazyGetLogsListQuery}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableLogs;
