import React, { useEffect, useState } from 'react';
import styles from './DamagePage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Slider, UniversalButton } from '../../../../../../../components';
import { IconPlus } from '../../../../../../../components/Icons';
import ImageUploading from 'react-images-uploading';
import S3Manager from '../../../../../../../helpers/s3storage';
import {
	putDamagesInEntryreport,
	setDamageImagesIfSelectedDamage,
	setDamagesList,
	setSelectedSide,
} from '../../../../../../../store/slices/containerAcceptanceSlice';
import { useParams } from 'react-router-dom';
import { iconDamageSwitcher } from '../../../../../../../components/ContainerAcceptance/steps/ChoiceDamageArea/components/ContainerMap/Sides/helpers/iconDamageSwitcher';
import ContainerMap from '../../steps/ChoiceDamageArea/components/ContainerMap/ContainerMap';
import PhotoViewerComponent from '../ PhotoViewerComponent/PhotoViewerComponent';

// Вкладка фотофиксации
function DamagePagePhoto({ imagesList }) {
	const dispatch = useDispatch();
	const { reportId } = useParams();
	const [images, setImages] = useState([]);
	const imageUploader = new S3Manager('damages');

	const onChange = async (imageList, addUpdateIndex) => {
		setImages(imageList);
		const newImage = imageList[addUpdateIndex];
		if (newImage) {
			try {
				const result = await imageUploader.uploadImage({
					actId: '2',
					file: { name: 'expample.png', data_url: newImage.data_url },
				});
				const photka = await imageUploader.getImage(result.Key);
				await dispatch(setDamageImagesIfSelectedDamage({ Key: result.Key, Location: result.Location, imageSrc: photka }));
				await dispatch(putDamagesInEntryreport({ reportId: reportId }));
			} catch (error) {
				console.error('Ошибка загрузки изображения:', error);
			}
		}
	};

	return (
		<div className={styles.damagePagePhoto}>
			<PhotoViewerComponent imagesList={imagesList} />
			<div className={styles.damagePagePhoto__add}>
				<p>Фото: {imagesList?.length}</p>
				<ImageUploading
					multiple
					value={images}
					onChange={onChange}
					maxNumber={10}
					dataURLKey="data_url"
					acceptType={['jpg', 'png', 'jpeg']}
				>
					{({ imageList, onImageUpload, isDragging, dragProps }) => (
						<UniversalButton
							onClick={onImageUpload}
							label={'Добавить фото'}
							size={'medium'}
							typeButton={'button'}
							color={'accent'}
							icon={<IconPlus color={'white'} size={'large'} />}
						/>
					)}
				</ImageUploading>
			</div>
		</div>
	);
}

// Вкладка информации о повреждении
function DamagePageInfo() {
	const { selectedDamage } = useSelector((state) => state.containerAccept);
	// dimensions
	return (
		<div className={styles.container}>
			<p className={styles.infoTitle}>Информация о повреждении</p>
			<div className={styles.currentDamageCheck}>
				<div>
					<p>{selectedDamage?.damage[0]?.name}</p>
				</div>
				<div>{iconDamageSwitcher(selectedDamage?.damage[0]?.name)}</div>
			</div>
			<div>
				<p>Длина: {selectedDamage?.dimensions.l || 'н/д'}</p>
				<p>Ширина: {selectedDamage?.dimensions.w || 'н/д'}</p>
				<p>Глубина: {selectedDamage?.dimensions.d || 'н/д'}</p>
			</div>
			<div className={styles.textInfo}>
				<p>Комментарий:</p>
				<p>{selectedDamage?.dimensions?.comment}</p>
			</div>
		</div>
	);
}

// Вкладка карты повреждений
function DamagePageMap() {
	const dispatch = useDispatch();
	const { selectedDamage, containerSides } = useSelector((state) => state.containerAccept);

	const side = selectedDamage?.side;
	const currentSide = containerSides[side];

	useEffect(() => {
		dispatch(setSelectedSide(currentSide));
		dispatch(setDamagesList(selectedDamage.placement));

		return () => {
			dispatch(setDamagesList([]));
		};
	}, []);

	return (
		<div>
			<ContainerMap />
		</div>
	);
}

function DamagePage() {
	const { selectedDamage } = useSelector((state) => state.containerAccept);

	const [activeTab, setActiveTab] = useState('0');
	const [currentPage, setCurrentPage] = useState('0');

	const tabsData = [
		{ id: '1', name: 'Информация о повреждении' },
		{ id: '2', name: 'Карта' },
	];

	const renderContent = () => {
		switch (activeTab) {
			case '0':
				return <DamagePagePhoto imagesList={selectedDamage?.pictures} />;
			case '1':
				return <DamagePageInfo />;
			case '2':
				return <DamagePageMap />;
			default:
				return <DamagePagePhoto imagesList={selectedDamage?.pictures} />;
		}
	};

	return (
		<div>
			<Slider
				data={tabsData}
				allName="Фотофиксация"
				currentPage={currentPage}
				setUser_group={setActiveTab}
				setSliderCont={setCurrentPage}
			/>
			<div className={styles.damagePage__content}>{renderContent()}</div>
		</div>
	);
}

export default DamagePage;
