import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconSideEnd({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6 16V10L6 4M8.82353 14.2857V5.71429C8.82353 4.76751 9.66629 4 10.7059 4H20.1176C21.1572 4 22 4.76751 22 5.71429V14.2857C22 15.2325 21.1572 16 20.1176 16H10.7059C9.66629 16 8.82353 15.2325 8.82353 14.2857Z"
					stroke="#8E8E93"
					strokeWidth="1.41176"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}
export default IconSideEnd;
