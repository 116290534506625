import { useParams } from 'react-router-dom';
import styles from '../AccountEmpl.module.scss';
import Header from '../components/Header/Header';
import LayoutOneClient from '../components/Layouts/LayoutOneClient';
import { getClient } from '../../../../store/slices/clientSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

function AccountOneClient() {
	// Запрос на выбранного клиента
	let { clientId } = useParams();
	const dispatch = useDispatch();

	const { currentClient } = useSelector((state) => state.client);
	useEffect(() => {
		dispatch(getClient({ user_id: clientId }));
	}, [clientId]);

	return (
		<div className={styles.account__wrap}>
			<Header text={'Клиент'} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<div className={styles.account__flex}>
						<LayoutOneClient client={currentClient} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default AccountOneClient;
