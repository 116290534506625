const initialState = {
    getOneContainerSuccess: false,
    putOneContainerSuccess: false,
    putContainersSuccess: false,
    containerInfo: []
}

const containersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ONECONTAINER_SUCCESS':
            //TODO : getOneUseSuccess ???? должен быть getOneContainerSuccess
            return { ...state, containerInfo: action.payload, getOneUserSuccess: true };
        case 'PUT_ONECONTAINER_SUCCESS':
            return { ...state, putOneContainerSuccess: true };
        case 'PUT_CONTAINERS_SUCCESS':
            return { ...state, putContainersSuccess: true };
        default:
            return state;
    }
}

export default containersReducer;