import { Form, UniversalButton } from "../../../../../../components";
import { Link } from "react-router-dom";
import styles from "./Step1.module.scss";
import { useState } from "react";
import MainInput from "../../../../../../components/Inputs/MainInput/MainInput";

function Step1({ setPage }) {

  const onSubmit = (data) => {
    localStorage.setItem("email", data.email);
    setPage((currentPage) => currentPage + 1);
  };

  const [email, setEmail] = useState(localStorage.getItem("email") || "")

  return (
    <div className={styles.block}>
      <h5>Вход</h5>
      <Form onSubmit={onSubmit}>
        <MainInput
          title={'Электронная почта'}
          errorTitle={'Некорректный формат почты'}
          name="email"
          value={email}
          setValue={setEmail}
          rules={{
            required: "Обязательное поле",
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Например: ivan.petrov@mail.ru",
            },
          }}
          placeholder={'ivan.petrov@mail.ru'}
        />
        <div className={styles.block__btns}>
          <UniversalButton label={"Далее"} color={"primary"} size={"large"} typeButton={"submit"} />
          <Link to={"/regClient"}>
            <UniversalButton label={"Регистрация"} color={"primary"} size={"large"} typeButton={"button"} type={"with-border"} />
          </Link>
        </div>
      </Form>
    </div>
  );
}

export default Step1;
