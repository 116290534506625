// PriceRangeInput.js
import React from 'react';
import { TextField, Grid } from '@mui/material';

const PriceRangeInput = ({ minPrice, maxPrice, onMinPriceChange, onMaxPriceChange, text }) => {
	return (
		<Grid container spacing={1} sx={{ marginTop: '8px', width: '100%' }}>
			<Grid item sx={{ color: '#000000', width: '100%', marginBottom: '10px' }}>
				<TextField
					label={`Мин. ${text}`}
					type="number"
					value={minPrice}
					onChange={(event) => onMinPriceChange(event.target.value)}
					fullWidth
					inputProps={{ min: 0 }} // Минимальное значение цены
					sx={{
						'& .MuiOutlinedInput-root': {
							color: '#000000',
							borderColor: '#5C53A7',
							borderRadius: '12px',
							fontSize: '14px',
							'& fieldset': {
								borderColor: '#5C53A7',
								borderRadius: '12px',
							},
							'&:hover fieldset': {
								borderColor: '#5C53A7', // Убираем изменение бордера при наведении
							},
							'&.Mui-focused fieldset': {
								borderColor: '#5C53A7', // Убираем изменение бордера при фокусе
							},
						},
						'& .MuiInputBase-input': {
							padding: '8px 16px',
							'&::-webkit-inner-spin-button': {
								// Убираем стрелочки в WebKit-браузерах
								webkitАppearance: 'none',
								margin: 0,
							},
							'&::-webkit-outer-spin-button': {
								// Убираем стрелочки в WebKit-браузерах
								webkitАppearance: 'none',
								margin: 0,
							},
							'&::-moz-spinner': {
								// Убираем стрелочки в Firefox
								display: 'none',
							},
							'& [type="number"]': {
								// Убираем стрелочки в других браузерах
								appearance: 'none',
							},
						},
						'& .MuiFormLabel-root': {
							fontSize: '14px', // Размер шрифта лейбла
							top: '-8px',
							'&.MuiInputLabel-shrink': {
								color: '#5C53A7', // Цвет лейбла при поднятии
							},
						},
					}}
				/>
			</Grid>
			<Grid item sx={{ color: '#000000', width: '100%' }}>
				<TextField
					label={`Макс. ${text}`}
					type="number"
					value={maxPrice}
					onChange={(event) => onMaxPriceChange(event.target.value)}
					fullWidth
					inputProps={{ min: 0 }}
					sx={{
						'& .MuiOutlinedInput-root': {
							color: '#000000',
							borderColor: '#5C53A7',
							borderRadius: '12px',
							fontSize: '14px',
							'& fieldset': {
								borderColor: '#5C53A7',
								borderRadius: '12px',
							},
							'&:hover fieldset': {
								borderColor: '#5C53A7', // Убираем изменение бордера при наведении
							},
							'&.Mui-focused fieldset': {
								borderColor: '#5C53A7', // Убираем изменение бордера при фокусе
							},
						},
						'& .MuiInputBase-input': {
							padding: '8px 16px',
							'&::-webkit-inner-spin-button': {
								// Убираем стрелочки в WebKit-браузерах
								webkitАppearance: 'none',
								margin: 0,
							},
							'&::-webkit-outer-spin-button': {
								// Убираем стрелочки в WebKit-браузерах
								webkitАppearance: 'none',
								margin: 0,
							},
							'&::-moz-spinner': {
								// Убираем стрелочки в Firefox
								display: 'none',
							},
							'& [type="number"]': {
								// Убираем стрелочки в других браузерах
								appearance: 'none',
							},
						},
						'& .MuiFormLabel-root': {
							fontSize: '14px', // Размер шрифта лейбла
							top: '-8px',
							'&.MuiInputLabel-shrink': {
								color: '#5C53A7', // Цвет лейбла при поднятии
							},
						},
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default PriceRangeInput;
