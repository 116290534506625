import MainInput from "../../../../../components/Inputs/MainInput/MainInput";
import styles from "./Step.module.scss";
import { Form, Notification, UniversalButton } from "../../../../../components";
import { regTransporter } from "../../../../../store/actions/registarationUser";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { postRegistrationTransporter } from "../../../../../store/slices/registrationSlice";

function Step6({ setPage }) {
  const dispatch = useDispatch();
  const { registrationTransporter_success, registrationTransporter_error } = useSelector((state) => state.registration)
  const [isActive, setIsActive] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [notText, setNotText] = useState("");
  const [notDescr, setNotDescr] = useState("");
  const [notStatus, setNotStatus] = useState("");

  const addNot = (text, descr, status) => {
    setNotText(text);
    setNotDescr(descr);
    setNotStatus(status);
    setIsActive(true);
  };

  const onClose = () => {
    setIsActive(false);
  };

  const onSubmit = async (data) => {
    if (data.password === data.repeatPassword) {
      let requestData;
      requestData = {
        email: localStorage.getItem("email"),
        password: data.password,
        transporter_name: localStorage.getItem("urName"),
        transporter_address: localStorage.getItem("urAdress"),
        is_confirmed: true,
      };
      await dispatch(postRegistrationTransporter({ requestData }));
    }
    else {
      addNot('Ошибка', 'Пароли не совпадают', 'error');
    }
  };

  useEffect(() => {
    if (registrationTransporter_success) {
      setPage((currentPage) => currentPage + 1);
    }
    else if (registrationTransporter_error) {
      addNot("Ошибка", "Попробуйте снова", "error");
    }
  }, [registrationTransporter_success, registrationTransporter_error])

  return (
    <>
      <Notification
        text={notText}
        descr={notDescr}
        onClose={onClose}
        isActive={isActive}
        isStatus={notStatus}
      />
      <div className={styles.block}>
        <h5>Регистрация</h5>
        <Form onSubmit={onSubmit}>
          <MainInput
            title={'Пароль'}
            errorTitle={'Некорректный формат пароля'}
            name="password"
            value={password}
            setValue={setPassword}
            rules={{
              required: "Обязательное поле",
              minLength: {
                value: 6,
                message: "Минимум 6 символов",
              },
              pattern: {
                value: /^[^\sа-яА-Я]{6,}$/,
                message: "Латинские символы",
              },
            }}
            placeholder={'Введите пароль'}
          />
          <MainInput
            title={'Повторите пароль'}
            errorTitle={'Некорректный формат пароля'}
            name="repeatPassword"
            value={repeatPassword}
            setValue={setRepeatPassword}
            rules={{
              required: "Обязательное поле",
              minLength: {
                value: 6,
                message: "Минимум 6 символов",
              },
              pattern: {
                value: /^[^\sа-яА-Я]{6,}$/,
                message: "Латинские символы",
              },
            }}
            placeholder={'Введите пароль'}
          />
          <div className={styles.block__btns}>
            <UniversalButton label={"Продолжить"} color={"primary"} size={"large"} typeButton={"submit"} />
          </div>
        </Form>
      </div>
    </>
  );
}

export default Step6;
