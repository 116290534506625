import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { postContactUs } from '../../api/public/postContactUs';

import { getOneUser } from '../../api/erp/getOneUser';
import { getGroup } from '../../api/user/getGroup';
import { delUser } from '../../api/erp/deleteUser';
import { putUser } from '../../api/erp/putUser';
import { postNewClient } from '../../api/erp/postClient';
import { postUserApi } from '../../api/user/postUserApi';
import { getUsersApi } from '../../api/user/getUsersApi';
import { postTerminalAccessForUserApi } from '../../api/erp/postTerminalAccessForUser';
import { deleteTerminalAccessForUserApi } from '../../api/erp/deleteTerminalAccessForUser';

export const getUsers = createAsyncThunk('user/getUsers', async (_, { rejectWithValue, getState }) => {
	try {
		const state = getState();
		let response;
		if (state.user.next && state.user.fetching) {
			response = await getUsersApi(state.user.next);
			console.log('1');
		} else if (state.user.url && state.user.fetching) {
			console.log('2');
			response = await getUsersApi(state.user.url);
		}
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса user/getUsers. Не удалось получить данные');
		}
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const getUser = createAsyncThunk('user/getUser', async ({ user_id }, { rejectWithValue }) => {
	try {
		const response = await getOneUser(user_id);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса user/getUser. Не удалось получить данные');
		}
		return response.data;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const getUserGroups = createAsyncThunk('user/getUserGroups', async (_, { rejectWithValue }) => {
	try {
		const response = await getGroup();
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса user/getUserGroups. Не удалось получить данные');
		}
		return response.data;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const postUser = createAsyncThunk('user/postUser', async ({ requestData }, { rejectWithValue }) => {
	try {
		const response = await postUserApi(requestData);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса user/postUser. Не удалось отправить данные');
		}
		return response.data;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const putUserAction = createAsyncThunk(
	'user/putUserAction',
	async ({ user_id, requestData }, { getState, rejectWithValue }) => {
		try {
			console.log(requestData, 'rD');
			// const state = getState();

			// const requestData1 = {
			// 	staff_terminal: {
			// 		terminal: state.user.currentUserChoosenTerminalData.id,
			// 		group: state.user.currentUserChoosenTerminalData.group.id,
			// 		permissions: requestData.permissions,
			// 	},
			// };

			// const mergedObject = { ...requestData, ...requestData1 };
			// console.log(mergedObject);

			const response = await putUser(user_id, requestData);
			if (!response.statusText === 'OK') {
				throw new Error('Ошибка запроса user/putUser. Не удалось отправить данные');
			}
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const deleteUser = createAsyncThunk('user/deleteUser', async ({ user_id }, { rejectWithValue }) => {
	try {
		const response = await delUser(user_id);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса user/deleteUser. Не удалось отправить данные');
		}
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

// Открыть доступ к терминалу для сотрудника
export const postTerminalAccessForUser = createAsyncThunk(
	'user/postTerminalAccessForUser',
	async ({ requestData, user_id }, { rejectWithValue }) => {
		try {
			const response = await postTerminalAccessForUserApi(requestData, user_id);
			if (!response.statusText === 'OK') {
				throw new Error('Ошибка запроса user/postTerminalAccessForUser. Не удалось отправить данные');
			}
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

// Закрыть доступ к терминалу для сотрудника
export const deleteTerminalAccessForUser = createAsyncThunk(
	'user/deleteTerminalAccessForUser',
	async ({ terminal_id, user_id }, { rejectWithValue }) => {
		try {
			const response = await deleteTerminalAccessForUserApi(terminal_id, user_id);
			if (!response.statusText === 'OK') {
				throw new Error('Ошибка запроса user/deleteTerminalAccessForUser. Не удалось отправить данные');
			}
			return { response: response, terminal_id: terminal_id };
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

const userSlice = createSlice({
	name: 'user',
	initialState: {
		//Модалки
		userModalActive: false,
		userRightsModalActive: false,
		currentUserIdForModal: null,

		//Тип модалки 'create / update'
		modalType: null,

		//Выбранный пользователь для отображения данных в модалке
		currentUser: null,
		currentUserId: null,
		// Массив терминалов к которым есть доступ у выбранного пользователя
		currentUserTerminalList: [],
		// Данные выбранного терминала пользователя
		currentUserChoosenTerminalData: null,

		userList: [],

		//! Infinite Scroll
		fetching: true,
		url: `/?limit=30&offset=0&user_type=1`,
		next: null,

		//!useForm
		useFormUserStub: null,

		// Поля формы для изменения пользователя
		newFullName: '',
		newUserName: '',
		newGroup: '',
		newPassword: '',
		newEmail: '',
		newPhone: '',
		newTelegram: '',
		newWhatsapp: '',

		//Состояния для выбора группы пользователя
		selectedId: null,
		selectedOption: '',

		inputChange: false,

		//Группы пользователей (роли)
		userGroups: [],
		userPermissions: [],

		// Данные для изменение кнопки сабмита в модалке (в случае если мы меняем пермишены пользователя)
		changeModalTypeForChangeUserPermissions: false,
		// Выбранная группа на слайдере
		chosenUserGroup: 0,

		//Роль при открытии к доступу к терминалу
		terminalForAccessId: null,
		terminalAccessUserGroup: null,
		terminalAccessUserGroupId: null,
		changeModalTypeForAccesses: false,

		getUsers_success: false,
		getUsers_pending: false,
		getUsers_error: false,

		getUserGroups_success: false,
		getUserGroups_pending: false,
		getUserGroups_error: false,

		getUser_success: false,
		getUser_pending: false,
		getUser_error: false,

		postUser_success: false,
		postUser_pending: false,
		postUser_error: false,

		putUser_success: false,
		putUser_pending: false,
		putUser_error: false,

		deleteUser_success: false,
		deleteUser_pending: false,
		deleteUser_error: false,

		// Статусы запроса на открытие доступа к терминалу
		postTerminalAccessForUser_success: false,
		postTerminalAccessForUser_pending: false,
		postTerminalAccessForUser_error: false,

		//Статусы запроса на закрытие доступа к терминалу
		deleteTerminalAccessForUser_success: false,
		deleteTerminalAccessForUser_pending: false,
		deleteTerminalAccessForUser_error: false,

		//Пермишены пользователя в соответствии с ролью на выбранном терминале
		choosenTerminalUserGroupPermissions: [],
		choosenTerminalUserAddedBeforePermissions: [],
		choosenTerminalUserAddedNowPermissions: [],
		// newGroupWhileChangingPermissions: [],
		newGroupIdWhileChangingPermissions: null,
		newGroupWhileChangingPermissions: null,
	},
	reducers: {
		setNewUserInfo(state, action) {
			state.currentUser[action.payload.property] = action.payload.data;
		},
		setSelectedId(state, action) {
			state.selectedId = action.payload;
		},
		setSelectedOption(state, action) {
			// state.currentUser.group = action.payload
			state.selectedOption = action.payload;
			state.newGroup = action.payload;
		},
		setInputChange(state, action) {
			state.inputChange = action.payload;
		},
		setChosenModal(state, action) {},
		//USERS
		setUserModalActive(state, action) {
			state.userModalActive = action.payload;
		},
		setUserRightsModalActive(state, action) {
			state.userRightsModalActive = action.payload;
		},
		setCurrentUserIdForModal(state, action) {
			state.currentUserIdForModal = action.payload;
		},
		setNewFullName(state, action) {
			state.newFullName = action.payload;
		},
		setNewPhone(state, action) {
			state.newPhone = action.payload;
		},
		setNewTelegram(state, action) {
			state.newTelegram = action.payload;
		},
		setNewWhatsapp(state, action) {
			state.newWhatsapp = action.payload;
		},
		setNewUserName(state, action) {
			state.newUserName = action.payload;
		},
		setNewPassword(state, action) {
			state.newPassword = action.payload;
		},
		setNewEmail(state, action) {
			state.newEmail = action.payload;
		},
		setCurrentUser(state, action) {
			state.currentUser = action.payload;
		},
		setNewGroup(state, action) {
			state.newGroup = action.payload;
			// const currentIndex = state.userGroups.findIndex((el)=>el.name === action.payload)
			// state.selectedId = state.userGroups[state.userGroups.findIndex((el)=>el.name === action.payload)]
		},
		setModalType(state, action) {
			state.modalType = action.payload;
		},
		setUserListAfterPut(state, action) {
			state.userList.forEach((item) => {
				if (item.id == action.payload.user_id) {
					item.full_name = action.payload.updatedUser?.full_name;
					item.username = action.payload.updatedUser?.username;
					item.group = action.payload.updatedUser?.group;
					item.password = action.payload.updatedUser?.password;
					//TODO сделать пермишены
					item.group_permissions_ids = action.payload.updatedUser?.permissions;
				}
			});
		},
		setUserListAfterDelete(state, action) {
			state.userList = state.userList.filter((user) => user.id !== action.payload.user_id);
		},
		setUserFetching(state, action) {
			state.fetching = action.payload;
		},
		setUserGroup(state, action) {
			state.chosenUserGroup = action.payload;
		},
		setUserUrlAfterUserGroupChange(state, action) {
			state.url = action.payload;
		},
		setUserList(state, action) {
			state.userList = action.payload;
		},
		setUserNextNull(state, action) {
			state.next = null;
		},
		setCurrentUserId(state, action) {
			state.currentUserId = action.payload;
		},

		clearUserInputs(state, action) {
			state.newFullName = null;
			state.newUserName = null;
			state.newGroup = null;
			state.newPassword = null;
			state.newEmail = null;
			state.newPhone = null;
			state.newTelegram = null;
			state.newWhatsapp = null;
		},
		setUseFormUserStub(state, action) {
			state.useFormUserStub = action.payload;
		},

		//Права доступа у пользователя (в соответствии с его ролью)
		// setUserGroupPermissions(state, action){
		// state.userGroupPermissions= [...state.userGroupPermissions, ...action.payload]
		// },
		//Права доступа у пользователя (кастомные которые мы дополнили ранее)
		setUserChoosenBeforePermissions(state, action) {
			if (action.payload.type === 'add') {
				state.userChoosenBeforePermissions.push(action.payload.permission);
			}
			if (action.payload.type === 'remove') {
				state.userChoosenBeforePermissions = state.userChoosenBeforePermissions.filter(
					(el) => el !== action.payload.permission,
				);
			}
		},
		//Права доступа у пользователя (кастомные которые мы выбрали сейчас)
		// setChoosenTerminalUserAddedNowPermissions(state, action) {
		// 	console.log(action.payload);
		// 	if (action.payload.type === 'add') {
		// 		console.log('add type REDUX');
		// 		if (Array.isArray(action.payload.permission)) {
		// 			console.log('ARRAYY');
		// 			state.userChoosenNowPermissions = [...state.userChoosenNowPermissions, ...action.payload.permission];
		// 		} else {
		// 			console.log('NOT ARRAY');
		// 			state.userChoosenNowPermissions.push(action.payload.permission);
		// 		}
		// 	}
		// 	if (action.payload.type === 'remove') {
		// 		state.userChoosenNowPermissions = state.userChoosenNowPermissions.filter(
		// 			(el) => el !== action.payload.permission,
		// 		);
		// 	}
		//   if(action.payload.type === 'clearAll'){
		//     state.userChoosenNowPermissions = []
		//   }
		// },
		clearAllPermissionArrays(state, action) {
			state.choosenTerminalUserAddedBeforePermissions = [];
			state.userChoosenNowPermissions = [];
			state.choosenTerminalUserGroupPermissions = [];
			state.changeModalTypeForAccesses = false;
			state.terminalForAccessId = null;
			state.terminalAccessUserGroup = null;
			state.terminalAccessUserGroupId = null;
			state.changeModalTypeForAccesses = false;
			state.currentUserTerminalList = [];
			state.currentUserChoosenTerminalData = null;
			state.currentUser = null;
			state.changeModalTypeForChangeUserPermissions = false;
			state.terminalAccessUserGroup = null;
			state.terminalAccessUserGroupId = null;
			state.newGroupWhileChangingPermissions = null;
		},
		clearNessesaryDataBeforeCreatingUserAccessToTerminal(state, action) {
			state.choosenTerminalUserAddedBeforePermissions = [];
			state.choosenTerminalUserAddedNowPermissions = [];
			state.userChoosenNowPermissions = [];
			state.choosenTerminalUserGroupPermissions = [];
		},
		// Массив терминалов к которым есть доступ у выбранного пользователя
		setCurrentUserTerminalsList(state, action) {
			state.currentUserTerminalList = action.payload;
		},
		// Данные выбранного терминала пользователя
		setCurrentUserChoosenTerminalData(state, action) {
			state.currentUserChoosenTerminalData = state.currentUserTerminalList.find((item) => item.id == action.payload);
		},
		// Поиск данных терминала при открытии доступа к нему
		setCurrentUserNewTerminalData(state, action) {
			state.currentUserChoosenTerminalData = action.payload.terminalList.find((item) => item.id == action.payload.id);
		},
		setChoosenTerminalUserGroupPermissions(state, action) {
			state.choosenTerminalUserGroupPermissions = action.payload;
		},
		setChoosenTerminalUserAddedBeforePermissions(state, action) {
			// state.choosenTerminalUserAddedBeforePermissions = action.payload;
			//TODO 20.03.2024
			// if (action.payload.type === 'add' && Array.isArray(action.payload.permission)) {
			// 	state.choosenTerminalUserAddedBeforePermissions = action.payload.permission;
			// }
			// if (action.payload.type === 'remove') {
			// 	state.choosenTerminalUserAddedBeforePermissions = state.choosenTerminalUserAddedBeforePermissions.filter(
			// 		(el) => el.id !== action.payload.permission.id,
			// 	);
			// }

			//TODO 20.03.2024
			if (action.payload.type === 'add') {
				if (Array.isArray(action.payload.permission)) {
					const unique = action.payload?.permission?.filter(
						(permission, index, self) =>
							index ===
							self.findIndex((p) => p.id === permission.id && p.name === permission.name && p.type === permission.type),
					);
					state.choosenTerminalUserAddedBeforePermissions = [
						...state.choosenTerminalUserAddedBeforePermissions,
						...unique,
					].filter(
						(permission, index, self) =>
							index ===
							self.findIndex((p) => p.id === permission.id && p.name === permission.name && p.type === permission.type),
					);
				} else {
					state.choosenTerminalUserAddedBeforePermissions.push(action.payload.permission);
				}
			}
			if (action.payload.type === 'remove') {
				if (Array.isArray(action.payload.permission)) {
					if (action.payload?.option === 'ifArrayThenRemoveNowAndBefore') {
						state.choosenTerminalUserAddedBeforePermissions = state.choosenTerminalUserAddedBeforePermissions.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
						state.choosenTerminalUserAddedNowPermissions = state.choosenTerminalUserAddedNowPermissions.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
					} else if (action.payload?.option !== 'ifArrayThenRemoveNowAndBefore' || !action.payload?.option) {
						state.choosenTerminalUserAddedBeforePermissions = state.choosenTerminalUserAddedBeforePermissions.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
					}

					// [
					// 	...state.choosenTerminalUserAddedNowPermissions,
					// 	...action.payload.permission,
					// ];
				} else {
					state.choosenTerminalUserAddedBeforePermissions = state.choosenTerminalUserAddedBeforePermissions.filter(
						(el) => el.id !== action.payload.permission.id,
					);
				}
			}
			if (action.payload.type === 'checkIfRoleWasChaged') {
				state.choosenTerminalUserAddedBeforePermissions = action.payload.permission;
			}
		},
		//! Установка новых пермишенов для уже открытого ранее терминала
		setChoosenTerminalUserAddedNowPermissions(state, action) {
			if (action.payload.type === 'add') {
				if (Array.isArray(action.payload.permission)) {
					const unique = action.payload?.permission?.filter(
						(permission, index, self) =>
							index ===
							self.findIndex((p) => p.id === permission.id && p.name === permission.name && p.type === permission.type),
					);
					state.choosenTerminalUserAddedNowPermissions = [
						...state.choosenTerminalUserAddedNowPermissions,
						...unique,
					].filter(
						(permission, index, self) =>
							index ===
							self.findIndex((p) => p.id === permission.id && p.name === permission.name && p.type === permission.type),
					);
				} else {
					state.choosenTerminalUserAddedNowPermissions.push(action.payload.permission);
				}
			}
			if (action.payload.type === 'remove') {
				if (Array.isArray(action.payload.permission)) {
					if (action.payload?.option === 'ifArrayThenRemoveNowAndBefore') {
						state.choosenTerminalUserAddedNowPermissions = state.choosenTerminalUserAddedNowPermissions.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
						state.choosenTerminalUserAddedBeforePermissions = state.choosenTerminalUserAddedBeforePermissions.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
					} else if (action.payload?.option !== 'ifArrayThenRemoveNowAndBefore' || !action.payload?.option) {
						state.choosenTerminalUserAddedNowPermissions = state.choosenTerminalUserAddedNowPermissions.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
					}

					// [
					// 	...state.choosenTerminalUserAddedNowPermissions,
					// 	...action.payload.permission,
					// ];
				} else {
					state.choosenTerminalUserAddedNowPermissions = state.choosenTerminalUserAddedNowPermissions.filter(
						(el) => el.id !== action.payload.permission.id,
					);
				}
			}
			if (action.payload.type === 'clearAll') {
				state.choosenTerminalUserAddedNowPermissions = [];
			}
		},

		//! При открытии доступа - установка выбранной группы для наследования прав
		setTerminalAccessUserGroup(state, action) {
			state.choosenTerminalUserAddedNowPermissions = [];
			state.terminalAccessUserGroup = action.payload;
			const foundRole = state.userGroups?.find((item) => item.name === action.payload);
			if (foundRole.permissions) {
				state.choosenTerminalUserGroupPermissions = foundRole.permissions;
			} else {
				state.choosenTerminalUserGroupPermissions = [];
			}
		},
		setTerminalAccessUserGroupId(state, action) {
			state.terminalAccessUserGroupId = action.payload;
		},

		//! При открытии доступа и назначении роли - выбор кастомных пермишенов
		setTerminalAccessUserAddedNowPermissions(state, action) {
			if (action.payload.type === 'add') {
				if (Array.isArray(action.payload.permission)) {
					const unique = action.payload?.permission?.filter(
						(permission, index, self) =>
							index ===
							self.findIndex((p) => p.id === permission.id && p.name === permission.name && p.type === permission.type),
					);
					state.choosenTerminalUserAddedNowPermissions = [
						...state.choosenTerminalUserAddedNowPermissions,
						...unique,
					].filter(
						(permission, index, self) =>
							index ===
							self.findIndex((p) => p.id === permission.id && p.name === permission.name && p.type === permission.type),
					);
				} else {
					state.choosenTerminalUserAddedNowPermissions.push(action.payload.permission);
				}
			}
			if (action.payload.type === 'remove') {
				if (Array.isArray(action.payload.permission)) {
					if (action.payload?.option === 'ifArrayThenRemoveNowAndBefore') {
						state.choosenTerminalUserAddedNowPermissions = state.choosenTerminalUserAddedNowPermissions.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
					} else if (action.payload?.option !== 'ifArrayThenRemoveNowAndBefore') {
						state.choosenTerminalUserAddedNowPermissions = state.choosenTerminalUserAddedNowPermissions.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
					}

					// [
					// 	...state.choosenTerminalUserAddedNowPermissions,
					// 	...action.payload.permission,
					// ];
				} else {
					state.choosenTerminalUserAddedNowPermissions = state.choosenTerminalUserAddedNowPermissions.filter(
						(el) => el.id !== action.payload.permission.id,
					);
				}

				// state.choosenTerminalUserAddedNowPermissions = state.choosenTerminalUserAddedNowPermissions.filter(
				// 	(el) => el.id !== action.payload.permission.id,
				// );
			}
			if (action.payload.type === 'clearAll') {
				state.choosenTerminalUserAddedNowPermissions = [];
			}
		},
		setTerminalForAccessId(state, action) {
			state.terminalForAccessId = action.payload;
		},

		setChangeModalTypeForAccesses(state, action) {
			state.changeModalTypeForAccesses = action.payload;
		},
		setUserUrl(state, action) {
			state.url = `/?limit=${action.payload}&offset=0&user_type=1`;
		},
		setChangeModalTypeForChangeUserPermissions(state, action) {
			state.changeModalTypeForChangeUserPermissions = action.payload;
		},
		//! В случае если для открытого терминала и назначенной роли - мы меняем роль
		setNewGroupWhileChangingPermissions(state, action) {
			// state.newGroupWhileChangingPermissions =action.payload
			// state.choosenTerminalUserGroupPermissions =
			const foundRole = state.userGroups?.find((item) => item.name === action.payload);
			if (foundRole.permissions) {
				state.choosenTerminalUserAddedBeforePermissions = [];
				state.choosenTerminalUserAddedNowPermissions = [];
				state.choosenTerminalUserGroupPermissions = foundRole.permissions;
			} else {
				state.choosenTerminalUserGroupPermissions = [];
			}
			state.newGroupWhileChangingPermissions = action.payload;
		},
		setNewGroupIdWhileChangingPermissions(state, action) {
			state.newGroupIdWhileChangingPermissions = action.payload;
		},
		resetPermissionsChanges(state, action) {
			state.choosenTerminalUserAddedNowPermissions = [];
			state.choosenTerminalUserAddedBeforePermissions = state.currentUserChoosenTerminalData.permissions;
			if (state.newGroupIdWhileChangingPermissions) {
				state.newGroupIdWhileChangingPermissions = null;
				state.newGroupWhileChangingPermissions = null;
				const foundRole = state.userGroups?.find(
					(item) => item.name === state.currentUserChoosenTerminalData.group.name,
				);
				if (foundRole?.permissions) {
					state.choosenTerminalUserGroupPermissions = state.choosenTerminalUserGroupPermissions = foundRole.permissions;
				} else {
					state.choosenTerminalUserGroupPermissions = [];
				}
			}
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(getUsers.fulfilled, (state, action) => {
				state.getUsers_success = true;
				state.getUsers_pending = false;
				state.getUsers_error = false;
				// TODO

				// state.currentUser = action.payload.data.results;

				if (action.payload.data.next) {
					const startIndex = action.payload.data.next?.indexOf('?');
					const trimmedUrl = `/${action.payload.data.next?.substring(startIndex)}`;
					// ${state.clientGroup == 0 ? '' : `&is_manager_confirmed=${state.clientGroup}`}
					state.next = trimmedUrl;
					state.url = '';
				} else {
					state.next = null;
					state.url = '';
				}
				state.fetching = false;
				state.userList = [...state.userList, ...action.payload.data.results];
			})
			.addCase(getUsers.pending, (state, action) => {
				state.getUsers_success = false;
				state.getUsers_pending = true;
				state.getUsers_error = false;
			})
			.addCase(getUsers.rejected, (state, action) => {
				state.getUsers_success = false;
				state.getUsers_pending = false;
				state.getUsers_error = action.payload;
			});

		builder
			.addCase(getUser.fulfilled, (state, action) => {
				state.getUser_success = true;
				state.getUser_pending = false;
				state.getUser_error = false;
				state.currentUser = action.payload;
				state.selectedOption = action.payload.group;

				//Terminals
				state.currentUserTerminalList = action.payload.terminals;

				// state.currentUserDataForChange = action.payload;
			})
			.addCase(getUser.pending, (state, action) => {
				state.getUser_success = false;
				state.getUser_pending = true;
				state.getUser_error = false;
			})
			.addCase(getUser.rejected, (state, action) => {
				state.getUser_success = false;
				state.getUser_pending = false;
				state.getUser_error = action.payload;
			});
		builder
			.addCase(deleteUser.fulfilled, (state, action) => {
				state.deleteUser_success = true;
				state.deleteUser_pending = false;
				state.deleteUser_error = false;
				// state.currentUser = action.payload;
				// state.currentUserDataForChange = action.payload;
			})
			.addCase(deleteUser.pending, (state, action) => {
				state.deleteUser_success = false;
				state.deleteUser_pending = true;
				state.deleteUser_error = false;
			})
			.addCase(deleteUser.rejected, (state, action) => {
				state.deleteUser_success = false;
				state.deleteUser_pending = false;
				state.deleteUser_error = action.payload;
			});
		builder
			.addCase(getUserGroups.fulfilled, (state, action) => {
				state.getUserGroups_success = true;
				state.getUserGroups_pending = false;
				state.getUserGroups_error = false;
				state.userGroups = action.payload;
			})
			.addCase(getUserGroups.pending, (state, action) => {
				state.getUserGroups_success = false;
				state.getUserGroups_pending = true;
				state.getUserGroups_error = false;
			})
			.addCase(getUserGroups.rejected, (state, action) => {
				state.getUserGroups_success = false;
				state.getUserGroups_pending = false;
				state.getUserGroups_error = action.payload;
			});
		builder
			.addCase(putUserAction.fulfilled, (state, action) => {
				state.putUser_success = true;
				state.putUser_pending = false;
				state.putUser_error = false;
			})
			.addCase(putUserAction.pending, (state, action) => {
				state.putUser_success = false;
				state.putUser_pending = true;
				state.putUser_error = false;
			})
			.addCase(putUserAction.rejected, (state, action) => {
				state.putUser_success = false;
				state.putUser_pending = false;
				state.putUser_error = action.payload;
			});
		builder
			.addCase(postUser.fulfilled, (state, action) => {
				state.postUser_success = true;
				state.postUser_pending = false;
				state.postUser_error = false;
			})
			.addCase(postUser.pending, (state, action) => {
				state.postUser_success = false;
				state.postUser_pending = true;
				state.postUser_error = false;
			})
			.addCase(postUser.rejected, (state, action) => {
				state.postUser_success = true;
				state.postUser_pending = false;
				state.postUser_error = action.payload;
			});

		builder
			.addCase(postTerminalAccessForUser.fulfilled, (state, action) => {
				state.postTerminalAccessForUser_success = true;
				state.postTerminalAccessForUser_pending = false;
				state.postTerminalAccessForUser_error = false;
				state.currentUserTerminalList.push(action.payload.data);
			})
			.addCase(postTerminalAccessForUser.pending, (state, action) => {
				state.postTerminalAccessForUser_success = false;
				state.postTerminalAccessForUser_pending = true;
				state.postTerminalAccessForUser_error = false;
			})
			.addCase(postTerminalAccessForUser.rejected, (state, action) => {
				state.postTerminalAccessForUser_success = true;
				state.postTerminalAccessForUser_pending = false;
				state.postTerminalAccessForUser_error = action.payload;
			});

		builder
			.addCase(deleteTerminalAccessForUser.fulfilled, (state, action) => {
				state.deleteTerminalAccessForUser_success = true;
				state.deleteTerminalAccessForUser_pending = false;
				state.deleteTerminalAccessForUser_error = false;
				state.currentUserTerminalList = state.currentUserTerminalList.filter(
					(el) => el.id !== action.payload.terminal_id,
				);
			})
			.addCase(deleteTerminalAccessForUser.pending, (state, action) => {
				state.deleteTerminalAccessForUser_success = false;
				state.deleteTerminalAccessForUser_pending = true;
				state.deleteTerminalAccessForUser_error = false;
			})
			.addCase(deleteTerminalAccessForUser.rejected, (state, action) => {
				state.deleteTerminalAccessForUser_success = true;
				state.deleteTerminalAccessForUser_pending = false;
				state.deleteTerminalAccessForUser_error = action.payload;
			});
	},
});

export const {
	setCurrentUserNewTerminalData,
	resetPermissionsChanges,
	setNewGroupIdWhileChangingPermissions,
	setNewGroupWhileChangingPermissions,
	setTerminalAccessUserAddedNowPermissions,
	setChangeModalTypeForChangeUserPermissions,
	clearNessesaryDataBeforeCreatingUserAccessToTerminal,
	setChangeModalTypeForAccesses,
	setTerminalForAccessId,
	setTerminalAccessUserGroupId,
	setTerminalAccessUserGroup,
	setChoosenTerminalUserAddedNowPermissions,
	setChoosenTerminalUserGroupPermissions,
	setChoosenTerminalUserAddedBeforePermissions,
	setCurrentUserTerminalsData,
	setCurrentUserChoosenTerminalData,
	clearAllPermissionArrays,
	setUserGroupPermissions,
	setUserChoosenBeforePermissions,
	// setUserChoosenNowPermissions,
	setUseFormUserStub,
	clearUserInputs,
	setCurrentUserId,
	setNewPassword,
	setUserListAfterDelete,
	setUserNextNull,
	setUserList,
	setUserUrlAfterUserGroupChange,
	setUserGroup,
	setUserFetching,
	setUserListAfterPut,
	setModalType,
	setNewGroup,
	setNewFullName,
	setNewUserName,
	setNewEmail,
	setNewPhone,
	setNewTelegram,
	setNewWhatsapp,
	setInputChange,
	setSelectedOption,
	setCurrentUser,
	setNewUserInfo,
	setSelectedId,
	setUserModalActive,
	setUserRightsModalActive,
	setCurrentUserIdForModal,
	setUserUrl,
} = userSlice.actions;
export default userSlice.reducer;
