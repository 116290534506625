import React, { useState, useEffect } from "react";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import Step6 from "./components/Step6";

import { IconArrowLeft, IconUserPlus } from "../../../../components/Icons";
import { CounterStep, Header, UniversalButton } from "../../../../components";
import styles from "./ManRegClient.module.scss";
import { useNavigate } from "react-router-dom"

function ManRegClient() {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  localStorage.removeItem("authClientStep");
  localStorage.removeItem("adminRegStep");
  localStorage.removeItem("regClientStep");

  useEffect(() => {
    const savedStep = localStorage.getItem("manRegClientStep");
    if (savedStep) {
      setPage(parseInt(savedStep));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("manRegClientStep", page.toString());
  }, [page]);

  const handleClickBack = () => {
    if (page > 0) {
      setPage(page - 1);
    } else if (page === 0) {
      localStorage.clear();
      navigate("/accountEmpl/clients")
    }
  };

  const pageDisplay = () => {
    if (page === 0) {
      return <Step1 setPage={setPage} />;
    } else if (page === 1) {
      return <Step2 setPage={setPage} />;
    } else if (page === 2) {
      return <Step3 setPage={setPage} />;
    } else if (page === 3) {
      return <Step4 setPage={setPage} />;
    } else if (page === 4) {
      return <Step5 setPage={setPage} />;
    } else if (page === 5) {
      return <Step6 setPage={setPage} />;
    }
  };

  const handlerClearStorage = () => {
    localStorage.clear();
    setPage(0)
  }

  return (
    <div className="backImg">
      <Header />
      <div className="container-center">
        <div className={styles.navBar}>
          {page !== 5 ? (
            <UniversalButton icon={<IconArrowLeft size={"largeS"} color={"black"} />} onClick={handleClickBack} size={"large"} typeButton={"button"} />
          ) : (
            <UniversalButton icon={<IconUserPlus size={"large"} color={"black"} />} onClick={handlerClearStorage} size={"large"} typeButton={"button"} />
          )}
          <CounterStep step={page + 1} allStep={"6"} />
        </div>
        {pageDisplay()}
      </div>
    </div>
  );
}

export default ManRegClient;
