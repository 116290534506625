import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconDisketa({ onClick, size, color }) {
  return (
    <div className={styles.icon} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={IconSize(size)}
        height={IconSize(size)}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M2.5 15.8333H2H2.5ZM4.16667 2.5V2V2.5ZM13.3333 2.5L13.6869 2.14645C13.5931 2.05268 13.4659 2 13.3333 2V2.5ZM17.5 6.66667H18C18 6.53406 17.9473 6.40688 17.8536 6.31311L17.5 6.66667ZM13.6667 17.5C13.6667 17.7761 13.8905 18 14.1667 18C14.4428 18 14.6667 17.7761 14.6667 17.5H13.6667ZM14.1667 10.8333H14.6667C14.6667 10.5572 14.4428 10.3333 14.1667 10.3333V10.8333ZM5.83333 10.8333V10.3333C5.55719 10.3333 5.33333 10.5572 5.33333 10.8333H5.83333ZM5.33333 17.5C5.33333 17.7761 5.55719 18 5.83333 18C6.10948 18 6.33333 17.7761 6.33333 17.5H5.33333ZM6.33333 2.5C6.33333 2.22386 6.10948 2 5.83333 2C5.55719 2 5.33333 2.22386 5.33333 2.5H6.33333ZM5.83333 6.66667H5.33333C5.33333 6.94281 5.55719 7.16667 5.83333 7.16667V6.66667ZM12.5 7.16667C12.7761 7.16667 13 6.94281 13 6.66667C13 6.39052 12.7761 6.16667 12.5 6.16667V7.16667ZM15.8333 17H4.16667V18H15.8333V17ZM4.16667 17C3.85725 17 3.5605 16.8771 3.34171 16.6583L2.6346 17.3654C3.04093 17.7717 3.59203 18 4.16667 18V17ZM3.34171 16.6583C3.12292 16.4395 3 16.1428 3 15.8333H2C2 16.408 2.22827 16.9591 2.6346 17.3654L3.34171 16.6583ZM3 15.8333V4.16667H2V15.8333H3ZM3 4.16667C3 3.85725 3.12292 3.5605 3.34171 3.34171L2.6346 2.6346C2.22827 3.04093 2 3.59203 2 4.16667H3ZM3.34171 3.34171C3.5605 3.12292 3.85725 3 4.16667 3V2C3.59203 2 3.04093 2.22827 2.6346 2.6346L3.34171 3.34171ZM4.16667 3H13.3333V2H4.16667V3ZM12.9798 2.85355L17.1464 7.02022L17.8536 6.31311L13.6869 2.14645L12.9798 2.85355ZM17 6.66667V15.8333H18V6.66667H17ZM17 15.8333C17 16.1428 16.8771 16.4395 16.6583 16.6583L17.3654 17.3654C17.7717 16.9591 18 16.408 18 15.8333H17ZM16.6583 16.6583C16.4395 16.8771 16.1428 17 15.8333 17V18C16.408 18 16.9591 17.7717 17.3654 17.3654L16.6583 16.6583ZM14.6667 17.5V10.8333H13.6667V17.5H14.6667ZM14.1667 10.3333H5.83333V11.3333H14.1667V10.3333ZM5.33333 10.8333V17.5H6.33333V10.8333H5.33333ZM5.33333 2.5V6.66667H6.33333V2.5H5.33333ZM5.83333 7.16667H12.5V6.16667H5.83333V7.16667Z"
          fill={color ? IconColor(color) : '#FFFBFF'}
        />
      </svg>
    </div>
  );
}

export default IconDisketa;
