import React from 'react';
import styles from './ModalClientInfo.module.scss';
import { UniversalButton } from '../../../../../../components';
import { IconArrowRight } from '../../../../../../components/Icons';
import { useDispatch } from 'react-redux';
function ModalClientInfo({ modalClient, handleNextStep }) {
	console.log(modalClient, 'modalClient');
	return (
		<div className={styles.container}>
			{/* <div className={styles.nav_cross}>
				<div>Основное </div>
			</div> */}
			<div className={styles.content}>
				<div className={styles.top}>
					<div className={styles.clientData}>
						<p className={styles.title}>Данные о клиенте</p>
						<p>{modalClient.organization_name}</p>
						<p>
							Договор: №{modalClient?.contract_data?.contract_number} от {'Ждем с бека'}
						</p>
						<p>Адрес: {modalClient.organization_address}</p>
					</div>
					<div className={styles.clientContacts}>
						<p className={styles.title}>Контактные данные</p>
						<p>Телеграм: @mail.ru</p>
						<p>Телефон: 79119881962</p>
						<p>Email: test@mail.ru</p>
					</div>
				</div>
				<div className={styles.actionContainer}>
					<UniversalButton
						label={'Продолжить'}
						icon={<IconArrowRight color={'white'} size={'largeS'} />}
						color={'accent'}
						size={'medium'}
						onClick={handleNextStep}
					/>
				</div>
			</div>
		</div>
	);
}

export default ModalClientInfo;
