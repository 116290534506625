import { useEffect } from 'react';
import ChoiceClient from '../ChoiceClient/ChoiceClient';
import styles from './CreateOrderSteps.module.scss';
import instance from '../../../api/erp/configAxios';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../store/slices/sideBarSlice';
import { getLines, postNewOrder, setNewOrganizationId } from '../../../store/slices/orderSlice';
import { useLocation } from 'react-router-dom';
import ModalClientInfo from '../../../pages/Account/AccountEmpl/components/Modals/ModalsCllient/ModalClientInfo';

function CreateOrderStep2({ setPage, mob, client, setClient }) {
	const dispatch = useDispatch();
	const location = useLocation();
	const isExport = location.pathname.split('/')[2] === 'createExportOrder';

	//активное модальное окно
	const { sideBarActive } = useSelector((state) => state.sideBar);
	const { newOrder_terminal_id, newOrder_organization_id } = useSelector((state) => state.order);

	//хендлер на получение юзера
	const handleClientInfo = (el) => {
		console.log('А создантся у нас заявочка на 1 - ввоз/ 2- вывоз', !isExport ? 1 : 2);
		dispatch(
			postNewOrder({
				terminal_id: newOrder_terminal_id,
				requestData: {
					status: 0,
					org_id: el.id,
					order_type: !isExport ? 1 : 2,
				},
			}),
		);
		// localStorage.setItem('chosenClientId', `${el.id}`);
		dispatch(setCurrentElementForSideBar(<ModalClientInfo modalClient={el} handleNextStep={() => handleNext(el.id)} />));
		dispatch(setSideBarActive(el.id));
	};

	const handleNext = (el) => {
		dispatch(setSideBarActive(false));
		dispatch(setNewOrganizationId(el));
		setPage(2);
	};

	//Закрытие мод окна при размонитровании!
	useEffect(() => {
		return () => {
			dispatch(setSideBarActive(false));
		};
	}, []);

	return (
		<div
			className={`${!mob ? styles.account__step : styles.mob_account__step} ${sideBarActive ? styles.choiceClient_withSidebar : ''}`}
		>
			<ChoiceClient handleClientInfo={handleClientInfo} client={client} />
		</div>
	);
}

export default CreateOrderStep2;
