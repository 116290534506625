import styles from "./Step.module.scss";
import { Form, UniversalButton } from "../../../../../components";
import { useState } from "react";
import MainInput from "../../../../../components/Inputs/MainInput/MainInput";

function Step3({ setPage }) {
  const onSubmit = (data) => {
    localStorage.setItem("login", data.login);
    setPage((currentPage) => currentPage + 1);
  };

  let typeUser = localStorage.getItem("typeUser");

  const [userName, setUserName] = useState(localStorage.getItem("login") || "")

  return (
    <div className={styles.block}>
      <h5>{typeUser === 'Новый клиент' ? typeUser : `Новый ${typeUser.toLowerCase()}`}</h5>
      <Form onSubmit={onSubmit}>
        <MainInput
          title={'Логин'}
          errorTitle={'Некорректный формат логина'}
          value={userName}
          setValue={setUserName}
          name="login"
          rules={{
            required: "Обязательное поле",
            minLength: {
              value: 2,
              message: "Минимум 2 символа",
            },
          }}
          placeholder={'Введите логин'}
        />
        <div className={styles.block__btns}>
          <UniversalButton label={"Продолжить"} color={"primary"} size={"large"} typeButton={"submit"} />
        </div>
      </Form>
    </div>
  );
}

export default Step3;
