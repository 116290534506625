import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconSideDoor({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M22 4V10V16M19.1765 5.71429V14.2857C19.1765 15.2325 18.3337 16 17.2941 16H7.88235C6.84276 16 6 15.2325 6 14.2857V5.71429C6 4.76751 6.84276 4 7.88235 4L17.2941 4C18.3337 4 19.1765 4.76751 19.1765 5.71429Z"
					stroke="#8E8E93"
					strokeWidth="1.41176"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}

export default IconSideDoor;
