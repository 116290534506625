import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPermissions } from "../../api/erp/getPermissions";
import { getPermissionListApi } from "../../api/permission/getPermissionListApi";

export const getPermissionList = createAsyncThunk(
  "permissions/getPermissionList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPermissionListApi();
      if (!response.statusText === "OK") {
        throw new Error(
          "Ошибка запроса permissions/getPermissionList. Не удалось отправить данные"
        );
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const permissionsSlice = createSlice({
  name: "permissions",
  initialState: {
    permissionList: [],

    getPermissionList_success: false,
    getPermissionList_pending: false,
    getPermissionList_error: false,

    // Изменение пермишенов у сотрудника (добавление к существующей роли)
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPermissionList.fulfilled, (state, action) => {
        state.permissionList = action.payload;
  
        state.getPermissionList_success = true;
        state.getPermissionList_pending = false;
        state.getPermissionList_error = false;
      })
      .addCase(getPermissionList.pending, (state, action) => {
        state.getPermissionList_success = false;
        state.getPermissionList_pending = true;
        state.getPermissionList_error = false;
      })
      .addCase(getPermissionList.rejected, (state, action) => {
        state.getPermissionList_success = true;
        state.getPermissionList_pending = false;
        state.getPermissionList_error = action.payload;
      });
  },
});

export const {} = permissionsSlice.actions;
export default permissionsSlice.reducer;
