import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './Slider.scss';
import { useState } from 'react';
import { memo } from 'react';
import { IconCircleInfo, IconHome, IconUser } from '../Icons';

const Slider = memo(function Slider({ data, user_group, setUser_group, setSliderCont, allName, currentPage }) {
	const [active, setActive] = useState(false);

	function handleSlide(e) {
		setActive(!active);
		// setUser_group(e.currentTarget.id);
		if (setUser_group) {
			setUser_group(e.currentTarget.id);
		}
		if (setSliderCont) {
			setSliderCont(e.currentTarget.id);
		}
	}

	return (
		<Swiper spaceBetween={8} slidesPerView={'auto'}>
			<SwiperSlide
				id="0"
				onClick={(e) => handleSlide(e)}
				// className={user_group == 0 ? 'active' : ''}
				className={user_group == 0 || currentPage == 0 ? 'active' : ''}
			>
				<span>{allName}</span>
				{allName === 'Основное' && <IconCircleInfo size={'largeS'} color={'white'} />}
				{allName === 'Клиент' && <IconUser size={'largeS'} color={'white'} />}
				{allName === 'Терминал' && <IconHome size={'largeS'} color={'white'} />}
			</SwiperSlide>
			{data?.map(
				(el, ind) =>
					el && (
						<SwiperSlide
							key={ind}
							id={el?.id}
							onClick={(e) => handleSlide(e)}
							className={user_group == el?.id || currentPage == el?.id ? 'active' : ''}
						>
							<span>{el?.name}</span>
							{el?.icon}
						</SwiperSlide>
					),
			)}
		</Swiper>
	);
});

export default Slider;
