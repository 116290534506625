import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconCloseLock({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 20 20" fill="none">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M14.1666 8.33333V6.66667C14.1666 4.36548 12.3011 2.5 9.99996 2.5C7.69877 2.5 5.83329 4.36548 5.83329 6.66667V8.33333H4.99996C4.53972 8.33333 4.16663 8.70643 4.16663 9.16667V13.3333C4.16663 15.6345 6.03211 17.5 8.33329 17.5H11.6666C13.9678 17.5 15.8333 15.6345 15.8333 13.3333V9.16667C15.8333 8.70643 15.4602 8.33333 15 8.33333H14.1666ZM12.9166 8.33333H7.08329V6.66667C7.08329 5.05584 8.38913 3.75 9.99996 3.75C11.6108 3.75 12.9166 5.05584 12.9166 6.66667V8.33333ZM5.41663 9.58333V13.3333C5.41663 14.9442 6.72246 16.25 8.33329 16.25H11.6666C13.2775 16.25 14.5833 14.9442 14.5833 13.3333V9.58333H5.41663ZM11.2575 12.0859C11.2575 12.5519 11.0028 12.9585 10.625 13.1742V14.1667C10.625 14.3968 10.4384 14.5833 10.2083 14.5833H9.79163C9.56151 14.5833 9.37496 14.3968 9.37496 14.1667V13.1699C9.00125 12.953 8.74996 12.5488 8.74996 12.0859C8.74996 11.3941 9.31129 10.8333 10.0037 10.8333C10.6962 10.8333 11.2575 11.3941 11.2575 12.0859Z"
					fill={IconColor(color)}
				/>
			</svg>
		</div>
	);
}

export default IconCloseLock;
