import { IconDamageCont, IconDamageCrack, IconDamageDeform, IconDamageEmpty } from '../../../../../../../Icons';

export const iconDamageSwitcher = (damage) => {
	switch (damage) {
		case 'Деформация':
			return <IconDamageDeform size={'large'} />;
		case 'Трещина':
			return <IconDamageCrack size={'large'} />;
		case 'Отсутствие':
			return <IconDamageEmpty size={'large'} />;
		case 'Прочее':
			return <IconDamageCont size={'large'} />;
		default:
			return <IconDamageDeform size={'large'} />;
	}
};
