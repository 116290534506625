import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMeApi } from '../../api/me/getMeApi';

export const getMe = createAsyncThunk('me/getMe', async (_, { dispatch, rejectWithValue }) => {
	try {
		const response = await getMeApi();
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

const meSlice = createSlice({
	name: 'me',
	initialState: {
		userData: [],
		getMe_success: false,
		getMe_pending: false,
		getMe_error: false,
	},

	reducers: {
		setUserPermissions(state, action) {
			state.userData = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMe.fulfilled, (state, action) => {
				state.userData = action.payload;
				state.getMe_success = true;
				state.getMe_pending = false;
				state.getMe_error = false;
			})
			.addCase(getMe.pending, (state, action) => {
				state.getMe_success = false;
				state.getMe_pending = true;
				state.getMe_error = false;
			})
			.addCase(getMe.rejected, (state, action) => {
				state.getMe_success = false;
				state.getMe_pending = false;
				state.getMe_error = action.payload;
			});
	},
});

export const { setUserPermissions } = meSlice.actions;
export default meSlice.reducer;
