import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconSideInside({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M15 6.66667L15 17.3333M9 6.66667L9 12V17.3333M18 5.77778L18 18.2222C18 19.2041 17.1046 20 16 20L8 20C6.89543 20 6 19.2041 6 18.2222L6 5.77778C6 4.79594 6.89543 4 8 4L16 4C17.1046 4 18 4.79594 18 5.77778Z"
					stroke="#8E8E93"
					strokeWidth="1.33333"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}
export default IconSideInside;
