const data = require('../../../../../../../../../../../assets/damages1.json');

export const detailCodeToName = (detail_code) => {
	// Получаем все детали из всех сторон контейнера
	const details = Object.values(data.container_sides)
		.flatMap((side) => side.details)
		.flatMap((detail) =>
			detail.options.map((option) => ({
				detailName: detail.name,
				optionName: option.name,
				code: option.code,
			})),
		);

	// Ищем нужную деталь по коду
	const detail = details.find((option) => option.code === detail_code);

	// Возвращаем имя детали + имя опции в нижнем регистре или null, если не найдено
	return detail ? `${detail.detailName} ${detail.optionName}`.toLowerCase() : null;
};
