import styles from './Preloader.module.scss';

function Preloader({ bg, small }) {
	return (
		<div className={bg && `${styles.preloader}`}>
			<div className={styles.spinner}>
				<div className={`${styles.bounce1} ${small ? styles.small : ''}`}></div>
				<div className={`${styles.bounce2} ${small ? styles.small : ''}`}></div>
				<div className={`${styles.bounce3} ${small ? styles.small : ''}`}></div>
			</div>
		</div>
	);
}

export default Preloader;
