import styles from './CardAuto.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveForm, setIndex } from '../../../store/slices/orderSlice';
import { setDriverFullName, setEl, setRegistrationNumber, setTrailerNumber } from '../../../store/slices/vehicleSlice';
import { IconCross, IconEdit, IconPlus, IconTrash, IconContainerCar } from '../../Icons';
import UniversalButton from '../../Buttons/UniversalButton/UniversalButton';
import { useEffect } from 'react';

function CardAuto({
	active,
	index,
	registrationNumber,
	trailerNumber,
	driverFullName,
	selectedContainer,
	createOrderSelect,
	onIconPlusClick,
	onIconCrossClick,
	selectedCars,
	delAuto,
	elem,
	setSelectedCar,
}) {
	const dispatch = useDispatch();
	const { activeForm } = useSelector((state) => state.order);
	// const { registrationNumber, trailerNumber, driverFullName  } = useSelector((state) => state.vehicle);

	const togleActiveCard = () => {
		if (setSelectedCar) {
			dispatch(setSelectedCar(index));
		}
		activeForm === index ? dispatch(setActiveForm(false)) : dispatch(setActiveForm(index), dispatch(setIndex(index)));
	};

	// useEffect(() => {
	//   dispatch(setRegistrationNumber(startRegistrationNumber));
	//   dispatch(setTrailerNumber(startTrailerNumber));
	//   dispatch(setDriverFullName(startDriverFullName));
	// }, [registrationNumber, trailerNumber, driverFullName])

	return (
		// <div onClick={togleActiveCard} className={`${styles.cardAuto} ${activeForm === index ? styles.activeCardAuto : ''}`}>
		<div className={`${styles.mainBlock} ${selectedCars ? styles.padding_left : ''}`}>
			{createOrderSelect ? (
				<div className={`${styles.firstBtns} ${selectedCars ? styles.dispNone : ''}`}>
					<UniversalButton
						icon={<IconTrash size={'medium'} color={'core-state-error'} />}
						color={'error'}
						type={'with-border'}
						size={'small'}
						onClick={delAuto}
					/>
					<UniversalButton
						icon={<IconEdit size={'medium'} color={'primary'} />}
						onClick={togleActiveCard}
						color={'primary'}
						type={'with-border'}
						size={'small'}
					/>
				</div>
			) : (
				<></>
			)}
			<div
				onClick={() => (createOrderSelect ? '' : togleActiveCard())}
				className={`${styles.cardAuto} ${
					activeForm === index ? styles.activeCardAuto : ''
				} ${selectedContainer === index ? styles.selectedContainerCard : ''} ${active === index ? styles.activeCardAuto : ''}`}
			>
				{selectedCars ? (
					<div className={`${selectedCars ? styles.error_btn : styles.dispNone}`}>
						<UniversalButton
							icon={<IconCross size={'medium'} color={'white'} />}
							color={'error'}
							onClick={onIconCrossClick}
							size={'small'}
						/>
					</div>
				) : (
					<></>
				)}
				<div className={styles.cardAuto__row}>
					<p className={styles.cardAuto__name}>Автомобиль: {registrationNumber ? registrationNumber : '-'}</p>
				</div>
				<div className={styles.cardAuto__row}>
					<p className={styles.cardAuto__info}>Прицеп: {trailerNumber ? trailerNumber : '-'}</p>
					<p className={styles.cardAuto__driver}>Водитель: {driverFullName ? driverFullName : '-'}</p>
					{selectedContainer === index ? (
						<div className={styles.carContainers}>
							<IconContainerCar color={'primary-active'} size={'large'} />
							<p>24</p>
						</div>
					) : (
						<></>
					)}
				</div>
				{createOrderSelect ? (
					<div className={`${selectedCars ? styles.dispNone : styles.successful_btn}`}>
						<UniversalButton
							icon={<IconPlus size={'medium'} color={'white'} />}
							color={'succesful'}
							size={'small'}
							onClick={onIconPlusClick}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
			{/* {
        selectedCars ? (
          <div className={styles.firstBtns}>
            <UniversalButton
              icon={
                <IconTrash size={'medium'} color={'core-state-error'} />
              }
              color={"error"}
              type={'with-border'}
              size={'small'}
              onClick={delAuto}
            />
            <UniversalButton
              icon={
                <IconEdit size={'medium'} color={'primary'} />
              }
              onClick={togleActiveCard}
              color={"primary"}
              type={'with-border'}
              size={'small'}
            />
          </div>
        ) : (<></>)
      } */}
		</div>
	);
}

export default CardAuto;
