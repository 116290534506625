import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material';
import CardComponent from '../../../../newComponents/CardComponent/CardComponent';
import { account, accountGridItemHalf } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLazyGetClientsListQuery } from '../../../../store/newApi/organizations/organizations.js';
import { getInitials } from '../../../../helpers/formatText';
import Preloader from '../../../../components/Preloader/Preloader';
import Filter from '../../../../newComponents/Filter/Filter.jsx';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../store/slices/sideBarSlice.js';
import ModalClient from '../components/Modals/ModalsCllient/ModalClient.jsx';
import { setCurrentClient, setModalType } from '../../../../store/slices/clientSlice.js';
import { useDispatch } from 'react-redux';
import {useLocation} from "react-router-dom";

function AccountEmplClients() {
	const [trigger, { data, isLoading }] = useLazyGetClientsListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);

	// Для режима выбора карточек
	const [selectionMode, setSelectionMode] = useState(false);
	const [selectedCards, setSelectedCards] = useState([]);

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const clientParam = queryParams.get('client');

	// Самая первая загрузка
	useEffect(() => {
		trigger({ page: 1, page_size: 20, id: clientParam });
	}, []);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => [...prevData, ...data.results]);
			setFetching(false);
		}
	}, [data]);

	// Обработка выбора карточек
	const handleCardSelect = (id) => {
		setSelectedCards((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((cardId) => cardId !== id);
			} else {
				return [...prevSelected, id];
			}
		});
	};

	const dispatch = useDispatch();

	const handleOpenModalForNewClient = () => {
		dispatch(setCurrentElementForSideBar(<ModalClient type={'clients'} />));
		dispatch(setSideBarActive(true));
		dispatch(
			setCurrentClient({
				contract_number: '',
				organization_address: '',
				organization_name: '',
				contact_email: '',
				is_manager_confirmed: '',
			}),
		);
		dispatch(setModalType('create'));
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Клиенты'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium" onClick={handleOpenModalForNewClient}>
						Добавить клиента
					</Button>,
				]}
			/>
			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					<div className={styles.account__filter}>
						<Filter />
					</div>
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemHalf(isDesktop)} key={index}>
									<CardComponent
										avatar={getInitials(card?.organization_name)}
										selectionMode={selectionMode}
										isSelected={selectedCards.includes(card?.id)}
										onSelectCard={() => handleCardSelect(card?.id)}
										title={card?.organization_name}
										rightText={card?.is_manager_confirmed}
										status={
											card?.is_manager_confirmed == 'Одобрен'
												? 'successful'
												: card?.is_manager_confirmed == 'Ожидает одобрения'
													? 'warning'
													: card?.is_manager_confirmed == 'Отклонен'
														? 'error'
														: {}
										}
										leftAdditionalInfo={[
											{
												name: 'ИНН:',
												text: `${card?.inn ? card?.inn : '-'}`,
											},
										]}
										rightAdditionalInfo={[
											{
												name: 'Почта:',
												text: `${card?.contact_email ? card?.contact_email : '-'}`,
											},
										]}
									/>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplClients;
