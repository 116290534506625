import { getLinesContainer } from '../../api/containers/getLinesContainer';

export const getLinesContainerAction = () => {
	return async (dispatch) => {
		try {
			const response = await getLinesContainer();
			console.log(response);
			dispatch(getLinesSuccess(response.data));
		} catch (error) {
			throw error;
		}
	};
};

export const getLinesSuccess = (payload) => ({
	type: 'GET_LINES_SUCCESS',
	payload,
});
