import styles from './Step.module.scss';
import React, { useEffect, useState } from 'react';
import { Form, UniversalButton } from '../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from '../../../../../components';
import { postRegistrationStaff, setInitialStaffRegistrationData } from '../../../../../store/slices/registrationSlice';
import MainInput from '../../../../../components/Inputs/MainInput/MainInput';

function Step4({ setPage }) {
  const dispatch = useDispatch();
  const { registrationStaff_success, registrationStaff_error } = useSelector((state) => state.registration)
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [notText, setNotText] = useState('');
  const [notDescr, setNotDescr] = useState('');
  const [notStatus, setNotStatus] = useState('');

  const addNot = (text, descr, status) => {
    setNotText(text);
    setNotDescr(descr);
    setNotStatus(status);
    setIsActive(true);
  };

  const onClose = () => {
    setIsActive(false);
  };

  const onSubmit = async (data) => {
    if (data.password === data.repeatPassword) {
      localStorage.setItem('password', data.password);
      const requestData = {
        full_name: localStorage.getItem("FLName"),
        username: localStorage.getItem("login"),
        password: data.repeatPassword,
        group: localStorage.getItem('typeUserId'),
      };
      await dispatch(postRegistrationStaff({ requestData }));
      dispatch(setInitialStaffRegistrationData())

    } else {
      addNot('Ошибка', 'Пароли не совпадают', 'error');
    }
  };

  useEffect(() => {
    if (registrationStaff_success) {
      setPage((currentPage) => currentPage + 1);
    }
    else if (registrationStaff_error) {
      addNot('Ошибка', 'Такой логин уже существует', 'error');
    }
  }, [registrationStaff_success, registrationStaff_error])

  let typeUser = localStorage.getItem('typeUser');

  return (
    <>
      <Notification
        text={notText}
        descr={notDescr}
        onClose={onClose}
        isActive={isActive}
        isStatus={notStatus}
      />
      <div className={styles.block}>
        <h5>
          {typeUser === 'Новый клиент'
            ? typeUser
            : `Новый ${typeUser.toLowerCase()}`}
        </h5>
        <Form onSubmit={onSubmit}>
          <MainInput
            title={'Пароль'}
            errorTitle={'Некорректный формат пароля'}
            name="password"
            value={password}
            setValue={setPassword}
            rules={{
              required: "Обязательное поле",
              minLength: {
                value: 6,
                message: "Минимум 6 символов",
              },
              pattern: {
                value: /^[^\sа-яА-Я]{6,}$/,
                message: "Латинские символы",
              },
            }}
            placeholder={'Введите пароль'}
          />
          <MainInput
            title={'Повторите пароль'}
            errorTitle={'Некорректный формат пароля'}
            name="repeatPassword"
            value={repeatPassword}
            setValue={setRepeatPassword}
            rules={{
              required: "Обязательное поле",
              minLength: {
                value: 6,
                message: "Минимум 6 символов",
              },
              pattern: {
                value: /^[^\sа-яА-Я]{6,}$/,
                message: "Латинские символы",
              },
            }}
            placeholder={'Введите пароль'}
          />
          <div className={styles.block__btns}>
            <UniversalButton label={"Продолжить"} color={"primary"} size={"large"} typeButton={"submit"} />
          </div>
        </Form>
      </div>
    </>
  );
}

export default Step4;