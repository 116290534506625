import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconSideOutside({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M20 4L20 20M4 4L4 20M16.8 5.77778L16.8 18.2222C16.8 19.2041 16.0837 20 15.2 20L8.8 20C7.91634 20 7.2 19.2041 7.2 18.2222L7.2 5.77778C7.2 4.79594 7.91634 4 8.8 4L15.2 4C16.0837 4 16.8 4.79594 16.8 5.77778Z"
					stroke="#8E8E93"
					strokeWidth="1.33333"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}
export default IconSideOutside;
