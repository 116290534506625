import React, { useEffect, useState } from 'react';
import { allPermissions } from './allPermissions';
import { useSelector } from 'react-redux';
import { useStore } from 'react-redux';

function RequireUI({ type, children, swap }) {
	const fakeData = useSelector((state) => state.me.userData);
	const checkPermissions = () => {
		if (type.terminal_id) {
			const currentTerminalUserData = fakeData?.terminals?.find((el) => el.id == type.terminal_id);
			const terminalGroupPermissions = currentTerminalUserData?.permissions.map((el) => (el = el.id));
			const terminalCustomPermissions = currentTerminalUserData?.permissions.map((elt) => (elt = elt.id));
			const currentPermissonsList = allPermissions?.find((el) => el.name === type.name);

			const concat = terminalGroupPermissions?.concat(terminalCustomPermissions);
			const isInOne = currentPermissonsList?.permissions.every((val) => concat?.includes(val));
			return isInOne;
		}
		if (!type.terminal_id) {
			const getUniqueIds = (data) => {
				const uniqueIds = new Set();
				data.terminals?.forEach((terminal) => {
					const ids = [
						...terminal.permissions.map((permission) => permission.id),
						...terminal.permissions.map((permission) => permission.id),
					];
					ids.forEach((id) => uniqueIds.add(id));
				});
				return Array.from(uniqueIds);
			};

			const uniqueIds = getUniqueIds(fakeData);
			const currentPermissonsList = allPermissions?.find((el) => el.name === type.name);
			const isInOne = currentPermissonsList?.permissions?.every((val) => uniqueIds?.includes(val));
			return isInOne;
		}
	};
	const show = checkPermissions();
	return <>{show ? children : swap ? swap : null}</>;
}

export default RequireUI;

export const RequireUIFunc = (type) => {
	const fakeData = useSelector((state) => state.me.userData);
	if (type.terminal_id) {
		const currentTerminalUserData = fakeData?.terminals?.find((el) => el.id == type.terminal_id);
		const terminalGroupPermissions = currentTerminalUserData?.permissions.map((el) => (el = el.id));
		const terminalCustomPermissions = currentTerminalUserData?.permissions.map((elt) => (elt = elt.id));
		const currentPermissonsList = allPermissions?.find((el) => el.name === type.name);

		const concat = terminalGroupPermissions?.concat(terminalCustomPermissions);
		const isInOne = currentPermissonsList?.permissions.every((val) => concat?.includes(val));
		return isInOne;
	}

	if (!type.terminal_id) {
		const getUniqueIds = (data) => {
			const uniqueIds = new Set();
			data.terminals?.forEach((terminal) => {
				const ids = [
					...terminal.permissions.map((permission) => permission.id),
					...terminal.permissions.map((permission) => permission.id),
				];
				ids.forEach((id) => uniqueIds.add(id));
			});
			return Array.from(uniqueIds);
		};

		const uniqueIds = getUniqueIds(fakeData);
		const currentPermissonsList = allPermissions?.find((el) => el.name === type.name);
		const isInOne = currentPermissonsList?.permissions?.every((val) => uniqueIds?.includes(val));
		return isInOne;
	}
};
