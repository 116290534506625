export const terminals = [
	{
		terminalName: 'Терминал «СЕВЕР»',
		controlRoom: {
			tel: '+7 926 646-91-91',
			mail: 'sever@tt3s.ru',
		},
		operatingMode: 'ПН-ВС, круглосуточно',
		adress: 'Московская область, Дмитровский район, с.Белый Раст, 5А',
		services: [
			'Хранение порожних/груженых контейнеров;',
			'Pемонт контейнеров;',
			'Накопление и переброска под отправку поездных партий контейнеров;',
			'Затарка из/в контейнер;',
		],
	},
	{
		terminalName: 'Терминал «ТЕТРИС»',
		controlRoom: {
			tel: '+7 901 546-91-91',
			mail: 'srb@tt3s.ru',
		},
		operatingMode: 'ПН-ВС, круглосуточно',
		adress: 'г. Москва, г. Щербинка, ул. Южная 7А',
		services: [
			'Хранение порожних/груженых контейнеров;',
			'Pемонт контейнеров;',
			'Накопление и переброска под отправку поездных партий контейнеров;',
			'Затарка из/в контейнер;',
		],
	},
	{
		terminalName: 'Терминал «МИКС»',
		controlRoom: {
			tel: '+7 999 545-91-91',
			mail: 'mix@tt3s.ru',
		},
		operatingMode: 'ПН-ВС, круглосуточно',
		adress: 'Московская область, г. Наро-Фоминск, ул. Московская, 19',
		services: [
			'Хранение порожних/груженых контейнеров;',
			'Pемонт контейнеров;',
			'Накопление и переброска под отправку поездных партий контейнеров;',
			'Затарка из/в контейнер;',
			'Вытарка легковых авто из контейнера',
		],
	},
	{
		terminalName: 'Терминал «НОРД»',
		controlRoom: {
			tel: '+7 929 546-91-91',
			mail: 'nord@tt3s.ru',
		},
		operatingMode: 'ПН-ВС, круглосуточно',
		adress: 'Московская область, Дмитровский район, посёлок Никольское, вл. 170',
		services: [
			'Хранение порожних/груженых контейнеров;',
			'Pемонт контейнеров;',
			'Накопление и переброска под отправку поездных партий контейнеров;',
			'Затарка из/в контейнер;',
			'Вытарка легковых авто из контейнера',
		],
	},
	{
		terminalName: 'Терминал «ЛОБНЯ»',
		controlRoom: {
			tel: '+7 925 949-91-91',
			mail: 'lobnya@tt3s.ru',
		},
		operatingMode: 'ПН-ВС, круглосуточно',
		adress: 'Московская область, г.о. Лобня, г. Лобня, улица Горки Киовские, вл5 (ориентир для проезда к шлагбауму)',
		services: [
			'Хранение порожних/груженых контейнеров;',
			'Pемонт контейнеров;',
			'Накопление и переброска под отправку поездных партий контейнеров;',
			'Затарка из/в контейнер;',
		],
	},
	{
		terminalName: 'Терминал «КРЫМСК»',
		controlRoom: {
			tel: '+7 909 946-91-91',
			mail: 'krymsk@tt3s.ru',
		},
		operatingMode: 'ПН-ВС, круглосуточно',
		adress: 'г. Крымск, ул. Строительная 51Б, заезд через ул. Линейная',
		services: [
			'Хранение порожних/груженых контейнеров;',
			'Pемонт контейнеров;',
			'Накопление и переброска под отправку поездных партий контейнеров;',
			'Затарка из/в контейнер;',
		],
	},
	{
		terminalName: 'Терминал «ДОМОДЕДОВО»',
		controlRoom: {
			tel: '+7 929 946-91-91',
			mail: 'dmd@tt3s.ru',
		},
		operatingMode: 'ПН-ВС, круглосуточно',
		adress: 'г.о. Домодедово, с. Домодедово, ул. Зорин Лес, вблизи вл. Беннинг',
		services: [
			'Хранение порожних/груженых контейнеров;',
			'Pемонт контейнеров;',
			'Накопление и переброска под отправку поездных партий контейнеров;',
			'Затарка из/в контейнер;',
		],
	},
	{
		terminalName: 'Терминал «ВОСТОК»',
		controlRoom: {
			tel: '+7 929 519-91-91',
			mail: 'vostok@tt3s.ru',
		},
		operatingMode: 'ПН-ВС, круглосуточно',
		adress: 'Московская область, Богородский городской округ, Электроугли, Заводская улица, 20с1',
		services: [
			'Хранение порожних/груженых контейнеров;',
			'Pемонт контейнеров;',
			'Накопление и переброска под отправку поездных партий контейнеров;',
			'Затарка из/в контейнер;',
		],
	},
];
