import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconDamageDeform({ onClick, size, color }) {
	return (
		<div className={styles.icon} onClick={onClick}>
			{/* <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 16 16" fill="none">
				<path
					d="M12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645C12.1583 3.45118 11.8417 3.45118 11.6464 3.64645L12.3536 4.35355ZM3.64645 11.6464C3.45118 11.8417 3.45118 12.1583 3.64645 12.3536C3.84171 12.5488 4.15829 12.5488 4.35355 12.3536L3.64645 11.6464ZM4.35355 3.64645C4.15829 3.45118 3.84171 3.45118 3.64645 3.64645C3.45118 3.84171 3.45118 4.15829 3.64645 4.35355L4.35355 3.64645ZM11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L11.6464 12.3536ZM11.6464 3.64645L7.64645 7.64645L8.35355 8.35355L12.3536 4.35355L11.6464 3.64645ZM7.64645 7.64645L3.64645 11.6464L4.35355 12.3536L8.35355 8.35355L7.64645 7.64645ZM3.64645 4.35355L7.64645 8.35355L8.35355 7.64645L4.35355 3.64645L3.64645 4.35355ZM7.64645 8.35355L11.6464 12.3536L12.3536 11.6464L8.35355 7.64645L7.64645 8.35355Z"
					fill={IconColor(color)}
				/>
			</svg> */}
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M19.9688 18.2458V6.84379C19.9688 6.84379 20.3826 4.00011 18.1945 4.00005C16.0808 3.99999 15.3205 6.41915 13.3955 5.96671C11.6874 5.56524 11.4262 3.99992 9.96772 4C8.50928 4.00008 8.17388 5.30824 6.9993 5.96676C5.94891 6.55566 4.00009 5.85981 4 6.84379V18.2458C4 19.2146 4.79439 20 5.77432 20H18.1945C19.1745 20 19.9688 19.2146 19.9688 18.2458Z"
					stroke="#8E8E93"
					strokeWidth="0.888889"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}

export default IconDamageDeform;
