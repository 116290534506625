function IconFileTextBig({ size, color }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 120 120" fill="none">
			<path
				d="M70 10L72.6516 7.34835C71.9484 6.64509 70.9946 6.25 70 6.25V10ZM30 10V6.25V10ZM20 20H16.25H20ZM20 100H16.25H20ZM100 40H103.75C103.75 39.0054 103.355 38.0516 102.652 37.3483L100 40ZM70 40H66.25C66.25 42.0711 67.9289 43.75 70 43.75V40ZM80 68.75C82.0711 68.75 83.75 67.0711 83.75 65C83.75 62.9289 82.0711 61.25 80 61.25V68.75ZM40 61.25C37.9289 61.25 36.25 62.9289 36.25 65C36.25 67.0711 37.9289 68.75 40 68.75V61.25ZM80 88.75C82.0711 88.75 83.75 87.0711 83.75 85C83.75 82.9289 82.0711 81.25 80 81.25V88.75ZM40 81.25C37.9289 81.25 36.25 82.9289 36.25 85C36.25 87.0711 37.9289 88.75 40 88.75V81.25ZM50 48.75C52.0711 48.75 53.75 47.0711 53.75 45C53.75 42.9289 52.0711 41.25 50 41.25V48.75ZM40 41.25C37.9289 41.25 36.25 42.9289 36.25 45C36.25 47.0711 37.9289 48.75 40 48.75V41.25ZM70 6.25H30V13.75H70V6.25ZM30 6.25C26.3533 6.25 22.8559 7.69866 20.2773 10.2773L25.5806 15.5806C26.7527 14.4085 28.3424 13.75 30 13.75V6.25ZM20.2773 10.2773C17.6987 12.8559 16.25 16.3533 16.25 20H23.75C23.75 18.3424 24.4085 16.7527 25.5806 15.5806L20.2773 10.2773ZM16.25 20V100H23.75V20H16.25ZM16.25 100C16.25 103.647 17.6987 107.144 20.2773 109.723L25.5806 104.419C24.4085 103.247 23.75 101.658 23.75 100H16.25ZM20.2773 109.723C22.8559 112.301 26.3533 113.75 30 113.75V106.25C28.3424 106.25 26.7527 105.592 25.5806 104.419L20.2773 109.723ZM30 113.75H90V106.25H30V113.75ZM90 113.75C93.6467 113.75 97.1441 112.301 99.7227 109.723L94.4194 104.419C93.2473 105.592 91.6576 106.25 90 106.25V113.75ZM99.7227 109.723C102.301 107.144 103.75 103.647 103.75 100H96.25C96.25 101.658 95.5915 103.247 94.4194 104.419L99.7227 109.723ZM103.75 100V40H96.25V100H103.75ZM102.652 37.3483L72.6516 7.34835L67.3484 12.6517L97.3484 42.6516L102.652 37.3483ZM66.25 10V40H73.75V10H66.25ZM70 43.75H100V36.25H70V43.75ZM80 61.25H40V68.75H80V61.25ZM80 81.25H40V88.75H80V81.25ZM50 41.25H40V48.75H50V41.25Z"
				fill={color}
			/>
		</svg>
	);
}

export default IconFileTextBig;
