import styles from '../AccountEmpl.module.scss';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import Header from '../components/Header/Header';
import { useDispatch } from 'react-redux';
import TerminalMainInfo from '../components/Terminals/TerminalMainInfo';
import ModulesSettings from '../components/Terminals/ModulesSettings';

function AccountEmplCreateTerminal({ userPermissions }) {
	const dispatch = useDispatch();
	const isDesktop = useMediaQuery({ minWidth: 743.9 });

	const [page, setPage] = useState(0);

	const pageDisplay = () => {
		if (page === 0) {
			return <TerminalMainInfo noBtns create setPage={setPage} />;
		} else if (page === 1) {
			return <ModulesSettings create setPage={setPage} />;
		}
	};

	return (
		<div className={styles.account__wrap}>
			<Header text={'Создание терминала '} step={page + 1} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					{/* <div className={styles.contentBtns}> */}
					<div className={styles.layout__content}>
						<div className={styles.account__order}>
							{/* //* Основной контент */}
							{pageDisplay()}
						</div>
					</div>
					{/* </div> */}
				</div>
			</div>
		</div>
	);
}

export default AccountEmplCreateTerminal;
