export const account = {
	container: {
		padding: '8px',
		maxHeight: '100%',
		overflowY: 'auto',
	},

	preloader: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
};

export const accountGridItemHalf = (isDesktop) => {
	return [
		{
			minWidth: isDesktop ? '488px' : '100%',
			width: isDesktop ? '50%' : '100%',
			padding: '8px',
		},
	];
};

export const accountGridItemThird = (isDesktop) => {
	return [
		{
			minWidth: isDesktop ? '320px' : '100%',
			width: isDesktop ? '33.333%' : '100%',
			padding: '8px',
		},
	];
};
