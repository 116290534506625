import React from 'react';
import style from './PassCard.module.scss';

const OrangeCheckBox = ({ checked, onChange }) => {
	return (
		<div className={style.checkContainer} onClick={onChange}>
			<div className={style.border}></div>
			{checked && <div className={style.galka} />}
		</div>
	);
};

const PassCard = ({
	numContainer,
	numOrder,
	status,
	transport,
	driver,
	responsible,
	security,
	isSelected,
	onSelect,
}) => {
	const statusData = [
		{ id: 0, statusText: 'Пропуск создан' },
		{ id: 1, statusText: 'Пропуск подтвержден' },
		{ id: 2, statusText: 'Пропуск активирован' },
		{ id: 3, statusText: 'Пропуск отменён' },
		{ id: 4, statusText: 'Контейнер и пропуск удалены' },
	];
	const handleSelectCard = () => {
		onSelect(!isSelected);
	};

	return (
		<div className={style.cardContainer}>
			<OrangeCheckBox checked={isSelected} onChange={handleSelectCard} />
			<div className={style.leftSide}>
				<p>Контейнер: {numContainer}</p>
				<div className={style.driverTransport}>
					<p>А/М: {transport}</p>
					<p>Водитель: {driver}</p>
				</div>
			</div>
			<div className={style.rightSide}>
				<div className={style.orderStatus}>
					<p>№ {numOrder}</p>
					<p>{statusData[status].statusText}</p>
				</div>
				<div className={style.responsibleSecurity}>
					<p>Ответственный: {responsible}</p>
					<p>Охранник: {security}</p>
				</div>
			</div>
		</div>
	);
};

export default PassCard;
