import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useLazyGetVehiclesListQuery } from '../../../../store/newApi/vehicles/vehicles';
import { Button } from '@mui/material';
import Filter from '../../../../newComponents/Filter/Filter';

function AccountEmplTableVehicles() {
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});

	// колонки
	const columnsList = useMemo(
		() => [
			{
				accessorFn: (row) => row.registration_number,
				id: 'registration_number',
				header: 'Номер',
				size: 120,
			},
			{
				accessorFn: (row) => row.trailer_number,
				id: 'trailer_number',
				header: 'Номер прицепа',
				size: 120,
			},
			{
				accessorFn: (row) => row.driver,
				id: 'driver',
				header: 'Водитель',
				size: 120,
			},
		],
		[],
	);

	// порядок колонок
	const columnOrdering = ['mrt-row-select', 'registration_number', 'trailer_number', 'driver'];

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Автомобили'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium">
						Добавить автомобиль
					</Button>,
				]}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<Filter />
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						hookName={useLazyGetVehiclesListQuery}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableVehicles;
