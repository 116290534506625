import { useEffect } from "react";
import styles from "./Select.module.scss";

function SelectLaptop({
    options,
    selectedOption,
    oneUser,
    setSelectedOption,
    setSelectedId,
    setShowModal,
    setInputChange,
    elementName,
    errors,
    name,
    multiSelect
}) {
    const handleSelection = (option, optionId) => {
        if (multiSelect) {
            let updatedSelection = selectedOption?.split(',').map(item => item.trim()); // Разбиваем текущую строку на массив по запятым и убираем пустые строки и лишние пробелы
    
            // Проверяем, присутствует ли выбранный вариант в текущем массиве
            const index = updatedSelection.indexOf(option);
            if (index !== -1) {
                // Если присутствует, удаляем его
                updatedSelection.splice(index, 1);
            } else {
                // Если отсутствует, добавляем
                updatedSelection.push(option);
            }
    
            // Обновляем selectedOption, объединяя массив обратно в строку с запятыми и пробелами
            setSelectedOption(updatedSelection.filter(item => item !== '').join(', '));
            setSelectedId(optionId);
        } else {
            setSelectedOption(option);
            setSelectedId(optionId);
            setShowModal(false);
        }
    
        // Проверяем изменение для oneUser.group
        if (oneUser?.group !== option && setInputChange) {
            setInputChange(true);
        } else if (setInputChange) {
            setInputChange(false);
        }
    };     

    return (
        // TODO вырезано из diva
        // ${errors[name] ? (styles.errorSelectLaptop) : ('')}
        <div className={`${styles.selectLaptop} `}>
            {elementName ? (<div className={styles.optionSelectLaptop}>
                {options.map((el, index) => (
                    <div
                        key={index}
                        className={`${styles.optionLaptop} ${selectedOption === el.line_name
                            ? styles.selectedOption
                            : styles.unselectedOption
                            }`}
                        onClick={() => {
                            handleSelection(el.line_name, el.id);
                        }}
                    >
                        <p className={styles.optionText}>{el.line_name}</p>
                    </div>
                ))}
            </div>) : (<div className={styles.optionSelectLaptop}>
                {options.map((el, index) => (
                    <div
                        key={index}
                        className={`${styles.optionLaptop} 
                            ${(multiSelect && (selectedOption.includes(el.name))) ? styles.selectedOption : (selectedOption === el.name)
                                ? styles.selectedOption
                                : styles.unselectedOption
                            }
                        `}
                        onClick={() => {
                            handleSelection(el.name, el.id);
                        }}
                    >
                        <p className={styles.optionText}>{el.name}</p>
                    </div>
                ))}
            </div>)}
        </div>
    );
}

export default SelectLaptop;