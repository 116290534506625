import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconCircleInfo({ size, color }) {
    return (
        <div className={styles.icon}>
            <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 20 20" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.0832 10.0001C17.0832 13.9121 13.9119 17.0834 9.99984 17.0834C6.08782 17.0834 2.9165 13.9121 2.9165 10.0001C2.9165 6.08806 6.08782 2.91675 9.99984 2.91675C13.9119 2.91675 17.0832 6.08806 17.0832 10.0001ZM18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001Z" 
                fill={IconColor(color)} />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.8048 6.95675H9.19478V5.41675H10.8048V6.95675ZM10.4181 8.33342H8.74984V9.58342H9.5848V12.9167H8.74984V14.1667H9.5848H10.8348H11.6665V12.9167H10.8348V8.75008C10.8348 8.51996 10.6483 8.33342 10.4181 8.33342Z" 
                fill={IconColor(color)} />
            </svg>
        </div>
    );
}

export default IconCircleInfo;