import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { IconSearch } from '../../components/Icons';
import { filter } from './Filter.styles.js';

const FilterSearch = ({ onSearch }) => {
	const [searchTerm, setSearchTerm] = useState('');

	const handleSearch = () => {
		onSearch(searchTerm);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			onSearch(searchTerm);
		}
	};

	return (
		<TextField
			variant="outlined"
			placeholder="Поиск"
			value={searchTerm}
			onChange={(e) => setSearchTerm(e.target.value)}
			onKeyDown={handleKeyDown}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							disableRipple
							onClick={handleSearch}
							sx={{
								padding: '0 16px 0 0',
							}}
						>
							<IconSearch size={'large'} color={'black'} />
						</IconButton>
					</InputAdornment>
				),
				sx: {
					borderRadius: '12px',
					height: '36px',
					paddingRight: '0',
					color: '#000000',
				},
			}}
			sx={filter.input}
			fullWidth
		/>
	);
};

export default FilterSearch;
