import { Preloader, Search, UniversalButton } from '../../../../../../components';
import { IconCopy } from '../../../../../../components/Icons';
import {
	setCurrentElementForSideBar,
	setPrevElementForSideBar,
	setSideBarActive,
	setNon_activeWrapper,
} from '../../../../../../store/slices/sideBarSlice';
import { generateBackground, getInitials } from '../../../../avatarsUtils';
import styles from '../Modals.module.scss';
import copy from 'copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import ModalClientEmpl from './ModalClientEmpl';
import ModalClient from './ModalClient';
import { useEffect } from 'react';
import { getWorkerList } from '../../../../../../store/slices/clientSlice';
import { useParams } from 'react-router-dom';

function ModalClientEmpls({ type, client }) {
	const dispatch = useDispatch();
	let { clientId } = useParams();
	const { workerList, getWorkerList_pending } = useSelector((state) => state.client);

	useEffect(() => {
		if (type === 'terminal') {
			dispatch(getWorkerList({ user_id: client.id }));
		} else {
			dispatch(getWorkerList({ user_id: clientId }));
		}
	}, [clientId, dispatch, client, type]);

	// Функция для копирования
	const handleCopy = (text) => {
		copy(text);
	};

	// Открытие модалки изменения сотрудника
	const handleOpenEmpl = (el) => {
		if (type === 'terminal') {
			dispatch(setPrevElementForSideBar(<ModalClient type={'terminalClients'} />));
			dispatch(setSideBarActive(true));
			dispatch(setCurrentElementForSideBar(<ModalClientEmpl employer={el} />));
			dispatch(setNon_activeWrapper(true));
		} else {
			dispatch(setPrevElementForSideBar(null));
			dispatch(setSideBarActive(true));
			dispatch(setCurrentElementForSideBar(<ModalClientEmpl employer={el} />));
			dispatch(setNon_activeWrapper(true));
		}
	};

	return (
		<div className={styles.card__userInfo}>
			<div className={styles.card__empls}>
				<Search />
				<div className={styles.card__empls}>
					{workerList?.map((el, ind) => (
						<div className={styles.card__empl} key={ind}>
							<div className={styles.card__userName}>
								<div
									className={styles.card__empl_img}
									style={{
										background: generateBackground(el?.full_name),
									}}
								>
									{getInitials(el?.full_name)}
								</div>
								<div className={styles.card__empl_info}>
									<span>{el?.full_name}</span>
									<p>{el?.email}</p>
								</div>
							</div>
							<div className={styles.card__empl_stack}>
								<ul className={styles.card__contacts}>
									{el?.phone && (
										<li className={styles.card__contacts_row}>
											<span>Телефон: {el?.phone}</span>
											<button onClick={() => handleCopy(el?.phone)}>
												<IconCopy color={'black'} size={'largeS'} />
											</button>
										</li>
									)}
									{el?.telegram && (
										<li className={styles.card__contacts_row}>
											<span>Телеграм: {el?.telegram}</span>
											<button onClick={() => handleCopy(el?.telegram)}>
												<IconCopy color={'black'} size={'largeS'} />
											</button>
										</li>
									)}
									{el?.whatsapp && (
										<li className={styles.card__contacts_row}>
											<span>Whatsapp: {el?.whatsapp}</span>
											<button onClick={() => handleCopy(el?.whatsapp)}>
												<IconCopy color={'black'} size={'largeS'} />
											</button>
										</li>
									)}
								</ul>
								<UniversalButton
									onClick={() => {
										handleOpenEmpl(el);
									}}
									typeButton={'button'}
									label={'Изменить данные'}
									color={'accent'}
									size={'medium'}
								/>
							</div>
						</div>
					))}
					{getWorkerList_pending ? (
						<div className={styles.preloader_inwrapper}>
							<Preloader />
						</div>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
}

export default ModalClientEmpls;
