import styles from './Containers.module.scss';
import ContainersItem from './ContainersItem';
import UniversalButton from '../../Buttons/UniversalButton/UniversalButton';
import OrderBlock from '../OrderBlock/OrderBlock';
import { useEffect, useState } from 'react';
import ConfirmationWindow from '../../ConfirmationWindow/ConfirmationWindow';
import { IconDisketa } from '../../Icons';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteOneContainerFromCurrentOrderContainerList,
	deleteAllContainersFromCurrentOrderContainerList,
	setChoosenContainerForChangeCancelVariant,
} from '../../../store/slices/orderSlice';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../store/slices/sideBarSlice';
import AddContainers from '../AddContainers/AddContainers';
import ChangeContainerForm from '../ChangeContainerForm/ChangeContainerForm';
import RequireUI from '../../../hoc/RequireUI';

function Containers({ data, type, exportOrder, handleChangeContainer, handleRemoveFromOrder }) {
	const dispatch = useDispatch();
	const { containersToAddInOrder } = useSelector((state) => state.order);
	const [confWindow, setConfWindow] = useState(false);
	const [containerEL, setContainerEL] = useState();
	const [removeAllCard, setRemoveAllCard] = useState(false);

	//Удаление одного контейнера при создании заявки на ввоз
	const handleRemoveCard = (e) => {
		dispatch(deleteOneContainerFromCurrentOrderContainerList(containerEL));
		setConfWindow(false);
	};
	// Удаление всех контейнеров по кнопке отчистить все
	const handleRemoveAllCard = () => {
		dispatch(deleteAllContainersFromCurrentOrderContainerList({ type: 'export_before', data: containersToAddInOrder }));
		setRemoveAllCard(false);
	};

	useEffect(() => {
		console.log(containersToAddInOrder, 'COntainersToAdd');
	}, [containersToAddInOrder]);

	return (
		<div className={styles.containers}>
			{confWindow || removeAllCard ? (
				<ConfirmationWindow
					titleText={`Вы уверены, что хотите удалить ${removeAllCard ? 'все контейнеры?' : 'контейнер №' + containerEL.container_number}`}
					acceptBtnText={'Да, удалить'}
					acceptBtnClick={removeAllCard ? handleRemoveAllCard : handleRemoveCard}
					rejectBtnText={'Отменить'}
					rejectBtnClick={() => (removeAllCard ? setRemoveAllCard(false) : setConfWindow(false))}
				/>
			) : (
				<></>
			)}
			<OrderBlock
				title={`Контейнеры: ${data?.length}`}
				topLineBtn={
					data?.length === 0 || exportOrder ? (
						<></>
					) : (
						<RequireUI type={{ name: 'DelContainers' }}>
							<UniversalButton
								size={'small'}
								color={'error'}
								type={'with-border'}
								label={'Очистить'}
								onClick={() => setRemoveAllCard(true)}
							/>
						</RequireUI>
					)
				}
				children={
					<div className={styles.containers__list}>
						{data?.length === 0 ? (
							<div className={styles.emptyList}>
								<p>Список контейнеров пуст</p>
								<p>Заполните форму, чтобы добавить контейнеры </p>
							</div>
						) : (
							<>
								<div>
									{data?.map((el, ind) => (
										<ContainersItem
											type={type}
											handleChange={handleChangeContainer}
											handleRemoveFromOrder={handleRemoveFromOrder}
											key={ind}
											el={el}
										/>
									))}
								</div>
								{exportOrder && (
									<UniversalButton
										icon={<IconDisketa size={'medium'} color={'core-state-success'} />}
										size={'small'}
										label={'Сохранить'}
										color={'succesful'}
										type={'with-border'}
									/>
								)}
							</>
						)}
					</div>
				}
			/>
		</div>
	);
}

export default Containers;
