import React from 'react';
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconMaxmize({ size, color }) {
  return (
    <div className={styles.icon}>
      <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none">
        <path fill={IconColor(color)} d="M15 2.25C14.5858 2.25 14.25 2.58579 14.25 3C14.25 3.41421 14.5858 3.75 15 3.75V2.25ZM21 3H21.75C21.75 2.58579 21.4142 2.25 21 2.25V3ZM20.25 9C20.25 9.41421 20.5858 9.75 21 9.75C21.4142 9.75 21.75 9.41421 21.75 9H20.25ZM9 21.75C9.41421 21.75 9.75 21.4142 9.75 21C9.75 20.5858 9.41421 20.25 9 20.25V21.75ZM3 21H2.25C2.25 21.4142 2.58579 21.75 3 21.75V21ZM3.75 15C3.75 14.5858 3.41421 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15H3.75ZM13.4697 9.46967C13.1768 9.76256 13.1768 10.2374 13.4697 10.5303C13.7626 10.8232 14.2374 10.8232 14.5303 10.5303L13.4697 9.46967ZM10.5303 14.5303C10.8232 14.2374 10.8232 13.7626 10.5303 13.4697C10.2374 13.1768 9.76256 13.1768 9.46967 13.4697L10.5303 14.5303ZM15 3.75H21V2.25H15V3.75ZM20.25 3V9H21.75V3H20.25ZM9 20.25H3V21.75H9V20.25ZM3.75 21V15H2.25V21H3.75ZM20.4697 2.46967L13.4697 9.46967L14.5303 10.5303L21.5303 3.53033L20.4697 2.46967ZM3.53033 21.5303L10.5303 14.5303L9.46967 13.4697L2.46967 20.4697L3.53033 21.5303Z" />
      </svg>
    </div>

  );
}

export default IconMaxmize;



