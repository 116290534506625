import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconOpenLock({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 21 20" fill="none">
				<path
					d="M2.91662 8.33333L2.91662 10.3333H2.66663V9.58333V6.66667C2.66663 4.64162 4.30825 3 6.33329 3C8.35834 3 9.99996 4.64162 9.99996 6.66667V8.33333V8.83333H10.5H18C18.1841 8.83333 18.3333 8.98257 18.3333 9.16667V13.3333C18.3333 15.3584 16.6917 17 14.6666 17H11.3333C9.30825 17 7.66663 15.3584 7.66663 13.3333V9.16667C7.66663 8.98257 7.81586 8.83333 7.99996 8.83333H9.24996H9.74996V8.33333V6.66667C9.74996 4.77969 8.22027 3.25 6.33329 3.25C4.44632 3.25 2.91663 4.77969 2.91663 6.66667L2.91662 8.33333ZM8.41663 9.08333H7.91663V9.58333V13.3333C7.91663 15.2203 9.44632 16.75 11.3333 16.75H14.6666C16.5536 16.75 18.0833 15.2203 18.0833 13.3333V9.58333V9.08333H17.5833H8.41663ZM13.377 12.74L13.125 12.884V13.1742V14.0833H12.875V13.1699V12.8819L12.6259 12.7374C12.3999 12.6063 12.25 12.3632 12.25 12.0859C12.25 11.6707 12.587 11.3333 13.0037 11.3333C13.4205 11.3333 13.7575 11.6707 13.7575 12.0859C13.7575 12.3651 13.6055 12.6095 13.377 12.74Z"
					stroke={IconColor(color)}
				/>
			</svg>
		</div>
	);
}

export default IconOpenLock;
