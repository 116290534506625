import { useMemo, useState } from 'react';
import { UniversalButton } from '../../../../components';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { getExportExcel } from '../../../../store/slices/allContainersSlice';
import { useDispatch, useSelector } from 'react-redux';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useGetContainersListQuery, useLazyGetContainersListQuery } from '../../../../store/newApi/containers/containers';
import { formatInitialDate } from '../../../../helpers/formatDate';
import Filter from '../../../../newComponents/Filter';
import { FILTER_TYPES } from '../../../../config/filter';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/containers.js';
import { Button } from '@mui/material';
import BtnDropdown from '../../../../newComponents/BtnDropdown/BtnDropdown';

function AccountEmplContainers() {
	const dispatch = useDispatch();

	const { getExportExcel_pending } = useSelector((state) => state.allContainers);

	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});

	const handleExportExcel = () => {
		let viewCols = '';
		if (Object.keys(rowSelection).length !== 0) {
			viewCols += 'container_ids_in=' + Object.keys(rowSelection).join(',');
		}
		dispatch(getExportExcel(viewCols));
	};

	// колонки
	const columnsList = useMemo(
		() => [
			{
				accessorFn: (row) => row.status_display,
				id: 'status',
				header: 'Статус',
				size: 192,
			},
			{
				accessorFn: (row) => row.line_display,
				id: 'line',
				header: 'Линия',
				size: 232,
			},
			{
				accessorFn: (row) => row.container_number,
				id: 'container_number',
				header: '№ контейнера',
				size: 230,
			},
			{
				accessorFn: (row) => row.size_display,
				id: 'size',
				header: 'Размер',
				size: 200,
			},
			{
				accessorFn: (row) => row.type_display,
				id: 'type',
				header: 'Тип',
				size: 180,
			},
			{
				accessorFn: (row) => row.client_display,
				id: 'org',
				header: 'Клиент',
				size: 280,
			},
			{
				accessorFn: (row) => row.booking,
				id: 'booking',
				header: 'Букинг',
				size: 280,
			},
			{
				accessorFn: (row) => (row.is_filled ? 'Да' : '-'),
				id: 'is_filled',
				header: 'Заполнен',
				size: 280,
			},
			{
				accessorFn: (row) => (row.is_dangerous ? 'Да' : '-'),
				id: 'is_dangerous',
				header: 'Опасный груз',
				size: 280,
			},
			{
				accessorFn: (row) => (row.is_damaged ? 'Да' : '-'),
				id: 'is_damaged',
				header: 'Поврежден',
				size: 280,
			},
			{
				accessorFn: (row) => row.entrypass[0]?.terminal_display,
				id: 'terminal',
				header: 'Терминал',
				size: 230,
			},

			{
				accessorFn: (row) => row.created_at && formatInitialDate(row.created_at),
				id: 'created_at',
				header: 'Дата создания',
				size: 230,
			},
		],
		[],
	);

	// расположение колонок
	const columnOrdering = [
		'mrt-row-select',
		'status',
		'line',
		'container_number',
		'size',
		'type',
		'org',
		'booking',
		'is_filled',
		'is_dangerous',
		'is_damaged',
		'terminal',
		'created_at',
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const menuItems = [
		{ text: 'Вручную', onClick: () => {} },
		{ text: 'Импорт из excel', onClick: () => {} },
	];

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Контейнеры'}
				btnDesktop={[
					<BtnDropdown mainButtonText="Добавить контейнеры" dropdownItems={menuItems} />,
					<Button variant="contained" color="primary" size="medium" onClick={handleExportExcel}>
						Экспортировать
					</Button>,
				]}
			/>

			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<Filter
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'status',
								title: 'Статус',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 1, label: 'Ожидается' },
									{ value: 2, label: 'Принят' },
									{ value: 3, label: 'На ремонте' },
									{ value: 4, label: 'Ожидает вывоза' },
									{ value: 5, label: 'Вывезен' },
								],
							},
							{
								name: 'size',
								title: 'Размер',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 1, label: '20' },
									{ value: 2, label: '40' },
									{ value: 3, label: '45' },
								],
							},
							{
								name: 'type',
								title: 'Тип',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 1, label: 'DC' },
									{ value: 2, label: 'DV' },
									{ value: 3, label: 'HC' },
									{ value: 4, label: 'PW' },
									{ value: 5, label: 'OT' },
									{ value: 6, label: 'OT HC' },
									{ value: 7, label: 'RF' },
									{ value: 8, label: 'RF HC' },
									{ value: 9, label: 'FR' },
									{ value: 10, label: 'FR HC' },
									{ value: 11, label: 'TC' },
									{ value: 12, label: 'HR' },
								],
							},
						]}
					/>
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						hookName={useLazyGetContainersListQuery}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="containersRtk"
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplContainers;
