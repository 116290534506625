import styles from "./Step.module.scss";
import { useNavigate } from "react-router-dom"
import {
  EmailShareButton,
  OKShareButton,
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  EmailIcon,
  OKIcon,
  TelegramIcon,
  VKIcon,
  WhatsappIcon,
} from "react-share";
import copy from 'copy-to-clipboard';
import { UniversalButton } from "../../../../../components";

function Step5({ setPage }) {
  const navigate = useNavigate();
  let typeUser = localStorage.getItem("typeUser");

  let data = `Фамилия и имя: ${localStorage.getItem(
    "FLName"
  )}, логин: ${localStorage.getItem("login")}, пароль: ${localStorage.getItem(
    "password"
  )}`;

  const copyData = () => {
    copy(data);
  };

  const handlerLinkPА = () => {
    localStorage.clear()
    navigate("/accountEmpl/empls")
  }

  return (
    <div className={styles.block}>
      <h5>
        {typeUser === "Новый клиент"
          ? typeUser
          : `Новый ${typeUser.toLowerCase()}`}
      </h5>
      <div>
        <div className={styles.block__info}>
          <p>Фамилия и имя: {localStorage.getItem("FLName")}</p>
          <p>Логин: {localStorage.getItem("login")}</p>
          <p>Пароль: {localStorage.getItem("password")}</p>
        </div>
        <div className={styles.block__btns}>
          <UniversalButton label={"Личный кабинет"} onClick={handlerLinkPА} color={"primary"} size={"large"} typeButton={"button"} />
          <UniversalButton label={"Скопировать"} onClick={copyData} color={"primary"} size={"large"} typeButton={"button"} />
        </div>
      </div>
      <div className={styles.block__links}>
        <EmailShareButton title={data} url={'http://example.com'}>
          <EmailIcon size={44} round={true} />
        </EmailShareButton>
        <OKShareButton title={data} url={'http://example.com'}>
          <OKIcon size={44} round={true} />
        </OKShareButton>
        <TelegramShareButton title={data} url={'http://example.com'}>
          <TelegramIcon size={44} round={true} />
        </TelegramShareButton>
        <VKShareButton title={data} url={'http://example.com'}>
          <VKIcon size={44} round={true} />
        </VKShareButton>
        <WhatsappShareButton title={data} url={'http://example.com'}>
          <WhatsappIcon size={44} round={true} />
        </WhatsappShareButton>
      </div>
    </div>
  );
}

export default Step5;
