// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { getContainersInOrder } from '../../../store/slices/orderSlice';
// import ConfirmationWindow from '../../ConfirmationWindow/ConfirmationWindow';
// import AddContainers from '../AddContainers/AddContainers';
// import Containers from '../Containers/Containers';
// import styles from './style.module.scss';
// export default function MainOrderContainers() {
// 	const dispatch = useDispatch();
// 	const { currentOrderContainerList } = useSelector((state) => state.order);
// 	const { terminalId, orderId } = useParams();
// 	useEffect(() => {
// 		console.log('useEffect');
// 		console.log(2);
// 		console.log(2);
// 		dispatch(getContainersInOrder({ terminal_id: terminalId, order_id: orderId, page: 1 }));
// 	}, []);
// 	const [viewMobContainers, setViewMobContainers] = useState(false);
// 	const [confWindow, setConfWindow] = useState(false);
// 	const [containerEL, setContainerEL] = useState();

// 	const [data, setData] = useState([]);
// 	const [page, setPage] = useState(1);
// 	const mob = false;
// 	const [removeAllCard, setRemoveAllCard] = useState(false);

// 	const handleRemoveCard = () => {
// 		const updatedData = data.filter((item) => item !== containerEL);
// 		setData(updatedData);
// 		setConfWindow(false);
// 	};

// 	const handleRemoveAllCard = () => {
// 		setData([]);
// 		setRemoveAllCard(false);
// 	};

// 	return (
// 		<div className={mob ? styles.mob_account__step : styles.account__step}>
// 			{(confWindow || removeAllCard) && (
// 				<ConfirmationWindow
// 					titleText={`Вы уверены, что хотите удалить ${removeAllCard ? 'все контейнеры?' : 'контейнер №' + containerEL.container_number}`}
// 					acceptBtnText={'Да, удалить'}
// 					acceptBtnClick={removeAllCard ? handleRemoveAllCard : handleRemoveCard}
// 					rejectBtnText={'Отменить'}
// 					rejectBtnClick={() => (removeAllCard ? setRemoveAllCard(false) : setConfWindow(false))}
// 				/>
// 			)}
// 			<AddContainers setData={setData} data={data} setPage={setPage} setViewMobContainers={setViewMobContainers} mob={mob} />
// 			{!mob && (
// 				<Containers
// 					setData1={setData}
// 					setRemoveAllCard={setRemoveAllCard}
// 					setContainerEL={setContainerEL}
// 					setConfWindow={setConfWindow}
// 					setData={setData}
// 					data={data}
// 					mob={mob}
// 					setPage={setPage}
// 				/>
// 			)}
// 			{viewMobContainers && (
// 				<Containers
// 					setData1={setData}
// 					setRemoveAllCard={setRemoveAllCard}
// 					setContainerEL={setContainerEL}
// 					setConfWindow={setConfWindow}
// 					data={data}
// 					mob={mob}
// 					setViewMobContainers={setViewMobContainers}
// 					setPage={setPage}
// 				/>
// 			)}
// 		</div>
// 	);
// }
import styles from './style.module.scss';
import Containers from '../Containers/Containers';
import { useEffect, useState } from 'react';
import ExportContainerList from '../ExportContainerList/ExportContainerList';
import OrderBlock from '../OrderBlock/OrderBlock';
import { useDispatch, useSelector } from 'react-redux';
import { getContainersInOrder, getOrgContainers } from '../../../store/slices/orderSlice';
import { useParams } from 'react-router-dom';
import ImportContextComponents from './ImportContextComponents/ImportContextComponents';
import ExportContextComponent from './ExportContextComponents/ExportContextComponent';

function MainOrderContainers({ setPage, mob }) {
	const dispatch = useDispatch();

	const { organizationContainerList, newOrder_terminal_id, newOrder_organization_id, currentOrderContainerList, currentOrderData } =
		useSelector((state) => state.order);
	// const [exportDataList, setExportDataList] = useState([]);

	const [selectedContainers, setSelectedContainers] = useState([]);
	//Для отчистки выбранных контейнеров
	useEffect(() => {
		setSelectedContainers([]);
	}, []);

	// useEffect(() => {
	// 	setExportDataList(organizationContainerList);
	// }, [organizationContainerList]);

	//! Контейнеры на текущем терминале у текущего клиента (вывоз)
	//! Прием - слева форма, справа пул
	return (
		<>
			{/* <p>DIDIDI {JSON.stringify(currentOrderData)}</p> */}
			{currentOrderData?.type == 2 ? (
				<>
					{/* <p>ЕКСПОРТ</p> */}

					<ExportContextComponent containersList={currentOrderContainerList} />
				</>
			) : (
				<>
					{/* <p>IMPORT</p> */}
					<ImportContextComponents containersList={currentOrderContainerList} />
				</>
			)}
		</>
		// <div className={`${styles.account__step} ${styles.export_step3}`}>
		// 	<OrderBlock
		// 		children={
		// 			<ExportContainerList
		// 				data1={selectedContainers}
		// 				setData1={setSelectedContainers}
		// 				data={exportDataList}
		// 				setData={setExportDataList}
		// 			/>
		// 		}
		// 		title="Контейнеры"
		// 	/>
		// 	<Containers
		// 		exportOrder
		// 		setData={setExportDataList}
		// 		setData1={setSelectedContainers}
		// 		data={currentOrderContainerList}
		// 		mob={mob}
		// 		setPage={setPage}
		// 	/>
		// </div>
	);
}

export default MainOrderContainers;
