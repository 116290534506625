import { NavLink } from 'react-router-dom';
import styles from './MenuDesktop.module.scss';
import SubMenu from './SubMenu';
import Cookies from 'js-cookie';
import { IconArrowDown, IconHome, IconSettings } from '../../../../../components/Icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../../store/slices/sideBarSlice';
import ProblemReport from './ProblemReport';
import { menuConfigEmpl, menuConfigClient } from './MenuConfig';
import { handleSearchTypePermission } from '../../../../../hoc/handleSearchTypePermission';
import { handleLogOut } from '../../../logOut';

function MenuDesktop() {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(true);
	const { sideBarActive } = useSelector((state) => state.sideBar);

	const handleModalSupport = () => {
		dispatch(setCurrentElementForSideBar(<ProblemReport />));
		dispatch(setSideBarActive(true));
	};

	function filterMenu(menuConfig, terminals) {
		return menuConfig.reduce((filteredMenu, item) => {
			if (item.subMenu) {
				// Если элемент меню содержит подменю то фильтруем его
				const filteredSubMenu = filterMenu(item.subMenu, terminals);
				if (filteredSubMenu.length > 0) {
					filteredMenu.push({ ...item, subMenu: filteredSubMenu });
				}
			} else if (handleSearchTypePermission(terminals, item.path, 'view')) {
				filteredMenu.push(item);
			}
			return filteredMenu;
		}, []);
	}

	const user = useSelector((state) => state.me.userData);
	const userType = Cookies.get('userType');
	const userMenu = userType == 1 ? filterMenu(menuConfigEmpl, user.terminals) : userType == 2 ? menuConfigClient : null;

	const [isHovered, setIsHovered] = useState(false);

	// Функции для изменения состояния при наведении и уходе мыши
	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	return (
		<div className={styles.menuDesktop} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<ul className={styles.menuDesktop__list}>
				<div
					className={`${styles.btnHide} ${open && styles.open} ${isHovered ? styles.visible : ''}`}
					onClick={() => {
						setOpen(!open);
					}}
				>
					<IconArrowDown size={'mediumL'} color={'white'} className={`${open ? styles.arrowLeft : styles.arrowRight}`} />
				</div>

				{userMenu?.map((item, index) => (
					<li
						key={index}
						className={`${item.subMenu ? styles.menuDesktop__subMenu : styles.menuDesktop__item}`}
						style={open ? { width: '240px' } : { display: 'flex', justifyContent: 'flex-start' }}
					>
						{item.subMenu ? (
							<SubMenu item={item} open={open} />
						) : (
							<>
								<NavLink
									to={item.path}
									className={({ isActive }) =>
										`${isActive ? styles.activeLink : ''}
                  `
									}
								>
									{item.icon}
									{!open ? null : item.label}
								</NavLink>
							</>
						)}
					</li>
				))}
			</ul>

			{/* дополнительные нижние пункты меню */}
			<ul className={styles.menuDesktop__other} style={open ? null : { width: 'fit-content' }}>
				<li
					className={styles.menuDesktop__item}
					style={open ? null : { display: 'flex', justifyContent: 'flex-start' }}
					onClick={handleModalSupport}
				>
					<div className={`${styles.menuDesktop__item_openModal} ${sideBarActive ? styles.activeLink : ''}`}>
						<IconSettings color={'black'} size={'medium'} />
						{!open ? null : 'Поддержка'}
					</div>
				</li>
				<li
					className={styles.menuDesktop__item}
					style={open ? null : { display: 'flex', justifyContent: 'flex-start' }}
					onClick={handleLogOut}
				>
					<div className={styles.menuDesktop__item_openModal}>
						<IconHome color={'white'} size={'medium'} />
						{!open ? null : 'Выйти'}
					</div>
				</li>
			</ul>
		</div>
	);
}

export default MenuDesktop;
