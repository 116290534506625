import { IconBurger, IconCar, IconHome, IconPlusCircle, IconTable } from '../../../../../components/Icons';
import styles from './MenuMobile.module.scss';
import { NavLink } from 'react-router-dom';

function MenuMobile() {
	// пункты меню
	const Menulist = [
		{
			path: 'main',
			icon: <IconHome size={'large'} />,
			text: 'Главная',
		},
		{
			path: 'cargo',
			icon: <IconCar size={'large'} />,
			text: 'Отправить',
		},

		{
			// path: '/accountEmpl/receptionTransmission/false',
			path: '/accountEmpl/containerAcceptance',
			icon: <IconPlusCircle size={'large'} />,
			text: 'Принять',
		},
		{
			path: 'summary',
			icon: <IconTable size={'large'} />,
			text: 'Таблица',
		},
	];

	return (
		<div className={styles.menuMobile}>
			{Menulist?.map((el, ind) => (
				<li key={ind} className={styles.menuMobile__item}>
					<NavLink to={el.path} className={({ isActive }) => (isActive ? styles.activeLink : '')}>
						{el.icon}
						<p className={styles.menuMobile__item__text}>{el.text}</p>
					</NavLink>
				</li>
			))}
			<li className={styles.menuMobile__item}>
				<IconBurger size={'large'} />
				<p className={styles.menuMobile__item__text}>Меню</p>
			</li>
		</div>
	);
}

export default MenuMobile;
