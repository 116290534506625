import UniversalButton from '../../Buttons/UniversalButton/UniversalButton';
import { IconEdit, IconTrash } from '../../Icons';
import CardContainer from '../CardContainer/CardContainer';
import styles from './Containers.module.scss';
import RequireUI from '../../../hoc/RequireUI';

//!
// type: added / notAdded - Разновидность блока контейнера когда его добавили в заявку или нет
function ContainersItem({ el, type, handleDelete, handleChange, handleAddToOrder, handleRemoveFromOrder }) {
	// console.log(el, type, 'ЕЛЕМЕНТ');
	return (
		<div
			className={`${styles.containersItem} ${type === 'added' ? styles.delActiveContainerItem : ''} ${type === 'notAdded' ? styles.activeContainersItem : ''}`}
		>
			{type === 'added' && <CardContainer type={'added'} action={handleRemoveFromOrder} el={el} />}
			<div className={styles.containersItem__actions}>
				<RequireUI type={{ name: 'DelContainers' }}>
					<UniversalButton
						color={'error'}
						size={'small'}
						type={'with-border'}
						// disabled={el.entrypass.length === 0}
						onClick={handleDelete}
						icon={<IconTrash size={'medium'} color={'core-state-error'} />}
					/>
				</RequireUI>
				<RequireUI type={{ name: 'ChangeContainers' }}>
					<UniversalButton
						color={'primary'}
						size={'small'}
						// disabled={el.entrypass.length === 0}
						type={'with-border'}
						onClick={(e) => handleChange(e, el)}
						icon={<IconEdit size={'medium'} color={'primary'} />}
					/>
				</RequireUI>
			</div>
			{type === 'notAdded' && (
				<>
					<CardContainer action={handleAddToOrder} type={'notAdded'} active={true} el={el} />
				</>
			)}
		</div>
	);
}

export default ContainersItem;
