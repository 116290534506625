export const allPermissions = [
	{ name: 'AddEmpls', permissions: [1] }, // Разрешение на добавление сотрудников +
	{ name: 'ChangeEmpls', permissions: [2] }, // Разрешение на изменение сотрудников +
	{ name: 'DelEmpls', permissions: [3] }, // Разрешение на удаление сотрудников +
	{ name: 'ViewEmpls', permissions: [4] }, // Разрешение на просмотр сотрудников +
	// { name: 'AddUsers', permissions: [5] }, // Разрешение на добавление пользователей ?
	// { name: 'ChangeUsers', permissions: [6] }, // Разрешение на изменение пользователей ?
	// { name: 'DelUsers', permissions: [7] }, // Разрешение на удаление пользователей ?
	// { name: 'ViewUsers', permissions: [8] }, // Разрешение на просмотр пользователей ?
	{ name: 'AddGroups', permissions: [17] }, // Разрешение на добавление групп +
	{ name: 'ChangeGroups', permissions: [18] }, // Разрешение на изменение групп
	{ name: 'DelGroups', permissions: [19] }, // Разрешение на удаление групп (есть)
	{ name: 'ViewGroups', permissions: [20] }, // Разрешение на просмотр групп (есть)
	{ name: 'AddOrgs', permissions: [29] }, // Разрешение на добавление организаций (есть)
	{ name: 'ChangeOrgs', permissions: [30] }, // Разрешение на изменение организаций (есть)
	{ name: 'DelOrgs', permissions: [31] }, // Разрешение на удаление организаций (есть)
	{ name: 'ViewOrgs', permissions: [32] }, // Разрешение на просмотр организаций (есть но раскоментить)
	// { name: 'AddUserToOrg', permissions: [29] }, // Разрешение на добавление пользователя в организацию (нет макетов)
	// { name: 'ChangeUserToOrg', permissions: [30] }, // Разрешение на изменение пользователя в организации (нет макетов)
	// { name: 'DelUserToOrg', permissions: [31] }, // Разрешение на удаление пользователя из организации (нет макетов)
	// { name: 'ViewUserToOrg', permissions: [32] }, // Разрешение на просмотр пользователей организации (нет макетов)
	{ name: 'AddContracts', permissions: [37] }, // Разрешение на добавление договоров
	{ name: 'ChangeContracts', permissions: [38] }, // Разрешение на изменение договоров
	{ name: 'DelContracts', permissions: [39] }, // Разрешение на удаление договоров
	{ name: 'ViewContracts', permissions: [40] }, // Разрешение на просмотр договоров
	{ name: 'AddLines', permissions: [41] }, // Разрешение на добавление линий
	{ name: 'ChangeLines', permissions: [42] }, // Разрешение на изменение линий
	{ name: 'DelLines', permissions: [43] }, // Разрешение на удаление линий
	{ name: 'ViewLines', permissions: [44] }, // Разрешение на просмотр линий
	{ name: 'AddCargo', permissions: [45] }, // Разрешение на добавление грузов
	{ name: 'ChangeCargo', permissions: [46] }, // Разрешение на изменение грузов
	{ name: 'DelCargo', permissions: [47] }, // Разрешение на удаление грузов
	{ name: 'ViewCargo', permissions: [48] }, // Разрешение на просмотр грузов
	{ name: 'AddContainers', permissions: [49] }, // Разрешение на добавление контейнеров (есть в заявке внутри)
	{ name: 'ChangeContainers', permissions: [50] }, // Разрешение на изменение контейнеров (есть в заявке внутри, есть в таблице)
	{ name: 'DelContainers', permissions: [51] }, // Разрешение на удаление контейнеров (есть в заявке внутри)
	{ name: 'ViewContainers', permissions: [52] }, // Разрешение на просмотр контейнеров (есть в заявке внутри, есть просмотр таблицы)
	{ name: 'AddMsgNumbers', permissions: [53] }, // Разрешение на добавление номеров сообщений
	{ name: 'ChangeMsgNumbers', permissions: [54] }, // Разрешение на изменение номеров сообщений
	{ name: 'DelMsgNumbers', permissions: [55] }, // Разрешение на удаление номеров сообщений
	{ name: 'ViewMsgNumbers', permissions: [56] }, // Разрешение на просмотр номеров сообщений
	{ name: 'AddApplications', permissions: [57] }, // Разрешение на добавление заявок (есть)
	{ name: 'ChangeApplications', permissions: [58] }, // Разрешение на изменение заявок (есть кроме даты и ответсвенного)
	{ name: 'DelApplications', permissions: [59] }, // Разрешение на удаление заявок (есть без бэка)
	{ name: 'ViewApplications', permissions: [60] }, // Разрешение на просмотр заявок (есть)

	// TODO ПОНЯТЬ ЧТО ТАКОЕ Transports
	// { name: 'AddTransports', permissions: [77] }, // Разрешение на добавление перевозок
	// { name: 'ChangeTransports', permissions: [78] }, // Разрешение на изменение перевозок
	// { name: 'DelTransports', permissions: [79] }, // Разрешение на удаление перевозок
	// { name: 'ViewTransports', permissions: [80] }, // Разрешение на просмотр перевозок
	{ name: 'AddServices', permissions: [61] }, // Разрешение на добавление услуг
	{ name: 'ChangeServices', permissions: [62] }, // Разрешение на изменение услуг
	{ name: 'DelServices', permissions: [63] }, // Разрешение на удаление услуг
	{ name: 'ViewServices', permissions: [64] }, // Разрешение на просмотр услуг
	{ name: 'AddDrivers', permissions: [65] }, // Разрешение на добавление водителей
	{ name: 'ChangeDrivers', permissions: [66] }, // Разрешение на изменение водителей
	{ name: 'DelDrivers', permissions: [67] }, // Разрешение на удаление водителей
	{ name: 'ViewDrivers', permissions: [68] }, // Разрешение на просмотр водителей
	{ name: 'AddTerminals', permissions: [69] }, // Разрешение на добавление терминалов +
	{ name: 'ChangeTerminals', permissions: [70] }, // Разрешение на изменение терминалов
	{ name: 'DelTerminals', permissions: [71] }, // Разрешение на удаление терминалов
	{ name: 'ViewTerminals', permissions: [72] }, // Разрешение на просмотр терминалов +
	// { name: 'AddUserTerminals', permissions: [73] }, // Разрешение на добавление пользователей терминалов
	// { name: 'ChangeUserTerminals', permissions: [74] }, // Разрешение на изменение пользователей терминалов
	// { name: 'DelUserTerminals', permissions: [75] }, // Разрешение на удаление пользователей терминалов
	// { name: 'ViewUserTerminals', permissions: [76] }, // Разрешение на просмотр пользователей терминалов
	{ name: 'AddVehicles', permissions: [77] }, // Разрешение на добавление транспортных средств
	{ name: 'ChangeVehicles', permissions: [78] }, // Разрешение на изменение транспортных средств
	{ name: 'DelVehicles', permissions: [79] }, // Разрешение на удаление транспортных средств
	{ name: 'ViewVehicles', permissions: [80] }, // Разрешение на просмотр транспортных средств
];

// export const allPermissions = [
// 	{ name: 'EmplCanAddEmpls', permissions: [1] }, // Разрешение на добавление сотрудников
// 	{ name: 'EmplCanChangeEmpls', permissions: [2] }, // Разрешение на изменение сотрудников
// 	{ name: 'EmplCanDelEmpls', permissions: [3] }, // Разрешение на удаление сотрудников
// 	{ name: 'EmplCanViewEmpls', permissions: [4] }, // Разрешение на просмотр сотрудников
// ];

// Пользователи
// Роли
// Терминалы

// userType на запрос в сотрудникахы
