import styles from '../AccountEmpl.module.scss';
import { useMediaQuery } from 'react-responsive';
import { IconDisketa, IconArrowRight } from '../../../../components/Icons';
import { useEffect, useRef, useState } from 'react';
import Header from '../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTerminalDataType } from '../../../../store/slices/terminalPageSlice';
import UniversalButton from '../../../../components/Buttons/UniversalButton/UniversalButton';
import CreateOrderExportStep3 from '../../../../components/Order/CreateOrderSteps/CreateOrderExportStep3';
import Terminals from '../../../../components/Order/Terminals/Terminals';
import CreateOrderStep2 from '../../../../components/Order/CreateOrderSteps/CreateOrderStep2';
import CreateOrderImportStep3 from '../../../../components/Order/CreateOrderSteps/CreateOrderImportStep3';
import { clearAllOrderData, clearNavigationViaRequestSuccess, putLinkContainers } from '../../../../store/slices/orderSlice';
import { useNavigate } from 'react-router-dom';
import { useUpdateEffect } from '../../../../hooks/useUpdateEffect';

function AccountEmplCreateOrder({ type }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { newOrder_organization_id, newOrderData, containersToAddInOrder, putLinkContainers_success } = useSelector(
		(state) => state.order,
	); //* 1й шаг - id Выбранного терминала
	const [activeTerminal, setActiveTerminal] = useState(false);
	// отслеживание ширины окна
	const isDesktop = useMediaQuery({ minWidth: 1279.9 });
	const setCurrentDataType = (typeData) => {
		setPage(Number(typeData));
		return dispatch(setCurrentTerminalDataType(typeData));
	};

	// список всех контейнеров
	const [data, setData] = useState([]);
	// список выбранных контейнеров на вывоз
	const [exportData, setExportData] = useState([]);
	//* Пример контейнера(объект)
	// {
	//     id: "11",
	//     type: "DC",
	//     line: "MSC",
	//     container_number: "11  WFH40BU0001",
	//     size: "30",
	//     is_dangerous: true,
	//     is_filled: true
	// }

	//* 2й шаг - Выбранный клиент
	const [client, setClient] = useState();

	// шаги
	const [page, setPage] = useState(0);

	const pageDisplay = () => {
		if (page === 0) {
			return <Terminals activeTerminal={activeTerminal} setActiveTerminal={setActiveTerminal} />;
		} else if (page === 1) {
			return <CreateOrderStep2 client={client} setClient={setClient} mob={!isDesktop} setPage={setPage} />;
		} else if (page === 2 && type === 'import') {
			//! Страгица 3го шага заявки на прием
			return <CreateOrderImportStep3 data={data} setData={setData} mob={!isDesktop} setPage={setPage} />;
		} else {
			//! Страгица 3го шага заявки на вывоз
			return <CreateOrderExportStep3 selectedContainers={exportData} setSelectedContainers={setExportData} setPage={setPage} />;
		}
		//* Страница с машинами/перевозчиками - <CreateOrderStep4 data={data} mob={!isDesktop} setPage={setPage} />
	};

	const handleCreateImportOrder = () => {
		const containerIds = containersToAddInOrder.map((el) => (el = el.id));
		dispatch(
			putLinkContainers({
				terminal_id: 1,
				requestData: { org_id: newOrder_organization_id, order_id: newOrderData.id, containers: containerIds },
			}),
		);
	};
	//! Переход на страницу заявки в случае успешного добавления контейнера
	//! С новой логикой чуть по другому но схема рабочая
	// useEffect(() => {
	// 	if (postContainer_success) {
	// 		navigate(`/accountEmpl/orders/${newOrder_terminal_id}/${newOrderData?.id}`);
	// 	}
	// }, [postContainer_success]);

	//! Финаьное создание заявки на вывоз. Добавление контейнеров к ней
	const handleCreateExportOrder = () => {
		const containers = containersToAddInOrder.map((container) => (container = container.id));
		dispatch(
			putLinkContainers({
				terminal_id: 1,
				requestData: { org_id: newOrder_organization_id, order_id: newOrderData.id, containers: containers },
			}),
		);
	};

	//* Обнуление стейтов при переходе на другой тип создания заявки
	useEffect(() => {
		dispatch(clearAllOrderData());
		setActiveTerminal(false);
		setPage(0);
		setClient();
	}, [type]);

	//! Переход на страницу заявки в случае успешной привязки контейнеров
	//TODO бывает баг при перезагрузке
	useUpdateEffect(() => {
		navigate(`/accountEmpl/orders/${newOrderData.terminal.id}/${newOrderData?.id}`);

		dispatch(clearNavigationViaRequestSuccess());
	}, [putLinkContainers_success]);

	useEffect(() => {
		localStorage.setItem('terminalID', activeTerminal);
	}, [activeTerminal]);

	return (
		<div className={styles.account__wrap}>
			<Header
				createOrder
				type={type}
				text={`Создание заявки на ${type === 'import' ? 'прием контейнера' : 'вывоз контейнеров'}`}
				step={isDesktop && page + 1}
			/>
			<div className={styles.account__container}>
				<div className={styles.contentBtns}>
					{/* //* Основной контент */}
					{pageDisplay()}
					<div className={`${styles.bottomBtns} ${page === 2 ? styles.bottomBtnsOrderStep3 : ''}`}>
						{/* //* Нижние кнопки */}
						{/* {page === 2 && (
							<UniversalButton
								label={'Сохранить'}
								color={'succesful'}
								size={'medium'}
								onClick={() => console.log('Сохранение данных заявки')}
								type={'with-border'}
								icon={<IconDisketa size={'largeS'} color="core-state-success" />}
							/>
						)} */}
						<div className={styles.nextPrevBtns}>
							{page !== 0 && (
								<UniversalButton
									label={'Назад'}
									color={'secondary'}
									size={'medium'}
									onClick={() => setCurrentDataType(page - 1)}
								/>
							)}
							{page === 2 ? (
								<UniversalButton
									label={'Создать заявку'}
									color={'succesful'}
									disabled={containersToAddInOrder.length === 0 && (type === 'import' || exportData.length === 0)}
									size={'medium'}
									onClick={type === 'import' ? handleCreateImportOrder : handleCreateExportOrder}
								/>
							) : (
								<UniversalButton
									label={'Продолжить'}
									disabled={
										!((page === 0 && (activeTerminal || activeTerminal === 0)) || (page === 1 && client) || page === 2)
									}
									icon={<IconArrowRight color={'white'} size={'largeS'} />}
									color={'accent'}
									size={'medium'}
									onClick={() => setCurrentDataType(page + 1)}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplCreateOrder;
