import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconSideRight({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M20 4V20M16 5.77778V18.2222C16 19.2041 15.1046 20 14 20H6C4.89543 20 4 19.2041 4 18.2222V5.77778C4 4.79594 4.89543 4 6 4L14 4C15.1046 4 16 4.79594 16 5.77778Z"
					stroke="#8E8E93"
					strokeWidth="1.33333"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}
export default IconSideRight;
