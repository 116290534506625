import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Button, Box } from '@mui/material';
import CardComponent from '../../../../newComponents/CardComponent/CardComponent';
import { useLazyGetOrdersListQuery } from '../../../../store/newApi/orders/orders';
import { Link, useNavigate } from 'react-router-dom';
import { formatInitialDate, formatDate } from '../../../../helpers/formatDate';
import { account, accountGridItemHalf } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import Preloader from '../../../../components/Preloader/Preloader';
import BtnDropdown from '../../../../newComponents/BtnDropdown/BtnDropdown.jsx';
import Filter from '../../../../newComponents/Filter/Filter.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { FILTER_TYPES } from '../../../../config/filter';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/orders.js';

function AccountEmplOrders() {
	const dispatch = useDispatch();
	const filters = useSelector((state) => state['ordersRtk']?.filter) ?? {};
	const [trigger, { data, isLoading }] = useLazyGetOrdersListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const navigate = useNavigate();
	const [filtering, setFiltering] = useState(false);

	// Для режима выбора карточек
	const [selectionMode, setSelectionMode] = useState(false);
	const [selectedCards, setSelectedCards] = useState([]);

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ ...filters, page: 1, page_size: 20 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		if (Object.keys(filters).length) {
			trigger({ ...filters, page: 1, page_size: 20, uniq: Math.random() });
			setFiltering(true);
			setFetching(true);
		}
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла

	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
		}
	}, [data]);

	const typeStatusOrders = {
		0: 'Создана',
		1: 'Принята',
		2: 'В работе',
		3: 'Завершена',
	};

	// Обработка выбора карточек
	const handleCardSelect = (id) => {
		setSelectedCards((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((cardId) => cardId !== id);
			} else {
				return [...prevSelected, id];
			}
		});
	};

	// Печать выбранных карточек
	const handlePrintSelected = () => {
		console.log(selectedCards);
	};

	const menuItems = [
		{
			text: 'Приём контейнеров',
			onClick: () => {
				navigate('/accountEmpl/createImportOrder');
			},
		},
		{
			text: 'Выдача контейнеров',
			onClick: () => {
				navigate('/accountEmpl/createExportOrder');
			},
		},
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Заявки на контейнеры'}
				btnDesktop={[<BtnDropdown mainButtonText="Добавить заявку" dropdownItems={menuItems} />]}
			/>
			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					<div className={styles.account__filter}>
						<Filter
							onSetFilter={handleSetFilter}
							onResetFilter={handleResetFilter}
							parameters={[
								{
									name: 'order_type',
									title: 'Тип',
									type: FILTER_TYPES.CHECKBOX,
									elements: [
										{ value: 1, label: 'Ввоз' },
										{ value: 2, label: 'Вывоз' },
									],
								},
								{
									name: 'order_status',
									title: 'Статус',
									type: FILTER_TYPES.CHECKBOX,
									elements: [
										{ value: 0, label: 'Создана' },
										{ value: 1, label: 'Принята' },
										{ value: 2, label: 'В работе' },
										{ value: 3, label: 'Завершена' },
									],
								},
								{
									name: 'terminal_id',
									title: 'Терминал',
									type: FILTER_TYPES.CHECKBOX,
									elements: [
										{ value: 1, label: 'TETRIS' },
										{ value: 2, label: 'SEVER' },
										{ value: 3, label: 'NORD' },
										{ value: 4, label: 'MIX' },
										{ value: 5, label: 'LOBNYA' },
										{ value: 6, label: 'KRYMSK' },
									],
								},
							]}
						/>
					</div>

					{/* <div>
						<Button onClick={() => setSelectionMode(!selectionMode)}>{selectionMode ? 'Отмена' : 'Выбрать'}</Button>
					</div> */}
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemHalf(isDesktop)} key={index}>
									{selectionMode ? (
										<CardComponent
											selectionMode={selectionMode}
											isSelected={selectedCards.includes(card?.id)}
											onSelectCard={() => handleCardSelect(card?.id)}
											title={`Заявка №${card?.id}`}
											rightText={card?.status !== undefined ? typeStatusOrders[card?.status] : '-'}
											status={card?.status == 2 ? 'warning' : card?.status == 3 ? 'successful' : null}
											leftAdditionalInfo={[
												{
													name: 'Тип заявки:',
													text: `${card?.type == 1 ? 'Ввоз' : card?.type == 2 ? 'Вывоз' : '-'}`,
												},
												{
													name: 'Клиент:',
													text: `${card?.client?.organization_name ? card?.client?.organization_name : '-'}`,
												},
												{
													name: 'Транспорт:',
													text: `${card?.expedition_type == 0 ? 'Автомобиль' : card?.expedition_type == 1 ? 'Поезд' : '-'}`,
												},
												{
													name: 'Дата создания:',
													text: `${card?.created_at ? formatInitialDate(card?.created_at) : '-'}`,
												},
												{
													name: 'Примерная дата завершения:',
													text: `${card?.eta ? formatDate(card?.eta) : '-'}`,
												},
											]}
											rightAdditionalInfo={[
												{
													name: 'Всего:',
													text: `${card?.completion?.total}`,
												},
												{
													name: 'Прибыло:',
													text: `${card?.completion?.arrived}`,
												},
												{
													name: 'Ожидается:',
													text: `${card?.completion?.expected}`,
												},
												{
													name: 'Отменено: ',
													text: `${card?.completion?.canceled}`,
												},
												{
													name: 'Без а/м:',
													text: `${card?.completion?.without_vehicle}`,
												},
											]}
										/>
									) : (
										<Link to={`${card?.terminal?.id}/${card?.id}`}>
											<CardComponent
												selectionMode={selectionMode}
												isSelected={selectedCards.includes(card?.id)}
												onSelectCard={() => handleCardSelect(card?.id)}
												title={`Заявка №${card?.id}`}
												rightText={card?.status !== undefined ? typeStatusOrders[card?.status] : '-'}
												status={card?.status == 2 ? 'warning' : card?.status == 3 ? 'successful' : null}
												leftAdditionalInfo={[
													{
														name: 'Тип заявки:',
														text: `${card?.type == 1 ? 'Ввоз' : card?.type == 2 ? 'Вывоз' : '-'}`,
													},
													{
														name: 'Клиент:',
														text: `${card?.client?.organization_name ? card?.client?.organization_name : '-'}`,
													},
													{
														name: 'Транспорт:',
														text: `${card?.expedition_type == 0 ? 'Автомобиль' : card?.expedition_type == 1 ? 'Поезд' : '-'}`,
													},
													{
														name: 'Дата создания:',
														text: `${card?.created_at ? formatInitialDate(card?.created_at) : '-'}`,
													},
													{
														name: 'Примерная дата завершения:',
														text: `${card?.eta ? formatDate(card?.eta) : '-'}`,
													},
												]}
												rightAdditionalInfo={[
													{
														name: 'Всего:',
														text: `${card?.completion?.total}`,
													},
													{
														name: 'Прибыло:',
														text: `${card?.completion?.arrived}`,
													},
													{
														name: 'Ожидается:',
														text: `${card?.completion?.expected}`,
													},
													{
														name: 'Отменено: ',
														text: `${card?.completion?.canceled}`,
													},
													{
														name: 'Без а/м:',
														text: `${card?.completion?.without_vehicle}`,
													},
												]}
											/>
										</Link>
									)}
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}
export default AccountEmplOrders;
