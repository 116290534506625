import React from "react";
import styles from "./Modal.module.scss";
import { IconArrowLeft, IconCross } from "../Icons";
import UniversalButton from "../Buttons/UniversalButton/UniversalButton";

function Modal({ title, arrow, onClose, noCross, onBack, children, displayNone }) {
  const handleCloseModal = (event) => {
    event.stopPropagation();
    onClose();
  };
  const handleBackModal = (event) => {
    event.stopPropagation();
    onBack();
  };
  return (
    <div className={`${styles.bgModal} ${displayNone ? styles.modalProfile : ''}`} onClick={onClose}>
      <div className={styles.bg}>
        <div
          className={`${styles["navBar"]} ${arrow ? `${styles["arrowBar"]}` : ""
            }`}
        >
          {arrow ? (
            <UniversalButton icon={<IconArrowLeft size={"large"} color={"black"} />} onClick={handleBackModal} size={"small"} typeButton={"button"} />
          ) : null}
          {
            noCross ? (
              null
            ) : (
              <UniversalButton icon={<IconCross size={"large"} color={"black"} />} onClick={handleCloseModal} size={"small"} typeButton={"button"} />
            )
          }
        </div>
        <div className={styles.contModal} onClick={(e) => e.stopPropagation()}>
          <div className={styles.mainBlock}>
            {title ? (<p className={styles.modalTitle}>{title}</p>) : ""}

            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
