import React from "react";
import { useForm } from "react-hook-form";
import '../../assets/styles/mainStyles.scss';

function Form({ defaultValues, children, onSubmit, }) {
  const { register, setValue, handleSubmit, setFocus, formState, clearErrors } = useForm({
    mode: "onChange",
    defaultValues: {
      email: localStorage.getItem("email"),
      FLName: localStorage.getItem("FLName"),
      login: localStorage.getItem("login"),
      numberContract: localStorage.getItem("numberContract"),
      urAdress: localStorage.getItem("urAdress"),
      urName: localStorage.getItem("urName"),
      selectInput: localStorage.getItem("selectInput"),
      autoNum: '',
    }
  });
  const { errors } = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form">
      {React.Children.map(children, (child) => {
        return child.props.name
          ? React.createElement(child.type, {
            ...{
              ...child.props,
              register,
              setFocus,
              errors,
              key: child.props.name,
            },
          })
          : child;
      })}
    </form>
  );
}

export default Form;
