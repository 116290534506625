import React from "react";
import styles from "./SeparateModal.module.scss";

function SeparateModal({ onClose, children, style, btnIcon }) {

    return (
        <div className={`${styles["bgModal"]} ${styles[style]}`} onClick={onClose}>
            <div className={styles.bg}>
                <div className={styles.navBar}>
                    {btnIcon}
                </div>
                <div className={styles.contModal} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.content}>{children}</div>
                </div>
            </div>
        </div>
    );
}

export default SeparateModal;
