import React from 'react';
import styles from './ConfirmationWindow.module.scss';
import UniversalButton from '../Buttons/UniversalButton/UniversalButton';

function ConfirmationWindow({ acceptBtnClick, rejectBtnClick, titleText, acceptBtnText, rejectBtnText }) {
	return (
		<div className={`${styles['bgModal']}`}>
			<div className={styles.bg}>
				<p>{titleText}</p>
				<div className={styles.modalBtns}>
					<UniversalButton label={rejectBtnText} type={'button'} size={'medium'} color={'primary'} onClick={rejectBtnClick} />
					<UniversalButton label={acceptBtnText} color={'error'} type={'button'} size={'medium'} onClick={acceptBtnClick} />
				</div>
			</div>
		</div>
	);
}

export default ConfirmationWindow;
