import React from 'react';
import styles from './InfoBlocks.module.scss';
import UniversalButton from '../../../Buttons/UniversalButton/UniversalButton';
import { IconEdit } from '../../../Icons';
import RequireUI from '../../../../hoc/RequireUI';

function MediumInfoBlock({
	type,
	title,
	subTitle,
	telegram,
	whatsapp,
	number,
	mail,
	contractNumber,
	adress,
	quota,
	noEditBtn,
	childrenInputs,
	noOfficerText,
	noOfficerBtnText,
}) {
	return (
		<div className={styles.mainMediumBlock}>
			{!childrenInputs ? (
				<div className={styles.firstBlock}>
					{/* Заголовок и подзаголовок */}
					<div className={styles.titleSubTitle}>
						<p className={styles.title}>{title}</p>
						{/* Подзаголовок отображается только если тип блока не 'fullWithoutSubTitle' и не переданы тексты для блока отсутствия ответственного */}
						{type !== 'fullWithoutSubTitle' && (!noOfficerText || !noOfficerBtnText) && (
							<p className={styles.subTitle}>{subTitle}</p>
						)}
					</div>
					{/* Отображение блока отсутствия ответственного, если переданы соответствующие тексты */}
					{noOfficerText || noOfficerBtnText ? (
						<div className={styles.main_absenceEmployee_block}>
							<p>{noOfficerText}</p>
							<UniversalButton typeButton={'button'} size={'small'} label={noOfficerBtnText} color={'primary'} />
						</div>
					) : // Отображение содержимого в зависимости от типа блока
					type === 'full' || type === 'fullWithoutSubTitle' ? (
						<div className={styles.fullInfo}>
							{/* Полная информация о клиенте */}
							<div>
								Телеграмм: <p>{telegram}</p>
							</div>
							<div>
								Whatsapp: <p>{whatsapp}</p>
							</div>
							<div>
								Телефон: <p>{number}</p>
							</div>
							<div>
								E-mail: <p>{mail}</p>
							</div>
						</div>
					) : type === 'smallInfo' ? (
						<div className={styles.fullInfo}>
							{quota ? (
								<div>
									Вместимость (квота): <p>{quota}</p>
								</div>
							) : (
								<div>
									Договор: <p className={styles.contractNumber}>{contractNumber}</p>
								</div>
							)}
							<div>
								Адрес: <p>{adress}</p>
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
			) : (
				childrenInputs
			)}
			{/* Рендеринг кнопки редактирования, если она не отключена и не переданы тексты для блока отсутствия ответственного */}
			{!noEditBtn && (!noOfficerText || !noOfficerBtnText) && (
				<RequireUI type={{ name: 'ChangeApplications' }}>
					<UniversalButton
						typeButton={'button'}
						size={'small'}
						type={'with-border'}
						icon={<IconEdit color={'primary'} />}
						color={'primary'}
					/>
				</RequireUI>
			)}
		</div>
	);
}

export default MediumInfoBlock;
