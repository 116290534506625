import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconBurger({ size, onClick, color, className }) {
  return (
    <div onClick={onClick} className={`${styles.icon} ${className}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 19.5 13.5">
        <g>
          <path fill={IconColor(color)} d="M3,11.25a.75.75,0,0,0,0,1.5Zm18,1.5a.75.75,0,0,0,0-1.5ZM3,5.25a.75.75,0,0,0,0,1.5Zm18,1.5a.75.75,0,0,0,0-1.5ZM3,17.25a.75.75,0,0,0,0,1.5Zm18,1.5a.75.75,0,0,0,0-1.5Zm-18-6H21v-1.5H3Zm0-6H21V5.25H3Zm0,12H21v-1.5H3Z" transform="translate(-2.25 -5.25)" />
        </g>
      </svg>
    </div>
  );
}

export default IconBurger;

