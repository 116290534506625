const initialState = {
    lines: []
};

const linesContainerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LINES_SUCCESS':
            return { ...state, lines: action.payload }
        default:
            return state;
    }
};

export default linesContainerReducer;