export const sideNameToType = (sideName) => {
	switch (sideName) {
		case 'Передний торец':
			return 'F';
		case 'Левая сторона':
			return 'L';
		case 'Правая сторона':
			return 'R';
		case 'Торец с дверью':
			return 'D';
		case 'Крыша':
			return 'T';
		case 'Низ':
			return 'U';
		case 'Пол':
			return 'B';
		case 'Контейнер полностью':
			return 'X';
		case 'Внешняя поверхность контейнера':
			return 'E';
		case 'Внутренняя часть контейнера':
			return 'I';
		case 'Холодильная установка':
			return 'M';
		case 'Дизель-генераторная установка':
			return 'G';
		default:
			break;
	}
};
