import styles from '../AccountClient.module.scss';
import { handleLogOut } from '../../logOut'
import Header from '../components/Header/Header';
import { UniversalButton } from '../../../../components';

function AccountClientMain() {

  return (
    <div className={styles.account__wrap}>
      <Header text={'Главная'} />
      <div className={styles.account__container}>
        <div className={styles.account__stack}>
          <UniversalButton label={"Выйти"} color={"primary"} onClick={handleLogOut} size={"large"} typeButton={"button"} />
        </div>
      </div>
    </div>
  );
}

export default AccountClientMain;
