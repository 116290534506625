import axios from './configAxios';

export const getEntriesList = async (url, orderId) => {
	console.log(url, orderId, 'API');
	return await axios.get(`${url}&order_id=${orderId}`);
};

export const getEntriesListTable = async (url) => {
	return await axios.get(`${url}`);
};
