import api from '../../api.js';
import { buildQueryString } from '../../../newComponents/Filter/utils.js';

export const entryreports = api.injectEndpoints({
	endpoints: (builder) => ({
		getEntryreportsList: builder.query({
			query: (params) => {
				let queryParams = { ...params };

				delete queryParams.uniq;

				return {
					url: `/api/entryreports/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetEntryreportsListQuery, useLazyGetEntryreportsListQuery } = entryreports;
