import { sideNameToType } from './sideNameToType';

export const isBlockChoosen = (damagesList, sideIndex, divider, part) => {
	const damage = damagesList?.find(
		(damage) =>
			(sideNameToType(damage?.side) === sideIndex && damage?.placement?.some((d) => d?.option === divider && d?.parts == part)) ||
			(damage?.option === divider && damage?.parts === part),
	);

	return damage?.side ? damagesList?.indexOf(damage) + 1 : damage ? true : false;
};
