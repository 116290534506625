import { createTheme } from "@mui/material/styles";

const breakpointsTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 720,
      desktop: 1140,
    },
  },
});

export default breakpointsTheme;
