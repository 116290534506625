import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllContainersList } from '../../api/containers/getAllContainersList';
import { getLinesContainer } from '../../api/containers/getLinesContainer';
import { putOneContainerApi } from '../../api/containers/putOneContainerApi';
import { putBulkContainersApi } from '../../api/containers/putBulkContainersApi';
import { getExportExcelApi } from '../../api/containers/getExportExcelApi';
import { getTerminalListApi } from '../../api/terminal/getTerminalList';
import { format } from 'date-fns';

// Запрос на получение списка всех контейнеров
export const getContainersList = createAsyncThunk(
	'allContainers/getContainersList',
	async (_, { rejectWithValue, getState }) => {
		try {
			const state = getState();

			const filter = () => {
				let filterArray = [`?page_size=30&page=${state.allContainers.filters.page}`];

				// По всем ключам filters и если есть фильтр добавляет в массив
				Object.keys(state.allContainers.filters).forEach((key) => {
					if (key !== 'page' && state.allContainers.filters[key] !== null) {
						filterArray.push(`&${key}=${state.allContainers.filters[key]}`);
					}
				});

				// Если ordering непустой то добавляем массив
				if (
					state.allContainers.ordering !== 'undefined' &&
					state.allContainers.ordering !== '-undefined' &&
					state.allContainers.ordering !== ''
				) {
					filterArray.push(`&ordering=${state.allContainers.ordering}`);
				}

				// Если globalSearch непустой то добавляем массив
				if (state.allContainers.globalSearch) {
					filterArray.push(`&search=${state.allContainers.globalSearch}`);
				}

				return filterArray.join('');
			};

			let response;

			// Infinite scroll
			if (state.allContainers.next && state.allContainers.fetching) {
				response = await getAllContainersList(state.allContainers.next);
			} else if (state.allContainers.url && state.allContainers.fetching) {
				response = await getAllContainersList(filter());
			}

			return { data: response.data, urlExport: filter() };
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

// Запрос на получение линий контейнеров
export const getLines = createAsyncThunk('allContainers/getLines', async (_, { rejectWithValue }) => {
	try {
		const response = await getLinesContainer();
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

// Редактирование одного контейнера
export const putOneContainer = createAsyncThunk(
	'allContainers/putOneContainer',
	async ({ terminal_pk, container_pk, requestData }, { rejectWithValue }) => {
		try {
			const response = await putOneContainerApi(terminal_pk, container_pk, requestData);
			return response.data;
		} catch (error) {
			return rejectWithValue({ error: error.message, containerId: container_pk });
		}
	},
);

// Редактирование нескольких контейнеров
export const putBulkContainers = createAsyncThunk(
	'allContainers/putBulkContainers',
	async ({ requestData }, { rejectWithValue }) => {
		try {
			const response = await putBulkContainersApi(requestData);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

// Экспорт таблицы в excel
export const getExportExcel = createAsyncThunk(
	'allContainers/getExportExcel',
	async (query, { rejectWithValue, getState }) => {
		try {
			const state = getState();
			let response;
			if (query === '') {
				response = await getExportExcelApi(
					query,
					state.allContainers.filterExport.replace(/\?page_size=\d+&page=\d+&?/, ''),
				);
			} else {
				response = await getExportExcelApi(
					query,
					state.allContainers.filterExport.replace(/\?page_size=\d+&page=\d+/, ''),
				);
			}
			return response;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

// Запрос на получение списка терминалов
export const getTerminalList = createAsyncThunk('allContainers/getTerminalList', async (_, { rejectWithValue }) => {
	try {
		const response = await getTerminalListApi();
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

const allContainersSlice = createSlice({
	name: 'allContainers',
	initialState: {
		// Список контейнеров
		containersList: [],
		getContainersList_success: false,
		getContainersList_pending: false,
		getContainersList_error: false,
		// Количество в списке
		count: 0,

		// Первая загрузка
		firstLoad: false,

		// Infinite scroll
		fetching: true,
		url: `?page_size=30`,
		next: null,

		// Сортировка, поиск, фильтрация
		ordering: '',
		globalSearch: '',
		filters: {
			page: 1,
			status: null,
			line: null,
			container_number: null,
			size: null,
			type: null,
			org: null,
			booking: null,
			is_filled: null,
			is_dangerous: null,
			is_damaged: null,
			terminal_id: null,
			order_in: null,
			entrypass_in_status: null,
			vehicle_in: null,
			driver_in: null,
			checkin_in_from: null,
			checkin_in_to: null,
			order_out: null,
			entrypass_out_status: null,
			vehicle_out: null,
			driver_out: null,
			checkout_in_from: null,
			checkout_in_to: null,
			report_available: null,
		},

		// Url фильтрации, сортировки и поиска для экспорта в excel
		filterExport: '',

		//Линии контейнеров
		lineList: [],
		getLines_success: false,
		getLines_pending: false,
		getLines_error: false,

		// Список терминалов
		terminalList: [],
		getTerminalList_success: false,
		getTerminalList_pending: false,
		getTerminalList_error: false,

		// Статусы для отправки запросов фильтрации
		allStatuses: {
			status: [
				{ id: 1, name: 'Ожидается' },
				{ id: 2, name: 'Принят' },
				{ id: 3, name: 'На ремонте' },
				{ id: 4, name: 'Ожидает вывоза' },
				{ id: 5, name: 'Вывезен' },
			],
			size: [
				{ id: 1, name: '20' },
				{ id: 2, name: '40' },
				{ id: 3, name: '45' },
			],
			type: [
				{ id: 1, name: 'DC' },
				{ id: 2, name: 'DV' },
				{ id: 3, name: 'HC' },
				{ id: 4, name: 'PW' },
				{ id: 5, name: 'OT' },
				{ id: 6, name: 'OT HC' },
				{ id: 7, name: 'RF' },
				{ id: 8, name: 'RF HC' },
				{ id: 9, name: 'FR' },
				{ id: 10, name: 'FR HC' },
				{ id: 11, name: 'TC' },
				{ id: 12, name: 'HR' },
			],

			entrypass_in_status: [
				{ id: 0, name: 'Пропуск создан' },
				{ id: 1, name: 'Пропуск подтвержден' },
				{ id: 2, name: 'Пропуск активирован' },
				{ id: 3, name: 'Пропуск отменён' },
				{ id: 4, name: 'Контейнер и пропуск удалены' },
			],

			entrypass_out_status: [
				{ id: 0, name: 'Пропуск создан' },
				{ id: 1, name: 'Пропуск подтвержден' },
				{ id: 2, name: 'Пропуск активирован' },
				{ id: 3, name: 'Пропуск отменён' },
				{ id: 4, name: 'Контейнер и пропуск удалены' },
			],
		},

		// Редактирование одного контейнера
		putOneContainer_success: false,
		putOneContainer_pending: false,
		putOneContainer_rejected: false,

		// Редактирование нескольких контейнеров
		putBulkContainers_success: false,
		putBulkContainers_pending: false,
		putBulkContainers_rejected: false,

		// Экспорт в excel
		getExportExcel_success: false,
		getExportExcel_pending: false,
		getExportExcel_error: false,
	},
	reducers: {
		setContainersFetching(state, action) {
			state.fetching = action.payload;
		},

		// Фильтрация
		setFilters(state, action) {
			console.log(action.payload, 'ap');
			state.next = null;
			state.containersList = [];
			state.url = '?page_size=30';

			Object.keys(state.filters).forEach((key) => {
				// Если ключ равен 'page', устанавливаем значение 1, иначе null
				state.filters[key] = key === 'page' ? 1 : null;
			});

			// Функция для поиска значений в массивах
			function getValueByKey(key, value) {
				const statuses = state.allStatuses[key];
				const status = statuses.find((status) => status.name === value);
				return status ? status.id : null;
			}

			// Формирование массива параметров запроса
			function paramsArray(array) {
				array.map((item) => {
					let paramValue;
					if (item.id === 'line') {
						state.filters.line = state.lineList?.find((el) => el.line_name == item.value).id;
					} else if (
						item.id === 'org' ||
						item.id === 'container_number' ||
						item.id === 'booking' ||
						item.id === 'order_in' ||
						item.id === 'vehicle_in' ||
						item.id === 'driver_in' ||
						item.id === 'order_out' ||
						item.id === 'vehicle_out' ||
						item.id === 'driver_out'
					) {
						state.filters[item.id] = item.value;
					} else if (item.id === 'is_filled' || item.id === 'is_damaged' || item.id === 'is_dangerous') {
						state.filters[item.id] = item.value;
					} else if (item.id === 'terminal') {
						state.filters['terminal_id'] = state.terminalList?.find((el) => el.name == item.value).id;
					} else if (item.id === 'checkin_at') {
						// Форматирование даты
						const formatDate = (date) => format(date.toISOString(), 'yyyy-MM-dd');
						state.filters['checkin_in_from'] = item.value[0]?.$d ? formatDate(item.value[0].$d) : null;
						state.filters['checkin_in_to'] = item.value[1]?.$d ? formatDate(item.value[1].$d) : null;
					} else if (item.id === 'checkout_at') {
						// Форматирование даты
						const formatDate = (date) => format(date.toISOString(), 'yyyy-MM-dd');
						state.filters['checkout_in_from'] = item.value[0]?.$d ? formatDate(item.value[0].$d) : null;
						state.filters['checkout_in_to'] = item.value[1]?.$d ? formatDate(item.value[1].$d) : null;
					} else if (item.id === 'report_available') {
						state.filters['report_available'] = action.payload[0].value;
					} else {
						state.filters[item.id] = getValueByKey(item.id, item.value);
					}

					return paramValue !== null ? paramValue : '';
				});
			}

			if (action.payload) {
				paramsArray(action.payload);
			}
		},

		// Сортировка
		setOrdering(state, action) {
			state.next = null;
			state.containersList = [];
			state.url = '?page_size=30';

			function changeSortingMore(value) {
				if (state.ordering === value) {
					state.ordering = '-' + value;
				} else {
					state.ordering = value;
				}
			}

			if (action.payload[0]?.id) {
				if (action.payload[0]?.id === 'line') {
					changeSortingMore('line__line_name');
				} else if (action.payload[0]?.id === 'org') {
					changeSortingMore('order_container__client__organization_name');
				} else {
					changeSortingMore(`${action.payload[0]?.id}`);
				}
			} else {
				changeSortingMore(`${action.payload[0]?.id}`);
			}
		},

		// Глобальный поиск
		setGlobalSearch(state, action) {
			state.next = null;
			state.containersList = [];
			state.url = '?page_size=30';
			state.globalSearch = '';

			if (action.payload) {
				state.globalSearch = action.payload;
			} else {
				state.globalSearch = '';
			}
		},
	},
	extraReducers: (builder) => {
		// Получение листа контейнеров
		builder
			.addCase(getContainersList.fulfilled, (state, action) => {
				state.getContainersList_success = true;
				state.getContainersList_pending = false;
				state.getContainersList_error = false;
				// Infinite scroll
				if (action.payload.data.next) {
					const startIndex = action.payload.data.next?.indexOf('?');
					const trimmedUrl = `${action.payload.data.next?.substring(startIndex)}`;
					state.next = trimmedUrl;
					state.url = '';
				} else {
					state.next = null;
					state.url = '';
				}
				state.fetching = false;
				state.containersList = [...state.containersList, ...action.payload.data.results];
				state.count = action.payload.data.count;
				state.firstLoad = true;
				state.filterExport = action.payload.urlExport;
			})
			.addCase(getContainersList.pending, (state, action) => {
				state.getContainersList_success = false;
				state.getContainersList_pending = true;
				state.getContainersList_error = false;
			})
			.addCase(getContainersList.rejected, (state, action) => {
				state.getContainersList_success = false;
				state.getContainersList_pending = false;
				state.getContainersList_error = action.payload;
			});
		// Получение линий
		builder
			.addCase(getLines.fulfilled, (state, action) => {
				state.lineList = action.payload;
				state.getLines_success = true;
				state.getLines_pending = false;
				state.getLines_rejected = false;
			})
			.addCase(getLines.pending, (state, action) => {
				state.getLines_success = false;
				state.getLines_pending = true;
				state.getLines_rejected = false;
			})
			.addCase(getLines.rejected, (state, action) => {
				state.getLines_success = false;
				state.getLines_pending = false;
				state.getLines_rejected = action.payload;
			});
		// Получение терминалов
		builder
			.addCase(getTerminalList.fulfilled, (state, action) => {
				state.terminalList = action.payload;
				state.getTerminalList_success = true;
				state.getTerminalList_pending = false;
				state.getTerminalList_error = false;
			})
			.addCase(getTerminalList.pending, (state, action) => {
				state.getTerminalList_success = false;
				state.getTerminalList_pending = true;
				state.getTerminalList_error = false;
			})
			.addCase(getTerminalList.rejected, (state, action) => {
				state.getTerminalList_success = false;
				state.getTerminalList_pending = false;
				state.getTerminalList_error = action.payload;
			});
		// Редактирование одного контейнера
		builder
			.addCase(putOneContainer.fulfilled, (state, action) => {
				state.putOneContainer_success = true;
				state.putOneContainer_pending = false;
				state.putOneContainer_rejected = false;

				// Обновить контейнер в списке containersList
				const updatedContainer = action.payload;
				const index = state.containersList.findIndex((container) => container.id === updatedContainer.id);
				if (index !== -1) {
					state.containersList[index] = { ...state.containersList[index], ...updatedContainer };
				}
			})
			.addCase(putOneContainer.pending, (state, action) => {
				state.putOneContainer_success = false;
				state.putOneContainer_pending = true;
				state.putOneContainer_rejected = false;
			})
			.addCase(putOneContainer.rejected, (state, action) => {
				state.putOneContainer_success = false;
				state.putOneContainer_pending = false;
				state.putOneContainer_rejected = action.payload.error;

				// Не менять значения контейнера в списке containersList
				const index = state.containersList.findIndex((container) => container.id === action.payload.containerId);
				if (index !== -1) {
					state.containersList[index] = { ...state.containersList[index] };
				}
			});
		// Редактирование нескольких контейнеров
		builder
			.addCase(putBulkContainers.fulfilled, (state, action) => {
				state.putBulkContainers_success = true;
				state.putBulkContainers_pending = false;
				state.putBulkContainers_rejected = false;

				// Обновление данных контейнеров после редактирования
				const updatedContainers = action.payload;

				updatedContainers.forEach((updatedContainer) => {
					const index = state.containersList.findIndex((container) => container.id === updatedContainer.id);
					if (index !== -1) {
						state.containersList[index] = { ...state.containersList[index], ...updatedContainer };
					}
				});
			})
			.addCase(putBulkContainers.pending, (state, action) => {
				state.putBulkContainers_success = false;
				state.putBulkContainers_pending = true;
				state.putBulkContainers_rejected = false;
			})
			.addCase(putBulkContainers.rejected, (state, action) => {
				state.putBulkContainers_success = false;
				state.putBulkContainers_pending = false;
				state.putBulkContainers_rejected = action.payload;
			});
		// Экспорт в excel
		builder
			.addCase(getExportExcel.fulfilled, (state, action) => {
				state.getExportExcel_success = true;
				state.getExportExcel_pending = false;
				state.getExportExcel_error = false;

				// Скачивание файла
				let blob = new Blob([action.payload.data], {
					type: 'excel/xlsx',
				});
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				// Форматирование даты для названия файла
				let formattedDate = new Date()
					.toLocaleString('ru-RU', {
						day: '2-digit',
						month: '2-digit',
						year: 'numeric',
						hour: '2-digit',
						minute: '2-digit',
					})
					.replace(',', '');
				link.download = `Сводная таблица_${formattedDate}.xlsx`;
				link.click();
			})
			.addCase(getExportExcel.pending, (state, action) => {
				state.getExportExcel_success = false;
				state.getExportExcel_pending = true;
				state.getExportExcel_error = false;
			})
			.addCase(getExportExcel.rejected, (state, action) => {
				state.getExportExcel_success = false;
				state.getExportExcel_pending = false;
				state.getExportExcel_error = action.payload;
			});
	},
});
export const { setContainersFetching, setFilters, setOrdering, setGlobalSearch } = allContainersSlice.actions;

export default allContainersSlice.reducer;
