import api from '../../api.js';

export const users = api.injectEndpoints({
	endpoints: (builder) => ({
		getEmplsList: builder.query({
			query: ({ page, page_size }) => {
				const params = { page, page_size };
				return {
					url: '/api/erp/users/?type=1',
					params,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetEmplsListQuery, useLazyGetEmplsListQuery } = users;
