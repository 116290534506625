import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { IconCalendar } from '../Icons';
import { useDispatch } from 'react-redux';
import './InputsStyle.scss';

//! Должны передавать
// label - текст над инпутом
// value - значение
// setValue - функция для изменения значения
// required - звездочка рядом с label

function InputCalendar({ label, setValue, value, required }) {
	const dispatch = useDispatch();
	return (
		<div className="inputCalendar">
			<div className="inputCalendarBlock">
				{required && value?.length === 0 ? <p className="input_required">*</p> : ''}
				<p>{label}</p>
			</div>
			<LocalizationProvider
				dateAdapter={AdapterDayjs}
				adapterLocale="ru"
				// Placeholder
				localeText={{
					fieldDayPlaceholder: () => '01',
					fieldMonthPlaceholder: () => '01',
					fieldYearPlaceholder: () => '2024',
				}}
			>
				<DesktopDatePicker
					format="DD.MM.YYYY"
					value={dayjs(value)}
					onChange={(newValue) => dispatch(setValue(newValue))}
					// Смена иконки
					slots={{
						openPickerIcon: IconCalendar,
					}}
					// Стилизация
					slotProps={{
						textField: {
							sx: {
								'> div': {
									'&.Mui-focused': {
										input: {
											borderColor: '#FF8E16',
										},
									},

									'&.MuiInputBase-colorPrimary': {
										input: {
											borderColor: '#443A8E',
											backgroundColor: '#E4DFFF',
											color: '#2D2276',
											fontFamily: 'Montserrat',
											fontSize: '16px',
											fontWeight: '400',
											lineHeight: '20px',
											letterSpacing: '0.035px',
										},
									},

									'&.MuiInputBase-colorPrimary.Mui-error': {
										input: {
											backgroundColor: '#fffbff',
											borderColor: '#756CC2',
											color: '#8E8E93',
										},
									},
								},

								input: {
									borderRadius: '8px',
									borderStyle: 'solid',
									borderWidth: '1px',
									padding: '8px 12px',
								},

								fieldset: {
									border: 'none',
								},

								button: {
									backgroundColor: '#5C53A7 !important',
									borderRadius: '6px',
									padding: '6px',
								},

								svg: {
									fill: '#FFFBFF',
									width: '16px',
									height: '16px',

									path: {
										fill: '#FFFBFF',
									},
								},
							},
						},
					}}
				/>
			</LocalizationProvider>
		</div>
	);
}

export default InputCalendar;
