import styles from "./Step.module.scss";
import { Form, Notification, UniversalButton } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { postCreateClient, setInitialCreateClientData } from "../../../../../store/slices/registrationSlice";
import MainInput from "../../../../../components/Inputs/MainInput/MainInput";

function Step5({ setPage }) {
  const dispatch = useDispatch();
  const { createClient_success, createClient_error } = useSelector((state) => state.registration)

  const [isActive, setIsActive] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [notText, setNotText] = useState("");
  const [notDescr, setNotDescr] = useState("");
  const [notStatus, setNotStatus] = useState("");

  const addNot = (text, descr, status) => {
    setNotText(text);
    setNotDescr(descr);
    setNotStatus(status);
    setIsActive(true);
  };

  const onClose = () => {
    setIsActive(false);
  };

  const onSubmit = async (data) => {
    if (data.password === data.repeatPassword) {
      localStorage.setItem("password", data.password)
      let requestData;
      requestData = {
        email: localStorage.getItem("email"),
        password: data.password,
        contract_number: localStorage.getItem("numberContract"),
        corporate_name: localStorage.getItem("urName"),
        corporate_address: localStorage.getItem("urAdress"),
      };
      await dispatch(postCreateClient({ requestData }));
      dispatch(setInitialCreateClientData())
    } else {
      addNot('Ошибка', 'Пароли не совпадают', 'error');
    }
  };

  useEffect(() => {
    if (createClient_success) {
      setPage((currentPage) => currentPage + 1);
    }
    else if (createClient_error) {
      addNot("Ошибка", "Попробуйте снова", "error");
    }
  }, [createClient_success, createClient_error])

  return (
    <>
      <Notification text={notText}
        descr={notDescr}
        onClose={onClose}
        isActive={isActive}
        isStatus={notStatus}
      />
      <div className={styles.block}>
        <h5>Новый клиент</h5>
        <Form onSubmit={onSubmit}>
          <MainInput
            title={'Пароль'}
            errorTitle={'Некорректный формат пароля'}
            name="password"
            value={password}
            setValue={setPassword}
            rules={{
              required: "Обязательное поле",
              minLength: {
                value: 6,
                message: "Минимум 6 символов",
              },
              pattern: {
                value: /^[^\sа-яА-Я]{6,}$/,
                message: "Латинские символы",
              },
            }}
            placeholder={'Введите пароль'}
          />
          <MainInput
            title={'Повторите пароль'}
            errorTitle={'Некорректный формат пароля'}
            name="repeatPassword"
            value={repeatPassword}
            setValue={setRepeatPassword}
            rules={{
              required: "Обязательное поле",
              minLength: {
                value: 6,
                message: "Минимум 6 символов",
              },
              pattern: {
                value: /^[^\sа-яА-Я]{6,}$/,
                message: "Латинские символы",
              },
            }}
            placeholder={'Введите пароль'}
          />
          <div className={styles.block__btns}>
            <UniversalButton label={"Продолжить"} color={"primary"} size={"large"} typeButton={"submit"} />
          </div>
        </Form>
      </div></>
  );
}

export default Step5;
