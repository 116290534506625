import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconSideFloor({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8.57143 16.5714H12H15.4286M7.71429 4H16.2857C17.2325 4 18 5.02335 18 6.28571V17.7143C18 18.9767 17.2325 20 16.2857 20H7.71429C6.76751 20 6 18.9767 6 17.7143V6.28571C6 5.02335 6.76751 4 7.71429 4Z"
					stroke="#8E8E93"
					strokeWidth="1.71429"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}
export default IconSideFloor;
