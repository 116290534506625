import { useState } from 'react';
import { Notification } from '../../components';
import styles from './Landing.module.scss';
import Benefits from './components/Benefits/Benefits';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import HeaderLanding from './components/HeaderLanding/HeaderLanding';
import Hero from './components/Hero/Hero';
import Terminals from './components/Terminals/Terminals.jsx';
import Advantages from './components/Advantages/Advantages';

function Landing() {
	const [isActive, setIsActive] = useState(false);
	const [text, setText] = useState('');
	const [descr, setDescr] = useState('');
	const [status, setStatus] = useState('');

	const onClose = () => {
		setIsActive(false);
	};

	return (
		<>
			<div className={styles.landing__notification}>
				<Notification text={text} descr={descr} onClose={onClose} isActive={isActive} isStatus={status} />
			</div>

			<div className={styles.landingBg}>
				<HeaderLanding />

				<div className={styles.landing__container} id="main">
					<div className={styles.landing__col}>
						<div className={styles.landing__row}>
							<Hero />
							<ContactUs setIsActive={setIsActive} setText={setText} setDescr={setDescr} setStatus={setStatus} />
						</div>
						<Terminals />
						<Advantages />
					</div>
				</div>
			</div>

			<div className={styles.landing__container}>
				<Benefits />
			</div>

			<div className={styles.footerBg} id="contacts">
				<div className={styles.landing__container}>
					<Footer />
				</div>
			</div>
		</>
	);
}

export default Landing;
