import styles from '../AccountClient.module.scss';
import Header from '../components/Header/Header';
import TableContainersClient from '../components/Tables/TableContainersClient';

function AccountClientContainers() {
	return (
		<div className={styles.account__wrap}>
			<Header text={'Сводная таблица'} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<TableContainersClient />
				</div>
			</div>
		</div>
	);
}

export default AccountClientContainers;
