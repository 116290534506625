import React, { useState, useEffect } from "react";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import Step6 from "./components/Step6";

import { IconArrowLeft } from "../../../../components/Icons";
import { CounterStep, Header, UniversalButton } from "../../../../components";
import styles from "./RegClient.module.scss";
import Congrate from "./components/Congrate";
import { useNavigate } from "react-router-dom"
import ContactManager from "./components/ContactManager";

function RegClient() {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  localStorage.removeItem("authClientStep");
  localStorage.removeItem("adminRegStep");
  localStorage.removeItem("manRegClientStep");

  useEffect(() => {
    const savedStep = localStorage.getItem("regClientStep");
    if (savedStep) {
      setPage(parseInt(savedStep));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("regClientStep", page.toString());
  }, [page]);


  const handleClickBack = () => {
    if (page > 0 && page < 7) {
      setPage(page - 1);
    } else if (page === 0) {
      navigate("/")
    } else if (page === 7) {
      if (localStorage.getItem("congrate")) {
        setPage(6);
      } else {
        setPage(0);
      }

    }
  };

  const pageDisplay = () => {
    if (page === 0) {
      return <Step1 setPage={setPage} />;
    } else if (page === 1) {
      return <Step2 setPage={setPage} />;
    } else if (page === 2) {
      return <Step3 setPage={setPage} />;
    } else if (page === 3) {
      return <Step4 setPage={setPage} />;
    } else if (page === 4) {
      return <Step5 setPage={setPage} />;
    } else if (page === 5) {
      return <Step6 setPage={setPage} />;
    } else if (page === 6) {
      return <Congrate setPage={setPage} />;
    } else if (page === 7) {
      return <ContactManager setPage={setPage} />;
    }
  };

  return (
    <div className="backImg">
      <Header />
      <div className="container-center">
        <div className={styles.navBar}>
          {page !== 6 ? (
            <UniversalButton icon={<IconArrowLeft size={"largeS"} color={"black"} />} onClick={handleClickBack} size={"large"} typeButton={"button"} />
          ) : ""}

          {page > 0 && page < 6 ? (
            <CounterStep step={page} allStep={"5"} />
          ) : (
            ""
          )}
        </div>
        {pageDisplay()}
      </div>
    </div>
  );
}

export default RegClient;
