export function BtnBg(bg) {
  let result =
    bg === 'blackBg'
      ? 'blackBg'
      : bg === 'whiteBg'
        ? 'whiteBg'
        : bg === 'violetBg'
          ? 'violetBg'
          : bg === 'blueBg'
            ? 'blueBg'
            : bg === 'delete'
              ? 'delete'
              : bg === 'noneBg'
                ? 'noneBg'
                : bg === 'refresh'
                  ? 'refresh'
                  : bg === 'noneBg'
                    ? 'noneBg'
                    : bg === 'blueBorder'
                      ? 'blueBorder'
                    : bg === 'noneBgSect'
                      ? 'noneBgSect'
                      : bg === 'green'
                        ? 'green' 
                        : '';
  return result;
}

export function BtnSize(size) {
  let result =
    size === 'p-6'
      ? 'p-6'
      : size === 'p-8'
        ? 'p-8'
        : size === 'p-12'
          ? 'p-12'
          : size === 'p-16'
            ? 'p-16'
            : '';
  return result;
} 