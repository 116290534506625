import MainInput from "../../../../../components/Inputs/MainInput/MainInput";
import styles from "./Step.module.scss";
import { Form, UniversalButton } from "../../../../../components";
import { useState } from "react";

function Step5({ setPage }) {

  const onSubmit = (data) => {
    localStorage.setItem("urAdress", data.urAdress);
    setPage((currentPage) => currentPage + 1);
  };


  const [corAdress, setCorAdress] = useState(localStorage.getItem("urAdress") || "")

  return (
    <div className={styles.block}>
      <h5>Регистрация</h5>
      <Form onSubmit={onSubmit}>
        <MainInput
          title={'Юридический адрес'}
          errorTitle={'Некорректный адрес'}
          name="urAdress"
          value={corAdress}
          setValue={setCorAdress}
          rules={{
            required: "Обязательное поле",
            minLength: {
              value: 5,
              message: "Минимум 5 символов",
            },
          }}
          placeholder={'г. Москва, ул. Ленина, д.43, к.1'}
        />
        <div className={styles.block__btns}>
          <UniversalButton label={"Продолжить"} color={"primary"} size={"large"} typeButton={"submit"} />
        </div>
      </Form>
    </div>
  );
}

export default Step5;
