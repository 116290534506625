import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconDamageEmpty({ onClick, size, color }) {
	return (
		<div className={styles.icon} onClick={onClick}>
			{/* <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 16 16" fill="none">
				<path
					d="M12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645C12.1583 3.45118 11.8417 3.45118 11.6464 3.64645L12.3536 4.35355ZM3.64645 11.6464C3.45118 11.8417 3.45118 12.1583 3.64645 12.3536C3.84171 12.5488 4.15829 12.5488 4.35355 12.3536L3.64645 11.6464ZM4.35355 3.64645C4.15829 3.45118 3.84171 3.45118 3.64645 3.64645C3.45118 3.84171 3.45118 4.15829 3.64645 4.35355L4.35355 3.64645ZM11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L11.6464 12.3536ZM11.6464 3.64645L7.64645 7.64645L8.35355 8.35355L12.3536 4.35355L11.6464 3.64645ZM7.64645 7.64645L3.64645 11.6464L4.35355 12.3536L8.35355 8.35355L7.64645 7.64645ZM3.64645 4.35355L7.64645 8.35355L8.35355 7.64645L4.35355 3.64645L3.64645 4.35355ZM7.64645 8.35355L11.6464 12.3536L12.3536 11.6464L8.35355 7.64645L7.64645 8.35355Z"
					fill={IconColor(color)}
				/>
			</svg> */}
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M5.77778 4C5.30628 4 4.8541 4.1873 4.5207 4.5207C4.1873 4.8541 4 5.30628 4 5.77778M9.33333 4H10.2222M13.7778 4H14.6667M18.2222 4C18.6937 4 19.1459 4.1873 19.4793 4.5207C19.8127 4.8541 20 5.30628 20 5.77778M20 9.33333V10.2222M20 13.7778V14.6667M20 18.2222C20 18.6937 19.8127 19.1459 19.4793 19.4793C19.1459 19.8127 18.6937 20 18.2222 20M13.7778 20H14.6667M9.33333 20H10.2222M5.77778 20C5.30628 20 4.8541 19.8127 4.5207 19.4793C4.1873 19.1459 4 18.6937 4 18.2222M4 13.7778V14.6667M4 9.33333V10.2222"
					stroke="#8E8E93"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	);
}
export default IconDamageEmpty;
