import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useLazyGetRepairTasksQuery } from '../../../../store/newApi/service/service';
import { Button } from '@mui/material';
import Filter from '../../../../newComponents/Filter/Filter';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/repairTasks.js';
import { useDispatch } from 'react-redux';

function AccountEmplTableRepairTasks() {
	const dispatch = useDispatch();
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});

	// колонки
	const columnsList = useMemo(
		() => [
			{
				accessorFn: (row) => row.terminal,
				id: 'terminal',
				header: 'Терминал',
				size: 150,
			},
			{
				accessorFn: (row) => row.name,
				id: 'name',
				header: 'Название',
				size: 240,
			},

			{
				accessorFn: (row) => row.repair_component,
				id: 'repair_component',
				header: 'Компонент',
				size: 280,
			},
			{
				accessorFn: (row) => row.unit,
				id: 'unit',
				header: 'Единица',
				size: 150,
			},
			{
				accessorFn: (row) => row.size_step,
				id: 'size_step',
				header: 'Размер шага',
				size: 200,
			},
			{
				accessorFn: (row) => row.hour_first_step,
				id: 'hour_first_step',
				header: 'Нормочасы за первый шаг, мин',
				size: 320,
			},
			{
				accessorFn: (row) => row.material_first_step,
				id: 'material_first_step',
				header: 'Материалы за первый шаг, $',
				size: 310,
			},

			{
				accessorFn: (row) => row.size_next_step,
				id: 'size_next_step',
				header: 'Размер для след. шага',
				size: 280,
			},
			{
				accessorFn: (row) => row.hour_next_step,
				id: 'hour_next_step',
				header: 'Нормочасы за след. шаг, мин',
				size: 320,
			},
			{
				accessorFn: (row) => row.material_next_step,
				id: 'material_next_step',
				header: 'Материалы за след. шаг, $',
				size: 310,
			},
		],
		[],
	);

	// порядок колонок
	const columnOrdering = [
		'mrt-row-select',
		'terminal',
		'name',
		'repair_component',
		'unit',
		'size_step',
		'hour_first_step',
		'material_first_step',
		'size_next_step',
		'hour_next_step',
		'material_next_step',
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Тарифы на ремонт'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium">
						Добавить новую
					</Button>,
					<Button variant="contained" color="primary" size="medium">
						Экспортировать
					</Button>,
				]}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<Filter onSetFilter={handleSetFilter} onResetFilter={handleResetFilter} />
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						hookName={useLazyGetRepairTasksQuery}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="repairTasksRtk"
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableRepairTasks;
