import React, { useState } from "react";
import styles from "./Step.module.scss";
import { Notification, UniversalButton } from "../../../../../components";
import { useNavigate } from "react-router-dom";

function Congrate({ setPage }) {
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();
  localStorage.clear()
  const onClose = () => {
    setIsActive(false);
  };

  const handlerLinkPА = () => {
    navigate("/authClient")
  }

  const handlerContactManager = () => {
    localStorage.setItem("congrate", "congrate");
    setPage(7)
  }

  return (
    <>
      <Notification
        text={"Ваш аккаунт успешно создан"}
        descr={"Подождите пока менеджер активирует его"}
        onClose={onClose}
        isActive={isActive}
        isStatus={"success"}
      />
      <div className={styles.block}>
        <div className={styles.block__descr}>
          <h5>Поздравляем!</h5>
          <p>
            Вы успешно прошли регистрацию. Осталось подождать, пока менеджер
            активирует ваш аккаунт
          </p>
        </div>
        <div className={styles.block__choice}>
          <UniversalButton label={"Войти"} color={"primary"} onClick={handlerLinkPА} size={"large"} typeButton={"button"} />
          <UniversalButton label={"Связаться с менеджером"} color={"primary"} onClick={handlerContactManager} size={"large"} typeButton={"button"} type={"with-border"} />
        </div>
      </div>
    </>
  );
}

export default Congrate;
