import { Outlet } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import styles from './Layout.module.scss';
import MenuDesktop from './Menu/MenuDesktop/MenuDesktop';
import MenuMobile from './Menu/MenuMobile/MenuMobile';
import SideBarModal from '../../../components/Modal/SideBarModal';

function Layout() {

    // отслеживание ширины окна
    const isDesktop = useMediaQuery({ minWidth: 1279.9 });

    return (
        <div className={styles.layout}>
            {/* меню декстоп версии */}
            {isDesktop ? (
                <div className={styles.layout__aside}>
                    <MenuDesktop />
                </div>
            ) : ""}

            {/* контент */}
            <Outlet />

            {/* меню моб версии */}
            {isDesktop ? "" : (
                <>
                    <MenuMobile />
                </>
            )}

            {/* модальное окно */}
            <SideBarModal />
        </div>
    );
}

export default Layout;
