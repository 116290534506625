import React, { useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const CheckBoxGroup = ({ name, title, elements, state, onChange }) => {
	// Состояние для управления видимостью содержимого
	const [isOpen, setIsOpen] = useState(false);
	// Функция для переключения видимости содержимого
	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};
	if (!state) return;

	const handleChange = (e) => {
		const value = e.target.id;

		let newState = [];
		if (state.includes(value)) newState = state.filter((item) => item !== value);
		else newState = [...state, value];

		onChange(name, newState);
	};

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography variant="alertTitle" style={{ flexGrow: 1 }}>
					{title}
				</Typography>
				<IconButton onClick={toggleOpen}>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
			</div>
			{isOpen && (
				<FormGroup sx={{ marginLeft: 1 }}>
					{elements.map((item) => (
						<FormControlLabel
							key={item.value}
							label={item.label}
							sx={{
								'.MuiTypography-root': {
									fontSize: '14px',
									lineHeight: '20px',
									letterSpacing: '0.035px',
								},
							}}
							control={
								<Checkbox
									id={item.value.toString()}
									checked={state.includes(item.value.toString())}
									onChange={(e) => handleChange(e)}
									sx={{
										width: '24px',
										height: '24px',
										padding: '20px',
										color: '#5C53A7',
										'&.Mui-checked': {
											color: '#5C53A7',
										},
									}}
								/>
							}
						/>
					))}
				</FormGroup>
			)}
		</>
	);
};

export default CheckBoxGroup;
