import { useEffect, useState } from 'react';
import styles from './ContactUs.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { contactUs } from '../../../../store/slices/publicFormSlice';
import { setInitialContactUsStatus } from '../../../../store/slices/publicFormSlice';
import { UniversalButton } from '../../../../components';
// import { contactUs } from "../../../../store/actions/publicForm"

function ContactUs({ setIsActive, setText, setDescr, setStatus }) {
	const dispatch = useDispatch();
	const { contactUs_success, contactUs_error } = useSelector((state) => state.publicForm);
	const initialFormState = {
		name: '',
		contact: '',
		message: '',
	};
	const [form, setForm] = useState(initialFormState);

	const formChangeHandler = (name, value) => {
		setForm((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setForm(initialFormState);
		await dispatch(contactUs({ requestData: form }));
	};

	useEffect(() => {
		if (contactUs_success) {
			setIsActive(true);
			setText('Заявка отправлена');
			setDescr('Мы свяжемся с вами в ближайшее время');
			setStatus('success');
		} else if (contactUs_error) {
			setIsActive(true);
			setText('Заявка не отправлена');
			setDescr('Произошла ошибка, попробуйте еще раз');
			setStatus('error');
		}

		dispatch(setInitialContactUsStatus());
	}, [contactUs_error, contactUs_success]);

	return (
		<div className={styles.contactUs}>
			<form onSubmit={onSubmit} className={styles.contactUs__form}>
				<h5 className={styles.contactUs__title}>Свяжитесь с нами</h5>
				<div className={styles.contactUs__input}>
					<label htmlFor="name">Ваше имя</label>
					<input
						required
						minLength="5"
						name="name"
						placeholder="Как к вам обращаться?"
						value={form.name}
						onChange={(e) => formChangeHandler('name', e.target.value)}
					/>
				</div>

				<div className={styles.contactUs__input}>
					<label htmlFor="contact">Эл. почта или телефон</label>
					<input
						required
						name="contact"
						minLength="11"
						placeholder="Как с вами связаться?"
						value={form.contact}
						onChange={(e) => formChangeHandler('contact', e.target.value)}
					/>
				</div>

				<div className={styles.contactUs__input}>
					<label htmlFor="message">Запрос</label>
					<input
						required
						name="message"
						placeholder="Опишите свой запрос"
						value={form.message}
						onChange={(e) => formChangeHandler('message', e.target.value)}
					/>
				</div>

				<div className={styles.contactUs__btn}>
					<UniversalButton label={'Отправить'} color={'primary'} size={'large'} typeButton={'submit'} />
					<p>
						Нажимая “Отправить”, Вы даете согласие на обработку персональных данных и соглашаетесь с условиями политики
						конфиденциальности
					</p>
				</div>
			</form>
		</div>
	);
}

export default ContactUs;
