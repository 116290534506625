import styles from './Select.module.scss';

function Select({
  name,
  options,
  onCloseModal,
  selectedOption,
  setSelectedOption,
  setSelectedId,
  oneUser,
  setInputChange,
  setValue,
}) {
  const handleSelection = (option, optionId) => {
    setValue(name, option);
    setSelectedOption(option);
    setSelectedId(optionId);
    if (oneUser?.group !== option && setInputChange) {
      setInputChange(true)
  } else if (setInputChange) {
      setInputChange(false)
  }
  };
  return (
    <div className={styles.optionSelect}>
      {options.map((el, index) => (
        <div
          key={index}
          className={`${styles.option} ${
            selectedOption === el.name
              ? styles.selectedOption
              : styles.unselectedOption
          }`}
          onClick={() => {
            handleSelection(el.name, el.id);
            onCloseModal();
          }}
        >
          <p className={styles.optionText}>{el.name}</p>
          <div className={styles.selectionIndicator}>
            {selectedOption === el.name ? (
              <div className={styles.indicatorActive}>
                <div className={styles.indicatorCheck}></div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Select;
