import { createTheme } from '@mui/material/styles';
import { primary, secondary, error, warning, successful, gray, dark } from './colors';
import breakpointsTheme from './breakpoints';

const darkTheme = createTheme(breakpointsTheme, {
	palette: {
		mode: 'dark',

		background: {
			header: secondary[10],
			table: secondary[20],
		},

		text: {
			primary: dark[100],
			secondary: dark[0],
			successful: successful[40],
			warning: warning[60],
			error: error[40],
			blue: primary[40],
		},

		border: {
			primary: primary[40],
			primaryHover: primary[30],
			primaryFocus: primary[20],
			primaryDisabled: dark[60],

			accent: warning[40],
			accentHover: warning[30],
		},
	},

	typography: {
		fontFamily: 'Montserrat',

		h1: {
			fontFamily: 'Montserrat',
			fontWeight: '700',
			fontSize: '56px',
			lineHeight: '64px',
		},

		h2: {
			fontFamily: 'Montserrat',
			fontWeight: '700',
			fontSize: '48px',
			lineHeight: '56px',
		},

		h3: {
			fontFamily: 'Montserrat',
			fontWeight: '700',
			fontSize: '40px',
			lineHeight: '48px',
			letterSpacing: '0.06px',
		},

		h4: {
			fontFamily: 'Montserrat',
			fontWeight: '700',
			fontSize: '36px',
			lineHeight: '44px',
			letterSpacing: '0.05px',
		},

		h5: {
			fontFamily: 'Montserrat',
			fontWeight: '700',
			fontSize: '32px',
			lineHeight: '40px',
			letterSpacing: '0.05px',
		},

		subtitle1: {
			fontFamily: 'Montserrat',
			fontWeight: '500',
			fontSize: '28px',
			lineHeight: '36px',
			letterSpacing: '0.03px',
		},

		subtitle2: {
			fontFamily: 'Montserrat',
			fontWeight: '500',
			fontSize: '24px',
			lineHeight: '32px',
			letterSpacing: '0.02px',
		},

		body1: {
			fontFamily: 'Montserrat',
			fontWeight: '400',
			fontSize: '18px',
			lineHeight: '28px',
			letterSpacing: '0.08px',
		},

		body2: {
			fontFamily: 'Montserrat',
			fontWeight: '400',
			fontSize: '16px',
			lineHeight: '24px',
			letterSpacing: '0.04px',
		},

		caption1: {
			fontFamily: 'Montserrat',
			fontWeight: '400',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.06px',
		},

		caption2: {
			fontFamily: 'Montserrat',
			fontWeight: '400',
			fontSize: '12px',
			lineHeight: '20px',
			letterSpacing: '-0.24px',
		},

		btnLarge: {
			fontFamily: 'Montserrat',
			fontWeight: '500',
			fontSize: '20px',
			lineHeight: '28px',
			letterSpacing: '0.08px',
		},

		btnMedium: {
			fontFamily: 'Montserrat',
			fontWeight: '500',
			fontSize: '16px',
			lineHeight: '24px',
			letterSpacing: '0.08px',
		},

		btnSmall: {
			fontFamily: 'Montserrat',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.08px',
		},

		btnExtraSmall: {
			fontFamily: 'Montserrat',
			fontWeight: '500',
			fontSize: '12px',
			lineHeight: '16px',
			letterSpacing: '0.08px',
		},

		alertTitle: {
			fontFamily: 'Montserrat',
			fontWeight: '600',
			fontSize: '16px',
			lineHeight: '24px',
			letterSpacing: '0.06px',
		},

		alertDescription: {
			fontFamily: 'Montserrat',
			fontWeight: '600',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '-0.24px',
		},
	},

	components: {
		MuiButton: {
			defaultProps: {
				disableRipple: true, // эффект волны при нажатии на кнопки
			},
			styleOverrides: {
				// общие стили
				root: {
					padding: '8px',
					fontFamily: 'Montserrat',
					boxShadow: 'none',
					border: '1px solid',
					textTransform: 'none',
					fontWeight: '500',
					minWidth: 'auto',

					'&:hover': {
						boxShadow: 'none',
					},

					'&:active': {
						outline: 'none',
						boxShadow: 'none',
					},

					'&.Mui-disabled': {
						boxShadow: 'none',
					},
				},

				// размеры
				sizeSmall: {
					borderRadius: '6px',
					fontSize: '12px',
					lineHeight: '16px',
					letterSpacing: '0.08px',
					height: '32px',
				},
				sizeMedium: {
					borderRadius: '8px',
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '0.08px',
					height: '36px',
				},
				sizeLarge: {
					borderRadius: '8px',
					fontSize: '16px',
					lineHeight: '24px',
					letterSpacing: '0.08px',
					height: '44px',
				},

				// варианты
				containedPrimary: {
					backgroundColor: primary[40],
					color: dark[100],
					borderColor: primary[40],

					'&:hover': {
						backgroundColor: primary[30],
						borderColor: primary[30],
					},

					'&:active': {
						outline: 'none',
						backgroundColor: primary[20],
						borderColor: primary[20],
					},

					'&.Mui-disabled': {
						backgroundColor: '#8E8E93',
						color: '#ffffff',
						border: '1px solid #8E8E93',
					},

					'&.selected': {
						backgroundColor: '#FF8E16',
						color: '#ffffff',
						border: '1px solid #FF8E16',

						'&:hover': {
							backgroundColor: '#FF8E16',
							color: '#ffffff',
							border: '1px solid #FF8E16',
						},

						'&:active': {
							outline: 'none',
							backgroundColor: '#FF8E16',
							color: '#ffffff',
							border: '1px solid #FF8E16',
						},
					},

					'&.actived': {
						backgroundColor: primary[20],
						borderColor: primary[20],
					},
				},
				outlinedPrimary: {
					backgroundColor: dark[100],
					color: primary[40],
					borderColor: primary[40],

					'&:hover': {
						backgroundColor: dark[100],
						color: primary[30],
						borderColor: primary[30],
					},

					'&:active': {
						outline: 'none',
						backgroundColor: dark[100],
						color: primary[20],
						borderColor: primary[20],
					},

					'&.Mui-disabled': {
						backgroundColor: '#ffffff',
						color: '#8E8E93',
						border: '1px solid #8E8E93',
					},

					'&.selected': {
						backgroundColor: '#ffffff',
						color: '#FF8E16',
						border: '1px solid #FF8E16',

						'&:hover': {
							backgroundColor: '#ffffff',
							color: '#FF8E16',
							border: '1px solid #FF8E16',
						},

						'&:active': {
							outline: 'none',
							backgroundColor: '#ffffff',
							color: '#FF8E16',
							border: '1px solid #FF8E16',
						},
					},
				},
			},
		},
	},
});

export default darkTheme;
