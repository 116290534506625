import { useEffect, useState } from "react";
import "../../../../../../assets/styles/Tables.scss";
import { Notification, Switcher, UniversalButton } from "../../../../../../components";
import ModalEditSelect from "./ModalEditSelect";
import { useDispatch } from 'react-redux';
import { putContainersAction } from "../../../../../../store/actions/clientContainer";

function ModalEditContainers({ setData, handleCloseModal, selectContainers, allLines, allSizes, allTypes }) {
    const dispatch = useDispatch();
    // объекты со значениями выбранных контейнеров

    const [lineArray, setLineArray] = useState();
    const [sizeArray, setSizeArray] = useState();
    const [typeArray, setTypeArray] = useState();
    const [damage, setDamage] = useState();
    const [dangerous, setDangerous] = useState();

    // уведомление 
    const onClose = () => {
        setIsActive(false);
    };

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setLineArray(selectContainers?.map((elem, index) => elem.line));
        setSizeArray(selectContainers?.map((elem, index) => elem.size));
        setTypeArray(selectContainers?.map((elem, index) => elem.type));
        setDamage(selectContainers?.map((elem, index) => elem.is_filled));
        setDangerous(selectContainers?.map((elem, index) => elem.is_dangerous));
    }, [selectContainers]);
    // отправка редактирования контейнеров на сервер 

    const [dataContainer, setDataContainer] = useState({});
    let dataContainerView = {};

    const handleSelectedOptionChangeType = (selectedOption) => {
        if (selectedOption === "Разное" || selectedOption === "") {
        } else {
            if (typeArray.every(element => element === String(selectedOption))) {
                delete dataContainer.type
            } else {
                dataContainer["type"] = allTypes.find(item => item.name == selectedOption)?.id;
                dataContainerView["type"] = selectedOption;
            }

        }
    };

    const handleSelectedOptionChangeLine = (selectedOption) => {
        if (selectedOption === "Разное" || selectedOption === "") {
        } else {
            if (lineArray.every(element => element === String(selectedOption))) {
                delete dataContainer.line
            } else {
                if (Array.isArray(allLines.lines)) {
                    dataContainer["line"] = allLines.lines.find(item => item.line_name == selectedOption)?.slug;
                    dataContainerView["line"] = selectedOption;
                }
            }
        }
    };

    const handleSelectedOptionChangeSize = (selectedOption) => {
        if (selectedOption === "Разное" || selectedOption === "") {
        } else {
            if (sizeArray.every(element => element === String(selectedOption))) {
                delete dataContainer.size
            } else {
                dataContainer["size"] = allSizes.find(item => item.name == selectedOption)?.id;
                dataContainerView["size"] = selectedOption;
            }
        }

    };

    // переключатели
    const [isCheckedDamage, setCheckedDamage] = useState(false);
    const [isCheckedDangerous, setCheckedDangerous] = useState(false);

    const handleCheckedDamage = (newChecked) => {
        dataContainer["is_filled"] = newChecked;
        dataContainerView["is_filled"] = newChecked;
        setCheckedDamage(newChecked);
    };

    const handleCheckedDangerous = (newChecked) => {
        dataContainer["is_dangerous"] = newChecked;
        dataContainerView["is_dangerous"] = newChecked;
        setCheckedDangerous(newChecked);
    };

    // обновление данных
    const updateDataByIds = (ids, updatedValues) => {
        setData((prevData) => {
            const newData = [...prevData];
            ids.forEach((elem) => {
                for (let i = 0; i < newData.length; i++) {
                    if (newData[i].id == elem) {
                        newData[i] = { ...newData[i], ...updatedValues };
                    }
                }
            });
            return newData;
        });
    };

    const handleSaveContainers = async () => {
        try {
            let container_ids = selectContainers.map(function (item) {
                return item.id;
            });
            dataContainer["container_ids"] = container_ids;
            if (Object.keys(dataContainer)?.length > 1) {
                await dispatch(putContainersAction(dataContainer));
                updateDataByIds(container_ids, dataContainerView)
                handleCloseModal()
            } else {
                handleCloseModal()
            }

        } catch (error) {
            setIsActive(true)
        }
    };


    return (
        <div className="modalEditСontainers">
            <Notification text={"Ошибка"}
                descr={"Попробуйте еще раз"}
                onClose={onClose}
                isActive={isActive}
                isStatus={"error"} />
            <div className="modalEditСontainers__container">
                <div>
                    <div className="modalEditСontainers__header">
                        Контейнеры
                        <div>
                            {selectContainers?.map((elem, index) => (
                                <p key={index}>№{elem.container_number}</p>
                            ))}
                        </div>
                    </div>
                    <div className="modalEditСontainers__body">
                        <form>
                            <div className="w-100">
                                {lineArray && (
                                    <ModalEditSelect
                                        name={"lines"}
                                        label={"Линия"}
                                        options={allLines.lines}
                                        values={lineArray}
                                        elementName={"line_name"}
                                        onSelectedOptionChange={handleSelectedOptionChangeLine}
                                    />
                                )}
                            </div>
                            {sizeArray && (
                                <ModalEditSelect
                                    name={"sizes"}
                                    label={"Размер"}
                                    options={allSizes}
                                    values={sizeArray}
                                    onSelectedOptionChange={handleSelectedOptionChangeSize}
                                />
                            )}
                            {typeArray && (
                                <ModalEditSelect
                                    name={"types"}
                                    label={"Тип"}
                                    options={allTypes}
                                    values={typeArray}
                                    onSelectedOptionChange={handleSelectedOptionChangeType}
                                />
                            )}
                            <div className="modalEdit__body_switchers">
                                <div className="switchers__item">
                                    Поврежден
                                    <Switcher onChecked={handleCheckedDamage} externalChecked={damage?.every(el => el === damage[0]) ? damage[0] : false} />
                                </div>
                                <div className="switchers__item">
                                    Опасный
                                    <Switcher onChecked={handleCheckedDangerous} externalChecked={dangerous?.every(el => el === dangerous[0]) ? dangerous[0] : false} />
                                </div>
                            </div>
                        </form>
                        {/* <div className="modalEdit__body_orders">
                                <h6>Заявки:</h6>
                                <div className="orders__info">
                                    <p>Приём: <span>№P47123-747219</span></p>
                                    <p>Вывоз: <span>№P47123-747219</span></p>
                                </div>
                            </div> */}
                    </div>
                </div>
                <div className="modalEditСontainers__footer">
                    <div className="footer__btnsChanges">
                        <UniversalButton label={"Отменить"} onClick={handleCloseModal} color={"error"} size={"large"} typeButton={"button"} />
                        <UniversalButton label={"Сохранить"} onClick={handleSaveContainers} color={"succesful"} size={"large"} typeButton={"button"} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalEditContainers;
