import styles from './ConfirmationModal.module.scss';
import { UniversalButton } from '../../../../../../../components';
import { useDispatch } from 'react-redux';
import { setPage } from '../../../../../../../store/slices/containerAcceptanceSlice';

const ConfirmationModal = ({ onClickNoDemage }) => {
	const dispatch = useDispatch();

	const handleAddDemage = () => {
		dispatch(setPage(3));
	};

	return (
		<div className={styles.modalContainer}>
			<span className={styles.modTitle}>Изменить сформированный акт нельзя. Подтвердить создание акта без указания повреждений?</span>
			<UniversalButton
				onClick={() => onClickNoDemage()}
				label={'Повреждений нет'}
				size={'large'}
				typeButton={'button'}
				color={'succesful'}
			/>
			<UniversalButton
				onClick={handleAddDemage}
				label={'Зафиксировать повреждения '}
				size={'large'}
				typeButton={'button'}
				color={'accent'}
			/>
		</div>
	);
};

export default ConfirmationModal;
