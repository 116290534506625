import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styles from './DamageCard.module.scss';
import { iconDamageSwitcher } from '../../../../../../../../components/ContainerAcceptance/steps/ChoiceDamageArea/components/ContainerMap/Sides/helpers/iconDamageSwitcher';
import { iconSideSwitcher } from '../../../../../../../../components/ContainerAcceptance/steps/ChoiceDamageArea/components/ContainerMap/Sides/helpers/iconSideSwitcher';
import S3Manager from '../../../../../../../../helpers/s3storage';
import { sideTypeToName } from '../../../../../../../../components/ContainerAcceptance/steps/ChoiceDamageArea/components/ContainerMap/Sides/helpers/sideTypeToName';
import { sideNameToType } from '../../../steps/ChoiceDamageArea/components/ContainerMap/Sides/helpers/sideNameToType';

const imageCache = {};

function DamageCard({ damage, index, onClick }) {
	const [imageUrls, setImageUrls] = useState([]);

	const imageUploader = useMemo(() => new S3Manager('damages'), []);

	const fetchImageUrls = useCallback(async () => {
		const urls = await Promise.all(
			damage?.pictures?.map(async (picture) => {
				if (imageCache[picture.filename]) {
					return imageCache[picture.filename];
				} else {
					const url = await imageUploader.getImage(picture.filename);
					imageCache[picture.filename] = url;
					return url;
				}
			}),
		);
		setImageUrls(urls);
	}, [damage.pictures, imageUploader]);

	useEffect(() => {
		fetchImageUrls();
	}, [fetchImageUrls]);

	return (
		<div onClick={() => onClick()} className={styles.main_damage_card}>
			<div className={styles.title}>
				<span>{index}</span>
				<p>{damage?.component ? damage?.component : 'Не указан компонент'}</p>
			</div>
			<div className={styles.types}>
				<div className={styles.types_side_withIcon}>
					{iconSideSwitcher(sideNameToType(damage.side))}
					<p>{damage?.side ? damage?.side : 'Сторона не указана'}</p>
				</div>
				<div className={styles.types_side_withIcon}>
					<p>{sideTypeToName(damage?.side)}</p>
				</div>
				{iconDamageSwitcher(sideNameToType(damage.side))}
				<p>{damage.damage[0].name ? damage.damage[0].name : 'Тип повреждения не указан'}</p>
			</div>
			<p className={styles.damage_card_descr}>{damage?.dimensions.comment ? damage.dimensions.comment : 'Описание отсутствует'}</p>
			<div className={styles.images_container}>
				{imageUrls.length > 0 ? (
					imageUrls?.map((url, index) => (
						<img key={index} src={url} alt={`Damage-${index + 1}`} className={styles.damage_image} />
					))
				) : (
					<img
						alt={'No photo'}
						className={styles.damage_image}
						src={'https://seo-gravity.ru/wp-content/themes/seogravity/static/img/assets/contacts/nophoto.png'}
					></img>
				)}
			</div>
		</div>
	);
}

export default DamageCard;
