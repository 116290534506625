import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postToLogin } from "../../api/login/postToLogin";

// ! postLoginUser (ранее loginUser + dispatch(loginUserSucces())
// Вход клиентов/сотрудников
// /authEmpl 
export const postLoginUser = createAsyncThunk(
    "registration/postLoginUser",
    async ({ requestData }, { rejectWithValue }) => {
      try {
        const response = await postToLogin(requestData);
              // localStorage.setItem('accessToken', response.data.access);
              // localStorage.setItem('refreshToken', response.data.refresh);
        if (!response.statusText === "OK") {
          throw new Error(
            "Ошибка запроса registration/postLoginUser. Не удалось отправить данные"
          );
        }
        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  const loginSlice = createSlice({
    name: "login",
    initialState: {
    // loginUser
      loginUser_success: false,
      loginUser_pending: true,
      loginUser_error: false,
    },
    reducers: {
        setInitialLoginStatus(state, action){
            state.loginUser_success = false;
            state.loginUser_pending = true;
            state.loginUser_error = false;
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(postLoginUser.fulfilled, (state, action) => {
          state.loginUser_success = true;
          state.loginUser_pending = false
          state.loginUser_error = false
        })
        .addCase(postLoginUser.pending, (state, action) => {
          state.loginUser_success = false;
          state.loginUser_error = false
          state.loginUser_pending = true;
        })
        .addCase(postLoginUser.rejected, (state, action) => {
          state.loginUser_success = false;
          state.loginUser_pending = false;
          state.loginUser_error = action.payload.response.data
        })
    }
    });

    export const { setInitialLoginStatus } = loginSlice.actions;
    export default loginSlice.reducer;