import api from '../../api.js';

export const groups = api.injectEndpoints({
	endpoints: (builder) => ({
		getGroupsList: builder.query({
			query: ({ page, page_size }) => {
				const params = { page, page_size };
				return {
					url: '/api/erp/group/list/',
					params,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetGroupsListQuery, useLazyGetGroupsListQuery } = groups;
