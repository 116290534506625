import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import "../../../../../assets/styles/Tables.scss";
import {
    MRT_EditActionButtons,
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import { putOneContainerAction } from '../../../../../store/actions/clientContainer';
import { useDispatch } from 'react-redux';
import ModalEditContainers from "./components/ModalEditContainers";
import { useSelector } from "react-redux";
import { getLinesContainerAction } from '../../../../../store/actions/linesContainer'
import { linesContainerSelector } from '../../../../../store/selectors/linesContainer'
import instanceClient from "../../../../../api/client/configAxios";
import { Modal, Notification, Preloader, SeparateModal, Switcher, UniversalButton } from "../../../../../components";
import { useMediaQuery } from 'react-responsive';
import { IconCross, IconEdit, IconEyeClose, IconFilter, IconMaxmize, IconSearch, IconSettings } from "../../../../../components/Icons";

// иконки
const fontAwesomeIcons = {
    FullscreenIcon: () => <IconMaxmize size={"largeS"} color={"white"} />,
    FullscreenExitIcon: () => <IconMaxmize size={"largeS"} color={"white"} />,
    ViewColumnIcon: () => <IconSettings size={"largeS"} />,
    FilterListIcon: () => <IconFilter size={"largeS"} color={"white"} />,
    FilterListOffIcon: () => <IconFilter size={"largeS"} color={"white"} />,
    SearchIcon: () => <IconSearch size={"largeS"} color={"white"} />,
    SearchOffIcon: () => <IconSearch size={"largeS"} color={"white"} />,
    ArrowDropDownIcon: () => <IconSearch size={"largeS"} color={"white"} />,
    ClearAllIcon: () => <IconSearch size={"largeS"} color={"white"} />,
    SortIcon: (props) => (
        <IconSearch size={"largeS"} color={"white"} {...props} />
    ),
    VisibilityOffIcon: () => <IconEyeClose size={"largeS"} />,
    RestartAltIcon: () => <IconSearch size={"largeS"} color={"white"} />,
};

const TableContainersClient = () => {
    const dispatch = useDispatch();

    // запрос контейнеров клиента 
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingRows, setIsLoadingRows] = useState(false);
    const [offset, setOffset] = useState(0)
    const [url, setUrl] = useState(`/containers/?limit=25&offset=0`);
    const [count, setCount] = useState(0)
    const [params, setParams] = useState("")
    const [validationErrors, setValidationErrors] = useState({});

    // уведомление 
    const onClose = () => {
        setIsActive(false);
    };

    const [isActive, setIsActive] = useState(false);

    // отслеживание ширины окна
    const isMobile = useMediaQuery({ maxWidth: 639.9 });

    const handleGetContainers = () => {
        instanceClient
            .get(url, { params: params })
            .then((response) => {
                setIsLoading(false)
                setData((prevState) => [...prevState, ...response.data.results]);
                setCount(response.data.count)
                setIsLoadingRows(false)
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (data.length !== count || data.length == 0) {
            handleGetContainers()
        } else {
            setIsLoadingRows(false)
        }

    }, [url, params]);

    useEffect(() => {
        setUrl(`/containers/?limit=25&offset=${offset}`);
    }, [offset]);

    // бесконечная загрузка
    const tableContainerRef = useRef(null);

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (scrollTop + clientHeight >= scrollHeight && scrollTop != 0) {
                    setIsLoadingRows(true)
                    setOffset((prevOffset) => prevOffset + 25);
                }
            }
        },
        [],
    );

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    // запрос на все линии контейнера
    useEffect(() => {
        const handleLines = async () => {
            try {
                await dispatch(getLinesContainerAction());
            } catch (error) {
            }
        };
        handleLines()
    }, []);

    // данные для выбора
    const allLines = useSelector(linesContainerSelector);
    const [allSizes, setallSizes] = useState([
        { id: 1, name: "20" },
        { id: 2, name: "40" },
        { id: 3, name: "45" },
    ]);
    const [allTypes, setallTypes] = useState([
        { id: 1, name: "DC" },
        { id: 2, name: "DV" },
        { id: 3, name: "HC" },
        { id: 4, name: "PW" },
        { id: 5, name: "OT" },
        { id: 6, name: "OT HC" },
        { id: 7, name: "RF" },
        { id: 8, name: "RF HC" },
        { id: 9, name: "FR" },
        { id: 10, name: "FR HC" },
        { id: 11, name: "TC" },
        { id: 12, name: "HR" },
    ]);

    const [allStatuses, setallStatuses] = useState([
        { id: 1, name: "Ожидается" },
        { id: 2, name: "Принят" },
        { id: 3, name: "На ремонте" },
        { id: 4, name: "Ожидает вывоза" },
        { id: 5, name: "Вывезен" },
    ])

    // const [allCargos, setallCargos] = useState(["Чечевица", "Кукуруза", "Асфальт"])

    // колонки
    const columns = useMemo(
        () => [
            {
                accessorKey: "status",
                header: "Статус",
                size: 192,
                enableEditing: false,
                // фильтрация
                muiFilterTextFieldProps: { placeholder: 'Выберите статус' },
                filterSelectOptions: allStatuses?.map((el) => (el.name)),
                filterVariant: 'select',

                // сортировка от меньшего к большему по первому клику
                sortDescFirst: true,
            },
            {
                accessorKey: "line",
                header: "Линия",
                size: 232,
                editVariant: 'select',
                editSelectOptions: allLines && allLines.lines?.map((el) => (el.line_name)),
                muiEditTextFieldProps: {
                    select: true,
                },
                muiFilterTextFieldProps: { placeholder: 'Выберите линию' },
                filterSelectOptions: allLines.lines?.map((el) => (el.line_name)),
                filterVariant: 'select',
                sortDescFirst: true,
            },
            {
                accessorKey: "container_number",
                header: "№ контейнера",
                size: 230,
                enableHiding: false,
                muiFilterTextFieldProps: { placeholder: 'Введите №' },
                sortDescFirst: true,
                muiEditTextFieldProps: {
                    placeholder: 'ABCD123456',
                    type: 'text',
                    required: true,
                    error: !!validationErrors?.container_number,
                    helperText: validationErrors?.container_number,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            container_number: undefined,
                        }),
                    onChange: (event) => {
                        event.target.value = event.target.value.toUpperCase()

                        if (!event.target.value) {
                            setValidationErrors((prev) => ({ ...prev, container_number: 'Введите номер в формате: ABCD123456' }));
                        } else {
                            delete validationErrors.container_number;
                            setValidationErrors({ ...validationErrors });
                        }
                    },
                },
            },
            {
                accessorKey: "size",
                header: "Размер",
                size: 200,
                editVariant: 'select',
                editSelectOptions: allSizes?.map((el) => (el.name)),
                muiEditTextFieldProps: {
                    select: true,
                },
                muiFilterTextFieldProps: { placeholder: 'Выберите размер' },
                filterSelectOptions: allSizes?.map((el) => (el.name)),
                filterVariant: 'select',
                sortDescFirst: true,
            },
            {
                accessorKey: "type",
                header: "Тип",
                size: 180,
                editVariant: 'select',
                editSelectOptions: allTypes?.map((el) => (el.name)),
                muiEditTextFieldProps: {
                    select: true,
                },
                muiFilterTextFieldProps: { placeholder: 'Выберите тип' },
                filterSelectOptions: allTypes?.map((el) => (el.name)),
                filterVariant: 'select',
            },
            // {
            //     accessorKey: "cargo",
            //     header: "Груз",
            //     size: 130,
            //     editVariant: 'select',
            //     editSelectOptions: allCargos,
            //     muiEditTextFieldProps: {
            //         select: true,
            //     },
            // },
        ],
        [allLines, validationErrors]
    );

    // валидация номера контейнера
    const validateNumberContainer = (numberContainer) =>
        !!numberContainer.length &&
        numberContainer
            .match(
                /^[A-Z]{4}\d{6}$/,
            );

    function validateData(data) {
        return {
            container_number: !validateNumberContainer(data.container_number) ? 'Введите номер в формате: ABCD123456' : ''
        };
    }

    // отправка редактирования контейнера на сервер 

    const [dataContainer, setDataContainer] = useState({})

    const handleSaveOneContainer = async ({ values, row, table }) => {
        const newValidationErrors = validateData(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        try {
            for (const key in values) {
                if (values.hasOwnProperty(key) && row.original.hasOwnProperty(key)) {
                    if (values[key] !== row.original[key]) {
                        if (key === 'size') {
                            dataContainer[key] = allSizes[allSizes.findIndex((item) => item.name == values[key])].id;
                        } else if (key === 'type') {
                            dataContainer[key] = allTypes[allTypes.findIndex((item) => item.name == values[key])].id;
                        } else if (key === 'line') {
                            dataContainer[key] = allLines.lines[allLines.lines.findIndex((item) => item.line_name == values[key])].slug;
                        } else {
                            dataContainer[key] = values[key];
                        }
                    }
                }
            }
            if (Object.entries(dataContainer).length !== 0) {
                await dispatch(putOneContainerAction(row.original.id, dataContainer));
                let containerItem = data.find((item) => item.id == row.original.id)
                containerItem.is_dangerous = dataContainer["is_dangerous"]
                containerItem.is_filled = dataContainer["is_filled"];
            }
            table.setEditingRow(null);
        } catch (error) {
            setData(prevData => [...prevData]);
            setIsActive(true)
        }
    };

    // выбор строк
    const [rowSelection, setRowSelection] = useState({});

    // выбор контейнеров
    const [selectContainers, setSelectContainers] = useState([]);

    // модальное окно для редактирования множества контейнеров
    const [showModalEditContainers, setShowModalEditContainers] = useState(false);
    const handleCloseModal = () => {
        setShowModalEditContainers(false);
    };

    // редактирование параметров на запросы
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);

    // сортировка
    useEffect(() => {
        if (sorting[0]?.id) {
            if (sorting[0]?.id === 'line') {
                if (params.ordering?.startsWith('-')) {
                    setParams({ ordering: `${sorting[0]?.id}__line_name` })
                } else {
                    setParams({ ordering: `-${sorting[0]?.id}__line_name` })
                }
            } else if (sorting[0]?.id === 'client') {
                if (params.ordering?.startsWith('-')) {
                    setParams({ ordering: `${sorting[0]?.id}__corporate_name` })
                } else {
                    setParams({ ordering: `-${sorting[0]?.id}__corporate_name` })
                }
            } else {
                if (params.ordering?.startsWith('-')) {
                    setParams({ ordering: `${sorting[0]?.id}` })
                } else {
                    setParams({ ordering: `-${sorting[0]?.id}` })
                }
            }
            setIsLoadingRows(true)
            setData("")
            setOffset(0)
        }
    }, [sorting]);

    // фильтрация в колонках
    useEffect(() => {
        if (columnFilters) {
            columnFilters.map((el) => {
                if (el.id === "type") {
                    let typeId = allTypes[allTypes.findIndex((item) => item.name == el.value.toUpperCase())]?.id;
                    setIsLoadingRows(true)
                    setParams({ type: typeId })
                    setData("")
                    setOffset(0)
                }
                if (el.id === "size") {
                    let sizeId = allSizes[allSizes.findIndex((item) => item.name == el.value)]?.id;
                    setIsLoadingRows(true)
                    setParams({ size: sizeId })
                    setData("")
                    setOffset(0)
                }
                if (el.id === "line") {
                    let lineId = allLines.lines.findIndex((item) => item.line_name == el.value) + 1;
                    setIsLoadingRows(true)
                    setParams({ line: lineId })
                    setData("")
                    setOffset(0)
                }
                if (el.id === "status") {
                    let statusId = allStatuses[allStatuses.findIndex((item) => item.name == el.value)]?.id;
                    setIsLoadingRows(true)
                    setParams({ status: statusId })
                    setData("")
                    setOffset(0)
                }
                if (el.id === "container_number") {
                    setIsLoadingRows(true)
                    setParams({ container_number: el.value })
                    setData("")
                    setOffset(0)
                }
                if (el.id === "client") {
                    setIsLoadingRows(true)
                    setParams({ client: el.value })
                    setData("")
                    setOffset(0)
                }
            })
        }

    }, [columnFilters]);

    // поиск
    useEffect(() => {
        if (globalFilter) {
            setIsLoadingRows(true)
            setParams({ search: globalFilter })
            setData("")
            setOffset(0)
        } else if (globalFilter === '') {
            setData("")
            setParams({})
        }
    }, [globalFilter]);

    // функции для повержден и опасный груз
    const [isCheckedDamage, setCheckedDamage] = useState(false);
    const [isCheckedDangerous, setCheckedDangerous] = useState(false);

    const handleCheckedDamage = (newChecked) => {
        setCheckedDamage(newChecked);
        dataContainer["is_filled"] = newChecked;
    };

    const handleCheckedDangerous = (newChecked) => {
        setCheckedDangerous(newChecked);
        dataContainer["is_dangerous"] = newChecked;
    };

    const table = useMaterialReactTable({
        data: data,
        columns,
        initialState: {
            showGlobalFilter: true,
            // порядок колонок
            columnOrder: [
                "mrt-row-select",
                "mrt-row-actions",
                "status",
                "line",
                "container_number",
                "size",
                "type",
                // "cargo",
            ],
        },

        // сортировка, фильтрация и поиск через сервер
        manualFiltering: true,
        manualSorting: true,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        state: {
            rowSelection,
            columnFilters,
            globalFilter,
            sorting,
        },

        // фильтрация в колонках
        muiFilterTextFieldProps: {
            sx: {
                '> div': {
                    fontFamily: "Montserrat",
                },

                '> div::before': {
                    borderBottom: '1px solid #E4DFFF !important',
                },

                '> div::after': {
                    borderBottom: '1px solid #E4DFFF !important',
                },

                '> div div': {
                    opacity: '1',
                    color: '#F3EEFF',
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '20px',
                },

                'input': {
                    padding: '2px 0 4px',

                    '&::placeholder': {
                        opacity: '1',
                        color: '#F3EEFF',
                        fontSize: '12px',
                        fontWeight: '400',
                        fontFamily: "Montserrat",
                        lineHeight: '20px',

                    },
                },

                'button svg': {
                    color: '#AEAEB2',
                },

                '> div > svg': {
                    color: '#756CC2',
                },
            },
        },


        // стиль контейнера таблицы
        muiTablePaperProps: {
            sx: {
                backgroundColor: "#263141",
                borderRadius: '24px',
            },
        },

        // отключить количество строк на странице
        enablePagination: false,

        // смена порядка колонок
        enableColumnOrdering: true,

        // русский язык
        localization: MRT_Localization_RU,

        // менять размер колонки кнопка в самой колонке
        enableColumnResizing: true,
        columnResizeMode: "onChange",
        layoutMode: "grid",

        // поисковая строка
        positionGlobalFilter: "left",
        muiSearchTextFieldProps: {
            sx: {
                '> div': {
                    width: "100%",
                    borderRadius: '8px',
                    background: '#5c53a7',
                    border: 'none',
                    padding: '2px 2px 2px 4px',
                },

                'svg': {
                    background: "#2D2276",
                    borderRadius: "8px",
                    width: "34px",
                    height: "34px",
                    marginRight: "4px",
                    padding: "6px",
                },

                '& fieldset': {
                    border: 'none',
                },

                '> div > div': {
                    display: 'none',
                },

                '& input': {
                    borderRadius: '8px',
                    background: '#fffbff',
                    padding: '6px 12px',
                    color: '#000000',
                    width: '100%',
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    lineHeight: "20px",
                },

            },
            placeholder: "Начните писать, например: NVNH349912, ECON, FESCO",
        },

        // изменение иконок
        icons: fontAwesomeIcons,

        // убрать кнопку меняющую ширину колонок в хедере
        enableDensityToggle: false,

        // выбор строк
        enableRowSelection: true,
        positionToolbarAlertBanner: "bottom",
        onRowSelectionChange: setRowSelection,

        // редактирование строк
        enableEditing: true,
        createDisplayMode: "modal",
        editDisplayMode: "modal",

        // хедер
        muiTableHeadCellProps: {
            sx: {
                backgroundColor: "#263141",
                color: '#fffbff',

                'div > span > span > svg.MuiTableSortLabel-icon': {
                    opacity: '1 !important',
                    color: '#fffbff !important',
                    fill: '#fffbff !important',
                },

                'div span span.MuiTableSortLabel-root': {
                    opacity: '1 !important',
                },
            },
        },

        muiTopToolbarProps: {
            sx: {
                backgroundColor: "#fffbff",
                padding: '16px 12px 12px',

                'div:first-of-type': {
                    width: '100%',
                },

                'div:nth-of-type(2) ': {
                    gap: '8px',
                    padding: '0',
                },

                'div:nth-of-type(2) > div': {
                    gap: '8px',
                },

                'button': {
                    borderRadius: '8px',
                    background: '#5c53a7',
                    padding: '8px',

                    'i': {
                        color: '#ffffff',
                    }
                },

                'button:focus, button:active, button:hover': {
                    background: '#5c53a7',
                },
            },
        },

        // липкий хедер
        enableStickyHeader: true,

        // высота тела таблицы 
        muiTableContainerProps: {
            ref: tableContainerRef,
            sx: {
                maxHeight: "calc(100vh - 270px)",
                minHeight: "calc(100vh - 270px)",

                '@media(min-width: 639.9px)': {
                    maxHeight: "calc(100vh - 278px)",
                    minHeight: "calc(100vh - 278px)",
                },

                '@media(min-width: 1279.9px)': {
                    maxHeight: "calc(100vh - 235px)",
                    minHeight: "calc(100vh - 235px)",
                },
            },

            onScroll: (event) => fetchMoreOnBottomReached(event.target),
        },

        // кнопка редактирования
        renderRowActions: ({ row, table }) => (
            <UniversalButton icon={<IconEdit size={"medium"} color={"primary-active"} />} onClick={() => table.setEditingRow(row)}
                size={"large"} type={"with-border"} color={"primary"}
            />
        ),

        // модальное окно
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <div className="modalEditСontainers">
                <Notification text={"Ошибка"}
                    descr={"Попробуйте еще раз"}
                    onClose={onClose}
                    isActive={isActive}
                    isStatus={"error"} />
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '4px 16px' }}>
                        <UniversalButton icon={<IconCross size={"large"} color={"black"} />} onClick={() => {
                            table.setEditingRow(null)
                            setIsActive(false)
                        }}
                            size={"large"}
                            typeButton={"button"} />
                    </div>
                    <div className="modalEdit">
                        <div className="modalEdit__header">
                            <div className="modalEdit__header_info">
                                <p>Контейнер №{row.original.container_number}</p>
                                <span>{row.original.status}</span>
                            </div>
                        </div>
                        <div className="modalEdit__body">
                            <div className="modalEdit__body_inputs">
                                {internalEditComponents}
                            </div>
                            <div className="modalEdit__body_switchers">
                                <div className="switchers__item">
                                    Поврежден
                                    <Switcher onChecked={handleCheckedDamage} externalChecked={row.original.is_filled} />
                                </div>
                                <div className="switchers__item">
                                    Опасный
                                    <Switcher onChecked={handleCheckedDangerous} externalChecked={row.original.is_dangerous} />
                                </div>
                            </div>
                            {/* <div className="modalEdit__body_orders">
                            <h6>Заявки:</h6>
                            <div className="orders__info">
                                <p>Приём: <span>№P47123-747219</span></p>
                                <p>Вывоз: <span>№P47123-747219</span></p>
                            </div>
                        </div> */}
                        </div>
                        <div className="modalEdit__footer">
                            <MRT_EditActionButtons variant="text" table={table} row={row} />
                        </div>
                    </div>
                </div>
            </div>
        ),

        // функция для сохранения изменений
        onEditingRowSave: handleSaveOneContainer,

        // футер таблицы
        muiBottomToolbarProps: {
            sx: {
                minHeight: 'none',
                background: '#263141',

                'div:nth-of-type(2)': {
                    padding: '0px',
                },
            },

        },

        renderBottomToolbarCustomActions: ({ table }) => (
            <div className="table__footer">
                <UniversalButton label={"Изменить"} color={"succesful"}
                    disabled={
                        Object.keys(rowSelection).length < 2
                    }
                    onClick={() => {
                        setShowModalEditContainers(true);
                        const selectedRowsModel = table.getSelectedRowModel();

                        if (selectedRowsModel && selectedRowsModel.rows) {
                            setSelectContainers(() => {
                                const uniqueValues = selectedRowsModel.rows.reduce((acc, el) => {
                                    const originalValue = el.original;
                                    acc.push(originalValue);
                                    return acc;
                                }, []);

                                return uniqueValues;
                            });
                        }
                    }}
                    size={"medium"} typeButton={"button"} />
                {isLoadingRows && <Preloader />}
                <div>
                    Загружено {data?.length} из {count}
                </div>
            </div>
        ),

        // сообщение сколько выбрано строк
        muiToolbarAlertBannerProps: {
            sx: {
                position: 'relative',
                '> div': {
                    position: 'absolute',
                    left: '125px',
                    top: '1px',
                    color: '#fffbff',
                },

                '@media(max-width: 550px)': {
                    '> div': {
                        top: '17px',
                    },

                    '> div > div': {
                        padding: '8px',
                        fontSize: '14px'
                    },
                },
            }
        },
    });

    return (
        <>
            {isLoading ? (
                <Preloader bg={'true'} />
            ) : (
                <>
                    {showModalEditContainers ?
                        (isMobile ?
                            (<div className="modalEditСontainers__wrapper" >
                                <Modal
                                    onClose={handleCloseModal}
                                    children={<ModalEditContainers setData={setData} handleCloseModal={handleCloseModal} selectContainers={selectContainers} allLines={allLines} allSizes={allSizes} allTypes={allTypes} />} />
                            </div>)
                            :
                            (<div className="modalEditСontainers__wrapper" >
                                <SeparateModal
                                    style={"bigSize"}
                                    btnIcon={
                                        <UniversalButton icon={<IconCross size={"large"} color={"black"} />} onClick={handleCloseModal} size={"large"} typeButton={"button"} />
                                    }
                                    onClose={handleCloseModal}
                                    children={<ModalEditContainers setData={setData} handleCloseModal={handleCloseModal} selectContainers={selectContainers} allLines={allLines} allSizes={allSizes} allTypes={allTypes} />} />
                            </div >)
                        ) : null}
                    <div className="table__container">
                        <MaterialReactTable table={table} />
                    </div>
                </>
            )}

        </>

    );
};

export default TableContainersClient; 