import styles from '../AccountEmpl.module.scss';
import Header from '../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { Switcher, UniversalButton } from '../../../../components';
import {
	postEntryreports,
	putOneContainer,
	getContainersForContainerAcceptance,
	getVehiclesForContainerAcceptance,
	postVehicle,
	setContainerNumberContainerAcceptance,
	setDriverContainerAcceptance,
	setVehicleNumberContainerAcceptance,
	setSizeContainerAcceptance,
	setTypeContainerAcceptance,
	setBruttoContainerAcceptance,
	setNettoContainerAcceptance,
	setIsFilledContainerAcceptance,
} from '../../../../store/slices/containerAcceptanceSlice';
import { putEntriesList } from '../../../../store/slices/orderSlice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateEffect } from '../../../../hooks/useUpdateEffect';
import { useForm } from 'react-hook-form';
import { useDebounce } from '../../../../hooks/useDebounce';
import AutoCompleteSearch from '../../../../components/Order/AutoCompleteSearch/AutoCompleteSearch';
import { FormControl, MenuItem, Select } from '@mui/material';
import MainInput from '../../../../components/Inputs/MainInput/MainInput';

function AccountEmplContainerAcceptance(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		containerListForContainerAcceptance,
		vehicleNumberContainerAcceptance,
		containerNumberContainerAcceptance,
		driverContainerAcceptance,
		sizes,
		types,
		sizeContainerAcceptance,
		typeContainerAcceptance,
		reportId,
		postVehicle_success,
		postEntryreports_success,
		grossContainerAcceptance,
		nettoContainerAcceptance,
		isFilledContainerAcceptance,
		vehicleListForContainerAcceptance,
		getVehiclesForContainerAcceptance_success,
		getVehiclesForContainerAcceptance_pending,
		getContainersForContainerAcceptance_success,
		getContainersForContainerAcceptance_pending,
	} = useSelector((state) => state.containerAccept);

	useEffect(() => {
		dispatch(getVehiclesForContainerAcceptance({ number: null }));
		dispatch(getContainersForContainerAcceptance({ number: null }));
	}, []);

	useUpdateEffect(() => {
		dispatch(getVehiclesForContainerAcceptance({ number: vehicleNumberContainerAcceptance.registration_number }));
	}, [postVehicle_success]);

	useEffect(() => {
		if (postEntryreports_success) {
			navigate(`/accountEmpl/containerAcceptance/${reportId}`);
		}
	}, [postEntryreports_success]);

	const { register, setFocus, formState, setValue } = useForm({});

	const { errors } = formState;

	const debouncedVehicleSearch = useDebounce((e, newValue) => {
		dispatch(setVehicleNumberContainerAcceptance(e.target.value));
		dispatch(getVehiclesForContainerAcceptance({ number: e.target.value || newValue }));
	}, 250);

	const debouncedContainerSearch = useDebounce((e, newValue) => {
		dispatch(setContainerNumberContainerAcceptance(e.target.value));
		dispatch(getContainersForContainerAcceptance({ number: e.target.value || newValue }));
	}, 250);

	const handleChangeVehicleInput = (e, newValue) => {
		if (e) {
			debouncedVehicleSearch(e, newValue);
		}
	};
	const handleChangeVehicleOption = (e, newValue) => {
		dispatch(setVehicleNumberContainerAcceptance(newValue));
	};

	const handleChangeContainerInput = (e, newValue) => {
		if (e) {
			debouncedContainerSearch(e, newValue);
		}
	};
	const handleChangeContainerOption = (e, newValue) => {
		dispatch(setContainerNumberContainerAcceptance(newValue));
	};
	const handleChangeDriver = (e) => {
		dispatch(setDriverContainerAcceptance(e.target.value));
	};

	const handleChangeBrutto = (e) => {
		dispatch(setBruttoContainerAcceptance(e.target.value));
	};

	const handleChangeNetto = (e) => {
		dispatch(setNettoContainerAcceptance(e.target.value));
	};

	const handleCheckedLaden = (newChecked) => {
		dispatch(setIsFilledContainerAcceptance(newChecked));
	};

	const [changeData, setChangeData] = useState({});

	// Начало приемки контейнера
	const handleCreateAcceptance = async () => {
		// Проверка и обновление данных по размеру и типу контейнера
		if (sizeContainerAcceptance !== containerListForContainerAcceptance[0].size) {
			changeData['size'] = sizes.find((item) => item.name === sizeContainerAcceptance)?.id;
		}
		if (typeContainerAcceptance !== containerListForContainerAcceptance[0].type) {
			changeData['type'] = types.find((item) => item.name === typeContainerAcceptance)?.id;
		}

		// Функция для отправки postEntryreports и putEntriesList
		const postEntryReportsAndUpdateEntriesList = async () => {
			try {
				// Отправка запроса postEntryreports
				const response = await dispatch(postEntryreports({ requestData: { container: containerNumberContainerAcceptance.id } }));

				// Поиск текущего пропуска для контейнера
				const currentEntryPass = response?.payload?.container?.entrypass?.find((el) => el.order_type_display === 'Заявка на ввоз');
				if (currentEntryPass) {
					const { id, order, terminal } = currentEntryPass;
					const requestData = [
						{
							pass_pk: id,
							vehicle_driver: driverContainerAcceptance,
							vehicle: vehicleNumberContainerAcceptance?.id,
							is_filled: isFilledContainerAcceptance,
							net: nettoContainerAcceptance,
							gross: grossContainerAcceptance,
						},
					];
					// Отправка запроса putEntriesList
					await dispatch(putEntriesList({ terminal_id: terminal, order_id: order, requestData }));
				}
			} catch (error) {
				console.error(error);
			}
		};

		try {
			if (Object.keys(changeData).length === 0) {
				// Если нет изменений в данных контейнера, сразу отправляем postEntryreports и putEntriesList
				await postEntryReportsAndUpdateEntriesList();
			} else {
				// Если есть изменения в данных контейнера, сначала отправляем putOneContainer
				await dispatch(
					putOneContainer({
						terminal_pk: containerNumberContainerAcceptance.terminal,
						container_pk: containerNumberContainerAcceptance.id,
						requestData: changeData,
					}),
				);
				// Затем отправляем postEntryreports и putEntriesList
				await postEntryReportsAndUpdateEntriesList();
			}
		} catch (error) {
			console.error(error);
		}
	};

	// Деактивация кнопки
	const isButtonDisabled = () => {
		if (isFilledContainerAcceptance) {
			return (
				!vehicleNumberContainerAcceptance ||
				!containerNumberContainerAcceptance ||
				!driverContainerAcceptance ||
				!grossContainerAcceptance ||
				!nettoContainerAcceptance
			);
		}
		return !vehicleNumberContainerAcceptance || !containerNumberContainerAcceptance || !driverContainerAcceptance;
	};

	return (
		<div className={styles.account__wrap}>
			<Header text={'Прием контейнера'} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<div className={styles.span_wrapper}>
						<span>Введите номер A/М или контейнера</span>
					</div>
					<div className={styles.items_wrapper}>
						<div className={styles.item_wrapper}>
							<AutoCompleteSearch
								multiple={false}
								title={'Номер контейнера'}
								data={containerListForContainerAcceptance}
								objectKey={'container_number'}
								handleInputChange={handleChangeContainerInput}
								handleOptionChange={handleChangeContainerOption}
								handleIfNoOptions={() => console.log('Нет контейнеров, добавить - TODO')}
								noOptionsTitle={'Добавить контейнер'}
								pending_state={getContainersForContainerAcceptance_pending}
								success_state={getContainersForContainerAcceptance_success}
								choosenOption={
									containerNumberContainerAcceptance?.id && containerNumberContainerAcceptance?.container_number
										? {
												id: containerNumberContainerAcceptance?.id,
												container_number: containerNumberContainerAcceptance?.container_number,
											}
										: null
								}
								// choosenOption={null}
								placeholder={'Выберите контейнер'}
								freeSolo={true}
							/>
							<div className={styles.item__inps}>
								<div>
									<p>Размер</p>
									<FormControl
										sx={{
											m: 1,
											minWidth: 120,
											margin: '0px',
											borderRadius: '8px',
											'& .MuiOutlinedInput-root': {
												'& fieldset': {
													borderColor: sizeContainerAcceptance ? '#2D2276' : '#5c53a7',
													borderWidth: '1px',
												},
												'&:hover fieldset': {
													borderColor: '#2D2276',
													borderWidth: '1px',
												},
												'&.Mui-focused fieldset': {
													borderColor: '#FF8e16',
													borderWidth: '1px',
												},
											},
										}}
										size="small"
									>
										<Select
											id="demo-select-small"
											value={sizeContainerAcceptance ? sizeContainerAcceptance : ''}
											onChange={(e) => dispatch(setSizeContainerAcceptance(e.target.value))}
											displayEmpty
											inputProps={{ 'aria-label': 'Without label' }}
											sx={{
												borderRadius: '8px',
												'& .MuiSelect-select': {
													borderRadius: '8px',
													padding: '6px 14px',
													fontSize: '16px',
													lineHeight: '24px',
													color: sizeContainerAcceptance ? '#443A8E' : '#1c1c1e',
													backgroundColor: sizeContainerAcceptance ? '#e4dfff' : '#fffbff',
												},
											}}
											required
											renderValue={(selected) => {
												if (selected.length === 0) {
													return <span>Выберите размер</span>;
												}

												return selected;
											}}
										>
											{sizes.map((el, index) => (
												<MenuItem key={index} value={el.name}>
													{el.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
								<div>
									<p>Тип</p>
									<FormControl
										sx={{
											m: 1,
											minWidth: 120,
											margin: '0px',
											borderRadius: '8px',
											'& .MuiOutlinedInput-root': {
												'& fieldset': {
													borderColor: typeContainerAcceptance ? '#2D2276' : '#5c53a7',
													borderWidth: '1px',
												},
												'&:hover fieldset': {
													borderColor: '#2D2276',
													borderWidth: '1px',
												},
												'&.Mui-focused fieldset': {
													borderColor: '#FF8e16',
													borderWidth: '1px',
												},
											},
										}}
										size="small"
									>
										<Select
											id="demo-select-small"
											value={typeContainerAcceptance ? typeContainerAcceptance : ''}
											onChange={(e) => dispatch(setTypeContainerAcceptance(e.target.value))}
											displayEmpty
											inputProps={{ 'aria-label': 'Without label' }}
											renderValue={(selected) => {
												if (selected.length === 0) {
													return <span>Выберите тип</span>;
												}

												return selected;
											}}
											sx={{
												borderRadius: '8px',
												'& .MuiSelect-select': {
													borderRadius: '8px',
													padding: '6px 14px',
													fontSize: '16px',
													lineHeight: '24px',
													color: typeContainerAcceptance ? '#443A8E' : '#1c1c1e',
													backgroundColor: typeContainerAcceptance ? '#e4dfff' : '#fffbff',
												},
											}}
											required
										>
											{types.map((el, index) => (
												<MenuItem key={index} value={el.name}>
													{el.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
							</div>
						</div>

						<div className={styles.item_wrapper}>
							<AutoCompleteSearch
								multiple={false}
								title={'Номер А/М'}
								data={vehicleListForContainerAcceptance}
								objectKey={'registration_number'}
								handleInputChange={handleChangeVehicleInput}
								handleOptionChange={handleChangeVehicleOption}
								handleIfNoOptions={() => dispatch(postVehicle())}
								noOptionsTitle={'Добавить автомобиль'}
								pending_state={getVehiclesForContainerAcceptance_pending}
								success_state={getVehiclesForContainerAcceptance_success}
								// choosenOption={vehicleNumberContainerAcceptance ? vehicleNumberContainerAcceptance : null}
								choosenOption={
									vehicleNumberContainerAcceptance?.id && vehicleNumberContainerAcceptance?.registration_number
										? {
												id: vehicleNumberContainerAcceptance?.id,
												registration_number: vehicleNumberContainerAcceptance?.registration_number,
											}
										: null
								}
								// choosenOption={null}
								placeholder={'Выберите автомобиль'}
								freeSolo={false}
							/>
						</div>
						<div className={styles.item_wrapper}>
							<MainInput
								title={'Водитель'}
								errorTitle={'Некорректный водитель'}
								name="driver"
								register={register}
								value={driverContainerAcceptance ? driverContainerAcceptance : ''}
								handleFieldChange={handleChangeDriver}
								setValue={setValue}
								setFocus={setFocus}
								errors={errors}
								rules={
									{
										// required: window.location.pathname === isCreateRoute ? 'Обязательное поле' : undefined,
										// minLength: {
										// 	value: 2,
										// 	message: 'Минимум 2 символа',
										// },
									}
								}
								placeholder={'Данные водителя'}
							/>
						</div>

						<div className={styles.item_wrapper}>
							<div className={styles.item__switch}>
								Груженый контейнер
								<Switcher onChecked={handleCheckedLaden} externalChecked={isFilledContainerAcceptance} />
							</div>
							<div className={styles.item__inps}>
								<MainInput
									title={'Масса Брутто'}
									errorTitle={'Некорректная масса'}
									name="gross"
									register={register}
									value={grossContainerAcceptance ? grossContainerAcceptance : ''}
									handleFieldChange={handleChangeBrutto}
									setValue={setValue}
									setFocus={setFocus}
									errors={errors}
									rules={{}}
									placeholder={'000000'}
									mask="999999"
								/>
								<MainInput
									title={'Масса Нетто'}
									errorTitle={'Некорректная масса'}
									name="netto"
									register={register}
									value={nettoContainerAcceptance ? nettoContainerAcceptance : ''}
									handleFieldChange={handleChangeNetto}
									setValue={setValue}
									setFocus={setFocus}
									errors={errors}
									rules={{}}
									placeholder={'000000'}
									mask="999999"
								/>
							</div>
						</div>
					</div>
					<div className={styles.button_wrapper}>
						<UniversalButton
							onClick={handleCreateAcceptance}
							label={'Начать прием контейнера'}
							size={'medium'}
							color={'accent'}
							disabled={isButtonDisabled()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplContainerAcceptance;
