import api from '../../api.js';

export const terminals = api.injectEndpoints({
	endpoints: (builder) => ({
		getTerminalsList: builder.query({
			query: ({ page, page_size }) => {
				const params = { page, page_size };
				return {
					url: '/api/terminal/list/',
					params,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetTerminalsListQuery, useLazyGetTerminalsListQuery } = terminals;
