import { format } from 'date-fns';
// import { ru } from 'date-fns/locale';

import { createSlice, createAsyncThunk, current, isDraft } from '@reduxjs/toolkit';
import { postNewOrderApi } from '../../api/order/postNewOrderApi';
import { getLinesContainer } from '../../api/containers/getLinesContainer';
import { postContainerApi } from '../../api/containers/postContainerApi';
import { getOrderApi } from '../../api/order/getOrderApi';
import { getContainersInOrderApi } from '../../api/containers/getContainersInOrderApi';
import { getContainersOrgApi } from '../../api/containers/getContainersOrgApi';
import { getOrdersApi } from '../../api/order/getOrdersApi';
import { getEntriesList } from '../../api/entries/getEntriesList';
import { putEntries } from '../../api/entries/putEntries';
import { getOneEntrieApi } from '../../api/entries/getOneEntrieApi';
import { putLinkContainersApi } from '../../api/containers/putLinkContainersApi';
import { deleteContainerApi } from '../../api/containers/deleteContainerApi';
import { putOneContainerApi } from '../../api/containers/putOneContainerApi';

export const getLines = createAsyncThunk('order/getLines', async (_, { rejectWithValue }) => {
	try {
		const response = await getLinesContainer();
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const getOrderData = createAsyncThunk('order/getOrderData', async ({ order_id, terminal_data }, { rejectWithValue }) => {
	try {
		const response = await getOrderApi(order_id);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const getOrders = createAsyncThunk('order/getOrders', async (_, { rejectWithValue, getState }) => {
	try {
		const state = getState();

		const filter = () => {
			//* Страница
			let filterArray = [`?page=${state.order.orderFilters.page}`];

			//* Тип заявки на ввоз/вывоз
			if (state.order.orderFilters?.order_type) {
				filterArray.push(`&order_type=${state.order.orderFilters.order_type}`);
			}

			//* ID терминала
			if (state.order.orderFilters?.terminal_id) {
				const terminalId = state.terminalPage.terminalList.find(
					(terminal) => terminal.name === state.order.orderFilters?.terminal_id,
				).id;
				filterArray.push(`&terminal_id=${terminalId}`);
			}
			//* ID клиента
			if (state.order.orderFilters?.client_id) {
				const clientId = state.client.clientList.find(
					(client) => client.organization_name === state.order.orderFilters?.client_id,
				).id;
				filterArray.push(`&client_id=${clientId}`);
			}
			//* Дата начала/конца
			if (state.order.orderFilters?.start_date && state.order.orderFilters?.end_date) {
				const dateStringStart = state.order.orderFilters.start_date.toISOString();
				const formattedStartDate = format(dateStringStart, 'yyyy-MM-dd');

				const dateStringEnd = state.order.orderFilters.end_date.toISOString();
				const formattedEndDate = format(dateStringEnd, 'yyyy-MM-dd');

				filterArray.push(`&start_date=${formattedStartDate}&end_date=${formattedEndDate}`);
			}

			return filterArray.join('');
		};
		let response;

		if (state.order.ordersNext && state.order.ordersFetching) {
			response = await getOrdersApi(state.order.ordersNext);
		} else if (state.order.ordersUrl && state.order.ordersFetching) {
			response = await getOrdersApi(filter());
		}
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const getContainersInOrder = createAsyncThunk(
	'order/getContainersInOrder',
	async ({ terminal_id, order_id, client_id, page }, { rejectWithValue, getState }) => {
		const state = getState();
		const firstVariant = state.order.currentOrderContainerList.length;
		const secondVariant = state.order.currentOrderData.completion.total;
		const totalContainers = firstVariant >= secondVariant ? firstVariant : secondVariant;
		try {
			const response = await getContainersInOrderApi(terminal_id, order_id, client_id, page, totalContainers);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

export const postContainer = createAsyncThunk(
	'order/postContainer',
	async ({ terminal_id, requestData, beforeAfter }, { rejectWithValue }) => {
		try {
			console.log(terminal_id, requestData, 'POST CONTAINER THUNK');
			const response = await postContainerApi(terminal_id, requestData);
			return { data: response.data, beforeAfter: beforeAfter };
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

export const deleteContainer = createAsyncThunk('order/deleteContainer', async ({ terminal_id, container_pk }, { rejectWithValue }) => {
	try {
		console.log(terminal_id, 'DELETE CONTAINER THUNK');
		const response = await deleteContainerApi(terminal_id, container_pk);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const postNewOrder = createAsyncThunk('order/postNewOrder', async ({ terminal_id, requestData }, { rejectWithValue }) => {
	try {
		const response = await postNewOrderApi(terminal_id, requestData);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const getOrgContainers = createAsyncThunk('order/getOrgContainers', async ({ terminal_id, org_id, page }, { rejectWithValue }) => {
	try {
		const response = await getContainersOrgApi(terminal_id, org_id, page);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

// Лист пропусков
export const getEntries = createAsyncThunk('order/getEntries', async ({ orderId }, { rejectWithValue, getState }) => {
	const state = getState();

	const filter = () => {
		let filterArray = [`?page=${state.order.passFilters.page}`];
		//Фильтр по статусу пропуска
		if (state.order.passFilters?.pass_status) {
			filterArray.push(`&status=${state.order.passFilters.pass_status}`);
		}
		//Фильтр по дате начало/конц
		if (state.order.passFilters?.start_date && state.order.passFilters?.end_date) {
			const dateStringStart = state.order.passFilters.start_date.toISOString();
			const formattedStartDate = format(dateStringStart, 'yyyy-MM-dd');

			const dateStringEnd = state.order.passFilters.end_date.toISOString();
			const formattedEndDate = format(dateStringEnd, 'yyyy-MM-dd');

			filterArray.push(`&start_date=${formattedStartDate}&end_date=${formattedEndDate}`);
		}
		return filterArray.join('');
	};
	try {
		let response;
		if (state.order.entriesNext && state.order.entriesFetching) {
			response = await getEntriesList(state.order.entriesNext, orderId);
		} else if (state.order.entriesUrl && state.order.entriesFetching) {
			// response = await getEntriesList(state.order.entriesUrl, orderId);
			response = await getEntriesList(filter(), orderId);
		}
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const getOneEntrie = createAsyncThunk('order/getOneEntrie', async ({ terminal_pk, order_pk, pass_pk }, { rejectWithValue }) => {
	try {
		const response = await getOneEntrieApi({ terminal_pk, order_pk, pass_pk });
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const putEntriesList = createAsyncThunk('order/putEntries', async ({ terminal_id, order_id, requestData }, { rejectWithValue }) => {
	try {
		const response = await putEntries(terminal_id, order_id, requestData);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});
export const putLinkContainers = createAsyncThunk('order/putLinkContainers', async ({ terminal_id, requestData }, { rejectWithValue }) => {
	try {
		//! PROBLEM1
		console.log('PROBLEM1 - LINK CONTAINERS', requestData);
		const response = await putLinkContainersApi(terminal_id, requestData);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const putContainer = createAsyncThunk(
	'order/putContainer',
	async ({ terminal_id, container_id, requestData }, { rejectWithValue }) => {
		try {
			const response = await putOneContainerApi(terminal_id, container_id, requestData);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

const orderSlice = createSlice({
	name: 'order',
	initialState: {
		orderList: [],
		ordersCount: 0,

		// Infinite Scroll Orders
		ordersFetching: true,
		ordersUrl: '?page_size=30',
		ordersNext: null,
		orderFilters: {
			page: 1,
			order_type: null,
			// hasNoResponsibleManager: false,
			start_date: '',
			end_date: '',
			responsibleManagers: [],
			client_id: null,
			terminal_id: null,
			// order_status: null
		},

		// clientId: 'client_id',
		// endDate: 'end_date',
		// orderStatus: 'order_status',
		// typeExport: 'order_type',
		// typeImport: 'order_type',
		// page: 'page',
		// startDate: 'start_date',
		// terminalId: 'terminal_id',

		//Пропуска
		oneEntrie: [],
		entriesList: [],
		countEntriesList: 0,

		// Infinite Scroll Entries
		entriesFetching: true,
		entriesUrl: `?page_size=30`,
		entriesNext: null,

		passInfo: {
			passTransporter: '',
			passVehicle: '',
			passVehicleId: '',
			passDriver: '',
			passTrailerNumber: '',
			passSealNumber: '',
			passTerminalResponsible: '',
			passExpectedCheckInTime: '',
		},

		passStatus: ['Пропуск создан', 'Пропуск подтвержден', 'Пропуск активирован', 'Пропуск отменён', 'Контейнер и пропуск удалены'],

		passFilters: {
			page: 1,
			pass_status: null,
			start_date: '',
			end_date: '',
		},

		//Линии контейнеров
		lineList: [],
		//Типы контейнеров
		allTypes: [
			{ id: 1, name: 'DC' },
			{ id: 2, name: 'DV' },
			{ id: 3, name: 'HC' },
			{ id: 4, name: 'PW' },
			{ id: 5, name: 'OT' },
			{ id: 6, name: 'OT HC' },
			{ id: 7, name: 'RF' },
			{ id: 8, name: 'RF HC' },
			{ id: 9, name: 'FR' },
			{ id: 10, name: 'FR HC' },
			{ id: 11, name: 'TC' },
			{ id: 12, name: 'HR' },
		],
		// Размеры контейнеров
		allSizes: [
			{ id: 1, name: '20' },
			{ id: 2, name: '40' },
			{ id: 3, name: '45' },
		],
		//! Только что созданная заявка (возвращаются все данные с POST запроса)
		newOrderData: null,
		//! Создание заявки
		newOrder_type: null,
		newOrder_terminal_id: null,
		newOrder_organization_id: null,
		newOrder_requestData: {},
		containersToAddInOrder: [], //контейнеры для оформления заявки 3й шаг.
		containersToAddInOrderIds: [],

		//! Процесс создания контейнера(ов)
		newContainerData: { is_damaged: false, is_dangerous: false },
		newContainersArray: [],

		postNewOrder_success: false,
		postNewOrder_pending: false,
		postNewOrder_rejected: false,

		getLines_success: false,
		getLines_pending: false,
		getLines_error: false,

		getOrders_success: false,
		getOrders_pending: false,
		getOrders_error: false,

		postContainer_success: false,
		postContainer_pending: false,
		postContainer_error: false,

		deleteContainer_success: false,
		deleteContainer_pending: false,
		deleteContainer_error: false,

		currentOrderData: {},
		getOrderData_success: false,
		getOrderData_pending: false,
		getOrderData_error: false,

		currentOrderContainerList: [],
		currentOrderTemporaryContainerList: [],

		//! Изменение контейнера
		choosenContainerForChangeSaveVariant: {},
		choosenContainerForChangeCancelVariant: {},
		putContainer_success: false,
		putContainer_pending: false,
		putContainer_error: false,

		getContainersInOrder_success: false,
		getContainersInOrder_pending: false,
		getContainersInOrder_error: false,

		organizationContainerList: [],
		getOrgContainers_success: false,
		getOrgContainers_pending: false,
		getOrgContainers_error: false,

		getEntries_success: false,
		getEntries_pending: false,
		getEntries_error: false,

		putLinkContainers_success: false,
		putLinkContainers_pending: false,
		putLinkContainers_error: false,

		addContainerButtonClicked: false,

		putEntriesList_success: false,
		putEntriesList_pending: false,
		putEntriesList_error: false,
		saveLinkContainersButtonActive: false,
	},
	reducers: {
		//Устанавливает имя для активной формы "new" / "assign_container" / "false" / "Индекс карточки автомобиля".
		setActiveForm(state, action) {
			state.activeForm = action.payload;
		},
		setSaveLinkContainersButtonActive(state, action) {
			state.saveLinkContainersButtonActive = action.payload;
		},
		setAddContainerButtonClicked(state, action) {
			state.addContainerButtonClicked = !state.addContainerButtonClicked;
		},
		setNewContainerData(state, action) {
			switch (action.payload.type) {
				case 'local_id':
					state.newContainerData = { ...state.newContainerData, local_id: action.payload.data };
					break;
				case 'line':
					state.newContainerData = { ...state.newContainerData, line: action.payload.data };
					break;
				case 'type':
					state.newContainerData = { ...state.newContainerData, type: action.payload.data };
					break;
				case 'size':
					state.newContainerData = { ...state.newContainerData, size: action.payload.data };
					break;
				case 'container_number':
					state.newContainerData = { ...state.newContainerData, container_number: action.payload.data };
					break;
				case 'is_dangerous':
					state.newContainerData = { ...state.newContainerData, is_dangerous: action.payload.data };
					break;
				case 'is_damaged':
					state.newContainerData = { ...state.newContainerData, is_damaged: action.payload.data };
					break;
				case 'clearAll':
					state.newContainerData = {
						local_id: 1,
						type: '',
						size: '',
						line: '',
						container_number: '',
						is_damaged: false,
						is_dangerous: false,
					};
					break;
				default:
					// state.newContainerData = {};
					break;
			}
		},
		setNewContainersArray(state, action) {
			// if (action.payload && Array.isArray(action.payload) && action.payload.length !== 0) {
			// 	state.newContainersArray = [...state.newContainersArray, action.payload];
			// } else {
			// 	state.newContainersArray = [];
			// }
			if (action.payload.type === 'remove') {
				console.log('remove', action.payload);
				state.newContainersArray = state.newContainersArray.filter((container) => container.index !== action.payload.data);
			}
			if (action.payload.type === 'reset') {
				state.newContainersArray = action.payload.data;
			}
			if (action.payload.type === 'concat') {
				state.newContainersArray = state.newContainersArray.concat(action.payload.data);
			}
			if (action.payload.type === 'update') {
				state.newContainersArray = action.payload.data;
			}
			// .concat(action.payload)
		},
		setNewOrderTerminalId(state, action) {
			state.newOrder_terminal_id = action.payload;
		},
		setNewOrganizationId(state, action) {
			state.newOrder_organization_id = action.payload;
		},
		//! Добавление контейнеров в заявку
		addContainersInOrder(state, action) {
			console.log('Добавление контейнеров в заявку', action.payload);
			if (action.payload.type === 'import_after') {
				state.currentOrderContainerList = state.currentOrderContainerList.concat(action.payload.data);
				// state.currentOrderTemporaryContainerList = state.currentOrderTemporaryContainerList.concat(action.payload.data);
			}
			if (action.payload.type === 'import_before') {
				console.log(action.payload, 'create redux');
				state.containersToAddInOrder = state.containersToAddInOrder.concat(action.payload.data);
			}
			if (action.payload.type === 'export_before') {
				if (Array.isArray(action.payload.data)) {
					state.organizationContainerList = [];
				} else if (!Array.isArray(action.payload.data)) {
					state.organizationContainerList = state.organizationContainerList.filter((el) => el.id !== action.payload.data.id);
				}
				state.containersToAddInOrder = state.containersToAddInOrder.concat(action.payload.data);
			}
			if (action.payload.type === 'export_after') {
				console.log('AFTERCREATE');
				state.organizationContainerList = state.organizationContainerList.filter((el) => el.id !== action.payload.data.id);
				state.currentOrderContainerList = state.currentOrderContainerList.concat(action.payload.data);
			}
		},
		//! Удаление промежуточного массива при добавлении контейнеров в заяву (работает только для существующей заявке на ввлоз при ее редактировании)
		clearCurrentOrderTemporaryContainerList(state, action) {
			state.currentOrderTemporaryContainerList = [];
		},
		removeContainersFromOrder(state, action) {
			console.log(action.payload);
			switch (action.payload.type) {
				case 'export_before':
					state.containersToAddInOrder = state.containersToAddInOrder.filter((el) => el.id !== action.payload.data.id);
					state.organizationContainerList = state.organizationContainerList.concat(action.payload.data);
					break;

				default:
					break;
			}
		},
		//Устанавливает index активной карточки автомобиля из списка. По нему передаются данные в форму
		setIndex(state, action) {
			state.index = action.payload;
		},
		clearTemporaryDataForOrderCreate(state, action) {
			state.postContainer_success = false;
			state.postNewOrder_success = false;
			state.newOrderData = null;
			state.newOrder_type = null;
			state.newOrder_terminal_id = null;
			state.newOrder_organization_id = null;
			state.newOrder_requestData = {};
			state.containersToAddInOrder = [];
			state.containersToAddInOrderIds = [];
			state.currentOrderContainerList = [];
			state.currentOrderTemporaryContainerList = [];
		},
		//Логика удаления одного контейнера из предварительного массива в заявку.
		deleteOneContainerFromCurrentOrderContainerList(state, action) {
			const containerToDelete = action.payload;
			state.containersToAddInOrder = state.containersToAddInOrder.filter(
				(container) => container.container_number !== containerToDelete.container_number,
			);
		},
		deleteOneContainerFromExistingOrder(state, action) {
			const containerToDeleteId = action.payload.data.id;
			if (action.payload.type === 'import') {
				if (containerToDeleteId) {
					state.currentOrderContainerList = state.currentOrderContainerList.filter(
						(container) => container.id !== containerToDeleteId,
					);
				} else {
					state.currentOrderContainerList = state.currentOrderContainerList.filter(
						(container) => container.container_number !== action.payload.data.container_number,
					);
				}
			}
			if (action.payload.type === 'export') {
				console.log('SUDA', action.payload.data);
				state.currentOrderContainerList = state.currentOrderContainerList.filter((el) => el.id !== action.payload.data.id);
				// state.organizationContainerList = state.organizationContainerList?.push(action.payload.data);
				state.organizationContainerList = state.organizationContainerList.concat(action.payload.data);
			}
		},
		updateOrganizationContainerList(state, action) {
			state.organizationContainerList = state.organizationContainerList?.filter((container) => {
				return !state.currentOrderContainerList.map((el) => (el = el.id)).includes(container.id);
			});
		},
		deleteAllContainersFromCurrentOrderContainerList(state, action) {
			if (action.payload.type === 'export_before') {
				console.log(action.payload.data, 'ДАТА');
				state.containersToAddInOrder = [];
				state.organizationContainerList = state.organizationContainerList.concat(action.payload.data);
			}
			state.currentOrderContainerList = []; //изменил на current
		},
		clearCurrentOrderContainerList(state, action) {
			state.currentOrderContainerList = [];
		},

		updateFieldsForCard(state, action) {
			const { requestData } = action.payload;
			requestData?.forEach((requestItem) => {
				const cardToUpdate = state.entriesList?.find((card) => card.id === requestItem.pass_pk);
				console.log(requestItem, '-ITEM', requestData, '-requestDAta', current(cardToUpdate), '-CardToUpdate');
				if (cardToUpdate) {
					if (requestItem.vehicle_driver) {
						cardToUpdate.vehicle_driver = requestItem.vehicle_driver;
					}
					if (requestItem.vehicle_trailer_number) {
						cardToUpdate.vehicle_trailer_number = requestItem.vehicle_trailer_number;
					}
					if (requestItem.seal_number) {
						cardToUpdate.seal_number = requestItem.seal_number;
					}
					if (requestItem.chekin_at) {
						cardToUpdate.chekin_at = requestItem.chekin_at;
					}
					if (requestItem.vehicle) {
						cardToUpdate.vehicle = requestItem.vehicle;
					}
					if (requestItem.vehicleNumber) {
						cardToUpdate.vehicle_display = requestItem.vehicleNumber;
					}
				}
			});
		},

		setOneEntrieAfterSave(state, action) {
			state.oneEntrie = action.payload;
		},

		clearAllOrderData(state, action) {
			state.containersToAddInOrder = [];
			state.currentOrderContainerList = [];
			//! Только что созданная заявка (возвращаются все данные с POST запроса)
			state.newOrderData = null;
			//! Создание заявки
			state.newOrder_type = null;
			state.newOrder_terminal_id = null;
			state.newOrder_organization_id = null;
			state.newOrder_requestData = {};
			state.containersToAddInOrder = []; //контейнеры для оформления заявки 3й шаг.
			//! Процесс создания контейнера(ов)
			state.newContainerData = { is_damaged: false, is_dangerous: false };
			state.newContainersArray = [];
		},
		clearNavigationViaRequestSuccess(state, action) {
			state.postContainer_success = false;
		},
		setOrderFilters(state, action) {
			if (action.payload.field === 'start_date') {
				state.orderFilters.start_date = action.payload.data;
			}
			if (action.payload.field === 'end_date') {
				state.orderFilters.end_date = action.payload.data;
			}
			if (action.payload.field !== 'end_date' && action.payload.field !== 'start_date') {
				console.log('SUDDDA');
				state.orderFilters[action.payload.field] = action.payload.data || null;
			}

			state.orderFilters.page = 1;
			state.ordersUrl = '?page_size=30';
			state.orderList = [];
			state.ordersNext = null;
			state.ordersFetching = true;
		},
		setPassInfo(state, action) {
			state.passInfo[action.payload.field] = action.payload.data;
		},
		//! Очистка полей в модальном окне при выборе нескольких пропусков для изменеия
		clearModalInputsForMultiplePasses(state, action) {
			state.passInfo = {
				passTransporter: '',
				passVehicle: '',
				passVehicleId: '',
				passDriver: '',
				passTrailerNumber: '',
				passSealNumber: '',
				passTerminalResponsible: '',
				passExpectedCheckInTime: '',
			};
		},

		setEntriesFetching(state, action) {
			state.entriesFetching = action.payload;
		},

		setOrdersFetching(state, action) {
			state.ordersFetching = action.payload;
		},
		//Изменение фильтров пропусков
		setPassFilters(state, action) {
			if (action.payload.field === 'status') {
				state.passFilters.pass_status = action.payload.data;
			}

			if (action.payload.field === 'start_date') {
				state.passFilters.start_date = action.payload.data;
			}
			if (action.payload.field === 'end_date') {
				state.passFilters.end_date = action.payload.data;
			}
			if (action.payload.field !== 'end_date' && action.payload.field !== 'start_date') {
				console.log('SUDDDA');
				state.passFilters[action.payload.field] = action.payload.data || null;
			}

			state.passFilters.page = 1;
			state.entriesUrl = '?page_size=30';
			state.entriesList = [];
			state.entriesNext = null;
			state.entriesFetching = true;
		},

		setChoosenContainerForChangeSaveVariant(state, action) {
			switch (action.payload.type) {
				case 'line':
					const lineFromTable = state.lineList.find((line) => line.line_name === action.payload.data);

					state.choosenContainerForChangeSaveVariant = {
						...state.choosenContainerForChangeSaveVariant,
						line: lineFromTable,
					};
					break;
				case 'type':
					const typeFromTable = state.allTypes.find((type) => type.name === action.payload.data);
					state.choosenContainerForChangeSaveVariant = {
						...state.choosenContainerForChangeSaveVariant,
						type: typeFromTable,
					};
					break;
				case 'size':
					const sizeFromTable = state.allSizes.find((size) => size.name === action.payload.data);
					state.choosenContainerForChangeSaveVariant = {
						...state.choosenContainerForChangeSaveVariant,
						size: sizeFromTable,
					};
					break;
				case 'container_number':
					state.choosenContainerForChangeSaveVariant = {
						...state.choosenContainerForChangeSaveVariant,
						container_number: action.payload.data,
					};
					break;
				case 'is_dangerous':
					console.log(action.payload, 'PAYLOAD danger');
					state.choosenContainerForChangeSaveVariant = {
						...state.choosenContainerForChangeSaveVariant,
						is_dangerous: action.payload.data,
					};
					break;
				case 'is_damaged':
					console.log(action.payload, 'PAYLOAD damaged');
					state.choosenContainerForChangeSaveVariant = {
						...state.choosenContainerForChangeSaveVariant,
						is_damaged: action.payload.data,
					};
					break;
				case 'booking':
					state.choosenContainerForChangeSaveVariant = {
						...state.choosenContainerForChangeSaveVariant,
						booking: action.payload.data,
					};
					break;
				case 'clearAll':
					state.newContainerData = {
						type: '',
						size: '',
						line: '',
						container_number: '',
						booking: '',
						is_damaged: false,
						is_dangerous: false,
					};
					break;
				default:
					// state.newContainerData = {};
					break;
			}
		},
		setChoosenContainerForChangeCancelVariant(state, action) {
			state.choosenContainerForChangeCancelVariant = action.payload;

			state.choosenContainerForChangeSaveVariant.id = action.payload.id;
			state.choosenContainerForChangeSaveVariant.container_number = action.payload.container_number;
			state.choosenContainerForChangeSaveVariant.line = { id: action.payload.line, line_name: action.payload.line_display };
			state.choosenContainerForChangeSaveVariant.size = { id: action.payload.size, name: action.payload.size_display };
			state.choosenContainerForChangeSaveVariant.type = { id: action.payload.type, name: action.payload.type_display };
			state.choosenContainerForChangeSaveVariant.is_dangerous = action.payload.is_dangerous;
			state.choosenContainerForChangeSaveVariant.is_damaged = action.payload.is_damaged;
			state.choosenContainerForChangeSaveVariant.booking = action.payload.booking;
		},
		//! Изменение контейнера в списке при создании заявки на ввоз после изменения отдельного контейнера
		setContainersToAddInOrderAfterPut(state, action) {
			console.log(action.payload, 'setContainersToAddInOrderAfterPut');
			console.log(current(state.containersToAddInOrder));

			state.containersToAddInOrder = state.containersToAddInOrder.map((container) => {
				if (container.id === action.payload.id) {
					return {
						...container,
						container_number: action.payload.container_number,
						booking: action.payload.booking,
						is_damaged: action.payload.is_damaged,
						is_dangerous: action.payload.is_dangerous,
						line: action.payload.line.id,
						line_display: action.payload.line.line_name,
						type: action.payload.type.id,
						type_display: action.payload.type.name,
						size: action.payload.size.id,
						size_display: action.payload.size.name,
					};
				} else return container;
			});
		},
		//! Изменение контейнера в списке контейнеров заявки на вывоз
		setCurrentOrderContainerList(state, action) {
			state.currentOrderContainerList = state.currentOrderContainerList.map((container) => {
				if (container.id === action.payload.id) {
					return {
						...container,
						container_number: action.payload.container_number,
						is_damaged: action.payload.is_damaged,
						is_dangerous: action.payload.is_dangerous,
						line: action.payload.line.id,
						line_display: action.payload.line.line_name,
						type: action.payload.type.id,
						type_display: action.payload.type.name,
						size: action.payload.size.id,
						size_display: action.payload.size.name,
					};
				} else return container;
			});
		},
		setOrganizationContainerListAfterPut(state, action) {
			console.log(action.payload, 'setOrganizationContainerListAfterPut');
			console.log(current(state.organizationContainerList), 'setOrganizationContainerListAfterPut222');
			state.organizationContainerList = state.organizationContainerList.map((container) => {
				if (container.id === action.payload.id) {
					console.log('PROOHODITH');
					return {
						...container,
						container_number: action.payload.container_number,
						is_damaged: action.payload.is_damaged,
						is_dangerous: action.payload.is_dangerous,
						line: action.payload.line.id,
						line_display: action.payload.line.line_name,
						type: action.payload.type.id,
						type_display: action.payload.type.name,
						size: action.payload.size.id,
						size_display: action.payload.size.name,
					};
				} else return container;
			});
		},
		setEntryPassFetchingData(state, action) {
			state.entriesList = [];
			state.entriesFetching = true;
			state.entriesUrl = `?page_size=30`;
			state.entriesNext = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(postNewOrder.fulfilled, (state, action) => {
			console.log(action.payload, 'Пейлоуд');
			state.newOrderData = action.payload;
			state.postNewOrder_success = true;
			state.postNewOrder_pending = false;
			state.postNewOrder_rejected = false;
		});
		builder.addCase(postNewOrder.pending, (state, action) => {
			state.postNewOrder_success = false;
			state.postNewOrder_pending = true;
			state.postNewOrder_rejected = false;
		});
		builder.addCase(postNewOrder.rejected, (state, action) => {
			state.postNewOrder_success = false;
			state.postNewOrder_pending = false;
			state.postNewOrder_rejected = action.payload;
		});
		//! Получение линий
		builder.addCase(getLines.fulfilled, (state, action) => {
			state.lineList = action.payload;
			state.getLines_success = true;
			state.getLines_pending = false;
			state.getLines_rejected = false;
		});
		builder.addCase(getLines.pending, (state, action) => {
			state.getLines_success = false;
			state.getLines_pending = true;
			state.getLines_rejected = false;
		});
		builder.addCase(getLines.rejected, (state, action) => {
			state.getLines_success = false;
			state.getLines_pending = false;
			state.getLines_rejected = action.payload;
		});
		//! Добавление контейнера
		//! PROBLEM1
		builder.addCase(postContainer.fulfilled, (state, action) => {
			if (action.payload.beforeAfter === 'before') {
				// state.containersToAddInOrder.map((container) => {
				// 	const id = action.payload.data.find((el) => el.container_number === container.container_number)?.id;
				// 	return (container.id = id);
				// });
				state.containersToAddInOrder = state.containersToAddInOrder.concat(action.payload.data);
			}
			if (action.payload.beforeAfter === 'after') {
				state.currentOrderContainerList = state.currentOrderContainerList.concat(action.payload.data);
				// state.currentOrderContainerList.map((container) => {
				// 	if (!container.hasOwnProperty('id')) {
				// 		const id = action.payload.data.find((el) => el.container_number === container.container_number)?.id;
				// 		container.id = id;
				// 	} else {
				// 		container = container.id;
				// 	}
				// 	return container;
				// });
			}

			state.postContainer_success = true;
			state.postContainer_pending = false;
			state.postContainer_rejected = false;
		});
		builder.addCase(postContainer.pending, (state, action) => {
			state.postContainer_success = false;
			state.postContainer_pending = true;
			state.postContainer_rejected = false;
		});
		builder.addCase(postContainer.rejected, (state, action) => {
			state.postContainer_success = false;
			state.postContainer_pending = false;
			state.postContainer_rejected = action.payload;
		});

		//! Удаление контейнера

		builder.addCase(deleteContainer.fulfilled, (state, action) => {
			state.deleteContainer_success = true;
			state.deleteContainer_pending = false;
			state.deleteContainer_rejected = false;
		});
		builder.addCase(deleteContainer.pending, (state, action) => {
			state.deleteContainer_success = false;
			state.deleteContainer_pending = true;
			state.deleteContainer_rejected = false;
		});
		builder.addCase(deleteContainer.rejected, (state, action) => {
			state.deleteContainer_success = false;
			state.deleteContainer_pending = false;
			state.deleteContainer_rejected = action.payload;
		});

		//! Изменение контейнера
		builder.addCase(putContainer.fulfilled, (state, action) => {
			console.log(action.payload, 'PUT SUCCESS');
			// state.currentOrderContainerList.map(())
			state.putContainer_success = true;
			state.putContainer_pending = false;
			state.putContainer_rejected = false;
		});
		builder.addCase(putContainer.pending, (state, action) => {
			state.putContainer_success = false;
			state.putContainer_pending = true;
			state.putContainer_rejected = false;
		});
		builder.addCase(putContainer.rejected, (state, action) => {
			state.putContainer_success = false;
			state.putContainer_pending = false;
			state.putContainer_rejected = action.payload;
		});
		//! Получения данных по заявке
		builder.addCase(getOrderData.fulfilled, (state, action) => {
			state.currentOrderData = action.payload;
			state.getOrderData_success = true;
			state.getOrderData_pending = false;
			state.getOrderData_rejected = false;
		});
		builder.addCase(getOrderData.pending, (state, action) => {
			state.getOrderData_success = false;
			state.getOrderData_pending = true;
			state.getOrderData_rejected = false;
		});
		builder.addCase(getOrderData.rejected, (state, action) => {
			state.getOrderData_success = false;
			state.getOrderData_pending = false;
			state.getOrderData_rejected = action.payload;
		});
		//! Получения списка заявок
		builder.addCase(getOrders.fulfilled, (state, action) => {
			console.log(action.payload, 'GET ORDERs FULFILLED');
			// state.orderList = action.payload.results;
			state.ordersCount = action.payload.count;

			if (action.payload.next) {
				const startIndex = action.payload.next?.indexOf('?');
				const trimmedUrl = `/${action.payload.next?.substring(startIndex)}`;
				// ${state.clientGroup == 0 ? '' : `&is_manager_confirmed=${state.clientGroup}`}
				state.ordersNext = trimmedUrl;
				state.ordersUrl = '';
			} else {
				state.ordersNext = null;
				state.ordersUrl = '';
			}
			state.ordersFetching = false;
			state.orderList = [...state.orderList, ...action.payload.results];

			state.getOrders_success = true;
			state.getOrders_pending = false;
			state.getOrderData_rejected = false;
		});
		builder.addCase(getOrders.pending, (state, action) => {
			state.getOrders_success = false;
			state.getOrders_pending = true;
			state.getOrderData_rejected = false;
		});
		builder.addCase(getOrders.rejected, (state, action) => {
			state.getOrders_success = false;
			state.getOrders_pending = false;
			state.getOrders_rejected = action.payload;
		});

		//! Получения контейнеров в заявке
		builder.addCase(getContainersInOrder.fulfilled, (state, action) => {
			console.log(action.payload, 'GET КОНТЕЙНЕРС FULFILLED');
			state.currentOrderContainerList = action.payload.results;
			state.getContainersInOrder_success = true;
			state.getContainersInOrder_pending = false;
			state.getContainersInOrder_error = false;
		});
		builder.addCase(getContainersInOrder.pending, (state, action) => {
			state.getContainersInOrder_success = false;
			state.getContainersInOrder_pending = true;
			state.getContainersInOrder_error = false;
		});
		builder.addCase(getContainersInOrder.rejected, (state, action) => {
			state.getContainersInOrder_success = false;
			state.getContainersInOrder_pending = false;
			state.getContainersInOrder_error = action.payload;
		});
		//получение контейнеров конкретного клиента
		builder.addCase(getOrgContainers.fulfilled, (state, action) => {
			state.getOrgContainers_success = true;
			state.getOrgContainers_pending = false;
			state.getOrgContainers_error = false;
			state.organizationContainerList = action.payload.results;
		});
		builder.addCase(getOrgContainers.pending, (state, action) => {
			state.getOrgContainers_success = false;
			state.getOrgContainers_pending = true;
			state.getOrgContainers_error = false;
		});
		builder.addCase(getOrgContainers.rejected, (state, action) => {
			state.getOrgContainers_success = false;
			state.getOrgContainers_pending = false;
			state.getOrgContainers_error = action.payload;
		});

		//! Получение листа пропусков
		builder.addCase(getEntries.fulfilled, (state, action) => {
			state.getEntries_success = true;
			state.getEntries_pending = false;
			state.getEntries_rejected = false;
			// Infinite Scroll
			if (action.payload?.next) {
				const startIndex = action.payload?.next?.indexOf('?');
				const trimmedUrl = `${action.payload?.next?.substring(startIndex)}`;
				state.entriesNext = trimmedUrl;
				state.entriesUrl = '';
			} else {
				state.entriesNext = null;
				state.entriesUrl = '';
			}
			state.entriesFetching = false;
			state.entriesList = [...state.entriesList, ...action.payload?.results];
			state.countEntriesList = action.payload?.count;
		});
		builder.addCase(getEntries.pending, (state, action) => {
			state.getEntries_success = false;
			state.getEntries_pending = true;
			state.getEntries_rejected = false;
		});
		builder.addCase(getEntries.rejected, (state, action) => {
			state.getEntries_success = false;
			state.getEntries_pending = false;
			state.getEntries_rejected = action.payload;
		});

		//! Прикрепление контейнеров к заявке
		builder.addCase(putLinkContainers.fulfilled, (state, action) => {
			state.putLinkContainers_success = true;
			state.putLinkContainers_pending = false;
			state.putLinkContainers_rejected = false;
		});
		builder.addCase(putLinkContainers.pending, (state, action) => {
			state.putLinkContainers_success = false;
			state.putLinkContainers_pending = true;
			state.putLinkContainers_rejected = false;
		});
		builder.addCase(putLinkContainers.rejected, (state, action) => {
			state.putLinkContainers_success = false;
			state.putLinkContainers_pending = false;
			state.putLinkContainers_rejected = action.payload;
		});

		//изменение пропусков
		builder.addCase(putEntriesList.fulfilled, (state, action) => {
			state.putEntriesList_success = true;
			state.putEntriesList_pending = false;
			state.putEntriesList_error = false;
		});
		builder.addCase(putEntriesList.pending, (state, action) => {
			state.putEntriesList_success = false;
			state.putEntriesList_pending = true;
			state.putEntriesList_error = false;
		});
		builder.addCase(putEntriesList.rejected, (state, action) => {
			state.putEntriesList_success = false;
			state.putEntriesList_pending = false;
			state.putEntriesList_error = true;
		});

		//!получение одного пропуска
		builder.addCase(getOneEntrie.fulfilled, (state, action) => {
			state.oneEntrie = action.payload;
			// state.passDriver = action.payload.vehicle_driver;
			console.log(action.payload, 'AGENT');
			state.passInfo.passDriver = action.payload.vehicle_driver;
			state.passInfo.passVehicle = action.payload.vehicle_display;
			state.passInfo.passVehicleId = action.payload.vehicle;
			state.passInfo.passSealNumber = action.payload.seal_number;
			state.passInfo.passTrailerNumber = action.payload.vehicle_trailer_number;
			state.passInfo.passExpectedCheckInTime = action.payload.chekin_at;

			state.getOneEntrie_success = true;
			state.getOneEntrie_pending = false;
			state.getOneEntrie_rejected = false;
		});
		builder.addCase(getOneEntrie.pending, (state, action) => {
			state.getOneEntrie_success = false;
			state.getOneEntrie_pending = true;
			state.getOneEntrie_rejected = false;
		});
		builder.addCase(getOneEntrie.rejected, (state, action) => {
			state.getOneEntrie_success = false;
			state.getOneEntrie_pending = false;
			state.getOneEntrie_rejected = action.payload;
		});
	},
});

export const {
	setCurrentOrderContainerList,
	setOrganizationContainerListAfterPut,
	setContainersToAddInOrderAfterPut,
	setChoosenContainerForChangeCancelVariant,
	setChoosenContainerForChangeSaveVariant,
	updateOrganizationContainerList,
	clearNavigationViaRequestSuccess,
	clearCurrentOrderTemporaryContainerList,
	setAddContainerButtonClicked,
	clearModalInputsForMultiplePasses,
	setPassInfo,
	setOrderFilters,
	clearAllOrderData,
	removeContainersFromOrder,
	setNewContainerData,
	setNewContainersArray,
	deleteOneContainerFromExistingOrder,
	clearCurrentOrderContainerList,
	deleteOneContainerFromCurrentOrderContainerList,
	deleteAllContainersFromCurrentOrderContainerList,
	setActiveForm,
	setIndex,
	setNewOrganizationId,
	setNewOrderTerminalId,
	addContainersInOrder,
	clearTemporaryDataForOrderCreate,
	updateFieldsForCard,
	setOneEntrieAfterSave,
	setPassDriver,
	setEntriesFetching,
	setOrdersFetching,
	setPassFilters,
	setSaveLinkContainersButtonActive,
	setEntryPassFetchingData,
} = orderSlice.actions;
export default orderSlice.reducer;
