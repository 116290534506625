// import { DeleteObjectCommand, GetObjectCommand, S3 } from '@aws-sdk/client-s3';
// import { Upload } from '@aws-sdk/lib-storage';
// // import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
// import { v4 as uuidv4 } from 'uuid';
// import CryptoJS from 'crypto-js';
// import { Buffer } from 'buffer';

// const bucketParams = { Bucket: process.env.REACT_APP_BUCKET_NAME };
// const uploadParams = { Bucket: bucketParams.Bucket, Key: '', Body: '' };
// const getParams = { Bucket: bucketParams.Bucket, Key: '' };
// const deleteParams = { Bucket: bucketParams.Bucket, Key: '' };
// const accessibleDirs = ['contract', 'damages'];

// /**
//  * `S3Manager` class provides a set of methods to interact with an S3-compatible storage service. it allows for
//  * uploading, retrieving, and deleting files. This class is designed to be used in React applications and relies on
//  * environment variables for configuration.
//  */
// class S3Manager {
// 	/**
// 	 * Initializes the `S3Manager` instance.
// 	 * @param dirPath (string): The folder where the file will be stored
// 	 */
// 	constructor(dirPath) {
// 		this.endpoint = 'https://s3.timeweb.com/';

// 		// Check if `dirPath` is in `accessibleDirs`. Else throw error
// 		if (accessibleDirs.includes(dirPath)) {
// 			this.endpoint = this.endpoint + dirPath;
// 		} else {
// 			throw new Error(`${dirPath} not in accessible dirs. Accessible dirs are: 'contract', 'damages'.`);
// 		}

// 		// S3 config
// 		this.s3 = new S3({
// 			credentials: {
// 				accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
// 				secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
// 			},
// 			endpoint: this.endpoint,
// 			s3ForcePathStyle: true,
// 			region: 'ru-1',
// 			apiVersion: 'latest',
// 		});
// 	}

// 	/**
// 	 * Hash the actId in SHA256.
// 	 * @param actId (int): The ID of the act associated with file.
// 	 * @returns {Promise<*>} returns hash in SHA256 (8 digits)
// 	 * @throws {Error} throws error if actId in NaN
// 	 */
// 	async hashCode(actId) {
// 		if (!isNaN(actId)) {
// 			const hash = CryptoJS.SHA256(actId.toString()).toString(CryptoJS.enc.Hex);
// 			return hash.slice(0, 8);
// 		} else {
// 			throw new Error(`${actId} is invalid value`);
// 		}
// 	}

// 	/**
// 	 * Generates a unique file name for a file.
// 	 * @param actId (int): The ID of the act associated with the file.
// 	 * @param name (string): The original name of the file.
// 	 * @returns {Promise<string>} A promise that resolves to the image unique file name in the format `{actIdHash}-{uuid}.{ext}`.
// 	 */
// 	async getUniqueFileName(actId, name) {
// 		const actIdHash = await this.hashCode(actId);
// 		const ext = name.split('.').pop().toLowerCase();
// 		return `${actIdHash}-${uuidv4()}.${ext}`;
// 	}

// 	async formattingImage(file) {
// 		const base64Data = file.data_url.split(',')[1];
// 		const byteCharacters = atob(base64Data);
// 		const byteNumbers = new Array(byteCharacters.length);
// 		for (let i = 0; i < byteCharacters.length; i++) {
// 			byteNumbers[i] = byteCharacters.charCodeAt(i);
// 		}
// 		return new Uint8Array(byteNumbers);
// 	}
// 	/**
// 	 * Uploads a file to the S3-compatible storage.
// 	 * @param actId (int): The ID of the act associated with the file.
// 	 * @param file (File): The file to upload.
// 	 * @returns {Promise<CompleteMultipartUploadCommandOutput>} A promise that resolves to the response from the upload operation.
// 	 * @throws {Error} Throw an error if the upload fails.
// 	 */
// 	async uploadImage({ actId, file }) {
// 		uploadParams.Body = await this.formattingImage(file);
// 		uploadParams.Key = await this.getUniqueFileName(actId, file.name);

// 		try {
// 			const parallelUploads3 = new Upload({
// 				client: this.s3,
// 				params: uploadParams,
// 			});

// 			// parallelUploads3.on можно выпилить, он не особо нужен
// 			// parallelUploads3.on('httpUploadProgress', (progress) => {
// 			//     console.log(progress, 'progress')
// 			// })

// 			const res = await parallelUploads3.done();
// 			// res.key - название файла, res.location - ссылка на файл
// 			// Нужно проверить, отображает ли он его по ссылке
// 			console.log(res.Key, res.Location);
// 			return res;
// 		} catch (error) {
// 			throw new Error(`Error uploading image: ${error.message}`);
// 		}
// 	}

// 	/**
// 	 * Retrieves a file from the S3-compatible storage.
// 	 * @param fileName (string): The name of the file to retrieve.
// 	 * @returns {Promise<*>} A promise that resolves to the image signed url.
// 	 * @throws {Error} Throw an error if the retrieval fails.
// 	 */
// 	async getImage(fileName) {
// 		getParams.Key = fileName;
// 		try {
// 			const command = new GetObjectCommand(getParams);
// 			const res = await this.s3.send(command);
// 			const body = res.Body;
// 			const chucks = [];

// 			for await (const chunk of body) {
// 				chucks.push(chunk);
// 			}
// 			const buffer = Buffer.concat(chucks);
// 			// const blob = new Blob([buffer], {type: res.ContentType})
// 			// return blob
// 			const base64 = buffer.toString('base64');
// 			const ext = fileName.split('.').pop().toLowerCase();
// 			let mimeType;

// 			switch (ext) {
// 				case 'jpg':
// 				case 'jpeg':
// 					mimeType = 'image/jpeg';
// 					break;
// 				case 'png':
// 					mimeType = 'image/png';
// 					break;
// 				case 'gif':
// 					mimeType = 'image/gif';
// 					break;
// 				case 'pdf':
// 					mimeType = 'application/pdf';
// 					break;
// 				case 'xlsx':
// 					mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
// 					break;
// 				case 'docx':
// 					mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
// 				default:
// 					throw new Error(`Unsupported file type: ${ext}`);
// 			}

// 			return `data:${mimeType};base64,${base64}`;
// 			// return await getSignedUrl(this.s3, command, { expiresIn: 7200 });
// 		} catch (error) {
// 			throw new Error(`Error getting image: ${error.message}`);
// 		}
// 	}

// 	/**
// 	 * Deletes a file from the S3-compatible storage.
// 	 * @param fileName (sting): The name of the file to delete.
// 	 * @returns {Promise<void>} Logs a success message to the console if the deletion pass
// 	 * @throws {Error} Throw an error if the deletion fails.
// 	 */
// 	async deleteImage(fileName) {
// 		deleteParams.Key = fileName;
// 		try {
// 			await this.s3.send(new DeleteObjectCommand(deleteParams));
// 			console.log(`Image ${fileName} deleted successfully`);
// 		} catch (error) {
// 			throw new Error(`Error deleting image: ${error.message}`);
// 		}
// 	}
// }

// export default S3Manager;

//!
import { DeleteObjectCommand, GetObjectCommand, S3 } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';

// Параметры S3 Bucket
const bucketParams = { Bucket: process.env.REACT_APP_BUCKET_NAME };
const uploadParams = { Bucket: bucketParams.Bucket, Key: '', Body: '' };
const getParams = { Bucket: bucketParams.Bucket, Key: '' };
const deleteParams = { Bucket: bucketParams.Bucket, Key: '' };
const accessibleDirs = ['contract', 'damages', 'errors'];

class S3Manager {
	constructor(dirPath) {
		this.endpoint = 'https://s3.timeweb.com/';

		if (accessibleDirs.includes(dirPath)) {
			this.endpoint = this.endpoint + dirPath;
		} else {
			throw new Error(`${dirPath} not in accessible dirs. Accessible dirs are: 'contract', 'damages'.`);
		}

		this.s3 = new S3({
			credentials: {
				accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
				secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
			},
			endpoint: this.endpoint,
			s3ForcePathStyle: true,
			region: 'ru-1',
			apiVersion: 'latest',
		});
	}

	async hashCode(actId) {
		if (!isNaN(actId)) {
			const hash = CryptoJS.SHA256(actId.toString()).toString(CryptoJS.enc.Hex);
			return hash.slice(0, 8);
		} else {
			throw new Error(`${actId} is invalid value`);
		}
	}

	async getUniqueFileName(actId, name) {
		const actIdHash = await this.hashCode(actId);
		const ext = name.split('.').pop().toLowerCase();
		return `${actIdHash}-${uuidv4()}.${ext}`;
	}

	async formattingImage(file) {
		const base64Data = file.data_url.split(',')[1];
		const byteCharacters = atob(base64Data);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		return new Uint8Array(byteNumbers);
	}

	async uploadImage({ actId, file }) {
		uploadParams.Body = await this.formattingImage(file);
		uploadParams.Key = await this.getUniqueFileName(actId, file.name);

		try {
			const parallelUploads3 = new Upload({
				client: this.s3,
				params: uploadParams,
			});

			const res = await parallelUploads3.done();
			console.log(res.Key, res.Location);
			return res;
		} catch (error) {
			throw new Error(`Error uploading image: ${error.message}`);
		}
	}

	async getImage(fileName) {
		getParams.Key = fileName;
		try {
			const command = new GetObjectCommand(getParams);
			const res = await this.s3.send(command);

			const streamToBuffer = async (stream) => {
				const reader = stream.getReader();
				const chunks = [];
				let done, value;

				while ((({ done, value } = await reader.read()), !done)) {
					chunks.push(value);
				}

				return Buffer.concat(chunks);
			};

			const buffer = await streamToBuffer(res.Body);
			const base64 = buffer.toString('base64');
			const ext = fileName.split('.').pop().toLowerCase();
			let mimeType;

			switch (ext) {
				case 'jpg':
				case 'jpeg':
					mimeType = 'image/jpeg';
					break;
				case 'png':
					mimeType = 'image/png';
					break;
				case 'gif':
					mimeType = 'image/gif';
					break;
				case 'pdf':
					mimeType = 'application/pdf';
					break;
				case 'xlsx':
					mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
					break;
				case 'docx':
					mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
					break;
				default:
					throw new Error(`Unsupported file type: ${ext}`);
			}

			return `data:${mimeType};base64,${base64}`;
		} catch (error) {
			throw new Error(`Error getting image: ${error.message}`);
		}
	}

	async deleteImage(fileName) {
		deleteParams.Key = fileName;
		try {
			await this.s3.send(new DeleteObjectCommand(deleteParams));
			console.log(`Image ${fileName} deleted successfully`);
		} catch (error) {
			throw new Error(`Error deleting image: ${error.message}`);
		}
	}
}

export default S3Manager;
