import styles from "./ContactManager.module.scss";
import {
  EmailIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";
import copy from 'copy-to-clipboard';
import { IconArrowLeft } from "../../../../../../components/Icons";
import UniversalButton from "../../../../../../components/Buttons/UniversalButton/UniversalButton";

function ContactManager({ setPage }) {

  const handlerCopyTel = () => {
    copy("+79999999999");
  }
  const handleClickBack = () => {
    setPage(2);
  };


  return (
    <>
      <div className={styles.navBar}>
        <UniversalButton icon={<IconArrowLeft size={"largeS"} color={"black"} />} onClick={handleClickBack} size={"large"} typeButton={"button"} />
      </div>
      <div className={styles.block}>
        <div className={styles.block__descr}>
          <h5>Свяжитесь с менеджером по номеру телефона</h5>
          <div className={styles.block__btns}>
            <UniversalButton label={"Скопировать"} onClick={handlerCopyTel} color={"primary"} size={"large"} typeButton={"button"} />
            <a href="tel:+79999999999" className={styles.block__btnsLink}>
              <UniversalButton label={"Позвонить"} typeButton={"button"} size={"large"} color={"primary"} type={"with-border"} />
            </a>
          </div>
        </div>
        <div className={styles.block__links}>
          <p>Или другим удобным способом</p>
          <div className={styles.block__linksList}>
            <a href="tg://resolve?domain=username"><TelegramIcon size={44} round={true} /></a>
            <a href="https://wa.me/<PHONE>"><WhatsappIcon size={44} round={true} /></a>
            <a href="mailto:mail@example.com"><EmailIcon size={44} round={true} /></a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactManager; 