import { useState } from 'react';
import { UniversalButton } from '../../../../components';
import styles from './Hero.module.scss';

function Hero() {
	const [moreInfo, setMoreInfo] = useState(false);
	return (
		<div className={styles.hero}>
			<h1 className={styles.hero__title}>Скорость. Слаженность. Стабильность</h1>
			<p className={styles.hero__sub_title}>ТЕТРИС – Сеть контейнерных терминалов</p>
			<ul className={styles.hero__descr}>
				<li>
					<p>
						ТЕТРИС – оператор терминальной обработки крупнотоннажных груженых и порожних контейнеров. Компания предоставляет
						свои услуги в Москве, Московской области и Краснодарском крае. Общая вместимость всех терминалов составляет более 50
						000 TEU. 
					</p>
				</li>
				{moreInfo && (
					<>
						<li>
							<p>Собственный парк спецтехники позволяет эффективно и оперативно выполнять погрузочно-разгрузочные работы.</p>
						</li>
						<li>
							<p>
								Дополнительно компания предоставляет услуги по накоплению и переброске контейнеров для отправки по Ж/Д, а
								также растарке, затарке, хранению грузов.
							</p>
						</li>
						<li>
							<p>Все терминалы оборудованы зоной ремонта для проведения техобслуживания и ремонта контейнеров на месте.</p>
						</li>
						<li>
							<p>
								В составе депо имеется ремонтная зона, которая позволяет оперативно устранять дефекты Вашего оборудования.
							</p>
						</li>
					</>
				)}
			</ul>
			<UniversalButton
				onClick={() => setMoreInfo(!moreInfo)}
				label={moreInfo ? 'Скрыть' : 'Узнать больше'}
				color={'primary'}
				size={'medium'}
				typeButton={'button'}
			/>
		</div>
	);
}

export default Hero;
