import { FILTER_TYPES } from '../../config/filter';

export const getInitialState = (parameters) => {
	const result = {};
	parameters.forEach((filter) => {
		result[filter.name] = filter.type === FILTER_TYPES.CHECKBOX ? [] : null;
	});
	return result;
};

export const getElementsByFilterName = (parameters, name) => {
	const filter = parameters.find((filter) => filter.name === name);
	return filter ? filter.elements : [];
};

export const buildQueryString = (params) => {
	const queryString = Object.keys(params)
		.map((key) => {
			const value = params[key];
			// Если значение - массив, проверяем, что оно не пустое, и создаем отдельную строку для каждого элемента
			if (Array.isArray(value)) {
				if (value.length === 0) {
					return null; // Пропускаем пустые массивы
				}
				return value.map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&');
			}
			// Если значение не массив, просто добавляем его
			return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
		})
		.filter(Boolean) // Убираем null значения
		.join('&');
	return queryString;
};
