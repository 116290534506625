import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOneUser } from '../../api/erp/getOneUser';
import { delUser } from '../../api/erp/deleteUser';
import { putUser } from '../../api/erp/putUser';
import { postNewClient } from '../../api/erp/postClient';

const confirmationWindowSlice = createSlice({
	name: 'confirmationWindow',
	initialState: {
		showConfirmationWindow: false,
		acceptAction: false,
	},

	reducers: {
		// getOneUserSuccess(state, action) {
		// },
		// postClientSuccess(state, action){
		// },
		// deleteUserSuccess(state, action){
		// },
		// putUserSuccess(state, action){
		// }
	},
});

export const {} = confirmationWindowSlice.actions;
export default confirmationWindowSlice.reducer;
