import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconPlusCircle({ size, onClick, color, className }) {
  return (
    <div onClick={onClick} className={`${styles.icon} ${className}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 21.5 21.5">
        <g>
          <path fill={IconColor(color)} d="M12.75,8a.75.75,0,0,0-1.5,0Zm-1.5,8a.75.75,0,0,0,1.5,0ZM8,11.25a.75.75,0,0,0,0,1.5Zm8,1.5a.75.75,0,0,0,0-1.5ZM21.25,12A9.25,9.25,0,0,1,12,21.25v1.5A10.75,10.75,0,0,0,22.75,12ZM12,21.25A9.25,9.25,0,0,1,2.75,12H1.25A10.75,10.75,0,0,0,12,22.75ZM2.75,12A9.25,9.25,0,0,1,12,2.75V1.25A10.75,10.75,0,0,0,1.25,12ZM12,2.75A9.25,9.25,0,0,1,21.25,12h1.5A10.75,10.75,0,0,0,12,1.25ZM11.25,8v8h1.5V8ZM8,12.75h8v-1.5H8Z" transform="translate(-1.25 -1.25)" />
        </g>
      </svg>
    </div>
  );
}

export default IconPlusCircle;



