import axios from 'axios';

const handleLogOut = async () => {
    const parsedUrl = new URL(window.location.href);

    try {
        await axios.post('/api/accounts/logout/', null, {
            withCredentials: true,
            xsrfHeaderName: 'X-CSRFToken',
            xsrfCookieName: 'csrftoken',
        });


        if (parsedUrl.pathname === "/accountEmpl/main") {
            window.location.href = "/authEmpl"
        } else if (parsedUrl.pathname === "/accountClient/main") {
            window.location.href = "/authClient"
        }
    } catch (error) {
        
    }
};

export { handleLogOut };
