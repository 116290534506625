import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material';
import CardComponent from '../../../../newComponents/CardComponent/CardComponent';
import { account, accountGridItemThird } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getInitials } from '../../../../helpers/formatText';
import Preloader from '../../../../components/Preloader/Preloader';
import { useLazyGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import Filter from '../../../../newComponents/Filter/Filter.jsx';
import { useNavigate } from 'react-router-dom';

function AccountEmplTerminals() {
	const [trigger, { data, isLoading }] = useLazyGetTerminalsListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const navigate = useNavigate();

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ page: 1, page_size: 20 });
	}, []);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data) {
			setDataCards((prevData) => [...prevData, ...data]);
			setFetching(false);
		}
	}, [data]);

	const handleCreateTerminalClick = () => {
		navigate('/accountEmpl/createTerminal');
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Терминалы'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium" onClick={handleCreateTerminalClick}>
						Добавить терминал
					</Button>,
				]}
			/>

			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					<div className={styles.account__filter}>
						<Filter />
					</div>
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemThird(isDesktop)} key={index}>
									<CardComponent
										avatar={getInitials(card?.name)}
										title={card?.name}
										leftAdditionalInfo={[{ name: 'Адрес:', text: `${card?.address ? card?.address : '-'}` }]}
									/>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplTerminals;
