import React, { useEffect, useState } from 'react';
import styles from './componentsStyles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { damageTypeToName } from '../Sides/helpers/damageTypeToName';
import { setDetailsInsteadDamages } from '../../../../../../../../../../store/slices/containerAcceptanceSlice';

function UniversalDetailBlock({ type, sideIndex, sideName, divider, part, choosen }) {
	const { damagedDetails } = useSelector((state) => state.containerAccept);
	const dispatch = useDispatch();

	const isActive = damagedDetails.some((block) => block === part);

	useEffect(() => {
		if (isActive) {
			dispatch(
				setDetailsInsteadDamages({
					sideIndex,
					sideName,
					type,
					divider: '-',
					part,
					active: true,
					damageName: damageTypeToName(type),
				}),
			);
		}
		// else {
		// 	// dispatch(clearChoosenDamageForChoosingDetail());
		// 	dispatch(
		// 		setDetailsInsteadDamages({
		// 			sideIndex,
		// 			sideName,
		// 			type,
		// 			divider: '-',
		// 			part,
		// 			active: false,
		// 			damageName: damageTypeToName(type),
		// 		}),
		// 	);
		// }
	}, [isActive, sideIndex, sideName, type, divider, part, dispatch]);

	return (
		<div
			className={`${styles.mainStyle} ${styles[type]} ${isActive && (type !== 'sealL' || type !== 'sealR') ? styles.active : ''} ${choosen && !isActive && (type !== 'sealL' || type !== 'sealR') ? styles.choosen : ''} ${(choosen && !isActive && type === 'sealL') || (choosen && !isActive && type === 'sealR') ? styles.choosen_seal : ''} ${isActive && (type === 'sealR' || type === 'sealL') ? styles.active_seal : ''}`}
		>
			{choosen ? choosen : null}
		</div>
	);
}

export default UniversalDetailBlock;
