import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTerminalListApi } from '../../api/terminal/getTerminalList';
import { getTerminalDetailsApi } from '../../api/terminal/getTerminalDetails';
import { getTerminalContainersApi } from '../../api/containers/getTerminalContainers';
import { getTerminalUsersApi } from '../../api/terminal/getTerminalUsers';
import { getTerminalClientsApi } from '../../api/terminal/getTerminalClients';
import { postTerminal } from '../../api/terminal/postTerminal';
import { putTerminal } from '../../api/terminal/putTerminal';
import { getOrganizations } from '../../api/erp/getOrganizations';

export const getTerminalList = createAsyncThunk('terminalPage/getTerminalList', async (_, { rejectWithValue }) => {
	try {
		const response = await getTerminalListApi();
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса terminalPage/getTerminalList. Не удалось получить данные');
		}
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

export const getTerminalDetails = createAsyncThunk(
	'terminalPage/getTerminalDetails',
	async ({ requestData }, { rejectWithValue }) => {
		try {
			const response = await getTerminalDetailsApi(requestData);
			if (!response.statusText === 'OK') {
				throw new Error('Ошибка запроса terminalPage/getTerminalDetails. Не удалось получить данные');
			}
			return response;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);
export const getTerminalContainers = createAsyncThunk(
	'terminalPage/getTerminalContainers',
	async ({ terminal_id }, { rejectWithValue }) => {
		try {
			const response = await getTerminalContainersApi(terminal_id);
			if (!response.statusText === 'OK') {
				throw new Error('Ошибка запроса terminalPage/getTerminalContainers. Не удалось получить данные');
			}
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

export const getTerminalUsers = createAsyncThunk(
	'terminalPage/getTerminalUsers',
	async ({ terminal_id }, { rejectWithValue }) => {
		try {
			const response = await getTerminalUsersApi(terminal_id);
			if (!response.statusText === 'OK') {
				throw new Error('Ошибка запроса terminalPage/getTerminalUsers. Не удалось получить данные');
			}
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

export const getTerminalClients = createAsyncThunk(
	'terminalPage/getTerminalClients',
	async ({ terminal_id }, { rejectWithValue }) => {
		try {
			// const response = await getTerminalClientsApi(terminal_id);
			const response = await getOrganizations(terminal_id);
			if (!response.statusText === 'OK') {
				throw new Error('Ошибка запроса terminalPage/getTerminalClients. Не удалось получить данные');
			}
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

export const postTerminalData = createAsyncThunk(
	'terminal/postTerminalData',
	async ({ requestData }, { rejectWithValue }) => {
		try {
			const response = await postTerminal(requestData);
			if (!response.statusText === 'OK') {
				throw new Error('Ошибка запроса terminalPage/getTerminalClients. Не удалось получить данные');
			}
			return response.data;
		} catch (error) {
			return rejectWithValue({ errorMessage: error.message });
		}
	},
);

export const putTerminalData = createAsyncThunk(
	'terminal/putTerminalData',
	async ({ id, requestData }, { rejectWithValue }) => {
		try {
			const response = await putTerminal(id, requestData);
			if (!response.statusText === 'OK') {
				throw new Error('Ошибка при обновлении данных терминала');
			}
			return response.data;
		} catch (error) {
			return rejectWithValue({ errorMessage: error.message });
		}
	},
);

const terminalPageSlice = createSlice({
	name: 'terminalPage',
	initialState: {
		// terminalList
		getTerminalList_success: false,
		getTerminalList_pending: false,
		getTerminalList_error: false,
		terminalList: [],
		currentTerminalId: 1,
		currentTerminalData: {},
		currentTerminalDataType: '0',

		//terminalUsers
		getTerminalUsers_success: false,
		getTerminalUsers_pending: false,
		getTerminalUsers_error: false,
		terminalUserList: [],
		userModalActive: false,
		userRightsModalActive: false,
		currentUserIdForModal: null,

		//terminalClients
		getTerminalClients_success: false,
		getTerminalClients_pending: false,
		getTerminalClients_error: false,
		terminalClientList: [],
		clientModalActive: false,

		//terminalContainers
		getTerminalContainers_success: false,
		getTerminalContainers_pending: false,
		getTerminalContainers_error: false,
		terminalContainerList: [],

		//Компонент для отрисовки
		currentJsxComponent: '',

		//Terminal Data
		terminalData: null,
		postTerminalData_success: false,
		postTerminalData_loading: false,
		postTerminalData_error: false,
		putTerminalData_success: false,
		putTerminalData_loading: false,
		putTerminalData_error: false,
	},
	reducers: {
		setCurrentTerminal(state, action) {
			state.currentTerminalId = action.payload;
			state.currentTerminalData = state.terminalList.find((el) => el.id === Number(action.payload));
		},
		setCurrentTerminalDataType(state, action) {
			state.currentTerminalDataType = action.payload;
		},
		setCurrentJsxComponent(state, action) {
			state.currentJsxComponent = action.payload;
		},
		setTerminalUsersAfterDelete(state, action) {
			state.terminalUserList = state.terminalUserList.filter((user) => user.id !== action.payload.user_id);
		},
		setTerminalUsersAfterPut(state, action) {
			state.terminalUserList.forEach((item) => {
				if (item.id == action.payload.user_id) {
					item.username = action.payload.updatedUser?.username;
					item.password = action.payload.updatedUser?.password;
					item.permissions = action.payload.updatedUser?.permissions;
					// TODO появится бэк - сделать терминалы
					// item.terminal
					item.full_name = action.payload.updatedUser?.full_name;
					item.group = action.payload.updatedUser?.group;
				}
			});
		},
		setTerminalClientsAfterPut(state, action) {
			state.terminalClientList.forEach((item) => {
				if (item.id == action.payload.user_id) {
					item.contact_email = action.payload.updatedUser?.contact_email;
					item.password = action.payload.updatedUser?.password;
					item.organization_name = action.payload.updatedUser?.organization_name;
					item.organization_address = action.payload.updatedUser?.organization_address;
					item.organization_number = action.payload.updatedUser?.contract_number;
				}
			});
		},
		setTerminalDataCreate(state, action) {
			console.log(action.payload, '198');
			state.terminalData = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(getTerminalList.fulfilled, (state, action) => {
				state.getTerminalList_success = true;
				state.getTerminalList_pending = false;
				state.getTerminalList_error = false;

				state.terminalList = action.payload;
				state.currentTerminalId = action.payload[0].id;
				state.currentTerminalData = action.payload[0];
			})
			.addCase(getTerminalList.pending, (state, action) => {
				state.getTerminalList_success = false;
				state.getTerminalList_pending = true;
				state.getTerminalList_error = false;
			})
			.addCase(getTerminalList.rejected, (state, action) => {
				state.getTerminalList_success = false;
				state.getTerminalList_pending = false;
				state.getTerminalList_error = action.payload.response.data;
			});
		builder
			.addCase(getTerminalUsers.fulfilled, (state, action) => {
				state.getTerminalUsers_success = true;
				state.getTerminalUsers_pending = false;
				state.getTerminalUsers_error = false;
				state.terminalUserList = action.payload.results;
			})
			.addCase(getTerminalUsers.pending, (state, action) => {
				state.getTerminalUsers_success = false;
				state.getTerminalUsers_pending = true;
				state.getTerminalUsers_error = false;
			})
			.addCase(getTerminalUsers.rejected, (state, action) => {
				state.getTerminalUsers_success = false;
				state.getTerminalUsers_pending = false;
				state.getTerminalUsers_error = action.payload.response.data;
			});
		builder
			.addCase(getTerminalContainers.fulfilled, (state, action) => {
				state.getTerminalContainers_success = true;
				state.getTerminalContainers_pending = false;
				state.getTerminalContainers_error = false;
				state.terminalContainerList = action.payload.results;
			})
			.addCase(getTerminalContainers.pending, (state, action) => {
				state.getTerminalContainers_success = false;
				state.getTerminalContainers_pending = true;
				state.getTerminalContainers_error = false;
			})
			.addCase(getTerminalContainers.rejected, (state, action) => {
				state.getTerminalContainers_success = false;
				state.getTerminalContainers_pending = false;
				state.getTerminalContainers_error = action.payload.response.data;
			});

		builder
			.addCase(getTerminalClients.fulfilled, (state, action) => {
				state.getTerminalClients_success = true;
				state.getTerminalClients_pending = false;
				state.getTerminalClients_error = false;
				state.terminalClientList = action.payload.results;
			})
			.addCase(getTerminalClients.pending, (state, action) => {
				state.getTerminalClients_success = false;
				state.getTerminalClients_pending = true;
				state.getTerminalClients_error = false;
			})
			.addCase(getTerminalClients.rejected, (state, action) => {
				state.getTerminalClients_success = false;
				state.getTerminalClients_pending = false;
				state.getTerminalClients_error = action.payload.response.data;
			});

		//new
		builder
			.addCase(postTerminalData.pending, (state) => {
				state.postTerminalData_success = false;
				state.postTerminalData_loading = true;
				state.postTerminalData_error = false;
			})
			.addCase(postTerminalData.fulfilled, (state, action) => {
				state.terminalData = action.payload;
				state.postTerminalData_success = true;
				state.postTerminalData_loading = false;
				state.postTerminalData_error = false;
			})
			.addCase(postTerminalData.rejected, (state, action) => {
				state.postTerminalData_success = false;
				state.postTerminalData_loading = false;
				state.postTerminalData_error = action.payload.errorMessage;
			})
			.addCase(putTerminalData.pending, (state) => {
				state.putTerminalData_success = false;
				state.putTerminalData_loading = true;
				state.putTerminalData_error = false;
			})
			.addCase(putTerminalData.fulfilled, (state, action) => {
				state.terminalData = action.payload;
				state.putTerminalData_success = true;
				state.putTerminalData_loading = false;
				state.putTerminalData_error = false;
				// const updatedTerminal = action.payload;
				state.terminalList = state.terminalList.map((terminal) =>
					terminal.id === action.payload.id ? action.payload : terminal,
				);
			})
			.addCase(putTerminalData.rejected, (state, action) => {
				state.putTerminalData_success = false;
				state.putTerminalData_loading = false;
				state.putTerminalData_error = action.payload.errorMessage;
			});
	},
});

export const {
	setTerminalClientsAfterPut,
	setTerminalUsersAfterDelete,
	setCurrentTerminal,
	setCurrentTerminalDataType,
	setCurrentJsxComponent,
	setTerminalUsersAfterPut,
	setTerminalDataCreate,
} = terminalPageSlice.actions;
export default terminalPageSlice.reducer;
