import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconDamageCont({ onClick, size, color }) {
	return (
		<div className={styles.icon} onClick={onClick}>
			{/* <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 16 16" fill="none">
				<path
					d="M12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645C12.1583 3.45118 11.8417 3.45118 11.6464 3.64645L12.3536 4.35355ZM3.64645 11.6464C3.45118 11.8417 3.45118 12.1583 3.64645 12.3536C3.84171 12.5488 4.15829 12.5488 4.35355 12.3536L3.64645 11.6464ZM4.35355 3.64645C4.15829 3.45118 3.84171 3.45118 3.64645 3.64645C3.45118 3.84171 3.45118 4.15829 3.64645 4.35355L4.35355 3.64645ZM11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L11.6464 12.3536ZM11.6464 3.64645L7.64645 7.64645L8.35355 8.35355L12.3536 4.35355L11.6464 3.64645ZM7.64645 7.64645L3.64645 11.6464L4.35355 12.3536L8.35355 8.35355L7.64645 7.64645ZM3.64645 4.35355L7.64645 8.35355L8.35355 7.64645L4.35355 3.64645L3.64645 4.35355ZM7.64645 8.35355L11.6464 12.3536L12.3536 11.6464L8.35355 7.64645L7.64645 8.35355Z"
					fill={IconColor(color)}
				/>
			</svg> */}
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_5324_64408)">
					<path
						d="M6.95484 19.9888C5.02175 19.8136 4 18.6911 4 16.7427C4 15.1498 4.74309 14.396 5.34019 13.7903C5.8485 13.2747 6.25 12.8674 6.25 11.9954C6.25 10.8092 4 10.091 4 8.24756C4 7.73152 4.15037 7.32206 4.44697 7.03057C5.03472 6.45297 6.01425 6.46949 7.25425 6.49032C8.39609 6.50956 8.57775 6.62084 9.79938 5.33302C10.4211 4.67767 11.0639 4 12 4C13.808 4 15.0333 5.97674 14.7469 8.55261C14.6829 9.12856 14.5725 9.65363 14.4751 10.1169C14.1441 11.6913 14.1627 12.0207 15.1213 12.2602C16.3279 12.5617 20 13.4792 20 16.9925C20 17.4581 19.8233 18.1756 19.3275 18.7933C18.889 19.3396 18.0425 19.9908 16.5 19.9908C14.2307 19.9908 13.266 18.253 12.0194 18.1024C11.6695 18.0602 11.0853 18.0956 10.0548 18.8878C9.21431 19.534 8.02919 20.0862 6.95484 19.9888ZM6.62403 7.48263C5.23481 7.48263 4.99997 7.66034 4.99997 8.24756C4.99997 9.54616 7.24997 10.2367 7.24997 11.9954C7.24997 13.277 6.61378 13.9224 6.05247 14.4917C5.48772 15.0646 4.99997 15.5594 4.99997 16.7427C4.99997 18.1711 5.63072 18.8653 7.04513 18.9934C8.09406 19.0884 9.22838 18.2622 9.44509 18.0956C10.4504 17.3227 11.3067 17.0097 12.1393 17.1102C13.783 17.3087 14.5784 18.9914 16.5 18.9914C17.42 18.9914 18.1089 18.7143 18.5475 18.1679C18.9047 17.7229 19 17.2197 19 16.9925C19 14.5428 16.6479 13.6718 14.8788 13.2298C13.9881 13.0072 13.4808 12.5638 13.3279 11.8742C13.1275 10.9705 13.5961 9.8542 13.7531 8.4422C13.9547 6.62896 13.2443 4.99943 12 4.99943C11.4938 4.99943 11.0449 5.47269 10.5251 6.02065C8.86619 7.7694 8.46825 7.48263 6.62403 7.48263Z"
						fill="#8E8E93"
					/>
					<path
						d="M18.5732 8.6665C17.7601 8.6665 16.8387 8.18505 16.4503 7.64135C15.3537 6.10645 16.4466 4.6665 17.7339 4.6665C19.2974 4.6665 20 5.71373 20 7.20409C20 8.08082 19.4705 8.6665 18.5732 8.6665ZM17.7339 5.51237C17.4525 5.51237 17.1642 5.68532 17.0166 5.94275C16.8183 6.28842 16.8851 6.72501 17.2045 7.17213C17.4702 7.54405 18.3145 7.92743 18.7817 7.79535C18.867 7.77127 19.0935 7.70724 19.0935 7.20406C19.0935 5.68775 18.3175 5.51237 17.7339 5.51237Z"
						fill="#8E8E93"
					/>
				</g>
				<defs>
					<clipPath id="clip0_5324_64408">
						<rect width="16" height="16" fill="white" transform="translate(4 4)" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
}

export default IconDamageCont;
