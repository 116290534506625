import {
	IconContainer,
	IconHome,
	IconTable,
	IconUsers,
	IconContainerCar,
	IconEmpls,
	IconFileImg,
	IconPlusCircle,
	IconTerminals,
	IconUsers2,
} from '../../../../../components/Icons';

export const menuConfigEmpl = [
	{ path: 'main', label: 'Главная', icon: <IconHome color={'white'} size={'medium'} /> },
	{ path: 'summary', label: 'Отчетности', icon: <IconHome color={'white'} size={'medium'} /> },
	{ path: 'containers', label: 'Контейнеры', icon: <IconContainer color={'white'} size={'medium'} /> },
	{ path: 'cargos', label: 'Грузы', icon: <IconContainer color={'white'} size={'medium'} /> },
	{ path: 'orders', label: 'Заявки', icon: <IconFileImg color={'white'} size={'medium'} /> },
	{
		label: 'Пропуска',
		icon: <IconUsers2 color={'white'} size={'medium'} />,
		subMenu: [
			{ path: 'entries', label: 'Пропуска', icon: <IconTable color={'white'} size={'medium'} /> },

			{ path: 'historyEntries', label: 'История въездов', icon: <IconEmpls color={'white'} size={'medium'} /> },
		],
	},
	{ path: 'inspection', label: 'Осмотр', icon: <IconFileImg color={'white'} size={'medium'} /> },
	{
		label: 'Услуги',
		icon: <IconUsers2 color={'white'} size={'medium'} />,
		subMenu: [
			{ path: 'storage', label: 'Хранение', icon: <IconUsers color={'white'} size={'medium'} /> },

			{ path: 'repair', label: 'Ремонт', icon: <IconEmpls color={'white'} size={'medium'} /> },
			{
				path: 'repack',
				label: 'Перетарка',
				icon: <IconUsers color={'white'} size={'medium'} />,
			},
			{ path: 'repairTasks', label: 'Тарифы на ремонт', icon: <IconUsers color={'white'} size={'medium'} /> },
			{ path: 'tariffs', label: 'Тарифы и ставки', icon: <IconUsers color={'white'} size={'medium'} /> },
		],
	},
	{ path: 'clients', label: 'Клиенты', icon: <IconUsers color={'white'} size={'medium'} /> },

	{
		label: 'Перевозчики',
		icon: <IconUsers color={'white'} size={'medium'} />,
		subMenu: [
			{ path: 'transporters', label: 'Перевозчики', icon: <IconUsers color={'white'} size={'medium'} /> },
			{
				path: 'vehicles',
				label: 'Автомобили',
				icon: <IconUsers color={'white'} size={'medium'} />,
			},
		],
	},
	{
		label: 'Пользователи',
		icon: <IconUsers2 color={'white'} size={'medium'} />,
		subMenu: [
			{ path: 'empls', label: 'Сотрудники', icon: <IconEmpls color={'white'} size={'medium'} /> },
			{
				path: 'createJob',
				label: 'Должности',
				icon: <IconUsers color={'white'} size={'medium'} />,
			},
			{ path: 'tableLogs', label: 'История действий', icon: <IconUsers color={'white'} size={'medium'} /> },
		],
	},
	{
		label: 'Настройки',
		icon: <IconUsers2 color={'white'} size={'medium'} />,
		subMenu: [
			// { path: 'company', label: 'Предприятие', icon: <IconEmpls color={'white'} size={'medium'} /> },
			{
				path: 'terminals',
				label: 'Терминалы',
				icon: <IconTerminals color={'white'} size={'medium'} />,
			},
		],
	},
];

export const menuConfigClient = [
	{ path: 'main', label: 'Главная', icon: <IconHome color={'white'} size={'medium'} /> },
	{ path: 'summary', label: 'Отчетности', icon: <IconHome color={'white'} size={'medium'} /> },
	{ path: 'containers', label: 'Контейнеры', icon: <IconContainer color={'white'} size={'medium'} /> },
	{ path: 'cargos', label: 'Грузы', icon: <IconContainer color={'white'} size={'medium'} /> },
	{ path: 'orders', label: 'Заявки', icon: <IconFileImg color={'white'} size={'medium'} /> },
	{
		label: 'Пропуска',
		icon: <IconUsers2 color={'white'} size={'medium'} />,
		subMenu: [
			{ path: 'entries', label: 'Пропуска', icon: <IconTable color={'white'} size={'medium'} /> },

			{ path: 'historyEntries', label: 'История въездов', icon: <IconEmpls color={'white'} size={'medium'} /> },
		],
	},
	{ path: 'inspection', label: 'Осмотр', icon: <IconFileImg color={'white'} size={'medium'} /> },
	{
		label: 'Услуги',
		icon: <IconUsers2 color={'white'} size={'medium'} />,
		subMenu: [
			{ path: 'storage', label: 'Хранение', icon: <IconUsers color={'white'} size={'medium'} /> },

			{ path: 'repair', label: 'Ремонт', icon: <IconEmpls color={'white'} size={'medium'} /> },
			{
				path: 'repack',
				label: 'Перетарка',
				icon: <IconUsers color={'white'} size={'medium'} />,
			},
			{ path: 'tariffs', label: 'Тарифы и ставки', icon: <IconUsers color={'white'} size={'medium'} /> },
		],
	},
	{ path: 'clients', label: 'Клиенты', icon: <IconUsers color={'white'} size={'medium'} /> },
	{
		label: 'Перевозчики',
		icon: <IconUsers color={'white'} size={'medium'} />,
		subMenu: [
			{ path: 'transporters', label: 'Перевозчики', icon: <IconUsers color={'white'} size={'medium'} /> },
			{
				path: 'vehicles',
				label: 'Автомобили',
				icon: <IconUsers color={'white'} size={'medium'} />,
			},
		],
	},
	{
		label: 'Пользователи',
		icon: <IconUsers2 color={'white'} size={'medium'} />,
		subMenu: [
			{ path: 'empls', label: 'Сотрудники', icon: <IconEmpls color={'white'} size={'medium'} /> },
			{ path: 'tableLogs', label: 'История действий', icon: <IconUsers color={'white'} size={'medium'} /> },
		],
	},
	{
		label: 'Настройки',
		icon: <IconUsers2 color={'white'} size={'medium'} />,
		subMenu: [{ path: 'company', label: 'Предприятие', icon: <IconEmpls color={'white'} size={'medium'} /> }],
	},
];

// Старые пункты (пока не удалять)
export const menuConfigOld = {
	// пункты для сотрудника
	1: [
		{ path: 'main', label: 'Главная', icon: <IconHome color={'white'} size={'medium'} /> },
		{
			path: 'terminals',
			label: 'Терминалы',
			icon: <IconTerminals color={'white'} size={'medium'} />,
		},
		{
			label: 'Контейнеры',
			icon: <IconContainer color={'white'} size={'medium'} />,
			subMenu: [
				{ path: 'summary', label: 'Сводная таблица', icon: <IconTable color={'white'} size={'medium'} /> },

				{ path: 'tableEntries', label: 'Таблица пропусков', icon: <IconTable color={'white'} size={'medium'} /> },

				{ path: 'orders', label: 'Заявки на контейнеры', icon: <IconFileImg color={'white'} size={'medium'} /> },
				{
					path: 'createImportOrder',
					label: 'Принять контейнеры',
					icon: <IconPlusCircle color={'white'} size={'medium'} />,
				},

				{
					path: 'createExportOrder',
					label: 'Вывезти контейнеры',
					icon: <IconContainerCar color={'white'} size={'medium'} />,
				},
			],
		},
		{
			label: 'Пользователи',
			icon: <IconUsers2 color={'white'} size={'medium'} />,
			subMenu: [
				// пермишен на просмотр вкладки с клиентами(организациями)

				{ path: 'clients', label: 'Клиенты', icon: <IconUsers color={'white'} size={'medium'} /> },

				{ path: 'empls', label: 'Сотрудники', icon: <IconEmpls color={'white'} size={'medium'} /> },
				{
					path: 'createJob',
					label: 'Должности',
					icon: <IconUsers color={'white'} size={'medium'} />,
				},
				{ path: 'tableLogs', label: 'Действия сотрудников', icon: <IconUsers color={'white'} size={'medium'} /> },
				// { path: 'cars', label: 'Автомобили', icon: <IconCar color={'white'} size={'medium'} /> },
				// { path: 'transporters', label: 'Перевозчики', icon: <IconUsers color={'white'} size={'medium'} /> },
			],
		},
	],

	// пункты для клиента
	2: [
		{ path: 'main', label: 'Главная', icon: <IconHome color={'white'} size={'medium'} /> },
		{ path: 'summary', label: 'Сводная таблица', icon: <IconTable color={'white'} size={'medium'} /> },
	],
};
