import { cardComponent } from './CardComponent.styles.js';
import { Card, CardContent, Typography, Box, Avatar, Checkbox } from '@mui/material';

// avatar - аватар с инициалами
// avatarSrc - загруженный аватар картинка
// title - главный заголовок
// rightText - текст справа
// status - статус текста справа (successful, warning, error)
// rightAdditionalInfo, leftAdditionalInfo - дополнительная информация справа и слева
// selectionMode - режим выбора
// isSelected - статус выбранной карточки
// onSelectCard - функция для выбора карточки

const CardComponent = ({
	avatar,
	avatarSrc,
	title,
	rightText,
	status,
	rightAdditionalInfo,
	leftAdditionalInfo,
	selectionMode,
	isSelected,
	onSelectCard,
}) => {
	return (
		<Card
			sx={{
				...cardComponent.wrapper,
				...(selectionMode && cardComponent.selected),
			}}
		>
			<CardContent sx={cardComponent.content}>
				{selectionMode && (
					<Checkbox
						sx={{ padding: '6px', '& .MuiSvgIcon-root': { fontSize: 18 } }}
						checked={isSelected}
						onChange={onSelectCard}
					/>
				)}
				<Box sx={cardComponent.container}>
					{avatar && <Box sx={cardComponent.avatarInitials}>{avatar}</Box>}
					{avatarSrc && <Avatar src={avatarSrc} alt="Avatar" />}
					<Box sx={cardComponent.descr}>
						<Box sx={cardComponent.box}>
							<Typography variant="alertTitle" sx={cardComponent.textOverflow}>
								{title}
							</Typography>

							{rightText && (
								<Box
									sx={{
										flexShrink: 0,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-end',
										minWidth: 'fit-content',
									}}
								>
									<Typography variant="alertTitle" sx={cardComponent[status] || {}}>
										{rightText}
									</Typography>
								</Box>
							)}
						</Box>
						<Box sx={cardComponent.stack}>
							{leftAdditionalInfo && (
								<Box>
									{leftAdditionalInfo.map((info, index) => (
										<Box sx={cardComponent.info} key={index}>
											{info.name && <Typography variant="caption2">{info.name}</Typography>}
											{info.text && (
												<Typography
													variant="caption2"
													sx={{
														...cardComponent.textOverflow,
														...(cardComponent[info.status] || {}),
													}}
													key={index}
												>
													{info.text}
												</Typography>
											)}
										</Box>
									))}
								</Box>
							)}
							{rightAdditionalInfo && (
								<Box>
									{rightAdditionalInfo.map((info, index) => (
										<Box sx={cardComponent.infoRight} key={index}>
											{info.name && <Typography variant="caption2">{info.name}</Typography>}
											{info.text && (
												<Typography variant="caption2" sx={cardComponent[info.status] || {}} key={index}>
													{info.text}
												</Typography>
											)}
										</Box>
									))}
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
};

export default CardComponent;
