import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconPlus({ size, onClick, color, className }) {
  return (
    <div onClick={onClick} className={`${styles.icon} ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={IconSize(size)}
        height={IconSize(size)}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill={IconColor(color)}
          d="M12.75 5C12.75 4.58579 12.4142 4.25 12 4.25C11.5858 4.25 11.25 4.58579 11.25 5H12.75ZM11.25 19C11.25 19.4142 11.5858 19.75 12 19.75C12.4142 19.75 12.75 19.4142 12.75 19H11.25ZM5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75V11.25ZM19 12.75C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25V12.75ZM11.25 5V12H12.75V5H11.25ZM11.25 12V19H12.75V12H11.25ZM5 12.75H12V11.25H5V12.75ZM12 12.75H19V11.25H12V12.75Z"
        />
      </svg>
    </div>
  );
}

export default IconPlus;
