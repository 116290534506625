import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './DescriptionOfDamage.module.scss';
import AdditionalInfo from './AdditionalInfo/AdditionalInfo';
import { useDispatch } from 'react-redux';
import MainInput from '../../../../../../../components/Inputs/MainInput/MainInput';
import {
	setDamageComment,
	setDamageDepth,
	setDamageLength,
	setDamageWidth,
} from '../../../../../../../store/slices/containerAcceptanceSlice';

function DescriptionOfDamage(props) {
	const [textValue, setTextValue] = useState();
	const [lengthValue, setLengthValue] = useState();
	const [widthValue, setWidthValue] = useState();
	const [depthValue, setDepthValue] = useState();

	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		setFocus,
		formState: { errors },
	} = useForm();

	const onClick = (e) => {};
	const handleCommentDamage = (e) => {
		dispatch(setDamageComment(e.target.value));
		console.log(e.target.value);
	};
	const handleLengthDamage = (e) => {
		dispatch(setDamageLength(e.target.value));
		console.log(e.target.value);
	};
	const handleWidthDamage = (e) => {
		dispatch(setDamageWidth(e.target.value));
		console.log(e.target.value);
	};
	const handleDepthDamage = (e) => {
		dispatch(setDamageDepth(e.target.value));
		console.log(e.target.value);
	};
	return (
		<form className={styles.desc_main_block} onSubmit={handleSubmit(onClick)}>
			<AdditionalInfo />
			<p className={styles.addPTitle}>Дополнительная информация</p>
			<div className={styles.inputs_block}>
				<MainInput
					name="lengthValue"
					register={register}
					title={'Длина, мм'}
					value={lengthValue ? lengthValue : ''}
					setValue={setLengthValue}
					setFocus={setFocus}
					errors={errors}
					rules={{}}
					handleFieldChange={handleLengthDamage}
					placeholder={'999'}
					mask="999999"
				/>
				<MainInput
					name="widthValue"
					register={register}
					title={'Ширина, мм'}
					value={widthValue ? widthValue : ''}
					setValue={setWidthValue}
					setFocus={setFocus}
					errors={errors}
					rules={{}}
					handleFieldChange={handleWidthDamage}
					placeholder={'999'}
					mask="999999"
				/>
				<MainInput
					name="depthValue"
					register={register}
					title={'Глубина, мм'}
					value={depthValue ? depthValue : ''}
					setValue={setDepthValue}
					setFocus={setFocus}
					errors={errors}
					rules={{}}
					handleFieldChange={handleDepthDamage}
					placeholder={'999'}
					mask="999999"
				/>
			</div>
			<div className={styles.textInfo}>
				<textarea
					name="descrOfDamage"
					id="comment"
					className={styles.commentTextArea}
					onChange={handleCommentDamage}
					placeholder="Укажите дополнительную информацию о повреждении"
				>
					{textValue}
				</textarea>
			</div>
		</form>
	);
}

export default DescriptionOfDamage;
