import axios from 'axios';

const instance = axios.create({
  baseURL: '/api/accounts/',
});

instance.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    config.xsrfHeaderName = 'X-CSRFToken';
    config.xsrfCookieName = 'csrftoken';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshResponse = await axios.post(
          '/api/accounts/token/refresh/'
        );
        if (refreshResponse.status === 200) {
          return instance(originalRequest);
        }
      } catch (error) {
        window.location.href = '/authEmpl';
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
