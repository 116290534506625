import styles from "./Header.module.scss";
import logo from "../../assets/images/logo.png";

function Header() {
  return (
    <div className={styles.header}>
      <div className={styles.header__logo}>
        <a href="/">
          <img src={logo} alt="Logo" className={styles.logo} />
        </a>
      </div>
    </div>
  );
}

export default Header;
