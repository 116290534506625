/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Button, Drawer, Box, IconButton, Typography, Stack } from '@mui/material';
import { IconCross, IconSettings } from '../../components/Icons';

import CheckBoxGroup from './components/CheckBoxGroup';
import { getElementsByFilterName, getInitialState } from './utils.js';
import { FILTER_TYPES } from '../../config/filter.js';
import { useDispatch } from 'react-redux';
import { filter } from './Filter.styles.js';
import FilterSearch from './FilterSearch.jsx';
import PriceRangeInput from '../InputRange/PriceRangeInput.jsx';

const Filter = ({ parameters = [], onSetFilter = () => {}, onResetFilter = () => {} }) => {
	const dispatch = useDispatch();

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const [filterState, setFilterState] = useState(getInitialState(parameters));

	useEffect(() => {
		return () => {
			onResetFilter();
		};
	}, []);

	const toggleDrawer = (open) => {
		setIsDrawerOpen(open);
	};
	const [minPrice, setMinPrice] = useState('');
	const [maxPrice, setMaxPrice] = useState('');

	useEffect(() => {
		setFilterState((prevState) => {
			const updatedState = { ...prevState };

			if (minPrice.length > 0) {
				updatedState['client_tariff_gte'] = minPrice;
			} else {
				delete updatedState['client_tariff_gte'];
			}

			return updatedState;
		});
	}, [minPrice]);

	useEffect(() => {
		setFilterState((prevState) => {
			const updatedState = { ...prevState };

			if (maxPrice.length > 0) {
				updatedState['client_tariff_lte'] = maxPrice;
			} else {
				delete updatedState['client_tariff_lte'];
			}

			return updatedState;
		});
	}, [maxPrice]);

	const handleChange = (name, value) => {
		setFilterState((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	const handleFilter = () => {
		onSetFilter(filterState);

		setIsDrawerOpen(false);
	};

	const handleSearch = (term) => {
		setFilterState((prevState) => {
			const updatedState = { ...prevState };

			if (term.length > 0) {
				updatedState['search'] = term; // Добавляем параметр поиска, если он не пустой
			} else {
				delete updatedState['search']; // Удаляем параметр поиска, если значение пустое
			}

			// Вызываем onSetFilter с обновленным состоянием
			onSetFilter(updatedState);

			return updatedState; // Возвращаем обновленное состояние
		});

		setIsDrawerOpen(false);
	};

	const [minPrice2, setMinPrice2] = useState('');
	const [maxPrice2, setMaxPrice2] = useState('');

	useEffect(() => {
		setFilterState((prevState) => {
			const updatedState = { ...prevState };

			if (minPrice2.length > 0) {
				updatedState['total_price_gte'] = minPrice2;
			} else {
				delete updatedState['total_price_gte'];
			}

			return updatedState;
		});
	}, [minPrice2]);

	useEffect(() => {
		setFilterState((prevState) => {
			const updatedState = { ...prevState };

			if (maxPrice2.length > 0) {
				updatedState['total_price_lte'] = maxPrice2;
			} else {
				delete updatedState['total_price_lte'];
			}

			return updatedState;
		});
	}, [maxPrice2]);

	const [minPrice3, setMinPrice3] = useState('');
	const [maxPrice3, setMaxPrice3] = useState('');

	useEffect(() => {
		setFilterState((prevState) => {
			const updatedState = { ...prevState };

			if (minPrice3.length > 0) {
				updatedState['task_count_gte'] = minPrice3;
			} else {
				delete updatedState['task_count_gte'];
			}

			return updatedState;
		});
	}, [minPrice3]);

	useEffect(() => {
		setFilterState((prevState) => {
			const updatedState = { ...prevState };

			if (maxPrice3.length > 0) {
				updatedState['task_count_lte'] = maxPrice3;
			} else {
				delete updatedState['task_count_lte'];
			}

			return updatedState;
		});
	}, [maxPrice3]);

	return (
		<Box sx={filter.wrapper}>
			<Box sx={filter.row}>
				<Button variant="contained" color="primary" className={`${isDrawerOpen && 'actived'}`} onClick={() => toggleDrawer(true)}>
					Фильтр
				</Button>

				<FilterSearch onSearch={handleSearch} />
			</Box>

			{isDrawerOpen && (
				<IconButton sx={filter.btnClose} onClick={() => toggleDrawer(false)}>
					<IconCross color={'primary-active'} size={'medium'} />
				</IconButton>
			)}

			<Drawer sx={filter.drawer} anchor="right" open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
				<Box sx={filter.content}>
					<Stack justifyContent="space-between" height={1}>
						<Box sx={{ padding: '16px 8px 8px 16px' }}>
							<Typography variant="subtitle1" marginBottom={2}>
								Фильтр
							</Typography>

							{parameters.map((parameter) => {
								switch (parameter.type) {
									case FILTER_TYPES.CHECKBOX:
										return (
											<CheckBoxGroup
												key={parameter.name}
												name={parameter.name}
												title={parameter.title}
												elements={getElementsByFilterName(parameters, parameter.name)}
												state={filterState[parameter.name]}
												onChange={handleChange}
											/>
										);
									case FILTER_TYPES.INPUTPRICERANGE:
										return (
											<div key={parameter.name}>
												<Typography variant="alertTitle" style={{ flexGrow: 1 }}>
													{parameter.title}
												</Typography>
												<PriceRangeInput
													text="тариф клиента"
													minPrice={minPrice}
													maxPrice={maxPrice}
													onMinPriceChange={setMinPrice}
													onMaxPriceChange={setMaxPrice}
												/>
											</div>
										);
									case FILTER_TYPES.INPUTPRICERANGE2:
										return (
											<Box key={parameter.name} sx={{ margin: '0 0 8px 0' }}>
												<Typography variant="alertTitle" style={{ flexGrow: 1 }}>
													{parameter.title}
												</Typography>
												<PriceRangeInput
													text="цена"
													minPrice={minPrice2}
													maxPrice={maxPrice2}
													onMinPriceChange={setMinPrice2}
													onMaxPriceChange={setMaxPrice2}
												/>
											</Box>
										);
									case FILTER_TYPES.INPUTPRICERANGE3:
										return (
											<div key={parameter.name}>
												<Typography variant="alertTitle" style={{ flexGrow: 1 }}>
													{parameter.title}
												</Typography>
												<PriceRangeInput
													text="кол-во"
													minPrice={minPrice3}
													maxPrice={maxPrice3}
													onMinPriceChange={setMinPrice3}
													onMaxPriceChange={setMaxPrice3}
												/>
											</div>
										);
									// сase - остальные типы фильтров
									default:
										return <></>;
								}
							})}
						</Box>

						<Box sx={filter.button}>
							<Button onClick={handleFilter} variant="contained" color="primary">
								Применить фильтр
							</Button>
						</Box>
					</Stack>
				</Box>
			</Drawer>
		</Box>
	);
};

export default Filter;
