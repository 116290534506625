import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconSideUp({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M18 4L12 4L6 4M8 6.5756C6.89543 6.5756 6 7.24342 6 8.0672V18.5084C6 19.3322 6.89543 20 8 20H16C17.1046 20 18 19.3322 18 18.5084V8.0672C18 7.24342 17.1046 6.5756 16 6.5756H12H8Z"
					stroke="#8E8E93"
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}
export default IconSideUp;
