import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getLinesContainer } from '../../api/containers/getLinesContainer';

// export const getLinesContainerAction = createAsyncThunk(
//   "linesContainer/getLinesContainerAction",
//   async (_, { dispatch, rejectWithValue }) => {
//     try {
//       const response = await getLinesContainer();
//       dispatch(getLinesSuccess(response.data));
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

const sideBarSlice = createSlice({
	name: 'sideBar',
	initialState: {
		currentElement: null,
		sideBarActive: false,
		prevElement: null,
		non_activeWrapper: false,
		height_fitContent: false,
	},
	reducers: {
		setCurrentElementForSideBar(state, action) {
			state.currentElement = action.payload;
		},
		setSideBarActive(state, action) {
			state.sideBarActive = action.payload;
		},
		setPrevElementForSideBar(state, action) {
			state.prevElement = action.payload;
		},
		setNon_activeWrapper(state, action) {
			state.non_activeWrapper = action.payload;
		},
		setHeightModal(state, action) {
			state.height_fitContent = action.payload;
		},
	},
	//   extraReducers: (builder) => {
	//     builder
	//       .addCase(getLinesContainerAction.fulfilled, (state, action) => {})
	//       .addCase(getLinesContainerAction.pending, (state, action) => {})
	//       .addCase(getLinesContainerAction.rejected, (state, action) => {});
	//   },
});

export const { setCurrentElementForSideBar, setSideBarActive, setPrevElementForSideBar, setNon_activeWrapper, setHeightModal } =
	sideBarSlice.actions;
export default sideBarSlice.reducer;
