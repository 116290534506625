import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { getGroupListApi } from '../../api/group/getGroupListApi';
import { getGroupApi } from '../../api/group/getGroupApi';
import { postGroupApi } from '../../api/group/postGroupApi';
import { putGroupApi } from '../../api/group/putGroupApi';
import { delGroupApi } from '../../api/group/delGroupApi';

//! Запрос на получение списка всех ролей
export const getGroupList = createAsyncThunk('group/getGroupList', async (_, { rejectWithValue }) => {
	try {
		const response = await getGroupListApi();
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

//! Запрос на получение информации о конкретной роли
export const getGroup = createAsyncThunk('group/getGroup', async ({ group_id }, { rejectWithValue }) => {
	try {
		const response = await getGroupApi(group_id);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

//! Запрос на создание новой роли
export const postGroup = createAsyncThunk('group/postGroup', async ({ requestData }, { rejectWithValue }) => {
	try {
		const response = await postGroupApi(requestData);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

//! Запрос на изменение роли
export const putGroup = createAsyncThunk('group/putGroup', async ({ group_id, requestData }, { rejectWithValue }) => {
	try {
		const response = await putGroupApi(group_id, requestData);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

//! Запрос на удаление роли
export const deleteGroup = createAsyncThunk('group/deleteGroup', async ({ group_id }, { rejectWithValue }) => {
	try {
		const response = await delGroupApi(group_id);
		return { response: response.data, group_id: group_id };
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

const groupSlice = createSlice({
	name: 'group',
	initialState: {
		//! Базовое хранилище данных
		// Список групп
		groupList: [],
		// Выбранная группа. Выбирается при клике на нее
		choosenGroup: null,
		choosenGroupReserve: null,

		//!Статусы запросов
		// Статус запроса на получение списка ролей
		getGroupList_success: false,
		getGroupList_pending: false,
		getGroupList_error: false,
		// Статус запроса на получение данных о выбранной роли
		getGroup_success: false,
		getGroup_pending: false,
		getGroup_error: false,
		// Статус запроса на создание роли
		postGroup_success: false,
		postGroup_pending: false,
		postGroup_error: false,
		// Статус запроса на изменение роли
		putGroup_success: false,
		putGroup_pending: false,
		putGroup_error: false,

		//! Процесс создания новой роли.
		// Список прав у выбранной группы для наследования
		choosenGroupPermissionsForNewRole: [],
		// Название роли
		newGroupName: '',
		// Название выбранной роли для наследования прав
		selectedGroupForNewRole: null,
		// ID выбранной роли для наследования прав
		selectedGroupId: null,
		// Отображение кнопки при изменении
		createRoleInputChange: false,

		//!Процесс изменения роли
		// Новое название существующей роли
		updatedGroupName: '',
		// Новые права для существующей роли
		newPermissionsForExistingRole: [],
		// Изменение типа модалки для изменения действий кнопок отменить/сохранить
		changeModalTypeForGroupChange: false,
		deleteGroup_success: false,
		deleteGroup_pending: false,
		deleteGroup_error: false,
	},
	reducers: {
		//! Действие добавления прав для новой роли
		//? При вызове приходит объект тип add/remove/clearAll и permission (массив или объект)
		//? Идет проверка на то что было добавлено ранее в choosenGroupPermissionsForNewRole
		setPermissionsForNewRole(state, action) {
			if (action.payload.type === 'add') {
				//TODO 20.03.24
				if (Array.isArray(action.payload.permission)) {
					const unique = action.payload?.permission?.filter(
						(permission, index, self) =>
							index ===
							self.findIndex((p) => p.id === permission.id && p.name === permission.name && p.type === permission.type),
					);
					if (state.choosenGroupPermissionsForNewRole) {
						state.choosenGroupPermissionsForNewRole = [...state.choosenGroupPermissionsForNewRole, ...unique].filter(
							(permission, index, self) =>
								index ===
								self.findIndex((p) => p.id === permission.id && p.name === permission.name && p.type === permission.type),
						);
					}
					if (!state.choosenGroupPermissionsForNewRole) {
						state.choosenGroupPermissionsForNewRole = [...unique];
					}
				} else {
					state.choosenGroupPermissionsForNewRole.push(action.payload.permission);
				}
			}
			if (action.payload.type === 'remove') {
				if (Array.isArray(action.payload.permission)) {
					if (action.payload?.option === 'ifArrayThenRemoveNowAndBefore') {
						state.choosenGroupPermissionsForNewRole = state.choosenGroupPermissionsForNewRole.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
						// state.choosenGroup.permissions = state.choosenGroup.permissions.filter(
						// 	(item) => !action.payload.permission.some(({ id }) => id === item.id),
						// );
					} else if (action.payload?.option !== 'ifArrayThenRemoveNowAndBefore' || !action.payload?.option) {
						state.choosenGroupPermissionsForNewRole = state.choosenGroupPermissionsForNewRole.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
					}

					// [
					// 	...state.choosenTerminalUserAddedNowPermissions,
					// 	...action.payload.permission,
					// ];
				} else {
					state.choosenGroupPermissionsForNewRole = state.choosenGroupPermissionsForNewRole.filter(
						(el) => el.id !== action.payload.permission.id,
					);
				}
			}
			if (action.payload.type === 'clearAll') {
				state.choosenGroupPermissionsForNewRole = [];
			}
		},

		//! Действие изменения прав для существующей роли
		//? При вызове приходит объект тип add/remove и permission (массив или объект)
		//? Изменяется newPermissionsForExistingRole
		setNewPermissionsForExistingRole(state, action) {
			if (action.payload.type === 'add') {
				if (Array.isArray(action.payload.permission)) {
					const unique = action.payload?.permission?.filter(
						(permission, index, self) =>
							index ===
							self.findIndex((p) => p.id === permission.id && p.name === permission.name && p.type === permission.type),
					);
					state.newPermissionsForExistingRole = [...state.newPermissionsForExistingRole, ...unique].filter(
						(permission, index, self) =>
							index ===
							self.findIndex((p) => p.id === permission.id && p.name === permission.name && p.type === permission.type),
					);
				} else {
					state.newPermissionsForExistingRole.push(action.payload.permission);
				}
			}
			if (action.payload.type === 'remove') {
				if (Array.isArray(action.payload.permission)) {
					if (action.payload?.option === 'ifArrayThenRemoveNowAndBefore') {
						state.newPermissionsForExistingRole = state.newPermissionsForExistingRole.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
						state.choosenGroup.permissions = state.choosenGroup.permissions.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
					} else if (action.payload?.option !== 'ifArrayThenRemoveNowAndBefore' || !action.payload?.option) {
						state.newPermissionsForExistingRole = state.newPermissionsForExistingRole.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
					}
				}
			}
		},
		// if (action.payload?.option === 'ifArrayThenRemoveNowAndBefore') {
		// 	state.newPermissionsForExistingRole = state.newPermissionsForExistingRole.filter(
		// 		(item) => !action.payload.permission.some(({ id }) => id === item.id),
		// 	);
		// 	console.log(current(state.choosenGroup.permissions), 'REMOVE ARRAY state.choosenGroup.permissions');
		// 	//TODO 20.03.24
		// 	state.choosenGroup.permissions = state.choosenGroup.permissions.filter((el) => el.id !== action.payload.permission.id);
		// } else if (action.payload?.option !== 'ifArrayThenRemoveNowAndBefore' || !action.payload?.option) {
		// 	state.newPermissionsForExistingRole = state.newPermissionsForExistingRole.filter(
		// 		(el) => el.id !== action.payload.permission.id,
		// 	);
		// }

		//! Действие выбора нового имени при создании новой роли
		setNewGroupName(state, action) {
			state.newGroupName = action.payload;
		},

		//! Действие выбора группы для наследования прав при создании новой роли
		//? Приходит название роли из селекта, затем по названию идет поиск по списку всех групп groupList
		//? Изменяется choosenGroupPermissionsForNewRole - туда записываются пермишены у найденной роли
		//? Изменяется selectedGroupForNewRole - туда записывается название роли
		setSelectedGroupForNewRole(state, action) {
			const foundRole = state.groupList?.find((item) => item.name === action.payload);
			if (foundRole.permissions) {
				state.choosenGroupPermissionsForNewRole = foundRole.permissions;
			} else {
				state.choosenGroupPermissionsForNewRole = [];
			}
			state.selectedGroupForNewRole = action.payload;
			// return foundRole ? foundRole.permissions : null;
		},

		//! Действие выбора группы и записи его ID при создании новой роли
		//? Изменяется choosenGroupPermissionsForNewRole
		setSelectedGroupId(state, action) {
			state.selectedGroupId = action.payload;
		},

		//! Действие для регистрации изменений в процессе создании роли и открытия кнопки "Сохранить"
		setCreateRoleInputChange(state, action) {
			state.createRoleInputChange = action.payload;
		},

		//! Действия для редактирования прав на выбранной роли (существующая роль)
		//? Перезаписываются права в choosenGroup, и далее закидываются в PUT запрос
		setChoosenGroupPermissions(state, action) {
			if (action.payload.type === 'add') {
				if (Array.isArray(action.payload.permission)) {
					const unique = action.payload?.permission?.filter(
						(permission, index, self) =>
							index ===
							self.findIndex((p) => p.id === permission.id && p.name === permission.name && p.type === permission.type),
					);
					state.choosenGroup.permissions = [...state.choosenGroup?.permissions, ...unique].filter(
						(permission, index, self) =>
							index ===
							self.findIndex((p) => p.id === permission.id && p.name === permission.name && p.type === permission.type),
					);
				} else {
					state.choosenGroup.permissions.push(action.payload.permission);
				}
			}
			if (action.payload.type === 'remove') {
				if (Array.isArray(action.payload.permission)) {
					if (action.payload?.option === 'ifArrayThenRemoveNowAndBefore') {
						state.choosenGroup.permissions = state.choosenGroup.permissions.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
						state.newPermissionsForExistingRole = state.newPermissionsForExistingRole.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
					} else if (action.payload?.option !== 'ifArrayThenRemoveNowAndBefore') {
						state.choosenGroup.permissions = state.choosenGroup.permissions.filter(
							(item) => !action.payload.permission.some(({ id }) => id === item.id),
						);
					}
					// if (state.choosenGroupPermissionsForNewRole) {
					// 	state.choosenGroupPermissionsForNewRole = [
					// 		...state.choosenGroupPermissionsForNewRole,
					// 		...action.payload.permission,
					// 	];
					// }
					// if (!state.choosenGroupPermissionsForNewRole) {
					// 	state.choosenGroupPermissionsForNewRole = [...action.payload.permission];
					// }
				} else {
					state.choosenGroup.permissions = state.choosenGroup?.permissions.filter((el) => el.id !== action.payload.permission.id);
				}
			}
		},

		//! Действие для очистки всех массивов и данных
		//? Используется при закрытии формы
		clearAllGroupArrays(state, action) {
			state.choosenGroup = null;
			state.choosenGroupReserve = null;
			state.choosenGroupPermissionsForNewRole = [];
			state.newGroupName = '';
			state.selectedGroupForNewRole = null;
			state.selectedGroupId = null;
			state.createRoleInputChange = false;
			state.newPermissionsForExistingRole = [];
		},

		//! Действие выбора нового имени при изменении существующей роли
		setUpdatedGroupName(state, action) {
			state.updatedGroupName = action.payload;
			// state.createRoleInputChange = true
		},
		resetGroupChanges(state, action) {
			state.newPermissionsForExistingRole = [];
			state.choosenGroup.permissions = state.choosenGroupReserve.permissions;
			state.updatedGroupName = state.choosenGroupReserve.name;
		},
		setChangeModalTypeForGroupChange(state, action) {
			state.changeModalTypeForGroupChange = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getGroupList.fulfilled, (state, action) => {
				state.groupList = action.payload;
				state.getGroupList_success = true;
				state.getGroupList_pending = false;
				state.getGroupList_error = false;
			})
			.addCase(getGroupList.pending, (state, action) => {
				state.getGroupList_success = false;
				state.getGroupList_pending = true;
				state.getGroupList_error = false;
			})
			.addCase(getGroupList.rejected, (state, action) => {
				state.getGroupList_success = false;
				state.getGroupList_pending = false;
				state.getGroupList_error = action.payload;
			});
		builder
			.addCase(getGroup.fulfilled, (state, action) => {
				// state.choosenGroup = action.payload;
				if (action.payload.permissions) {
					state.choosenGroup = action.payload;
					state.choosenGroupReserve = action.payload;
				}
				if (!action.payload.permissions) {
					state.choosenGroup = { ...action.payload, permissions: [] };
					state.choosenGroupReserve = { ...action.payload, permissions: [] };
				}
				state.updatedGroupName = action.payload.name;
				state.getGroup_success = true;
				state.getGroup_pending = false;
				state.getGroup_error = false;
			})
			.addCase(getGroup.pending, (state, action) => {
				state.getGroup_success = false;
				state.getGroup_pending = true;
				state.getGroup_error = false;
			})
			.addCase(getGroup.rejected, (state, action) => {
				state.getGroup_success = false;
				state.getGroup_pending = false;
				state.getGroup_error = action.payload;
			});
		builder
			.addCase(postGroup.fulfilled, (state, action) => {
				const newItem = { ...action.payload, permissions: [], users_count: 0 };
				state.groupList.push(newItem);
				// state.choosenGroup = action.payload;
				state.postGroup_success = true;
				state.postGroup_pending = false;
				state.postGroup_error = false;
			})
			.addCase(postGroup.pending, (state, action) => {
				state.postGroup_success = false;
				state.postGroup_pending = true;
				state.postGroup_error = false;
			})
			.addCase(postGroup.rejected, (state, action) => {
				state.postGroup_success = false;
				state.postGroup_pending = false;
				state.postGroup_error = action.payload;
			});

		builder
			.addCase(putGroup.fulfilled, (state, action) => {
				// state.choosenGroup = action.payload;
				state.groupList.forEach((item) => {
					if (item.id == action.payload.id) {
						item.name = action.payload.name;
						// item.username = action.payload.updatedUser?.username;
						// item.group = action.payload.updatedUser?.group;
						// item.password = action.payload.updatedUser?.password;
						// //TODO сделать пермишены
						// item.group_permissions_ids = action.payload.updatedUser?.permissions;
					}
				});
				state.putGroup_success = true;
				state.putGroup_pending = false;
				state.putGroup_error = false;
			})
			.addCase(putGroup.pending, (state, action) => {
				state.putGroup_success = false;
				state.putGroup_pending = true;
				state.putGroup_error = false;
			})
			.addCase(putGroup.rejected, (state, action) => {
				state.putGroup_success = false;
				state.putGroup_pending = false;
				state.putGroup_error = action.payload;
			});

		builder
			.addCase(deleteGroup.fulfilled, (state, action) => {
				state.groupList = state.groupList.filter((el) => el.id !== action.payload.group_id);
				state.deleteGroup_success = true;
				state.deleteGroup_pending = false;
				state.deleteGroup_error = false;
			})
			.addCase(deleteGroup.pending, (state, action) => {
				state.deleteGroup_success = false;
				state.deleteGroup_pending = true;
				state.deleteGroup_error = false;
			})
			.addCase(deleteGroup.rejected, (state, action) => {
				state.deleteGroup_success = false;
				state.deleteGroup_pending = false;
				state.deleteGroup_error = action.payload.response;
			});
	},
});

export const {
	setChangeModalTypeForGroupChange,
	resetGroupChanges,
	setUpdatedGroupName,
	clearAllGroupArrays,
	setChoosenGroupPermissions,
	setPermissionsForNewRole,
	// changeGroupPermissions,
	setNewPermissionsForExistingRole,
	setNewGroupName,
	setSelectedGroupForNewRole,
	setSelectedGroupId,
	setCreateRoleInputChange,
} = groupSlice.actions;
export default groupSlice.reducer;
