import { buildQueryString } from '../../../newComponents/Filter/utils.js';
import api from '../../api.js';

export const service = api.injectEndpoints({
	endpoints: (builder) => ({
		getServiceList: builder.query({
			query: ({ page, page_size, sorting }) => {
				const params = { page, page_size };

				const sortParam = sorting?.length ? sorting.map(({ id, desc }) => (desc ? `-${id}` : id)).join(',') : null;

				if (sortParam) {
					params.ordering = sortParam;
				}
				return {
					url: '/api/service/list/',
					params,
					method: 'GET',
				};
			},
		}),
		getRepairTasks: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;

				return {
					url: `/api/service/repair_tasks/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getServiceOrders: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;

				return {
					url: `/api/service/service_orders/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getStorageOrdersList: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;

				return {
					url: `/api/service/storage/orders/list?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getRepackingOrdersList: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;

				return {
					url: `api/service/repacking/orders/list?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const {
	useGetServiceListQuery,
	useLazyGetServiceListQuery,
	useLazyGetRepairTasksQuery,
	useLazyGetServiceOrdersQuery,
	useLazyGetStorageOrdersListQuery,
	useLazyGetRepackingOrdersListQuery,
} = service;
