import React, { useState } from 'react';
import styles from './DescrDamagePhoto.module.scss';
import ImageUploading from 'react-images-uploading';
import { useDispatch, useSelector } from 'react-redux';
import { IconPlusCircle } from '../../../../../../../components/Icons';
import { setDamageImages } from '../../../../../../../store/slices/containerAcceptanceSlice';
import S3Manager from '../../../../../../../helpers/s3storage';
import { useParams } from 'react-router-dom';

function DescrDamagePhoto() {
	const { reportId } = useParams();
	const { currentSideDamage } = useSelector((state) => state.containerAccept);
	const [images, setImages] = useState(() => {
		const savedImages = localStorage.getItem('uploadedImages');
		return savedImages ? JSON.parse(savedImages) : [];
	});

	const dispatch = useDispatch();

	const imageUploader = new S3Manager('damages');

	const onChange = async (imageList, addUpdateIndex) => {
		setImages(imageList);
		const newImage = imageList[addUpdateIndex];
		if (newImage) {
			try {
				const result = await imageUploader.uploadImage({
					actId: reportId,
					file: { name: 'expample.png', data_url: newImage.data_url },
				});
				const photka = await imageUploader.getImage(result.Key);
				await dispatch(setDamageImages({ Key: result.Key, Location: result.Location, imageSrc: photka }));
			} catch (error) {
				console.error('Ошибка загрузки изображения:', error);
			}
		}
	};

	return (
		<div className={styles.mainCont}>
			<ImageUploading
				multiple
				value={images}
				onChange={onChange}
				maxNumber={10}
				dataURLKey="data_url"
				acceptType={['jpg', 'png', 'jpeg']}
			>
				{({ imageList, onImageUpload, isDragging, dragProps }) => (
					<>
						<div className={styles.selectedPhotos}>
							{currentSideDamage.pictures.map((image, index) => (
								<div key={index} className={styles.imageItem} style={{ backgroundImage: `url(${image.imageSrc})` }}></div>
							))}
						</div>
						<div
							className={`${styles.uploadImageWrapper} ${styles.photoContainer} ${isDragging ? styles.dragging : ''}`}
							{...dragProps}
						>
							<button className={styles.uploadButton} onClick={onImageUpload}>
								Загрузить фото
								<IconPlusCircle color={'primary'} size={'large'} />
							</button>
						</div>
					</>
				)}
			</ImageUploading>
		</div>
	);
}

export default DescrDamagePhoto;
