import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOrders } from "../../api/order/getOrders";
import { getOrderDetails } from "../../api/order/getOrderDetails";

export const getOrdersAction = createAsyncThunk(
  "linesContainer/getOrdersAction",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      let response;
      if (state.orderPage.next && state.orderPage.fetching) {
        response = await getOrders(state.orderPage.next);
      } else if (state.orderPage.url && state.orderPage.fetching) {
        response = await getOrders(state.orderPage.url);
      }
      if (!response.statusText === "OK") {
        throw new Error(
          "Ошибка запроса linesContainer/getOrdersAction. Не удалось получить данные"
        );
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getOrderDetailsAction = createAsyncThunk(
  "linesContainer/getOrderDetailsAction",
  async ({ order_id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getOrderDetails(order_id);
      if (!response.statusText === "OK") {
        throw new Error(
          "linesContainer/getOrderDetailsAction. Не удалось получить данные"
        );
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const orderPageSlice = createSlice({
  name: "orderPage",
  initialState: {
    orderList: [],
    currentOrderDetails: {},
    activeCardOrder: false,
    pending_list: false,
    pending_details: false,
    error_list: null,
    error_details: null,
    activeFilter: 0,
    next: null,

    //! query parameters
    countItem: 10,
    active: null,
    no_drivers: null,

    //! Infinite Scroll
    fetching: true,
    url: `/list/?active=${true}&no_drivers=${null}`,
  },
  reducers: {
    setCardOrderActive(state, action) {
      state.activeCardOrder = action.payload;
    },
    // { name: "Без водителей", id: 1 },
    // { name: "Все заявки", id: 2 },
    // { name: "Законченные", id: 3 },
    setActiveFilter(state, action) {
      state.activeFilter = action.payload;
      state.orderList = [];
      state.next = null;
      switch (action.payload) {
        case "0":
          state.url = `/list/?active=${true}&no_drivers=${null}`;
          break;
        case "1":
          state.url = `/list/?active=${null}&no_drivers=${true}`;
          break;
        case "2":
          state.url = `/list/?active=${null}&no_drivers=${null}`;
          break;
        case "3":
          state.url = `/list/?active=${false}&no_drivers=${null}`;
          break;
        default:
          state.url = `/list/?active=${true}&no_drivers=${null}`;
          break;
      }
    },
    allowFetching(state, action) {
      state.fetching = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrdersAction.fulfilled, (state, action) => {
        if (action.payload.data.next) {
          const startIndex = action.payload.data.next?.indexOf("?");
          const trimmedUrl = `/list/${action.payload.data.next?.substring(
            startIndex
          )}`;
          state.next = trimmedUrl;
          state.url = "";
        } else {
          state.next = null;
          state.url = "";
        }
        state.fetching = false;
        state.pending_list = false;
        state.orderList = [...state.orderList, ...action.payload.data.results];
      })
      .addCase(getOrdersAction.pending, (state, action) => {
        state.pending_list = true;
        state.error_list = null;
        state.firstLoading = false;
      })
      .addCase(getOrdersAction.rejected, (state, action) => {
        state.pending_list = false;
        state.error_list = action.payload;
        state.fetching = false;
      });
    builder
      .addCase(getOrderDetailsAction.fulfilled, (state, action) => {
        state.currentOrderDetails = action.payload;
        state.pending_details = false;
        state.fetching = false;
      })
      .addCase(getOrderDetailsAction.pending, (state, action) => {
        state.pending_details = true;
        state.error_details = null;
      })
      .addCase(getOrderDetailsAction.rejected, (state, action) => {
        state.pending_details = false;
        state.error_details = action.payload;
      });
  },
});

export const {
  setCardOrderActive,
  allowFetching,
  setDataAndUrlForInfiniteScroll,
  setUrlForInfiniteScroll,
  setActiveFilter,
} = orderPageSlice.actions;
export default orderPageSlice.reducer;
