import api from '../../api.js';

export const organizations = api.injectEndpoints({
	endpoints: (builder) => ({
		getClientsList: builder.query({
			query: ({ page, page_size, id }) => {
				let params = { page, page_size };
				if (id !== null) {
					params = { page, page_size, id }
				}
				return {
					url: '/api/erp/organization/list/?type=2',
					params,
					method: 'GET',
				};
			},
		}),
		getTransportersList: builder.query({
			query: ({ page, page_size }) => {
				const params = { page, page_size };
				return {
					url: '/api/erp/organization/list/?type=3',
					params,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetClientsListQuery, useLazyGetClientsListQuery, useLazyGetTransportersListQuery } = organizations;
