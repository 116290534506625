import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainInput from '../../../Inputs/MainInput/MainInput';
import { useForm } from 'react-hook-form';
import style from './ModalPassChange.module.scss';
import UniversalButton from '../../../Buttons/UniversalButton/UniversalButton';
import { useDispatch, useSelector } from 'react-redux';
import { setSideBarActive } from '../../../../store/slices/sideBarSlice';
import {
	getOneEntrie,
	putEntriesList,
	setOneEntrieAfterSave,
	updateFieldsForCard,
	setPassInfo,
	getEntries,
} from '../../../../store/slices/orderSlice';
import TimeDatePicker from '../TimeDatePicker/TimeDatePicker';
import AutoCompleteSearch from '../../AutoCompleteSearch/AutoCompleteSearch';
import { getVehicles } from '../../../../store/slices/vehicleSlice';

const ModalPassChange = ({ selectedContainers, setSelectedContainers, setSelectedCards, setOpenToast, selectedCards }) => {
	const [carrier, setCarier] = useState(''); //Ожидаем от бека
	const [auto, setAuto] = useState(''); // Ожидаем от бека
	const [responsible, setResponsible] = useState(''); // Ожидаем от бека
	const [startDate, setStartDate] = useState('');
	const { terminalId, orderId } = useParams(); //
	const { vehiclesList, getVehicles_pending, getVehicles_success } = useSelector((state) => state.vehicle);
	const pass_pk = selectedContainers.length === 1 && selectedContainers[0].id;

	const dispatch = useDispatch();
	const { register, handleSubmit, setFocus, formState, setValue } = useForm({});
	const { errors } = formState;

	const { passInfo } = useSelector((state) => state.order);

	let numbersPass; // Логика на отображение номеров
	if (selectedContainers.length <= 5) {
		numbersPass = selectedContainers?.map((el) => el.container_number).join(' ');
	} else {
		const firstFive = selectedContainers
			.slice(0, 5)
			.map((el) => el.container_number)
			.join(' ');
		const remainingCount = selectedContainers.length - 5;
		numbersPass = `${firstFive} и еще ${remainingCount} шт`;
	}

	const handleChangeCarrier = (e) => {
		setCarier(e.target.value);
	};
	//Vehicle
	const handleChangeVehicleOption = (e, newValue) => {
		dispatch(setPassInfo({ field: 'passVehicleId', data: newValue?.id }));
		dispatch(setPassInfo({ field: 'passVehicle', data: newValue?.registration_number }));
	};
	const handleChangeVehicleInput = (e, newValue) => {
		if (e) {
			dispatch(setPassInfo({ field: 'passVehicle', data: e.target.value }));
			dispatch(getVehicles({ number: e.target.value || newValue }));
		}
		//Для варианта когда у нас стирается название
		// if (!e) {
		// 	console.log('Для варианта когда у нас стирается название');
		// 	dispatch(setPassInfo({ field: 'passVehicle', data: newValue }));
		// 	dispatch(getVehicles({ number: newValue }));
		// }
		// dispatch()
	};

	const handleChangeDriver = (e) => {
		dispatch(setPassInfo({ field: 'passDriver', data: e.target.value }));
	};
	const handleChangeTrailer = (e) => {
		dispatch(setPassInfo({ field: 'passTrailerNumber', data: e.target.value }));
	};
	const handleChangeSeal = (e) => {
		dispatch(setPassInfo({ field: 'passSealNumber', data: e.target.value }));
	};
	const handleChangeResponsible = (e) => {
		setResponsible(e.target.value);
	};
	const handleChangeTime = (e) => {
		dispatch(setPassInfo({ field: 'passExpectedCheckInTime', data: startDate }));
	};

	const onSubmit = () => {
		//создание реквест даты для изменения одной или нескольких карточек
		const requestData = selectedContainers?.map((pass) => {
			const data = { pass_pk: pass.id };
			if (passInfo.passDriver) {
				data.vehicle_driver = passInfo.passDriver;
			}
			if (passInfo.passTrailerNumber) {
				data.vehicle_trailer_number = passInfo.passTrailerNumber;
			}
			if (passInfo.passSealNumber) {
				data.seal_number = passInfo.passSealNumber;
			}
			if (passInfo.passExpectedCheckInTime) {
				data.eta_checkin = passInfo.passExpectedCheckInTime;
			}
			if (passInfo.passVehicleId) {
				data.vehicle = passInfo.passVehicleId;
			}
			if (passInfo.passVehicle) {
				data.vehicleNumber = passInfo.passVehicle;
			}
			return data;
		});
		dispatch(putEntriesList({ terminal_id: terminalId, order_id: orderId, requestData: requestData }));
		dispatch(updateFieldsForCard({ requestData }));
		dispatch(setSideBarActive(false));
		setSelectedContainers([]);
		setSelectedCards(Array(selectedContainers?.length)?.fill(false));
		dispatch(setOneEntrieAfterSave([]));
		setStartDate(''); //обнуление даты
		setOpenToast(true);
	};

	useEffect(() => {
		if (selectedContainers.length === 1) {
			dispatch(getOneEntrie({ terminal_pk: terminalId, order_pk: orderId, pass_pk }));
		}
	}, []);

	//еффект для временной даты
	useEffect(() => {
		dispatch(setPassInfo({ field: 'passExpectedCheckInTime', data: startDate }));
	}, [startDate]);

	//* Получение списка автомобилей
	useEffect(() => {
		dispatch(getVehicles({ number: '' }));
	}, []);

	useEffect(() => {
		console.log('passInfo', passInfo);
	}, [passInfo]);
	return (
		<div className={style.modalContainer}>
			<div>№ {numbersPass}</div>
			<form action="" className={style.passForm}>
				<MainInput
					title={'Перевозчик'}
					errorTitle={'Некорректный перевозчик'}
					name="carrier"
					register={register}
					value={carrier ? carrier : ''}
					handleFieldChange={handleChangeCarrier}
					setValue={setValue}
					setFocus={setFocus}
					errors={errors}
					rules={
						{
							// required: window.location.pathname === isCreateRoute ? 'Обязательное поле' : undefined,
							// minLength: {
							// 	value: 2,
							// 	message: 'Минимум 2 символа',
							// },
						}
					}
					placeholder={'Выберите перевозчика'}
				/>
				{/* <MainInput
					title={'Автомобиль'}
					errorTitle={'Некорректный автомобиль'}
					name="auto"
					register={register}
					value={auto ? auto : ''}
					handleFieldChange={handleChangeAuto}
					setValue={setValue}
					setFocus={setFocus}
					errors={errors}
					rules={
						{
							// required: window.location.pathname === isCreateRoute ? 'Обязательное поле' : undefined,
							// minLength: {
							// 	value: 2,
							// 	message: 'Минимум 2 символа',
							// },
						}
					}
					placeholder={'Выберите автомобиль'}
				/> */}

				<AutoCompleteSearch
					multiple={selectedCards?.length > 1}
					title={'Автомобиль'}
					data={vehiclesList}
					objectKey={'registration_number'}
					handleInputChange={handleChangeVehicleInput}
					handleOptionChange={handleChangeVehicleOption}
					handleIfNoOptions={() => console.log('Нет автомобилей, добавить - TODO')}
					noOptionsTitle={'Добавить автомобиль'}
					pending_state={getVehicles_pending}
					success_state={getVehicles_success}
					choosenOption={
						passInfo.passVehicleId && passInfo.passVehicle
							? { id: passInfo.passVehicleId, registration_number: passInfo.passVehicle }
							: null
					}
					placeholder={'Выберите автомобиль'}
				/>
				<MainInput
					title={'Водитель'}
					errorTitle={'Некорректный водитель'}
					name="driver"
					register={register}
					value={passInfo.passDriver ? passInfo.passDriver : ''}
					handleFieldChange={handleChangeDriver}
					setValue={setValue}
					setFocus={setFocus}
					errors={errors}
					rules={
						{
							// required: window.location.pathname === isCreateRoute ? 'Обязательное поле' : undefined,
							// minLength: {
							// 	value: 2,
							// 	message: 'Минимум 2 символа',
							// },
						}
					}
					placeholder={'Выберите водителя'}
				/>
				<MainInput
					title={'Номер прицепа'}
					errorTitle={'Некорректный номер прицепа'}
					name="trailer"
					register={register}
					value={passInfo.passTrailerNumber ? passInfo.passTrailerNumber : ''}
					// value={trailer ? trailer : ''}
					handleFieldChange={handleChangeTrailer}
					setValue={setValue}
					setFocus={setFocus}
					errors={errors}
					rules={
						{
							// required: window.location.pathname === isCreateRoute ? 'Обязательное поле' : undefined,
							// minLength: {
							// 	value: 2,
							// 	message: 'Минимум 2 символа',
							// },
						}
					}
					placeholder={'Выберите номер прицепа'}
				/>
				{selectedContainers.length < 2 && (
					<MainInput
						title={'Номер пломбы'}
						errorTitle={'Некорректный номер пломбы'}
						name="seal"
						register={register}
						value={passInfo.passSealNumber ? passInfo.passSealNumber : ''}
						handleFieldChange={handleChangeSeal}
						setValue={setValue}
						setFocus={setFocus}
						errors={errors}
						rules={
							{
								// required: window.location.pathname === isCreateRoute ? 'Обязательное поле' : undefined,
								// minLength: {
								// 	value: 2,
								// 	message: 'Минимум 2 символа',
								// },
							}
						}
						placeholder={'Введите номер пломбы'}
					/>
				)}
				<MainInput
					title={'Ответственный на терминале'}
					errorTitle={'Некорректный ответственный на терминале'}
					name="responsible"
					register={register}
					value={responsible ? responsible : ''}
					handleFieldChange={handleChangeResponsible}
					setValue={setValue}
					setFocus={setFocus}
					errors={errors}
					rules={{
						// required: window.location.pathname === isCreateRoute ? 'Обязательное поле' : undefined,
						minLength: {
							value: 2,
							message: 'Минимум 2 символа',
						},
					}}
					placeholder={'Выберите ответственного на терминале'}
				/>
				<div className={style.timeInput}>
					<MainInput
						title={'Ожидаемое время проезда'}
						errorTitle={'Некорректное время проезда'}
						name="time"
						register={register}
						value={passInfo.passExpectedCheckInTime ? passInfo.passExpectedCheckInTime.toString().split(' (')[0] : ''}
						handleFieldChange={handleChangeTime}
						setValue={setValue}
						setFocus={setFocus}
						errors={errors}
						rules={{
							// required: window.location.pathname === isCreateRoute ? 'Обязательное поле' : undefined,
							minLength: {
								value: 2,
								message: 'Минимум 2 символа',
							},
						}}
						placeholder={'Выберите ожидаемое время проезда'}
						disabled={true}
					/>
					<TimeDatePicker startDate={startDate} setStartDate={setStartDate} />
				</div>
			</form>
			<div className={style.buttonContainer}>
				<UniversalButton label={'Сохранить'} color={'primary'} type={'button'} size={'medium'} onClick={handleSubmit(onSubmit)} />
			</div>
		</div>
	);
};

export default ModalPassChange;
