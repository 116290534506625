import React, { useEffect, useState } from 'react';
import styles from './ContainerAcceptanceContent.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	handleNextAsync,
	putDamagesInEntryreport,
	putEntryreports,
	setHandleNextButtonDisabled,
	setPage,
} from '../../../../../../../store/slices/containerAcceptanceSlice';
import { setCurrentElementForSideBar, setSideBarActive, setHeightModal } from '../../../../../../../store/slices/sideBarSlice';
import { Slider, UniversalButton } from '../../../../../../../components';
import { IconArrowRight, IconPlus, IconTrash } from '../../../../../../../components/Icons';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import DeleteModal from '../DeleteModal/DeleteModal';

function ContainerAcceptanceContent({ child, title }) {
	const { page, damagesList, currentSideDamage, handleNextButtonDisabled, damagedDetails } = useSelector(
		(state) => state.containerAccept,
	);
	const [scrollable, setScrollable] = useState(false); // Добавляем состояние для отслеживания наличия скроллбара

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { reportId } = useParams();

	const handleNext = () => {
		dispatch(handleNextAsync());
	};
	const handleScroll = () => {
		const elem = document.querySelector(`.${styles.main_content}`);
		setScrollable(elem.scrollHeight > elem.clientHeight);
	};

	useEffect(() => {
		handleScroll(); // Вызываем обработчик скролла при загрузке компонента
	}, [page]);

	//TODO логику перенести в async thunk
	const handlePrevStep = () => {
		console.log('prev step', page);
		if (page === 9 || page === 10) {
			console.log('9 page');
			dispatch(setPage(2));
		}
		if (page === 8) {
			dispatch(setPage(9));
		}
		if (page === 3) {
			dispatch(setPage(9));
		}
		if (page === 4) {
			dispatch(setPage(3));
		}
		if (page === 5) {
			dispatch(setPage(4));
		}
		if (page === 6) {
			dispatch(setPage(5));
		}
	};

	const handlePutReport = () => {
		const requestData = {
			status: 1,
		};
		dispatch(putDamagesInEntryreport({ reportId }));
		dispatch(putEntryreports({ reportId, requestData })).then((result) => {
			if (putEntryreports.fulfilled.match(result)) {
				const reportId = result.payload.id;
				navigate(`/accountEmpl/receptionTransmission/act/${reportId}`);
			}
		});
		dispatch(setSideBarActive(false));
	};

	const handleFormAct = () => {
		if (damagesList.length === 0) {
			dispatch(setHeightModal(true));
			dispatch(setSideBarActive(true));
			dispatch(setCurrentElementForSideBar(<ConfirmationModal onClickNoDemage={handlePutReport} />));
		} else {
			handlePutReport();
		}
	};

	const handleDelete = () => {
		dispatch(setHeightModal(true));
		dispatch(setSideBarActive(true));
		dispatch(setCurrentElementForSideBar(<DeleteModal onClickNoDemage={handlePutReport} />));
	};

	const sides = [
		{ name: 'Правая сторона (1)', id: 1 },
		{ name: 'Верхняя сторона (3)', id: 2 },
		{ name: 'Нижняя сторона (2)', id: 3 },
	];

	const mainInfo = [{ name: 'Повреждения', id: 1 }];

	useEffect(() => {
		if (page === 4) {
			console.log(currentSideDamage.damages.length, damagedDetails, 'Проверка');
			if (currentSideDamage.damages.length > 0 || damagedDetails.length > 0) {
				dispatch(setHandleNextButtonDisabled(false));
			} else {
				dispatch(setHandleNextButtonDisabled(true));
			}
		} else if (page === 5) {
			if (currentSideDamage.damageType) {
				dispatch(setHandleNextButtonDisabled(false));
			} else {
				dispatch(setHandleNextButtonDisabled(true));
			}
		}
	}, [page, currentSideDamage?.damages, damagedDetails, currentSideDamage.damageType]);

	useEffect(() => {
		return () => {
			dispatch(setSideBarActive(false));
		};
	}, [page]);

	return (
		<div className={styles.mainMain_block}>
			{page === 7 && (
				<div className={styles.top_slider}>
					<Slider
						user_group={0}
						allName={`${page === 9 ? 'Основное' : `Все стороны (${damagesList.length})`}`}
						data={page === 9 ? mainInfo : sides}
					/>
				</div>
			)}
			<div className={styles.main_block}>
				{title && <p className={styles.main_block_title}>{title}</p>}
				<div className={`${styles.main_content} ${scrollable ? styles.scroll_content : ''}`}>{child}</div>
			</div>
			<div
				className={`${styles.bottom_btns} ${page === 7 || page === 8 || page === 9 ? styles.bottom_first_left : ''} ${page === 3 ? styles.bottom_btns_left : ''} ${page === 10 ? styles.modal_btns_sB : ''}`}
			>
				{/* //Кнопка удаления повреждения */}
				{page === 8 && (
					<UniversalButton
						onClick={handleDelete}
						typeButton={'button'}
						size={'large'}
						color={'error'}
						icon={<IconTrash color={'white'} size={'large'} />}
					/>
				)}
				{page !== 2 && (
					<UniversalButton
						onClick={() => handlePrevStep()}
						typeButton={'button'}
						label={'Назад'}
						size={'medium'}
						color={'secondary'}
					/>
				)}
				{/* //Кнопка добавления повреждения */}
				{page === 9 && (
					<UniversalButton
						onClick={() => dispatch(setPage(3))}
						typeButton={'button'}
						label={'Повреждение'}
						size={'medium'}
						color={'accent'}
						icon={<IconPlus color={'white'} size={'large'} />}
					/>
				)}
				{page !== 3 && page !== 2 && page !== 9 && page !== 8 && page !== 10 && (
					<UniversalButton
						onClick={() => handleNext()}
						label={'Далее'}
						disabled={handleNextButtonDisabled}
						size={'medium'}
						typeButton={page === 4 ? 'submit' : 'button'}
						color={'primary'}
						icon={<IconArrowRight color={'white'} size={'largeS'} />}
					/>
				)}
				{page === 2 && (
					<div className={styles.bottom_btns}>
						<UniversalButton
							onClick={() => handleFormAct()}
							label={'Сформировать акт'}
							size={'medium'}
							typeButton={'button'}
							color={'accent'}
						/>
					</div>
				)}
				{page === 10 && <UniversalButton label={'Завершить'} size={'medium'} typeButton={'button'} color={'accent'} />}
			</div>
		</div>
	);
}

export default ContainerAcceptanceContent;
