import React, { forwardRef } from 'react';
import styles from './UniversalButton.module.scss';

const UniversalButton = forwardRef(
	(
		{
			label, //your text
			icon, //your icon
			color, //color: { primary | accent | error | succesful}
			size, //size: {small | medium | large}
			onClick, //your function
			disabled, // {true | false}
			type, //outline {with-border}
			typeButton, // btn type {"button" | "submit"}
		},
		ref,
	) => {
		const hasIcon = Boolean(icon);
		const hasLabel = Boolean(label);

		const buttonClasses = `
      ${styles['universal-button']}
      ${styles[`color-${color}`]}
      ${styles[`size-${size}`]}
      ${styles[`type-${type}`]} 
      ${hasIcon && !hasLabel ? styles['with-icon-only'] : ''}
      ${icon && styles['with-icon']}
      ${label && styles['with-label']}
    `;

		const iconClasses = `
      ${styles['button-icon']}
      ${!hasLabel ? styles['icon-only'] : ''}
    `;

		return (
			<button ref={ref} className={buttonClasses} onClick={onClick} disabled={disabled} type={typeButton}>
				{hasIcon && !hasLabel && <span className={iconClasses}>{icon}</span>}
				{hasIcon && hasLabel && (
					<>
						<span className={styles['button-label']}>{label}</span>
						<span className={styles['button-icon']}>{icon}</span>
					</>
				)}
				{!hasIcon && hasLabel && label}
			</button>
		);
	},
);

export default UniversalButton;
