import React, { useEffect, useRef, useState } from 'react';
import ConfirmationWindow from '../../../ConfirmationWindow/ConfirmationWindow';
import AddContainers from '../../AddContainers/AddContainers';
import Containers from '../../Containers/Containers';
import styles from '../style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteOneContainerFromExistingOrder,
	getContainersInOrder,
	setChoosenContainerForChangeCancelVariant,
	setSaveLinkContainersButtonActive,
} from '../../../../store/slices/orderSlice';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../store/slices/sideBarSlice';
import ChangeContainerForm from '../../ChangeContainerForm/ChangeContainerForm';
import { useParams } from 'react-router-dom';
import RequireUI from '../../../../hoc/RequireUI';

export default function ImportContextComponents({ containersList }) {
	const [confWindow, setConfWindow] = useState(false);
	const dispatch = useDispatch();
	const { currentOrderContainerList, putLinkContainers_success, currentOrderData } = useSelector((state) => state.order);
	//! TEST
	const viewMobContainers = false;
	const mob = false;

	const { terminalId, orderId } = useParams();

	const handleRemoveFromOrder = (container) => {
		dispatch(setSaveLinkContainersButtonActive(true));
		dispatch(deleteOneContainerFromExistingOrder({ type: 'import', data: container }));
	};

	//* Кастомный хук
	const useUpdateEffect = (effect, deps) => {
		const isInitialMount = useRef(true);
		useEffect(() => {
			if (isInitialMount.current) {
				isInitialMount.current = false;
			} else {
				return effect();
			}
		}, deps);
	};

	const handleRemoveAllCard = () => {
		console.log('PRIVET');
	};
	const handleRemoveCard = () => {
		console.log('PRIVET2');
	};
	const setRemoveAllCard = () => {
		console.log('setRemoveAllCard');
	};

	const setPage = () => {
		console.log('setData');
	};
	const setViewMobContainers = () => {
		console.log('setViewMobContainers');
	};
	const setContainerEL = () => {
		console.log('setContainerEL');
	};

	// console.log(currentOrderData, 'CUREBT');
	// useUpdateEffect(() => {
	// 	dispatch(getContainersInOrder({ terminal_id: terminalId, order_id: orderId, client_id: currentOrderData?.client?.id, page: 1 }));
	// 	console.log(currentOrderContainerList, 'cur ord cont lis');
	// }, [putLinkContainers_success]);

	const handleChangeContainerInOrder = (e, container) => {
		console.log('IMPORT AFTER ');
		dispatch(setSideBarActive(true));
		dispatch(setCurrentElementForSideBar(<ChangeContainerForm type={'import_after_order_containers'} />));
		dispatch(setChoosenContainerForChangeCancelVariant(container));
	};

	const containerEL = { container_number: 1 };
	return (
		<div className={mob ? styles.mob_account__step : styles.account__step}>
			{confWindow && (
				<ConfirmationWindow
					titleText={`Вы уверены, что хотите удалить ${'контейнер №' + containerEL.container_number}`}
					acceptBtnText={'Да, удалить'}
					acceptBtnClick={handleRemoveFromOrder}
					rejectBtnText={'Отменить'}
					rejectBtnClick={() => setConfWindow(false)}
				/>
			)}
			<RequireUI type={{ name: 'AddContainers' }} swap={<div className={styles.swap}>Вам недоступно добавление контейнеров</div>}>
				<AddContainers
					type={'import_after'}
					data={containersList}
					setPage={setPage}
					setViewMobContainers={setViewMobContainers}
					mob={mob}
				/>
			</RequireUI>

			{!mob && (
				<Containers
					type={'added'}
					setRemoveAllCard={setRemoveAllCard}
					handleChangeContainer={handleChangeContainerInOrder}
					setContainerEL={setContainerEL}
					setConfWindow={setConfWindow}
					handleRemoveFromOrder={handleRemoveFromOrder}
					data={containersList}
					mob={mob}
					setPage={setPage}
				/>
			)}
			{viewMobContainers && (
				<Containers
					type={'added'}
					setRemoveAllCard={setRemoveAllCard}
					handleChangeContainer={handleChangeContainerInOrder}
					handleRemoveFromOrder={handleRemoveFromOrder}
					data={containersList}
					mob={mob}
					setViewMobContainers={setViewMobContainers}
					setPage={setPage}
				/>
			)}
		</div>
	);
}
