import React, { useEffect, useState } from 'react';
import styles from '../Modals.module.scss';
import { IconEmpls, IconUser } from '../../../../../../components/Icons';
import { Notification, Preloader, UniversalButton } from '../../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
	setInputChange,
	setNewClientCorporateAddress,
	setNewClientCorporateName,
	setNewClientEmail,
} from '../../../../../../store/slices/clientSlice';
import ModalClientEmpls from './ModalClientEmpls';
import ModalClientInputs from './ModalClientInputs';

function ModalClient({ type }) {
	// type: clients/terminalclients

	//! Нужно для правильной работы input в useForm при анмаунте компонента
	const [useFormPlug, setUseFormPlug] = useState('');

	const dispatch = useDispatch();
	const {
		newClientCorporateName,
		newClientCorporateAddress,
		newClientEmail,
		newClientContractNumber,
		currentClient,
		modalType,
		getClient_pending,
	} = useSelector((state) => state.client);

	const [isActive, setIsActive] = useState(false);
	const [disabledBtn, setDisabledBtn] = useState(true);

	const onClose = () => {
		setIsActive(false);
	};

	// ! Если нет номера контракта кнопки принять и отклонить недоступны
	useEffect(() => {
		if (newClientContractNumber === '12345') {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
	}, [newClientContractNumber]);

	// ! Отслеживание изменений в инпутах
	useEffect(() => {
		dispatch(setNewClientCorporateAddress(currentClient?.organization_address));
		dispatch(setNewClientCorporateName(currentClient?.organization_name));
		dispatch(setNewClientEmail(currentClient?.contact_email));
	}, [currentClient, dispatch]);

	//! Чтобы в инпутах отображались данные при переключении между вкладками, нужно сделать это
	useEffect(() => {
		setUseFormPlug(newClientCorporateName);
	}, [newClientCorporateName, newClientEmail, newClientCorporateAddress, dispatch]);

	// Активное окно
	const [activeTab, setActiveTab] = useState(1);

	// Переключение вкладок
	const handleOpenTab = (tabNumber) => {
		setActiveTab(tabNumber);
		dispatch(setInputChange(false));
	};

	return (
		<>
			{getClient_pending ? (
				<div className={styles.modal__preloader}>
					<Preloader />
				</div>
			) : (
				<>
					<Notification
						text={'Ошибка'}
						descr={'Поля не должны быть пустыми'}
						onClose={onClose}
						isActive={isActive}
						isStatus={'error'}
					/>
					<div className={styles.modal__card}>
						<div className={styles.MainCardInfo}>
							<div className={styles.card__chooseModalsBtns}>
								<UniversalButton
									icon={<IconUser color={'primary_white'} />}
									color={activeTab == 1 ? 'accent' : 'primary'}
									size={'medium'}
									label={'Основное'}
									onClick={() => handleOpenTab(1)}
								/>

								{modalType === 'update' && (
									<UniversalButton
										icon={<IconEmpls color={'primary_white'} />}
										color={activeTab == 2 ? 'accent' : 'primary'}
										size={'medium'}
										label={'Сотрудники'}
										onClick={() => handleOpenTab(2)}
									/>
								)}
							</div>

							{activeTab == 1 && <ModalClientInputs type={'terminal'} />}

							{modalType === 'update' && (
								<>{activeTab == 2 && <ModalClientEmpls type={'terminal'} client={currentClient} />}</>
							)}
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default ModalClient;
