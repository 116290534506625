//! Получение контейнеров заявки
// получение списка контейниров

import axios from './configAxios';

export const getContainersInOrderApi = async (terminal_id, order_id, client_id, page, totalContainers) => {
	console.log(terminal_id, order_id, 'Контейнерс ин ордер');
	return axios.get(
		`/?&terminal_id=${terminal_id}&order_id=${order_id}&client_id=${client_id}&page_size=${totalContainers}`,
	);
};
