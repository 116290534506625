import { useEffect, useState } from 'react';
import { Preloader, Search, UniversalButton } from '../../../../../../components';
import styles from '../Modals.module.scss';
import { IconPlus } from '../../../../../../components/Icons';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MainInput from '../../../../../../components/Inputs/MainInput/MainInput';
import {
	getContract,
	getContractsList,
	postCreateContract,
	setPostCreateContract_success,
} from '../../../../../../store/slices/clientSlice';
import { useDropzone } from 'react-dropzone';
import IconFileTextBig from '../../../../../../assets/images/IconFileTextBig';
import { setNewClientContractNumber, setNewClientContractDate } from '../../../../../../store/slices/clientSlice';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../../../store/slices/sideBarSlice';
import InputCalendar from '../../../../../../components/Inputs/InputCalendar';

// Создание нового документа
export function ModalNewContract({ typeContract }) {
	const [files, setFiles] = useState([]);
	// Неактивность кнопки сброса
	const [resetFiles, setResetFiles] = useState(false);
	const dispatch = useDispatch();
	let { clientId } = useParams();

	const {
		register,
		setFocus,
		setValue,
		formState: { errors },
		handleSubmit,
	} = useForm({
		mode: 'onChange',
	});

	const { newClientContractNumber, newClientContractDate, postCreateContract_success, postCreateContract_pending } = useSelector(
		(state) => state.client,
	);

	// Неактивность кнопки загрузки
	const [disabledUpload, setDisabledUpload] = useState(true);

	// Дропзона
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		// Максимальное количество файлов
		maxFiles: 1,
		multiple: false,
		// Разрешенные типы файлов
		// accept: {
		// 	'image/jpeg': [],
		// 	'image/png': [],
		// },
		onDrop: (acceptedFiles) => {
			setFiles([...files, ...acceptedFiles]);
		},
	});

	// Показ добавленных файлов
	let acceptedFileItems = files.map((file) => (
		<li key={file.path}>
			Файл {file.name} {postCreateContract_success && 'успешно загружен'}
		</li>
	));

	// Активность кнопки загрузки
	useEffect(() => {
		if (files.length > 0 && newClientContractNumber.length > 4 && newClientContractDate) {
			setDisabledUpload(false);
		} else {
			setDisabledUpload(true);
		}
	}, [files, newClientContractNumber, newClientContractDate]);

	// Активность кнопки сброса
	useEffect(() => {
		if (files.length > 0) {
			setResetFiles(true);
		} else {
			setResetFiles(false);
		}
	}, [files]);

	// Отправка файлов на сервер
	const handleCreateContract = () => {
		let requestDataContract = new FormData();
		requestDataContract.append('contract_number', newClientContractNumber);

		// Форматирование даты подписания
		const day = newClientContractDate['$D'] < 10 ? `0${newClientContractDate['$D']}` : `${newClientContractDate['$D']}`;
		const month = newClientContractDate['$M'] < 9 ? `0${newClientContractDate['$M'] + 1}` : `${newClientContractDate['$M'] + 1}`;
		requestDataContract.append('date_created', `${newClientContractDate['$y']}-${month}-${day}`);

		requestDataContract.append('contract_file', files[0]);

		if (typeContract === 'main') {
			requestDataContract.append('contract_type', '1');
		} else {
			requestDataContract.append('contract_type', '2');
		}
		dispatch(postCreateContract({ org_id: clientId, requestDataContract }));
	};

	// Сбросить файлы
	const handleResetFile = () => {
		if (postCreateContract_success) {
			setFiles([]);
			dispatch(setNewClientContractNumber(''));
			dispatch(setNewClientContractDate(''));
			dispatch(setPostCreateContract_success(false));
		} else {
			setFiles([]);
		}
	};

	return (
		<div className={styles.modalContracts}>
			<div className={styles.stackClients_header}>
				{typeContract === 'main' ? <h5>Загрузите договор для регистрации</h5> : <h5>Добавление приложения</h5>}
			</div>

			<form
				onSubmit={handleSubmit(handleCreateContract)}
				className={`${styles.modalContracts__form} ${typeContract === 'main' ? '' : styles.sideBar}`}
			>
				<div className={styles.modalNewContract__inputs}>
					<MainInput
						title={typeContract === 'main' ? 'Номер договора' : 'Номер документа'}
						errorTitle={'Некорректный номер договора'}
						name="numberContract"
						setValue={setValue}
						register={register}
						setFocus={setFocus}
						handleFieldChange={(e) => dispatch(setNewClientContractNumber(e.target.value))}
						value={newClientContractNumber ? newClientContractNumber : ''}
						errors={errors}
						rules={{
							required: 'Обязательное поле',
							minLength: {
								value: 5,
								message: 'Минимум 5 символов',
							},
						}}
						placeholder={'12345'}
					/>
					<InputCalendar
						label={'Дата подписания'}
						value={newClientContractDate}
						setValue={setNewClientContractDate}
						required={true}
					/>
				</div>
				<div className={styles.dropzone__container}>
					<h6>Загрузите скан документа</h6>
					{/* Дропзона */}
					<div
						{...getRootProps({
							className: `${styles.dropzone__block} ${postCreateContract_success && styles.dropzone__block_success}`,
						})}
					>
						{acceptedFileItems.length > 0 ? (
							<>
								<ul>{acceptedFileItems}</ul>
								{postCreateContract_pending && <Preloader />}
							</>
						) : (
							<>
								<input {...getInputProps()} />
								<div className={styles.dropzone__icon}>
									<IconFileTextBig size={'120'} color={postCreateContract_success ? '#149527' : '#443A8E'} />
								</div>
								<p className={styles.dropzone__text}>
									Перенесите документ в эту область <br /> или <br />
									<span className={styles.text__underline}>выберите файл</span>
								</p>
							</>
						)}
					</div>
					<div className={styles.dropzone__btn}>
						{resetFiles && (
							<UniversalButton
								label={postCreateContract_success ? 'Загрузить новый документ' : 'Заменить документ'}
								color={'primary'}
								size={'large'}
								onClick={handleResetFile}
								typeButton="button"
							/>
						)}
						<UniversalButton label={'Загрузить документ'} color={'succesful'} size={'large'} disabled={disabledUpload} />
					</div>
				</div>
			</form>
		</div>
	);
}

// Список документов
export function ModalContractsList() {
	const dispatch = useDispatch();
	let { clientId } = useParams();
	const { contractsList, getContractsList_pending, currentContract, getContract_pending } = useSelector((state) => state.client);

	// Добавить новый договор
	const handleNewContract = () => {
		dispatch(setCurrentElementForSideBar(<ModalNewContract typeContract={'annex'} />));
		dispatch(setSideBarActive(true));
		dispatch(setNewClientContractNumber(''));
		dispatch(setNewClientContractDate(''));
		dispatch(setPostCreateContract_success(false));
	};

	// Получение контракта
	const handleContract = (el) => {
		dispatch(getContract({ org_id: clientId, contract_id: el.id }));
	};

	// Открытие документа в новой вкладке
	useEffect(() => {
		if (currentContract) {
			window.open(currentContract.url, '_blank');
		}
	}, [currentContract]);

	return (
		<div className={styles.modalContracts}>
			<div className={styles.stackClients_header}>
				<h5>Документы</h5>
				<UniversalButton
					label={'Добавить приложение'}
					icon={<IconPlus size={'medium'} color={'#5C53A7'} />}
					size={'small'}
					color={'primary'}
					type={'with-border'}
					onClick={handleNewContract}
				/>
			</div>
			<div className={styles.card__userInfo}>
				<div className={styles.card__contracts}>
					<Search />
					{getContractsList_pending ? (
						<div className={styles.preloader_inwrapper}>
							<Preloader />
						</div>
					) : (
						<div className={styles.contracts__list}>
							{contractsList?.map((el, ind) => (
								<div className={styles.contracts__item} key={ind} onClick={() => handleContract(el)}>
									<p>
										{el.contract_type} №{el.contract_number} от {el.date_created.split('-').reverse().join('.')}
									</p>
								</div>
							))}
						</div>
					)}
				</div>
				{getContract_pending && (
					<div className={styles.preloaderContract}>
						<Preloader />
					</div>
				)}
			</div>
		</div>
	);
}

// Главное модальное окно
function ModalClientContracts() {
	const dispatch = useDispatch();
	let { clientId } = useParams();
	const { contractsList, getContractsList_pending } = useSelector((state) => state.client);

	// Получение документов по id клиента
	useEffect(() => {
		dispatch(getContractsList({ org_id: clientId }));
	}, [clientId, dispatch]);

	return (
		<>
			{getContractsList_pending ? (
				<div className={styles.preloader_inwrapper}>
					<Preloader />
				</div>
			) : contractsList?.some((obj) => obj.contract_type === 'Договор') ? (
				<ModalContractsList />
			) : (
				<ModalNewContract typeContract={'main'} />
			)}
		</>
	);
}

export default ModalClientContracts;
