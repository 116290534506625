import styles from './CheckAct.module.scss';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOneEntryreport } from '../../../../../../../store/slices/containerAcceptanceSlice';
import MainApplication from '../../components/MainApplication/MainApplication';
import { ClientApplication, PhotoFixApplication, DamagesApplication } from '../../components/MainApplication/InfoApplication';

function CheckAct() {
	const dispatch = useDispatch();
	const { reportId } = useParams();
	const { entryOneReport, damagesList } = useSelector((state) => state.containerAccept);

	const actId = reportId;

	useEffect(() => {
		dispatch(getOneEntryreport({ actId }));
	}, []);

	return (
		<div className={styles.checkAct}>
			<ClientApplication />
			<MainApplication />
			<PhotoFixApplication />
			<DamagesApplication />
		</div>
	);
}

export default CheckAct;
