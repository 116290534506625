const initialState = {
    getOneUserSuccess: false,
    deleteUserSuccess: false,
    putUserSuccess: false,
    postClientSuccess: false,
    putClientSuccess: false,
    userInfo: []
}

const oneUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ONEUSER_SUCCESS':
            return { ...state, userInfo: action.payload, getOneUserSuccess: true };
        case 'DELETE_USER_SUCCESS':
            return { ...state, deleteUserSuccess: true }
        case 'PUT_USER_SUCCESS':
            return { ...state, putUserSuccess: true };
        case 'POST_CLIENT_SUCCESS':
            return { ...state, postClientSuccess: true };
        case 'PUT_CLIENT_SUCCESS':
            return { ...state, putClientSuccess: true };
        default:
            return state;
    }
}

export default oneUserReducer