import { useState } from 'react';
import styles from './Step2.module.scss';
import { UniversalButton } from '../../../../../../../../components';
import { IconPlus, IconPrint, IconTelegram } from '../../../../../../../../components/Icons';

const Card = ({ title, description }) => {
	const [isChecked, setIsChecked] = useState(false);

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	const handleCardClick = (e) => {
		if (e.target.type !== 'checkbox') {
			setIsChecked(!isChecked);
		}
	};

	return (
		<div className={styles.card} onClick={handleCardClick}>
			<div className={styles.cardHeader}>
				<span className={styles.cardTitle}>{title}</span>
				<label className={styles.customCheckbox}>
					<input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} className={styles.checkboxInput} />
					<span className={styles.checkboxCustom}></span>
				</label>
			</div>
			<div className={styles.cardDescription}>{description}</div>
		</div>
	);
};

const Step2 = () => {
	const cardData = [
		{ title: 'Возможный документ 1', description: 'This is the description for card 1.' },
		{ title: 'Возможный документ 2', description: 'This is the description for card 2.' },
		{ title: 'Возможный документ 3', description: 'This is the description for card 3.' },
		{ title: 'Возможный документ 4', description: 'This is the description for card 4.' },
		{ title: 'Возможный документ 5', description: 'This is the description for card 5.' },
		{ title: 'Возможный документ 6', description: 'This is the description for card 6.' },
		{ title: 'Возможный документ 7', description: 'This is the description for card 7.' },
		{ title: 'Возможный документ 8', description: 'This is the description for card 8.' },
		{ title: 'Возможный документ 9', description: 'This is the description for card 9.' },
		{ title: 'Возможный документ 10', description: 'This is the description for card 10.' },
	];
	return (
		<div className={styles.stepContainer}>
			<p className={styles.title}>Документы</p>
			<div className={styles.documentContainer}>
				{cardData.map((card, index) => (
					<Card key={index} title={card.title} description={card.description} />
				))}
			</div>
			<div className={styles.actionButton}>
				<div className={styles.actionPrintSend}>
					<UniversalButton
						label={'Отправить все'}
						color={'primary'}
						size={'medium'}
						icon={<IconTelegram color={'white'} />}
					/>
					<UniversalButton
						label={'Распечатать все'}
						color={'primary'}
						size={'medium'}
						icon={<IconPrint color={'white'} />}
					/>
				</div>
				<UniversalButton
					label={'Принять другой контейнер'}
					color={'accent'}
					size={'medium'}
					icon={<IconPlus color={'white'} />}
				/>
			</div>
		</div>
	);
};

export default Step2;
