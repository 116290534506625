import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UniversalButton from '../../../Buttons/UniversalButton/UniversalButton';
import { IconDisketa } from '../../../Icons';

import styles from './style.module.scss';
import ConfirmationWindow from '../../../ConfirmationWindow/ConfirmationWindow';
import OrderBlock from '../../OrderBlock/OrderBlock';
import ContainersItem from '../../Containers/ContainersItem';
import {
	clearCurrentOrderContainerList,
	deleteOneContainerFromExistingOrder,
	setChoosenContainerForChangeCancelVariant,
	setSaveLinkContainersButtonActive,
} from '../../../../store/slices/orderSlice';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../store/slices/sideBarSlice';
import ChangeContainerForm from '../../ChangeContainerForm/ChangeContainerForm';
export default function ContainersInOrder() {
	const dispatch = useDispatch();
	const { currentOrderData, currentOrderContainerList } = useSelector((state) => state.order);

	//! Функция для очистки всех контейнеров
	const clearCurrentOrderContainerListFunc = () => {
		dispatch(setSaveLinkContainersButtonActive(true));
		for (let i = 0; i <= currentOrderContainerList.length - 1; i++) {
			dispatch(deleteOneContainerFromExistingOrder({ type: 'export', data: currentOrderContainerList[i] }));
		}
		dispatch(clearCurrentOrderContainerList());
	};

	useEffect(() => {
		console.log(currentOrderContainerList, '');
	}, []);

	const handleRemoveFromOrder = (container) => {
		dispatch(setSaveLinkContainersButtonActive(true));
		dispatch(deleteOneContainerFromExistingOrder({ type: 'export', data: container }));
		// state.organizationContainerList = state.organizationContainerList.filter((el) => el.id !== action.payload.data.id);
		// state.currentOrderContainerList = state.currentOrderContainerList.concat(action.payload.data);
		// console.log(container_id);
	};
	const handleChangeContainerInOrder = (e, container) => {
		console.log('EXPORT CONTAINERLIST ->  CONTAINERS IN OORDER CHANGE CONTAINER', container);
		dispatch(setSideBarActive(true));
		dispatch(setCurrentElementForSideBar(<ChangeContainerForm type={'export_after_order_containers'} />));
		dispatch(setChoosenContainerForChangeCancelVariant(container));
	};
	return (
		<div className={styles.containers}>
			{/* {confWindow || removeAllCard ? (
				<ConfirmationWindow
					titleText={`Вы уверены, что хотите удалить ${removeAllCard ? 'все контейнеры?' : 'контейнер №' + containerEL.container_number}`}
					acceptBtnText={'Да, удалить'}
					acceptBtnClick={removeAllCard ? handleRemoveAllCard : handleRemoveCard}
					rejectBtnText={'Отменить'}
					rejectBtnClick={() => (removeAllCard ? setRemoveAllCard(false) : setConfWindow(false))}
				/>
			) : (
				<></>
			)} */}
			<OrderBlock
				title={`Выбрано: ${currentOrderContainerList?.length}`}
				topLineBtn={
					currentOrderContainerList?.length === 0 ? (
						<></>
					) : (
						<UniversalButton
							size={'small'}
							color={'error'}
							type={'with-border'}
							label={'Очистить'}
							onClick={() => clearCurrentOrderContainerListFunc()}
						/>
					)
				}
				children={
					<div className={styles.containers__list}>
						{currentOrderContainerList?.length === 0 ? (
							<div className={styles.emptyList}>
								<p>Список контейнеров пуст</p>
								<p>Заполните форму, чтобы добавить контейнеры </p>
							</div>
						) : (
							<>
								<div>
									{currentOrderContainerList?.map((el, ind) => (
										<ContainersItem
											type={'added'}
											handleChange={handleChangeContainerInOrder}
											handleRemoveFromOrder={handleRemoveFromOrder}
											data={el}
											key={ind}
											el={el}
										/>
									))}
								</div>

								{/* <UniversalButton
									icon={<IconDisketa size={'medium'} color={'core-state-success'} />}
									size={'small'}
									label={'Сохранить'}
									color={'succesful'}
									type={'with-border'}
								/> */}
							</>
						)}
					</div>
				}
			/>
		</div>
	);
}
