import { useCallback, useState } from "react";
import "./Switch.scss";

function Switch({ title }) {
  const [check, setCheck] = useState(false);
  const onCheck = useCallback(() => {
    setCheck(!check);
  }, [setCheck, check]);

  return (
    <label>
      <input
        onClick={onCheck}
        type="checkbox"
        className="checkbox"
      />
      <div className={check ? "switch switchOn" : "switch"}>
        <p>{title}</p>
      </div>
    </label>
  );
}

export default Switch;



