import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconMail({ size, color }) {
  return (
    <div className={styles.icon}>
      <svg
        width={IconSize(size)}
        height={IconSize(size)}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99999 10.8333L9.56989 11.4478C9.82813 11.6285 10.1718 11.6285 10.4301 11.4478L9.99999 10.8333ZM3.33332 4.08333H16.6667V2.58333H3.33332V4.08333ZM16.6667 4.08333C17.1691 4.08333 17.5833 4.49754 17.5833 4.99999H19.0833C19.0833 3.66911 17.9975 2.58333 16.6667 2.58333V4.08333ZM17.5833 4.99999V15H19.0833V4.99999H17.5833ZM17.5833 15C17.5833 15.5024 17.1691 15.9167 16.6667 15.9167V17.4167C17.9975 17.4167 19.0833 16.3309 19.0833 15H17.5833ZM16.6667 15.9167H3.33332V17.4167H16.6667V15.9167ZM3.33332 15.9167C2.83087 15.9167 2.41666 15.5024 2.41666 15H0.916656C0.916656 16.3309 2.00244 17.4167 3.33332 17.4167V15.9167ZM2.41666 15V4.99999H0.916656V15H2.41666ZM2.41666 4.99999C2.41666 4.49754 2.83087 4.08333 3.33332 4.08333V2.58333C2.00244 2.58333 0.916656 3.66911 0.916656 4.99999H2.41666ZM17.9032 4.38557L9.56989 10.2189L10.4301 11.4478L18.7634 5.61442L17.9032 4.38557ZM10.4301 10.2189L2.09675 4.38557L1.23656 5.61442L9.56989 11.4478L10.4301 10.2189Z"
          fill={IconColor(color)}
        />
      </svg>
    </div>
  );
}

export default IconMail;
