export const modelPathMatch = [
	{ model: 'organization', path: 'clients' },
	{ model: 'container', path: 'summary' },
	{ model: 'order', path: 'orders' },
	{ model: 'terminal', path: 'terminals' },
	{ model: 'customuser', path: 'transporters' },
	{ model: 'group', path: 'createJob' },
	{ model: 'orguser', path: 'empls' },
	{ model: 'contract', path: 'reports' },
];
