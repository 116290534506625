import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';
import style from './TimeDatePicker.module.scss';
import UniversalButton from '../../../Buttons/UniversalButton/UniversalButton';
import { IconCalendar } from '../../../Icons';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import ru from 'date-fns/locale/ru';
import './style.css';

const TimeDatePicker = ({ startDate, setStartDate }) => {
	const [open, setOpen] = useState(false);

	// const [startDate, setStartDate] = useState(setHours(setMinutes(new Date(), 30), 16));

	const handleOpenDatePicker = (e) => {
		e.preventDefault();
		setOpen(!open);
	};

	return (
		<div className={style.timeContainer}>
			<UniversalButton
				color={'primary'}
				type={'button'}
				size={'large'}
				icon={<IconCalendar color={'white'} />}
				onClick={handleOpenDatePicker}
			/>
			{open && (
				<div className={style.time}>
					<DatePicker
						inline
						locale={ru}
						selected={startDate}
						onChange={(date) => setStartDate(date)}
						showTimeSelect
						excludeTimes={[
							setHours(setMinutes(new Date(), 0), 17),
							setHours(setMinutes(new Date(), 30), 18),
							setHours(setMinutes(new Date(), 30), 19),
							setHours(setMinutes(new Date(), 30), 17),
						]}
						dateFormat="d MMMM, yyyy h:mm"
					/>
				</div>
			)}
		</div>
	);
};

export default TimeDatePicker;
