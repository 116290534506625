import React, { useEffect } from "react";
import styles from "./Notification.module.scss";
import IconCheckCircle from "./Icons/IconCheckCircle";
import IconTriangle from "./Icons/IconTriangle";
import IconCircle from "./Icons/IconCircle";
import { IconCross } from "../Icons";

function Notification({ text, descr, onClose, isActive, isStatus }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [isActive]);

  return (
    <div
      className={`${styles.notificationBlock} ${isActive ? styles.active : ""}`}
    >
      <div className={styles.stackOne}>
        {isStatus === "success" ? (
          <IconCheckCircle />
        ) : isStatus === "error" ? (
          <IconTriangle />
        ) : (
          <IconCircle />
        )}

        <div className={styles.stackTwo}>
          <p
            className={`${styles.titleNotification} ${isStatus === "success"
              ? styles.success
              : isStatus === "error"
                ? styles.error
                : ""
              }`}
          >
            {text}
          </p>
          <p
            className={`${styles.textNotification} ${isStatus === "success"
              ? styles.success
              : isStatus === "error"
                ? styles.error
                : ""
              }`}
          >
            {descr}
          </p>
        </div>
        <div className={styles.notificationBlock__close}>
          <IconCross onClick={onClose} size={"largeS"} color={"black"} />
        </div>
      </div>
    </div>
  );
}

export default Notification;
