import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import RViewerJS from 'viewerjs-react';
import { useDispatch, useSelector } from 'react-redux';
import S3Manager from '../../../../../../../helpers/s3storage';
import {
	putDamagesInEntryreport,
	setDamageListDeletePhoto,
	setSelectedDamage,
} from '../../../../../../../store/slices/containerAcceptanceSlice';
import { UniversalButton } from '../../../../../../../components';
import { IconTrash } from '../../../../../../../components/Icons';
import 'viewerjs-react/dist/index.css';
import styles from './PhotoViewerComponent.module.scss';

const imageCache = {};

const PhotoViewerComponent = ({ imagesList }) => {
	const [imageUrls, setImageUrls] = useState([]);
	const [fileNames, setFileNames] = useState([]);
	const imageUploader = useMemo(() => new S3Manager('damages'), []);
	const { reportId } = useParams();
	const dispatch = useDispatch();
	const { selectedDamage } = useSelector((state) => state.containerAccept);

	const uuid = selectedDamage.uuid;

	const handleDelete = async (fileName) => {
		try {
			await imageUploader.deleteImage(fileName);
			const newUrls = imageUrls.filter((url, idx) => fileNames[idx] !== fileName);
			const newNames = fileNames.filter((name) => name !== fileName);
			setImageUrls(newUrls);
			setFileNames(newNames);
			dispatch(setSelectedDamage({ ...selectedDamage, pictures: selectedDamage.pictures.filter((el) => el.filename !== fileName) }));
			dispatch(setDamageListDeletePhoto({ uuid, filename: fileName }));
			dispatch(putDamagesInEntryreport({ reportId: reportId }));
		} catch (error) {
			console.error('Error deleting image:', error);
		}
	};

	const fetchImageUrls = useCallback(async () => {
		const urls = await Promise.all(
			imagesList?.map(async (picture) => {
				try {
					console.log(`Fetching image for filename: ${picture.filename}`);
					if (imageCache[picture.filename]) {
						return imageCache[picture.filename];
					} else {
						const url = await imageUploader.getImage(picture.filename);
						imageCache[picture.filename] = url;
						return url;
					}
				} catch (error) {
					console.error(`Error getting image for ${picture.filename}:`, error.message);
					return null; // Возвращаем null в случае ошибки
				}
			}),
		);
		const validUrls = urls.filter((url) => url !== null); // Фильтруем недействительные URL
		const validFileNames = imagesList.filter((picture, idx) => urls[idx] !== null).map((picture) => picture.filename);
		setImageUrls(validUrls);
		setFileNames(validFileNames);
	}, [imagesList, imageUploader]);

	useEffect(() => {
		fetchImageUrls();
	}, [fetchImageUrls]);

	return (
		<div className={styles.photoContainer}>
			<RViewerJS>
				{imageUrls.map((url, idx) => (
					<div key={`image_wrapper_${idx}`} className={styles.onePhotoCard}>
						<img src={url} alt="example" />
						<div>
							<UniversalButton
								icon={<IconTrash size={'large'} color={'white'} />}
								typeButton={'button'}
								onClick={() => handleDelete(fileNames[idx])}
							/>
						</div>
					</div>
				))}
			</RViewerJS>
		</div>
	);
};

export default PhotoViewerComponent;
