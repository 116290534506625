import React, { useEffect } from 'react';
import styles from './DamageList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import DamageCard from './components/DamageCard';
import { setSelectedDamage, setPage, setDamagesList } from '../../../../../../../store/slices/containerAcceptanceSlice';

function DamageList(props) {
	const dispatch = useDispatch();
	const { entryOneReport } = useSelector((state) => state.containerAccept);

	const handleOpenDamagePage = (item) => {
		dispatch(setPage(8));
		dispatch(setSelectedDamage(item));
	};

	useEffect(() => {
		dispatch(setDamagesList(entryOneReport?.damages));
	}, []);

	return (
		<div className={styles.main_block_damageList}>
			<div className={styles.damage_list}>
				{entryOneReport?.damages?.map((item, index) => (
					<DamageCard key={item.uuid} index={index + 1} onClick={() => handleOpenDamagePage(item)} damage={item} />
				))}
			</div>
		</div>
	);
}

export default DamageList;
