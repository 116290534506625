import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOneUser } from "../../api/erp/getOneUser";
import { delUser } from "../../api/erp/deleteUser";
import { putUser } from "../../api/erp/putUser";
import { postNewClient } from "../../api/erp/postClient";

export const getOneUserApi = createAsyncThunk(
  "erp/getOneUserApi",
  async ({ userId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getOneUser(userId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const postClient = createAsyncThunk(
  "erp/postClient",
  async ({ adressId, requestData }, { dispatch, rejectWithValue }) => {
    try {
      const response = await postNewClient(adressId, requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "erp/deleteUser",
  async ({ userId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await delUser(userId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const putUserAction = createAsyncThunk(
  "erp/putUserAction",
  async ({ adressId, requestData }, { dispatch, rejectWithValue }) => {
    try {
      const response = await putUser(adressId, requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const erpSlice = createSlice({
  name: "erp",
  initialState: {
    getOneUserSuccess: false,
    deleteUserSuccess: false,
    putUserSuccess: false,
    postClientSuccess: false,
    userInfo: [],
  },

  reducers: {
    // getOneUserSuccess(state, action) {
    // },
    // postClientSuccess(state, action){
    // },
    // deleteUserSuccess(state, action){
    // },
    // putUserSuccess(state, action){
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneUserApi.fulfilled, (state, action) => {
        state.userInfo = action.payload;
        state.getOneUserSuccess = true;
      })
      .addCase(getOneUserApi.pending, (state, action) => {
        state.getOneUserSuccess = false;
      })
      .addCase(getOneUserApi.rejected, (state, action) => {
        state.getOneUserSuccess = false;
      });

    builder
      .addCase(postClient.fulfilled, (state, action) => {
        state.postClientSuccess = true;
      })
      .addCase(postClient.pending, (state, action) => {
        state.postClientSuccess = false;
      })
      .addCase(postClient.rejected, (state, action) => {
        state.postClientSuccess = false;
      });

    builder
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.deleteUserSuccess = true;
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.deleteUserSuccess = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteUserSuccess = false;
      });

    builder
      .addCase(putUserAction.fulfilled, (state, action) => {
        state.putUserSuccess = true;
      })
      .addCase(putUserAction.pending, (state, action) => {
        state.putUserSuccess = false;
      })
      .addCase(putUserAction.rejected, (state, action) => {
        state.putUserSuccess = false;
      });
  },
});

export const {} = erpSlice.actions;
export default erpSlice.reducer;
