import { useEffect, useRef, useState } from "react";
import "../../../../../../assets/styles/Tables.scss";
import { useForm } from "react-hook-form";

function ModalEditSelect({ name, label, options, values, elementName, onSelectedOptionChange }) {
    const { register } = useForm();

    const [showModal, setShowModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedId, setSelectedId] = useState("");

    // открытие модального окна списка
    const handleOpenModal = () => {
        setShowModal(!showModal);
    };

    // значения в инпуты
    useEffect(() => {
        if (values) {
            if (values.every(element => element === values[0])) {
                setSelectedOption(values[0])
            } else {
                setSelectedOption("Разное")
            }
        }
    }, []);

    // функция при изменении selectedOption 
    useEffect(() => {
        onSelectedOptionChange(selectedOption);
    }, [selectedOption, onSelectedOptionChange]);


    // закрытие списка при открытии другого
    const inputSelectRef = useRef(null);

    const closeDropdown = (event) => {
        if (inputSelectRef.current && !inputSelectRef.current.contains(event.target)) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown);

        return () => {
            document.removeEventListener('mousedown', closeDropdown);
        };
    }, []);

    const handleSelection = (option, optionId) => {
        setSelectedOption(option);
        setSelectedId(optionId);
        setShowModal(false);
    };


    return (
        <div ref={inputSelectRef}>
            <div className="editSelect">
                <label className="editSelect__label">
                    {label}
                </label>
                <div
                    onClick={(e) => {
                        handleOpenModal();
                    }}
                    className='editSelect__container'
                    tabIndex="1"
                >
                    <input
                        name={name}
                        {...register(name, {
                            onChange: (e) => setSelectedOption(e.target.value),
                        })}
                        value={selectedOption ? selectedOption : ''}
                        placeholder="Выберите из списка"
                        readOnly={true}
                    />
                    <span className={`arrow ${showModal ? 'dropListArrowUp' : ''}`}></span>
                </div>
            </div>

            {showModal &&
                <div className="selectLaptop">
                    {elementName ? (<div className="optionSelectLaptop">
                        {options.map((el, index) => (
                            <div
                                key={index}
                                className={`optionLaptop ${selectedOption === el.line_name
                                    ? "selectedOption"
                                    : ""
                                    }`}
                                onClick={() => {
                                    handleSelection(el.line_name, el.id);
                                }}
                            >
                                <p className="optionText">{el.line_name}</p>
                            </div>
                        ))}
                    </div>) : (<div className="optionSelectLaptop">
                        {options.map((el, index) => (
                            <div
                                key={index}
                                className={`optionLaptop ${selectedOption === el.name
                                    ? "selectedOption"
                                    : ""
                                    }`}
                                onClick={() => {
                                    handleSelection(el.name, el.id);
                                }}
                            >
                                <p className="optionText">{el.name}</p>
                            </div>
                        ))}
                    </div>)}
                </div>
            }
        </div>

    );
}

export default ModalEditSelect;