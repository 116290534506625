import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconUser({ size, color }) {
  return (
    <div className={styles.icon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={IconSize(size)}
        height={IconSize(size)}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M15.7668 17.5C15.7668 17.9971 16.1698 18.4 16.6668 18.4C17.1639 18.4 17.5668 17.9971 17.5668 17.5H15.7668ZM13.3335 12.5V11.6V12.5ZM6.66683 12.5V11.6V12.5ZM3.3335 15.8333H2.4335H3.3335ZM2.4335 17.5C2.4335 17.9971 2.83644 18.4 3.3335 18.4C3.83055 18.4 4.2335 17.9971 4.2335 17.5H2.4335ZM17.5668 17.5V15.8333H15.7668V17.5H17.5668ZM17.5668 15.8333C17.5668 14.7106 17.1208 13.6338 16.3269 12.8399L15.0541 14.1127C15.5105 14.569 15.7668 15.188 15.7668 15.8333H17.5668ZM16.3269 12.8399C15.533 12.046 14.4562 11.6 13.3335 11.6V13.4C13.9789 13.4 14.5978 13.6564 15.0541 14.1127L16.3269 12.8399ZM13.3335 11.6H6.66683V13.4H13.3335V11.6ZM6.66683 11.6C5.54408 11.6 4.46731 12.046 3.67341 12.8399L4.9462 14.1127C5.40254 13.6564 6.02147 13.4 6.66683 13.4V11.6ZM3.67341 12.8399C2.87951 13.6338 2.4335 14.7106 2.4335 15.8333H4.2335C4.2335 15.188 4.48986 14.569 4.9462 14.1127L3.67341 12.8399ZM2.4335 15.8333V17.5H4.2335V15.8333H2.4335ZM12.4335 5.83333C12.4335 7.17723 11.3441 8.26667 10.0002 8.26667V10.0667C12.3382 10.0667 14.2335 8.17134 14.2335 5.83333H12.4335ZM10.0002 8.26667C8.65627 8.26667 7.56683 7.17723 7.56683 5.83333H5.76683C5.76683 8.17134 7.66216 10.0667 10.0002 10.0667V8.26667ZM7.56683 5.83333C7.56683 4.48944 8.65627 3.4 10.0002 3.4V1.6C7.66216 1.6 5.76683 3.49533 5.76683 5.83333H7.56683ZM10.0002 3.4C11.3441 3.4 12.4335 4.48944 12.4335 5.83333H14.2335C14.2335 3.49533 12.3382 1.6 10.0002 1.6V3.4Z"
          fill={IconColor(color)}
        />
      </svg>
    </div>
  );
}

export default IconUser;
