import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getLogsTableApi } from '../../api/logs/getLogsTableApi';
import { format } from 'date-fns';

// Лист всех действий для таблицы
export const getLogsTable = createAsyncThunk('logs/getLogsTable', async (_, { rejectWithValue, getState }) => {
	try {
		const state = getState();
		const filter = () => {
			let filterArray = [`?page_size=30&page=${state.logs.filters.page}`];

			// По всем ключам filters и если есть фильтр добавляет в массив
			Object.keys(state.logs.filters).forEach((key) => {
				if (key !== 'page' && state.logs.filters[key] !== null) {
					filterArray.push(`&${key}=${state.logs.filters[key]}`);
				}
			});

			// Если ordering непустой то добавляем массив
			if (state.logs.ordering !== 'undefined' && state.logs.ordering !== '-undefined' && state.logs.ordering !== '') {
				filterArray.push(`&ordering=${state.logs.ordering}`);
			}

			// Если globalSearch непустой то добавляем массив
			if (state.logs.globalSearch) {
				filterArray.push(`&search=${state.logs.globalSearch}`);
			}

			return filterArray.join('');
		};

		let response;
		if (state.logs.next && state.logs.fetching) {
			response = await getLogsTableApi(state.logs.next);
		} else if (state.logs.url && state.logs.fetching) {
			response = await getLogsTableApi(filter());
		}
		return { data: response.data, urlExport: filter() };
	} catch (error) {
		return rejectWithValue(error.message);
	}
});

const logsSlice = createSlice({
	name: 'logs',
	initialState: {
		logsList: [],
		countLogsList: 0,
		// Первая загрузка
		firstLoad: false,

		// Infinite Scroll
		fetching: true,
		url: `?page_size=30`,
		next: null,

		// Сортировка, поиск, фильтрация
		ordering: '',
		globalSearch: '',
		filters: {
			page: 1,
			id: null,
			full_name: null,
			user_id: null,
			full_url: null,
			ip_address: null,
			created_at: null,
		},

		// Получение листа
		getLogsTable_success: false,
		getLogsTable_pending: false,
		getLogsTable_error: false,
	},
	reducers: {
		setLogsFetching(state, action) {
			state.fetching = action.payload;
		},

		// Фильтрация
		setFilters(state, action) {
			state.next = null;
			state.logsList = [];
			state.url = '?page_size=30';

			Object.keys(state.filters).forEach((key) => {
				// Если ключ равен 'page', устанавливаем значение 1, иначе null
				state.filters[key] = key === 'page' ? 1 : null;
			});

			// Формирование массива параметров запроса
			function paramsArray(array) {
				array.map((item) => {
					let paramValue;
					if (item.id === 'created_at') {
						// Форматирование даты
						const formatDate = (date) => format(date.toISOString(), 'yyyy-MM-dd');
						state.filters['created_at_from'] = item.value[0]?.$d ? formatDate(item.value[0].$d) : null;
						state.filters['created_at_to'] = item.value[1]?.$d ? formatDate(item.value[1].$d) : null;
					} else {
						state.filters[item.id] = item.value;
					}

					return paramValue !== null ? paramValue : '';
				});
			}

			if (action.payload) {
				paramsArray(action.payload);
			}
		},

		// Сортировка
		setOrdering(state, action) {
			state.next = null;
			state.logsList = [];
			state.url = '?page_size=30';

			function changeSortingMore(value) {
				if (state.ordering === value) {
					state.ordering = '-' + value;
				} else {
					state.ordering = value;
				}
			}

			changeSortingMore(`${action.payload[0]?.id}`);
		},

		// Глобальный поиск
		setGlobalSearch(state, action) {
			state.next = null;
			state.logsList = [];
			state.url = '?page_size=30';
			state.globalSearch = '';

			if (action.payload) {
				state.globalSearch = action.payload;
			} else {
				state.globalSearch = '';
			}
		},
	},

	extraReducers: (builder) => {
		// Получение листа
		builder
			.addCase(getLogsTable.fulfilled, (state, action) => {
				state.getLogsTable_success = true;
				state.getLogsTable_pending = false;
				state.getLogsTable_rejected = false;

				// Infinite scroll
				if (action.payload.data.next) {
					const startIndex = action.payload.data.next?.indexOf('?');
					const trimmedUrl = `${action.payload.data.next?.substring(startIndex)}`;
					state.next = trimmedUrl;
					state.url = '';
				} else {
					state.next = null;
					state.url = '';
				}
				state.fetching = false;
				state.logsList = [...state.logsList, ...action.payload.data.results];
				state.countLogsList = action.payload.data.count;
				state.firstLoad = true;
				state.filterExport = action.payload.urlExport;
			})
			.addCase(getLogsTable.pending, (state, action) => {
				state.getLogsTable_success = false;
				state.getLogsTable_pending = true;
				state.getLogsTable_rejected = false;
			})
			.addCase(getLogsTable.rejected, (state, action) => {
				state.getLogsTable_success = false;
				state.getLogsTable_pending = false;
				state.getLogsTable_rejected = action.payload;
			});
	},
});

export const { setLogsFetching, setFilters, setOrdering, setGlobalSearch } = logsSlice.actions;
export default logsSlice.reducer;
