import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconDamageCrack({ onClick, size, color }) {
	return (
		<div className={styles.icon} onClick={onClick}>
			{/* <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 16 16" fill="none">
				<path
					d="M12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645C12.1583 3.45118 11.8417 3.45118 11.6464 3.64645L12.3536 4.35355ZM3.64645 11.6464C3.45118 11.8417 3.45118 12.1583 3.64645 12.3536C3.84171 12.5488 4.15829 12.5488 4.35355 12.3536L3.64645 11.6464ZM4.35355 3.64645C4.15829 3.45118 3.84171 3.45118 3.64645 3.64645C3.45118 3.84171 3.45118 4.15829 3.64645 4.35355L4.35355 3.64645ZM11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L11.6464 12.3536ZM11.6464 3.64645L7.64645 7.64645L8.35355 8.35355L12.3536 4.35355L11.6464 3.64645ZM7.64645 7.64645L3.64645 11.6464L4.35355 12.3536L8.35355 8.35355L7.64645 7.64645ZM3.64645 4.35355L7.64645 8.35355L8.35355 7.64645L4.35355 3.64645L3.64645 4.35355ZM7.64645 8.35355L11.6464 12.3536L12.3536 11.6464L8.35355 7.64645L7.64645 8.35355Z"
					fill={IconColor(color)}
				/>
			</svg> */}
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12 4H5.77778C4.79594 4 4 4.79594 4 5.77778V18.2222C4 19.2041 4.79594 20 5.77778 20H18.2222C19.2041 20 20 19.2041 20 18.2222V5.77778C20 4.79594 19.2041 4 18.2222 4H12ZM12 4L15.2075 8.0037L12 10.5688L15.2075 13.6002"
					stroke="#8E8E93"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	);
}

export default IconDamageCrack;
