export const tableComponent = {
	header: {
		backgroundColor: (theme) => theme.palette.background.table,
		color: (theme) => theme.palette.text.primary,
	},

	topToolbar: {
		minHeight: '0',
	},

	tablePaper: {
		backgroundColor: (theme) => theme.palette.background.table,
		borderRadius: '8px',
		boxShadow: 'none',
	},

	tableContainer: {
		maxHeight: 'calc(100vh - 188px)',
		minHeight: 'calc(100vh - 188px)',
	},

	footer: {
		minHeight: 'none',
		backgroundColor: (theme) => theme.palette.background.table,

		'div:nth-of-type(2)': {
			padding: '0px',
		},
	},

	toolbarAlertBanner: {
		position: 'relative',
		'> div': {
			maxWidth: '100% !important',
			position: 'absolute',
			right: '0',
			top: '-1px',
			color: (theme) => theme.palette.text.primary,

			button: {
				display: 'none',
			},
		},
	},
};
