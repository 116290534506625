import './Terminals.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import { IconMail, IconCall } from '../../../../components/Icons';
import { terminals } from './terminals';

function Terminals() {
	return (
		<div className="terminals" id="terminals">
			<div className="terminals__slider">
				<Swiper spaceBetween={16} navigation={true} modules={[Navigation]}>
					{terminals.map((item, index) => (
						<SwiperSlide key={index}>
							<h5 className="slide__title">{item.terminalName}</h5>
							<div className="slide__flex">
								<div className="slide__stack mobileBlock">
									<div className="slide__info">
										<div className="stack__title">Диспетчерская:</div>
										<div className="slide__descr">
											<div className="stack__textWithIcon">
												<IconCall color={'primary-active'} size={'largeS'} />
												<p>{item.controlRoom.tel}</p>
											</div>
											<div className="stack__textWithIcon">
												<IconMail color={'primary-active'} size={'largeS'} />
												<p>{item.controlRoom.mail}</p>
											</div>
										</div>
									</div>
									<div className="slide__info">
										<div className="stack__title">Режим работы:</div>
										<div className="stack__text">{item.operatingMode}</div>
									</div>
									<div className="slide__info">
										<div className="stack__title">Адрес:</div>
										<div className="stack__text">{item.adress}</div>
									</div>
								</div>
								<div className="slide__stack mobileNoneBlock">
									<div className="slide__info">
										<div className="stack__title">Услуги:</div>
										<ul className="slide__list">
											{item.services.map((serviceItem, serviceKey) => (
												<li key={serviceKey} className="stack__text">
													{serviceItem}
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
}

export default Terminals;
