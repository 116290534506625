import { buildQueryString } from '../../../newComponents/Filter/utils.js';
import api from '../../api.js';

export const containers = api.injectEndpoints({
	endpoints: (builder) => ({
		getContainersList: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								if (id === 'line') {
									field = 'line__line_name';
								} else if (id === 'org') {
									field = 'order_container__client__organization_name';
								} else {
									field = id;
								}
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;

				return {
					url: `/api/containers/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetContainersListQuery, useLazyGetContainersListQuery } = containers;
