import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box } from '@mui/material';
import CardComponent from '../../../../newComponents/CardComponent/CardComponent';
import { account, accountGridItemHalf } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import Preloader from '../../../../components/Preloader/Preloader';
import { useLazyGetEntryreportsListQuery } from '../../../../store/newApi/entryreports/entryreports.js';
import { formatInitialDate } from '../../../../helpers/formatDate.js';
import BtnDropdown from '../../../../newComponents/BtnDropdown/BtnDropdown.jsx';
import Filter from '../../../../newComponents/Filter/Filter.jsx';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FILTER_TYPES } from '../../../../config/filter';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/entryreports.js';

function AccountEmplInspection() {
	const dispatch = useDispatch();
	const filters = useSelector((state) => state['entryreportsRtk']?.filter) ?? {};
	const [filtering, setFiltering] = useState(false);
	const [trigger, { data, isLoading }] = useLazyGetEntryreportsListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const navigate = useNavigate();

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ ...filters, page: 1, page_size: 30 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		if (Object.keys(filters).length) {
			trigger({ ...filters, page: 1, page_size: 30, uniq: Math.random() });
			setFiltering(true);
			setFetching(true);
		}
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 30 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
		}
	}, [data]);

	const menuItems = [
		{
			text: 'Приём контейнера',
			onClick: () => {
				navigate('/accountEmpl/containerAcceptance');
			},
		},
		{ text: 'Выдача контейнера', onClick: () => {} },
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	return (
		<div className={styles.account__wrap}>
			<Header text={'Акты осмотра'} btnDesktop={[<BtnDropdown mainButtonText="Добавить акт" dropdownItems={menuItems} />]} />

			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					<div className={styles.account__filter}>
						<Filter
							onSetFilter={handleSetFilter}
							onResetFilter={handleResetFilter}
							parameters={[
								{
									name: 'status',
									title: 'Статус',
									type: FILTER_TYPES.CHECKBOX,
									elements: [
										{ value: 1, label: 'Черновик' },
										{ value: 2, label: 'Подтвержден' },
									],
								},
								{
									name: 'type',
									title: 'Тип',
									type: FILTER_TYPES.CHECKBOX,
									elements: [
										{ value: 1, label: 'Прием' },
										{ value: 2, label: 'Вывоз' },
									],
								},
							]}
						/>
					</div>
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemHalf(isDesktop)} key={index}>
									<CardComponent
										title={`Акт №${card?.id}`}
										rightText={card?.status_display}
										status={card?.status_display == 'Подтвержден' ? 'successful' : null}
										leftAdditionalInfo={[
											{
												name: 'Тип:',
												text: `${card?.type_display ? card?.type_display : '-'}`,
											},
											{
												name: 'Контейнер:',
												text: `${card?.container_number_display ? card?.container_number_display : '-'}`,
											},
											{
												name: 'Клиент:',
												text: `${card?.client_display ? card?.client_display : '-'}`,
											},
										]}
										rightAdditionalInfo={[
											{
												name: 'Дата создания:',
												text: `${card?.created_at ? formatInitialDate(card?.created_at) : '-'}`,
											},
										]}
									/>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplInspection;
