import { IconArrowDown, IconEye, IconEyeClose } from '../../Icons';
import './MainInput.scss';
import { useState } from 'react';
import InputMask from 'react-input-mask';

//! Должны передавать value и setValue
//* Пример внедрения:
// <MainInput
//     title={'Логин'}
//     errorTitle={'Некорректный формат логина'}
//     name="login"
//     rules={{
//         required: "Обязательное поле",
//         minLength: {
//             value: 6,
//             message: "Минимум 6 символов",
//         },
//         pattern: {
//             value: /^[^\sа-яА-Я]{6,}$/,
//             message: "Латинские символы",
//         },
//     }}
//     placeholder={'Введите логин'}
// />

function MainInput({
	rules,
	title,
	errorTitle,
	placeholder,
	setInputChange,
	errors,
	setFocus,
	name,
	register,
	setValue,
	value,
	handleFieldChange,
	maxLength,
	modalDropList,
	onClick,
	mask,
	type,
	disabled,
}) {
	const [isChange, setChange] = useState(false);
	const [typeInput, setTypeInput] = useState(name === 'password' || name === 'repeatPassword' || type === 'selectInput' ? type : '');

	// const [internalValue, setInternalValue] = useState(value)
	const handleViewPassword = () => {
		setTypeInput((prevType) => (prevType === 'password' ? 'text' : 'password'));
	};

	//* Пропсы-занчения для инпута
	const commonProps = {
		placeholder,
		type: typeInput !== '' ? typeInput : null,
		...register(name, {
			...rules,
			onChange: (e) => {
				setValue(e.target.value);
				if (handleFieldChange) {
					handleFieldChange(e);
				}
			},
			disabled: disabled,
		}),
		readOnly: typeInput === 'selectInput' ? true : false,
		onBlur: () => setChange(false),
		onFocus: () => setChange(true),
		value,
		maxLength: maxLength || null,
	};

	return (
		<div onClick={(e) => (onClick ? onClick() : null)} onChange={() => (setInputChange ? setInputChange(true) : '')} className="input">
			{errors[name] ? (
				<div className="input__descr">
					<div className="input_titleBlock">
						{rules.required && value?.length === 0 ? <p className="input_required">*</p> : ''}
						<p className="input_error_title">{errors[name] ? errorTitle : null}</p>
					</div>
					<span className={`${value?.length === 0 ? '' : 'pl0'}`}>{errors[name]?.message}</span>
				</div>
			) : (
				title && (
					<div className="input_titleBlock">
						{rules.required && value?.length === 0 ? <p className="input_required">*</p> : ''}
						<p className="input_title">{title}</p>
					</div>
				)
			)}

			<div
				className={`container_input ${isChange ? 'change' : ''} ${
					!errors[name] && isChange && value?.length !== 0 ? 'success' : ''
				} ${errors[name] ? 'error' : isChange ? '' : value?.length !== 0 ? 'fixed' : ''}`}
				onClick={() => setFocus(name)}
			>
				<div className="block_input">
					{mask ? <InputMask mask={mask} maskChar={''} {...commonProps} /> : <input {...commonProps} />}
					{name === 'password' || name === 'repeatPassword' ? (
						typeInput === 'password' ? (
							<IconEye color={'black'} onClick={handleViewPassword} size={'large'} />
						) : (
							<IconEyeClose color={'black'} onClick={handleViewPassword} size={'large'} />
						)
					) : (
						type === 'selectInput' && (
							<div
								className={`input__arrow ${isChange ? 'input__arrow_change' : ''} ${
									!errors[name] && isChange && value?.length !== 0 ? 'input__arrow_success' : ''
								} ${errors[name] ? 'input__arrow_error' : isChange ? '' : value.length !== 0 ? 'input__arrow_fixed' : ''}`}
							>
								<IconArrowDown size={'largeS'} color={'white'} className={!modalDropList ? '' : 'dropListArrowUp'} />
							</div>
						)
					)}
				</div>
			</div>
		</div>
	);
}

export default MainInput;
