import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconCall({ size, onClick, color, className }) {
	return (
		<div onClick={onClick} className={`${styles.icon} ${className}`}>
			<svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 25" fill="none">
				<path
					d="M22 17.2903V20.2903C22.0011 20.5688 21.9441 20.8445 21.8325 21.0997C21.7209 21.3549 21.5573 21.5839 21.3521 21.7722C21.1468 21.9605 20.9046 22.1038 20.6407 22.193C20.3769 22.2823 20.0974 22.3154 19.82 22.2903C16.7428 21.956 13.787 20.9045 11.19 19.2203C8.77382 17.685 6.72533 15.6365 5.18999 13.2203C3.49997 10.6115 2.44824 7.64133 2.11999 4.55034C2.095 4.2738 2.12787 3.9951 2.21649 3.73196C2.30512 3.46882 2.44756 3.22703 2.63476 3.02196C2.82196 2.81689 3.0498 2.65305 3.30379 2.54086C3.55777 2.42867 3.83233 2.3706 4.10999 2.37034H7.10999C7.5953 2.36556 8.06579 2.53741 8.43376 2.85387C8.80173 3.17032 9.04207 3.60978 9.10999 4.09034C9.23662 5.0504 9.47144 5.99306 9.80999 6.90034C9.94454 7.25826 9.97366 7.64725 9.8939 8.02122C9.81415 8.39518 9.62886 8.73845 9.35999 9.01034L8.08999 10.2803C9.51355 12.7839 11.5864 14.8568 14.09 16.2803L15.36 15.0103C15.6319 14.7415 15.9751 14.5562 16.3491 14.4764C16.7231 14.3967 17.1121 14.4258 17.47 14.5603C18.3773 14.8989 19.3199 15.1337 20.28 15.2603C20.7658 15.3289 21.2094 15.5735 21.5265 15.9478C21.8437 16.3221 22.0122 16.7999 22 17.2903Z"
					stroke={IconColor(color)}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.05 2.37036C16.0882 2.58513 17.9922 3.48916 19.4469 4.93294C20.9015 6.37672 21.8199 8.27377 22.05 10.3104"
					stroke={IconColor(color)}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.05 6.37036C15.0335 6.5643 15.936 7.04939 16.6404 7.76267C17.3447 8.47595 17.8184 9.38449 18 10.3704"
					stroke={IconColor(color)}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}

export default IconCall;
