import React from "react";
import styles from "../AccountEmpl.module.scss";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import AddCarsList from "../../../../components/Order/AddCars/AddCarsList";
import AddCarsForm from "../../../../components/Order/AddCars/AddCarsForm";
import { useDispatch, useSelector } from "react-redux";
import { getClientVehiclesApi } from "../../../../store/slices/vehicleSlice";
import { Modal } from "../../../../components";
import { setActiveForm } from "../../../../store/slices/orderSlice";
import Header from "../components/Header/Header";

function AccountEmplAddCar({ userPermissions }) {
  // отслеживание ширины окна
  const isDesktop = useMediaQuery({ minWidth: 1279.9 });

  // Активная форма
  const { activeForm } = useSelector((state) => state.order);

  const dispatch = useDispatch();
  //TODO: пока что для просмотра страницы со списком автомобилей используется id выбранного клиента из заявки
  const clientId = localStorage.getItem("chosenClientId");

  // Запрос на получение списка транспорта у клиента
  useEffect(() => {
    try {
      dispatch(getClientVehiclesApi(clientId));
    } catch (error) {
      console.error(error);
    }
  }, [clientId, dispatch]);

  return (
    <div className={styles.account__wrap}>
      <Header text={"Автомобили"} />
      <div className={styles.account__container}>

        {isDesktop ? (
          <>
            <div className={styles.account__stackCars}>
              <AddCarsList title={"Автомобили"} filterToggle={false} />
              {activeForm !== false ? (
                <AddCarsForm
                  title={"Данные автомобиля"}
                  description={false}
                  submitBtnText={"Сохранить"}
                  submitBtnAction={"save"}
                // activeForm={activeForm}
                />
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>
            <AddCarsList title={"Автомобили"} filterToggle={false} />
            {activeForm !== false ? (
              <Modal
                children={
                  <AddCarsForm
                    title={"Данные автомобиля"}
                    description={false}
                    submitBtnText={"Сохранить"}
                    submitBtnAction={"save"}
                  />
                }
                onClose={() => dispatch(setActiveForm(false))}
              />
            ) : (
              <></>
            )}
          </>
        )}

      </div>
    </div>
  );
}

export default AccountEmplAddCar;
