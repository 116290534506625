import React, { useEffect, useState } from "react";
import { BtnText } from "../../Buttons";
import styles from "./AddCars.module.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  postNewVehicleAction,
  putVehicleAction,
  setDriverFullName,
  setRegistrationNumber,
  setTrailerNumber,
  setVehiclePostSuccess,
  setVehiclePutSuccess,
} from "../../../store/slices/vehicleSlice";
import { setActiveForm } from "../../../store/slices/orderSlice";
import MainInput from "../../Inputs/MainInput/MainInput";
import UniversalButton from "../../Buttons/UniversalButton/UniversalButton";
import { IconCross, IconPlus } from "../../Icons";

// ? submitBtnAction:
// ? "save" - сохранить изменения
// ? "assign_containers" - назначить контейнеры
// ? Для отправки новой формы - передается пропс activeForm: "new"

export default function AddCarsForm({
  title,
  description,
  setCurrentStep,
  btnBack,
  submitBtnText,
  submitBtnAction,
  createOrder,
  setContainers,
  selectedCars
}) {

  //State для input
  const [fieldAutoValue, setFieldAutoValue] = useState("");
  const [fieldTrailerValue, setFieldTrailerValue] = useState("");
  const [fieldDriverValue, setFieldDriverValue] = useState("");



  // Redux
  const dispatch = useDispatch();
  const {
    postNewVehicleSuccess,
    putVehicleSuccess,
    vehicleId,
    registrationNumber,
    trailerNumber,
    driverFullName,
  } = useSelector((state) => state.vehicle);
  const { activeForm } = useSelector((state) => state.order);

  const { vehiclesList, vehiclesListInOrder } = useSelector((state) => state.vehicle);

  //TODO: пока что для просмотра страницы со списком автомобилей используется id выбранного клиента из заявки
  const clientId = localStorage.getItem("chosenClientId");

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    reset,
    formState: { errors },
  } = useForm();

  //Функции для Inputs
  const handleFieldAutoChange = (e) => {
    setFieldAutoValue(e.target.value);
  };
  const handleFieldTrailerChange = (e) => {
    setFieldTrailerValue(e.target.value);
  };
  const handleFieldDriverChange = (e) => {
    setFieldDriverValue(e.target.value);
  };

  //Функции для шагов в заявке
  const handleNextStep = async (data) => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = async (data) => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const onSubmit = (data) => {
    //Отправка POST запроса на добавление нового автомобиля
    if (activeForm === "new" && submitBtnAction === "save") {
      try {
        const formData = {
          id: clientId,
          formData: {
            registration_number: fieldAutoValue,
            trailer_number: fieldTrailerValue,
            driver: fieldDriverValue,
          },
        };
        dispatch(postNewVehicleAction(formData));
        // ! Сброс формы происходит в useEffect
      } catch (error) {
        throw error;
      }
    }
    //Отправка PUT запроса на изменение автомобиля
    else if (submitBtnAction === "save" || submitBtnAction === 'assign_containers') {
      try {
        dispatch(setRegistrationNumber(fieldAutoValue));
        dispatch(setTrailerNumber(fieldTrailerValue));
        dispatch(setDriverFullName(fieldDriverValue));
        const formData = {
          clientId: clientId,
          vehicleId: Number(vehicleId),
          formData: {
            id: vehicleId,
            registration_number: fieldAutoValue,
            trailer_number: fieldTrailerValue,
            driver: fieldDriverValue,
          },
        };

        dispatch(putVehicleAction(formData));
        // ! Сброс формы происходит в useEffect
      } catch (error) {
        throw error;
      }
    }
    // Назначить контейнеры + возможность изменения номера трейлера и водителя (нет dispatch на изменение номера авто)
    else if (submitBtnAction === "assign_containers") {
      try {
        dispatch(setTrailerNumber(fieldTrailerValue));
        dispatch(setDriverFullName(fieldDriverValue));
        const formData = {
          clientId: clientId,
          vehicleId: Number(vehicleId),
          formData: {
            id: vehicleId,
            registration_number: fieldAutoValue,
            trailer_number: fieldTrailerValue,
            driver: fieldDriverValue,
          },
        };
        dispatch(putVehicleAction(formData));
        // ! Сброс формы происходит в useEffect
        setContainers(true);
        handleNextStep(data);
      } catch (error) {
        throw error;
      }
    }
  };

  // Обновление полей формы
  useEffect(() => {
    setFieldAutoValue(registrationNumber);
    setFieldTrailerValue(trailerNumber);
    setFieldDriverValue(driverFullName); 
  }, [registrationNumber, trailerNumber, driverFullName]);

  // Сброс формы в случае успеха и возобновление состояния success
  useEffect(() => {
    if (putVehicleSuccess || postNewVehicleSuccess) {
      reset();
      dispatch(setActiveForm(false));
      dispatch(setVehiclePutSuccess(false));
      dispatch(setVehiclePostSuccess(false));
    }
  }, [postNewVehicleSuccess, putVehicleSuccess, dispatch, reset]);

  return (
    <div
      className={createOrder ? styles.carsForm_block : styles.noCarsForm_block}
    >
      <div className={styles.carsForm_layout}>
        <div className={styles.carsForm__header}>
          <div className={styles.carsForm__header_top}>
            <p className={styles.carsForm__header_text}>{title}</p>
            <UniversalButton
              type={'with-border'}
              color={'error'}
              size={"small"}
              icon={<IconCross color={'core-state-error'} size={'medium'} />}
              onClick={() => dispatch(setActiveForm(false))}
            />
          </div>

          {description ? (
            <div className={styles.carsForm__header_bottom}>
              <span>{description}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.carsForm__formBlock}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.carsForm__formBlock_top}>
              <MainInput
                title={'Введите номер автомобиля'}
                errorTitle={'Некорректный формат номера'}
                name="autoNum"
                register={register}
                handleFieldChange={handleFieldAutoChange}
                setValue={setValue}
                setFocus={setFocus}
                errors={errors}
                value={fieldAutoValue ? fieldAutoValue : ""}
                rules={{
                  required: fieldAutoValue ? null : "Обязательное поле",
                  minLength: {
                    value: 5,
                    message: "Минимум 5 символов",
                  },
                  pattern: {
                    value: /^[A-ZА-Я]\s?\d{3}\s?[A-ZА-Я]{2}$/,
                    message: "Пример: О048РР",
                  }
                }}
                placeholder={'Введите номер автомобиля'}
              />
              <MainInput
                title={'Введите номер прицепа'}
                // errorTitle={'Некорректный формат номера'}
                name="trailerNum"
                register={register}
                handleFieldChange={handleFieldTrailerChange}
                setValue={setValue}
                setFocus={setFocus}
                errors={errors}
                value={fieldTrailerValue ? fieldTrailerValue : ""}
                rules={{
                  // required: "Обязательное поле",
                  minLength: {
                    value: 5,
                    message: "Минимум 5 символов",
                  },
                  pattern: {
                    // value: /^[A-ZА-Я]\s?\d{3}\s?[A-ZА-Я]{2}$/,
                    message: "Пример: АА 0728",
                  }
                }}
                placeholder={'Введите номер прицепа'}
              />
              <MainInput
                title={'Введите ФИО водителя'}
                // errorTitle={'Некорректный формат данных'}
                name="nameDriver"
                register={register}
                handleFieldChange={handleFieldDriverChange}
                setValue={setValue}
                setFocus={setFocus}
                errors={errors}
                value={fieldDriverValue ? fieldDriverValue : ''}
                rules={{
                  // required: "Обязательное поле",
                  minLength: {
                    value: 5,
                    message: "Минимум 5 символов",
                  },
                  pattern: {
                    // value: /^[A-ZА-Я]\s?\d{3}\s?[A-ZА-Я]{2}$/,
                    message: "Пример: Константинов И.Ф",
                  }
                }}
                placeholder={'Введите ФИО водителя'}
              />
            </div>
            <div className={styles.carsForm__formBlock_bottom}>
              <UniversalButton
                typeButton={'submit'}
                label={submitBtnText}
                color={'succesful'}
                size={"medium"}
                icon={<IconPlus color={'white'} size={'largeS'} />}
              />
            </div>
          </form>
        </div>
      </div>
      {createOrder ? (
        <div className={styles.dataClient__footer}>
          <BtnText
            text={"Назад"}
            bg={"grey"}
            type={"button"}
            font={"fs-18"}
            size={"p-1724"}
            onClick={handlePrevStep}
          />
          <BtnText
            text={"Продолжить"}
            bg={"orange"}
            type={"button"}
            font={"fs-18"}
            size={"p-1724"}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}