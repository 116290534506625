import { useDispatch, useSelector } from 'react-redux';
import { IconAlertCircle, IconArrowDown } from '../../../../../../../components/Icons';
import styles from './MainApplication.module.scss';
import { setPage } from '../../../../../../../store/slices/containerAcceptanceSlice';

export function ClientApplication() {
	const { entryOneReport } = useSelector((state) => state.containerAccept);
	const { client } = entryOneReport;
	return (
		<div className={styles.appContainer}>
			<div className={styles.leftInfo}>
				<p className={styles.title}>{`Клиент: ${client?.organization_name}`}</p>
			</div>
		</div>
	);
}

export function PhotoFixApplication() {
	const dispatch = useDispatch();

	const handleClickOnDameges = () => {
		dispatch(setPage(10));
	};
	return (
		<div className={styles.appContainer} onClick={handleClickOnDameges}>
			<div className={styles.info__block}>
				<div className={styles.info__title}>
					<p className={styles.title}>Фотофиксация</p>
					<div className={`${styles.info__status} ${styles.warning}`}>
						<IconAlertCircle color={'core-tertiary-70'} />
					</div>
				</div>
				<div className={styles.info__arrow}>
					<IconArrowDown size={'large'} color={'core-dark-60'} />
				</div>
			</div>
		</div>
	);
}

export function DamagesApplication() {
	const dispatch = useDispatch();
	const { entryOneReport } = useSelector((state) => state.containerAccept);
	const { damages } = entryOneReport;

	const handleClickOnDameges = () => {
		dispatch(setPage(9));
	};
	return (
		<div className={styles.appContainer} onClick={handleClickOnDameges}>
			<div className={styles.info__block}>
				<div className={styles.info__title}>
					<p className={styles.title}>Повреждения</p>
					{damages && damages.length ? (
						<div className={`${styles.info__status} ${styles.damagesLength}`}>{damages?.length}</div>
					) : (
						<div className={`${styles.info__status} ${styles.successful}`}>не зафиксированы</div>
					)}
				</div>
				<div className={styles.info__arrow}>
					<IconArrowDown size={'large'} color={'core-dark-60'} />
				</div>
			</div>
		</div>
	);
}
