import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconBlocks({ size, onClick, color, className }) {
	return (
		<div onClick={onClick} className={`${styles.icon} ${className}`}>
			<svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 30 31" fill="none">
				<path
					d="M25.0001 3.8241H18.7501C18.0597 3.8241 17.5001 4.38374 17.5001 5.0741V11.3241C17.5001 12.0145 18.0597 12.5741 18.7501 12.5741H25.0001C25.6904 12.5741 26.2501 12.0145 26.2501 11.3241V5.0741C26.2501 4.38374 25.6904 3.8241 25.0001 3.8241Z"
					stroke={IconColor(color)}
					strokeWidth="2.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.5001 26.3241V10.0741C12.5001 9.74258 12.3684 9.42463 12.134 9.19021C11.8996 8.95579 11.5816 8.8241 11.2501 8.8241H5.00012C4.6686 8.8241 4.35066 8.95579 4.11624 9.19021C3.88182 9.42463 3.75012 9.74258 3.75012 10.0741V25.0741C3.75012 25.4056 3.88182 25.7236 4.11624 25.958C4.35066 26.1924 4.6686 26.3241 5.00012 26.3241H20.0001C20.3316 26.3241 20.6496 26.1924 20.884 25.958C21.1184 25.7236 21.2501 25.4056 21.2501 25.0741V18.8241C21.2501 18.4926 21.1184 18.1746 20.884 17.9402C20.6496 17.7058 20.3316 17.5741 20.0001 17.5741H3.75012"
					stroke={IconColor(color)}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}

export default IconBlocks;
