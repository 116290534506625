import { Form, Header, Notification, UniversalButton } from '../../../../components';
import styles from './AuthEmpl.module.scss';
import { Link } from 'react-router-dom';
import { IconArrowLeft } from '../../../../components/Icons';
// import { loginUser } from '../../../../store/actions/registarationUser';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postLoginUser } from '../../../../store/slices/loginSlice';
import MainInput from '../../../../components/Inputs/MainInput/MainInput';


function AuthEmpl() {
  const dispatch = useDispatch();
  const { loginUser_success, loginUser_error } = useSelector((state) => state.login)

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    let requestData;
    requestData = {
      username: data.login,
      password: data.password,
    };
    dispatch(postLoginUser({ requestData }));
  };

  const onClose = () => {
    setIsActive(false);
  };

  const [isActive, setIsActive] = useState(false);
  const [notText, setNotText] = useState('');
  const [notDescr, setNotDescr] = useState('');
  const [notStatus, setNotStatus] = useState('');
  const [password, setPassword] = useState('');

  const addNot = (text, descr, status) => {
    setNotText(text);
    setNotDescr(descr);
    setNotStatus(status);
    setIsActive(true);
  };

  const [userName, setUserName] = useState("")

  useEffect(() => {
    if (loginUser_success) {
      addNot('Успех', 'Вход выполнен успешно', 'success');
      setTimeout(() => {
        navigate('/accountEmpl/main');
      }, [1000]);
    }
    else if (loginUser_error) {
      addNot('Ошибка', 'Попробуйте снова', 'error');
    }
  }, [loginUser_success, loginUser_error])

  const handleClickBack = () => {
    navigate("/")
  };

  return (
    <div className="backImg">
      <Header />
      <div className="container-center">
        <Notification
          text={notText}
          descr={notDescr}
          onClose={onClose}
          isActive={isActive}
          isStatus={notStatus}
        />
        <div className={styles.navBar}>
          <UniversalButton icon={<IconArrowLeft size={"largeS"} color={"black"} />} onClick={handleClickBack} size={"large"} typeButton={"button"} />
        </div>
        <div className={styles.block}>
          <h5>Вход</h5>
          <Form onSubmit={onSubmit}>
            <MainInput
              title={'Логин'}
              errorTitle={'Некорректный формат логина'}
              value={userName}
              setValue={setUserName}
              name="login"
              rules={{
                required: "Обязательное поле",
                minLength: {
                  value: 2,
                  message: "Минимум 2 символа",
                },
              }}
              placeholder={'Введите логин'}
            />
            <MainInput
              title={'Пароль'}
              errorTitle={'Некорректный формат пароля'}
              name="password"
              value={password}
              setValue={setPassword}
              rules={{
                required: "Обязательное поле",
                minLength: {
                  value: 6,
                  message: "Минимум 6 символов",
                },
                pattern: {
                  value: /^[^\sа-яА-Я]{6,}$/,
                  message: "Латинские символы",
                },
              }}
              placeholder={'Введите пароль'}
            />
            <div className={styles.block__btns}>
              <UniversalButton label={"Войти"} color={"primary"} size={"large"} typeButton={"submit"} />
              <Link to={'/recoveryEmpl'}>
                <UniversalButton label={"Восстановить пароль"} color={"primary"} size={"large"} typeButton={"button"} type={"with-border"} />
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AuthEmpl;
