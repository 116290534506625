import { NavLink } from 'react-router-dom';
import styles from './MenuDesktop.module.scss';
import { useState } from 'react';
import { IconArrowDown } from '../../../../../components/Icons';

// подпункты пункта меню
function SubMenu({ item, open }) {
	// url страницы
	const currentPath = window.location.pathname;
	// если подменю открыто, после перезагрузки остается открытым
	const [isShow, setShow] = useState(false);

	const handleSubMenu = () => {
		setShow(!isShow);
	};
	return (
		<>
			<div className={styles.subMenu__title} onClick={handleSubMenu}>
				<div>
					{item?.icon}
					<span> {open ? item?.label : ''}</span>
				</div>
				<IconArrowDown color={'white'} size={'medium'} className={!isShow ? '' : `${styles.arrowUp}`} />
			</div>
			{isShow ? (
				<ul className={styles.subMenu__list}>
					{item.subMenu.map((item, index) => (
						<li
							key={index}
							className={`${item ? styles.menuDesktop__item : styles.menuDesktop__item_dnone}`}
							style={open ? null : { display: 'flex', justifyContent: 'flex-start' }}
						>
							<NavLink to={`${item?.path}`} className={({ isActive }) => (isActive ? styles.activeLink : '')}>
								{item?.icon}
								{open ? item?.label : ''}
							</NavLink>
						</li>
					))}
				</ul>
			) : null}
		</>
	);
}

export default SubMenu;
