import React, { useState, useEffect } from 'react';
import styles from './ChoiceDamageSide.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { UniversalButton } from '../../../../../../../components';
import { IconArrowDown } from '../../../../../../../components/Icons';
import {
	setSelectedSide,
	setPage,
	setDamagesList,
	clearCurrentSideDamage,
} from '../../../../../../../store/slices/containerAcceptanceSlice';
import { sideNameToType } from '../ChoiceDamageArea/components/ContainerMap/Sides/helpers/sideNameToType';

function ChoiceDamageSide() {
	const dispatch = useDispatch();
	const [moreBtns, setMoreBtns] = useState(false);
	const { containerSides, damagesList } = useSelector((state) => state.containerAccept);

	//!Очистка текущих повреждений после перехода на карту для правильного отображения логики отключения кнопок навигации по шагам
	useEffect(() => {
		dispatch(clearCurrentSideDamage());
	}, []);

	const handleChoiceeSide = (item) => {
		dispatch(setSelectedSide(item));
		dispatch(setPage(4));
	};
	const countDamagePerSide = (sideType) => {
		return damagesList?.filter((damage) => sideNameToType(damage?.side) === sideType).length;
	};

	return (
		<div className={`${styles.choiceDamage_main_block} ${moreBtns ? styles.moreBtns : ''}`}>
			{
				<div className={styles.container_sides}>
					{Object.keys(containerSides)
						.filter((side) => ['R', 'L', 'F', 'D', 'T', 'U', 'B', 'X', 'E'].includes(sideNameToType(side)))
						.map((key, index) => {
							const side = containerSides[key];
							const isDisabled = ['X', 'E'].includes(side.code);
							return (
								<div
									key={index}
									onClick={!isDisabled ? () => handleChoiceeSide(side) : null}
									className={isDisabled ? styles.containerSide_button_disabled : styles.containerSide_button}
								>
									<div>{side.code}</div>
									<div>{key}</div>

									{countDamagePerSide(side.code) ? (
										<div className={styles.containerSide_button_count}>{countDamagePerSide(side.code)}</div>
									) : (
										<div className={styles.containerSide_button_emptyCount}></div>
									)}
								</div>
							);
						})}
				</div>
			}
			<UniversalButton
				onClick={() => {
					setMoreBtns(!moreBtns);
				}}
				key={'more'}
				label={'Внутреннее оборудование'}
				size={'large'}
				type={'Внутреннее оборудование'}
				color={'primary'}
				icon={<IconArrowDown size={'medium'} color={'white'} />}
			/>
			{moreBtns && (
				<div className={styles.container_sides}>
					{Object.keys(containerSides)
						.filter((side) => ['I', 'M', 'G'].includes(sideNameToType(side)))
						.map((key, index) => {
							const side = containerSides[key];
							const isDisabled = ['I', 'M', 'G'].includes(side.code);
							const damageCount = countDamagePerSide(side.code);
							return (
								<div
									key={index}
									onClick={!isDisabled ? () => handleChoiceeSide(side) : null}
									className={isDisabled ? styles.containerSide_button_disabled : styles.containerSide_button}
								>
									<div>{side.code}</div>
									<div>{key}</div>
									{damageCount ? (
										<div className={styles.containerSide_button_count}>{damageCount}</div>
									) : (
										<div className={styles.containerSide_button_emptyCount}></div>
									)}
								</div>
							);
						})}
				</div>
			)}
		</div>
	);
}

export default ChoiceDamageSide;
