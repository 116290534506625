import React from 'react';
import { BtnFont, BtnBg, BtnSize, BtnFlex } from './styleBtnText.js';
import './styleBtnText.scss';

function BtnText({ disabled, text, onClick, font, bg, size, type, icon, iconFirst, className }) {
  return (
    <button type={type} disabled={disabled} className={`${'btnText'} ${BtnFont(font)} ${BtnBg(bg)} ${BtnSize(size)} ${className}`} onClick={onClick}>
      {
        iconFirst ? (
          <>
            {text}{icon}
          </>
        ) : (
          <>
            {icon}{text}
          </>
        )
      }
    </button>
  );
}

export default BtnText;
