import styles from './ContainerForm.module.scss';
import MainInput from '../../../../../../../components/Inputs/MainInput/MainInput';
import { useDispatch, useSelector } from 'react-redux';
import {
	getVehiclesForContainerAcceptance,
	postVehicle,
	setChangeVehicleNumberContainerAcceptance,
	setChangeDriverContainerAcceptance,
	putEntriesList,
} from '../../../../../../../store/slices/containerAcceptanceSlice';
import { useForm } from 'react-hook-form';
import { useDebounce } from '../../../../../../../hooks/useDebounce';
import AutoCompleteSearch from '../../../../../../../components/Order/AutoCompleteSearch/AutoCompleteSearch';
import { UniversalButton } from '../../../../../../../components';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../../../../store/slices/sideBarSlice';

function ContainerForm() {
	const {
		entryOneReport,
		vehicleListForContainerAcceptance,
		vehicleNumberContainerAcceptance,
		driverContainerAcceptance,
		getVehiclesForContainerAcceptance_success,
		getVehiclesForContainerAcceptance_pending,
		changeVehicleNumberContainerAcceptance,
		changeDriverContainerAcceptance,
	} = useSelector((state) => state.containerAccept);

	const { register, setFocus, formState, setValue } = useForm({});
	const { errors } = formState;
	const dispatch = useDispatch();
	const { container } = entryOneReport; //инфо о контейнере
	const entrypass = container?.entrypass; //инфо энтрипасс

	const debouncedVehicleSearch = useDebounce((e, newValue) => {
		dispatch(setChangeVehicleNumberContainerAcceptance(e.target.value));
		dispatch(getVehiclesForContainerAcceptance({ number: e.target.value || newValue }));
	}, 250);

	const handleChangeVehicleInput = (e, newValue) => {
		if (e) {
			debouncedVehicleSearch(e, newValue);
		}
	};
	const handleChangeVehicleOption = (e, newValue) => {
		dispatch(setChangeVehicleNumberContainerAcceptance(newValue));
	};
	const handleChangeDriver = (e) => {
		dispatch(setChangeDriverContainerAcceptance(e.target.value));
	};

	// Вспомогательная функция по поиску по типам заявок
	function findOrder(array, key, value) {
		const foundObject = array.find((obj) => obj[key] === value);
		return foundObject ? foundObject : '';
	}

	// Запрос на редактирование пропуска
	const handlePutReport = () => {
		let entry = findOrder(entrypass, 'order_type_display', 'Заявка на ввоз');
		if (entry) {
			const requestData = [{ pass_pk: entry.id }];
			if (driverContainerAcceptance !== changeDriverContainerAcceptance) {
				requestData[0]['vehicle_driver'] = changeDriverContainerAcceptance;
			}
			if (vehicleNumberContainerAcceptance.registration_number !== changeVehicleNumberContainerAcceptance.registration_number) {
				requestData[0]['vehicle'] = changeVehicleNumberContainerAcceptance.id;
			}
			if (requestData.length > 0) {
				dispatch(putEntriesList({ terminal_id: entry.terminal, order_id: entry.order, requestData: requestData }));
			}
		}
		dispatch(setSideBarActive(false));
		dispatch(setCurrentElementForSideBar(null));
	};

	return (
		<div className={styles.modalChange}>
			<div>
				<span className={styles.modalChange__title}>Внести изменения</span>
				<div className={styles.item_wrapper}>
					<AutoCompleteSearch
						multiple={false}
						title={'Номер А/М'}
						data={vehicleListForContainerAcceptance}
						objectKey={'registration_number'}
						handleInputChange={handleChangeVehicleInput}
						handleOptionChange={handleChangeVehicleOption}
						handleIfNoOptions={() => dispatch(postVehicle())}
						noOptionsTitle={'Добавить автомобиль'}
						pending_state={getVehiclesForContainerAcceptance_pending}
						success_state={getVehiclesForContainerAcceptance_success}
						choosenOption={
							changeVehicleNumberContainerAcceptance?.id && changeVehicleNumberContainerAcceptance?.registration_number
								? {
										id: changeVehicleNumberContainerAcceptance?.id,
										registration_number: changeVehicleNumberContainerAcceptance?.registration_number,
									}
								: null
						}
						placeholder={'Выберите автомобиль'}
						freeSolo={false}
					/>
				</div>
				<div className={styles.item_wrapper}>
					<MainInput
						title={'Водитель'}
						errorTitle={'Некорректный водитель'}
						name="changeDriver"
						register={register}
						value={changeDriverContainerAcceptance ? changeDriverContainerAcceptance : ''}
						handleFieldChange={handleChangeDriver}
						setValue={setValue}
						setFocus={setFocus}
						errors={errors}
						rules={{}}
						placeholder={'Данные водителя'}
					/>
				</div>
			</div>
			<div className={styles.modalChange__btn}>
				<UniversalButton onClick={handlePutReport} label={'Сохранить'} size={'medium'} color={'succesful'} />
			</div>
		</div>
	);
}

export default ContainerForm;
