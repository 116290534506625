import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ChoiceDamageArea.module.scss';
import ContainerMap from './components/ContainerMap/ContainerMap';

import { CheckBox } from '../../../../../../../components';

import {
	clearCurrentSideDamage,
	setDamageNameMaterial,
	setDamagedDetails,
} from '../../../../../../../store/slices/containerAcceptanceSlice';
import { isDetailDamaged } from './components/ContainerMap/Sides/helpers/isDetailDamaged';
import { isDetailCheckBoxDisabled } from './components/ContainerMap/Sides/helpers/isDetailCheckBoxDisabled';
import { isMaterialOrDamageChosen } from './components/ContainerMap/Sides/helpers/isMaterialOrDamageChosen';

function ChoiceDamageArea({}) {
	const { selectedSide, currentSideDamage, choosenMaterial, damagedDetails, damagesList } = useSelector((state) => state.containerAccept);
	const dispatch = useDispatch();

	//! Для выбора детали (левые/правые стороны, перед, торец с дверью)
	const handleCheckBoxOptionClick = (component, option) => {
		dispatch(setDamagedDetails(option.code));
	};
	//! Для дополнительного выбора дополнительных данных (крыша/пол 'T', 'B')
	const handleCheckBoxAdditionalClickRequired = (component, option) => {
		dispatch(setDamageNameMaterial(option.name));
	};
	//! Для дополнительного выбора дополнительных данных (Низ 'U')
	const handleCheckBoxAdditionalClickNoRequired = (component, option) => {
		dispatch(setDamageNameMaterial(option.name));
	};

	//!Очистка текущих повреждений после перехода на карту для правильного отображения логики отключения кнопок навигации по шагам
	useEffect(() => {
		dispatch(clearCurrentSideDamage());
	}, []);

	return (
		<div className={styles.damage_area_main_block}>
			<ContainerMap side={selectedSide} />
			<div className={styles.checkboxMain}>
				{selectedSide.details.length > 0 ? (
					<>
						<h3>
							{['T', 'B'].includes(selectedSide.code)
								? 'Покрытие'
								: ['U'].includes(selectedSide.code)
									? 'Элементы выбранной зоны'
									: 'Детали'}
						</h3>
						{selectedSide.details.map((component, index) => (
							<div key={index} className={styles.checkBox_block}>
								<h5>{component.name}</h5>
								<div className={styles.checkBox_items}>
									{component.options.map((option, index) => (
										<div className={styles.checkBox_item} key={index}>
											<CheckBox
												type={2}
												disabled={
													['U'].includes(selectedSide.code)
														? false
														: ['T', 'B'].includes(selectedSide.code)
															? currentSideDamage.damages.length === 0
															: isDetailCheckBoxDisabled(damagesList, damagedDetails, option.code)
												}
												typeCheck={
													['T', 'B'].includes(selectedSide.code)
														? isMaterialOrDamageChosen(currentSideDamage, choosenMaterial, option)
														: ['U'].includes(selectedSide.code)
															? false
															: isDetailDamaged(damagesList, damagedDetails, option.code)
												}
												onClick={
													['T', 'B'].includes(selectedSide.code)
														? () => handleCheckBoxAdditionalClickRequired(component, option)
														: ['U'].includes(selectedSide.code)
															? () => handleCheckBoxAdditionalClickNoRequired(component, option)
															: () => handleCheckBoxOptionClick(component, option)
												}
											/>
											<p>{option.name}</p>
										</div>
									))}
								</div>
							</div>
						))}
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
}

export default ChoiceDamageArea;
