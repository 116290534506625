export const sideTypeToName = (sideType) => {
	switch (sideType) {
		case 'F':
			return 'Передний торец';
		case 'L':
			return 'Левая сторона';
		case 'R':
			return 'Правая сторона';
		case 'D':
			return 'Торец с дверью';
		case 'T':
			return 'Крыша';
		case 'B':
			return 'Пол';
		case 'U':
			return 'Нижняя часть';
		case 'X':
			return 'Контейнер полностью';
		case 'E':
			return 'Внешняя поверхность контейнера';
		case 'I':
			return 'Внутренняя часть контейнера';
		case 'M':
			return 'Холодильная установка';
		case 'G':
			return 'Дизель-генераторная установка';
		default:
			break;
	}
};
