import { UniversalButton } from "../../../../../../components";
import { IconArrowLeft } from "../../../../../../components/Icons";
import styles from "./NonActive.module.scss";

function NonActive({ setPage }) {

  const handleRepeatAuth = () => {
    setPage(0);
  };

  const handleContactManager = () => {
    setPage(3);
  };

  return (
    <>
      <div className={styles.navBar}>
        <UniversalButton icon={<IconArrowLeft size={"largeS"} color={"black"} />} size={"large"} typeButton={"button"} />
      </div>
      <div className={styles.block}>
        <div className={styles.block__descr}>
          <span>Аккаунт ещё не активирован</span>
          <p>
            Вы успешно прошли регистрацию. Осталось подождать, пока менеджер
            активирует ваш аккаунт
          </p>
        </div>
        <div className={styles.block__btns}>
          <UniversalButton label={"Попробовать еще раз"} onClick={handleRepeatAuth} color={"primary"} size={"large"} typeButton={"button"} />
          <UniversalButton label={"Связаться с менеджером"} onClick={handleContactManager} color={"primary"} size={"large"} typeButton={"button"} type={"with-border"} />
        </div>
      </div>
    </>
  );
}

export default NonActive;
