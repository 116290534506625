import React, { useEffect, useRef, useState } from 'react';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker';
import style from './datePicker.module.scss';
import { IconCalendar, IconCross } from '../../../Icons';
import UniversalButton from '../../../Buttons/UniversalButton/UniversalButton';
import 'react-datepicker/dist/react-datepicker.css';
import MainInput from '../../../Inputs/MainInput/MainInput';
import { useForm } from 'react-hook-form';

export const DatePickerRange = ({ startDate, setStartDate, endDate, setEndDate }) => {
	const datePickerRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');

	const {
		register,
		handleSubmit,
		setValue,
		setFocus,
		formState: { errors },
	} = useForm();

	const options = [
		{ id: 'last-week', label: 'Последняя неделя' },
		{ id: 'current-month', label: 'Текущий месяц' },
		{ id: 'last-month', label: 'Предыдущий месяц' },
		{ id: 'quarter', label: 'Квартал' },
		{ id: 'year', label: 'Год' },
	];

	const onChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		// setEndDate(end);
		if (end) {
			setEndDate(end);
			setOpen(false); // Закрываем календарь только при выборе обеих дат
		}
	};

	const handleOpenDatePicker = (e) => {
		e.preventDefault();
		setOpen(!open);
	};

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};

	const handleOptionClick = (optionId) => {
		switch (optionId) {
			case 'last-week':
				const lastWeekStartDate = new Date();
				lastWeekStartDate.setDate(lastWeekStartDate.getDate() - 7);
				setStartDate(lastWeekStartDate);
				setEndDate(new Date());
				break;
			case 'current-month':
				const currentMonthStartDate = new Date();
				currentMonthStartDate.setDate(1);
				setStartDate(currentMonthStartDate);
				setEndDate(new Date());
				break;
			case 'last-month':
				const lastMonthStartDate = new Date();
				lastMonthStartDate.setMonth(lastMonthStartDate.getMonth() - 1);
				lastMonthStartDate.setDate(1);
				setStartDate(lastMonthStartDate);
				const lastMonthEndDate = new Date();
				lastMonthEndDate.setDate(0);
				setEndDate(lastMonthEndDate);
				break;
			case 'quarter':
				const quarterEndDate = new Date();
				const quarterStartMonth = Math.floor(quarterEndDate.getMonth() / 3) * 3; // Находим первый месяц квартала
				quarterEndDate.setMonth(quarterStartMonth); // Устанавливаем начало квартала
				quarterEndDate.setDate(1); // Устанавливаем день месяца на первое число
				setEndDate(quarterEndDate);

				const quarterStartDate = new Date(quarterEndDate); // Создаем новую дату, чтобы не изменять дату окончания квартала
				quarterStartDate.setMonth(quarterStartDate.getMonth() - 3); // Отнимаем 3 месяца, чтобы получить начало квартала
				setStartDate(quarterStartDate);
				break;
			case 'year':
				const yearStartDate = new Date(new Date().getFullYear(), 0, 1);
				setStartDate(yearStartDate);
				setEndDate(new Date());
				break;
			default:
				break;
		}
	};

	//сброс дат
	const handleResetData = () => {
		setStartDate(null);
		setEndDate(null);
		setSelectedOption('');
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
				setOpen(false);
			}
		};
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<div className={style.container} ref={datePickerRef}>
			<div className={style.inputContainer}>
				{options.map((option) => (
					<div key={option.id} className={style.radioItem}>
						<input
							type="radio"
							id={option.id}
							name="time-period"
							value={option.id}
							checked={selectedOption === option.id}
							onChange={handleOptionChange}
							className={style.radioInput}
							onClick={() => handleOptionClick(option.id)}
						/>
						<label htmlFor={option.id}>{option.label}</label>
					</div>
				))}
			</div>
			<div className={style.calendarItems}>
				<div
					className={style.startDate}
					onClick={() => {
						setOpen(true);
					}}
				>
					<MainInput
						name="start"
						value={startDate ? startDate.toLocaleDateString('ru-RU') : ''}
						register={register}
						setValue={setValue}
						setFocus={setFocus}
						errors={errors}
						rules={{}}
						placeholder={'с 01.01.2024'}
						disabled={true}
					/>
				</div>
				<div
					className={style.endDate}
					onClick={() => {
						setOpen(true);
					}}
				>
					<MainInput
						name="end"
						value={endDate ? endDate.toLocaleDateString('ru-RU') : ''}
						register={register}
						setValue={setValue}
						setFocus={setFocus}
						disabled={true}
						errors={errors}
						rules={{}}
						placeholder={'с 01.01.2024'}
						onClick={() => {
							setOpen(true);
						}}
					/>
				</div>
				{startDate && endDate ? (
					<UniversalButton
						color={'error'}
						type={'button'}
						size={'medium'}
						icon={<IconCross color={'white'} />}
						onClick={handleResetData}
					/>
				) : (
					<UniversalButton
						color={'primary'}
						type={'button'}
						size={'medium'}
						icon={<IconCalendar color={'white'} />}
						onClick={handleOpenDatePicker}
					/>
				)}
			</div>
			{open && (
				<div className={style.datePicker}>
					<DatePicker
						selected={startDate}
						onChange={onChange}
						startDate={startDate}
						endDate={endDate}
						selectsRange
						inline
						dateFormat="dd.MM.yy"
						maxDate={new Date()}
						locale={ru}
						monthsShown={2}
					/>
				</div>
			)}
		</div>
	);
};
