import styles from "./Step.module.scss";
import React, { useState, useEffect } from "react";
import { Form, Notification, UniversalButton } from "../../../../../components";
import { checkCode } from "../../../../../store/actions/registarationUser";
// import { sendCode } from "../../../../../store/actions/registarationUser";
import { useDispatch, useSelector } from "react-redux";
import { postCheckCode, postSendCode } from "../../../../../store/slices/registrationSlice";
import MainInput from "../../../../../components/Inputs/MainInput/MainInput";

function Step3({ setPage }) {
  const dispatch = useDispatch();
  const { checkCode_success, checkCode_error } = useSelector((state) => state.registration)

  const [isActive, setIsActive] = useState(false);
  const [notText, setNotText] = useState("");
  const [notDescr, setNotDescr] = useState("");
  const [notStatus, setNotStatus] = useState("");
  const [code, setCode] = useState('');
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [timer, setTimer] = useState(30);

  const addNot = (text, descr, status) => {
    setNotText(text);
    setNotDescr(descr);
    setNotStatus(status);
    setIsActive(true);
  };

  const onClose = () => {
    setIsActive(false);
  };

  const onSubmit = async (data) => {
    const requestData = {
      code: data.code,
    };
    await dispatch(postCheckCode({ requestData }));
  };

  const handleNewCode = async () => {
    try {
      let requestData = {};
      requestData = {
        email: localStorage.getItem("email"),
      };
      dispatch(postSendCode({ requestData }));
      setIsTimerRunning(true);
      setTimer(30);
    } catch (error) {
    }
  };

  const formatTime = (time) => {
    const seconds = time % 60;
    return `${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  useEffect(() => {
    if (checkCode_success) {
      setPage((currentPage) => currentPage + 1);
    }
    else if (checkCode_error) {
      addNot("Ошибка", "Неверный код", "error");
    }
  }, [checkCode_success, checkCode_error])

  useEffect(() => {
    let intervalId;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer === 0) {
      setIsTimerRunning(false);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning, timer]);

  return (
    <>
      <Notification
        text={notText}
        descr={notDescr}
        onClose={onClose}
        isActive={isActive}
        isStatus={notStatus}
      />
      <div className={styles.block}>
        <h5>Регистрация</h5>
        <Form onSubmit={onSubmit}>
          <MainInput
            title={'Код подтверждения'}
            errorTitle={'Некорректный код'}
            name="code"
            value={code}
            setValue={setCode}
            rules={{
              required: "Обязательное поле",
              pattern: {
                value: /^\d{4}$/,
                message: "Введите четырехзначное число",
              },
            }}
            placeholder={'1111'}
            maxLength={4}
          />
          <div className={styles.block__btns}>
            <UniversalButton label={"Продолжить"} color={"primary"} size={"large"} typeButton={"submit"} />
            <UniversalButton label={
              isTimerRunning
                ? `Новый код через: ${formatTime(timer)}`
                : "Новый код"
            } color={"primary"} size={"large"} typeButton={"button"} onClick={handleNewCode}
              disabled={isTimerRunning} />
          </div>
        </Form>
      </div>
    </>
  );
}

export default Step3;
