export function IconSize(size) {
	let result =
		size === 'extraSmall'
			? '10'
			: size === 'overSmall'
				? '12'
				: size === 'small'
					? '14'
					: size === 'medium'
						? '16'
						: size === 'mediumL'
							? '18'
							: size === 'largeS'
								? '20'
								: size === 'large'
									? '24'
									: size === 'largeM'
										? '28'
										: size === 'overLarge'
											? '32'
											: size === 'maxi'
												? '40'
												: '16';
	return result;
}

export function IconColor(color) {
	let result =
		color === 'core-state-success'
			? '#149527'
			: color === 'primary'
				? '#8F86DE'
				: color === 'primary-active'
					? '#5C53A7'
					: color === 'black'
						? '#1C1C1E'
						: color === 'primary_white'
							? '#FFFBFF'
							: color === 'icon-social'
								? '#2D2276'
								: color === 'primary-30'
									? '#443A8E'
									: color === 'icon-mobileMenu'
										? '#C7BFFF'
										: color === 'white'
											? '#FFFFFF'
											: color === 'core-state-error'
												? '#BA1A1A'
												: color === 'core-tertiary-70'
													? '#FF8E16'
													: color === 'core-dark-60'
														? '#8E8E93'
														: '#8F86DE';
	return result;
}
