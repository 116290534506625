import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconCopy({ size, onClick, color, className }) {
	return (
		<div onClick={onClick} className={`${styles.icon} ${className}`}>
			<svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 20 20" fill="none">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.66667 4.99996H3.33333C2.8731 4.99996 2.5 5.37306 2.5 5.83329V17.5C2.5 17.9602 2.8731 18.3333 3.33333 18.3333H12.5C12.9602 18.3333 13.3333 17.9602 13.3333 17.5V15H16.6667C17.1269 15 17.5 14.6269 17.5 14.1666V4.16663C17.5 2.78591 16.3807 1.66663 15 1.66663H7.5C7.03976 1.66663 6.66667 2.03972 6.66667 2.49996V4.99996ZM7.91667 4.99996H10.8333C12.214 4.99996 13.3333 6.11925 13.3333 7.49996V13.75H16.25V4.16663C16.25 3.47627 15.6904 2.91663 15 2.91663H7.91667V4.99996ZM3.75 17.0833V6.24996H10.8333C11.5237 6.24996 12.0833 6.8096 12.0833 7.49996V17.0833H3.75Z"
					fill={IconColor(color)}
				/>
			</svg>
		</div>
	);
}

export default IconCopy;
