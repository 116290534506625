import CardAuto from "../CardAuto/CardAuto";
import CardContainer from "../CardContainer/CardContainer";
import CardOrder from "../CardOrder/CardOrder";
import styles from "./DataClient.module.scss";
import DataClientItem from "./DataClientItem";

function DataClient({ client, handleNextStep }) {

    const data = [
        { id: "1", type: "DC", line: "MSC", number: "SCH40BU0001", status: "Вывезен", date: "07.12.2023", size: "20" },
        { id: "2", type: "DC", line: "MSC", number: "SCH40BU0001", status: "Принят", date: "09.12.2023", size: "20" },
        { id: "3", type: "DC", line: "MSC", number: "SCH40BU3231", status: "Ожидается", date: "07.12.2023", size: "20" },
        { id: "4", type: "DC", line: "MSC", number: "SCH40BU0301", status: "Вывезен", date: "12.11.2023", size: "20" },
    ]

    return (
        <div className={styles.dataClient}>
            <div className={styles.dataClient__container}>
                <div className={styles.dataClient__info}>
                    <h4 className={styles.dataClient__title}>Данные о клиенте</h4>
                    <ul className={styles.dataClient__info__list}>
                        <li>
                            <span>{client?.corporate_name}</span>
                        </li>
                        <li>
                            <span>Договор №{client?.contract_data?.contract_number} от {client?.contract_data?.date_created}</span>
                        </li>
                        <li>
                            <span>Адрес: {client?.corporate_address}</span>
                        </li>
                    </ul>
                </div>
                <div className={styles.dataClient__body}>
                    <DataClientItem data={data} title={"Контейнеры"} card={<CardContainer />} />
                    <DataClientItem data={data} title={"Автомобили"} card={<CardAuto />} />
                    <DataClientItem data={data} title={"Заявки"} card={<CardOrder />} />
                </div>
            </div>
        </div>
    );
}

export default DataClient;