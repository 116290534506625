import {
	IconSideBottom,
	IconSideDoor,
	IconSideEnd,
	IconSideFloor,
	IconSideInside,
	IconSideLeft,
	IconSideOutside,
	IconSideRight,
	IconSideUp,
} from '../../../../../../../Icons';

export const iconSideSwitcher = (side) => {
	switch (side) {
		case 'F':
			return <IconSideEnd size="medium" />;
		case 'D':
			return <IconSideDoor size="medium" />;
		case 'L':
			return <IconSideLeft size="medium" />;
		case 'R':
			return <IconSideRight size="medium" />;
		case 'T':
			return <IconSideUp size="medium" />;
		case 'B':
			return <IconSideFloor size="medium" />;
		case 'U':
			return <IconSideBottom size="medium" />;
		case 'E':
			return <IconSideOutside size="medium" />;
		case 'I':
			return <IconSideInside size="medium" />;
		case 'G':
			return <IconSideInside size="medium" />;

		default:
			return <IconSideOutside size="medium" />;
	}
};

// { id: 1, title: 'Передний торец F', type: 'F', position: 'out' },
// { id: 2, title: 'Торец с дверью D', type: 'D', position: 'out' },
// { id: 3, title: 'Левая сторона L', type: 'L', position: 'out' },
// { id: 4, title: 'Правая сторона R', type: 'R', position: 'out' },
// { id: 5, title: 'Крыша  T', type: 'T', position: 'out' },
// { id: 6, title: 'Пол B', type: 'B', position: 'out' },
// { id: 7, title: 'Низ U', type: 'U', position: 'out' },
// { id: 8, title: 'Контейнер снаружи E', type: 'E', position: 'out' },
// { id: 9, title: 'Контейнер внутри I', type: 'I', position: 'in' },
// { id: 10, title: 'Дизель-генераторная установка G', type: 'G', position: 'in' },

// export { default as IconSideBottom } from './IconSideBottom';
// export { default as IconSideDoor } from './IconSideDoor';
// export { default as IconSideFrontEndWithDoor } from './IconSideFrontEndWithDoor';
// export { default as IconSideDown } from './IconSideDown';
// export { default as IconSideEnd } from './IconSideEnd';
// export { default as IconSideFloor } from './IconSideFloor';
// export { default as IconSideInside } from './IconSideInside';
// export { default as IconSideLeft } from './IconSideLeft';
// export { default as IconSideOutside } from './IconSideOutside';
// export { default as IconSideRight } from './IconSideRight';
// export { default as IconSideUp } from './IconSideUp';
