import styles from './CreateOrderSteps.module.scss';
import Containers from '../Containers/Containers';
import { useEffect, useState } from 'react';
import ExportContainerList from '../ExportContainerList/ExportContainerList';
import OrderBlock from '../OrderBlock/OrderBlock';
import { useDispatch, useSelector } from 'react-redux';
import {
	addContainersInOrder,
	getOrgContainers,
	removeContainersFromOrder,
	setChoosenContainerForChangeCancelVariant,
} from '../../../store/slices/orderSlice';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../store/slices/sideBarSlice';
import ChangeContainerForm from '../ChangeContainerForm/ChangeContainerForm';

function CreateOrderExportStep3({ setPage, mob }) {
	const dispatch = useDispatch();
	const { organizationContainerList, newOrder_terminal_id, newOrder_organization_id, containersToAddInOrder } = useSelector(
		(state) => state.order,
	);
	// const [exportDataList, setExportDataList] = useState([]);

	//Для отчистки выбранных контейнеров
	// useEffect(() => {
	// 	setSelectedContainers([]);
	// }, []);

	// useEffect(() => {
	// 	setExportDataList(organizationContainerList);
	// }, [organizationContainerList]);

	//! Добавление существующего контейнера у клиента в заявку (клик по зеленой кнопке)
	const handleAddToOrder = (container_id) => {
		const container = organizationContainerList.find((el) => el.id == container_id);
		dispatch(addContainersInOrder({ type: 'export_before', data: container }));
	};

	//! Удаление контейнера из заявки  (клик по красной кнопке)
	const handleRemoveFromOrder = (container) => {
		dispatch(removeContainersFromOrder({ type: 'export_before', data: container }));
	};

	//!Получение контейнеров по терминалу определенной организации
	useEffect(() => {
		dispatch(getOrgContainers({ terminal_id: newOrder_terminal_id, org_id: newOrder_organization_id, page: 1 }));
	}, []);

	const handleChangeContainerInOrder = (e, container) => {
		console.log('EXPORT BEFORE ');
		dispatch(setSideBarActive(true));
		dispatch(setCurrentElementForSideBar(<ChangeContainerForm type={'export_before_order_containers'} />));
		dispatch(setChoosenContainerForChangeCancelVariant(container));
	};

	const handleChangeOrganizationContainer = (e, container) => {
		console.log('EXPORT CONTAINERLIST -> handleChangeOrganizationContainer CHANGE CONTAINER', container);
		dispatch(setSideBarActive(true));
		dispatch(setCurrentElementForSideBar(<ChangeContainerForm type={'export_before_organization_containers'} />));
		dispatch(setChoosenContainerForChangeCancelVariant(container));
	};
	return (
		<div className={`${styles.account__step} ${styles.export_step3}`}>
			<OrderBlock
				children={
					<ExportContainerList
						handleAddToOrder={handleAddToOrder}
						handleChangeContainer={handleChangeOrganizationContainer}
						// data1={selectedContainers}
						// setData1={setSelectedContainers}
						data={organizationContainerList}
						// setData={setExportDataList}
					/>
				}
				title="Контейнеры"
			/>
			<Containers
				// exportOrder
				type={'added'}
				// setData={setExportDataList}
				// setData1={setSelectedContainers}
				handleRemoveFromOrder={handleRemoveFromOrder}
				handleChangeContainer={handleChangeContainerInOrder}
				data={containersToAddInOrder}
				mob={mob}
				setPage={setPage}
			/>
		</div>
	);
}

export default CreateOrderExportStep3;
