// import React from "react";
import { IconSize, IconColor } from '../styleIcon.js';
import styles from '../styleIcon.module.scss';

function IconArrowRight({ size, color }) {
  return (
    <div className={styles.icon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={IconSize(size)}
        height={IconSize(size)}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M4.1665 9.49984C3.89036 9.49984 3.6665 9.72369 3.6665 9.99984C3.6665 10.276 3.89036 10.4998 4.1665 10.4998V9.49984ZM15.8332 9.99984L16.1867 10.3534C16.382 10.1581 16.382 9.84155 16.1867 9.64628L15.8332 9.99984ZM10.3534 3.81295C10.1581 3.61769 9.84155 3.61769 9.64628 3.81295C9.45102 4.00821 9.45102 4.3248 9.64628 4.52006L10.3534 3.81295ZM9.64628 15.4796C9.45102 15.6749 9.45102 15.9915 9.64628 16.1867C9.84155 16.382 10.1581 16.382 10.3534 16.1867L9.64628 15.4796ZM4.1665 10.4998H15.8332V9.49984H4.1665V10.4998ZM9.64628 4.52006L15.4796 10.3534L16.1867 9.64628L10.3534 3.81295L9.64628 4.52006ZM15.4796 9.64628L9.64628 15.4796L10.3534 16.1867L16.1867 10.3534L15.4796 9.64628Z"
          fill={IconColor(color)}
        />
      </svg>
    </div>
  );
}

export default IconArrowRight;
