import styles from "./Step.module.scss";
import { Form, UniversalButton } from "../../../../../components";
import { useState } from "react";
import MainInput from "../../../../../components/Inputs/MainInput/MainInput";

function Step2({ setPage }) {
  const onSubmit = (data) => {
    localStorage.setItem("FLName", data.FLName);
    setPage((currentPage) => currentPage + 1);
  };

  let typeUser = localStorage.getItem("typeUser");
  const [flName, setFlname] = useState(localStorage.getItem("FLName") || "");

  return (
    <div className={styles.block}>
      <h5>{typeUser === 'Новый клиент' ? typeUser : `Новый ${typeUser.toLowerCase()}`}</h5>
      <Form onSubmit={onSubmit}>
        <MainInput
          title={'Фамилия и имя'}
          errorTitle={'Введите фамилию и имя'}
          name="FLName"
          value={flName}
          setValue={setFlname}
          rules={{
            required: "Обязательное поле",
          }}
          placeholder={'Константинов Константин'}
        />

        <div className={styles.block__btns}>
          <UniversalButton label={"Продолжить"} color={"primary"} size={"large"} typeButton={"submit"} />
        </div>
      </Form>
    </div>
  );
}

export default Step2;