import styles from './Modals.module.scss';
import { useState, useEffect } from 'react';
import { getInitials, generateBackground } from '../../../avatarsUtils';
import { IconArrowDown, IconCross, IconDisketa, IconFileText, IconRestart, IconTrash } from '../../../../../components/Icons';
import RightItem from '../../../../Login/Reg/AdminReg/components/RightItem';
import ChangeBtn from './components/ChangeBtn';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../../../../store/selectors/erpSelector';
import { getPermissionsList } from '../../../../../store/selectors/permissionsListSelector';
import { getPermissionList } from '../../../../../store/slices/permissionsSlice';
import UniversalButton from '../../../../../components/Buttons/UniversalButton/UniversalButton';
import {
	deleteUser,
	setUserModalActive,
	setUserRightsModalActive,
	setInputChange,
	// setUserChoosenNowPermissions,
	// setUserChoosenBeforePermissions,
	setChoosenTerminalUserGroupPermissions,
	setChoosenTerminalUserAddedBeforePermissions,
	setChoosenTerminalUserAddedNowPermissions,
	setTerminalAccessUserGroup,
	setTerminalAccessUserGroupId,
	postTerminalAccessForUser,
	putUserAction,
	clearAllPermissionArrays,
	clearNessesaryDataBeforeCreatingUserAccessToTerminal,
	setChangeModalTypeForChangeUserPermissions,
	setTerminalAccessUserAddedNowPermissions,
	setNewGroupWhileChangingPermissions,
	setNewGroupIdWhileChangingPermissions,
} from '../../../../../store/slices/userSlice';
import { setTerminalUsersAfterDelete } from '../../../../../store/slices/terminalPageSlice';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../../store/slices/sideBarSlice';
import {
	deleteGroup,
	getGroupList,
	postGroup,
	setChoosenGroupPermissions,
	setCreateRoleInputChange,
	setNewGroupName,
	setPermissionsForNewRole,
	setSelectedGroupForNewRole,
	setSelectedGroupId,
	setNewPermissionsForExistingRole,
	clearAllGroupArrays,
	putGroup,
	setUpdatedGroupName,
	resetGroupChanges,
} from '../../../../../store/slices/groupSlice';
import MainInput from '../../../../../components/Inputs/MainInput/MainInput';
import { useForm } from 'react-hook-form';
import { Select, SelectLaptop } from '../../../../../components';
import { useLocation } from 'react-router-dom';
import { putUser } from '../../../../../api/erp/putUser';
import RequireUI from '../../../../../hoc/RequireUI';
// !type:
// createGroup - для создания новой роли
// changeGroup - редактирования роли/должности
// changePermissions - для изменения пермишенов пользователя на терминале
// accessTerminal - создание пермишенов и группы пользователя при открытии доступа на терминал

export function ModalRightsLayout({ type, children }) {
	const location = useLocation();
	const [showGroupModal, setShowGroupModal] = useState(false);
	const {
		register,
		setFocus,
		setValue,
		formState: { errors },
		clearErrors,
		handleSubmit,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			//   email: localStorage.getItem("email"),
			// FLName: newFullName,
			//   login: localStorage.getItem("login"),
			//   numberContract: localStorage.getItem("numberContract"),
			//   urAdress: localStorage.getItem("urAdress"),
			//   urName: localStorage.getItem("urName"),
			//   autoNum: '',
		},
	});
	const dispatch = useDispatch();
	const {
		newGroupName,
		groupList,
		selectedGroupForNewRole,
		updatedGroupName,
		choosenGroup,
		choosenGroupPermissionsForNewRole,
		newPermissionsForExistingRole,
	} = useSelector((state) => state.group);

	const {
		inputChange,
		currentUser,
		terminalAccessUserGroup,
		currentUserChoosenTerminalData,
		choosenTerminalUserGroupPermissions,
		newGroupWhileChangingPermissions,
	} = useSelector((state) => state.user);

	const handleTest = () => {
		console.log('test');
		dispatch(postGroup({ id: 34 }));
	};

	//! Открытие списка с ролями
	const handleOpenGroupModal = () => {
		setShowGroupModal(!showGroupModal);
	};

	useEffect(() => {
		dispatch(getGroupList());
	}, [dispatch]);

	useEffect(() => {
		if (type === 'accessTerminal') {
			dispatch(clearNessesaryDataBeforeCreatingUserAccessToTerminal());
		}
	}, []);

	return (
		<div className={`${type === 'createGroup' ? styles.modal__card3 : styles.modal__card1}`}>
			{/* setUpdatedGroupName */}
			{type === 'createGroup' && <p className={styles.modal__card_p}>Создание новой должности</p>}
			{type === 'changeGroup' && <p className={styles.modal__card_p}>Изменение должности</p>}
			{type === 'changePermissions' && (
				<p className={styles.modal__card_p}>Изменение прав на терминале {currentUserChoosenTerminalData?.name}</p>
			)}
			{type === 'accessTerminal' && (
				<p className={styles.modal__card_p}>Открытие доступа к терминалу {currentUserChoosenTerminalData?.name}</p>
			)}
			<div className={styles.modalRights_wrapper_top}>
				{type === 'createGroup' ? (
					<MainInput
						title={'Название должности'}
						errorTitle={'Введите название должности'}
						setInputChange={(bool) => dispatch(setCreateRoleInputChange(bool))}
						register={register}
						errors={errors}
						setFocus={setFocus}
						name="FLName"
						value={newGroupName ? newGroupName : ''}
						handleFieldChange={(e) => dispatch(setNewGroupName(e.target.value))}
						setValue={setValue}
						rules={{
							required: newGroupName ? null : 'Обязательное поле',
							minLength: {
								value: 3,
								message: 'Минимум 3 символа',
							},
						}}
						placeholder={'Должность сотрудника'}
					/>
				) : null}
				{type === 'changeGroup' ? (
					<MainInput
						title={'Название должности'}
						errorTitle={'Введите название должности'}
						setInputChange={(bool) => dispatch(setCreateRoleInputChange(bool))}
						register={register}
						errors={errors}
						setFocus={setFocus}
						name="FLName"
						value={updatedGroupName ? updatedGroupName : ''}
						handleFieldChange={(e) => dispatch(setUpdatedGroupName(e.target.value))}
						setValue={setValue}
						rules={{
							required: updatedGroupName ? null : 'Обязательное поле',
							minLength: {
								value: 3,
								message: 'Минимум 3 символа',
							},
						}}
						placeholder={'Должность сотрудника'}
					/>
				) : null}
				{/* TODO сейчас */}
				{type === 'changePermissions' && (
					<MainInput
						title={'Изменить должность'}
						name="selectInput"
						errors={errors}
						setFocus={setFocus}
						register={register}
						setValue={setValue}
						setInputChange={(bool) => dispatch(setInputChange(bool))}
						handleFieldChange={(e) => dispatch(setNewGroupWhileChangingPermissions(e.target.value))}
						value={
							newGroupWhileChangingPermissions ? newGroupWhileChangingPermissions : currentUserChoosenTerminalData.group.name
						}
						rules={{
							required: false,
						}}
						placeholder={'Выберите из списка'}
						modalDropList={showGroupModal}
						onClick={handleOpenGroupModal}
					/>
				)}
				{showGroupModal && type === 'changePermissions' && (
					<div className={styles.additional_to_selectLapTop}>
						<SelectLaptop
							options={groupList}
							oneUser={currentUser}
							inputChange={inputChange}
							setInputChange={(bool) => dispatch(setInputChange(bool))}
							selectedOption={newGroupWhileChangingPermissions}
							setSelectedOption={(option_id) => dispatch(setNewGroupWhileChangingPermissions(option_id))}
							setSelectedId={(id) => dispatch(setNewGroupIdWhileChangingPermissions(id))}
							setShowModal={setShowGroupModal}
							setValue={setValue}
						/>
					</div>
				)}
				{type === 'createGroup' && (
					<MainInput
						title={'Взять за основу'}
						name="selectInput"
						errors={errors}
						setFocus={setFocus}
						register={register}
						setValue={setValue}
						setInputChange={(bool) => dispatch(setCreateRoleInputChange(bool))}
						handleFieldChange={(e) => dispatch(setSelectedGroupForNewRole(e.target.value))}
						value={selectedGroupForNewRole ? selectedGroupForNewRole : ''}
						rules={{
							required: selectedGroupForNewRole ? null : 'Обязательное поле',
						}}
						placeholder={'Выберите из списка'}
						modalDropList={showGroupModal}
						onClick={handleOpenGroupModal}
					/>
				)}

				{showGroupModal && type === 'createGroup' && (
					<div className={styles.additional_to_selectLapTop}>
						<SelectLaptop
							options={groupList}
							oneUser={currentUser}
							inputChange={inputChange}
							setInputChange={(bool) => dispatch(setCreateRoleInputChange(bool))}
							selectedOption={selectedGroupForNewRole ? selectedGroupForNewRole : ''}
							setSelectedOption={(option_id) => dispatch(setSelectedGroupForNewRole(option_id))}
							setSelectedId={(id) => dispatch(setSelectedGroupId(id))}
							setShowModal={setShowGroupModal}
							setValue={setValue}
						/>
					</div>
				)}
				{type === 'accessTerminal' && (
					<div className={styles.relative}>
						<MainInput
							title={'Выбрать должность'}
							name="selectInput"
							errors={errors}
							setFocus={setFocus}
							register={register}
							setValue={setValue}
							setInputChange={(bool) => dispatch(setInputChange(bool))}
							handleFieldChange={(e) => dispatch(setTerminalAccessUserGroup(e.target.value))}
							value={terminalAccessUserGroup ? terminalAccessUserGroup : ''}
							rules={{
								required: terminalAccessUserGroup ? null : 'Обязательное поле',
							}}
							placeholder={'Выберите из списка'}
							modalDropList={showGroupModal}
							onClick={handleOpenGroupModal}
						/>
						{showGroupModal && (
							<SelectLaptop
								options={groupList}
								oneUser={currentUser}
								inputChange={inputChange}
								setInputChange={(bool) => dispatch(setInputChange(bool))}
								selectedOption={terminalAccessUserGroup}
								setSelectedOption={(option_id) => dispatch(setTerminalAccessUserGroup(option_id))}
								setSelectedId={(id) => dispatch(setTerminalAccessUserGroupId(id))}
								setShowModal={setShowGroupModal}
								setValue={setValue}
							/>
						)}
					</div>
				)}
			</div>
			{children}
			{/* <button type='button' onClick={()=>handleTest()}>СУДА</button> */}
		</div>
	);
}
function ModalRights({ type }) {
	const dispatch = useDispatch();
	const { permissionList } = useSelector((state) => state.permissions);
	const {
		currentUser,
		modalType,
		choosenTerminalUserAddedNowPermissions,
		userChoosenBeforePermissions,
		userGroupPermissions,
		currentUserChoosenTerminalData,
		choosenTerminalUserGroupPermissions,
		choosenTerminalUserAddedBeforePermissions,
		terminalAccessUserGroupId,
		terminalForAccessId,
		terminalAccessUserGroup,
	} = useSelector((state) => state.user);

	const {
		choosenGroupPermissionsForNewRole,
		createRoleInputChange,
		newGroupName,
		selectedGroupForNewRole,
		choosenGroup,
		updatedGroupName,
		newPermissionsForExistingRole,
		changeModalTypeForGroupChange,
	} = useSelector((state) => state.group);

	const [inputChanged, setInputChanged] = useState(false);

	const [activeTab, setActiveTab] = useState(null);

	// Тест
	// const [userGroupPermissions, setUserGroupPermissions] = useState([])
	// const [userCustomPermissions, setUserCustomPermissions] = useState([]);
	// const [userChosenPermissions, setUserChosenPermissions] = useState([])

	const [check, setCheck] = useState(false);
	const [base, setBase] = useState(false);

	const [buttonToAdd, setButtonToAdd] = useState([
		{ type: 'add', condition: null },
		{ type: 'delete', condition: null },
		{ type: 'view', condition: null },
		{ type: 'change', condition: null },
	]);

	const handleCreateRole = () => {
		const changedPermArray = choosenGroupPermissionsForNewRole.map((el) => el.id);
		const requestData = {
			name: newGroupName,
			permissions: changedPermArray,
		};
		dispatch(postGroup({ requestData }));
		dispatch(setCurrentElementForSideBar(null));
		dispatch(setSideBarActive(false));
	};

	//! Функция для изменения названия и пермишенов существующей роли/должности
	//? Складываем пермишены пришедшие изначально с ролью и те которые мы выбрали
	const handleChangeRole = () => {
		const allPermissions = newPermissionsForExistingRole.concat(choosenGroup.permissions);
		const changedPermArray = allPermissions.map((el) => el?.id);
		const requestData = {
			name: updatedGroupName,
			permissions: changedPermArray,
		};
		dispatch(putGroup({ group_id: choosenGroup?.id, requestData }));
		dispatch(setCurrentElementForSideBar(null));
		dispatch(setSideBarActive(false));
	};

	//! Очистка данных с выбранными массивами пермишенов
	useEffect(() => {
		return () => {
			dispatch(clearAllGroupArrays());
		};
	}, []);

	//! Запись пермишенов роли в соответствующие стейты redux в зависимости от выбранного терминала
	useEffect(() => {
		if (type === 'changePermissions') {
			dispatch(setChangeModalTypeForChangeUserPermissions(true));
			dispatch(setChoosenTerminalUserGroupPermissions(currentUserChoosenTerminalData?.group?.permissions));
			dispatch(
				setChoosenTerminalUserAddedBeforePermissions({
					type: 'add',
					permission: currentUserChoosenTerminalData?.permissions,
				}),
			);
		}
	}, [currentUserChoosenTerminalData, dispatch]);

	//! Функция для выбора всех пермишенов конкретного типа по нажатию кнопки (исключая пермишены роли по умолчанию)
	//! Также выбирается соответствующий объект пермишена с типом view
	//! РАБОТАЕТ НОРМАЛЛЬНО
	function findPermissionByType(type, scenario) {
		let foundPermissions = [];
		permissionList.forEach((item) => {
			let permissionsWithMatchingType;

			if (scenario === 'accessTerminal') {
				permissionsWithMatchingType = item?.permissions.filter(
					(permission) =>
						(permission.type === type || permission.type === 'view') &&
						!checkExististionOfAnyUserPermission(permission, 'group') &&
						!checkExististionOfAnyUserPermission(permission, 'choosenBefore'),
				);
			}
			if (scenario === 'changePermissions') {
				permissionsWithMatchingType = item?.permissions.filter(
					(permission) =>
						(permission.type === type || permission.type === 'view') &&
						!checkExististionOfAnyUserPermission(permission, 'group') &&
						!checkExististionOfAnyUserPermission(permission, 'choosenBefore'),
				);
			}

			if (scenario === 'changeGroup') {
				permissionsWithMatchingType = item?.permissions.filter(
					(permission) =>
						(permission.type === type || permission.type === 'view') &&
						!checkExististionOfAnyUserPermission(permission, 'group') &&
						!checkExististionOfAnyUserPermission(permission, 'choosenBefore'),
				);
			}
			if (scenario === 'createGroup') {
				permissionsWithMatchingType = item?.permissions.filter(
					(permission) =>
						(permission.type === type || permission.type === 'view') &&
						!checkExististionOfAnyUserPermission(permission, 'group') &&
						!checkExististionOfAnyUserPermission(permission, 'choosenBefore'),
				);
			}

			//TODO ОШИБКА ЕСЛИ ВЫБРАНА РОЛЬ ПРИ ОТКРЫТИИ ДОСТУПА
			//TODO еще при выходе в терминалы не обнуляется группа которая в инпуте отображается
			// console.log(permissionsWithMatchingType, 'ПЕРМИШЕН ВИЗ МЕЧИНГ ТАЙП');

			foundPermissions = foundPermissions.concat(permissionsWithMatchingType);
		});
		// const permissionsIds = foundPermissions.map((permission) => permission.id);
		return { foundPermissions: foundPermissions, type: type };
	}

	//! Функция для проверки пермишена есть ли он в различных массивах пермишенов. Групповые/выбранные ранее/выбранные сейчас
	function checkExististionOfAnyUserPermission(el, permissionType) {
		//! Типы в case:
		// - group : Проверка пермишена el на наличие его в массиве пермишенов роли на выбранном терминале у пользователя (choosenTerminalUserGroupPermissions)
		// - choosenBefore : Проверка пермишена el на наличие его в массиве пермишенов добавленных к роли ранее  на выбранном терминале у пользователя (choosenTerminalUserAddedBeforePermissions)
		// - choosenNow : Проверка пермишена el на наличие его в массиве выбранных сейчас пермишенов для роли на выбранном терминале у пользователя (choosenTerminalUserAddedNowPermissions)
		// - createGroup : Проверка пермишена el на наличие его в массиве пермишенов выбранной для наследования роли (choosenGroupPermissionsForNewRole). Используется при создании роли
		let checkExisting;
		switch (permissionType) {
			case 'group':
				checkExisting = choosenTerminalUserGroupPermissions?.find((userPermission) => userPermission.id === el.id);
				break;
			case 'choosenBefore':
				checkExisting = choosenTerminalUserAddedBeforePermissions?.find((userPermission) => userPermission.id === el.id);
				break;
			case 'choosenNow':
				checkExisting = choosenTerminalUserAddedNowPermissions?.find((userPermission) => userPermission.id === el.id);
				break;
			case 'createGroup':
				checkExisting = choosenGroupPermissionsForNewRole?.find((userPermission) => userPermission.id === el.id);
				break;
			case 'changeGroup':
				checkExisting = choosenGroup?.permissions?.find((userPermission) => userPermission.id === el.id);
				break;
			case 'changeGroupChoosenNow':
				checkExisting = newPermissionsForExistingRole?.find((userPermission) => userPermission.id === el.id);
				break;
			default:
				break;
		}

		if (checkExisting) {
			return true;
		} else {
			return false;
		}
	}

	//! Функция, которая при выборе любого пермишена с типом 'change'/'delete'/'add' - проставляет в той же группе пермишен 'view'

	function addViewPermission(permission, permissionList, arrayToPush, scenario, scenarioArray, actionType) {
		if (actionType === 'add') {
			arrayToPush.push(permission);
			const group = permissionList.find((group) => group.permissions.some((perm) => perm?.id === permission?.id));
			const viewPermission = group.permissions.find((perm) => perm.type === 'view');

			// Обработка сценариев
			switch (scenario) {
				case 'createGroup':
					if (viewPermission && !scenarioArray?.some((perm) => perm?.id === viewPermission?.id)) {
						arrayToPush.push(viewPermission); // Добавляем пермишен типа 'view' в массив
					}
					break;
				case 'changeGroup':
					if (viewPermission && !scenarioArray?.some((perm) => perm?.id === viewPermission?.id)) {
						arrayToPush.push(viewPermission); // Добавляем пермишен типа 'view' в массив
					}
					break;
				case 'changePermissions':
					if (viewPermission && !scenarioArray?.some((perm) => perm?.id === viewPermission?.id)) {
						arrayToPush?.push(viewPermission); // Добавляем пермишен типа 'view' в массив
					}
					break;
				case 'accessTerminal':
					if (viewPermission && !scenarioArray?.some((perm) => perm?.id === viewPermission?.id)) {
						arrayToPush.push(viewPermission); // Добавляем пермишен типа 'view' в массив
					}
					break;
				default:
					console.log('Unknown scenario');
			}

			return arrayToPush;
		} else if (actionType === 'remove') {
			//TODO 20.03.2024
			arrayToPush.push(permission);

			const group = permissionList.find((group) => group.permissions.some((perm) => perm.id === permission.id));
			const addPermission = group.permissions.find((perm) => perm.type === 'add');
			const deletePermission = group.permissions.find((perm) => perm.type === 'delete');
			const changePermission = group.permissions.find((perm) => perm.type === 'change');

			// Обработка сценариев
			switch (scenario) {
				case 'createGroup':
					if (permission.type === 'view' && scenarioArray.some((perm) => perm.id === addPermission.id)) {
						arrayToPush.push(addPermission); // Добавляем пермишен типа 'add' в массив
					}
					if (permission.type === 'view' && scenarioArray.some((perm) => perm.id === deletePermission.id)) {
						arrayToPush.push(deletePermission); // Добавляем пермишен типа 'delete' в массив
					}
					if (permission.type === 'view' && scenarioArray.some((perm) => perm.id === changePermission.id)) {
						arrayToPush.push(changePermission); // Добавляем пермишен типа 'change' в массив
					}
					break;
				case 'changeGroup':
					if (permission.type === 'view' && scenarioArray.some((perm) => perm.id === addPermission.id)) {
						arrayToPush.push(addPermission); // Добавляем пермишен типа 'add' в массив
					}
					if (permission.type === 'view' && scenarioArray.some((perm) => perm.id === deletePermission.id)) {
						arrayToPush.push(deletePermission); // Добавляем пермишен типа 'delete' в массив
					}
					if (permission.type === 'view' && scenarioArray.some((perm) => perm.id === changePermission.id)) {
						arrayToPush.push(changePermission); // Добавляем пермишен типа 'change' в массив
					}
					break;
				case 'changePermissions':
					if (permission.type === 'view' && scenarioArray.some((perm) => perm.id === addPermission.id)) {
						arrayToPush.push(addPermission); // Добавляем пермишен типа 'add' в массив
					}
					if (permission.type === 'view' && scenarioArray.some((perm) => perm.id === deletePermission.id)) {
						arrayToPush.push(deletePermission); // Добавляем пермишен типа 'delete' в массив
					}
					if (permission.type === 'view' && scenarioArray.some((perm) => perm.id === changePermission.id)) {
						arrayToPush.push(changePermission); // Добавляем пермишен типа 'change' в массив
					}
					break;
				case 'accessTerminal':
					if (permission.type === 'view' && scenarioArray.some((perm) => perm.id === addPermission.id)) {
						arrayToPush.push(addPermission); // Добавляем пермишен типа 'add' в массив
					}
					if (permission.type === 'view' && scenarioArray.some((perm) => perm.id === deletePermission.id)) {
						arrayToPush.push(deletePermission); // Добавляем пермишен типа 'delete' в массив
					}
					if (permission.type === 'view' && scenarioArray.some((perm) => perm.id === changePermission.id)) {
						arrayToPush.push(changePermission); // Добавляем пермишен типа 'change' в массив
					}
					break;
				default:
					console.log('Unknown scenario');
			}
			return arrayToPush;
		}
	}

	//! Функция, которая проверяет массив групповых прав (прав должности) и массив добавленных ранее прав.
	//! В случае если мы изменили права группы и они были дополнительно добавлены пользователю, они убираются из отображения
	function checkUserPermissionsIfRoleWasChanged(choosenTerminalUserAddedBeforePermissions, choosenTerminalUserGroupPermissions) {
		const idsToRemove = new Set(choosenTerminalUserGroupPermissions?.map((item) => item.id));
		const newChoosenBeforeArray = choosenTerminalUserAddedBeforePermissions?.filter((item) => !idsToRemove.has(item.id));
		if (newChoosenBeforeArray.length) {
			dispatch(
				setChoosenTerminalUserAddedBeforePermissions({
					type: 'checkIfRoleWasChaged',
					permission: newChoosenBeforeArray,
				}),
			);
		}
	}

	//! Важно! Срабатывает если мы изменили права в должности, но у нас ранее эти права были выбраны у отдельного сотрудника.
	//! Вызывается функция, которая убирает из choosenTerminalUserAddedBeforePermissions недавно добавленные права
	useEffect(() => {
		checkUserPermissionsIfRoleWasChanged(choosenTerminalUserAddedBeforePermissions, choosenTerminalUserGroupPermissions);
	}, [choosenTerminalUserGroupPermissions, dispatch]);

	//TODO 21.03.2024
	//! Эта функция проверяет количество пермишенов одного типа в разных массивах и меняет соостояние кнопки для выбора всего или удаления всего
	//TODO нужно докрутить чтобы при открытии сразу отрабатывал юзефект
	const updateButtonToAdd = ({ type, permissionList, groupPermissions, choosenBeforePermissions, choosenNowPermissions, scenario }) => {
		if (scenario === 'user') {
			const allPermissions = permissionList.flatMap((group) => group?.permissions);
			const countInPermissionList = allPermissions.filter((permission) => permission?.type === type).length;
			if (!Array.isArray(groupPermissions)) {
				groupPermissions = [];
			}
			const countInOtherArrays = [...groupPermissions, ...choosenBeforePermissions, ...choosenNowPermissions].filter((permission) => {
				return permission?.type === type;
			}).length;

			let newCondition;
			if (countInPermissionList == 0) {
				newCondition = true;
			} else if (countInPermissionList !== 0) {
				newCondition = countInOtherArrays < countInPermissionList;
			}
			console.log('user', countInOtherArrays, countInPermissionList);
			setButtonToAdd((prevState) =>
				prevState.map((button) => {
					if (button.type === type) {
						return { ...button, condition: newCondition };
					}
					return button;
				}),
			);
		} else if (scenario === 'group') {
			if (!choosenBeforePermissions) {
				choosenBeforePermissions = [];
			}
			const allPermissions = permissionList.flatMap((group) => group?.permissions);
			const countInPermissionList = allPermissions.filter((permission) => permission?.type === type).length;
			const countInOtherArrays = [...choosenBeforePermissions, ...choosenNowPermissions].filter((permission) => {
				return permission?.type === type;
			}).length;

			console.log(
				'group:',
				type,
				countInOtherArrays,
				'before:',
				choosenBeforePermissions.filter((permission) => {
					return permission?.type === type;
				}),
				'now:',
				choosenNowPermissions.filter((permission) => {
					return permission?.type === type;
				}),
				'|||',
				countInPermissionList,
			);
			const newCondition = countInOtherArrays < countInPermissionList;

			setButtonToAdd((prevState) =>
				prevState.map((button) => {
					if (button.type === type) {
						return { ...button, condition: newCondition };
					}
					return button;
				}),
			);
		}
	};

	//! createRole
	useEffect(() => {
		updateButtonToAdd({
			type: 'change',
			permissionList: permissionList,
			groupPermissions: null,
			choosenBeforePermissions: null,
			choosenNowPermissions: choosenGroupPermissionsForNewRole,
			scenario: 'group',
		});
	}, [choosenGroupPermissionsForNewRole, permissionList]);

	//! createRole
	useEffect(() => {
		updateButtonToAdd({
			type: 'add',
			permissionList: permissionList,
			groupPermissions: null,
			choosenBeforePermissions: null,
			choosenNowPermissions: choosenGroupPermissionsForNewRole,
			scenario: 'group',
		});
	}, [choosenGroupPermissionsForNewRole, permissionList]);

	//! createRole
	useEffect(() => {
		updateButtonToAdd({
			type: 'delete',
			permissionList: permissionList,
			groupPermissions: null,
			choosenBeforePermissions: null,
			choosenNowPermissions: choosenGroupPermissionsForNewRole,
			scenario: 'group',
		});
	}, [choosenGroupPermissionsForNewRole, permissionList]);

	//! createRole
	useEffect(() => {
		updateButtonToAdd({
			type: 'view',
			permissionList: permissionList,
			groupPermissions: null,
			choosenBeforePermissions: null,
			choosenNowPermissions: choosenGroupPermissionsForNewRole,
			scenario: 'group',
		});
	}, [choosenGroupPermissionsForNewRole, permissionList]);

	//! changeGroup
	useEffect(() => {
		updateButtonToAdd({
			type: 'change',
			permissionList: permissionList,
			groupPermissions: null,
			choosenBeforePermissions: choosenGroup?.permissions,
			choosenNowPermissions: newPermissionsForExistingRole,
			scenario: 'group',
		});
	}, [newPermissionsForExistingRole, permissionList, choosenGroup?.permissions]);

	//! changeGroup
	useEffect(() => {
		updateButtonToAdd({
			type: 'delete',
			permissionList: permissionList,
			groupPermissions: null,
			choosenBeforePermissions: choosenGroup?.permissions,
			choosenNowPermissions: newPermissionsForExistingRole,
			scenario: 'group',
		});
	}, [newPermissionsForExistingRole, permissionList, choosenGroup?.permissions]);
	//! changeGroup
	useEffect(() => {
		updateButtonToAdd({
			type: 'add',
			permissionList: permissionList,
			groupPermissions: null,
			choosenBeforePermissions: choosenGroup?.permissions,
			choosenNowPermissions: newPermissionsForExistingRole,
			scenario: 'group',
		});
	}, [newPermissionsForExistingRole, permissionList, choosenGroup?.permissions]);
	//! changeGroup
	useEffect(() => {
		updateButtonToAdd({
			type: 'view',
			permissionList: permissionList,
			groupPermissions: null,
			choosenBeforePermissions: choosenGroup?.permissions,
			choosenNowPermissions: newPermissionsForExistingRole,
			scenario: 'group',
		});
	}, [newPermissionsForExistingRole, permissionList, choosenGroup?.permissions]);

	//! changePermissions / accessTerminal
	useEffect(() => {
		updateButtonToAdd({
			type: 'change',
			permissionList: permissionList,
			groupPermissions: choosenTerminalUserGroupPermissions,
			choosenBeforePermissions: choosenTerminalUserAddedBeforePermissions,
			choosenNowPermissions: choosenTerminalUserAddedNowPermissions,
			scenario: 'user',
		});
	}, [choosenTerminalUserAddedNowPermissions, permissionList]);

	//! changePermissions / accessTerminal
	useEffect(() => {
		updateButtonToAdd({
			type: 'delete',
			permissionList: permissionList,
			groupPermissions: choosenTerminalUserGroupPermissions,
			choosenBeforePermissions: choosenTerminalUserAddedBeforePermissions,
			choosenNowPermissions: choosenTerminalUserAddedNowPermissions,
			scenario: 'user',
		});
	}, [choosenTerminalUserAddedNowPermissions, permissionList]);

	//! changePermissions / accessTerminal
	useEffect(() => {
		updateButtonToAdd({
			type: 'add',
			permissionList: permissionList,
			groupPermissions: choosenTerminalUserGroupPermissions,
			choosenBeforePermissions: choosenTerminalUserAddedBeforePermissions,
			choosenNowPermissions: choosenTerminalUserAddedNowPermissions,
			scenario: 'user',
		});
	}, [choosenTerminalUserAddedNowPermissions, permissionList]);

	//! changePermissions / accessTerminal
	useEffect(() => {
		updateButtonToAdd({
			type: 'view',
			permissionList: permissionList,
			groupPermissions: choosenTerminalUserGroupPermissions,
			choosenBeforePermissions: choosenTerminalUserAddedBeforePermissions,
			choosenNowPermissions: choosenTerminalUserAddedNowPermissions,
			scenario: 'user',
		});
	}, [choosenTerminalUserAddedNowPermissions, permissionList]);

	//! Функция срабатывающая при нажатии на кнопку выбра всех пермишенов одного типа. Выполняется проверка от дублирования
	//! ОДНА ИЗ ОСНОВНЫХ ФУНКЦИЙ
	function handleChooseRoles(permissionType) {
		dispatch(setInputChange(true));
		const permissions = findPermissionByType(permissionType, type).foundPermissions;
		const concatPermsUnique = permissions.filter((el) => el.type === permissionType);
		concatPermsUnique.forEach((el) => {
			toggleSelectItem({ elt: el, byGroupClick: true });
		});
	}

	// Удаление группы
	const handleDeleteGroup = () => {
		dispatch(deleteGroup({ group_id: choosenGroup.id }));
		dispatch(setCurrentElementForSideBar(null));
		dispatch(setSideBarActive(false));
	};

	const handleChangePermissions = () => {
		console.log('OK');
	};

	useEffect(() => {
		return () => dispatch(clearNessesaryDataBeforeCreatingUserAccessToTerminal());
	}, []);

	//! Функция для добавления/удаления выбранного пермишенна в соответствующие массивы
	const toggleSelectItem = ({ elt, byGroupClick }) => {
		dispatch(setInputChange(true));
		const buttonCondition = buttonToAdd.find((el) => el.type === elt.type).condition;
		if (type === 'changePermissions') {
			if (
				!checkExististionOfAnyUserPermission(elt, 'group') &&
				!checkExististionOfAnyUserPermission(elt, 'choosenNow') &&
				!checkExististionOfAnyUserPermission(elt, 'choosenBefore')
			) {
				const arrayForElt = [];

				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'changePermissions',
					choosenTerminalUserAddedNowPermissions
						.concat(choosenTerminalUserAddedBeforePermissions)
						.concat(choosenTerminalUserGroupPermissions),
					'add',
				);
				dispatch(setChoosenTerminalUserAddedNowPermissions({ type: 'add', permission: formattedElt }));
			}
			if (checkExististionOfAnyUserPermission(elt, 'choosenNow') && !byGroupClick) {
				//TODO 20.03.2024 DONE
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'changePermissions',
					choosenTerminalUserAddedNowPermissions.concat(choosenTerminalUserAddedBeforePermissions),
					'remove',
				);
				dispatch(
					setChoosenTerminalUserAddedNowPermissions({
						type: 'remove',
						permission: formattedElt,
						option: 'ifArrayThenRemoveNowAndBefore',
					}),
				);
			}
			if (checkExististionOfAnyUserPermission(elt, 'choosenNow') && !buttonCondition && byGroupClick) {
				//TODO 20.03.2024 DONE
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'changePermissions',
					choosenTerminalUserAddedNowPermissions.concat(choosenTerminalUserAddedBeforePermissions),
					'remove',
				);
				dispatch(
					setChoosenTerminalUserAddedNowPermissions({
						type: 'remove',
						permission: formattedElt,
						option: 'ifArrayThenRemoveNowAndBefore',
					}),
				);
			}

			if (checkExististionOfAnyUserPermission(elt, 'choosenBefore')) {
				//TODO 20.03.2024 DONE
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'changePermissions',
					choosenTerminalUserAddedBeforePermissions.concat(choosenTerminalUserAddedNowPermissions),
					'remove',
				);
				dispatch(
					setChoosenTerminalUserAddedBeforePermissions({
						type: 'remove',
						permission: formattedElt,
						option: 'ifArrayThenRemoveNowAndBefore',
					}),
				);
			}
		} else if (type === 'createGroup') {
			if (!checkExististionOfAnyUserPermission(elt, 'createGroup')) {
				dispatch(setCreateRoleInputChange(true));
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'createGroup',
					choosenGroupPermissionsForNewRole,
					'add',
				);
				dispatch(setPermissionsForNewRole({ type: 'add', permission: formattedElt }));
			}
			if (checkExististionOfAnyUserPermission(elt, 'createGroup') && byGroupClick && !buttonCondition) {
				dispatch(setCreateRoleInputChange(true));
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'createGroup',
					choosenGroupPermissionsForNewRole,
					'remove',
				);
				dispatch(
					setPermissionsForNewRole({
						type: 'remove',
						permission: formattedElt,
						option: 'ifArrayThenRemoveNowAndBefore',
					}),
				);
			}
			if (checkExististionOfAnyUserPermission(elt, 'createGroup') && !byGroupClick) {
				dispatch(setCreateRoleInputChange(true));
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'createGroup',
					choosenGroupPermissionsForNewRole,
					'remove',
				);
				dispatch(
					setPermissionsForNewRole({
						type: 'remove',
						permission: formattedElt,
						option: 'ifArrayThenRemoveNowAndBefore',
					}),
				);
			}
		} else if (type === 'changeGroup') {
			if (
				!checkExististionOfAnyUserPermission(elt, 'changeGroup') &&
				!checkExististionOfAnyUserPermission(elt, 'changeGroupChoosenNow')
			) {
				dispatch(setCreateRoleInputChange(true));
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'changeGroup',
					newPermissionsForExistingRole.concat(choosenGroup?.permissions),
					'add',
				);
				dispatch(setNewPermissionsForExistingRole({ type: 'add', permission: formattedElt }));
			}
			if (checkExististionOfAnyUserPermission(elt, 'changeGroup') && !byGroupClick) {
				dispatch(setCreateRoleInputChange(true));
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'changeGroup',
					newPermissionsForExistingRole.concat(choosenGroup?.permissions),
					'remove',
				);
				dispatch(
					setChoosenGroupPermissions({
						type: 'remove',
						permission: formattedElt,
						option: 'ifArrayThenRemoveNowAndBefore',
					}),
				);
			}
			if (checkExististionOfAnyUserPermission(elt, 'changeGroup') && !buttonCondition && byGroupClick) {
				dispatch(setCreateRoleInputChange(true));
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'changeGroup',
					newPermissionsForExistingRole.concat(choosenGroup?.permissions),
					'remove',
				);
				dispatch(
					setChoosenGroupPermissions({
						type: 'remove',
						permission: formattedElt,
						option: 'ifArrayThenRemoveNowAndBefore',
					}),
				);
			}
			if (checkExististionOfAnyUserPermission(elt, 'changeGroupChoosenNow') && !byGroupClick) {
				dispatch(setCreateRoleInputChange(true));
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'changeGroup',
					newPermissionsForExistingRole.concat(choosenGroup?.permissions),
					'remove',
				);
				dispatch(
					setNewPermissionsForExistingRole({
						type: 'remove',
						permission: formattedElt,
						option: 'ifArrayThenRemoveNowAndBefore',
					}),
				);
			}
			if (checkExististionOfAnyUserPermission(elt, 'changeGroupChoosenNow') && byGroupClick && !buttonCondition) {
				dispatch(setCreateRoleInputChange(true));
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'changeGroup',
					newPermissionsForExistingRole.concat(choosenGroup?.permissions),
					'remove',
				);
				dispatch(
					setNewPermissionsForExistingRole({
						type: 'remove',
						permission: formattedElt,
						option: 'ifArrayThenRemoveNowAndBefore',
					}),
				);
			}
		} else if (type === 'accessTerminal') {
			if (!checkExististionOfAnyUserPermission(elt, 'group') && !checkExististionOfAnyUserPermission(elt, 'choosenNow')) {
				dispatch(setCreateRoleInputChange(true));
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'accessTerminal',
					choosenTerminalUserAddedNowPermissions.concat(choosenTerminalUserGroupPermissions),
					'add',
				);
				dispatch(setTerminalAccessUserAddedNowPermissions({ type: 'add', permission: formattedElt }));
			}
			if (checkExististionOfAnyUserPermission(elt, 'choosenNow') && !byGroupClick) {
				dispatch(setCreateRoleInputChange(true));
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'accessTerminal',
					choosenTerminalUserAddedNowPermissions,
					'remove',
				);
				dispatch(
					setTerminalAccessUserAddedNowPermissions({
						type: 'remove',
						permission: formattedElt,
						option: 'ifArrayThenRemoveNowAndBefore',
					}),
				);
			}

			if (checkExististionOfAnyUserPermission(elt, 'choosenNow') && byGroupClick && !buttonCondition) {
				dispatch(setCreateRoleInputChange(true));
				const arrayForElt = [];
				const formattedElt = addViewPermission(
					elt,
					permissionList,
					arrayForElt,
					'accessTerminal',
					choosenTerminalUserAddedNowPermissions,
					'remove',
				);
				dispatch(
					setTerminalAccessUserAddedNowPermissions({
						type: 'remove',
						permission: formattedElt,
						option: 'ifArrayThenRemoveNowAndBefore',
					}),
				);
			}
		}
	};

	const updateBaseState = () => {
		// handlePutBaseUser();
		// setRools(currentUser.user_permissions_ids || currentUser?.group_permissions_ids || []);
	};

	const handleOpenModal = () => {
		dispatch(setUserModalActive(true));
		dispatch(setUserRightsModalActive(false));
	};
	const handleOpenRights = () => {
		dispatch(getPermissionList());
		dispatch(setUserRightsModalActive(true));
		dispatch(setUserModalActive(false));
	};

	const handleResetGroupChanges = () => {
		dispatch(resetGroupChanges());
	};

	return (
		<ModalRightsLayout type={type}>
			<div className={styles.modal__card2}>
				<div className={styles.card__user_rights}>
					<div className={styles.block__list_header}>
						<p>Разделы</p>
						<UniversalButton
							// icon={<IconActionLock color={"primary_white"} size={"largeS"} />}
							color={!buttonToAdd[0].condition ? 'accent' : 'primary'}
							size={'medium'}
							label={'Создание'}
							onClick={() => handleChooseRoles('add')}
						/>
						<UniversalButton
							color={!buttonToAdd[3].condition ? 'accent' : 'primary'}
							size={'medium'}
							label={'Изменение'}
							onClick={() => handleChooseRoles('change')}
						/>
						<UniversalButton
							color={!buttonToAdd[1].condition ? 'accent' : 'primary'}
							size={'medium'}
							label={'Удаление'}
							onClick={() => handleChooseRoles('delete')}
						/>
						<UniversalButton
							color={!buttonToAdd[2].condition ? 'accent' : 'primary'}
							size={'medium'}
							label={'Просмотр'}
							onClick={() => handleChooseRoles('view')}
						/>
					</div>
					<ul className={styles.block__list_1}>
						{permissionList?.map((roolItem, index) => (
							<div className={styles.permission_group_item} key={index}>
								<p>{roolItem.name}</p>
								<div className={styles.permission_checkbox_list}>
									{roolItem.permissions.map((elt, index) => {
										return (
											<li
												onClick={() => {
													toggleSelectItem({ elt: elt, byGroupClick: false });
												}}
												// className={`${userChoosenNowPermissions.includes(elt.id) ? styles.selected : null} ${
												// 	userGroupPermissions.includes(elt.id) ? styles.dontCheck : null
												// } ${styles.childRool}`}
												className={styles.permissionItem_box}
												key={index}
											>
												{/* <pre>{JSON.stringify(elt, null, 2)}</pre>
                        <br/> */}
												{/* <pre>{JSON.stringify(choosenTerminalUserGroupPermissions[0],null,2)}</pre> */}
												{/* {item} */}
												<div className={styles.permissionItem_round}>
													{checkExististionOfAnyUserPermission(elt, 'group') ? (
														<div className={styles.groupPermissionItem_radio}>
															<div className={styles.checkMark} />
														</div>
													) : null}
													{checkExististionOfAnyUserPermission(elt, 'choosenBefore') ||
													checkExististionOfAnyUserPermission(elt, 'changeGroup') ? (
														<div className={styles.choosenBeforePermissionItem_radio}>
															<div className={styles.checkMark} />
														</div>
													) : null}
													{checkExististionOfAnyUserPermission(elt, 'choosenNow') ||
													checkExististionOfAnyUserPermission(elt, 'createGroup') ||
													checkExististionOfAnyUserPermission(elt, 'changeGroupChoosenNow') ? (
														<div className={styles.choosenNowPermissionItem_radio}>
															<div className={styles.checkMark} />
														</div>
													) : (
														''
													)}
												</div>
												{/* <div>{userGroupPermissions.includes(Number(elt.id)) ? ('Да'):('НЕТ')}</div> */}
											</li>
										);
									})}
								</div>
							</div>
						))}
					</ul>
				</div>
				<div>
					<div className={styles.card__btns}>
						{type !== 'accessTerminal' && type !== 'changePermissions' && (type === 'changeGroup' || type === 'createGroup') ? (
							<>
								{/* пермишен на удаление группы (должности) */}
								<RequireUI type={{ name: 'DelGroups' }}>
									<UniversalButton
										icon={<IconTrash color={'white'} size={'medium'} />}
										color={'error'}
										onClick={type === 'changeGroup' && handleDeleteGroup}
										size={'small'}
									/>
								</RequireUI>

								<div className={styles.card__btns_wrapper}>
									{type === 'changeGroup' && (
										<UniversalButton
											// icon={modalType === 'update' ? <IconRestart color={'white'} size={'medium'} /> : null}
											label={'Отменить изменения'}
											color={'primary'}
											size={'small'}
											disabled={createRoleInputChange ? false : true}
											onClick={handleResetGroupChanges}
										/>
									)}
									<UniversalButton
										// icon={modalType === 'update' ? <IconDisketa color={'white'} /> : null}
										label={'Сохранить изменения'}
										color={'succesful'}
										size={'small'}
										disabled={
											(type === 'changeGroup' && !createRoleInputChange) ||
											(type === 'createGroup' && !createRoleInputChange)
										}
										// disabled={type === 'changeGroup' && createRoleInputChange}
										onClick={
											type === 'createGroup'
												? () => handleCreateRole()
												: type === 'changeGroup'
													? () => handleChangeRole()
													: type === 'changePermissions'
														? () => handleChangePermissions()
														: null
										}
										type={'outline'}
									/>
								</div>
							</>
						) : (
							''
						)}
					</div>
				</div>
			</div>
		</ModalRightsLayout>
	);
}

export default ModalRights;
