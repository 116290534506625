export const sideTypeToName = (sideType) => {
	switch (sideType) {
		case 'F':
			return 'Передний торец';
		case 'L':
			return 'Левая сторона';
		case 'R':
			return 'Правая сторона';
		case 'D':
			return 'Торец с дверью';
		case 'T':
			return 'Крыша';
		case 'U':
			return 'Пол';
		case 'B':
			return 'Нижняя часть';

		default:
			break;
	}
};
