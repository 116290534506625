import React, { useEffect } from 'react';
import styles from '../Modals.module.scss';
import { Preloader } from '../../../../../../components';
import MainInput from '../../../../../../components/Inputs/MainInput/MainInput';
import { useDispatch, useSelector } from 'react-redux';
import {
	setNewEmail,
	setNewPassword,
	setNewTelegram,
	setNewPhone,
	setNewWhatsapp,
	setNewFullName,
	setInputChange,
} from '../../../../../../store/slices/userSlice';
import { IconCopy2 } from '../../../../../../components/Icons';
import { UniversalButton } from '../../../../../../components';
import copy from 'copy-to-clipboard';

const ModalClientEmplInfo = ({ handlePutUser, handleCreateUser, register, setFocus, setValue, errors, clearErrors, handleSubmit }) => {
	const { newFullName, newEmail, newPhone, newTelegram, newWhatsapp, newPassword, modalType, getUser_pending } = useSelector(
		(state) => state.user,
	);
	const dispatch = useDispatch();

	// Функции для копирования информации из инпутов

	const handlerCopyFullName = () => {
		copy(newFullName);
	};

	const handlerCopyEmail = () => {
		copy(newEmail);
	};

	const handlerCopyPassword = () => {
		copy(newPassword);
	};

	const handlerCopyPhone = () => {
		copy(newPhone);
	};

	const handlerCopyTelegram = () => {
		copy(newTelegram);
	};

	const handlerCopyWhatsapp = () => {
		copy(newWhatsapp);
	};

	return (
		<>
			{getUser_pending ? (
				<div className={styles.modal__preloader}>
					<Preloader />
				</div>
			) : (
				<div className={styles.card__userInfo}>
					<form
						onSubmit={
							modalType === 'update'
								? handleSubmit(handlePutUser)
								: modalType === 'create'
									? handleSubmit(handleCreateUser)
									: null
						}
					>
						<div className={styles.card__blockInput}>
							<MainInput
								title={'Имя Фамилия'}
								errorTitle={'Введите фамилию и имя'}
								setInputChange={(bool) => dispatch(setInputChange(bool))}
								register={register}
								errors={errors}
								setFocus={setFocus}
								name="FLName"
								value={newFullName ? newFullName : ''}
								handleFieldChange={(e) => dispatch(setNewFullName(e.target.value))}
								setValue={setValue}
								rules={{
									required: newFullName ? null : 'Обязательное поле',
									minLength: {
										value: 10,
										message: 'Минимум 10 символов',
									},
								}}
								placeholder={'Константинов Константин'}
							/>
							<UniversalButton
								icon={<IconCopy2 color={'white'} size={'medium'} />}
								typeButton={'button'}
								size={'small'}
								color={'primary'}
								onClick={handlerCopyFullName}
							/>
						</div>

						<div className={styles.card__blockInput}>
							<MainInput
								title={'Email'}
								errorTitle={'Введите email'}
								setInputChange={(bool) => dispatch(setInputChange(bool))}
								register={register}
								errors={errors}
								setFocus={setFocus}
								name="email"
								value={newEmail ? newEmail : ''}
								handleFieldChange={(e) => dispatch(setNewEmail(e.target.value))}
								setValue={setValue}
								rules={{
									required: newEmail ? null : 'Обязательное поле',
									minLength: {
										value: 5,
										message: 'Минимум 5 символов',
									},
								}}
								placeholder={'test@test.ru'}
							/>
							<UniversalButton
								icon={<IconCopy2 color={'white'} size={'medium'} />}
								typeButton={'button'}
								size={'small'}
								color={'primary'}
								onClick={handlerCopyEmail}
							/>
						</div>

						<div className={styles.card__blockInput}>
							<MainInput
								title={'Пароль'}
								errorTitle={'Введите пароль'}
								setInputChange={(bool) => dispatch(setInputChange(bool))}
								register={register}
								errors={errors}
								setFocus={setFocus}
								name="pass"
								value={newPassword ? newPassword : ''}
								handleFieldChange={(e) => dispatch(setNewPassword(e.target.value))}
								setValue={setValue}
								rules={{
									required: newPassword ? null : 'Обязательное поле',
									minLength: {
										value: 5,
										message: 'Минимум 5 символов',
									},
								}}
								placeholder={''}
							/>
							<UniversalButton
								icon={<IconCopy2 color={'white'} size={'medium'} />}
								typeButton={'button'}
								size={'small'}
								color={'primary'}
								onClick={handlerCopyPassword}
							/>
						</div>

						<div className={styles.card__blockInput}>
							<MainInput
								title={'Телефон'}
								errorTitle={'Введите телефон'}
								setInputChange={(bool) => dispatch(setInputChange(bool))}
								register={register}
								errors={errors}
								setFocus={setFocus}
								name="tel"
								value={newPhone ? newPhone : ''}
								handleFieldChange={(e) => dispatch(setNewPhone(e.target.value))}
								setValue={setValue}
								rules={
									{
										// minLength: {
										// 	value: 10,
										// 	message: 'Минимум 10 символов',
										// },
									}
								}
								placeholder={'+7 999 000 11 22'}
							/>
							<UniversalButton
								icon={<IconCopy2 color={'white'} size={'medium'} />}
								typeButton={'button'}
								size={'small'}
								color={'primary'}
								onClick={handlerCopyPhone}
							/>
						</div>

						<div className={styles.card__blockInput}>
							<MainInput
								title={'Телеграм'}
								errorTitle={'Введите телеграм'}
								setInputChange={(bool) => dispatch(setInputChange(bool))}
								register={register}
								errors={errors}
								setFocus={setFocus}
								name="tg"
								value={newTelegram ? newTelegram : ''}
								handleFieldChange={(e) => dispatch(setNewTelegram(e.target.value))}
								setValue={setValue}
								rules={
									{
										// required: newFullName ? null : 'Обязательное поле',
										// minLength: {
										// 	value: 10,
										// 	message: 'Минимум 10 символов',
										// },
									}
								}
								placeholder={'@primer'}
							/>
							<UniversalButton
								icon={<IconCopy2 color={'white'} size={'medium'} />}
								typeButton={'button'}
								size={'small'}
								color={'primary'}
								onClick={handlerCopyTelegram}
							/>
						</div>

						<div className={styles.card__blockInput}>
							<MainInput
								title={'Whatsapp'}
								errorTitle={'Введите whatsapp'}
								setInputChange={(bool) => dispatch(setInputChange(bool))}
								register={register}
								errors={errors}
								setFocus={setFocus}
								name="whatsapp"
								value={newWhatsapp ? newWhatsapp : ''}
								handleFieldChange={(e) => dispatch(setNewWhatsapp(e.target.value))}
								setValue={setValue}
								rules={
									{
										// required: newFullName ? null : 'Обязательное поле',
										// minLength: {
										// 	value: 10,
										// 	message: 'Минимум 10 символов',
										// },
									}
								}
								placeholder={'@whatsapp'}
							/>
							<UniversalButton
								icon={<IconCopy2 color={'white'} size={'medium'} />}
								typeButton={'button'}
								size={'small'}
								color={'primary'}
								onClick={handlerCopyWhatsapp}
							/>
						</div>
					</form>
				</div>
			)}
		</>
	);
};

export default ModalClientEmplInfo;
