import styles from "./Step.module.scss";
import { Form, UniversalButton } from "../../../../../components";
import { useState } from "react";
import MainInput from "../../../../../components/Inputs/MainInput/MainInput";

function Step4({ setPage }) {

  const onSubmit = (data) => {
    localStorage.setItem("urName", data.urName);
    setPage((currentPage) => currentPage + 1);
  };

  const [urName, setUrName] = useState(localStorage.getItem("urName") || "")

  return (
    <div className={styles.block}>
      <h5>Регистрация</h5>
      <Form onSubmit={onSubmit}>
        <MainInput
          title={'Наименование юр. лица'}
          errorTitle={'Некорректное наименование'}
          name="urName"
          value={urName}
          setValue={setUrName}
          rules={{
            required: "Обязательное поле",
            minLength: {
              value: 5,
              message: "Минимум 5 символов",
            },
          }}
          placeholder={'ПАО "Аэрофлот'}
        />
        <div className={styles.block__btns}>
          <UniversalButton label={"Продолжить"} color={"primary"} size={"large"} typeButton={"submit"} />
        </div>
      </Form>
    </div>
  );
}

export default Step4;