import { BtnIcon } from '../../Buttons';
import styles from './CardContainer.module.scss';
import { IconCross, IconDanger, IconWarningTool } from '../../Icons';
import { useDispatch } from 'react-redux';
import { addContainersInOrder, deleteOneContainerFromCurrentOrderContainerList } from '../../../store/slices/orderSlice';
import { useEffect } from 'react';

function CardContainer({ el, type, action }) {
	//? el - объект контейнера
	//? type - тип added/notAdded (добавлен в заявку или нет)
	//? action - действие на боковой кнопке

	return (
		<div className={styles.cardContainer}>
			{type === 'added' ? (
				<div
					className={`${styles.addIcont} 
         ${type === 'added' ? styles.delIcon : ''}
        `}
				>
					<BtnIcon
						type={'button'}
						bg={type === 'added' ? 'delete' : 'green'}
						icon={<IconCross color={'white'} size={'medium'} />}
						size={'p-6'}
						onClick={() => action(el)}
					/>
				</div>
			) : (
				<div
					className={`${styles.addIcont} 
       ${type === 'added' ? styles.delIcon : ''}
      `}
				>
					<BtnIcon
						type={'button'}
						bg={type === 'added' ? 'delete' : 'green'}
						icon={<IconCross color={'white'} size={'medium'} />}
						size={'p-6'}
						onClick={() => action(el)}
					/>
				</div>
			)}
			<div className={styles.cardContainer__row}>
				<p className={styles.cardContainer__name}>№ {el?.container_number}</p>
				<p className={styles.cardContainer__status}>{el?.status_display ? el?.status_display : null}</p>
			</div>
			<div className={styles.cardContainer__row}>
				<ul className={styles.cardContainer__info}>
					<li>
						<p>{el?.type_display ? el?.type_display : el?.type}</p>
					</li>
					<li>
						<p>{el?.size_display ? el?.size_display : el?.size}</p>
					</li>
					<li>
						<p>{el?.line_display ? el?.line_display : el?.line}</p>
					</li>
					<li>
						<p>{el?.booking}</p>
					</li>
				</ul>
				<div className={styles.cardContainer__details}>
					{el?.is_dangerous ? (
						<div className={styles.details__danger}>
							<IconDanger size={'small'} color={'white'} />
						</div>
					) : (
						<></>
					)}
					{el?.is_damaged ? (
						<div className={styles.details__damage}>
							<IconWarningTool size={'small'} color={'white'} />
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
}

export default CardContainer;
