import React from 'react';
import MainInput from '../Inputs/MainInput/MainInput';
// import UniversalButton from '../Buttons/UniversalButton/UniversalButton';
// import IconCalendar from '../Icons/IconCalendar';
import styles from './StartEndDateComponent.module.scss';
import { useForm } from 'react-hook-form';

function StartEndDateComponent({ onSubmit, title, columnType, onChangeStart, onChangeEnd, start, end }) {
	const {
		register,
		handleSubmit,
		setValue,
		setFocus,
		formState: { errors },
	} = useForm();

	return (
		<form onSubmit={onSubmit ? handleSubmit(onSubmit) : null}>
			{!columnType && <p className={styles.dateCreateOrderTitle}>{title}</p>}
			<div className={`${styles.dateCreateOrder} ${columnType ? styles.dateCreateOrderColumn : ''}`}>
				{columnType ? (
					<div className={styles.columnTipeInputPicker}>
						<MainInput
							name="startDateCreateOrder"
							register={register}
							setValue={setValue}
							setFocus={setFocus}
							title={'Примерная дата завершения'}
							errors={errors}
							value={start ? start : ''}
							handleFieldChange={onChangeStart}
							mask={'99.99.9999'}
							rules={{}}
							placeholder={'с 01.01.2024'}
						/>
						{/* <UniversalButton
                        color={"primary"}
                        size={"small"}
                        icon={<IconCalendar color={'white'} />}
                    /> */}
					</div>
				) : (
					<MainInput
						name="startDateCreateOrder"
						register={register}
						setValue={setValue}
						setFocus={setFocus}
						errors={errors}
						value={start ? start : ''}
						handleFieldChange={onChangeStart}
						mask={'99.99.9999'}
						rules={{}}
						placeholder={'с 01.01.2024'}
					/>
				)}
				<MainInput
					name="endDateCreateOrder"
					register={register}
					setValue={setValue}
					setFocus={setFocus}
					errors={errors}
					// value={columnType && '01.01.2024'}
					value={end ? end : ''}
					handleFieldChange={onChangeEnd}
					title={columnType && 'Дата создания заявки'}
					mask={'99.99.9999'}
					rules={{}}
					placeholder={'по 04.01.2024'}
				/>
				{/* <UniversalButton
                    color={"primary"}
                    size={"small"}
                    icon={<IconCalendar color={'white'} />}
                /> */}
			</div>
		</form>
	);
}

export default StartEndDateComponent;
