import React from 'react';
import styles from './ContainerMap.module.scss';
import { FrontEnd, RoofSide } from './Sides';
import FrontEndWithDoor from './Sides/FrontEndWithDoor';
import { useSelector } from 'react-redux';
import LeftSide from './Sides/LeftSide';
import RightSide from './Sides/RightSide';
import BottomSide from './Sides/BottomSide_U';
import FloorSide from './Sides/FloorSide_B';

function ContainerMap({ setSmthSelected }) {
	const { selectedSide, entryOneReport } = useSelector((state) => state.containerAccept);

	return (
		<>
			<p className={styles.mainTitle}>{selectedSide?.title}</p>
			<div className={styles.mainMapBlock}>
				<div className={styles.overflowBlock}>
					{selectedSide?.code === 'L' ? (
						<LeftSide setSmthSelected={setSmthSelected} feet={entryOneReport.container.size_display === '20' ? 20 : 40} />
					) : selectedSide?.code === 'R' ? (
						<RightSide setSmthSelected={setSmthSelected} feet={entryOneReport.container.size_display === '20' ? 20 : 40} />
					) : selectedSide?.code === 'T' ? (
						<RoofSide setSmthSelected={setSmthSelected} feet={entryOneReport.container.size_display === '20' ? 20 : 40} />
					) : selectedSide?.code === 'B' ? (
						<FloorSide setSmthSelected={setSmthSelected} feet={entryOneReport.container.size_display === '20' ? 20 : 40} />
					) : selectedSide?.code === 'U' ? (
						<BottomSide setSmthSelected={setSmthSelected} feet={entryOneReport.container.size_display === '20' ? 20 : 40} />
					) : selectedSide?.code === 'F' ? (
						<FrontEnd setSmthSelected={setSmthSelected} />
					) : selectedSide?.code === 'D' ? (
						<FrontEndWithDoor setSmthSelected={setSmthSelected} />
					) : (
						<></>
					)}
					<div className={styles.bottomP}>
						<p className={styles.mainTitle}>
							{selectedSide?.title === 'Левая сторона L'
								? 'Передняя сторона'
								: selectedSide?.title === 'Правая сторона R' ||
									  selectedSide?.title === 'Крыша T' ||
									  selectedSide?.title === 'Пол B' ||
									  selectedSide?.title === 'Низ U'
									? 'Двери'
									: ''}
						</p>
						<p className={styles.mainTitle}>
							{selectedSide?.title === 'Левая сторона L'
								? 'Двери'
								: selectedSide?.title === 'Правая сторона R' ||
									  selectedSide?.title === 'Крыша T' ||
									  selectedSide?.title === 'Пол B' ||
									  selectedSide?.title === 'Низ U'
									? 'Передняя сторона'
									: ''}
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default ContainerMap;
