import styles from "./Step.module.scss";
import { useNavigate } from "react-router-dom"
import {
  EmailShareButton,
  OKShareButton,
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  EmailIcon,
  OKIcon,
  TelegramIcon,
  VKIcon,
  WhatsappIcon,
} from "react-share";
import copy from 'copy-to-clipboard';
import { UniversalButton } from "../../../../../components";

function Step6({ setPage }) {
  const navigate = useNavigate();


  let data = `Номер договора: ${localStorage.getItem(
    "numberContract"
  )}, наименование: ${localStorage.getItem(
    "urName"
  )}, почта: ${localStorage.getItem("email")}, пароль: ${localStorage.getItem(
    "password"
  )}`;

  const copyData = () => {
    copy(data);
  };

  const handlerLinkPА = () => {
    localStorage.clear()
    navigate("/accountEmpl/main")
  }

  return (
    <div className={styles.block}>
      <h5>Новый клиент</h5>
      <div>
        <div className={styles.block__info}>
          <p>Номер договора: {localStorage.getItem("numberContract")}</p>
          <p>Наименование: {localStorage.getItem("urName")}</p>
          <p>Почта: {localStorage.getItem("email")}</p>
          <p>Пароль: {localStorage.getItem("password")}</p>
        </div>
        <div className={styles.block__btns}>
          <UniversalButton label={"Личный кабинет"} color={"primary"} size={"large"} typeButton={"button"} onClick={handlerLinkPА} />
          <UniversalButton label={"Скопировать"} color={"primary"} size={"large"} typeButton={"button"} onClick={copyData} />
        </div>
      </div>
      <div className={styles.block__links}>
        <EmailShareButton title={data} url={'http://example.com'}>
          <EmailIcon size={44} round={true} />
        </EmailShareButton>
        <OKShareButton title={data} url={'http://example.com'}>
          <OKIcon size={44} round={true} />
        </OKShareButton>
        <TelegramShareButton title={data} url={'http://example.com'}>
          <TelegramIcon size={44} round={true} />
        </TelegramShareButton>
        <VKShareButton title={data} url={'http://example.com'}>
          <VKIcon size={44} round={true} />
        </VKShareButton>
        <WhatsappShareButton title={data} url={'http://example.com'}>
          <WhatsappIcon size={44} round={true} />
        </WhatsappShareButton>
      </div>
    </div>
  );
}

export default Step6;
