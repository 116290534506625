export const panelNameToPanelAvailableCodes = (components, sideCode, placement, part) => {
	const comparing = sideCode + placement + part;
	let someComponents;
	switch (comparing) {
		// F Боковая стойка
		case 'FT4':
		case 'FB4':
		case 'FT1':
		case 'FB1':
			someComponents = components.filter((comp) => comp.code === 'SBO');
			return { availableCodes: ['SBO'], availableComponents: someComponents };
		// F Угловой фитинг
		case 'FH4':
		case 'FG4':
		case 'FH1':
		case 'FG1':
			someComponents = components.filter((comp) => comp.code === 'CFG' || comp.code === 'SBO');
			return { availableCodes: ['CFG'], availableComponents: someComponents };
		// F Панель торцевая
		case 'FT3':
		case 'FB3':
		case 'FT2':
		case 'FB2':
			someComponents = components.filter((comp) => comp.code === 'PAA');
			return { availableCodes: ['PAA'], availableComponents: someComponents };
		// F Балка крыши
		case 'FH3':
		case 'FH2':
			someComponents = components.filter((comp) => comp.code === 'RBO');
			return { availableCodes: ['RBO'], availableComponents: someComponents };
		// F Опорный элемент
		case 'FG3':
		case 'FG2':
			someComponents = components.filter((comp) => comp.code === 'CMA');
			return { availableCodes: ['CMA'], availableComponents: someComponents };
		default:
			break;
	}
};
