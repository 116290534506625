import React from 'react';
import styles from './InfoBlocks.module.scss'

function InfoBlock({children, smallLong}) {
    return (
        <div className={`${styles.mainInfoBlock} ${smallLong ? styles.smallLong : ''}`}>
            {children}
        </div>
    );
}

export default InfoBlock;