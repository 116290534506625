import { combineReducers } from 'redux';
import registrationReducer from './registrationUser';
import publicFormReducer from './publicForm';
import groupsUsersReducer from './groupsUsers'
import oneUserReducer from './erp';
import permissionsListReducer from './permissions';
import containersReducer from './clientContainer';
import linesContainerReducer from './linesContainer';
  
//Slices RTK
import containersReducerErp from './erpContainer'
import vehicleReducer from '../slices/vehicleSlice'; 



const rootReducer = combineReducers({
  registration: registrationReducer,
  publicForm: publicFormReducer,
  groupsUsers: groupsUsersReducer,
  userOne: oneUserReducer,
  permList: permissionsListReducer,
  containers: containersReducer,
  linesContainer: linesContainerReducer,
  //RTK Slices 
  vehicle: vehicleReducer,
  containersReducerErp: containersReducerErp,
 
});

export default rootReducer;

// export default configureStore({
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware({
//     serializableCheck: false,
//   }),
//   reducer: {
//     profile: profileReducer,
//     loading: loadingReducer,
//     content: contentReducer,
//     // auth: authReducer,
//     menu: menuReducer,
//     widget: widgetReducer,
//     filter: filterReducer,
//     user: userReducer,
//     statistic: statisticReducer,
//     // parser: parserReducer,
//     search: searchReducer,
//     organizers: organizersReducer,
//     modal: modalReducer,
//   },
// });