import React from 'react';
import { UniversalButton } from '../../../../../../components';

function StatusBtn({ status, buttonText, bgColor, handlePostClient, disabled, icon }) {
	return (
		<UniversalButton
			icon={icon}
			color={bgColor}
			size={'medium'}
			onClick={() => handlePostClient({ status })}
			typeButton={'button'}
			label={buttonText}
			disabled={disabled}
		/>
	);
}

export default StatusBtn;
