import { useEffect, useState } from 'react';
import styles from './CreateOrderSteps.module.scss';
import AddContainers from '../AddContainers/AddContainers';
import Containers from '../Containers/Containers';
import ConfirmationWindow from '../../ConfirmationWindow/ConfirmationWindow';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearTemporaryDataForOrderCreate,
	deleteContainer,
	deleteOneContainerFromCurrentOrderContainerList,
	setChoosenContainerForChangeCancelVariant,
} from '../../../store/slices/orderSlice';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../store/slices/sideBarSlice';
import ChangeContainerForm from '../ChangeContainerForm/ChangeContainerForm';

function CreateOrderImportStep3({ setPage, mob, data, setData }) {
	const dispatch = useDispatch();
	const { containersToAddInOrder, newOrder_terminal_id } = useSelector((state) => state.order);
	const [viewMobContainers, setViewMobContainers] = useState(false);
	const [confWindow, setConfWindow] = useState(false);
	const [containerEL, setContainerEL] = useState();

	const [removeAllCard, setRemoveAllCard] = useState(false);

	const handleRemoveCard = () => {
		const updatedData = data.filter((item) => item !== containerEL);
		setData(updatedData);
		setConfWindow(false);
	};
	//! Удаление контейнера при клике на красную кнопку
	const handleRemoveFromOrder = (container) => {
		console.log(container, 'КонтеЙнер');
		dispatch(deleteContainer({ terminal_id: newOrder_terminal_id, container_pk: container.id }));
		dispatch(deleteOneContainerFromCurrentOrderContainerList(container));
	};

	const handleRemoveAllCard = () => {
		setData([]);
		setRemoveAllCard(false);
	};

	//* Очистка данных при уходе со страницы
	useEffect(() => {
		return () => {
			dispatch(clearTemporaryDataForOrderCreate());
		};
	}, []);

	const handleChangeContainerInOrder = (e, container) => {
		console.log('IMPORT BEFORE ');
		dispatch(setSideBarActive(true));
		dispatch(setCurrentElementForSideBar(<ChangeContainerForm type={'import_before_order_containers'} />));
		dispatch(setChoosenContainerForChangeCancelVariant(container));
	};

	return (
		<div className={mob ? styles.mob_account__step : styles.account__step}>
			{(confWindow || removeAllCard) && (
				<ConfirmationWindow
					titleText={`Вы уверены, что хотите удалить ${removeAllCard ? 'все контейнеры?' : 'контейнер №' + containerEL.container_number}`}
					acceptBtnText={'Да, удалить'}
					acceptBtnClick={removeAllCard ? handleRemoveAllCard : handleRemoveCard}
					rejectBtnText={'Отменить'}
					rejectBtnClick={() => (removeAllCard ? setRemoveAllCard(false) : setConfWindow(false))}
				/>
			)}
			<AddContainers
				type={'import_before'}
				setData={setData}
				data={data}
				setPage={setPage}
				setViewMobContainers={setViewMobContainers}
				mob={mob}
			/>
			{!mob && (
				<Containers
					type={'added'}
					setData1={setData}
					handleChangeContainer={handleChangeContainerInOrder}
					handleRemoveFromOrder={handleRemoveFromOrder}
					setRemoveAllCard={setRemoveAllCard}
					setContainerEL={setContainerEL}
					setConfWindow={setConfWindow}
					setData={setData}
					data={containersToAddInOrder}
					mob={mob}
					setPage={setPage}
				/>
			)}
			{viewMobContainers && (
				<Containers
					type={'added'}
					setData1={setData}
					handleChangeContainer={handleChangeContainerInOrder}
					setRemoveAllCard={setRemoveAllCard}
					setContainerEL={setContainerEL}
					setConfWindow={setConfWindow}
					// data={data}
					data={containersToAddInOrder}
					mob={mob}
					setViewMobContainers={setViewMobContainers}
					setPage={setPage}
				/>
			)}
		</div>
	);
}

export default CreateOrderImportStep3;
