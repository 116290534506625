import styles from './CounterStep.module.scss'

function CounterStep({step, allStep}) {
  return ( 
      <div className={styles.counter}>
        Шаг {step}/{allStep}
      </div>
  );
}

export default CounterStep;
