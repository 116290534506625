import { useSelector } from 'react-redux';
import { Modal, Select, SelectLaptop, UniversalButton } from '../../../../../components';
import { BtnText } from '../../../../../components/Buttons';
import styles from './Step.module.scss';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from '../../../../../components';
import { groupsUsersSelector } from '../../../../../store/selectors/groupsUsersSelector';
import { useMediaQuery } from 'react-responsive';
import MainInput from '../../../../../components/Inputs/MainInput/MainInput';

function Step1({ setPage }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [titleError, setTitleError] = useState(false);

  const typesUsersAll = useSelector(groupsUsersSelector);

  const [value, setValue] = useState(''); // Перестал работать селект (пофиксил так) ???

  const handleNextStep = () => {
    if (selectedOption) {
      localStorage.setItem('typeUser', selectedOption);
      localStorage.setItem('typeUserId', selectedId);
      setPage((currentPage) => currentPage + 1);
    } else {
      setTitleError(true);
    }
  };


  const handleOpenModal = () => {
    setShowModal(!showModal);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const isDesktop = useMediaQuery({ minWidth: 1000 });
  const [typesUser, setTypesUser] = useState(typesUsersAll);

  useEffect(() => {
    setTypesUser(typesUsersAll.filter((n) => n.name !== 'Владелец'));
  }, [typesUsersAll]);

  useEffect(() => {
    if (localStorage.getItem('typeUser')) {
      setSelectedOption(localStorage.getItem('typeUser'));
      setSelectedId(localStorage.getItem('typeUserId'));
    }
  }, []);

  const { register, handleSubmit } = useForm();

  return (
    <div className={styles.block}>
      <h5>Новый пользователь</h5>
      <div>
        <div className={styles.relative}>
          <Form onSubmit={handleSubmit()}>
            <MainInput
              title={'Тип пользователя'}
              name="selectInput"
              type={'selectInput'}
              register={register}
              setValue={setSelectedOption}
              value={selectedOption}
              rules={{
                required: "Обязательное поле",
              }}
              placeholder={'Выберите из списка'}
              modalDropList={showModal}
              onClick={handleOpenModal}
            />

            {
              isDesktop ? (
                showModal ? (
                  <SelectLaptop
                    options={typesUser}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    setSelectedId={setSelectedId}
                    setShowModal={setShowModal}
                  />
                ) : (
                  <></>
                )
              ) : (
                showModal ? (
                  <Modal
                    title={'Тип пользователя'}
                    arrow={true}
                    children={
                      <Select
                        options={typesUser}
                        onCloseModal={handleCloseModal}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        setSelectedId={setSelectedId}
                        setValue={setValue}
                      />
                    }
                    onClose={handleCloseModal}
                    onBack={handleCloseModal}
                  />
                ) : (
                  <></>
                )
              )
            }
            <div className={styles.block__btns}>
              <UniversalButton label={"Продолжить"} color={"primary"} onClick={handleNextStep} size={"large"} typeButton={"submit"} />
            </div>
          </Form>
        </div>
      </div>
    </div >
  );
}

export default Step1;
