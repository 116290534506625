import { getGroup } from '../../api/user/getGroup';

export const getGroupsUsers = () => {
    return async (dispatch) => {
        try {
            const response = await getGroup();
            dispatch(getGroupSuccess(response.data));
        } catch (error) {
            throw error;
        }
    };
};

export const getGroupSuccess = (payload) => ({
    type: 'GET_GROUP_SUCCESS',
    payload,
}); 