import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconSideBottom({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6 20H12H18M7.71429 4H16.2857C17.2325 4 18 4.84276 18 5.88235V15.2941C18 16.3337 17.2325 17.1765 16.2857 17.1765H7.71429C6.76751 17.1765 6 16.3337 6 15.2941V5.88235C6 4.84276 6.76751 4 7.71429 4Z"
					stroke="#8E8E93"
					strokeWidth="1.41176"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}

export default IconSideBottom;
