import React from 'react';
import styles from './InfoBlocks.module.scss';
import UniversalButton from '../../../Buttons/UniversalButton/UniversalButton';
import { IconTrash } from '../../../Icons';
import RequireUI from '../../../../hoc/RequireUI';

function LongInfoBlock({ orderState }) {
	return (
		<div className={`${styles.longInfo_mainBlock} ${!orderState ? styles.longInfo_mainBlock_progress : ''}`}>
			<div className={styles.infoP_btns}>
				<div className={styles.infoP}>
					{/* Отображение текста в зависимости от состояния заявки */}
					{orderState ? <p>Статус заявки: черновик</p> : <p>Прогресс завершения</p>}
					{/* Отображение дополнительного текста в зависимости от состояния заявки */}
					{orderState ? (
						<p>Черновик создан. Вы можете дополнить информацию и опубликовать заявку</p>
					) : (
						<div className={styles.progressP}>
							<p>Всего контейнеров: 100</p>
							<p>Принято контейнеров: 75</p>
							<p>Ожидаются контейнеры: 25</p>
						</div>
					)}
				</div>
				{/* Кнопка для публикации заявки, отображается только если заявка в статусе "черновик" */}
				{orderState && (
					<RequireUI type={{ name: 'ChangeApplications' }}>
						<UniversalButton label={'Опубликовать заявку'} type={'button'} size={'small'} color={'succesful'} />
					</RequireUI>
				)}
			</div>
			{/* Иконки состояния заявки */}
			{orderState ? (
				<div className={styles.states__container}>
					<div className={styles.states}>
						<div className={styles.green} />
						<div className={styles.orange} />
						<div className={styles.primary} />
						<div className={styles.primary} />
					</div>
					<RequireUI type={{ name: 'DelApplications' }}>
						<UniversalButton
							icon={<IconTrash size={'medium'} color={'core-state-error'} />}
							color={'error'}
							type={'with-border'}
							size={'small'}
						/>
					</RequireUI>
				</div>
			) : (
				<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
					<path
						d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32ZM6.4 32C6.4 46.1385 17.8615 57.6 32 57.6C46.1385 57.6 57.6 46.1385 57.6 32C57.6 17.8615 46.1385 6.4 32 6.4C17.8615 6.4 6.4 17.8615 6.4 32Z"
						fill="#F2F2F7"
					/>
				</svg>
			)}
		</div>
	);
}

export default LongInfoBlock;
