import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { Button } from '@mui/material';
import { useLazyGetCargosListQuery } from '../../../../store/newApi/cargos/cargos';
import Filter from '../../../../newComponents/Filter/Filter';
import { formatInitialDate } from '../../../../helpers/formatDate';
import { useDispatch } from 'react-redux';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/cargos.js';

function AccountEmplTableCargos() {
	const dispatch = useDispatch();
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});

	// колонки
	const columnsList = useMemo(
		() => [
			{
				accessorFn: (row) => row.client_display,
				id: 'client_display',
				header: 'Клиент',
				size: 300,
			},
			{
				accessorFn: (row) => row.terminal_display,
				id: 'terminal_display',
				header: 'Терминал',
				size: 200,
			},
			{
				accessorFn: (row) => row.package,
				id: 'cell',
				header: 'Ячейка',
				size: 200,
			},
			{
				accessorFn: (row) => row.gng_code,
				id: 'gng_code',
				header: 'Код ГНГ',
				size: 150,
			},
			{
				accessorFn: (row) => row.cargo,
				id: 'cargo',
				header: 'Груз',
				size: 300,
			},
			{
				accessorFn: (row) => row.package,
				id: 'package',
				header: 'Тара',
				size: 150,
			},
			{
				accessorFn: (row) => row.package_count,
				id: 'package_count',
				header: 'Кол-во тары',
				size: 200,
			},
			{
				accessorFn: (row) => (row.is_pallet ? 'Да' : '-'),
				id: 'is_pallet',
				header: 'Палеты',
				size: 150,
			},
			{
				accessorFn: (row) => row.pallet_count,
				id: 'pallet_count',
				header: 'Кол-во палетов',
				size: 200,
			},
			{
				accessorFn: (row) => row.gross,
				id: 'gross',
				header: 'Вес брутто',
				size: 180,
			},
			{
				accessorFn: (row) => (row.checkin_at ? formatInitialDate(row.checkin_at) : '-'),
				id: 'checkin_at',
				header: 'Дата поступления',
				size: 220,
			},
			{
				accessorFn: (row) => (row.eta_checkout ? formatInitialDate(row.eta_checkout).split(' ')[0] : '-'),
				id: 'eta_checkout',
				header: 'Планируемая дата вывоза',
				size: 290,
			},
		],
		[],
	);

	// порядок колонок
	const columnOrdering = [
		'mrt-row-select',
		'client_display',
		'terminal_display',
		'cell',
		'gng_code',
		'cargo',
		'package',
		'package_count',
		'is_pallet',
		'pallet_count',
		'gross',
		'checkin_at',
		'eta_checkout',
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Грузы'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium">
						Добавить груз
					</Button>,
				]}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<Filter onSetFilter={handleSetFilter} onResetFilter={handleResetFilter} />
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						hookName={useLazyGetCargosListQuery}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="cargosRtk"
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableCargos;
