import { format, parseISO } from 'date-fns';

// Форматирование начальной даты для показа в таблицах
export const formatInitialDate = (dateString) => {
	// Парсинг строки даты в объект Date
	const date = parseISO(dateString);

	// Форматируем дату
	const formattedDate = format(date, 'dd.MM.yyyy HH:mm');

	// Собираем финальную строку
	const result = `${formattedDate}`;

	return result;
};

// Для формата с 2020-10-22 на 22.10.2022
export const formatDate = (dateString) => {
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	return `${day}.${month}.${year}`;
};
