import { header, headerBlock } from './Header.styles.js';
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

// text - Заголовок
// btnDesktop и btnMobile - Массивы кнопок декстоп и мобильной версий
// stepAll - Количество шагов
// stepNumber - Номер данного шага
// stepText - Текст данного шага

function Header({ text, btnDesktop, btnMobile, stepAll, stepNumber, stepText }) {
	const isTablet = useMediaQuery((theme) => theme.breakpoints.up('tablet'));
	const buttons = isTablet ? btnDesktop : btnMobile;

	return (
		<Box sx={header.container}>
			<Box sx={headerBlock(isTablet)}>
				<Typography variant="p" sx={header.title}>
					{text}
				</Typography>

				{stepAll && (
					<Typography variant="p" sx={header.steps}>
						Шаг {stepNumber} из {stepAll}. {stepText}
					</Typography>
				)}
			</Box>
			<Box sx={header.buttonsContainer}>
				{buttons?.map((item, ind) => (
					<Box key={ind} component="div">
						{item}
					</Box>
				))}
			</Box>
		</Box>
	);
}

export default Header;
