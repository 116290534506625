import { getOneContainer } from "../../api/client/getOneContainer";
import { putOneContainer } from "../../api/client/putOneContainer";
import { putContainers } from "../../api/client/putContainers";

export const getOneContainerAction = (containerId) => {
    return async (dispatch) => {
        try {
            const response = await getOneContainer(containerId);
            dispatch(getOneContainerSuccess(response.data));

        } catch (error) {
            throw error;
        }
    }
}

export const putOneContainerAction = (containerId, requestData) => {
    return async (dispatch) => {
        try {
            const response = await putOneContainer(containerId, requestData);
            dispatch(putOneContainerSuccess(response.data));
        } catch (error) {
            throw error;
        }
    };
};

export const putContainersAction = (requestData) => {
    return async (dispatch) => {
        try {
            const response = await putContainers(requestData);
            dispatch(putContainersSuccess(response.data));
        } catch (error) {
            throw error;
        }
    };
};

export const getOneContainerSuccess = (payload) => ({
    type: 'GET_ONECONTAINER_SUCCESS',
    payload,
})

export const putOneContainerSuccess = (payload) => ({
    type: 'PUT_ONECONTAINER_SUCCESS',
    payload,
});

export const putContainersSuccess = (payload) => ({
    type: 'PUT_ONECONTAINER_SUCCESS',
    payload,
}); 