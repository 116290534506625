import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconSideLeft({ size, color }) {
	return (
		<div className={styles.icon}>
			<svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M4 20L4 4M8 18.2222C8 19.2041 8.89543 20 10 20L18 20C19.1046 20 20 19.2041 20 18.2222L20 5.77778C20 4.79594 19.1046 4 18 4L10 4C8.89543 4 8 4.79594 8 5.77778L8 12V18.2222Z"
					stroke="#8E8E93"
					strokeWidth="1.33333"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}
export default IconSideLeft;
