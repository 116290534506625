import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material';
import CardComponent from '../../../../newComponents/CardComponent/CardComponent';
import { account, accountGridItemThird } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getInitials } from '../../../../helpers/formatText';
import { useLazyGetGroupsListQuery } from '../../../../store/newApi/groups/groups.js';
import Preloader from '../../../../components/Preloader/Preloader';
import Filter from '../../../../newComponents/Filter/Filter.jsx';
import { useDispatch } from 'react-redux';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../store/slices/sideBarSlice.js';
import { getPermissionList } from '../../../../store/slices/permissionsSlice';
import ModalRights from '../components/Modals/ModalRights.jsx';

function AccountEmplJobTitle() {
	const [trigger, { data, isLoading }] = useLazyGetGroupsListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ page: 1, page_size: 20 });
	}, []);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data) {
			setDataCards((prevData) => [...prevData, ...data]);
			setFetching(false);
		}
	}, [data]);

	const dispatch = useDispatch();

	const handleNewTitle = (el) => {
		dispatch(getPermissionList());
		dispatch(setSideBarActive(true));
		dispatch(setCurrentElementForSideBar(<ModalRights type="createGroup" />));
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Должности'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium" onClick={handleNewTitle}>
						Добавить должность
					</Button>,
				]}
			/>
			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					<div className={styles.account__filter}>
						<Filter />
					</div>
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemThird(isDesktop)} key={index}>
									<CardComponent avatar={getInitials(card?.name)} title={card?.name} />
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplJobTitle;
