import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconArrowLeft({ size, color }) {
  return (
    <div className={styles.icon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={IconSize(size)}
        height={IconSize(size)}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M15.8334 10.75C16.2476 10.75 16.5834 10.4142 16.5834 9.99996C16.5834 9.58575 16.2476 9.24996 15.8334 9.24996V10.75ZM4.16675 9.99996L3.63642 9.46963C3.34352 9.76252 3.34352 10.2374 3.63642 10.5303L4.16675 9.99996ZM9.46975 16.3636C9.76264 16.6565 10.2375 16.6565 10.5304 16.3636C10.8233 16.0707 10.8233 15.5959 10.5304 15.303L9.46975 16.3636ZM10.5304 4.69696C10.8233 4.40406 10.8233 3.92919 10.5304 3.6363C10.2375 3.3434 9.76264 3.3434 9.46975 3.6363L10.5304 4.69696ZM15.8334 9.24996H4.16675V10.75H15.8334V9.24996ZM10.5304 15.303L4.69708 9.46963L3.63642 10.5303L9.46975 16.3636L10.5304 15.303ZM4.69708 10.5303L10.5304 4.69696L9.46975 3.6363L3.63642 9.46963L4.69708 10.5303Z"
          fill={IconColor(color)}
        />
      </svg>
    </div>
  );
}

export default IconArrowLeft;
