import React from "react";
import styles from "../Modals.module.scss";
import { UniversalButton } from "../../../../../../components";

function ChangeBtn({
  inputChange,
  handlePutBaseUser,
  setInputChange,
  handlePutUser,
  errors,
}) {

  return (
    <div
      className={
        !inputChange ? styles.card_change_btns : styles.card_changeTrue_btns
      }
    >
      <UniversalButton
        color={"primary"}
        size={"small"}
        onClick={() => {
          handlePutBaseUser();
          setInputChange(false);
        }}
        typeButton={"button"}
        label={"Отменить"}
      />
      <UniversalButton
        color={"accent"}
        size={"small"}
        onClick={() => {
          if (Object.keys(errors).length === 0) {
            setInputChange(false);
            handlePutUser();
          }
        }}
        typeButton={"button"}
        label={"Сохранить"}
      />
    </div>
  );
}

export default ChangeBtn;
