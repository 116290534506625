import { UniversalButton } from "../../../../../components";
import styles from "./Step.module.scss";

function Step1({ setPage }) {
  const handleNextStep = () => {
    setPage((currentPage) => currentPage + 1);
    localStorage.clear();
  };

  const handleContactManager = () => {
    localStorage.clear();
    setPage(7);
  };

  return (
    <div className={styles.block}>
      <h5>Регистрация</h5>
      <div className={styles.block__choice}>
        <UniversalButton label={"С помощью менеджера"} color={"primary"} onClick={handleContactManager} size={"large"} typeButton={"button"} />
        <UniversalButton label={"Самостоятельно"} color={"primary"} onClick={handleNextStep} size={"large"} typeButton={"button"} />
      </div>
    </div>
  );
}

export default Step1;
