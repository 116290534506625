import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconPrint({ size, onClick, color, className }) {
	return (
		<div width={IconSize(size)} height={IconSize(size)} onClick={onClick} className={`${styles.icon} ${className}`}>
			<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="24 / Action / Print">
					<path
						id="Union"
						d="M14.2493 6.33333H14.7493V5.83333V4.16667C14.7493 3.66041 14.3389 3.25 13.8327 3.25H7.16602C6.65975 3.25 6.24935 3.66041 6.24935 4.16667V5.83333V6.33333H6.74935H14.2493ZM14.9993 5.83333V6.33333H15.4993H16.3327C17.4373 6.33333 18.3327 7.22876 18.3327 8.33333V13.3333C18.3327 14.4379 17.4373 15.3333 16.3327 15.3333H15.4993H14.9993V15.8333C14.9993 16.4777 14.477 17 13.8327 17H7.16602C6.52168 17 5.99935 16.4777 5.99935 15.8333V15.3333H5.49935H4.66602C3.56145 15.3333 2.66602 14.4379 2.66602 13.3333V8.33333C2.66602 7.22876 3.56145 6.33333 4.66602 6.33333H5.49935H5.99935V5.83333V4.16667C5.99935 3.52233 6.52168 3 7.16602 3H13.8327C14.477 3 14.9993 3.52233 14.9993 4.16667V5.83333ZM5.49935 15.0833H5.99935V14.5833V10.8333C5.99935 10.6492 6.14859 10.5 6.33268 10.5H14.666C14.8501 10.5 14.9993 10.6492 14.9993 10.8333V14.5833V15.0833H15.4993H16.3327C17.2992 15.0833 18.0827 14.2998 18.0827 13.3333V8.33333C18.0827 7.36683 17.2992 6.58333 16.3327 6.58333H4.66602C3.69952 6.58333 2.91602 7.36683 2.91602 8.33333V13.3333C2.91602 14.2998 3.69952 15.0833 4.66602 15.0833H5.49935ZM14.7493 11.25V10.75H14.2493H6.74935H6.24935V11.25V15.8333C6.24935 16.3396 6.65975 16.75 7.16602 16.75H13.8327C14.3389 16.75 14.7493 16.3396 14.7493 15.8333V11.25Z"
						fill={IconColor(color)}
						stroke="#FFFBFF"
					/>
				</g>
			</svg>
		</div>
	);
}

export default IconPrint;
