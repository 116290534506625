import styles from './Footer.module.scss';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import logoSteizaWhite from '../../../../assets/images/logo-steizaWhite.svg';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Notification, SeparateModal, UniversalButton } from '../../../../components';
import { IconCross } from '../../../../components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { sendError, setInitialSendError } from '../../../../store/slices/publicFormSlice';
// import { sendError } from "../../../../store/actions/publicForm"

function Footer() {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [text, setText] = useState('');
	const [descr, setDescr] = useState('');
	const [status, setStatus] = useState('');
	const { sendError_success, sendError_error } = useSelector((state) => state.publicForm);

	const handleOpenModal = () => {
		setShowModal(true);
	};
	const handleCloseModal = () => {
		setShowModal(false);
	};

	const initialFormState = {
		contact: '',
		error: '',
	};
	const [form, setForm] = useState(initialFormState);

	const formChangeHandler = (name, value) => {
		setForm((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		let requestData;
		if (form.contact) {
			requestData = {
				error: form.error,
				contact: form.contact,
			};
		} else {
			requestData = {
				error: form.error,
			};
		}
		await dispatch(sendError({ requestData }));
	};

	useEffect(() => {
		if (sendError_success) {
			setForm(initialFormState);
			setIsActive(true);
			setText('Сообщение отправлено');
			setDescr('Мы свяжемся с вами в ближайшее время');
			setStatus('success');
		} else if (sendError_error) {
			setIsActive(true);
			setText('Сообщение не отправлено');
			setDescr('Описание должно содержать минимум 50 символов');
			setStatus('error');
		}

		dispatch(setInitialSendError());
	}, [sendError_success, sendError_error]);

	const onClose = () => {
		setIsActive(false);
	};

	return (
		<div className={styles.footer}>
			{showModal && (
				<div className={styles.footer__modal}>
					<SeparateModal
						btnIcon={
							<UniversalButton
								icon={<IconCross size={'largeM'} color={'primary_white'} />}
								onClick={handleCloseModal}
								color={'primary'}
								size={'medium'}
								typeButton={'button'}
							/>
						}
						onClose={handleCloseModal}
						style={'secondary'}
						children={
							<div className={styles.modal}>
								<span>Сообщить об ошибке</span>
								<form onSubmit={onSubmit} className={styles.modal__form}>
									<div>
										<label htmlFor="contact">Способ связи (необязательно)</label>
										<input
											name="contact"
											placeholder="Как с вами связаться?"
											minLength="11"
											value={form.contact}
											onChange={(e) => formChangeHandler('contact', e.target.value)}
										/>
									</div>
									<div>
										<label htmlFor="error">Описание проблемы</label>
										<textarea
											name="error"
											required
											minLength="50"
											rows="7"
											placeholder="Опишите проблему"
											value={form.error}
											onChange={(e) => formChangeHandler('error', e.target.value)}
										/>
									</div>
									<UniversalButton label={'Отправить'} color={'primary'} size={'large'} typeButton={'submit'} />
								</form>
							</div>
						}
					/>
					<Notification text={text} descr={descr} onClose={onClose} isActive={isActive} isStatus={status} />
				</div>
			)}

			<div className={styles.footer__block}>
				<div className={styles.footer__contacts}>
					<span>Контакты:</span>
					<div className={styles.contacts__list}>
						<p>г. Москва, Варшавское шоссе, д. 1, стр. 6</p>
						<p>
							Телефон: <a href="tel:+78005509191">8-800-550-91-91</a>(круглосуточно)
						</p>
						<p>
							Эл. почта: <a href="mailto:mail@tt3s.ru">mail@tt3s.ru</a>
						</p>
					</div>
					<div className={styles.footer__contacts_btn}>
						<UniversalButton
							label={'Сообщить об ошибке'}
							onClick={handleOpenModal}
							color={'primary'}
							size={'large'}
							typeButton={'button'}
						/>
					</div>
				</div>
			</div>
			<div className={styles.footer__copyright}>
				<div>
					<p>Разработчик</p>
					<Link to="https://steiza.com/">
						<img src={logoSteizaWhite} alt="Logo Steiza White" />
					</Link>
				</div>
				<p>Copyright © 2024 ТЕТРИС Контейнерный терминал.</p>
			</div>
		</div>
	);
}

export default Footer;
