import { Notification, UniversalButton } from "../../../../../../components";
import { IconArrowLeft } from "../../../../../../components/Icons";
import styles from "./Step2.module.scss";
// import { loginUser } from "../../../../../../store/actions/registarationUser";
import { Form } from "../../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { postLoginUser, setInitialLoginStatus } from "../../../../../../store/slices/loginSlice";
import MainInput from "../../../../../../components/Inputs/MainInput/MainInput";


function Step2({ setPage }) {
  const [isActive, setIsActive] = useState(false);
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const { loginUser_success, loginUser_error } = useSelector((state) => state.login)

  const navigate = useNavigate();
  let email = localStorage.getItem("email");

  const [notText, setNotText] = useState("");
  const [notDescr, setNotDescr] = useState("");
  const [notStatus, setNotStatus] = useState("");

  const addNot = (text, descr, status) => {
    setNotText(text);
    setNotDescr(descr);
    setNotStatus(status);
    setIsActive(true);
  };

  const onClose = () => {
    setIsActive(false);
  };

  const handleClickBack = () => {
    setPage(0);
  };

  const onSubmit = async (data) => {
    // try {
    let requestData;
    requestData = {
      email: email,
      password: data.password,
    };
    await dispatch(postLoginUser({ requestData }));

    // } catch (error) {
    // if (error.response.data.email) {
    //   addNot("Ошибка", error.response.data.email, "error");
    // } else if (error.response.data.password) {
    //   addNot("Ошибка", error.response.data.password, "error");
    // } else {
    //   setPage(2);
    // }
    // }
  };


  useEffect(() => {
    if (loginUser_success) {
      addNot("Успех", "Вход выполнен успешно", "success");
      localStorage.removeItem("email");
      setTimeout(() => {
        navigate('/accountClient/main');
      }, [1000]);
    }
    else if (loginUser_error.email) {
      addNot("Ошибка", loginUser_error.email, "error");
    }
    else if (loginUser_error.password) {
      addNot("Ошибка", loginUser_error.password, "error");
    }
    else if (loginUser_error.detail) {
      setPage(2);
    }
    dispatch(setInitialLoginStatus())
  }, [loginUser_success, loginUser_error])

  return (
    <>
      <Notification
        text={notText}
        descr={notDescr}
        onClose={onClose}
        isActive={isActive}
        isStatus={notStatus}
      />
      <div className={styles.navBar}>
        <UniversalButton icon={<IconArrowLeft size={"largeS"} color={"black"} />} onClick={handleClickBack} size={"large"} typeButton={"button"} />
      </div>
      <div className={styles.block}>
        <h5>Вход</h5>
        <Form onSubmit={onSubmit}>
          <MainInput
            title={'Пароль'}
            errorTitle={'Некорректный формат пароля'}
            name="password"
            value={password}
            setValue={setPassword}
            rules={{
              required: "Обязательное поле",
              minLength: {
                value: 6,
                message: "Минимум 6 символов",
              },
              pattern: {
                value: /^[^\sа-яА-Я]{6,}$/,
                message: "Латинские символы",
              },
            }}
            placeholder={'Введите пароль'}
          />
          <div className={styles.block__btns}>
            <UniversalButton label={"Войти"} color={"primary"} size={"large"} typeButton={"submit"} />
            <UniversalButton label={"Восстановить пароль"} color={"primary"} size={"large"} typeButton={"button"} type={"with-border"} />
          </div>
        </Form>
      </div>
    </>
  );
}

export default Step2;
