import React, { useEffect } from 'react';
import ExportContainerList from '../../ExportContainerList/ExportContainerList';
import OrderBlock from '../../OrderBlock/OrderBlock';
import Containers from '../../Containers/Containers';

import styles from '../style.module.scss';
import AvailiableContainers from './AvailiableContainers';
import ContainersInOrder from './ContainersInOrder';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgContainers } from '../../../../store/slices/orderSlice';
export default function ExportContextComponent({ containersList }) {
	const { currentOrderContainerList, currentOrderData, organizationContainerList } = useSelector((state) => state.order);
	const dispatch = useDispatch();
	//! TEST
	const setSelectedContainers = () => {
		console.log('testik 1');
	};
	const setExportDataList = () => {
		console.log('testik 1');
	};
	const setPage = () => {
		console.log('setPAge');
	};
	const mob = false;

	//! Получение списка всех контейнеров у клиента
	useEffect(() => {
		dispatch(getOrgContainers({ terminal_id: currentOrderData?.terminal?.id, org_id: currentOrderData?.client?.id, page: 1 }));
	}, []);

	// useEffect(() => {
	// 	console.log(organizationContainerList, currentOrderContainerList, 'EKSPORT');
	// }, []);
	return (
		<div className={`${styles.account__step} ${styles.export_step3}`}>
			<AvailiableContainers />
			{/* <OrderBlock
				children={
					<ExportContainerList
						data1={containersList}
						setData1={setSelectedContainers}
						data={containersList}
						setData={setExportDataList}
					/>
				}
				title="Контейнеры"
			/> */}
			{/* <pre>{JSON.stringify(containersList) || 122}</pre> */}
			<ContainersInOrder />
			{/* <Containers
				exportOrder
				setData={setExportDataList}
				setData1={setSelectedContainers}
				data={containersList}
				mob={mob}
				setPage={setPage}
			/> */}
		</div>
	);
}
