import React, { useEffect, useState } from 'react';
import styles from './AdditionalInfo.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox } from '../../../../../../../../components';
import { setDamageType } from '../../../../../../../../store/slices/containerAcceptanceSlice';

function AdditionalInfo(props) {
	const dispatch = useDispatch();
	const { currentSideDamage, selectedSide } = useSelector((state) => state.containerAccept);

	const handleCheckBoxClick = (label) => {
		dispatch(setDamageType(label));
	};

	const isDamageTypeChoosen = (currentSideDamage, damage) => {
		return currentSideDamage?.damageType?.name === damage?.name;
	};

	useEffect(() => {
		console.log(currentSideDamage);
	}, [currentSideDamage]);
	return (
		<>
			<div className={styles.checkbox_items}>
				{selectedSide.damages.map((checkbox, index) => (
					<div key={index}>
						<CheckBox
							type={2}
							typeCheck={isDamageTypeChoosen(currentSideDamage, checkbox)}
							onClick={() => handleCheckBoxClick(checkbox)}
						/>
						<p>
							{checkbox.name} - {checkbox.code}
						</p>
					</div>
				))}
			</div>
		</>
	);
}

export default AdditionalInfo;
