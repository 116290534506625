import styles from '../Notification.module.scss';

function IconCheckCircle() {
    return (
        <div className={styles.icon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18.5303 7.90533C18.8232 7.61244 18.8232 7.13756 18.5303 6.84467C18.2374 6.55178 17.7626 6.55178 17.4697 6.84467L18.5303 7.90533ZM9.75 15.625L9.21967 16.1553C9.51256 16.4482 9.98744 16.4482 10.2803 16.1553L9.75 15.625ZM6.53033 11.3447C6.23744 11.0518 5.76256 11.0518 5.46967 11.3447C5.17678 11.6376 5.17678 12.1124 5.46967 12.4053L6.53033 11.3447ZM17.4697 6.84467L9.21967 15.0947L10.2803 16.1553L18.5303 7.90533L17.4697 6.84467ZM10.2803 15.0947L6.53033 11.3447L5.46967 12.4053L9.21967 16.1553L10.2803 15.0947ZM21.25 12C21.25 17.1086 17.1086 21.25 12 21.25V22.75C17.9371 22.75 22.75 17.9371 22.75 12H21.25ZM12 21.25C6.89137 21.25 2.75 17.1086 2.75 12H1.25C1.25 17.9371 6.06294 22.75 12 22.75V21.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75V1.25C6.06294 1.25 1.25 6.06294 1.25 12H2.75ZM12 2.75C17.1086 2.75 21.25 6.89137 21.25 12H22.75C22.75 6.06294 17.9371 1.25 12 1.25V2.75Z" fill="#149527" />
            </svg>
        </div>
    );
}

export default IconCheckCircle; 