import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconCheck({ size, color }) {
  return (
    <div className={styles.icon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={IconSize(size)}
        height={IconSize(size)}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.6663 5L7.49967 14.1667L3.33301 10"
          stroke={IconColor(color)}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default IconCheck;
