import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postContactUs } from '../../api/public/postContactUs';

import { getOneUser } from '../../api/erp/getOneUser';
import { getGroup } from '../../api/user/getGroup';
import { delUser } from '../../api/erp/deleteUser';
import { putUser } from '../../api/erp/putUser';
import { postNewClient } from '../../api/erp/postClient';
import { postNewUser } from '../../api/user/postUserApi';
import { deleteClientApi } from '../../api/client/deleteClientApi';
import { putClientApi } from '../../api/client/putClientApi';
import { postClientApi } from '../../api/client/postClientApi';
import { getClientsApi } from '../../api/client/getClientsApi';
import { confirmClientApi } from '../../api/client/confirmClientApi';
import { getAllOrganizations } from '../../api/erp/getAllOrganizations';
import { getOneOrganization } from '../../api/erp/getOneOrganization';
import { putOrganization } from '../../api/erp/putOrganization';
import { postCreateWorkerOrg } from '../../api/erp/postCreateWorkerOrg';
import { getOneOrganizationWorkerList } from '../../api/erp/getOneOrganizationWorkerList';
import { putWorkerOrganization } from '../../api/erp/putWorkerOrganization';
import { postOrgCreateContract } from '../../api/erp/postOrgCreateContract';
import { confirmOrganization } from '../../api/erp/confirmOrganization';
import { getOrgContracts } from '../../api/erp/getOrgContracts';
import { getOrgContract } from '../../api/erp/getOrgContract';

export const getClients = createAsyncThunk('client/getClients', async (_, { rejectWithValue, getState }) => {
	try {
		const state = getState();
		let response;
		if (state.client.next && state.client.fetching) {
			response = await getAllOrganizations(state.client.next);
		} else if (state.client.url && state.client.fetching) {
			response = await getAllOrganizations(state.client.url);
		}

		if (!response?.statusText === 'OK') {
			throw new Error('Ошибка запроса client/getClient. Не удалось получить данные');
		}
		return response.data;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const getClient = createAsyncThunk('client/getClient', async ({ user_id }, { rejectWithValue }) => {
	try {
		const response = await getOneOrganization(user_id);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса client/getClient. Не удалось получить данные');
		}
		return response.data;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const getWorkerList = createAsyncThunk('client/getWorkerList', async ({ user_id }, { rejectWithValue }) => {
	try {
		const response = await getOneOrganizationWorkerList(user_id);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса client/getWorkerList. Не удалось получить данные');
		}
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const postCreateWorker = createAsyncThunk('client/postCreateWorker', async ({ requestData }, { rejectWithValue }) => {
	try {
		const response = await postCreateWorkerOrg(requestData);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса user/postUser. Не удалось отправить данные');
		}
		return response.data;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const putClient = createAsyncThunk('client/putClient', async ({ user_id, requestData }, { rejectWithValue }) => {
	try {
		// const response = await putClientApi(user_id, requestData);
		const response = await putOrganization(user_id, requestData);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса client/putClient. Не удалось отправить данные');
		}
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const putWorkerOrg = createAsyncThunk('client/putWorkerOrg', async ({ user_id, requestData }, { rejectWithValue }) => {
	try {
		const response = await putWorkerOrganization(user_id, requestData);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса client/putWorkerOrg. Не удалось отправить данные');
		}
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const deleteClient = createAsyncThunk('client/deleteClient', async ({ user_id }, { rejectWithValue }) => {
	try {
		const response = await deleteClientApi(user_id);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса client/deleteClient. Не удалось отправить данные');
		}
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const confirmClient = createAsyncThunk('client/confirmClient', async ({ user_id, requestData }, { rejectWithValue }) => {
	try {
		const response = await confirmClientApi(user_id, requestData);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса client/confirmClient. Не удалось отправить данные');
		}
		return { response, status: requestData.is_manager_confirmed };
	} catch (error) {
		return rejectWithValue(error);
	}
});

// Новое одобрение клиента
export const confirmOrg = createAsyncThunk('client/confirmOrg', async ({ org_id, requestData }, { rejectWithValue }) => {
	try {
		const response = await confirmOrganization(org_id, requestData);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса client/confirmOrg. Не удалось отправить данные');
		}
		return { response, status: requestData.is_manager_confirmed };
	} catch (error) {
		return rejectWithValue(error);
	}
});

// Получение списков документов клиента
export const getContractsList = createAsyncThunk('client/getContractsList', async ({ org_id }, { rejectWithValue }) => {
	try {
		const response = await getOrgContracts(org_id);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса client/getContractsList. Не удалось получить данные');
		}
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

// Получение одного документа клиента
export const getContract = createAsyncThunk('client/getContract', async ({ org_id, contract_id }, { rejectWithValue }) => {
	try {
		const response = await getOrgContract(org_id, contract_id);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса client/getContract. Не удалось получить данные');
		}
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const postCreateContract = createAsyncThunk(
	'client/postCreateContract',
	async ({ org_id, requestDataContract }, { rejectWithValue }) => {
		try {
			const response = await postOrgCreateContract(org_id, requestDataContract);
			if (!response.statusText === 'OK') {
				throw new Error('Ошибка запроса user/postCreateContract. Не удалось отправить данные');
			}
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

const clientSlice = createSlice({
	name: 'client',
	initialState: {
		//Модалки
		clientModalActive: false,
		currentClientIdForModal: null,

		//Тип модалки 'create / update'
		modalType: null,

		// Группы пользователей одобренные/ожидают одобрения/отклоненные, 0 - отображает все
		clientGroup: 0,

		// Список клиентов
		clientList: [],

		// Количество клиентов
		count: 0,

		// Список документов клиента
		contractsList: [],
		// Один документ клиента
		currentContract: null,

		//Выбранный клиент для отображения данных в модалке
		currentClient: null,

		// Список работников клиента
		workerList: [],

		// Пользователь статус подтверждения клиента (для отображения кнопок подтверждения и отклонения)
		isCurrentClientConfirmed: null,

		// Поля формы для изменения клиента
		newFullName: '',
		newClientCorporateName: '',
		newClientCorporateAddress: '',
		newClientEmail: '',
		newClientInn: '',
		// newClientIsManagerConfirmed: '',
		newClientContractNumber: '',
		newClientContractDate: '',
		//TODO
		// newClientTerminal: '',
		newClientPassword: '',

		inputChange: false,

		//Статусы запроса на получение списка клиентов
		getClients_success: false,
		getClients_pending: false,
		getClients_error: false,

		// Статусы запроса на получение клиента
		getClient_success: false,
		getClient_pending: false,
		getClient_error: false,

		// Статусы запроса на получение работников клиента
		getWorkerList_success: false,
		getWorkerList_pending: false,
		getWorkerList_error: false,

		// Статусы запроса на добавления клиента
		postCreateWorker_success: false,
		postCreateWorker_pending: false,
		postCreateWorker_error: false,

		// Статусы запроса на изменение клиента
		putClient_success: false,
		putClient_pending: false,
		putClient_error: false,

		// Статусы запроса на изменение работников у клиента
		putWorkerOrg_success: false,
		putWorkerOrg_pending: false,
		putWorkerOrg_error: false,

		// Статусы запроса на удаление клиента
		deleteClient_success: false,
		deleteClient_pending: false,
		deleteClient_error: false,

		// Статусы запроса на подтверждение клиента
		confirmClient_success: false,
		confirmClient_pending: false,
		confirmClient_error: false,

		// НОВОЕ Статусы запроса на подтверждение клиента
		confirmOrg_success: false,
		confirmOrg_pending: false,
		confirmOrg_error: false,

		// Статусы запроса на добавление контракта
		postCreateContract_success: false,
		postCreateContract_pending: false,
		postCreateContract_error: false,

		// Статусы запроса на получение документов клиента
		getContractsList_success: false,
		getContractsList_pending: false,
		getContractsList_error: false,

		// Статусы запроса на получение 1 документа клиента
		getContract_success: false,
		getContract_pending: false,
		getContract_error: false,

		//! Infinite Scroll for organizations
		fetching: true,
		url: '',
		next: null,
	},
	reducers: {
		//Функция для регистрации изменений для того чтобы отображать кнопки сохранить/отменить
		setInputChange(state, action) {
			state.inputChange = action.payload;
		},
		// Отображение скрытие клиентской модалки
		//TODO сейчас другая логика
		setClientModalActive(state, action) {
			state.clientModalActive = action.payload;
		},
		// Изменение названия юр лица
		setNewClientCorporateName(state, action) {
			state.newClientCorporateName = action.payload;
		},
		// Изменение юридического адреса
		setNewClientCorporateAddress(state, action) {
			state.newClientCorporateAddress = action.payload;
		},
		// Изменение email в форме
		setNewClientEmail(state, action) {
			state.newClientEmail = action.payload;
		},
		// Функция подтверждения или отклонения клиента
		setNewClientIsManagerConfirmed(state, action) {
			state.clientConfirmed = action.payload;
		},
		// Изменение номера договора в форме
		setNewClientContractNumber(state, action) {
			state.newClientContractNumber = action.payload;
		},
		// Изменение даты подписания договора в форме
		setNewClientContractDate(state, action) {
			state.newClientContractDate = action.payload;
		},

		//TODO
		setNewClientTerminal(state, action) {
			state.newClientTerminal = action.payload;
		},
		// Выбор клиента по нажатию на его карточку
		setCurrentClient(state, action) {
			state.currentClient = action.payload;
		},
		// Сброс новых данных при отмене изменений
		setCurrentClientInitial(state, action) {
			// state.newClientContractNumber = state.currentClient.contract_number;
			state.newClientCorporateAddress = state.currentClient.organization_address;
			state.newClientCorporateName = state.currentClient.organization_name;
			state.newClientEmail = state.currentClient.contact_email;
			state.newClientInn = state.currentClient.inn;
		},
		// Выбор типа модального клиента (редактирование или создание нового)
		setModalType(state, action) {
			state.modalType = action.payload;
		},
		//! Infinite scroll
		setClientsFetching(state, action) {
			state.fetching = action.payload;
		},
		setClientNextNull(state, action) {
			state.next = null;
		},
		setClientsUrlAfterClientGroupChange(state, action) {
			state.url = action.payload;
		},
		setClientList(state, action) {
			state.clientList = action.payload;
		},
		// setClientFetching(state, action) {
		//   state.fetching = action.payload;
		// },
		// setClientNextNull(state, action) {
		//   state.next = null;
		// },
		// Выбор группы клиента в слайдере
		setClientGroup(state, action) {
			// state.clientList =[]
			state.clientGroup = action.payload;
		},
		setClientListAfterDelete(state, action) {
			state.clientList = state.clientList.filter((user) => user.id !== action.payload.user_id);
		},
		setClientListAfterPut(state, action) {
			state.clientList.forEach((item) => {
				if (item.id == action.payload.user_id) {
					item.contact_email = action.payload.updatedUser?.contact_email;
					item.password = action.payload.updatedUser?.password;
					item.organization_name = action.payload.updatedUser?.organization_name;
					item.organization_address = action.payload.updatedUser?.organization_address;
					item.contract_number = action.payload.updatedUser?.contract_number;
					item.inn = action.payload.updatedUser?.inn;
				}
			});
		},
		setClientListAfterConfirm(state, action) {
			let type;
			switch (action.payload.status) {
				case 1:
					type = 'Одобрен';
					break;
				case 2:
					type = 'Ожидает одобрения';
					break;
				case 3:
					type = 'Отклонен';
					break;
				default:
					break;
			}
			state.clientList.forEach((el) => {
				if (el.id === action.payload.user_id) {
					el.is_manager_confirmed = type;
				}
			});
		},
		setUseFormPlug(state, action) {},
		setClientUrl(state, action) {
			if (action.payload !== 0) {
				state.url = `?type=2&limit=${action.payload}&offset=0`;
			}
		},
		// Список работников
		setWorkerList(state, action) {
			state.workerList = action.payload.data.results;
		},

		// Обновление списка работников после редактирования одного
		setWorkerListAfterPut(state, action) {
			state.workerList.forEach((item) => {
				if (item.id == action.payload.user_id) {
					item.full_name = action.payload.updatedUser?.full_name;
					item.email = action.payload.updatedUser?.email;
					item.password = action.payload.updatedUser?.password;
					item.phone = action.payload.updatedUser?.phone;
					item.telegram = action.payload.updatedUser?.telegram;
					item.whatsapp = action.payload.updatedUser?.whatsapp;
				}
			});
		},

		// Обновление списка работников после удаления одного
		setWorkerListAfterDelete(state, action) {
			state.workerList = state.workerList.filter((user) => user.id !== action.payload.user_id);
		},
		setNewFullName(state, action) {
			state.newFullName = action.payload;
		},
		setNewClientInn(state, action) {
			state.newClientInn = action.payload;
		},
		// Список документов
		setContractsList(state, action) {
			state.contractsList = action.payload.data.results;
		},
		setCurrentContract(state, action) {
			state.currentContract = action.payload;
		},
		setPostCreateContract_success(state, action) {
			state.postCreateContract_success = action.payload;
		},
		clearClientDataWhileUnmounting(state, action) {
			console.log('client unmounting');
			state.clientModalActive = false;
			state.currentClientIdForModal = null;
			state.modalType = null;
			state.clientGroup = 0;
			state.clientList = [];
			state.count = 0;
			state.fetching = true;
			state.url = '';
			state.next = null;
			state.getClients_success = false;
			state.getClients_pending = false;
			state.getClients_error = false;
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(getClients.fulfilled, (state, action) => {
				state.getClients_success = true;
				state.getClients_pending = false;
				state.getClients_error = false;
				if (action.payload?.next) {
					const startIndex = action.payload?.next.indexOf('?');
					const trimmedUrl = `${action.payload?.next.substring(startIndex)}`;
					state.next = trimmedUrl;
					state.url = '';
				} else {
					state.next = null;
					state.url = '';
				}
				state.fetching = false;
				if (action.payload?.results) {
					state.clientList = [...state.clientList, ...action.payload?.results];
				}

				state.count = action.payload?.count;
				//   state.clientList = action.payload;
			})
			.addCase(getClients.pending, (state, action) => {
				state.getClients_success = false;
				state.getClients_pending = true;
				state.getClients_error = false;
			})
			.addCase(getClients.rejected, (state, action) => {
				state.getClients_success = false;
				state.getClients_pending = false;
				state.getClients_error = action.payload;
			});
		builder
			.addCase(getClient.fulfilled, (state, action) => {
				state.getClient_success = true;
				state.getClient_pending = false;
				state.getClient_error = false;
				state.currentClient = action.payload;

				// state.newClientContractNumber = action.payload.contract_number;
				state.newClientCorporateName = action.payload.organization_name;
				state.newClientCorporateAddress = action.payload.organization_address;
				state.isCurrentClientConfirmed = action.payload.is_manager_confirmed;
				state.newClientEmail = action.payload.contact_email;
				state.newClientInn = action.payload.inn;
			})
			.addCase(getClient.pending, (state, action) => {
				state.getClient_success = false;
				state.getClient_pending = true;
				state.getClient_error = false;
			})
			.addCase(getClient.rejected, (state, action) => {
				state.getClient_success = false;
				state.getClient_pending = false;
				state.getClient_error = action.payload;
			});
		builder
			.addCase(getWorkerList.fulfilled, (state, action) => {
				state.getWorkerList_success = true;
				state.getWorkerList_pending = false;
				state.getWorkerList_error = false;
				state.workerList = action.payload.data.results;
			})
			.addCase(getWorkerList.pending, (state, action) => {
				state.getWorkerList_success = false;
				state.getWorkerList_pending = true;
				state.getWorkerList_error = false;
			})
			.addCase(getWorkerList.rejected, (state, action) => {
				state.getWorkerList_success = false;
				state.getWorkerList_pending = false;
				state.getWorkerList_error = action.payload;
			});
		builder
			.addCase(deleteClient.fulfilled, (state, action) => {
				state.deleteClient_success = true;
				state.deleteClient_pending = false;
				state.deleteClient_error = false;
			})
			.addCase(deleteClient.pending, (state, action) => {
				state.deleteClient_success = false;
				state.deleteClient_pending = true;
				state.deleteClient_error = false;
			})
			.addCase(deleteClient.rejected, (state, action) => {
				state.deleteClient_success = false;
				state.deleteClient_pending = false;
				state.deleteClient_error = action.payload;
			});

		builder
			.addCase(putClient.fulfilled, (state, action) => {
				state.putClient_success = true;
				state.putClient_pending = false;
				state.putClient_error = false;
			})
			.addCase(putClient.pending, (state, action) => {
				state.putClient_success = false;
				state.putClient_pending = true;
				state.putClient_error = false;
			})
			.addCase(putClient.rejected, (state, action) => {
				state.putClient_success = false;
				state.putClient_pending = false;
				state.putClient_error = action.payload;
			});
		builder
			.addCase(putWorkerOrg.fulfilled, (state, action) => {
				state.putWorkerOrg_success = true;
				state.putWorkerOrg_pending = false;
				state.putWorkerOrg_error = false;
			})
			.addCase(putWorkerOrg.pending, (state, action) => {
				state.putWorkerOrg_success = false;
				state.putWorkerOrg_pending = true;
				state.putWorkerOrg_error = false;
			})
			.addCase(putWorkerOrg.rejected, (state, action) => {
				state.putWorkerOrg_success = false;
				state.putWorkerOrg_pending = false;
				state.putWorkerOrg_error = action.payload;
			});
		builder
			.addCase(postCreateWorker.fulfilled, (state, action) => {
				state.postCreateWorker_success = true;
				state.postCreateWorker_pending = false;
				state.postCreateWorker_error = false;
				// Обновление списка работников после добавления одного
				state.workerList = [...state.workerList, action.payload];
			})
			.addCase(postCreateWorker.pending, (state, action) => {
				state.postCreateWorker_success = false;
				state.postCreateWorker_pending = true;
				state.postCreateWorker_error = false;
			})
			.addCase(postCreateWorker.rejected, (state, action) => {
				state.postCreateWorker_success = false;
				state.postCreateWorker_pending = false;
				state.postCreateWorker_error = action.payload;
			});
		builder
			.addCase(confirmClient.fulfilled, (state, action) => {
				state.confirmClient_success = true;
				state.confirmClient_pending = false;
				state.confirmClient_error = false;

				switch (action.payload.status) {
					case 1:
						state.isCurrentClientConfirmed = 'Одобрен';
						break;
					case 2:
						state.isCurrentClientConfirmed = 'Ожидает одобрения';
						break;
					case 3:
						state.isCurrentClientConfirmed = 'Одобрен';
						break;
					default:
						break;
				}
			})
			.addCase(confirmClient.pending, (state, action) => {
				state.confirmClient_success = false;
				state.confirmClient_pending = true;
				state.confirmClient_error = false;
			})
			.addCase(confirmClient.rejected, (state, action) => {
				state.confirmClient_success = false;
				state.confirmClient_pending = false;
				state.confirmClient_error = action.payload;
			});

		// НОВОЕ
		builder
			.addCase(confirmOrg.fulfilled, (state, action) => {
				state.confirmOrg_success = true;
				state.confirmOrg_pending = false;
				state.confirmOrg_error = false;

				switch (action.payload.status) {
					case 1:
						state.isCurrentClientConfirmed = 'Одобрен';
						break;
					case 2:
						state.isCurrentClientConfirmed = 'Ожидает одобрения';
						break;
					case 3:
						state.isCurrentClientConfirmed = 'Отклонен';
						break;
					default:
						break;
				}
			})
			.addCase(confirmOrg.pending, (state, action) => {
				state.confirmOrg_success = false;
				state.confirmOrg_pending = true;
				state.confirmOrg_error = false;
			})
			.addCase(confirmOrg.rejected, (state, action) => {
				state.confirmOrg_success = false;
				state.confirmOrg_pending = false;
				state.confirmOrg_error = action.payload;
			});
		builder
			.addCase(getContractsList.fulfilled, (state, action) => {
				state.getContractsList_success = true;
				state.getContractsList_pending = false;
				state.getContractsList_error = false;
				state.contractsList = action.payload.data.results;
			})
			.addCase(getContractsList.pending, (state, action) => {
				state.getContractsList_success = false;
				state.getContractsList_pending = true;
				state.getContractsList_error = false;
			})
			.addCase(getContractsList.rejected, (state, action) => {
				state.getContractsList_success = false;
				state.getContractsList_pending = false;
				state.getContractsList_error = action.payload;
			});

		builder
			.addCase(getContract.fulfilled, (state, action) => {
				state.getContract_success = true;
				state.getContract_pending = false;
				state.getContract_error = false;
				state.currentContract = action.payload.data;
			})
			.addCase(getContract.pending, (state, action) => {
				state.getContract_success = false;
				state.getContract_pending = true;
				state.getContract_error = false;
			})
			.addCase(getContract.rejected, (state, action) => {
				state.getContract_success = false;
				state.getContract_pending = false;
				state.getContract_error = action.payload;
			});

		builder
			.addCase(postCreateContract.fulfilled, (state, action) => {
				state.postCreateContract_success = true;
				state.postCreateContract_pending = false;
				state.postCreateContract_error = false;

				// Обновление списка документов после добавления
				state.contractsList = [...state.contractsList, action.payload];
			})
			.addCase(postCreateContract.pending, (state, action) => {
				state.postCreateContract_success = false;
				state.postCreateContract_pending = true;
				state.postCreateContract_error = false;
			})
			.addCase(postCreateContract.rejected, (state, action) => {
				state.postCreateContract_success = false;
				state.postCreateContract_pending = false;
				state.postCreateContract_error = action.payload;
			});
	},
});

export const {
	clearClientDataWhileUnmounting,
	setUseFormPlug,
	setClientListAfterPut,
	setNewClientTerminal,
	setClientListAfterConfirm,
	setClientListAfterDelete,
	setClientNextNull,
	setClientGroup,
	// setClientFetching,
	setClientList,
	setClientsUrlAfterClientGroupChange,
	setCurrentClientInitial,
	setInputChange,
	setClientModalActive,
	setNewClientCorporateName,
	setNewClientCorporateAddress,
	setNewClientEmail,
	setNewClientIsManagerConfirmed,
	setNewClientContractNumber,
	setNewClientContractDate,
	setCurrentClient,
	setModalType,
	setClientsFetching,
	setClientUrl,
	setWorkerList,
	setWorkerListAfterPut,
	setWorkerListAfterDelete,
	setNewFullName,
	setNewClientInn,
	setPostCreateContract_success,
	setCurrentContract,
} = clientSlice.actions;
export default clientSlice.reducer;
