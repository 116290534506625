import React, { useState } from 'react';
import styles from './ExportContainerList.module.scss';
import UniversalButton from '../../Buttons/UniversalButton/UniversalButton';
import { IconContainerCar, IconDisketa } from '../../Icons';
import ContainersItem from '../Containers/ContainersItem';
import ConfirmationWindow from '../../ConfirmationWindow/ConfirmationWindow';
import { useDispatch } from 'react-redux';
import { addContainersInOrder, setChoosenContainerForChangeCancelVariant } from '../../../store/slices/orderSlice';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../store/slices/sideBarSlice';
import ChangeContainerForm from '../ChangeContainerForm/ChangeContainerForm';

function ExportContainerList({ data, handleAddToOrder, handleChangeContainer, handleRemove }) {
	const dispatch = useDispatch();
	const [confWindow, setConfWindow] = useState(false);
	const [containerEL, setContainerEL] = useState();
	const [removeAllCard, setRemoveAllCard] = useState(false);

	const handleRemoveCard = () => {
		// const updatedData = data?.filter((item) => item !== containerEL);
		// setData(updatedData);
		// setConfWindow(false);
	};

	// При экспорте кнопка выбрать все
	const selectAllCards = () => {
		dispatch(addContainersInOrder({ type: 'export_before', data: data }));

		// setData([]);
	};

	return (
		<div className={styles.main_block_container_list}>
			{/* <p>INS</p> */}
			{confWindow || removeAllCard ? (
				<ConfirmationWindow
					titleText={`Вы уверены, что хотите удалить ${removeAllCard ? 'все контейнеры?' : 'контейнер №' + containerEL.container_number}`}
					acceptBtnText={'Да, удалить'}
					acceptBtnClick={handleRemove}
					rejectBtnText={'Отменить'}
					rejectBtnClick={() => (removeAllCard ? setRemoveAllCard(false) : setConfWindow(false))}
				/>
			) : (
				<></>
			)}
			<div className={styles.containers_list}>
				{data?.map((item, index) => (
					<ContainersItem
						handleAddToOrder={() => handleAddToOrder(item.id)}
						handleChange={handleChangeContainer}
						type={'notAdded'}
						key={index}
						el={item}
					/>
				))}
			</div>
			<div className={styles.containers_bottom_buttons}>
				<div>
					<UniversalButton
						icon={<IconDisketa size={'medium'} color={'core-state-success'} />}
						size={'small'}
						color={'succesful'}
						type={'with-border'}
					/>
					<UniversalButton
						icon={<IconContainerCar size={'medium'} color={'white'} />}
						size={'small'}
						label={'0'}
						color={'primary'}
					/>
				</div>
				{data?.length !== 0 && <UniversalButton size={'small'} label={'Выбрать все'} color={'accent'} onClick={selectAllCards} />}
			</div>
		</div>
	);
}

export default ExportContainerList;
