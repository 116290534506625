import api from '../../api.js';
import { buildQueryString } from '../../../newComponents/Filter/utils.js';

export const entries = api.injectEndpoints({
	endpoints: (builder) => ({
		getEntriesList: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;

				return {
					url: `/api/entries/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetEntriesListQuery, useLazyGetEntriesListQuery } = entries;
