import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postContactUs } from '../../api/public/postContactUs';
import { postSendError } from '../../api/public/postSendError';
import { postSendErrorPrivate } from '../../api/public/postSendErrorPrivate';

export const contactUs = createAsyncThunk('publicForm/contactUs', async ({ requestData }, { rejectWithValue }) => {
	try {
		const response = await postContactUs(requestData);
		if (!response.statusCode == '201') {
			throw new Error('Ошибка запроса publicForm/postContactUs. Не удалось отправить данные');
		}
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const sendError = createAsyncThunk('publicForm/sendError', async ({ requestData }, { rejectWithValue }) => {
	try {
		const response = await postSendError(requestData);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса publicForm/postSendError. Не удалось отправить данные');
		}
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const sendErrorPrivate = createAsyncThunk('publicForm/sendErrorPrivate', async ({ requestData }, { rejectWithValue }) => {
	try {
		const response = await postSendErrorPrivate(requestData);
		if (!response.statusText === 'OK') {
			throw new Error('Ошибка запроса publicForm/sendErrorPrivate. Не удалось отправить данные');
		}
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

const publicFormSlice = createSlice({
	name: 'publicForm',
	initialState: {
		contactUs_success: false,
		contactUs_pending: true,
		contactUs_error: false,

		sendError_success: false,
		sendError_pending: true,
		sendError_error: false,

		sendErrorPrivate_success: false,
		sendErrorPrivate_pending: true,
		sendErrorPrivate_error: false,
	},
	reducers: {
		setInitialContactUsStatus(state, action) {
			state.contactUs_success = false;
			state.contactUs_pending = true;
			state.contactUs_error = false;
		},
		setInitialSendError(state, action) {
			state.sendError_success = false;
			state.sendError_pending = true;
			state.sendError_error = false;
		},
		setInitialSendErrorPrivate(state, action) {
			state.sendErrorPrivate_success = false;
			state.sendErrorPrivate_pending = true;
			state.sendErrorPrivate_error = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(contactUs.fulfilled, (state, action) => {
				state.contactUs_success = true;
				state.contactUs_pending = false;
				state.contactUs_error = false;
			})
			.addCase(contactUs.pending, (state, action) => {
				state.contactUs_success = false;
				state.contactUs_pending = true;
				state.contactUs_error = false;
			})
			.addCase(contactUs.rejected, (state, action) => {
				state.contactUs_success = false;
				state.contactUs_pending = false;
				state.contactUs_error = action.payload;
			});

		builder
			.addCase(sendError.fulfilled, (state, action) => {
				state.sendError_success = true;
				state.sendError_pending = false;
				state.sendError_error = false;
			})
			.addCase(sendError.pending, (state, action) => {
				state.sendError_success = false;
				state.sendError_pending = true;
				state.sendError_error = false;
			})
			.addCase(sendError.rejected, (state, action) => {
				state.sendError_success = false;
				state.sendError_pending = false;
				state.sendError_error = action.payload;
			});
		builder
			.addCase(sendErrorPrivate.fulfilled, (state, action) => {
				state.sendErrorPrivate_success = true;
				state.sendErrorPrivate_pending = false;
				state.sendErrorPrivate_error = false;
			})
			.addCase(sendErrorPrivate.pending, (state, action) => {
				state.sendErrorPrivate_success = false;
				state.sendErrorPrivate_pending = true;
				state.sendErrorPrivate_error = false;
			})
			.addCase(sendErrorPrivate.rejected, (state, action) => {
				state.sendErrorPrivate_success = false;
				state.sendErrorPrivate_pending = false;
				state.sendErrorPrivate_error = action.payload;
			});
	},
});

export const { setInitialContactUsStatus, setInitialSendError, setInitialSendErrorPrivate } = publicFormSlice.actions;
export default publicFormSlice.reducer;
