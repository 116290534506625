const initialState = {
    groupsUsers: []
};

const groupsUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_GROUP_SUCCESS':
            return { ...state, groupsUsers: action.payload }
        default:
            return state;
    }
};

export default groupsUsersReducer;