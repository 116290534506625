import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = async (args, api, extraOptions) => {
	return fetchBaseQuery({
		baseUrl: '/',
	})(args, api, extraOptions);
};

const api = createApi({
	baseQuery,
	tagTypes: [
		'cargos',
		'containers',
		'entries',
		'entryreports',
		'groups',
		'logs',
		'orders',
		'organizations',
		'service',
		'terminals',
		'users',
		'vehicles',
	],
	endpoints: () => ({}),
});

export default api;
