import React from 'react';
import UniversalBlock from '../components/UniversalBlock';
import styles from './SideStyle.module.scss';
import { useSelector } from 'react-redux';
import { isBlockChoosen } from './helpers/isBlockChoosen';
import { sideTypeToName } from './helpers/sideTypeToName';
import { panelNameToPanelAvailableCodes } from './helpers/panelNameToPanelAvailableCodes';
import UniversalDetailBlock from '../components/UniversalDetailBlock';
import { isDetailDamaged } from './helpers/isDetailDamaged';

function LeftSide({ feet }) {
	const { damagesList, selectedSide, damagedDetails } = useSelector((state) => state.containerAccept);
	const { placement_code_description } = useSelector((state) => state.containerAccept.selectedSide);

	console.log(feet, 'GG');

	return (
		<div className={styles.mainBlock_new}>
			<div className={styles.mapRowNumber_new}>
				<p className={styles.topNumber_new}></p>
				{placement_code_description[0]?.parts[`${feet}-feet`]
					.slice()
					.reverse()
					.map((part) => (
						<p key={part} className={styles.topNumber_new}>
							{part}
						</p>
					))}
			</div>
			{placement_code_description.map(({ option, parts }) => (
				<div key={option} className={styles.mapRow_new}>
					<p className={styles.sideLabel_new}>{option}</p>

					{parts[`${feet}-feet`]
						.slice()
						.reverse()
						.map((part, index) => (
							<UniversalBlock
								key={index}
								type={getType(option, part, feet)}
								sideIndex={selectedSide.code}
								sideName={sideTypeToName(selectedSide?.code)}
								divider={option}
								part={part.toString()}
								partCode={panelNameToPanelAvailableCodes(selectedSide?.components, selectedSide?.code, option, part)}
								choosen={isBlockChoosen(damagesList, selectedSide?.code, option, part.toString())}
							/>
						))}
				</div>
			))}
			<div className={styles[`forkPocketsContainer_${feet}`]}>
				<UniversalDetailBlock
					type={'Left_forkpocket_LL'}
					sideIndex={'L'}
					sideName={'Левая сторона'}
					divider={'-'}
					part={'Left_forkpocket_LL'}
					choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_forkpocket_LL')}
				/>
				<UniversalDetailBlock
					type={'Right_forkpocket_LR'}
					sideIndex={'L'}
					sideName={'Левая сторона'}
					divider={'-'}
					part={'Right_forkpocket_LR'}
					choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_forkpocket_LR')}
				/>
			</div>
		</div>
	);
}

function getType(option, part, feet) {
	if (option === 'H') {
		return `beam-horizontal-H`;
	} else if (option === 'G') {
		return `beam-horizontal-B`;
	} else {
		return 'mainPanel';
	}
}
export default LeftSide;
