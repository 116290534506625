import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useLazyGetEntriesListQuery } from '../../../../store/newApi/entries/entries';
import Filter from '../../../../newComponents/Filter/Filter';
import { formatInitialDate } from '../../../../helpers/formatDate';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/historyEntries.js';
import { useDispatch } from 'react-redux';

function AccountEmplTableHistoryVehicles() {
	const dispatch = useDispatch();
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});

	// колонки
	const columnsList = useMemo(
		() => [
			{
				accessorFn: (row) => row.name,
				id: 'name',
				header: 'Пропуск',
				size: 150,
			},
			{
				accessorFn: (row) => row.container.container_number,
				id: 'container_number',
				header: 'Номер контейнера',
				size: 220,
			},
			{
				accessorFn: (row) => row.terminal.name,
				id: 'terminal_name',
				header: 'Терминал',
				size: 160,
			},

			{
				accessorFn: (row) => row.vehicle_display,
				id: 'vehicle_display',
				header: 'Номер автомобиля',
				size: 250,
			},

			{
				accessorFn: (row) => row.vehicle_driver,
				id: 'vehicle_driver',
				header: 'Водитель',
				size: 200,
			},
			{
				accessorFn: (row) => (row.chekin_at ? formatInitialDate(row.chekin_at) : ''),
				id: 'chekin_at',
				header: 'Время приезда',
				size: 200,
			},
			{
				accessorFn: (row) => row.seal_number,
				id: 'seal_number',
				header: 'Номер пломбы',
				size: 200,
			},
		],
		[],
	);

	// порядок колонок
	const columnOrdering = [
		'mrt-row-select',
		'name',
		'container_number',
		'terminal_name',
		'vehicle_display',
		'vehicle_driver',
		'chekin_at',
		'seal_number',
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	return (
		<div className={styles.account__wrap}>
			<Header text={'История движений автомобилей по пропускам'} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<Filter onSetFilter={handleSetFilter} onResetFilter={handleResetFilter} />
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						hookName={useLazyGetEntriesListQuery}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="historyEntriesRtk"
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableHistoryVehicles;
