import React from 'react';
import styles from '../AccountEmpl.module.scss';
import ReceptionTransmission from '../components/ReceptionTransmission/ReceptionTransmission';
import Header from '../components/Header/Header';

const AccountEmplReceptionTransmission = () => {
	return (
		<div className={styles.account__wrap}>
			<Header text={'Акты приёма-передачи'} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<ReceptionTransmission />
				</div>
			</div>
		</div>
	);
};

export default AccountEmplReceptionTransmission;
