import styles from "./Step.module.scss";
import { Form, Notification, UniversalButton } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { postSendCode } from "../../../../../store/slices/registrationSlice";
import MainInput from "../../../../../components/Inputs/MainInput/MainInput";

function Step2({ setPage }) {
  const dispatch = useDispatch();
  const { sendCode_success, sendCode_error } = useSelector(
    (state) => state.registration
  );
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [isActive, setIsActive] = useState(false);

  const [notText, setNotText] = useState("");
  const [notDescr, setNotDescr] = useState("");
  const [notStatus, setNotStatus] = useState("");

  const addNot = (text, descr, status) => {
    setNotText(text);
    setNotDescr(descr);
    setNotStatus(status);
    setIsActive(true);
  };

  const onClose = () => {
    setIsActive(false);
  };

  const onSubmit = async (data) => {
    let requestData;
    requestData = {
      email: data.email,
    };
    await dispatch(postSendCode({ requestData }));
    localStorage.setItem("email", data.email);
  };
  useEffect(() => {
    if (sendCode_success) {
      setPage((currentPage) => currentPage + 1);
    } else if (sendCode_error) {
      addNot("Ошибка", "Данная электронная почта занята", "error");
    }
  }, [sendCode_success, sendCode_error]);
  return (
    <>
      <Notification
        text={notText}
        descr={notDescr}
        onClose={onClose}
        isActive={isActive}
        isStatus={notStatus}
      />
      <div className={styles.block}>
        <h5>Регистрация</h5>
        <Form onSubmit={onSubmit}>
          <MainInput
            title={'Электронная почта'}
            errorTitle={'Некорректный формат почты'}
            name="email"
            value={email}
            setValue={setEmail}
            rules={{
              required: "Обязательное поле",
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Например: ivan.petrov@mail.ru",
              },
            }}
            placeholder={'ivan.petrov@mail.ru'}
          />
          <div className={styles.block__btns}>
            <UniversalButton label={"Продолжить"} color={"primary"} size={"large"} typeButton={"submit"} />
          </div>
        </Form>
      </div>
    </>
  );
}

export default Step2;
