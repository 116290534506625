import React, { useEffect, useState } from "react";
import styles from "./AddCars.module.scss";
import CardAuto from "../CardAuto/CardAuto";
import Search from "../../Search/Search";
import { BtnIcon, BtnText } from "../../Buttons";
import { IconContainerCar, IconFilter, IconPlus, IconSort } from "../../Icons";
import Switcher from "../../Switcher/Switcher";
import { useDispatch, useSelector } from "react-redux";
import { setActiveForm } from "../../../store/slices/orderSlice";
import {
  addVehicleToOrder,
  deleteVehicleAction,
  removeVehicleFromOrder,
  setDriverFullName,
  setRegistrationNumber,
  setTrailerNumber,
  setVehicleId,
} from "../../../store/slices/vehicleSlice";
import { useForm } from "react-hook-form";
import UniversalButton from "../../Buttons/UniversalButton/UniversalButton";


export default function AddCarsList({
  title,
  filterToggle,
  selectedContainer,
  mob,
  setContList,
  data,
  createOrderSelect,
  selectedCars,
  type,
  setSelectedCars
}) {
  const {
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const { vehiclesList, vehiclesListInOrder, registrationNumber } = useSelector((state) => state.vehicle);
  const { activeForm } = useSelector((state) => state.order);
  // state switcher водитель/перевозчик
  const [driverSwitch, setDriverSwitch] = useState({
    driver: true,
    expeditor: false,
  });
  const [allClientVehicles, setAllClientVehicles] = useState([]);
  const clientId = localStorage.getItem('chosenClientId')
  const handleContainers = () => {
    // изменение состояния на открытие контейнеров
    setContList(true);
  };

  // function switcher водитель/перевозчик
  const onCheckedDriver = (isChecked) => {
    driverSwitch.driver = !isChecked;
    driverSwitch.expeditor = isChecked;
  };

  const handleAddNewVehicle = () => {
    // Нажатие на кнопку добавления нового автомобиля при открытой форме добавления
    if (activeForm === "new") {
      dispatch(setActiveForm(false));
      dispatch(setRegistrationNumber(null));
      dispatch(setTrailerNumber(null));
      dispatch(setDriverFullName(null));
    }
    // Нажатие на кнопку добавления нового автомобиля при открытой форме изменения
    else if (activeForm !== "new" && typeof activeForm === "number") {
      reset();
      dispatch(setActiveForm("new"));
      dispatch(setRegistrationNumber(null));
      dispatch(setTrailerNumber(null));
      dispatch(setDriverFullName(null));
    }
    // Нажатие на кнопку добавления нового автомобиля при закрытой форме
    else if (activeForm === false) {
      reset();
      dispatch(setActiveForm("new"));
      dispatch(setRegistrationNumber(null));
      dispatch(setTrailerNumber(null));
      dispatch(setDriverFullName(null));
    }
  };

  // ! Заглушка
  const handleFilter = () => {
  };
  // ! Заглушка
  const handleSort = () => {
  };

  useEffect(() => {
    if (activeForm === "new") {
      dispatch(setVehicleId(null));
      dispatch(setRegistrationNumber(null));
      dispatch(setTrailerNumber(null));
      dispatch(setDriverFullName(null));
    } else {
      const selectedVehicle = vehiclesListInOrder.find(el => el.id === activeForm) || vehiclesList.find(el => el.id === activeForm);
      if (selectedVehicle) {
        const { id, registration_number, trailer_number, driver } = selectedVehicle;
        dispatch(setVehicleId(id));
        dispatch(setRegistrationNumber(registration_number));
        dispatch(setTrailerNumber(trailer_number));
        dispatch(setDriverFullName(driver));
      }
    }
  }, [activeForm, vehiclesListInOrder, vehiclesList, dispatch]);

  // useEffect(() => {
  //   setAllClientVehicles(vehiclesList);
  // }, [vehiclesList]);

  return (
    <div className={styles.carsList__layout}>
      <div className={styles.carList__header}>
        <div className={styles.carList__header_top}>
          <p className={styles.carList__header_text}>{title}</p>
          {selectedCars ? (
            <></>
          ) : (
            <UniversalButton
              icon={<IconPlus size={"medium"} color={"#5C53A7"} />}
              size={'small'}
              color={"primary"}
              onClick={handleAddNewVehicle}
              type={'with-border'}
            />
          )}
        </div>
        <div className={styles.carList__header_bottom}>
        </div>
      </div>
      <div className={styles.carList__search}>
        <Search />
        <BtnIcon
          type={"button"}
          icon={<IconFilter size={"large"} color={"primary_white"} />}
          size={"p-8"}
        />
        <BtnIcon
          type={"button"}
          icon={<IconSort size={"large"} color={"primary_white"} />}
          size={"p-8"}
        />
      </div>

      <div className={styles.carList__list_overflow}>
        {selectedCars ? (
          vehiclesListInOrder?.map((el, index) => {
            return (
              <CardAuto
                key={el.id}
                createOrderSelect={createOrderSelect}
                index={el.id}
                selectedCars={selectedCars}
                onIconCrossClick={() => dispatch(removeVehicleFromOrder(el.id))}
                delAuto={() => dispatch(deleteVehicleAction({ id: el.id }))}
                registrationNumber={el.registration_number}
                trailerNumber={el.trailer_number}
                driverFullName={el.driver}
              />
            );
          })
        ) : (
          vehiclesList?.map((el, index) => {
            return (
              <CardAuto
                key={el.id}
                selectedContainer={selectedContainer}
                index={el.id}
                delAuto={() => dispatch(deleteVehicleAction({ id: el.id }))}
                onIconPlusClick={() => dispatch(addVehicleToOrder(el.id))}
                createOrderSelect={createOrderSelect}
                selectedCars={selectedCars}
                registrationNumber={el.registration_number}
                trailerNumber={el.trailer_number}
                driverFullName={el.driver}
              />
            );
          })
        )}
      </div>
      {mob ? (
        <div className={styles.step3Btns}>
          <div onClick={handleContainers} className={styles.mobContBtn}>
            <p>{data.length}</p>
            <IconContainerCar size={"large"} color={"white"} />
          </div>
          <BtnText text={"Продолжить"} size={"p-1416"} bg={"orange"} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}