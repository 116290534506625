import styles from '../AccountEmpl.module.scss';
import { handleLogOut } from '../../logOut';
import Header from '../../../../newComponents/Header/Header';
import { UniversalButton } from '../../../../components';

function AccountEmplMain({ userPermissions }) {
	return (
		<div className={styles.account__wrap}>
			<Header text={'Главная'} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}></div>
			</div>
		</div>
	);
}

export default AccountEmplMain;
