// Экспорт таблицы в excel
// //api/containers/export_excel

import axios from './configAxios';

export const getExportExcelApi = async (query, url) => {
	if (query === '' && url === '') {
		return axios.get(`export_excel/`, { responseType: 'blob' });
	} else {
		return axios.get(`export_excel/?${query}${url}`, { responseType: 'blob' });
	}
};
