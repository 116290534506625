import React from 'react';
import styles from './ActCard.module.scss';

function ActCard({ card, onClick }) {
	function formatDateTime(dateString) {
		const date = new Date(dateString);

		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear();

		return `${hours}:${minutes} ${day}/${month}/${year}`;
	}

	const dateString = card.updated_at;
	const formattedDateTime = formatDateTime(dateString);
	return (
		<div className={styles.act_main_block} onClick={onClick}>
			<div className={styles.act_left_right} />
			<div className={styles.topBlock}>
				<p className={styles.act_title_num}>Акт № {card.id}</p>
				<p>{card.status_display}</p>
			</div>
			<div className={styles.act_num_name}>
				<p>№ {card.container_number_display}</p>
				<p>{card.client_display}</p>
			</div>
			<p className={styles.act_import}>Ввоз: {formattedDateTime}</p>
			<div className={styles.act_inspection_damage}>
				<p>Осмотр: {card.actInspection}</p>
				<p>Повреждения: {!card.damages ? '-' : card.damages}</p>
			</div>
		</div>
	);
}

export default ActCard;
