import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconTelegram({ size, color }) {
  return (
    <div className={styles.icon}>
      <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 40 40" fill="none">
        <path d="M34.9049 7.91575L29.8746 33.3744C29.4948 35.1709 28.5055 35.6181 27.0994 34.7722L19.4343 28.7107L15.7363 32.5286C15.3267 32.9681 14.985 33.3348 14.1956 33.3348L14.7469 24.958L28.9519 11.1829C29.5699 10.5926 28.8174 10.2642 27.9923 10.8558L10.4309 22.7233L2.87061 20.1832C1.22639 19.6325 1.19662 18.4186 3.2135 17.5715L32.7845 5.34497C34.1537 4.79427 35.3514 5.67079 34.9049 7.91575Z" fill={IconColor(color)} />
      </svg>
    </div>
  );
}

export default IconTelegram;


