import React from 'react';
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconRestart({ size, onClick, color, className }) {
    return (
        <div className={styles.icon}>
            <svg width={IconSize(size)} height={IconSize(size)} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1147_630)">
                    <path
                        d="M2.25 3.99994C2.25 3.58573 1.91421 3.24994 1.5 3.24994C1.08579 3.24994 0.75 3.58573 0.75 3.99994H2.25ZM1.5 9.99994H0.75C0.75 10.4142 1.08579 10.7499 1.5 10.7499L1.5 9.99994ZM7.5 10.7499C7.91421 10.7499 8.25 10.4142 8.25 9.99994C8.25 9.58573 7.91421 9.24994 7.5 9.24994V10.7499ZM4.71738 14.7507C4.57974 14.3601 4.15145 14.1549 3.76078 14.2926C3.3701 14.4302 3.16498 14.8585 3.30262 15.2492L4.71738 14.7507ZM6.14 5.63995L6.65358 6.18651C6.65909 6.18133 6.66453 6.17607 6.66988 6.17073L6.14 5.63995ZM0.75 3.99994V9.99994H2.25V3.99994H0.75ZM1.5 10.7499H7.5V9.24994H1.5V10.7499ZM3.30262 15.2492C4.00505 17.2429 5.3364 18.9544 7.09608 20.1257L7.92723 18.877C6.43827 17.8859 5.31174 16.4377 4.71738 14.7507L3.30262 15.2492ZM7.09608 20.1257C8.85576 21.297 10.9484 21.8646 13.0588 21.7432L12.9726 20.2457C11.1869 20.3484 9.41619 19.8681 7.92723 18.877L7.09608 20.1257ZM13.0588 21.7432C15.1692 21.6217 17.1829 20.8177 18.7966 19.4522L17.8277 18.3072C16.4622 19.4626 14.7583 20.1429 12.9726 20.2457L13.0588 21.7432ZM18.7966 19.4522C20.4103 18.0868 21.5365 16.2339 22.0055 14.1727L20.5429 13.8399C20.146 15.584 19.1931 17.1518 17.8277 18.3072L18.7966 19.4522ZM22.0055 14.1727C22.4746 12.1116 22.261 9.95379 21.3971 8.02453L20.0281 8.63757C20.7591 10.27 20.9398 12.0958 20.5429 13.8399L22.0055 14.1727ZM21.3971 8.02453C20.5332 6.09527 19.0657 4.49903 17.2157 3.47633L16.49 4.78909C18.0554 5.65446 19.2971 7.00512 20.0281 8.63757L21.3971 8.02453ZM17.2157 3.47633C15.3657 2.45363 13.2334 2.05987 11.1402 2.35438L11.3492 3.83975C13.1204 3.59055 14.9246 3.92373 16.49 4.78909L17.2157 3.47633ZM11.1402 2.35438C9.04694 2.64889 7.10611 3.61571 5.61012 5.10917L6.66988 6.17073C7.93572 4.90703 9.57796 4.08895 11.3492 3.83975L11.1402 2.35438ZM5.62642 5.09338L0.986418 9.45337L2.01358 10.5465L6.65358 6.18651L5.62642 5.09338Z"
                        fill={IconColor(color)} />
                </g>
                <defs>
                    <clipPath id="clip0_1147_630">
                        <rect width={IconSize(size)} height={IconSize(size)} fill={IconColor(color)} transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

export default IconRestart;