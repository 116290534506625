import { useEffect, useState } from 'react';
import styles from './TerminalMainInfo.module.scss';
import MainInput from '../../../../../components/Inputs/MainInput/MainInput';
import UniversalButton from '../../../../../components/Buttons/UniversalButton/UniversalButton';
import { useMediaQuery } from 'react-responsive';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { IconArrowRight } from '../../../../../components/Icons';
import { getTerminalList, putTerminalData, setTerminalDataCreate } from '../../../../../store/slices/terminalPageSlice';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { RequireUIFunc } from '../../../../../hoc/RequireUI';
import { getMe } from '../../../../../store/slices/meSlice';

export default function TerminalMainInfo({ noBtns, create, setPage }) {
	const dispatch = useDispatch();
	const isDesktop = useMediaQuery({ minWidth: '1279.9px' });
	const isCreateRoute = '/accountEmpl/createTerminal';

	const [nameTerminal, setNameTerminal] = useState('');
	const [addressTerminal, setAddressTerminal] = useState('');
	const [сapacityTerminal, setСapacityTerminal] = useState('');
	const [phoneTerminal, setPhoneTerminal] = useState('');
	const [open, setOpen] = useState(false);

	const {
		register,
		handleSubmit,
		setValue,
		setFocus,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			nameTerminal: '',
			addressTerminal: '',
			capacityTerminal: '',
			phoneTerminal: '',
		},
	});

	const updatedData = {
		name: nameTerminal,
		address: addressTerminal,
		max_teu: сapacityTerminal,
		contact_number: phoneTerminal,
	};

	const {
		currentTerminalId,
		currentTerminalData,
		putTerminalData_success,
		postTerminalData_success,
		putTerminalData_loading,
		putTerminalData_error,
		terminalData,
	} = useSelector((state) => state.terminalPage);

	const permByChangeTerminal = !RequireUIFunc({ name: 'ChangeTerminals', terminal_id: currentTerminalId }); //право на изменение терминала
	const permByCreateTerminal = !RequireUIFunc({ name: 'AddTerminals' }); //право на изменение терминала

	const handleClose = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const onSubmit = async (data) => {
		if (window.location.pathname !== isCreateRoute) {
			dispatch(putTerminalData({ id: currentTerminalId, requestData: updatedData }));
			setOpen(true);
		} else {
			dispatch(setTerminalDataCreate(updatedData));
			setPage(1);
		}
	};

	const handleFieldNameTerminalChange = (e) => {
		setNameTerminal(e.target.value);
	};
	const handleFieldAddressTerminalChange = (e) => {
		setAddressTerminal(e.target.value);
	};
	const handleFieldСapacityTerminalChange = (e) => {
		setСapacityTerminal(e.target.value);
	};
	const handleFieldPhoneTerminalChange = (e) => {
		setPhoneTerminal(e.target.value);
	};

	// useEffect(() => {
	// 	if (window.location.pathname !== isCreateRoute) {
	// 		dispatch(getTerminalList());
	// 	}
	// }, [dispatch]);

	//доп запрос на ми после добавления нового терминала
	useEffect(() => {
		if (window.location.pathname !== isCreateRoute) {
			dispatch(getMe());
		}
	}, [postTerminalData_success]);

	useEffect(() => {
		if (window.location.pathname !== isCreateRoute) {
			setValue('nameTerminal', currentTerminalData?.name);
			setValue('addressTerminal', currentTerminalData?.address);
			setValue('capacityTerminal', currentTerminalData?.max_teu);
			setValue('phoneTerminal', currentTerminalData?.contact_number);
			setNameTerminal(currentTerminalData?.name);
			setAddressTerminal(currentTerminalData?.address);
			setСapacityTerminal(currentTerminalData?.max_teu);
			setPhoneTerminal(currentTerminalData?.contact_number);
		} else {
			setValue('nameTerminal', terminalData?.name);
			setValue('addressTerminal', terminalData?.address);
			setValue('capacityTerminal', terminalData?.max_teu);
			setValue('phoneTerminal', terminalData?.contact_number);
			setNameTerminal(terminalData?.name);
			setAddressTerminal(terminalData?.address);
			setСapacityTerminal(terminalData?.max_teu);
			setPhoneTerminal(terminalData?.contact_number);
		}
	}, [currentTerminalData]);

	return (
		<div className={styles.TMI_Container}>
			{putTerminalData_success && (
				<Snackbar
					open={open}
					onClose={handleClose}
					autoHideDuration={3000}
					anchorOrigin={!isDesktop ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'left' }}
				>
					<Alert severity="success" sx={{ width: '100%' }}>
						Терминал успешно обновлен
					</Alert>
				</Snackbar>
			)}
			<div className={styles.MainData}>
				<p className={styles.blocks_title}>Основные данные</p>
				<form action="" onSubmit={handleSubmit(onSubmit)} className={styles.TMI_Form} autoComplete="off">
					<div className={styles.TMI_Form_inputs}>
						<MainInput
							title={'Название терминала'}
							errorTitle={'Некорректное название терминала'}
							name="nameTerminal"
							register={register}
							value={nameTerminal ? nameTerminal : ''}
							handleFieldChange={handleFieldNameTerminalChange}
							setValue={setValue}
							setFocus={setFocus}
							errors={errors}
							rules={{
								required: window.location.pathname === isCreateRoute ? 'Обязательное поле' : undefined,
								minLength: {
									value: 2,
									message: 'Минимум 2 символа',
								},
							}}
							placeholder={'Пример: Северный'}
							disabled={create ? permByCreateTerminal : permByChangeTerminal}
						/>
						<MainInput
							title={'Адрес терминала'}
							errorTitle={'Некорректный адрес терминала'}
							name="addressTerminal"
							register={register}
							value={addressTerminal ? addressTerminal : ''}
							handleFieldChange={handleFieldAddressTerminalChange}
							setValue={setValue}
							setFocus={setFocus}
							errors={errors}
							rules={
								{
									// required: 'Обязательное поле',
									// minLength: {
									//   value: 6,
									//   message: 'Минимум 6 символов',
									// },
								}
							}
							placeholder={'Симферопольское ш., 22, строение 1, Москва, 142170'}
							disabled={create ? permByCreateTerminal : permByChangeTerminal}
						/>
						<MainInput
							title={'Вместимость терминала (TEU)'}
							errorTitle={'Некорректная вместимость терминала'}
							name="capacityTerminal"
							register={register}
							value={сapacityTerminal ? сapacityTerminal : ''}
							handleFieldChange={handleFieldСapacityTerminalChange}
							setValue={setValue}
							setFocus={setFocus}
							errors={errors}
							rules={{
								required: window.location.pathname === isCreateRoute ? 'Обязательное поле' : undefined,
								minLength: {
									value: 1,
									message: 'Минимум 1 символов',
								},
								pattern: {
									value: /^\d+$/,
									message: 'Только цифры',
								},
							}}
							placeholder={'Например: 2000'}
							disabled={create ? permByCreateTerminal : permByChangeTerminal}
						/>
						<MainInput
							title={'Номер телефона'}
							errorTitle={'Некорректный номер телефона'}
							name="phoneTerminal"
							register={register}
							value={phoneTerminal ? phoneTerminal : ''}
							handleFieldChange={handleFieldPhoneTerminalChange}
							setValue={setValue}
							setFocus={setFocus}
							errors={errors}
							rules={{
								// required: 'Обязательное поле',
								minLength: {
									value: 10,
									message: 'Минимум 10 символов',
								},
								pattern: {
									value: /^(\+\d{1,2}\s?)?(\(\d{1,4}\)|\d{1,4})([\s.-]?\d{1,12})$/,
									message: 'Введите номер телефона в формате 89324706777',
								},
							}}
							placeholder={'8 (901) 546-91-91'}
							disabled={create ? permByCreateTerminal : permByChangeTerminal}
						/>
						<div className={styles.actionContainer}>
							{create && (
								<UniversalButton
									label={'Продолжить'}
									color={'accent'}
									size={'medium'}
									icon={<IconArrowRight size={'largeS'} color={'white'} />}
								/>
							)}
						</div>
					</div>
					{noBtns ? (
						<></>
					) : (
						<div className={styles.actionContainer}>
							<UniversalButton label={'Сохранить'} color={'accent'} size={'large'} />
						</div>
					)}
				</form>
			</div>
		</div>
	);
}
