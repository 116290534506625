import React from 'react';
import { IconSize, IconColor } from './styleIcon.js';
import styles from './styleIcon.module.scss';

function IconHome({ size, onClick, color, className }) {
  return (
    <div onClick={onClick} className={`${styles.icon} ${className}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width={IconSize(size)} height={IconSize(size)} viewBox="0 0 19.5 21.5">
        <path fill={IconColor(color)} d="M3,9l-.46-.59A.75.75,0,0,0,2.25,9Zm9-7,.46-.59a.75.75,0,0,0-.92,0Zm9,7h.75a.75.75,0,0,0-.29-.59ZM3,20H3Zm5.25,2a.75.75,0,0,0,1.5,0ZM9,12v-.75a.76.76,0,0,0-.75.75Zm6,0h.75a.76.76,0,0,0-.75-.75Zm-.75,10a.75.75,0,0,0,1.5,0ZM3.46,9.59l9-7-.92-1.18-9,7Zm8.08-7,9,7,.92-1.18-9-7ZM20.25,9V20h1.5V9Zm0,11a1.22,1.22,0,0,1-.37.88l1.06,1.06A2.72,2.72,0,0,0,21.75,20Zm-.37.88a1.22,1.22,0,0,1-.88.37v1.5a2.72,2.72,0,0,0,1.94-.81Zm-.88.37H5v1.5H19Zm-14,0a1.22,1.22,0,0,1-.88-.37L3.06,21.94A2.72,2.72,0,0,0,5,22.75Zm-.88-.37A1.22,1.22,0,0,1,3.75,20H2.25a2.72,2.72,0,0,0,.81,1.94ZM3.75,20V9H2.25V20Zm6,2V12H8.25V22ZM9,12.75h6v-1.5H9ZM14.25,12V22h1.5V12Z" transform="translate(-2.25 -1.25)" />
      </svg>
    </div>
  );
}

export default IconHome;