import React, { useEffect, useState, forwardRef } from 'react';

import style from './style.module.scss';
import { Autocomplete, TextField, CircularProgress, Button, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const StyledPopper = styled('div')({
	zIndex: 9110,
	background: '#ffffff',
	borderRadius: '8px',
	'& .MuiPaper-root': {
		backgroundColor: '#ffffff',
		color: '#000000',
	},
});

const StyledListbox = styled('ul')({
	padding: 0,
	margin: 0,
	listStyleType: 'none',
	'&:hover': {
		background: '#5c53a7',
	},
});

const StyledOption = styled('li')({
	padding: '8px 10px',
	cursor: 'pointer',
	color: '#000000',
	fontWeight: '300',
	fontSize: '16px',
	lineHeight: '20px',
	fontFamily: 'Montserrat',
	letterSpacing: '0.035px',
	background: '#ffffff',
});

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
	return (
		<StyledPopper {...props}>
			<StyledListbox {...props} ref={ref} />
		</StyledPopper>
	);
});

AutoCompleteSearch.propTypes = {
	multiple: PropTypes.bool,
	title: PropTypes.string,
	data: PropTypes.array,
	objectKey: PropTypes.string,
	handleInputChange: PropTypes.func,
	handleOptionChange: PropTypes.func,
	handleIfNoOptions: PropTypes.func,
	noOptionsTitle: PropTypes.string,
	pending_state: PropTypes.bool,
	success_state: PropTypes.bool,
	choosenOption: PropTypes.object,
};

/**
 * Селект с динамическим поиском
 * @component AutoCompleteSearch
 * @param {boolean} multiple - Условие для обнуления первоначального значения на случай редактирования нескольких записей
 * @param {string} title - Заголовок для поля ввода
 * @param {Array} data - Данные для списка в формате объекта {id:1, title: 'Вариант 1'}, где id - обязательно, а title может быть чем угодно
 * @param {string} objectKey - Ключ объекта, по которуму будут отображаться значения в списке (нужен тот который в data идет вторым)
 * @param {function} handleInputChange - Функция, которая отрабатывает при изменении value в инпуте (в данном компоненте динамический поиск)
 * @param {function} handleOptionChange - Функция, которая отрабатывает при выборе конкретной опции из представленного списка
 * @param {function} handleIfNoOptions - Функция, которая выполняет определенное действие если совпадения не найдены
 * @param {string} noOptionsTitle-  Текст кнопки в случае отсутствия совпадений
 * @param {boolean} pending_state - State из Redux (thunk.pending) или useState, который отвечает за отслеживание загрузки запроса (getState_pending)
 * @param {boolean} success_state - State из Redux (thunk.fulfilled) или useState, который отвечает за успешность загрузки запроса (getState_fulfilled)
 * @param {object} choosenOption - state из redux или useState с выбранной опцией. Обязательная проверка на наличие значений ключей типа: passInfo.passVehicleId && passInfo.passVehicle ? { id: passInfo.passVehicleId, registration_number: passInfo.passVehicle } : null
 * @param {string} placeholder - плейсхолдер
 * @param {boolean} freeSolo - для mui, по умолчанию лучше ставить false. FreeSolo отвечает за отображение кнопки в случае отсутствия совпадений
 */

export default function AutoCompleteSearch({
	multiple,
	title,
	data,
	objectKey,
	handleInputChange,
	handleOptionChange,
	handleIfNoOptions,
	noOptionsTitle,
	pending_state,
	success_state,
	choosenOption,
	placeholder,
	freeSolo,
}) {
	const [openList, setOpenList] = useState(false);
	const [options, setOptions] = useState([]);

	const [localValue, setLocalValue] = useState(!multiple ? choosenOption : null);
	const [localInputValue, setLocalInputValue] = useState('');

	useEffect(() => {
		setLocalValue(choosenOption);
	}, [choosenOption]);

	const onInputChange = (event, newInputValue) => {
		setLocalInputValue(newInputValue);
		handleInputChange(event, newInputValue);
	};
	const onOptionChange = (event, newValue) => {
		setLocalValue(newValue);
		handleOptionChange(event, newValue);
	};

	useEffect(() => {
		if (!openList) {
			setOptions([]);
		}
	}, [openList]);

	useEffect(() => {
		setOptions(data);
	}, [success_state, openList]);

	return (
		<div className={style.wrapper}>
			<div className={style.title_block}>
				<span>{title}</span>
			</div>
			<Autocomplete
				// freeSolo={freeSolo ? freeSolo : false}
				value={localValue}
				onChange={onOptionChange}
				inputValue={localInputValue}
				onInputChange={onInputChange}
				id="asynchronous-demo"
				sx={{
					cursor: 'pointer',
					height: '36px',
					width: '100%',
					display: 'flex',
					gap: '8px',
					alignItems: 'center',
					background: '#5c53a7',
					borderRadius: '8px',
					transition: '0.2s linear',
					'& .MuiPaper-elevation': {
						background: '#ffffff',
					},
				}}
				open={openList}
				onOpen={() => {
					setOpenList(true);
				}}
				onClose={() => {
					setOpenList(false);
				}}
				// isOptionEqualToValue={(option, value) => option?.id === value?.id || value === null}
				getOptionLabel={(option) => (option ? option[objectKey] : '')}
				options={options}
				loading={pending_state}
				loadingText={
					<Box
						sx={{
							color: '#000000',
						}}
					>
						Загрузка...
					</Box>
				}
				noOptionsText={
					openList && success_state && !freeSolo && data.length === 0 ? (
						<Button
							variant="outlined"
							sx={{
								padding: '8px 10px 8px 6px',
								width: ['-moz-fit-content', 'fit-content'],
								borderRadius: '8px',
								background: '#5c53a7',
								cursor: 'pointer',
								transition: '0.3s',
								display: 'flex',
								flexDirection: 'row-reverse',
								alignItems: 'center',
								color: '#000000',
								fontWeight: '300',
								fontSize: '16px',
								lineHeight: '20px',
								fontFamily: 'Montserrat',
								letterSpacing: '0.035px',
								border: 'none',
								justifyContent: 'space-between',
								gap: '4px',
								textTransform: 'none',
								'&:hover': {
									background: '#7b70c2',
									border: 'none',
								},
							}}
							onClick={() => handleIfNoOptions()}
						>
							{noOptionsTitle ? noOptionsTitle : 'Нет совпадений'}
						</Button>
					) : (
						<p style={{ color: '#000000' }}>Нет совпадений</p>
					)
				}
				PopperComponent={(props) =>
					openList ? (
						<StyledPopper
							{...props}
							sx={{
								background: '#5c53a7',
								border: 'none',
								position: 'absolute',
								'& .MuiAutocomplete-listbox': { padding: '0', margin: '5px 0px' },
							}}
						/>
					) : null
				}
				ListboxComponent={ListboxComponent}
				renderOption={(props, option) => <StyledOption {...props}>{option[objectKey]}</StyledOption>}
				renderInput={(params) => (
					<TextField
						sx={{
							'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
								borderColor: '#443A8E',
							},
							'& .MuiOutlinedInput-root': {
								height: '36px',
								borderRadius: '8px',
								padding: '10px',
								fontWeight: '300',
								fontSize: '16px',
								lineHeight: '20px',
								fontFamily: 'Montserrat',
								letterSpacing: '0.035px',
								color: choosenOption ? '#443A8E' : 'black',
								background: choosenOption ? '#e4dfff' : '#fffbff',

								'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
									border: openList ? '1px solid orange' : '1px solid #443A8E',
								},
							},
							'& .MuiOutlinedInput-notchedOutline': {
								top: '-10px',
								border: choosenOption ? '1px solid #443A8E' : '1px solid #7b70c2',
							},
							'& .MuiAutocomplete-input': {
								border: 'none',
								position: 'relative',

								'&.Mui-focused': {
									color: 'transparent',
									border: 'none',
								},
							},
							'& .MuiAutocomplete-popupIndicator': {
								border: 'none',
							},
							'& .MuiAutocomplete-clearIndicator': {
								border: 'none',
								display: 'none',
							},
							'& input::placeholder': {
								color: '#000000',
								fontWeight: '300',
								fontSize: '16px',
								lineHeight: '20px',
								fontFamily: 'Montserrat',
								letterSpacing: '0.035px',
								opacity: 1,
							},
							borderRadius: '8px',
							cursor: 'pointer',
							transition: '0.3s',
							display: 'flex',
							flexDirection: 'row-reverse',
							alignItems: 'center',
							border: 'none',
							justifyContent: 'space-between',
							gap: '4px',
						}}
						{...params}
						placeholder={`${placeholder}`}
						onChange={handleInputChange}
						InputProps={{
							...params.InputProps,

							sx: {
								cursor: 'pointer',
								width: '100%',
								backgroundColor: '#fffbff',
								borderRadius: '8px',
								transition: '0.2s linear',
								position: 'relative',
							},
							endAdornment: (
								<>
									{pending_state ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
			/>
		</div>
	);
}
