import React, { useState } from 'react';
import UniversalBlock from '../components/UniversalBlock';
import styles from './SideStyle.module.scss';
import { isBlockChoosen } from './helpers/isBlockChoosen';
import { useSelector } from 'react-redux';
import UniversalAdditionalBlock from '../components/UniversalAdditionalBlock';
import { panelNameToPanelAvailableCodes } from './helpers/panelNameToPanelAvailableCodes';
import { sideTypeToName } from './helpers/sideTypeToName';
import UniversalDetailBlock from '../components/UniversalDetailBlock';
import { isDetailDamaged } from './helpers/isDetailDamaged';

function FrontEndWithDoor() {
	const { damagesList, selectedSide, damagedDetails } = useSelector((state) => state.containerAccept);
	const { placement_code_description } = useSelector((state) => state.containerAccept.selectedSide);
	return (
		<div className={styles.mainBlock_new}>
			<div className={styles.mapRowNumber_new}>
				<p className={styles.topNumber_new}></p>
				{placement_code_description[0].parts.map((part) => (
					<p key={part} className={styles.topNumber_new}>
						{part}
					</p>
				))}
			</div>
			{placement_code_description.map(({ option, parts }) => (
				<div key={option} className={styles.mapRow_new}>
					<p className={styles.sideLabel_new}>{option}</p>
					{parts.map((part, index) => (
						<UniversalBlock
							key={index}
							type={getType(option, part)}
							sideIndex={selectedSide.code}
							sideName={sideTypeToName(selectedSide?.code)}
							divider={option}
							part={part.toString()}
							partCode={panelNameToPanelAvailableCodes(selectedSide?.components, selectedSide?.code, option, part)}
							choosen={isBlockChoosen(damagesList, selectedSide?.code, option, part.toString())}
						/>
					))}
					{/* Левые петли */}
					<div className={styles.doorHingesL}>
						<UniversalDetailBlock
							type={'doorHingeL1'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'TB'}
							part={'Left_top_hinge'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_top_hinge')}
						/>
						<UniversalDetailBlock
							type={'doorHingeL2'}
							sideIndex={'D'}
							sideName={'Торец c дверью'}
							divider={'TB'}
							part={'Left_center_top_hinge'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_center_top_hinge')}
						/>
						<UniversalDetailBlock
							type={'doorHingeL3'}
							sideIndex={'D'}
							sideName={'Торец c дверью'}
							divider={'TB'}
							part={'Left_center_bottom_hinge'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_center_bottom_hinge')}
						/>
						<UniversalDetailBlock
							type={'doorHingeL4'}
							sideIndex={'D'}
							sideName={'Торец c дверью'}
							divider={'TB'}
							part={'Left_bottom_hinge'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_bottom_hinge')}
						/>
					</div>
					{/* Правые петли */}
					<div className={styles.doorHingesR}>
						<UniversalDetailBlock
							type={'doorHingeR1'}
							sideIndex={'D'}
							sideName={'Торец c дверью'}
							divider={'TB'}
							part={'Right_top_hinge'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_top_hinge')}
						/>
						<UniversalDetailBlock
							type={'doorHingeR2'}
							sideIndex={'D'}
							sideName={'Торец c дверью'}
							divider={'TB'}
							part={'Right_center_top_hinge'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_center_top_hinge')}
						/>
						<UniversalDetailBlock
							type={'doorHingeR3'}
							sideIndex={'D'}
							sideName={'Торец c дверью'}
							divider={'TB'}
							part={'Right_center_botton_hinge'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_center_botton_hinge')}
						/>
						<UniversalDetailBlock
							type={'doorHingeR4'}
							sideIndex={'D'}
							sideName={'Торец c дверью'}
							divider={'TB'}
							part={'Right_bottom_hinge'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_bottom_hinge')}
						/>
					</div>
					{/* Ручки */}
					<div className={styles.handlers}>
						<UniversalDetailBlock
							type={'handle'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'G'}
							part={'Left_end_handle'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_end_handle')}
						/>
						<UniversalDetailBlock
							type={'handle'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'G'}
							part={'Left_center_handle'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_center_handle')}
						/>
						<UniversalDetailBlock
							type={'handle'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'G'}
							part={'Right_center_handle'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_center_handle')}
						/>
						<UniversalDetailBlock
							type={'handle'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'G'}
							part={'Right_end_handle'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_end_handle')}
						/>
					</div>
					{/* Штанги */}
					<div className={styles.rods}>
						<UniversalDetailBlock
							type={'rod'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'G'}
							part={'Left_end_rod'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_end_rod')}
						/>
						<UniversalDetailBlock
							type={'rod'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'G'}
							part={'Left_center_rod'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_center_rod')}
						/>
						<UniversalDetailBlock
							type={'rod'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'G'}
							part={'Right_center_rod'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_center_rod')}
						/>
						<UniversalDetailBlock
							type={'rod'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'G'}
							part={'Right_end_rod'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_end_rod')}
						/>
					</div>
					{/* Кулачки верхние */}
					<div className={styles.rodCamsH}>
						<UniversalDetailBlock
							type={'rodCamH1'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'H'}
							part={'Left_end_top_cam'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_end_top_cam')}
						/>
						<UniversalDetailBlock
							type={'rodCamH2'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'H'}
							part={'Left_center_top_cam'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_center_top_cam')}
						/>
						<UniversalDetailBlock
							type={'rodCamH3'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'H'}
							part={'Right_center_top_cam'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_center_top_cam')}
						/>
						<UniversalDetailBlock
							type={'rodCamH4'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'H'}
							part={'Right_end_top_cam'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_end_top_cam')}
						/>
					</div>
					{/* Кулачки нижние */}
					<div className={styles.rodCamsL}>
						<UniversalDetailBlock
							type={'rodCamL1'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'H'}
							part={'Left_end_bottom_cam'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_end_bottom_cam')}
						/>
						<UniversalDetailBlock
							type={'rodCamL2'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'H'}
							part={'Left_center_bottom_cam'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_center_bottom_cam')}
						/>
						<UniversalDetailBlock
							type={'rodCamL3'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'H'}
							part={'Right_center_bottom_cam'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_center_bottom_cam')}
						/>
						<UniversalDetailBlock
							type={'rodCamL4'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'H'}
							part={'Right_end_bottom_cam'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_end_bottom_cam')}
						/>
					</div>
					{/* Кулачки нижние */}
					<div className={styles.rodCamsL}>
						<UniversalDetailBlock
							type={'rodCamL1'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'H'}
							part={'Left_end_bottom_cam'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_end_bottom_cam')}
						/>
						<UniversalDetailBlock
							type={'rodCamL2'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'H'}
							part={'Left_center_bottom_cam'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_center_bottom_cam')}
						/>
						<UniversalDetailBlock
							type={'rodCamL3'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'H'}
							part={'Right_center_bottom_cam'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_center_bottom_cam')}
						/>
						<UniversalDetailBlock
							type={'rodCamL4'}
							sideIndex={'D'}
							sideName={'Торец с дверью'}
							divider={'H'}
							part={'Right_end_bottom_cam'}
							choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_end_bottom_cam')}
						/>
					</div>
				</div>
			))}
			{/* Уплотнители */}
			<div className={styles.seals}>
				<UniversalDetailBlock
					type={'sealL'}
					sideIndex={'D'}
					sideName={'Торец с дверью'}
					divider={'TB'}
					part={'Left_seal'}
					choosen={isDetailDamaged(damagesList, damagedDetails, 'Left_seal')}
				/>
				<UniversalDetailBlock
					type={'sealR'}
					sideIndex={'D'}
					sideName={'Торец с дверью'}
					divider={'TB'}
					part={'Right_seal'}
					choosen={isDetailDamaged(damagesList, damagedDetails, 'Right_seal')}
				/>
			</div>
		</div>
	);
}
function getType(option, part) {
	if (option === 'H' && part === 1) {
		return 'angular-HL';
	} else if (option === 'H' && part === 4) {
		return 'angular-HR';
	} else if (option === 'G' && part === 1) {
		return 'angular-BL';
	} else if (option === 'G' && part === 4) {
		return 'angular-BR';
	} else if (option === 'H' && (part === 2 || part === 3)) {
		return `beam-horizontal-H`;
	} else if (option === 'G' && (part === 2 || part === 3)) {
		return `beam-horizontal-B`;
	} else if ((option === 'T' && part === 1) || (option === 'B' && part === 1)) {
		return 'beam-vertical-L';
	} else if ((option === 'T' && part === 4) || (option === 'B' && part === 4)) {
		return 'beam-vertical-R';
	} else {
		return 'mainPanel';
	}
}
export default FrontEndWithDoor;
