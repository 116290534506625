import React from "react";
import { IconSize, IconColor } from "./styleIcon.js";
import styles from "./styleIcon.module.scss";

function IconDownload({ onClick, size, color }) {
    return (
        <div className={styles.icon} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg"
                width={IconSize(size)}
                height={IconSize(size)}
                viewBox="0 0 16 16"
                fill="none">
                <path d="M14.5 10C14.5 9.72386 14.2761 9.5 14 9.5C13.7239 9.5 13.5 9.72386 13.5 10H14.5ZM2 12.6667H1.5H2ZM2.5 10C2.5 9.72386 2.27614 9.5 2 9.5C1.72386 9.5 1.5 9.72386 1.5 10H2.5ZM5.02022 6.31311C4.82496 6.11785 4.50838 6.11785 4.31311 6.31311C4.11785 6.50838 4.11785 6.82496 4.31311 7.02022L5.02022 6.31311ZM8 10L7.64645 10.3536C7.84171 10.5488 8.15829 10.5488 8.35355 10.3536L8 10ZM11.6869 7.02022C11.8821 6.82496 11.8821 6.50838 11.6869 6.31311C11.4916 6.11785 11.175 6.11785 10.9798 6.31311L11.6869 7.02022ZM8.5 2C8.5 1.72386 8.27614 1.5 8 1.5C7.72386 1.5 7.5 1.72386 7.5 2H8.5ZM13.5 10V12.6667H14.5V10H13.5ZM13.5 12.6667C13.5 12.8877 13.4122 13.0996 13.2559 13.2559L13.963 13.963C14.3068 13.6192 14.5 13.1529 14.5 12.6667H13.5ZM13.2559 13.2559C13.0996 13.4122 12.8877 13.5 12.6667 13.5V14.5C13.1529 14.5 13.6192 14.3068 13.963 13.963L13.2559 13.2559ZM12.6667 13.5H3.33333V14.5H12.6667V13.5ZM3.33333 13.5C3.11232 13.5 2.90036 13.4122 2.74408 13.2559L2.03697 13.963C2.38079 14.3068 2.8471 14.5 3.33333 14.5V13.5ZM2.74408 13.2559C2.5878 13.0996 2.5 12.8877 2.5 12.6667H1.5C1.5 13.1529 1.69315 13.6192 2.03697 13.963L2.74408 13.2559ZM2.5 12.6667V10H1.5V12.6667H2.5ZM4.31311 7.02022L7.64645 10.3536L8.35355 9.64645L5.02022 6.31311L4.31311 7.02022ZM8.35355 10.3536L11.6869 7.02022L10.9798 6.31311L7.64645 9.64645L8.35355 10.3536ZM8.5 10V2H7.5V10H8.5Z"
                    fill={IconColor(color)}
                />
            </svg>
        </div>
    );
}

export default IconDownload;