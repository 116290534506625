// Экспорт таблицы в excel
// //api/entries/export_excel

import axios from './configAxios';

export const getExportExcelEntriesApi = async (query, url) => {
	if (query === '' && url === '') {
		return axios.get(`export/`, { responseType: 'blob' });
	} else {
		return axios.get(`export/?${query}${url}`, { responseType: 'blob' });
	}
};
