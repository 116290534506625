import styles from './Benefits.module.scss';

function Benefits() {
	return (
		<div className={styles.benefits}>
			<p className={styles.benefits__title}>гарантируем</p>
			<ul className={styles.benefits__list}>
				<li className={styles.benefits__item}>
					<div className={styles.item__figure1}>
						<div className={styles.transparentSquare} />
						<div className={styles.redSquare} />
						<div className={styles.violetSquare} />
						<div className={styles.redSquare} />
						<div className={styles.violetSquare} />
						<div className={styles.transparentSquare} />
					</div>
					<p>Пунктуальность</p>
				</li>
				<li className={styles.benefits__item}>
					<div className={styles.item__figure2}>
						<div className={styles.transparentSquare} />
						<div className={styles.redSquare} />
						<div className={styles.transparentSquare} />
						<div className={styles.violetSquare} />
						<div className={styles.redSquare} />
						<div className={styles.violetSquare} />
					</div>
					<p>Конфиденциальность</p>
				</li>
				<li className={styles.benefits__item}>
					<div className={styles.item__figure3}>
						<div className={styles.violetSquare} />
						<div className={styles.redSquare} />
						<div className={styles.redSquare} />
						<div className={styles.violetSquare} />
					</div>
					<p>Надежность операций</p>
				</li>
				<li className={styles.benefits__item}>
					<div className={styles.item__figure4}>
						<div className={styles.transparentSquare} />
						<div className={styles.transparentSquare} />
						<div className={styles.redSquare} />
						<div className={styles.violetSquare} />
						<div className={styles.redSquare} />
						<div className={styles.violetSquare} />
					</div>
					<p>Круглосуточный сервис</p>
				</li>
			</ul>
		</div>
	);
}

export default Benefits;
