const initialState = {
    permissionsList: []
};

const permissionsListReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PERMISSIONS_SUCCESS':
            return { ...state, permissionsList: action.payload }
        default:
            return state;
    }
};

export default permissionsListReducer;