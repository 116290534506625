import { generateBackground, getInitials } from '../../../../avatarsUtils';
import ModalClientEmplInfo from './ModalClientEmplInfo';
import styles from '../Modals.module.scss';
import { useForm } from 'react-hook-form';
import {
	getUser,
	setCurrentUserId,
	setCurrentUser,
	setInputChange,
	setNewEmail,
	setNewFullName,
	setNewGroup,
	setNewPassword,
	setNewTelegram,
	setNewPhone,
	setNewUserName,
	setNewWhatsapp,
	setSelectedId,
	deleteUser,
} from '../../../../../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { UniversalButton } from '../../../../../../components';
import { IconDisketa, IconTrash } from '../../../../../../components/Icons';
import {
	postCreateWorker,
	putWorkerOrg,
	setWorkerListAfterDelete,
	setWorkerListAfterPut,
} from '../../../../../../store/slices/clientSlice';
import { useParams } from 'react-router-dom';
import { setCurrentElementForSideBar, setNon_activeWrapper, setSideBarActive } from '../../../../../../store/slices/sideBarSlice';

function ModalClientEmpl({ employer }) {
	const dispatch = useDispatch();
	let { clientId } = useParams();
	const {
		register,
		setFocus,
		setValue,
		formState: { errors },
		clearErrors,
		handleSubmit,
	} = useForm({
		mode: 'onChange',
	});

	const { currentUser, userGroups, inputChange, newFullName, newEmail, newPassword, newTelegram, newPhone, newWhatsapp } = useSelector(
		(state) => state.user,
	);

	useEffect(() => {
		if (employer !== '') {
			dispatch(setCurrentUserId(employer.id));
			dispatch(getUser({ user_id: employer.id }));
		}
	}, [employer]);

	useEffect(() => {
		dispatch(setNewFullName(currentUser?.full_name));
		dispatch(setNewUserName(currentUser?.username));
		dispatch(setNewEmail(currentUser?.email));
		dispatch(setNewGroup(currentUser?.group));
		dispatch(setNewPassword(currentUser?.password));
		dispatch(setNewWhatsapp(currentUser?.whatsapp));
		dispatch(setNewTelegram(currentUser?.telegram));
		dispatch(setNewPhone(currentUser?.phone));
		const currentGroupId = userGroups?.find((el) => el.name === currentUser?.group);
		dispatch(setSelectedId(currentGroupId?.id));
		dispatch(setInputChange(false));
	}, [currentUser, dispatch, userGroups]);

	// Создание работника
	const handleCreateUser = () => {
		let requestData = {};
		requestData.full_name = newFullName;
		requestData.email = newEmail;
		requestData.password = newPassword;
		requestData.phone = newPhone;
		requestData.telegram = newTelegram;
		requestData.whatsapp = newWhatsapp;
		requestData.org = Number(clientId);

		requestData.is_confirmed = true;
		requestData.user_type = 2;

		dispatch(postCreateWorker({ requestData }));
		dispatch(setCurrentUser(null));
		dispatch(setCurrentUserId());
		dispatch(setSideBarActive(false));
	};

	// Изменение работника
	const handlePutUser = () => {
		let requestData = {};

		if (currentUser?.full_name !== newFullName) {
			requestData['full_name'] = newFullName;
		}

		if (currentUser?.email !== newEmail) {
			requestData['email'] = newEmail;
		}

		if (currentUser?.password !== newPassword) {
			requestData['password'] = newPassword;
		}

		if (currentUser?.phone !== newPhone) {
			requestData['phone'] = newPhone;
		}

		if (currentUser?.telegram !== newTelegram) {
			requestData['telegram'] = newTelegram;
		}

		if (currentUser?.whatsapp !== newWhatsapp) {
			requestData['whatsapp'] = newWhatsapp;
		}
		dispatch(setSideBarActive(false));
		dispatch(setCurrentUser(null));
		dispatch(setCurrentElementForSideBar(null));
		dispatch(setNon_activeWrapper(false));
		dispatch(putWorkerOrg({ user_id: employer?.id, requestData }));
		dispatch(
			setWorkerListAfterPut({
				updatedUser: {
					full_name: newFullName,
					email: newEmail,
					password: newPassword,
					phone: newPhone,
					telegram: newTelegram,
					whatsapp: newWhatsapp,
				},
				user_id: employer.id,
			}),
		);
	};

	// Удаление работника
	const handleDeleteUser = () => {
		dispatch(deleteUser({ user_id: employer?.id }));
		dispatch(setSideBarActive(false));
		dispatch(setCurrentUser(null));
		dispatch(setCurrentElementForSideBar(null));
		dispatch(setNon_activeWrapper(false));
		dispatch(setWorkerListAfterDelete({ user_id: employer.id }));
	};

	return (
		<div className={styles.modal__card}>
			<div className={styles.MainCardInfo}>
				<div className={styles.card__user}>
					{employer == '' ? (
						<div className={styles.card__userName}>
							<div className={styles.card__info}>
								<span>Новый сотрудник</span>
							</div>
						</div>
					) : (
						<div className={styles.card__userName}>
							<div
								className={styles.card__img}
								style={{
									background: generateBackground(currentUser?.full_name),
								}}
							>
								{getInitials(currentUser?.full_name)}
							</div>
							<div className={styles.card__info}>
								<span>{currentUser?.full_name}</span>
								<p>{currentUser?.group}</p>
							</div>
						</div>
					)}
				</div>
				<ModalClientEmplInfo
					handleCreateUser={handleCreateUser}
					handlePutUser={handlePutUser}
					register={register}
					setFocus={setFocus}
					setValue={setValue}
					errors={errors}
					clearErrors={clearErrors}
					handleSubmit={handleSubmit}
				/>
			</div>

			{employer == '' ? (
				<div className={styles.card__btns}>
					<UniversalButton label={'Добавить'} color={'succesful'} size={'small'} onClick={handleCreateUser} />
				</div>
			) : (
				<div className={styles.card__btns}>
					<UniversalButton icon={<IconTrash color={'white'} />} color={'error'} size={'small'} onClick={handleDeleteUser} />
					<UniversalButton
						icon={<IconDisketa color={'white'} />}
						label={'Сохранить изменения'}
						color={'succesful'}
						size={'small'}
						// disabled={inputChange ? false : true} //пока выключил
						onClick={handlePutUser}
					/>
				</div>
			)}
		</div>
	);
}

export default ModalClientEmpl;
