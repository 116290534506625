import React from 'react';
import { BtnBg, BtnSize } from './styleBtnIcon.js';
import './styleBtnIcon.scss';

function BtnIcon({ disabled, icon, onClick, bg, size, type, custom }) {
	return (
		<button
			type={type}
			disabled={disabled}
			className={`${'btnIcon'} ${custom ? 'custom' : ''} ${BtnBg(bg)} ${BtnSize(size)}`}
			onClick={onClick}
		>
			{icon}
		</button>
	);
}

export default BtnIcon;
