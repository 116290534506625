export const header = {
	container: {
		backgroundColor: (theme) => theme.palette.background.header,
		padding: '8px 12px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		minHeight: '52px',
	},
	title: {
		color: (theme) => theme.palette.text.primary,
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
	},
	steps: {
		color: (theme) => theme.palette.text.primary,
		fontSize: '14px',
		lineHeight: '18px',
		fontWeight: 400,
	},
	buttonsContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	},
};

export const headerBlock = (isTablet) => {
	return [
		{
			padding: '0 8px 0 0',
			display: 'flex',
			flexDirection: isTablet ? 'row' : 'column',
			alignItems: isTablet ? 'center' : 'flex-start',
			justifyContent: 'space-between',
		},
	];
};
