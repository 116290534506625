import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useLazyGetServiceListQuery } from '../../../../store/newApi/service/service';
import { Button } from '@mui/material';
import Filter from '../../../../newComponents/Filter/Filter';
import { formatPrice } from '../../../../helpers/formatText';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/tariffs.js';
import { useDispatch } from 'react-redux';

function AccountEmplTableTariffs() {
	const dispatch = useDispatch();
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});

	// колонки
	const columnsList = useMemo(
		() => [
			{
				accessorFn: (row) => row.name,
				id: 'name',
				header: 'Название',
				size: 150,
			},
			{
				accessorFn: (row) => row.description,
				id: 'description',
				header: 'Описание',
				size: 200,
			},
			{
				accessorFn: (row) => row.terminal,
				id: 'terminal',
				header: 'Терминал',
				size: 200,
			},
			{
				accessorFn: (row) => (row.price ? formatPrice(row.price) : '-'),
				id: 'price',
				header: 'Цена, ₽',
				size: 150,
			},
			{
				accessorFn: (row) => row.unit,
				id: 'unit',
				header: 'Единица',
				size: 150,
			},
		],
		[],
	);

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	// порядок колонок
	const columnOrdering = ['mrt-row-select', 'name', 'description', 'terminal', 'price', 'unit'];

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Тарифы и ставки'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium">
						Добавить новую
					</Button>,
					<Button variant="contained" color="primary" size="medium">
						Экспортировать
					</Button>,
				]}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<Filter onSetFilter={handleSetFilter} onResetFilter={handleResetFilter} />
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						hookName={useLazyGetServiceListQuery}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="tariffsRtk"
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableTariffs;
