import logo_big from '../../../../assets/images/logo_new.svg';
import { Link } from 'react-router-dom';
import './HeaderLanding.scss';
import { IconCross, IconMail, IconTelegram, IconWhatsApp } from '../../../../components/Icons';
import { useState, useEffect } from 'react';
import { UniversalButton } from '../../../../components';

function HeaderLanding() {
	const [scrollPosition, setScrollPosition] = useState(0);
	const [open, setOpen] = useState(false);

	let scrollPos = 0;
	let timer;

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);

		if (scrollPos < position) {
			document.querySelector('.header-two').classList.add('hide');
			clearTimeout(timer);
		} else {
			document.querySelector('.header-two').classList.remove('hide');
			clearTimeout(timer);
		}
		scrollPos = position;

		timer = window.setTimeout(function () {
			document.querySelector('.header-two').classList.remove('hide');
		}, 500);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		if (open) {
			document.querySelector('body').style.overflow = 'hidden';
		} else {
			document.querySelector('body').style.overflow = 'auto';
		}
	}, [open]);

	const onBurger = () => {
		setOpen(!open);
	};

	const handleCloseModal = () => {
		setOpen(!open);
	};

	return (
		<>
			<div className="header-main">
				<div className={`header__wrap ${scrollPosition === 0 ? '' : 'has-fixed'}`}>
					<div className={`burgerMenu ${open ? 'show' : ''}`}>
						<div className={`burgerMenu__container ${open ? 'show' : ''}`}>
							<div className="burgerMenu__stack">
								<UniversalButton
									icon={<IconCross size={'large'} color={'primary-30'} />}
									onClick={handleCloseModal}
									size={'large'}
									typeButton={'button'}
								/>
								<ul className="links__social mobile_links_social">
									<a href="mailto:mail@tt3s.ru" target="_blank" rel="noreferrer noopener nofollow">
										<IconMail size={'largeM'} color={'icon-social'} />
									</a>
								</ul>
							</div>
							<div className="burgerMenu__menu">
								<div>
									<div className="header__logo">
										<Link to="/">
											<img src={logo_big} alt="Logo" />
										</Link>
									</div>
									<div className="header__nav">
										<ul>
											<a href="#main" onClick={handleCloseModal}>
												Главная
											</a>
											<a href="#terminals" onClick={handleCloseModal}>
												Терминалы
											</a>
											<a href="#advantages" onClick={handleCloseModal}>
												Преимущества
											</a>
											<a href="#contacts" onClick={handleCloseModal}>
												Контакты
											</a>
										</ul>
									</div>
								</div>
								<ul className="links__contacts col">
									<Link to={'https://tetris.effex.ru/'}>Войти</Link>
									<a href="tel:+78005509191" target="_blank" rel="noreferrer noopener nofollow">
										8-800-550-91-91
									</a>
								</ul>
							</div>
						</div>
					</div>

					<div className="header__container header-one">
						<div className="header__logo">
							<Link to="/">
								<img src={logo_big} alt="Logo" />
							</Link>
						</div>
						<div className="header__block">
							<div className="header__nav" id="header-menu">
								<ul>
									<a href="#main">Главная</a>
									<a href="#terminals">Терминалы</a>
									<a href="#advantages">Преимущества</a>
									<a href="#contacts">Контакты</a>
								</ul>
							</div>
							<Link to={'https://tetris.effex.ru/'}>
								<UniversalButton label={'Войти'} color={'primary'} size={'large'} typeButton={'button'} />
							</Link>
						</div>
						<div className="header__burger" onClick={onBurger}>
							<div className="burger__block">
								<div className="burger">
									<span></span>
								</div>
							</div>
						</div>
					</div>

					<div className="header__container header-two">
						<ul className="links__social">
							<a href="mailto:mail@tt3s.ru" target="_blank" rel="noreferrer noopener nofollow">
								<IconMail size={'maxi'} color={'icon-social'} />
							</a>
						</ul>
						<ul className="links__contacts">
							<a href="tel:+78005509191" target="_blank" rel="noreferrer noopener nofollow">
								8-800-550-91-91
							</a>
							<a href="https://tetris.effex.ru/ru/check" target="_blank" rel="noreferrer noopener nofollow">
								Проверить заявку
							</a>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}

export default HeaderLanding;
