import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { postToRegistaration } from "../../api/login/postToRegistration";
import { postToStaffRegistration } from "../../api/login/postToStaffRegistration";
import { postToSendCode } from "../../api/login/postToSendCode";
import { postToCheckCode } from "../../api/login/postCheckCode";
import { postToCreateClient } from "../../api/login/postToCreateClient";
import { postToTransporterRegistration } from "../../api/login/postToTransporterRegistration";

// ! postSelfRegistrationUser (ранее regUser + dispatch(regUserSuccess()))
//  Самостоятельная регистрация пользователя (без менеджера). 
//  С лендинга -> Войти -> Регистрация (/regClient) -> Самостоятельно

export const postSelfRegistrationUser = createAsyncThunk(
  "registration/postSelfRegistrationUser",
  async ({ requestData }, { rejectWithValue }) => {
    try {
      const response = await postToRegistaration(requestData);
      if (!response.statusText === "OK") {
        throw new Error(
          "Ошибка запроса registration/postSelfRegistrationUser. Не удалось отправить данные"
        );
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// ! postRegistrationStaff (ранее regStaff + dispatch(regStaffSuccess()))
//  Добавление сотрудника через админ панель. 
//  Админпанель -> Базы Данных -> Сотрудники (/accountEmpl/empls) -> Добавить сотрудника

export const postRegistrationStaff = createAsyncThunk(
  "registration/postRegistrationStaff",
  async ({ requestData }, { rejectWithValue }) => {
    try {
      const response = await postToStaffRegistration(requestData);
      if (!response.statusText === "OK") {
        throw new Error(
          "Ошибка запроса registration/postRegistrationStaff. Не удалось отправить данные"
        );
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//! postRegistrationTransporter (ранее regTransporter + dispatch(regTransporterSuccess()))
// Адрес /regTransporter - самостоятельная регистрация перевозчика

export const postRegistrationTransporter = createAsyncThunk(
  "registration/postRegistrationTransporter",
  async ({ requestData }, { rejectWithValue }) => {
    try {
      const response = await postToTransporterRegistration(requestData);
      //   dispatch(regTransporterSuccess());
      if (!response.statusText === "OK") {
        throw new Error(
          "Ошибка запроса registration/postRegistrationTransporter. Не удалось отправить данные"
        );
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// ! postSendCode - отправка кода при самостоятельной регистрации клиента/перевозчика и при manRegClient
// Step2/step3
export const postSendCode = createAsyncThunk(
  "registration/postSendCode",
  async ({ requestData }, { rejectWithValue }) => {
    try {
      const response = await postToSendCode(requestData);
      if (!response.statusText === "OK") {
        throw new Error(
          "Ошибка запроса registration/postSendCode. Не удалось отправить данные"
        );
      }
      //   dispatch(sendCodeSuccess());
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// ! postCheckCode - отправка проверки кода при самостоятельной регистрации клиента/перевозчика и при manRegClient
// Step2/step3
export const postCheckCode = createAsyncThunk(
  "registration/postCheckCode",
  async ({ requestData }, { rejectWithValue }) => {
    try {
      const response = await postToCheckCode(requestData);
      if (!response.statusText === "OK") {
        throw new Error(
          "Ошибка запроса registration/postCheckCode. Не удалось отправить данные"
        );
      }
      //   dispatch(checkCodeSuccess());
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// ! manRegClient step5
// Создание клиента через админ панель
// Базы данных -> Клиенты -> Добавить клиента
export const postCreateClient = createAsyncThunk(
  "registration/postCreateClient",
  async ({ requestData }, { rejectWithValue }) => {
    try {
      const response = await postToCreateClient(requestData);
      if (!response.statusText === "OK") {
        throw new Error(
          "Ошибка запроса registration/postCreateClient. Не удалось отправить данные"
        );
      }
      //   dispatch(createClientSuccess());
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const registrationSlice = createSlice({
  name: "registration",
  initialState: {
    //Самостоятельная регистрация
    registrationUser_success: false,
    registrationUser_pending: true,
    registrationUser_error: false,

    //Регистрация нового сотрудника
    registrationStaff_success: false,
    registrationStaff_pending: true,
    registrationStaff_error: false,

    //Самостоятельная регистрация перевозчика
    registrationTransporter_success: false,
    registrationTransporter_pending: true,
    registrationTransporter_error: false,

    // Регистрация нового клиента через менеджера
    createClient_success: false,
    createClient_pending: true,
    createClient_error: false,

    // Отправка кода для регистрации
    sendCode_success: false,
    sendCode_pending: true,
    sendCode_error: false,

    //Проверка кода для регистрации
    checkCode_success: false,
    checkCode_pending: true,
    checkCode_error: false
   
  },
  reducers: {
    setInitialCreateClientData(state, action){
      state.createClient_success =false;
      state.createClient_pending =true;
      state.createClient_error =false;
    },
    setInitialSendCodeData(state, action){
      state.sendCode_success = false;
      state.sendCode_pending = true;
      state.sendCode_error = false;
    },
    setInitialStaffRegistrationData(state, action){
      state.registrationStaff_success = false;
      state.registrationStaff_pending =true;
      state.registrationStaff_error =false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSelfRegistrationUser.fulfilled, (state, action) => {
        state.registrationUser_success = true;
        state.registrationUser_pending = false;
        state.registrationUser_error = false
      })
      .addCase(postSelfRegistrationUser.pending, (state, action) => {
        state.registrationUser_success = false;
        state.registrationUser_pending = true;
        state.registrationUser_error = false
      })
      .addCase(postSelfRegistrationUser.rejected, (state, action) => {
        state.registrationUser_success = false;
        state.registrationUser_pending = false;
        state.registrationUser_error = true
      });
      builder
      .addCase(postRegistrationStaff.fulfilled, (state, action) => {
        state.registrationStaff_success = true;
        state.registrationStaff_pending = false;
        state.registrationStaff_error = false
        
      })
      .addCase(postRegistrationStaff.pending, (state, action) => {
        state.registrationStaff_success = false;
        state.registrationStaff_pending = true;
        state.registrationStaff_error = false
      })
      .addCase(postRegistrationStaff.rejected, (state, action) => {
        state.registrationStaff_success = false;
        state.registrationStaff_pending = false;
        state.registrationStaff_error = true
      });
  
      builder
      .addCase(postRegistrationTransporter.fulfilled, (state, action) => {
        state.registrationTransporter_success = true;
        state.registrationTransporter_pending = false
      })
      .addCase(postRegistrationTransporter.pending, (state, action) => {
        state.registrationTransporter_success = false;
        state.registrationTransporter_pending = true;
      })
      .addCase(postRegistrationTransporter.rejected, (state, action) => {
        state.registrationTransporter_success = false;
        state.registrationTransporter_pending = false;
        state.registrationTransporter_error = true
      });

      builder
      .addCase(postCreateClient.fulfilled, (state, action) => {
        state.createClient_success = true;
        state.createClient_pending = false
      })
      .addCase(postCreateClient.pending, (state, action) => {
        state.createClient_success = false;
        state.createClient_pending = true;
      })
      .addCase(postCreateClient.rejected, (state, action) => {
        state.createClient_success = false;
        state.createClient_pending = false;
        state.createClient_error = true
      });

      builder
      .addCase(postSendCode.fulfilled, (state, action) => {
        state.sendCode_success = true;
        state.sendCode_pending = false;
        state.sendCode_error = false
      })
      .addCase(postSendCode.pending, (state, action) => {
        state.sendCode_success = false;
        state.sendCode_pending = true;
        state.sendCode_error = false
      })
      .addCase(postSendCode.rejected, (state, action) => {
        state.sendCode_success = false;
        state.sendCode_pending = false;
        state.sendCode_error = true
      });

      builder
      .addCase(postCheckCode.fulfilled, (state, action) => {
        state.checkCode_success = true;
        state.checkCode_pending = false;
        state.checkCode_error = false
      })
      .addCase(postCheckCode.pending, (state, action) => {
        state.checkCode_success = false;
        state.checkCode_pending = true;
        state.checkCode_error = false
      })
      .addCase(postCheckCode.rejected, (state, action) => {
        state.checkCode_success = false;
        state.checkCode_pending = false;
        state.checkCode_error = true
      });
  },
});

export const { setInitialCreateClientData, setInitialSendCodeData, setInitialStaffRegistrationData } = registrationSlice.actions;
export default registrationSlice.reducer;
