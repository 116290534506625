const initialState = {
  contactUsSuccess: false,
  sendErrorSuccess: false,
};

const publicFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CONTACT_US_SUCCESS':
      return {
        ...state,
        contactUsSuccess: true,
      };
    case 'SEND_ERROR_SUCCESS':
      return {
        ...state,
        checkCodeSuccess: true,
      };
    default:
      return state;
  }
};

export default publicFormReducer;
