import { Search, UniversalButton } from '../../../../../components';
import styles from './Layouts.module.scss';
import { useState } from 'react';
import { IconArrowLeft, IconEmpls, IconFileText, IconLink, IconPlus, IconTerminals } from '../../../../../components/Icons';
import { generateBackground, getInitialsCLient } from '../../../avatarsUtils';
import { Link } from 'react-router-dom';
import { setCurrentElementForSideBar, setSideBarActive } from '../../../../../store/slices/sideBarSlice';
import { useDispatch, useSelector } from 'react-redux';
import ModalClientInputs from '../Modals/ModalsCllient/ModalClientInputs';
import ModalClientEmpls from '../Modals/ModalsCllient/ModalClientEmpls';
import ModalClientEmpl from '../Modals/ModalsCllient/ModalClientEmpl';
import { setCurrentContract, setInputChange } from '../../../../../store/slices/clientSlice';
import ModalClientContracts from '../Modals/ModalsCllient/ModalClientContracts';

// Вкладка терминалы
function TabTerminals({ client }) {
	// Функция поделиться терминалом
	const handleShareTerminal = (terminal) => {};

	return (
		<div className={styles.TabTerminals}>
			<Search />
			<div className={styles.TabTerminals__list}>
				{client?.terminals?.map((el, ind) => (
					<div key={ind} className={styles.TabTerminals__item}>
						<div>
							<span>Терминал {el.name}</span>
							<button onClick={() => handleShareTerminal(el)}>
								<IconLink size={'medium'} color={'black'} />
							</button>
						</div>
						<p>
							<span>Адрес:</span> г. Москва, ул. Ленина
						</p>
						<p>
							<span>Вместимость (квота):</span> 20
						</p>
					</div>
				))}
			</div>
		</div>
	);
}

// Страница одного клиента
function LayoutOneClient({ client }) {
	const dispatch = useDispatch();
	// Активное окно
	const [activeTab, setActiveTab] = useState(1);
	const { contractsList } = useSelector((state) => state.client);
	// Переключение вкладок
	const handleOpenTab = (tabNumber) => {
		setActiveTab(tabNumber);
		dispatch(setCurrentContract(null));
	};

	// Добавить нового сотрудника
	const handleNewEmpl = () => {
		dispatch(setCurrentElementForSideBar(<ModalClientEmpl employer={''} />));
		dispatch(setSideBarActive(true));
	};

	// Вспомогательная функция по поиску по типам документов
	function findDoc(array, key, value) {
		const foundObject = array.find((obj) => obj[key] === value);
		return foundObject ? foundObject : '';
	}

	return (
		<>
			<div className={styles.layout__blockInfo}>
				<div className={styles.layout__infoFlex}>
					<Link to={'/accountEmpl/clients'}>
						<UniversalButton
							icon={<IconArrowLeft size={'large'} color={'black'} />}
							size={'large'}
							typeButton={'button'}
							onClick={() => {
								dispatch(setInputChange(false));
								dispatch(setCurrentContract(null));
							}}
						/>
					</Link>
					<div className={styles.client__card}>
						<div className={styles.client__img} style={{ background: generateBackground(client?.organization_name) }}>
							{getInitialsCLient(client?.organization_name)}
						</div>

						<div className={styles.client__info}>
							<span>{client?.organization_name}</span>
							<p>
								{findDoc(contractsList, 'contract_type', 'Договор') && 'Договор №'}
								{findDoc(contractsList, 'contract_type', 'Договор').contract_number}
							</p>
						</div>
					</div>
				</div>
				<div className={styles.layout__filters}>
					<ModalClientInputs />
				</div>
			</div>
			<div className={styles.layout__blockList}>
				<div className={styles.layout__content}>
					<div className={styles.layout__tabs}>
						<UniversalButton
							icon={<IconEmpls color={'primary_white'} />}
							color={activeTab == 1 ? 'accent' : 'primary'}
							size={'medium'}
							label={'Сотрудники'}
							onClick={() => handleOpenTab(1)}
						/>
						<UniversalButton
							icon={<IconFileText color={'primary_white'} />}
							color={activeTab == 2 ? 'accent' : 'primary'}
							size={'medium'}
							label={'Документы'}
							onClick={() => handleOpenTab(2)}
						/>
						<UniversalButton
							icon={<IconTerminals color={'primary_white'} />}
							color={activeTab == 3 ? 'accent' : 'primary'}
							size={'medium'}
							label={'Терминалы'}
							onClick={() => handleOpenTab(3)}
						/>
					</div>
					<div className={styles.layout__stackClients}>
						{activeTab == 1 && (
							<div className={styles.layout__content}>
								<div className={styles.stackClients_header}>
									<h5>Сотрудники</h5>
									<UniversalButton
										icon={<IconPlus size={'medium'} color={'#5C53A7'} />}
										size={'small'}
										color={'primary'}
										type={'with-border'}
										onClick={handleNewEmpl}
									/>
								</div>

								<ModalClientEmpls />
							</div>
						)}
						{activeTab == 2 && <ModalClientContracts />}
						{activeTab == 3 && (
							<div className={styles.layout__content}>
								<div className={styles.stackClients_header}>
									<h5>Терминалы</h5>
									<UniversalButton
										icon={<IconPlus size={'medium'} color={'#5C53A7'} />}
										size={'small'}
										color={'primary'}
										type={'with-border'}
									/>
								</div>

								<TabTerminals client={client} />
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default LayoutOneClient;
