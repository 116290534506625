import React, { useEffect, useState } from 'react';
import Slider from '../../../../../../../components/Slider/Slider';
import styles from './ActComponent.module.scss';
import Header from '../../../Header/Header';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import { useDispatch, useSelector } from 'react-redux';
import { getOneEntryreport } from '../../../../../../../store/slices/containerAcceptanceSlice';
import { useParams } from 'react-router-dom';

function ActComponent() {
	const dispatch = useDispatch();
	const { actId } = useParams();
	const [numberOfStep, setNumberOfStep] = useState(0);

	const { entryOneReport, damagesList } = useSelector((state) => state.containerAccept);
	console.log(entryOneReport, 'TEST');

	const steps = [
		{ id: 1, name: 'Документы' },
		{ id: 2, name: `Повреждения ( ${damagesList.length} )` },
	];

	const pageComponents = {
		0: <Step1 />,
		1: <Step2 />,
		2: <Step3 />,
	};

	const displayPage = () => pageComponents[numberOfStep] || null;

	useEffect(() => {
		dispatch(getOneEntryreport({ actId }));
	}, []);

	return (
		<div className={styles.account__wrap}>
			<Header text={`Акт приёма-передачи №${entryOneReport?.id}`} />
			<div className={styles.account__container}>
				{/* <div className={styles.account__stack}> */}
				<div className={styles.actSlider}>
					<Slider allName="Основное" data={steps} user_group={numberOfStep} setUser_group={setNumberOfStep} />
				</div>
				<div className={styles.stack}>{displayPage()}</div>
				{/* </div> */}
			</div>
		</div>
	);
}

export default ActComponent;
