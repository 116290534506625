import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';
// import publicFormReducer from './reducers/publicForm';
// import registrationReducer from './reducers/registrationUser';
import groupsUsersReducer from './reducers/groupsUsers';
import oneUserReducer from './reducers/erp';
// import permissionsListReducer from './reducers/permissions';
import containersReducer from './reducers/clientContainer';
import linesContainerReducer from './reducers/linesContainer';
// RTK Slices
import vehicleReducer from './slices/vehicleSlice';
import publicFormReducer from './slices/publicFormSlice';
import orderReducer from './slices/orderSlice';
import permissionsReducer from './slices/permissionsSlice';
import erpReducer from './slices/erpSlice';
import orderPageReducer from './slices/orderPageSlice';
import containerAcceptanceReducer from './slices/containerAcceptanceSlice';
import registrationReducer from './slices/registrationSlice';
import terminalPageReducer from './slices/terminalPageSlice';
import loginReducer from './slices/loginSlice';
import userReducer from './slices/userSlice';
import clientReducer from './slices/clientSlice';
import sideBarReducer from './slices/sideBarSlice';
import groupReducer from './slices/groupSlice';
import meReducer from './slices/meSlice';
import confirmationWindowReducer from './slices/confirmationWindowSlice';
import allContainersReducer from './slices/allContainersSlice';
import entriesSliceReducer from './slices/entriesSlice';
import logsSliceReducer from './slices/logsSlice';
import api from './api.js';
import containers from './rtkSlices/containers';
import entries from './rtkSlices/entries';
import orders from './rtkSlices/orders';
import entryreports from './rtkSlices/entryreports';
import storage from './rtkSlices/storage.js';
import repairs from './rtkSlices/repairs.js';
import cargos from './rtkSlices/cargos.js';
import historyEntries from './rtkSlices/historyEntries.js';
import repack from './rtkSlices/repack.js';
import repairTasks from './rtkSlices/repairTasks.js';
import tariffs from './rtkSlices/tariffs.js';
import clients from './rtkSlices/clients.js';
import transporters from './rtkSlices/transporters.js';
import vehicles from './rtkSlices/vehicles.js';
import empls from './rtkSlices/empls.js';
import createJob from './rtkSlices/createJob.js';
import tableLogs from './rtkSlices/tableLogs.js';
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore(
	{
		middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
		reducer: {
			[api.reducerPath]: api.reducer,
			groupsUsers: groupsUsersReducer,
			userOne: oneUserReducer,
			// permList: permissionsListReducer,
			containers: containersReducer,
			linesContainer: linesContainerReducer,
			//! RTK Slices
			publicForm: publicFormReducer,
			vehicle: vehicleReducer,
			order: orderReducer,
			containerAccept: containerAcceptanceReducer,
			orderPage: orderPageReducer,
			registration: registrationReducer,
			login: loginReducer,
			terminalPage: terminalPageReducer,
			user: userReducer,
			client: clientReducer,
			permissions: permissionsReducer,
			sideBar: sideBarReducer,
			group: groupReducer,
			me: meReducer,
			confirmationWindow: confirmationWindowReducer,
			allContainers: allContainersReducer,
			entries: entriesSliceReducer,
			logs: logsSliceReducer,
			// erp: erpReducer

			containersRtk: containers,
			entriesRtk: entries,
			ordersRtk: orders,
			entryreportsRtk: entryreports,
			storageRtk: storage,
			repairsRtk: repairs,
			cargosRtk: cargos,
			historyEntriesRtk: historyEntries,
			repackRtk: repack,
			repairTasksRtk: repairTasks,
			tariffsRtk: tariffs,
			clientsRtk: clients,
			transportersRtk: transporters,
			vehiclesRtk: vehicles,
			emplsRtk: empls,
			createJobRtk: createJob,
			tableLogsRtk: tableLogs,
		},
	},

	// composeEnhancers(applyMiddleware(thunk))
);

export default store;
