import { handleSearchTypePermission } from './handleSearchTypePermission';
import { useSelector } from 'react-redux';

const RequireCheckRights = ({ path, children, type }) => {
	const user = useSelector((state) => state.me.userData);

	const hasAccess = handleSearchTypePermission(user.terminals, path, type);

	return <>{hasAccess ? children : null}</>;
};

export default RequireCheckRights;
